import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// common
import { makeSelectDocumentTemplates } from '../../../../common/selectors';
// components
import { ConfirmComponent } from '../../../../components/confirm';
// features
import { RelatedTaskFrom } from '../../../task-management/task/related-tasks';
// forms
import { SelectDropdownForm } from '../../../../forms/forms/select-dropdown-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet-list
import {
  printItemRequest,
  removeItemRequest,
  getItemListRequest,
  createFleetItemRequest,
  updateFleetItemRequest,
} from '../../../fleet-list/actions';
// feature fleet
import EquipmentServiceForm, { EditEquipmentService } from '../components/form';
import MaintenanceForm, { EditMaintenanceForm } from '../../maintenance/components/maintenance-form';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  documentTemplates: makeSelectDocumentTemplates(state),
});

const mapDispatchToProps = (dispatch: Function) => ({
  dispatch,
  ...bindActionCreators(
    {
      printItemRequest,
      removeItemRequest,
      getItemListRequest,
      createFleetItemRequest,
      updateFleetItemRequest,
    },
    dispatch,
  ),
});

export const withEquipmentServiceActions = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    openMaintenanceForm: (props: Object) => (serviceData: Object = {}, documentTypeOptions: Array) => {
      const {
        configs,
        openModal,
        closeModal,
        openLoader,
        branchGuid,
        closeLoader,
        currentBranchGuid,
        createFleetItemRequest,
        updateFleetItemRequest,
      } = props;

      const {
        documentList,
        equipmentServiceGuid,
        equipmentServiceEntityGuid,
        equipmentServiceEntityType,
        equipmentServiceEntityGuidType,
      } = serviceData;

      let component;

      const isCreate = G.isNilOrEmpty(equipmentServiceGuid);
      const entityType = R.pathOr(equipmentServiceEntityType, ['entityType'], props);
      const entityGuid = R.pathOr(equipmentServiceEntityGuid, ['entityGuid'], props);
      const entityGuidType = R.pathOr(equipmentServiceEntityGuidType, ['entityGuidType'], props);

      const initialValues = { [entityGuidType]: entityGuid };

      const submitAction = (values: Object) => {
        const data = { values, entityType };

        if (isCreate) return createFleetItemRequest(data);

        updateFleetItemRequest(data);
      };

      const componentProps = {
        configs,
        openModal,
        closeModal,
        entityGuid,
        entityType,
        openLoader,
        closeLoader,
        submitAction,
        initialValues,
        entityGuidType,
        equipmentServiceGuid,
        primaryObjectGuid: equipmentServiceGuid,
        branchGuid: R.or(branchGuid, currentBranchGuid),
      };

      if (isCreate) {
        component = <MaintenanceForm {...componentProps} />;
      } else {
        component = (
          <EditMaintenanceForm
            {...componentProps}
            documentList={documentList}
            documentTypeOptions={R.pathOr(documentTypeOptions, ['documentTypeOptions'], props)}
          />
        );
      }

      const modal = G.getFullWindowModalWithContentWithLeftSpace(component, 300);

      openModal(modal);
    },
    // TODO: remove after customer testing
    // handleCreateOrUpdateEquipmentService: (props: Object) => (serviceData: Object = {}, documentTypeOptions: Array) => {
    //   const {
    //     configs,
    //     openModal,
    //     closeModal,
    //     openLoader,
    //     branchGuid,
    //     closeLoader,
    //     currentBranchGuid,
    //     createFleetItemRequest,
    //     updateFleetItemRequest,
    //   } = props;

    //   const {
    //     documentList,
    //     equipmentServiceGuid,
    //     equipmentServiceEntityGuid,
    //     equipmentServiceEntityType,
    //     equipmentServiceEntityGuidType,
    //   } = serviceData;

    //   let component;

    //   const isCreate = G.isNilOrEmpty(equipmentServiceGuid);
    //   const entityType = R.pathOr(equipmentServiceEntityType, ['entityType'], props);
    //   const entityGuid = R.pathOr(equipmentServiceEntityGuid, ['entityGuid'], props);
    //   const entityGuidType = R.pathOr(equipmentServiceEntityGuidType, ['entityGuidType'], props);

    //   const initialValues = { [entityGuidType]: entityGuid };

    //   const submitAction = (values: Object) => {
    //     const data = { values, entityType };

    //     if (isCreate) return createFleetItemRequest(data);

    //     updateFleetItemRequest(data);
    //   };

    //   const componentProps = {
    //     configs,
    //     openModal,
    //     closeModal,
    //     entityGuid,
    //     entityType,
    //     openLoader,
    //     closeLoader,
    //     submitAction,
    //     initialValues,
    //     entityGuidType,
    //     equipmentServiceGuid,
    //     branchGuid: R.or(branchGuid, currentBranchGuid),
    //   };

    //   if (isCreate) {
    //     component = <EquipmentServiceForm {...componentProps} />;
    //   } else {
    //     component = (
    //       <EditEquipmentService
    //         {...componentProps}
    //         documentList={documentList}
    //         documentTypeOptions={R.pathOr(documentTypeOptions, ['documentTypeOptions'], props)}
    //       />
    //     );
    //   }

    //   const title = G.ifElse(
    //     isCreate,
    //     G.getAddTitle,
    //     G.getEditTitle,
    //   )(['titles:equipment-service', 'Equipment Service']);

    //   const modal = {
    //     p: '0px',
    //     component,
    //     options: {
    //       title,
    //       width: 'auto',
    //       height: 'auto',
    //     },
    //   };

    //   openModal(modal);
    // },
    handleRemoveEquipmentService: ({ openModal, removeItemRequest }: Object) => (serviceData: Object) => {
      const { equipmentServiceGuid, equipmentServiceEntityType } = serviceData;

      const textLocale = `${G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')} ${
        G.getWindowLocale('titles:equipment-service', 'Equipment Service')
      }`;

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => removeItemRequest({
                fleetType: equipmentServiceEntityType,
                guids: R.of(Array, equipmentServiceGuid),
              }),
            },
          ],
        },
      };

      openModal(modal);
    },
    handlePrintEquipmentService: (props: Object) => (serviceData: Object) => {
      const { openModal, entityGuid, closeModal, printItemRequest } = props;

      const { equipmentServiceGuid, equipmentServiceEntityGuid, equipmentServiceEntityType } = serviceData;

      const entityType = R.pathOr(equipmentServiceEntityType, ['entityType'], props);

      const documentTemplates = G.ifElse(
        R.equals(entityType, GC.FIELD_TRUCK),
        R.path(['documentTemplates', GC.DOCUMENT_PRINTABLE_SECTION_FLEET_TRUCK_SERVICE], props),
        R.path(['documentTemplates', GC.DOCUMENT_PRINTABLE_SECTION_FLEET_TRAILER_SERVICE], props),
      );

      if (G.isNilOrEmpty(documentTemplates)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:no-configured-document-templates', 'There are no configured document templates'),
        );
      }

      const submitAction = (templateGuid: string) => printItemRequest({
        entityType,
        templateGuid,
        [GC.FIELD_SERVICE_GUID]: equipmentServiceGuid,
        [GC.FIELD_GUID]: R.or(entityGuid, equipmentServiceEntityGuid),
      });

      const component = (
        <SelectDropdownForm
          fieldLabel=' '
          cancelAction={closeModal}
          submitAction={submitAction}
          options={G.mapNameGuidObjectPropsToLabelValueObject(documentTemplates)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          maxWidth: '95vw',
          maxHeight: '95vh',
          width: 'fit-content',
          title: G.getWindowLocale('titles:select-template', 'Select Template'),
        },
      };

      openModal(modal);
    },
    handleCreateRelatedTask: (props: Object) => (entity: Object) => {
      const { dispatch, openModal, openLoader, closeModal, closeLoader, getItemListRequest } = props;

      const { guid } = entity;

      const callback = () => getItemListRequest({ guids: R.of(Array, guid) });

      const truckGuid = G.getPropFromObject(GC.GRC.FLEET_TRUCK_GUID, entity);
      const trailerGuid = G.getPropFromObject(GC.GRC.FLEET_TRAILER_GUID, entity);

      const initialValues = {
        relatedTask: true,
        [GC.FIELD_SUB_OBJECT_GUID]: guid,
        [GC.FIELD_TASK_OBJECT]: R.or(truckGuid, trailerGuid),
        [GC.FIELD_SUB_OBJECT_TYPE]: GC.TASK_MANAGEMENT_SUB_OBJECT_TYPE_EQUIPMENT_SERVICE,
        [GC.FIELD_OBJECT_TYPE]: R.toUpper(G.ifElse(G.isNotNil(truckGuid), GC.FIELD_TRUCK, GC.FIELD_TRAILER)),
      };

      const component = (
        <RelatedTaskFrom
          dispatch={dispatch}
          callback={callback}
          openModal={openModal}
          closeModal={closeModal}
          openLoader={openLoader}
          closeLoader={closeLoader}
          initialValues={initialValues}
        />
      );

      const modal = {
        p: '0px',
        component,
        options: {
          movable: false,
          overflow: 'auto',
          maxHeight: '90vh',
          title: G.getWindowLocale('titles:create-task', 'Create Task'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

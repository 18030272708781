import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withState,
  withProps,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// common
import { toggleMarkAsHotLoadByLoadTypeRequest } from '../../../common/actions';
// components
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { PageActions } from '../../../components/page-actions';
import { ConfirmComponent } from '../../../components/confirm';
import { withPromptModal } from '../../../components/edit-report/hocs';
import { openModal, closeModal } from '../../../components/modal/actions';
// features
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import ReferenceFormComponent from '../../reference/components/reference-form';
import { setExpandedContainerOptions } from '../../expanded-container/actions';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
import { makeSelectAvailableReferenceTypesByScope } from '../../reference/selectors';
import { makeSelectCloDispatchBoardActionPanel } from '../../configurations/selectors';
// forms
import CancelCLOForm from '../../../forms/forms/cancel-clo-form';
import { withUpdateContainerForm } from '../../../forms/hocs/with-update-container-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withFixedPopover, withLoadCustomStatusOptions } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { ListWrapper, ZOrderWrapper } from '../../../ui';
// utilities
import routesMap, { getOrderEntryRoute } from '../../../utilities/routes';
// feature dispatch-board-new
import * as H from '../helpers';
import GroupTable from '../components/table';
import RowActions from './components/row-actions';
import { TOGGLE_SHIP_UNIT_CELL } from '../constants';
import { getCLOFilterParams } from '../settings/filter-params';
import { getColumnSettings } from '../settings/table-settings';
import LeftTitleSection from '../components/left-title-section';
import { getGroupByOptions, getSummaryOptions } from '../settings';
import { withSelectLoads, withLoadMessageNoteClick } from '../hocs';
import {
  makeSelectModeOptions,
  makeSelectServiceOptions,
  makeSelectConfigCurrency,
  makeSelectConfigAutodialName,
  makeSelectConfigsForCloGlobalSearch,
  makeSelectConfigShowTransportationMode,
} from '../selectors';
import {
  makeSelectSummary,
  makeSelectGroupBy,
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectGlobalFilterValue,
  makeSelectGlobalFilterType,
  makeSelectTableTitleFilters,
  makeSelectShipUnitsExpanded,
} from './selectors';
import {
  selectItem,
  setGroupBy,
  setReports,
  setUsedReport,
  cancelCLORequest,
  cleanQuickFilter,
  unquoteCLORequest,
  deleteItemRequest,
  getSummaryRequest,
  setUsedReportOnly,
  setTableTitleSort,
  getItemListRequest,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setGlobalFilterType,
  setQuickFilterParams,
  setGlobalFilterValue,
  setShipUnitsExpanded,
  createReferenceRequest,
  resetListAndPagination,
  updateContainerCallback,
  getOrdersShipUnitRequest,
  changeCancelStatusRequest,
  toggleOrderDetailsRequest,
  changeDefaultReportRequest,
} from './actions';
/////////////////////////////////////////////////

const enhance = compose(
  withProps({ [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO }),
  withFixedPopover,
  withLoadCustomStatusOptions,
  withState('usePrompt', 'setPromptStatus', true),
  withState('reportFromPrompt', 'setReportFromPrompt', null),
  withState('originalReport', 'setOriginalReport', null),
  withSelectLoads,
  withUpdateContainerForm,
  withHandlers({
    handleListRequest: ({ getItemListRequest }: Object) => (isInitial: boolean = false) => (
      getItemListRequest(isInitial)
    ),
    getQuickFilteredListRequest: (props: Object) => () => {
      props.resetListAndPagination();
      props.getItemListRequest(true);
    },
    handleAddReference: (props: Object) => (guid: scting) => {
      const { openModal, selectedList, createReferenceRequest } = props;

      const list = G.ifElse(
        G.isString(guid),
        R.of(Array, guid),
        selectedList,
      );

      if (R.isEmpty(list)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      const component = (
        <ReferenceFormComponent
          scope={GC.REF_SCOPE_NAME_CLO}
          submitAction={
            (values: Object) => createReferenceRequest(R.assoc('primaryObjectGuids', list, values))
          }
        />
      );
      const modal = G.createAddReferenceModalOptions(component);
      openModal(modal);
    },
    handleEditReport: (props: Object) => () => {
      const {
        openModal,
        modeOptions,
        setUsedReport,
        selectedReport,
        requestPending,
        serviceOptions,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          showReportSummary={true}
          setReport={setUsedReport}
          usedReport={selectedReport}
          disableSetReportFields={true}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getItemListRequest(true)}
          groupByOptions={getGroupByOptions(GC.LOAD_TYPE_CLO)}
          summaryOptions={getSummaryOptions(GC.LOAD_TYPE_CLO)}
          fields={getCLOFilterParams({ modeOptions, serviceOptions })}
        />
      );
      const modal = {
        component,
        options: {
          version: 2,
          maxWidth: 1175,
          width: 'calc(100vw - 20px)',
          height: 'calc(100vh - 60px)',
        },
      };
      openModal(modal);
    },
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const selectedReport = R.find(R.propEq(reportGuid, GC.FIELD_GUID), props.reportList);
      props.setUsedReport(selectedReport);
      props.getItemListRequest(true);
    },
    handleChangeReportParams: (props: Object) => (data: Object) => (
      props.setReportFromPrompt(data)
    ),
    handleSetUsedReport: (props: Object) => () => {
      const {
        setUsedReport,
        selectedReport,
        setPromptStatus,
        reportFromPrompt,
        setOriginalReport,
        getItemListRequest,
      } = props;

      if (R.and(
        G.isNotNilAndNotEmpty(reportFromPrompt),
        G.notEquals(selectedReport, reportFromPrompt),
      )) {
        setOriginalReport(selectedReport);
        setUsedReport(reportFromPrompt);
        getItemListRequest(true);
      }

      setPromptStatus(false);
    },
    handleCleanFilter: (props: Object) => () => {
      props.cleanQuickFilter();
      props.getItemListRequest(true);
    },
    handleSetGlobalFilter: (props: Object) => (value: string, changedGlobalFilterType: boolean) => {
      if (R.and(R.equals(props.globalFilterValue, value), R.not(changedGlobalFilterType))) return;

      props.setGlobalFilterValue(value);
      props.resetListAndPagination();
      props.getItemListRequest(true);
    },
    handleSetGroupBy: (props: Object) => (group: Object) => {
      props.setGroupBy(group.value);
      props.resetListAndPagination();
      props.getItemListRequest(true);
    },
    handleSummaryFilter: (props: Object) => (group: string, groupValue: string, parent: Object) => {
      const report = H.getReportWithSummary(group, groupValue, parent, props.selectedReport);
      props.setUsedReport(report);
      props.getItemListRequest(true);
    },
    handleSetSummaryGroup: (props: Object) => (name: string, groupValue: string) => {
      const report = R.assoc(name, groupValue, props.selectedReport);
      props.setUsedReportOnly(report);
    },
    handleCleanSummaryFilter: (props: Object) => (filter: string | Object) => {
      let summaryFilters = [];

      if (G.notEquals(filter, 'all')) {
        summaryFilters = R.filter(
          (summaryFilter: Object) => R.not(R.propEq(R.path(['summaryGroup'], filter), 'summaryGroup', summaryFilter)),
          R.path(['selectedReport', 'summaryFilters'], props),
        );
      }

      const report = R.assoc('summaryFilters', summaryFilters, props.selectedReport);
      props.setUsedReport(report);
      props.getItemListRequest(true);
    },
    handleCancelOrRestoreLoad: (props: Object) => (data: Object) => {
      const isCanceled = R.pathEq(GC.LOAD_STATUS_CANCELED, [GC.FIELD_STATUS], data);
      const type = G.ifElse(isCanceled, 'restore', 'cancel');
      let modal;

      if (isCanceled) {
        const options = {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:submit', 'Submit'),
              action: () => {
                props.closeModal();
                props.changeCancelStatusRequest({ data, type });
              },
            },
          ],
        };
        const component = (
          <ConfirmComponent textLocale={G.getWindowLocale('message:common-confirmation', 'Are you sure ?')} />
        );
        modal = { options, component };
      } else {
        const { closeModal, cancelCLORequest } = props;
        const initialValues = { [GC.FIELD_GUID]: R.prop(GC.FIELD_GUID, data) };
        const tels = R.pathOr([], [GC.SYSTEM_LIST_TELS], data);
        const telsLength = R.length(tels);
        const telGuid = G.ifElse(
          R.equals(telsLength, 1),
          R.path([GC.FIELD_GUID], R.head(tels)),
          null,
        );
        const component = (
          <CancelCLOForm
            telGuid={telGuid}
            closeModal={closeModal}
            initialValues={initialValues}
            submitAction={cancelCLORequest}
            cloGuid={R.path([GC.FIELD_GUID], data)}
            branchGuid={R.prop(GC.BRANCH_GUID, data)}
          />
        );
        modal = { p: '0px', component, options: {} };
      }

      props.openModal(modal);
    },
  }),
  withHandlers({
    handleClickEditIcon: (props: Object) => (e: Object, data: Object) => (
      props.openFixedPopup({
        version: 2,
        position: 'right',
        el: e.currentTarget,
        content: (
          <RowActions
            entity={data}
            openModal={props.openModal}
            closeModal={props.closeModal}
            closeFixedPopup={props.closeFixedPopup}
            addReference={props.handleAddReference}
            handleCancelOrRestoreLoad={props.handleCancelOrRestoreLoad}
            toggleMarkAsHotLoadByLoadTypeRequest={props.toggleMarkAsHotLoadByLoadTypeRequest}
            handleDeleteTel={(data: Object) => {
              props.setSelectedList(R.without(data[GC.FIELD_GUID], props.selectedList));
              props.deleteItemRequest(data);
            }}
          />
        ),
      })
    ),
    handleTableTitleFilter: G.handleTableTitleFilter,
    handleTitleCellClick: (props: Object) => (fieldName: string) => {
      if (R.equals(fieldName, TOGGLE_SHIP_UNIT_CELL)) {
        const { itemList, shipUnitsExpanded, setShipUnitsExpanded, getOrdersShipUnitRequest } = props;

        if (shipUnitsExpanded) {
          return setShipUnitsExpanded(false);
        }

        getOrdersShipUnitRequest(H.getItemListGuids(itemList));
        setShipUnitsExpanded(true);
      }
    },
  }),
  withLoadMessageNoteClick,
  withPromptModal(getCLOFilterParams({ modeOptions: [], serviceOptions: [] })),
  branch(
    (props: Object) => R.or(
      R.not(props.initialDataLoaded),
      G.isNilOrEmpty(props.selectedReport),
    ),
    renderNothing,
  ),
  pure,
);

const RouteListOrder = (props: Object) => {
  const {
    summary,
    groupBy,
    itemList,
    openModal,
    closeModal,
    totalCount,
    pagination,
    actionPanel,
    autodialApp,
    modeOptions,
    selectedList,
    selectedReport,
    configCurrency,
    openFixedPopup,
    serviceOptions,
    titleSortValues,
    closeFixedPopup,
    handleSelectAll,
    handleSelectLoad,
    globalFilterType,
    handleSetGroupBy,
    handleEditReport,
    globalFilterValue,
    getSummaryRequest,
    tableTitleFilters,
    shipUnitsExpanded,
    getItemListRequest,
    handleAddReference,
    setGlobalFilterType,
    handleClickEditIcon,
    handleSummaryFilter,
    handleTitleCellClick,
    handleUpdateContainer,
    handleSetGlobalFilter,
    handleSetSummaryGroup,
    handleMessageNoteClick,
    handleTableTitleFilter,
    showTransportationMode,
    loadCustomStatusOptions,
    handleShowLoadReferences,
    handleCleanSummaryFilter,
    toggleOrderDetailsRequest,
    configsForCloGlobalSearch,
  } = props;

  const mainLightColor = G.getTheme('colors.light.mainLight');

  const listActionsOpt = [
    {
      type: 'massAction',
      action: handleAddReference,
      permissions: [PC.CLO_REFERENCE_WRITE],
      icon: I.plusRound(mainLightColor, 25, 25),
      text: G.getWindowLocale('actions:add-reference', 'Add Reference'),
    },
    {
      permissions: [PC.ORDER_QUOTE_WRITE],
      action: () => G.goToRoute(GC.ROUTE_PATH_DO_QUOTE),
      icon: I.createCloIcon(mainLightColor, 40, 40, 'transparent'),
      text: G.getWindowLocale('actions:create-quote', 'Create Quote'),
    },
  ];

  const customTitleComponent = (
    <LeftTitleSection
      summary={summary}
      groupBy={groupBy}
      totalCount={totalCount}
      loadType={GC.LOAD_TYPE_CLO}
      selectedReport={selectedReport}
      handleEditReport={handleEditReport}
      globalFilterType={globalFilterType}
      globalFilterValue={globalFilterValue}
      getSummaryRequest={getSummaryRequest}
      handleSelectGroupBy={handleSetGroupBy}
      handleSummaryFilter={handleSummaryFilter}
      setGlobalFilterType={setGlobalFilterType}
      handleSetGlobalFilter={handleSetGlobalFilter}
      handleSetSummaryGroup={handleSetSummaryGroup}
      configsForGlobalSearch={configsForCloGlobalSearch}
      handleCleanSummaryFilter={handleCleanSummaryFilter}
    />
  );

  const columnSettingsData = {
    openModal,
    closeModal,
    autodialApp,
    selectedList,
    configCurrency,
    openFixedPopup,
    closeFixedPopup,
    handleSelectLoad,
    handleUpdateContainer,
    handleMessageNoteClick,
    handleShowLoadReferences,
    loadType: GC.LOAD_TYPE_CLO,
    toggle: toggleOrderDetailsRequest,
    actionPanel: R.indexBy(R.prop(GC.FIELD_STATUS), actionPanel),
  };

  return (
    <ListWrapper p={15}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          pt='0px'
          version={2}
          noExportable={true}
          popperWithCount={true}
          type={GC.ROUTE_TEL_REPORT}
          hiddenRightFilterInfo={false}
          customTitleComponent={customTitleComponent}
          exportPermissions={R.of(Array, PC.TEL_WRITE)}
          title={G.getWindowLocale('titles:tel', 'TEL')}
          pinnedReportsPanelAction={() => G.goToRoute(routesMap.cloList)}
          filterProps={getCLOFilterParams({ modeOptions, serviceOptions })}
          pinnedReportsPanelActionTitle={G.getWindowLocale('titles:show-historical-orders', 'Show Historical Orders')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        <GroupTable
          groupBy={groupBy}
          groups={itemList}
          totalCount={totalCount}
          pagination={pagination}
          modeOptions={modeOptions}
          routeTab={GC.LOAD_TYPE_CLO}
          selectedList={selectedList}
          titleSortValues={titleSortValues}
          handleSelectAll={handleSelectAll}
          tableTitleFilters={tableTitleFilters}
          shipUnitsExpanded={shipUnitsExpanded}
          handleClickEditIcon={handleClickEditIcon}
          handleLoadMoreEntities={getItemListRequest}
          handleTitleCellClick={handleTitleCellClick}
          handleTableTitleFilter={handleTableTitleFilter}
          showTransportationMode={showTransportationMode}
          loadCustomStatusOptions={loadCustomStatusOptions}
          columnSettings={getColumnSettings(columnSettingsData)}
        />
      </ZOrderWrapper>
      <AuthWrapper has={[PC.TEL_WRITE]}>
        <PageActions
          version={2}
          listActions={listActionsOpt}
          count={R.length(selectedList)}
          shadowColor={G.getTheme('createButton.shadowColor')}
          mainAction={{
            permissions: [PC.CLO_WRITE],
            action: () => G.goToRoute(getOrderEntryRoute()),
            text: G.getWindowLocale('actions:create-clo', 'Create CLO'),
          }}
        />
      </AuthWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  summary: makeSelectSummary(state),
  groupBy: makeSelectGroupBy(state),
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  modeOptions: makeSelectModeOptions(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  branchGuid: makeSelectCurrentBranchGuid(state),
  configCurrency: makeSelectConfigCurrency(state),
  serviceOptions: makeSelectServiceOptions(state),
  autodialApp: makeSelectConfigAutodialName(state),
  titleSortValues: makeSelectTitleSortValues(state),
  globalFilterType: makeSelectGlobalFilterType(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  globalFilterValue: makeSelectGlobalFilterValue(state),
  shipUnitsExpanded: makeSelectShipUnitsExpanded(state),
  refList: makeSelectAvailableReferenceTypesByScope(state),
  actionPanel: makeSelectCloDispatchBoardActionPanel(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  configsForCloGlobalSearch: makeSelectConfigsForCloGlobalSearch(state),
  showTransportationMode: makeSelectConfigShowTransportationMode(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  selectItem,
  setGroupBy,
  setReports,
  setUsedReport,
  cancelCLORequest,
  cleanQuickFilter,
  unquoteCLORequest,
  deleteItemRequest,
  getSummaryRequest,
  setUsedReportOnly,
  setTableTitleSort,
  getItemListRequest,
  setGlobalFilterType,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setGlobalFilterValue,
  setShipUnitsExpanded,
  createReferenceRequest,
  resetListAndPagination,
  updateContainerCallback,
  getOrdersShipUnitRequest,
  changeCancelStatusRequest,
  toggleOrderDetailsRequest,
  changeDefaultReportRequest,
  setExpandedContainerOptions,
  toggleMarkAsHotLoadByLoadTypeRequest,
})(enhance(RouteListOrder));

import styled, { css } from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

const greenColor = G.getTheme('colors.dark.green');
const greyColor = G.getTheme('colors.dark.grey');

const FleetMarkerInfoItem = styled.div`
  margin-bottom: 4px;
  border-bottom: 1px solid ${greyColor};

  ${({ highlighted }: Object) => highlighted && css`
    & {
      background-color: ${() => `${greenColor}25`};
    }
  `}
`;

export {
  FleetMarkerInfoItem,
};


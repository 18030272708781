import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
// feature config
import { tollChargeFields, defaultTollChargesFields, getTollChargesValidationSchema } from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultTollChargesFields,
      props.initialValues,
    ),
    validationSchema: getTollChargesValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
  }),
  pure,
);

const TollChargeForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={tollChargeFields}
      fieldsWrapperStyles={{ pt: 11 }}
      handlers={{ handleDisableIntegrationType: () => props.isEditMode }}
    />
    <FormFooter />
  </form>
);

export default enhance(TollChargeForm);

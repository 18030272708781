import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
// components
import { openModal } from '../../../components/modal/actions';
import { ConfirmComponent } from '../../../components/confirm';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { asyncGetConfigsByNames } from '../../../helpers/api-async';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// features
import allEventsFieldsList from '../../configurations/settings/all-event-filds';
import { makeSelectAllAvailableReferenceTypesByScopeName } from '../../reference/selectors';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.light.blue');
const warningColor = G.getTheme('colors.warning');
const mainLightColor = G.getTheme('colors.light.mainLight');

const useExportExcel = (props: Object, filterProps: Array) => {
  const { selectedReport, exportReportDataRequest } = props;

  const configurationTabUri = `${routesMap.configUI}?tab=${GC.TAB_UI_LOAD}`;

  const dispatch = useDispatch();

  const [eventFields, setEventFields] = useState(null);

  const allAvailableRefTypesByScopeName = useSelector(makeSelectAllAvailableReferenceTypesByScopeName());

  useEffect(() => {
    const getConfigs = async () => {
      const branchGuid = G.getAmousCurrentBranchGuidFromWindow();

      const { configs } = await asyncGetConfigsByNames({ branchGuid, names: [GC.UI_LOAD_GENERATE_EXCEL_WITH_EVENTS] });

      const eventFields = R.pathOr(null, [GC.UI_LOAD_GENERATE_EXCEL_WITH_EVENTS, 'value'], configs);

      setEventFields(eventFields);
    };

    getConfigs();
  }, []);

  const filterBy = useMemo(() => {
    const references = G.getRefListByReportType(
      allAvailableRefTypesByScopeName,
      R.path([GC.FIELD_TYPE], selectedReport),
    );

    return G.setFilterByParams(references, filterProps);
  }, [filterProps, selectedReport, allAvailableRefTypesByScopeName]);

  const allEventsFields = useMemo(() => {
    if (G.isNilOrEmpty(eventFields)) return null;

    return G.mapIndexed((name: Object, index: number) => ({
      name,
      sequence: R.inc(index),
      displayName: R.compose(
        R.prop('label'),
        R.find(R.propEq(name, 'value')),
      )(allEventsFieldsList),
    }), eventFields);
  }, [eventFields]);

  const goToConfiguration = useCallback(() => G.goToRoute(configurationTabUri), []);

  const handleEmptyEventFields = useCallback(() => {
    const removeTemplateText = G.getWindowLocale(
      'messages:export-event-fields-empty',
      'To generate Excel with stops you must fill up the field list in the configuration.',
    );

    const component = (
      <Flex gap={20} flexDirection='column'>
        <ConfirmComponent textLocale={removeTemplateText} />
        <Box
          cursor='pointer'
          color={blueColor}
          onClick={goToConfiguration}
        >
          {G.getWindowLocale('actions:go-to-configuration', 'Go to configuration')}
        </Box>
      </Flex>
    );

    const modal = {
      p: '15px',
      component,
      TitleComponent: () => (
        <Flex p={6} gap={5} backgroundColor={blueColor}>
          {I.symbol('⚠', warningColor, 18, 18)}
          <Box fontWeight={600} color={mainLightColor}>{G.getWindowLocale('titles:warning', 'Warning')}</Box>
        </Flex>
      ),
      options: {
        width: 600,
        movable: false,
        withCloseIcon: true,
      },
    };

    dispatch(openModal(modal));
  }, [goToConfiguration]);

  const handleExportAction = useCallback((showAllEvents: boolean) => {
    if (R.and(showAllEvents, G.isNilOrEmpty(allEventsFields))) return handleEmptyEventFields();

    const fields = R.pathOr({}, ['fields'], selectedReport);
    const report = R.assoc('fields', G.addDisplayNameToReportFields(fields, filterBy), selectedReport);

    exportReportDataRequest({
      ...report,
      showAllEvents,
      allEventsFields,
      fileType: 'xlsx',
    });
  }, [filterBy, allEventsFields, selectedReport, exportReportDataRequest, handleEmptyEventFields]);

  const exportFileTypes = useMemo(() => (
    [
      {
        label: 'xls',
        action: () => handleExportAction(false),
        text: G.getWindowLocale('actions:export-exl', 'Generate Excel'),
      },
      {
        label: 'xls',
        action: () => handleExportAction(true),
        text: G.getWindowLocale('actions:export-exl-with-stops', 'Generate Excel With Stops'),
      },
    ]
  ), [handleExportAction]);

  return exportFileTypes;
};

export default useExportExcel;

import React from 'react';
import * as R from 'ramda';
// components
import { Tabs2 } from '../../../../components/tabs-mui';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

const getTabs = (activeTab: number) => [
  {
    value: 0,
    withCount: R.equals(activeTab, 0),
    text: G.getWindowLocale('titles:service', 'Service'),
  },
  {
    value: 1,
    withCount: R.equals(activeTab, 1),
    text: G.getWindowLocale('titles:vendor', 'Vendor'),
  },
  {
    value: 2,
    withCount: R.equals(activeTab, 2),
    text: G.getWindowLocale('titles:invoice', 'Invoice'),
  },
];

const LeftTabs = (props: Object) => {
  const {
    leftActiveTab,
    setLeftActiveTab,
  } = props;

  return (
    <Tabs2
      activeTab={leftActiveTab}
      tabs={getTabs(leftActiveTab)}
      setActiveTab={setLeftActiveTab}
      tabStyles={GC.COMMON_MUI_TAB_STYLES}
      tabsStyles={{ ...GC.COMMON_MUI_TABS_STYLES, m: '15px' }}
    />
  );
};

export default LeftTabs;

import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
// features
import { makeSelectMasterSettings } from '../../../branch/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box } from '../../../../ui';
import { scrollableContainerCss4px } from '../../../../ui/common';
// feature fleet
import Notes from './notes';
import Notify from './notify';
import AiUpload from './ai-upload';
import Documents from './documents';
import RightTabs from './right-tabs';
//////////////////////////////////////////////////

const activeTabContentMap1 = {
  0: AiUpload,
  1: Documents,
  2: Notes,
  3: Notify,
};

const activeTabContentMap2 = {
  0: Documents,
  1: Notes,
  2: Notify,
};

const RightSection = (props: Object) => {
  const {
    rightActiveTab,
    setRightActiveTab,
  } = props;

  const masterSettings = useSelector(makeSelectMasterSettings());

  const aiEnabled = R.pathOr(false, [GC.FIELD_BRANCH_SETTINGS_AI_ENABLED], masterSettings);

  const contentMap = G.isTrue(aiEnabled) ? activeTabContentMap1 : activeTabContentMap2;

  const Component = contentMap[rightActiveTab];

  return (
    <Box
      flexGrow={1}
      height='100%'
      minWidth={620}
      flexShrink={0}
      overflow='auto'
      css={scrollableContainerCss4px}
    >
      <RightTabs
        aiEnabled={aiEnabled}
        rightActiveTab={rightActiveTab}
        setRightActiveTab={setRightActiveTab}
      />
      {
        G.isNilOrEmpty(Component) ? null : <Component {...props} />
      }
    </Box>
  );
};

export default RightSection;

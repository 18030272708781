import React from 'react';
import * as R from 'ramda';
// components
import { ActionBox } from '../../../../components/action-box';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// feature fleet-profile
import DailyHoursForm from '../forms/daily-hours';
import DriverHosLogList from '../components/driver-hos-log-list';
import { DailyHoursAdditionalFormGroupTitle } from '../components/daily-hours-additional-form-group-title';
//////////////////////////////////////////////////

const settings = {
  [GC.FIELD_LOG_DATE]: {
    width: 210,
    type: 'date',
    name: 'titles:date',
  },
  [GC.FIELD_ON_DUTY_MINUTES]: {
    width: 100,
    searchable: false,
    disableFilter: true,
    name: 'titles:on-duty-hours',
    customComponent: ({ data }: Object) => {
      const { shortString } = G.convertMinutesToHoursAndMinutes(G.getPropFromObject(GC.FIELD_ON_DUTY_MINUTES, data));

      return shortString;
    },
  },
  [GC.FIELD_DRIVING_MINUTES]: {
    width: 100,
    searchable: false,
    disableFilter: true,
    name: 'titles:driving-hours',
    customComponent: ({ data }: Object) => {
      const { shortString } = G.convertMinutesToHoursAndMinutes(G.getPropFromObject(GC.FIELD_DRIVING_MINUTES, data));

      return shortString;
    },
  },
  [GC.FIELD_SOURCE]: {
    width: 100,
    name: 'titles:source',
  },
  [GC.FIELD_TOTAL_DISTANCE]: {
    width: 150,
    name: 'titles:total-distance',
  },
  [GC.FIELD_TOTAL_DISTANCE_UOM]: {
    width: 150,
    name: 'titles:total-distance-uom',
  },
  [GC.FIELD_TRUCK_DAILY_LOG_GUID]: {
    width: 150,
    name: 'titles:from-truck-log',
    customComponent: ({ data }: Object) => {
      const truckDailyLogGuid = G.getPropFromObject(GC.FIELD_TRUCK_DAILY_LOG_GUID, data);

      if (G.isNilOrEmpty(truckDailyLogGuid)) return I.uiFalse();

      return I.uiTrue();
    },
  },
  [`truckDailyLog.${GC.GRC.FLEET_TRUCK_UNIT_ID}`]: {
    width: 150,
    name: ['titles:truck', 'titles:unit-id'],
    customComponent: ({ data, fieldData, callbackData }: Object) => {
      if (R.isNil(fieldData)) return null;

      const { handleGoToProfileByProfileType } = callbackData;

      const guid = R.prop(`truckDailyLog.${GC.GRC.FLEET_TRUCK_GUID}`, data);

      return (
        <ActionBox
          text={fieldData}
          action={() => handleGoToProfileByProfileType({ guid, type: 'truck' })}
        />
      );
    },
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 210,
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 210,
    type: 'date',
    name: 'titles:update-on',
  },
};

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(settings)),
};

const columnSettings = R.map(R.pick(['name', 'type', 'width', 'searchable', 'customComponent']), settings);

const placeholder = { text: 'Choose', key: 'titles:choose' };

const makeExportRequestPayload = ({ primaryObjectGuid }: Object) => ({
  searchCriteria: [
    {
      operation: 'equal',
      dataType: 'string',
      stringValue: primaryObjectGuid,
      propertyName: GC.FIELD_DRIVER_GUID,
    },
  ],
});

const makeRequestPayload = ({ driverBranchGuid, primaryObjectGuid }: Object) => ({
  [GC.CURRENT_BRANCH]: driverBranchGuid,
  systemFields: [
    { sequence: 1, name: GC.FIELD_DRIVER },
    { sequence: 2, name: `truckDailyLog.${GC.GRC.FLEET_TRUCK_GUID}` },
  ],
  searchCriteria: [
    {
      operation: 'equal',
      dataType: 'string',
      stringValue: primaryObjectGuid,
      propertyName: GC.FIELD_DRIVER_GUID,
    },
  ],
});

const filterProps = R.mapObjIndexed((
  { name, options, collection, filterType, disableFilter, type = 'string' }: Object,
  value: string,
) => ({
  value,
  options,
  collection,
  placeholder,
  disableFilter,
  type: R.or(filterType, type),
  [GC.FIELD_NAME]: G.isArray(name) ? R.join(': ', R.map(G.getWindowLocale, name)) : G.getWindowLocale(name),
}), settings);

const FILTER_PARAMS = R.values(filterProps);

const dailyHoursSettings = {
  report,
  filterProps,
  isReport: true,
  columnSettings,
  noExportable: false,
  useSearchableColumns: true,
  withResizableColumns: true,
  CustomForm: DailyHoursForm,
  groupName: 'dailyHoursList',
  useNewTitleFilterInputs: true,
  getItemListRequestMethod: 'post',
  reportType: GC.FLEET_DRIVER_DAILY_LOG_REPORT,
  tableCallbackDataProps: ['handleGoToProfileByProfileType'],
  itemTitleArr: ['titles:driver-daily-log', 'Driver Daily Log'],
  formGroupTitleArr: ['titles:driver-daily-log', 'Driver Daily Log'],
  modalAdditionalOptions: {
    p: '0 0 15px 0',
    options: { title: null },
  },
  additionalTableSettings: {
    expandableItems: true,
    checkBoxCellWidth: 81,
    searchableTitles: true,
    tableWrapperProps: { minHeight: 320, maxHeight: 'calc(100vh - 700px)' },
    expandedDetailsComponent: ({ parentProps }: Object) => <DriverHosLogList {...parentProps} />,
  },
  endpoints: {
    update: 'driverDailyLog',
    list: 'driverDailyLogList',
    entity: 'driverDailyLogByGuid',
    remove: 'driverDailyLogByGuid',
    export: 'driverDailyLogListExport',
    createOrUpdate: 'driverDailyLogMassCreate',
  },
  // components
  AdditionalFormGroupTitleComponent: DailyHoursAdditionalFormGroupTitle,
  // helpers
  makeRequestPayload,
  makeExportRequestPayload,
};

export {
  FILTER_PARAMS,
  dailyHoursSettings,
};

import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
import { documentSendingTypeOptions, documentSendingFromOptions } from '../../../../../helpers/options';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 300,
};

const getFieldDisplay = (name: string, values: Object) => {
  const {
    sendingFrom,
    sendingType,
    documentTypeGuid,
  } = values;

  const condition = G.isAnyTrue(
    R.and(R.equals(name, GC.FIELD_SENDING_FROM), G.isNotNilAndNotEmpty(documentTypeGuid)),
    R.and(R.equals(name, GC.FIELD_DOCUMENT_TYPE_GUID), G.isNotNilAndNotEmpty(sendingFrom)),
    R.and(R.equals(name, GC.FIELD_EXPIRATION_DAYS), R.equals(sendingType, GC.DOCUMENT_SENDING_TYPE_ATTACHMENT)),
  );

  if (condition) return 'none';
};

const handleSendingFromChange = (value: string, _: Object, { values, setValues }: Object) => setValues({
  ...values,
  [GC.FIELD_SENDING_FROM]: value,
  [GC.FIELD_SENDING_TYPE]: GC.DOCUMENT_SENDING_TYPE_LINK,
});

const handleSendingTypeChange = (e: Event, _: Object, { values, setValues }: Object) => {
  const value = G.getEventTargetValue(e);

  setValues({
    ...values,
    [GC.FIELD_SENDING_TYPE]: value,
    [GC.FIELD_EXPIRATION_DAYS]: R.equals(value, GC.DOCUMENT_SENDING_TYPE_ATTACHMENT) ?
      null :
      R.prop(GC.FIELD_EXPIRATION_DAYS, values),
  });
};

const getFieldSettings = (values: Object) => [
  {
    isRequired: true,
    type: 'reactSelect',
    options: 'documentTypeOptions',
    fieldName: GC.FIELD_DOCUMENT_TYPE_GUID,
    label: ['titles:document-type', 'Document Type'],
    inputWrapperStyles: {
      ...inputWrapperStyles,
      zIndex: 13,
      display: getFieldDisplay(GC.FIELD_DOCUMENT_TYPE_GUID, values),
    },
  },
  {
    isRequired: true,
    type: 'reactSelect',
    shouldCustomChange: true,
    fieldName: GC.FIELD_SENDING_FROM,
    options: documentSendingFromOptions,
    customChangeHandler: handleSendingFromChange,
    label: ['titles:sending-from', 'Sending From'],
    inputWrapperStyles: {
      ...inputWrapperStyles,
      zIndex: 12,
      display: getFieldDisplay(GC.FIELD_SENDING_FROM, values),
    },
  },
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    shouldCustomChange: true,
    fieldName: GC.FIELD_SENDING_TYPE,
    customChangeHandler: handleSendingTypeChange,
    label: ['titles:sending-type', 'Sending Type'],
    options: R.propEq(GC.DOCUMENT_SENDING_FROM_PRINT_MASTER_INVOICE, GC.FIELD_SENDING_FROM, values) ?
      [R.head(documentSendingTypeOptions)] :
      documentSendingTypeOptions,
  },
  {
    type: 'number',
    isRequired: true,
    fieldName: GC.FIELD_EXPIRATION_DAYS,
    label: ['titles:expiration-days', 'Expiration Days'],
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: getFieldDisplay(GC.FIELD_EXPIRATION_DAYS, values),
    },
  },
];

const defaultValues = {
  [GC.FIELD_SENDING_FROM]: null,
  [GC.FIELD_EXPIRATION_DAYS]: null,
  [GC.FIELD_DOCUMENT_TYPE_GUID]: null,
  [GC.FIELD_SENDING_TYPE]: GC.DOCUMENT_SENDING_TYPE_LINK,
};

const getValidationSchema = (values: Object) => {
  const {
    sendingFrom,
    sendingType,
    documentTypeGuid,
  } = values;

  return Yup.object().shape({
    [GC.FIELD_SENDING_FROM]: G.isNotNilAndNotEmpty(documentTypeGuid) ? G.yupStringNotRequired : G.yupStringRequired,
    [GC.FIELD_DOCUMENT_TYPE_GUID]: G.isNotNilAndNotEmpty(sendingFrom) ? G.yupStringNotRequired : G.yupStringRequired,
    [GC.FIELD_EXPIRATION_DAYS]: R.equals(sendingType, GC.DOCUMENT_SENDING_TYPE_ATTACHMENT) ?
      G.yupArrayNotRequired :
      Yup.number()
        .nullable(true)
        .required(G.getRequiredLocaleTxt())
        .integer(G.getShouldBeIntegerLocaleTxt())
        .typeError(G.getShouldBeIntegerLocaleTxt())
        .min(1, G.getShouldBeFromToLocaleTxt(1, 100))
        .max(100, G.getShouldBeFromToLocaleTxt(1, 100)),
  });
};

const getSubmitValues = (values: Object) => {
  const {
    sendingFrom,
    sendingType,
  } = values;

  let valuesToOmit = [G.isNotNilAndNotEmpty(sendingFrom) ? GC.FIELD_DOCUMENT_TYPE_GUID : GC.FIELD_SENDING_FROM];

  if (R.equals(sendingType, GC.DOCUMENT_SENDING_TYPE_ATTACHMENT)) {
    valuesToOmit = R.append(GC.FIELD_EXPIRATION_DAYS, valuesToOmit);
  }

  return R.omit(valuesToOmit, values);
};

const DocumentSendingForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={getFieldSettings(props.values)}
      documentTypeOptions={props.documentTypeOptions}
      fieldsWrapperStyles={{ pt: 15, flexDirection: 'column' }}
    />
    <FormFooter2 />
  </form>
);

const enhance = compose(
  withFormik({
    validationSchema: () => Yup.lazy(getValidationSchema),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(getSubmitValues(values)),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultValues,
      initialValues,
    ),
  }),
  pure,
);

export default enhance(DocumentSendingForm);

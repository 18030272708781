import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
// feature config
import {
  documentHubConfigFieldSettings,
  defaultDocumentHubConfigFields,
  documentHubConfigValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    validationSchema: documentHubConfigValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultDocumentHubConfigFields,
      initialValues,
    ),
  }),
  pure,
);

const DocumentHubConfigForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...props.optionsForSelect}
      {...G.getFormikProps(props)}
      fields={documentHubConfigFieldSettings}
      fieldsWrapperStyles={{ pt: 11, flexDirection: 'column' }}
      handlers={{ disableIntegrationType: () => props.isEditMode }}
    />
    <FormFooter />
  </form>
);

export default enhance(DocumentHubConfigForm);

import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const labelStyles = { pl: 10, fontSize: 14 };

const inputWrapperStyles = {
  mb: 20,
  width: 270,
  justifyContent: 'flex-end',
  flexDirection: 'row-reverse',
};

const getFieldSettings = (isAllDriversList: boolean = false) => [
  {
    labelStyles,
    type: 'checkbox',
    inputWrapperStyles,
    label: ['titles:rate', 'Rate'],
    fieldName: GC.FIELD_PREFER_DISTANCE_RATE,
  },
  {
    labelStyles,
    type: 'checkbox',
    inputWrapperStyles,
    fieldName: GC.FIELD_DRIVER,
    label: ['titles:driver', 'Driver'],
  },
  {
    labelStyles,
    type: 'checkbox',
    inputWrapperStyles,
    label: ['titles:truck', 'Truck'],
    fieldName: GC.FIELD_TRUCK_UNIT_ID,
  },
  {
    labelStyles,
    type: 'checkbox',
    inputWrapperStyles,
    fieldName: GC.FIELD_TRUCK,
    label: ['titles:truck-dimensions', 'Truck: Dimensions'],
  },
  {
    labelStyles,
    type: 'checkbox',
    inputWrapperStyles,
    fieldName: GC.FIELD_STATUS,
    label: ['titles:status', 'Status'],
  },
  {
    labelStyles,
    type: 'checkbox',
    inputWrapperStyles,
    fieldName: GC.FIELD_NOTE,
    label: ['titles:note', 'Note'],
  },
  {
    labelStyles,
    type: 'checkbox',
    fieldName: GC.FIELD_DISTANCE,
    label: ['titles:distance', 'Distance'],
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: () => G.ifElse(isAllDriversList, 'none', 'flex'),
    },
  },
  {
    labelStyles,
    type: 'checkbox',
    inputWrapperStyles,
    fieldName: GC.FIELD_LOCATION,
    label: ['titles:location', 'Location'],
  },
  {
    labelStyles,
    type: 'checkbox',
    inputWrapperStyles,
    fieldName: GC.FIELD_RESERVED_BY,
    label: ['titles:reserved-by', 'Reserved By'],
  },
];

const defaultFields = R.reduce(
  (acc: Object, { fieldName }: Object) => ({ ...acc, [fieldName]: true }),
  {},
  getFieldSettings(),
);

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(defaultFields, initialValues),
    handleSubmit: (values: Object, { props: { submitAction } }: Object) => {
      const data = R.compose(
        R.keys,
        R.pickBy(R.equals(false)),
      )(values);

      submitAction(data);
    },
  }),
  pure,
);


export const EditViewForm = enhance((props: Object) => {
  const { handleSubmit, isAllDriversList } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2 {...G.getFormikPropsToFieldset(props)} fields={getFieldSettings(isAllDriversList)} />
      <FormFooter2 />
    </form>
  );
});

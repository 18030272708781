import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, Flex } from '../../../ui';
// features dashboards
import LargeCard from './large-card';
import MiddleCard from './middle-card';
import TotalCard from './total-card-narrow';
import { generateExcelWithChartData } from '../actions';
import { TITLES_MAP, dataRangeEnumLocale } from '../constants';
import {
  chartDateMapper,
  getTotalsForCard,
  getFromLastDaysDescription,
  getDomainYAndTicksYByChartData,
} from '../helpers';
import {
  getFilterProps,
  TrailersByLocationTable,
  TrailersByIdleTimeTable,
} from '../settings/groupable-table-settings';
//////////////////////////////////////////////////

const TrailersCharts = (props: Object) => {
  const dispatch = useDispatch();

  const { filters, dashboard } = props;

  const { days } = filters;

  const trailersByCarrier = R.compose(
    R.map((item: Object) => R.assoc('name', item.name, item)),
    R.pathOr([], ['TRAILERS_BY_CARRIER', 'groups']),
  )(dashboard);

  const issuesByVendor = R.compose(
    R.map((item: Object) => R.assoc(
      'name',
      R.or(item.name, G.getWindowLocale('titles:no-vendor', 'No Vendor')),
      item,
    )),
    R.pathOr([], ['ISSUES_BY_VENDOR', 'groups']),
  )(dashboard);

  const openedIssuesByPriority = R.compose(
    R.map((item: Object) => R.assoc(
      'name',
      R.or(item.name, G.getWindowLocale('titles:no-priority', 'No Priority')),
      item,
    )),
    R.pathOr([], ['OPENED_ISSUES_BY_PRIORITY', 'groups']),
  )(dashboard);

  const inOutOfServiceTrailers = chartDateMapper(dashboard, 'IN_OR_OUT_OF_SERVICE_TRAILERS');

  const trailersByLocation = R.pathOr([], ['TRAILERS_BY_LOCATION', 'geofencingLocationWithTrailers'], dashboard);

  const trailersByIdleTime = R.compose(
    R.map((item: Object) => R.assoc(
      'name',
      R.propOr('', item.name, dataRangeEnumLocale),
      item,
    )),
    R.pathOr([], ['TRAILERS_BY_IDLE_TIME', 'groups']),
  )(dashboard);

  const fromLastDaysDescription = getFromLastDaysDescription(days);

  const handleGenerateExcel = useCallback((exportChartType: string) => {
    dispatch(generateExcelWithChartData(exportChartType));
  }, []);

  const daysSuffix = G.getWindowLocale('titles:days', 'days', { caseAction: 'lowerCase' });

  return (
    <Flex p={20} alignItems='start'>
      <Box width={1600}>
        <Flex mb={20}>
          <TotalCard
            description={fromLastDaysDescription}
            title={TITLES_MAP.availableTrailers}
            {...getTotalsForCard('TOTAL_AVAILABLE_TRAILERS', dashboard)}
          />
          <TotalCard
            title={TITLES_MAP.inServiceTrailers}
            revertLowPercentageTextColor={true}
            description={fromLastDaysDescription}
            {...getTotalsForCard('TOTAL_IN_SERVICE_TRAILERS', dashboard)}
          />
          <TotalCard
            title={TITLES_MAP.outOfService}
            revertLowPercentageTextColor={true}
            description={fromLastDaysDescription}
            {...getTotalsForCard('TOTAL_OUT_OF_SERVICE_TRAILERS', dashboard)}
          />
          <TotalCard
            totalSuffix={daysSuffix}
            title={TITLES_MAP.averageTrailersIdleTime}
            description={fromLastDaysDescription}
            {...getTotalsForCard('AVERAGE_TRAILERS_IDLE_TIME', dashboard)}
          />
          <TotalCard
            description={fromLastDaysDescription}
            title={TITLES_MAP.completedInspections}
            {...getTotalsForCard('TOTAL_COMPLETED_INSPECTIONS', dashboard)}
          />
          <TotalCard
            formatter={G.getFormattedDurationFromMinutes}
            description={fromLastDaysDescription}
            title={TITLES_MAP.averageInspectionTime}
            {...getTotalsForCard('AVERAGE_INSPECTION_TIME', dashboard)}
          />
          <TotalCard
            title={TITLES_MAP.issuesFound}
            description={fromLastDaysDescription}
            {...getTotalsForCard('TOTAL_ISSUES_FOUND', dashboard)}
          />
          <TotalCard
            totalSuffix={daysSuffix}
            title={TITLES_MAP.averageIssuesAge}
            description={fromLastDaysDescription}
            {...getTotalsForCard('AVERAGE_ISSUES_AGE', dashboard)}
          />
        </Flex>
        <Flex alignItems='start' flexDirection='column'>
          <LargeCard
            width={1585}
            height={480}
            withExport={true}
            data={trailersByLocation}
            chartType='GROUPABLE_TABLE'
            title={TITLES_MAP.trailersByLocation}
            exportChartType='TRAILERS_BY_LOCATION'
            tableSettings={TrailersByLocationTable}
            handleGenerateExcel={handleGenerateExcel}
            report={R.prop('report', TrailersByLocationTable)}
            filterProps={getFilterProps(R.prop('columnSettings', TrailersByLocationTable))}
          />
          <LargeCard
            width={1585}
            height={480}
            withExport={true}
            data={trailersByIdleTime}
            chartType='GROUPABLE_TABLE'
            title={TITLES_MAP.trailersByIdleTime}
            exportChartType='TRAILERS_BY_IDLE_TIME'
            tableSettings={TrailersByIdleTimeTable}
            handleGenerateExcel={handleGenerateExcel}
            report={R.prop('report', TrailersByIdleTimeTable)}
            filterProps={getFilterProps(R.prop('columnSettings', TrailersByIdleTimeTable))}
          />
          <LargeCard
            {...getDomainYAndTicksYByChartData(G.getPropFromObject('IN_OR_OUT_OF_SERVICE_TRAILERS', dashboard))}
            width={1585}
            noStack={true}
            chartType='BAR'
            data={inOutOfServiceTrailers}
            title={TITLES_MAP.inOutOfServiceTrailers}
            tickFormatter={(tick: string) => (tick === 0 ? '' : tick)}
          />
        </Flex>
      </Box>
      <Box width={520}>
        <Flex alignItems='start' flexDirection='column'>
          <MiddleCard
            height={360}
            chartType='SCORE'
            data={trailersByCarrier}
            title={TITLES_MAP.trailersByCarrier}
          />
          <MiddleCard
            height={380}
            chartType='PIE'
            showPercent={true}
            data={issuesByVendor}
            title={TITLES_MAP.issuesByVendor}
          />
          <MiddleCard
            height={380}
            chartType='PIE'
            showPercent={true}
            data={openedIssuesByPriority}
            title={TITLES_MAP.openedIssuesByPriority}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

export default TrailersCharts;

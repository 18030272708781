import React from 'react';
import * as R from 'ramda';
// components
import TextComponent from '../../../components/text';
import EditableBox from '../../../components/editable-box';
// forms
import { Info } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { ENUMS } from '../../../constants/enums';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const commonTextProps = {
  display: 'block',
  overflow: 'hidden',
  withEllipsis: true,
  width: 'calc(100% - 15px)',
};

const mainTextProps = {
  ...commonTextProps,
  fontWeight: 700,
};

const subTextProps = {
  ...commonTextProps,
  color: G.getTheme('colors.light.grey'),
};

export const CellComponent = (props: Object) => {
  const { icon, subText, mainText, singleText, handleClickMainText } = props;

  const withSubText = G.isNotNilAndNotEmpty(subText);
  const withSingleText = G.isNotNilAndNotEmpty(singleText);

  return (
    <Flex>
      <Flex mr='5px'>{icon}</Flex>
      {
        withSingleText &&
        <TextComponent {...commonTextProps} title={singleText}>{singleText}</TextComponent>
      }
      {
        R.not(withSingleText) &&
        <Box width='100%'>
          {
            G.isNotNilAndNotEmpty(mainText) &&
            <TextComponent
              {...mainTextProps}
              title={mainText}
              onClick={handleClickMainText}
              mb={G.ifElse(withSubText, '5px', '0px')}
              cursor={G.ifElse(G.isFunction(handleClickMainText), 'pointer', 'auto')}
              color={G.getThemeByCond(G.isFunction(handleClickMainText), 'colors.light.blue', 'colors.black')}
            >
              {mainText}
            </TextComponent>
          }
          {
            withSubText &&
            <TextComponent {...subTextProps} title={subText}>{subText}</TextComponent>
          }
        </Box>
      }
    </Flex>
  );
};

export const StatusCell = (props: Object) => {
  const { status, reserved, statusSource, handleOpenTelDetails, telPrimaryReferenceValue } = props;

  const commonProps = {
    p: '5px 10px',
    borderRadius: '5px',
    width: 'max-content',
    textTransform: 'capitalize',
  };

  const infoStyles = {
    ml: '5px',
    zIndex: 0,
    width: 14,
    height: 14,
    minWidth: 14,
  };

  const showStatusInfo = R.and(G.isFalse(reserved), G.isNotNilAndNotEmpty(statusSource));
  const infoText = `${G.getWindowLocale('titles:source', 'Source')}: ${G.getEnumLocale(statusSource)}`;

  if (R.and(G.isFalse(reserved), G.isNotNilAndNotEmpty(telPrimaryReferenceValue))) {
    const blackColor = G.getTheme('colors.black');

    return (
      <Flex>
        <Flex
          {...commonProps}
          color={blackColor}
          bg={G.getTheme('colors.orange')}
        >
          <Flex mr='5px'>{I.routesLoads(blackColor)}</Flex>
          <TextComponent
            {...commonTextProps}
            maxWidth={70}
            onClick={handleOpenTelDetails}
            title={telPrimaryReferenceValue}
            cursor={G.ifElse(G.isFunction(handleOpenTelDetails), 'pointer', 'auto')}
            textDecoration={G.ifElse(G.isFunction(handleOpenTelDetails), 'underline', 'auto')}
          >
            {telPrimaryReferenceValue}
          </TextComponent>
        </Flex>
        {showStatusInfo && <Info {...infoStyles} text={infoText} />}
      </Flex>
    );
  }

  const statusesMap = {
    [ENUMS.ENUM_AVAILABLE_NOW]: {
      [GC.FIELD_COLOR]: 'colors.lightGreen',
      [GC.FIELD_TITLE]: ['titles:available', 'Available'],
    },
    [GC.FIELD_RESERVED]: {
      [GC.FIELD_COLOR]: 'colors.poloBlue',
      [GC.FIELD_TITLE]: ['titles:reserved', 'Reserved'],
    },
    [ENUMS.ENUM_AVAILABLE_IN_FUTURE]: {
      [GC.FIELD_COLOR]: 'colors.light.blue',
      [GC.FIELD_TITLE]: ['titles:available-later', 'Available Later'],
    },
    [ENUMS.ENUM_UNAVAILABLE]: {
      [GC.FIELD_COLOR]: 'colors.light.mainRed',
      [GC.FIELD_TITLE]: ['titles:unavailable', 'Unavailable'],
    },
  };

  const driverStatus = G.ifElse(G.isTrue(reserved), GC.FIELD_RESERVED, status);

  const bgColor = G.getTheme(R.path([driverStatus, GC.FIELD_COLOR], statusesMap));
  const statusTitle = G.getWindowLocale(...R.path([driverStatus, GC.FIELD_TITLE], statusesMap));

  return (
    <Flex>
      <Flex {...commonProps} bg={bgColor} color={G.getTheme('colors.white')}>
        {statusTitle}
      </Flex>
      {showStatusInfo && <Info {...infoStyles} text={infoText} />}
    </Flex>
  );
};

export const NoteCell = (props: Object) => {
  const { note, handlePatchUpdateNote } = props;

  const changeNoteCallback = (text: string) => {
    if (G.isAllNilOrEmpty([note, text])) return;

    handlePatchUpdateNote(R.assoc(GC.FIELD_TEXT, text, note));
  };

  return (
    <Flex>
      <EditableBox
        onBlurCallback={changeNoteCallback}
        value={R.pathOr('', [GC.FIELD_TEXT], note)}
        wrapperStyles={{ p: '5px', height: 40, width: '100%' }}
        placeholder={G.getWindowLocale('titles:click-to-add-a-note', 'Click to add a note')}
      />
    </Flex>
  );
};

import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../../components/text';
// forms
import { Fieldset2 } from '../../../../forms/formik';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, Flex, ActionButton } from '../../../../ui';
//////////////////////////////////////////////////

const whiteGreyColor = G.getTheme('colors.whiteGrey');

const LocationInfo = ({ ml, type, label, entity }: Object) => {
  const location = G.concatLocationFields(entity);
  const address = G.renderAddressInfoWithNameAndCode(R.dissoc(GC.FIELD_TEMPLATE_ID, entity));
  const contact = G.concatContactFields(entity, [GC.FIELD_CONTACT_NAME, GC.FIELD_PHONE, GC.FIELD_EMAILS]);

  return (
    <Box
      p='5px'
      ml={ml}
      maxWidth={300}
      borderRadius='2px'
      bg={whiteGreyColor}
    >
      <Box fontWeight={600}>
        {label}
      </Box>
      <TextComponent title={address} display='block' maxWidth='100%' withEllipsis={true}>
        {address}
      </TextComponent>
      <TextComponent title={location} display='block' maxWidth='100%' withEllipsis={true}>
        {location}
      </TextComponent>
      {
        R.equals(type, GC.SYSTEM_OBJECT_PRIMARY_LOCATION) &&
        <TextComponent title={contact} display='block' maxWidth='100%' withEllipsis={true}>
          {contact}
        </TextComponent>
      }
    </Box>
  );
};

const ServiceVendorInfo = ({ text, label }: Object) => (
  <Box
    p='5px'
    ml={20}
    maxWidth={150}
    borderRadius='2px'
    bg={whiteGreyColor}
  >
    <TextComponent
      minWidth='100%'
      maxWidth='100%'
      withEllipsis={true}
      whiteSpace='pre-line'
      wordBreak='break-all'
    >
      <Flex justifyContent='space-between'>
        <Box fontWeight={600} textTransform='uppercase'>
          {label}
        </Box>
      </Flex>
      {text}
    </TextComponent>
  </Box>
);

const selectFields = [
  {
    type: 'reactSelect',
    inputWrapperStyles: { width: 200 },
    fieldName: GC.FIELD_SERVICE_VENDOR_GUID,
    options: 'serviceVendorListAvailableOptions',
    label: ['titles:service-vendor', 'Service Vendor'],
  },
];

const SelectServiceVendor = (props: Object) => (
  <Fieldset2
    {...G.getFormikProps(props)}
    fields={selectFields}
    serviceVendorListAvailableOptions={props.serviceVendorListAvailableOptions}
    fieldsWrapperStyles={{ px: 0, mr: props.mr, justifyContent: 'space-between' }}
  />
);

const ServiceVendorSection = (props: Object) => {
  const serviceVendorGuid = R.path(['values', GC.FIELD_SERVICE_VENDOR_GUID], props);

  if (G.isNilOrEmpty(serviceVendorGuid)) {
    return (
      <Flex mb={15}>
        <SelectServiceVendor {...props} />
        <ActionButton
          ml={20}
          px='8px'
          height={30}
          type='button'
          borderRadius='5px'
          onClick={props.handleAddServiceVendor}
        >
          {G.getWindowLocale('titles:add-service-vendor', 'Add Service Vendor')}
        </ActionButton>
      </Flex>
    );
  }

  const { serviceVendorListAvailableOptions } = props;

  const serviceVendor = R.find(R.propEq(serviceVendorGuid, GC.FIELD_VALUE), serviceVendorListAvailableOptions);
  const dba = G.getPropFromObject(GC.FIELD_DBA, serviceVendor);
  const dunsNumber = G.getPropFromObject(GC.FIELD_DUNS_NUMBER, serviceVendor);
  const payToLocation = G.getPropFromObject(GC.SYSTEM_OBJECT_PAY_TO_LOCATION, serviceVendor);
  const primaryLocation = G.getPropFromObject(GC.SYSTEM_OBJECT_PRIMARY_LOCATION, serviceVendor);
  const hasNotServiceVendorInfoFields = G.isAllNilOrEmpty([dba, dunsNumber]);

  return (
    <Flex mb={15} flexWrap='wrap'>
      <SelectServiceVendor {...props} mr={G.ifElse(hasNotServiceVendorInfoFields, 20, '0px')} />
      {
        G.isNotNilAndNotEmpty(dba) &&
        <ServiceVendorInfo text={dba} label={G.getWindowLocale('titles:dba', 'DBA')} />
      }
      {
        G.isNotNilAndNotEmpty(dunsNumber) &&
        <ServiceVendorInfo text={dunsNumber} label={G.getWindowLocale('titles:duns-number', 'D-U-N-S Number')} />
      }
      {
        R.or(G.isNotNilAndNotEmpty(payToLocation), G.isNotNilAndNotEmpty(primaryLocation)) &&
        <Flex
          mt={G.ifElse(hasNotServiceVendorInfoFields, '0px', 15)}
          mx={G.ifElse(hasNotServiceVendorInfoFields, null, 'auto')}
        >
          {
            G.isNotNilAndNotEmpty(payToLocation) &&
            <LocationInfo entity={payToLocation} label={`${G.getWindowLocale('titles:pay-to', 'Pay To')}:`} />
          }
          {
            G.isNotNilAndNotEmpty(primaryLocation) &&
            <LocationInfo
              ml={20}
              entity={primaryLocation}
              type={GC.SYSTEM_OBJECT_PRIMARY_LOCATION}
              label={`${G.getWindowLocale('titles:primary-location', 'Primary Location')}:`}
            />
          }
        </Flex>
      }
    </Flex>
  );
};

const ServiceForm = (props: Object) => {
  const {
    handleAddServiceVendor,
    serviceVendorListAvailableOptions,
  } = props;

  return (
    <Box p={15}>
      <ServiceVendorSection
        {...G.getFormikPropsToFieldset(props)}
        handleAddServiceVendor={handleAddServiceVendor}
        serviceVendorListAvailableOptions={serviceVendorListAvailableOptions}
      />
    </Box>
  );
};

export default ServiceForm;

import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature dispatch-planner
import * as H from './helpers';
import * as A from './actions';
import * as C from './constants';
import { PLANNER_TEL_MODES } from './constants';
//////////////////////////////////////////////////

const emptyRoute = {
  tels: {},
};

const initialState = {
  telList: [],
  telTotal: 0,
  telReport: null,
  filterProps: [],
  cloEventTotal: 0,
  cloEventList: [],
  hidePlanned: true,
  inboundTelTotal: 0,
  inboundTelList: [],
  outboundTelList: [],
  outboundTelTotal: 0,
  openedFromPage: null,
  cloEventReport: null,
  inboundTelReport: null,
  telTitleSortValues: {},
  outboundTelReport: null,
  telTableTitleFilters: {},
  inboundTitleSortValues: {},
  outboundTitleSortValues: {},
  inboundTableTitleFilters: {},
  cloEventsTitleSortValues: {},
  outboundTableTitleFilters: {},
  cloEventsTableTitleFilters: {},
  cloEventPagination: {
    limit: 20,
    offset: 0,
  },
  telPagination: {
    limit: 20,
    offset: 0,
  },
  inboundTelPagination: {
    limit: 20,
    offset: 0,
  },
  outboundTelPagination: {
    limit: 20,
    offset: 0,
  },
  editedClos: {},
  branchGuid: null,
  selectedTels: {},
  routeErrors: null,
  currentRoute: null,
  routeTemplates: {},
  telFilterParams: {},
  initPlannerData: {},
  telListLoading: false,
  currentRouteItems: {},
  globalFilterValue: '',
  deletedEventGuids: [],
  telsByCloGuidList: {},
  inboundTerminals: null,
  availableTelReports: [],
  outboundTerminals: null,
  unassignedCloEvents: [],
  globalFilterValueET: '',
  globalFilterValueIT: '',
  globalFilterValueOT: '',
  primaryRefSequence: null,
  cloEventFilterParams: {},
  cloEventListLoading: false,
  branchGuidForRequest: null,
  currentBranchConfigs: null,
  currentRouteContainers: {},
  crossDockLocationList: null,
  currentRouteInitialItems: {},
  availableCloEventReports: [],
  inboundTelListLoading: false,
  outboundTelListLoading: false,
  currentRouteInitialState: null,
  cloRelatedTelListLoading: false,
  initPlannerDataFromTemplate: {},
  currentRouteInitialContainers: {},
  plannerMode: PLANNER_TEL_MODES.ALL_EXPANDED,
  // clo event list for map
  stopsInfoList: {},
  cloEventListForMap: [],
  cloEventListForMapLoading: false,
};

const omitOnSetInitialState = [
  'initPlannerData',
  'currentBranchConfigs',
  'crossDockLocationList',
  'initPlannerDataFromTemplate',
];

const initialItemProps = [
  {
    value: false,
    name: 'selected',
  },
  {
    value: false,
    name: 'expanded',
  },
];

const initialEventItemProps = [
  ...initialItemProps,
  {
    value: false,
    name: C.ADDED_TO_INTER_TERMINAL,
  },
];

const initialItemSelectedProps = [
  {
    value: true,
    name: 'selected',
  },
  {
    value: false,
    name: 'expanded',
  },
];

const setRouteBuilderInitialState = (state: Object) => R.mergeRight(
  R.pick(omitOnSetInitialState, state),
  R.omit(omitOnSetInitialState, initialState),
);

const setBranchGuidForRequest = (state: Object, data: string) => (
  P.$set('branchGuidForRequest', data, state)
);

const setCloEventsReports = (state: Object, data: Object) => (
  P.$set('availableCloEventReports', data, state)
);

const setTelReports = (state: Object, data: Object) => (
  P.$set('availableTelReports', data, state)
);

const getConfigsByNamesSuccess = (state: Object, data: Object) => (
  P.$set('currentBranchConfigs', data, state)
);

const setDispatchPlannerEventsOpenedFromPage = (state: Object, data: string) => (
  P.$set('openedFromPage', data, state)
);

const setInitialCurrentRoute = (state: Object, branchGuid: string) => (
  P.$all(
    P.$set('branchGuid', branchGuid),
    P.$set('currentRoute', R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, emptyRoute)),
    P.$set('currentRouteInitialState', R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, emptyRoute)),
    state,
  )
);

const setCurrentCloEventReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('cloEventReport', data),
    P.$set('globalFilterValue', ''),
    P.$set('cloEventsTitleSortValues', {}),
    P.$set('cloEventsTableTitleFilters', {}),
    P.$set('cloEventPagination', initialState.cloEventPagination),
    P.$set('cloEventList', R.filter(R.propEq(true, 'selected'), state.cloEventList)),
    state,
  )
);

const setCurrentTelReportWithRoute = (state: Object, data: Object) => (
  P.$all(
    P.$set('telList', []),
    P.$set('telReport', data),
    P.$set('selectedTels', {}),
    P.$set('routeErrors', null),
    P.$set('currentRouteItems', {}),
    P.$set('telTitleSortValues', {}),
    P.$set('globalFilterValueET', ''),
    P.$set('globalFilterValueIT', ''),
    P.$set('globalFilterValueOT', ''),
    P.$set('telTableTitleFilters', {}),
    P.$set('currentRouteContainers', {}),
    P.$set('telPagination', initialState.telPagination),
    P.$set('currentRoute', R.assoc(GC.FIELD_BRANCH_GUID, state.branchGuid, emptyRoute)),
    P.$set('currentRouteInitialState', R.assoc(GC.FIELD_BRANCH_GUID, state.branchGuid, emptyRoute)),
    P.$set('inboundTelList', G.setPropsWithValuesToArrayItems(state.inboundTelList, initialItemProps)),
    P.$set('outboundTelList', G.setPropsWithValuesToArrayItems(state.outboundTelList, initialItemProps)),
    P.$set('cloEventList', G.setPropsWithValuesToArrayItems(P.$get('cloEventList', state), initialEventItemProps)),
    state,
  )
);

const setCurrentTelReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('telList', []),
    P.$set('telReport', data),
    P.$set('inboundTelReport', data),
    P.$set('telTitleSortValues', {}),
    P.$set('outboundTelReport', data),
    P.$set('globalFilterValueET', ''),
    P.$set('telTableTitleFilters', {}),
    P.$set('telPagination', initialState.telPagination),
    P.$set('cloEventList', G.setPropsWithValuesToArrayItems(P.$get('cloEventList', state), initialItemProps)),
    state,
  )
);

const setCurrentInboundTelReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('inboundTelList', []),
    P.$set('inboundTelReport', data),
    P.$set('globalFilterValueIT', ''),
    P.$set('inboundTitleSortValues', {}),
    P.$set('inboundTableTitleFilters', {}),
    P.$set('inboundTelPagination', initialState.inboundTelPagination),
    state,
  )
);

const setCurrentOutboundTelReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('outboundTelList', []),
    P.$set('outboundTelReport', data),
    P.$set('globalFilterValueOT', ''),
    P.$set('outboundTitleSortValues', {}),
    P.$set('outboundTableTitleFilters', {}),
    P.$set('outboundTelPagination', initialState.outboundTelPagination),
    state,
  )
);

const setAvailableCloReports = (state: Object, data: Array) => (
  P.$set('availableCloEventReports', data, state)
);

const setAvailableTelReports = (state: Object, data: Array) => (
  P.$set('availableTelReports', data, state)
);

const setCloEventListLoading = (state: Object, data: boolean) => (
  P.$set('cloEventListLoading', data, state)
);

const setTelListLoading = (state: Object, data: boolean) => (
  P.$set('telListLoading', data, state)
);

const setInboundTelListLoading = (state: Object, data: boolean) => (
  P.$set('inboundTelListLoading', data, state)
);

const setOutboundTelListLoading = (state: Object, data: boolean) => (
  P.$set('outboundTelListLoading', data, state)
);

const setInitialPlannerData = (state: Object, data: string) => (
  P.$set('initPlannerData', data, state)
);

const setInitPlannerDataFromTemplate = (state: Object, data: string) => (
  P.$set('initPlannerDataFromTemplate', data, state)
);

const setCurrentRoute = (state: Object, data: string) => (
  P.$all(
    P.$set('routeErrors', null),
    P.$set('currentRoute', data),
    P.$set('currentRouteInitialState', data),
    state,
  )
);

const setSelectedCloEventList = (state: Object, data: Object) => {
  const { cloEventList } = state;

  return P.$set(
    'cloEventList',
    R.concat(
      G.setPropsWithValuesToArrayItems(data.results, initialItemSelectedProps),
      G.getArrayFilteredFromArrayByProp(
        cloEventList,
        data.results,
        GC.FIELD_GUID,
      ),
    ),
    state,
  );
};

const setSelectedTelList = (state: Object, data: Object) => (
  P.$all(
    P.$set('telTotal', data.totalCount),
    P.$set('telList', G.setPropsWithValuesToArrayItems(data.results, initialItemSelectedProps)),
    state,
  )
);

const setCloEventList = (state: Object, data: Object) => {
  const { cloEventList, cloEventPagination } = state;
  const newOffset = R.add(cloEventPagination.offset, cloEventPagination.limit);
  return P.$all(
    P.$set('cloEventTotal', data.totalCount),
    P.$set('cloEventPagination.total', data.totalCount),
    P.$set(
      'cloEventPagination.offset',
      G.ifElse(R.gt(data.totalCount, newOffset), newOffset, data.totalCount),
    ),
    P.$set(
      'cloEventList',
      R.concat(
        cloEventList,
        G.getArrayFilteredFromArrayByProp(
          G.setPropsWithValuesToArrayItems(data.results, initialEventItemProps),
          cloEventList,
          GC.FIELD_GUID,
        ),
      ),
    ),
    state,
  );
};

const setTelList = (state: Object, data: Object) => {
  const { telList, telPagination } = state;
  const newOffset = R.add(telPagination.offset, telPagination.limit);
  return P.$all(
    P.$set('telTotal', data.totalCount),
    P.$set(
      'telPagination.offset',
      G.ifElse(R.gt(data.totalCount, newOffset), newOffset, data.totalCount),
    ),
    P.$set(
      'telList',
      R.concat(
        telList,
        G.getArrayFilteredFromArrayByProp(
          G.setPropsWithValuesToArrayItems(data.results, initialItemProps),
          telList,
          GC.FIELD_GUID,
        ),
      ),
    ),
    state,
  );
};

const addTelsToList = (state: Object, data: Object) => (
  P.$all(
    P.$set('telPagination.offset', R.inc(state.telPagination.offset)),
    P.$set(
      'telList',
      R.concat(
        state.telList,
        G.getArrayFilteredFromArrayByProp(
          G.setPropsWithValuesToArrayItems(data.results, initialItemProps),
          state.telList,
          GC.FIELD_GUID,
        ),
      ),
    ),
    state,
  )
);

const removeTelsFromList = (state: Object, guids: Array) => {
  const { telList, telTotal, telPagination } = state;

  const count = R.length(guids);
  const newTelTotal = R.subtract(telTotal, count);
  const newTelList = R.omit(guids, R.indexBy(R.prop(GC.FIELD_GUID), telList));
  const offset = R.subtract(G.getPropFromObject('offset', telPagination), count);

  return P.$all(
    P.$set('telTotal', newTelTotal),
    P.$set('telPagination.offset', offset),
    P.$set('telList', R.values(newTelList)),
    state,
  );
};

const setInboundTelList = (state: Object, data: Object) => {
  const { inboundTelList, inboundTelPagination } = state;
  const newOffset = R.add(inboundTelPagination.offset, inboundTelPagination.limit);
  return P.$all(
    P.$set('inboundTelTotal', data.totalCount),
    P.$set(
      'inboundTelPagination.offset',
      G.ifElse(R.gt(data.totalCount, newOffset), newOffset, data.totalCount),
    ),
    P.$set(
      'inboundTelList',
      R.concat(
        inboundTelList,
        H.setSelectedPropIfExist(
          G.getArrayFilteredFromArrayByProp(
            G.setPropsWithValuesToArrayItems(data.results, initialItemProps),
            inboundTelList,
            GC.FIELD_GUID,
          ),
          R.values(state.selectedTels),
        ),
      ),
    ),
    state,
  );
};

const setOutboundTelList = (state: Object, data: Object) => {
  const { outboundTelList, outboundTelPagination } = state;
  const newOffset = R.add(outboundTelPagination.offset, outboundTelPagination.limit);
  return P.$all(
    P.$set('outboundTelTotal', data.totalCount),
    P.$set(
      'outboundTelPagination.offset',
      G.ifElse(R.gt(data.totalCount, newOffset), newOffset, data.totalCount),
    ),
    P.$set(
      'outboundTelList',
      R.concat(
        outboundTelList,
        H.setSelectedPropIfExist(
          G.getArrayFilteredFromArrayByProp(
            G.setPropsWithValuesToArrayItems(data.results, initialItemProps),
            outboundTelList,
            GC.FIELD_GUID,
          ),
          R.values(state.selectedTels),
        ),
      ),
    ),
    state,
  );
};

const resetCloEventPagination = (state: Object) => (
  P.$set('cloEventPagination', initialState.cloEventPagination, state)
);

const resetTelPagination = (state: Object) => (
  P.$set('telPagination', initialState.telPagination, state)
);

const selectCloEvent = (state: Object, id: string) => (
  P.$set('cloEventList', H.selectItem(state.cloEventList, id), state)
);

const selectTel = (state: Object, data: Array = []) => (
  P.$all(
    P.$set('telList', H.selectTelItems(state.telList, data)),
    P.$set('inboundTelList', H.setSelectedPropIfExist(state.inboundTelList, data)),
    P.$set('outboundTelList', H.setSelectedPropIfExist(state.outboundTelList, data)),
    P.$set('selectedTels', R.reduce((acc: Object, guid: string) => R.assoc(guid, guid, acc), {}, data)),
    state,
  )
);

const selectTelOnAllLists = (state: Object, data: Array) => (
  P.$all(
    P.$set('telList', H.setSelectedPropIfExist(state.telList, data)),
    P.$set('inboundTelList', H.setSelectedPropIfExist(state.inboundTelList, data)),
    P.$set('outboundTelList', H.setSelectedPropIfExist(state.outboundTelList, data)),
    P.$set('selectedTels', R.reduce((acc: Object, guid: string) => R.assoc(guid, guid, acc), {}, data)),
    state,
  )
);

const toggleCloEvent = (state: Object, id: string) => (
  P.$set(
    'cloEventList',
    R.map((item: Object) => {
      if (R.equals(R.prop(GC.FIELD_GUID, item), id)) {
        return P.$set('expanded', R.not(R.prop('expanded', item)), item);
      }
      return item;
    }, state.cloEventList),
    state,
  )
);

const getListName = ({ isInbound, isOutbound }: Object) => {
  if (isInbound) return 'inboundTelList';
  if (isOutbound) return 'outboundTelList';
  return 'telList';
};

const toggleTel = (state: Object, data: string) => {
  const listName = getListName(data);
  return P.$set(
    listName,
    R.map((item: Object) => {
      if (R.eqProps(GC.FIELD_GUID, item, data)) {
        return P.$set('expanded', R.not(R.prop('expanded', item)), item);
      }
      return item;
    }, state[listName]),
    state,
  );
};

const getCloEventDetailsSuccess = (state: Object, { data, cloGuid }: Object) => (
  P.$set(
    'cloEventList',
    R.map((item: Object) => {
      if (R.propEq(cloGuid, GC.FIELD_CLO_GUID, item)) return P.$set('details', data, item);
      return item;
    }, state.cloEventList),
    state,
  )
);

const uncheckAndRemoveCloEvent = (state: Object, data: Object) => {
  const removeEvent = H.getRemoveEventByGuid(R.prop(GC.FIELD_GUID, data), P.$get('currentRoute.tels', state));
  const tels = H.removeCloEventFromTels(removeEvent, P.$get('currentRoute.tels', state));

  return (
    P.$all(
      P.$set('currentRoute.tels', tels),
      P.$set('currentRouteItems', H.refreshRouteItems(tels)),
      P.$set('currentRouteContainers', H.refreshRouteContainers(tels)),
      P.$set('cloEventList', H.selectItem(state.cloEventList, R.prop(GC.FIELD_GUID, data))),
      state,
    )
  );
};

const selectCloEventForTelSuccess = (state: Object, data: Object) => H.selectCloEventForTel(state, data);

const addUnassignedEventToPlanner = (state: Object, data: Object) => H.addUnassignedEventToPlanner(state, data);

const addCloEventToInterTerminalTelSuccess = (state: Object, data: Object) => H.addCloEventToInterTerminal(state, data);

const setDetailsToListItem = (data: Object, list: Array) => R.map((item: Object) => {
  if (R.eqProps(GC.FIELD_GUID, data, item)) return R.assoc('details', data, item);
  return item;
}, list);

const setTelDetails = (state: Object, data: Object) => (
  P.$all(
    P.$set('telList', setDetailsToListItem(data, state.telList)),
    P.$set('inboundTelList', setDetailsToListItem(data, state.inboundTelList)),
    P.$set('outboundTelList', setDetailsToListItem(data, state.outboundTelList)),
    state,
  )
);

const sortTelEvents = (state: Object, guid: string) => {
  const events = H.sortTelEventsByDate(R.pathOr({}, ['currentRoute', 'tels', guid, GC.FIELD_LOAD_STOPS], state));
  return P.$set(`currentRoute.tels.${guid}.events`, events, state);
};

const addNewTelToRouteBuilder = (state: Object, data: Object = {}) => {
  const { telInfo, terminalInfo } = data;
  const order = R.length(R.values(P.$get('currentRoute.tels', state)));
  const newTel = H.createNewRouteTel(R.assoc('order', order, telInfo));
  const stateWithNewTel = P.$set(`currentRoute.tels.${newTel.guid}`, newTel, state);
  if (G.isNilOrEmpty(terminalInfo)) return stateWithNewTel;
  const { crossDockLocationList } = state;
  const returnObject = {
    dropTels: R.of(Array, R.prop(GC.FIELD_GUID, newTel)),
  };
  const dates = R.pick([GC.FIELD_LOAD_EVENT_LATE_DATE, GC.FIELD_LOAD_EVENT_EARLY_DATE], terminalInfo);
  const templateId = R.prop(GC.FIELD_LOCATION, terminalInfo);
  const terminal = R.mergeRight(R.prop(templateId, crossDockLocationList), dates);
  const terminalData = {
    returnObject,
    [GC.FIELD_LOCATION]: terminal,
    [GC.FIELD_EVENT_TYPE]: GC.STOP_TYPE_TERMINAL,
  };
  const stopId = G.generateGuid();
  return H.addTerminalDropsToCurrentRouteTels(stopId, terminalData, stateWithNewTel);
};

const addNewStopToRouteBuilderTelSuccess = (state: Object, data: Object) => {
  const options = H.generateDataForNewTelStopFromStopForm(data, state);
  const newStop = H.createNewStopOnRouteTel(options);

  return P.$set(`currentRoute.tels.${data.loadGuid}.events.${newStop.guid}`, newStop, state);
};

const addNewCloStopToRouteBuilderTel = (state: Object, data: Object) => {
  const options = H.generateDataForNewCloStopFromStopForm(data, state);
  const newStop = H.createNewCloStopOnRouteTel(options);

  return P.$set(`currentRoute.tels.${data.telGuid}.events.${newStop.guid}`, newStop, state);
};

const updateCloStopsOnPlannerSuccess = (state: Object, data: Object) => H.updateCloStopsOnPlanner(state, data);

const updateStopOnRouteBuilderTelSuccess = (state: Object, data: Object) => {
  const { stop, telGuid } = data;

  const pathToEvent = `currentRoute.tels.${telGuid}.events.${stop.eventGuid}`;

  const prevEvent = H.updateEventByStopType(P.$get(pathToEvent, state));

  const dateTimes = R.pick(
    R.concat(GC.GROUPED_FIELDS.EVENT_DATES_ARR, GC.GROUPED_FIELDS.APPOINTMENTS_ARR),
    stop.location,
  );

  const loc = H.getLocationByStopType(stop.location, prevEvent.stopType);
  const locationWithDateTimes = R.assoc('location', loc, dateTimes);
  const mergedData = R.mergeDeepRight(prevEvent, locationWithDateTimes);

  return P.$set(pathToEvent, mergedData, state);
};

const addTerminalToLoadPlannerTelUI = (state: Object, data: Object) => {
  const { updatedStop } = data;

  const stopId = G.generateGuid();

  let cloEventList = P.$get('cloEventList', state);

  if (G.isNotNil(updatedStop)) cloEventList = H.getCloEventListWithUpdatedStop(cloEventList, updatedStop);

  const stateWithTerminalDrops = H.addTerminalDropsToCurrentRouteTels(stopId, data, state);
  const stateWithAllTerminals = H.addTerminalPickupsToCurrentRouteTels(stopId, data, stateWithTerminalDrops);

  return P.$all(
    P.$set(
      'currentRoute',
      H.addContainersToRouteTerminal(stateWithAllTerminals.currentRoute, stopId),
    ),
    P.$set('cloEventList', cloEventList),
    state,
  );
};

const addTerminalPickupToLoadPlannerTel = (state: Object, data: Object) => {
  const stopId = G.generateGuid();
  return H.addTerminalPickupsToCurrentRouteTels(stopId, data, state);
};

const addExistedTerminalToTel = (state: Object, data: Object) => {
  const { telGuid } = data;
  const stopId = R.path(['terminal'], data);
  const eventType = R.path(['eventType'], data);
  const location = H.getFirstTerminalEventLocationByStopIdFromState(stopId, state);
  const options = {
    location,
    eventType,
    loadGuid: telGuid,
  };
  const stopData = H.generateDataForNewTelStopFromStopForm(options, state);
  const newStopData = R.assoc('stopId', stopId, stopData);
  const stopDataToSet = H.createTerminalStopWithExistedStopId(newStopData);
  return P.$set(`currentRoute.tels.${data.telGuid}.events.${stopDataToSet.guid}`, stopDataToSet, state);
};

const removeStopFromRouteBuilderTel = (state: Object, data: Object) => {
  const itemsToFilter = R.prop('itemIds', data);
  const stopTel = R.path(['currentRoute', 'tels', data.telGuid], state);
  const tels = H.getFilteredTels(
    R.pathOr({}, ['currentRoute', 'tels'], state),
    stopTel,
    data,
    itemsToFilter,
  );
  const itemsToRemove = H.getItemsToRemove(itemsToFilter, tels);
  const deletedEventGuids = G.ifElse(
    data.isNew,
    state.deletedEventGuids,
    R.append(G.getGuidFromObject(data), state.deletedEventGuids),
  );
  return P.$all(
    P.$set('currentRoute.tels', tels),
    P.$set('deletedEventGuids', deletedEventGuids),
    P.$drop(`currentRoute.tels.${data.telGuid}.events.${data.guid}`),
    P.$set('currentRouteContainers', H.refreshRouteContainers(tels)),
    P.$set('currentRouteItems', R.omit(itemsToRemove, state.currentRouteItems)),
    state,
  );
};

const removeItemFromStop = (state: Object, { id, stop }: Object) => {
  const { currentRouteItems } = state;

  const { telGuid, stopType } = stop;

  const itemsToFilter = R.of(Array, id);
  const stopTel = R.path(['currentRoute', 'tels', telGuid], state);

  const tels = H.getFilteredTels(
    R.pathOr({}, ['currentRoute', 'tels'], state),
    stopTel,
    stop,
    itemsToFilter,
  );

  const itemsToRemove = H.getItemsToRemove(itemsToFilter, tels);

  if (R.and(G.isNilOrEmpty(itemsToRemove), G.isStopTypeTerminal(stopType))) {
    const currentItem = G.getPropFromObject(id, currentRouteItems);

    return P.$all(
      P.$set('currentRoute.tels', tels),
      P.$set(`currentRouteItems.${id}`, R.assoc(GC.FIELD_SELECTED, G.isStopPickup(stop), currentItem)),
      state,
    );
  }

  return P.$all(
    P.$set('currentRoute.tels', tels),
    P.$set('currentRouteItems', R.omit(itemsToRemove, currentRouteItems)),
    state,
  );
};

const removeCloItemFromStop = (state: Object, { stopGuid, cloGuid, telGuid }: Object) => (
  P.$all(
    P.$set(
      `currentRoute.tels.${telGuid}.events.${stopGuid}.items`,
      R.filter(
        ({ loadGuid }: Object) => G.notEquals(loadGuid, cloGuid),
        R.path(['currentRoute', 'tels', telGuid, GC.FIELD_LOAD_STOPS, stopGuid, 'items'], state),
      ),
    ),
    P.$set(
      `currentRoute.tels.${telGuid}.events.${stopGuid}.itemIds`,
      R.filter(
        (itemId: string) => G.notEquals(R.pathOr(null, ['currentRouteItems', itemId, 'loadGuid'], state), cloGuid),
        R.path(['currentRoute', 'tels', telGuid, GC.FIELD_LOAD_STOPS, stopGuid, 'itemIds'], state),
      ),
    ),
    state,
  )
);

const removeTrailerFromStop = (state: Object, { tel, stop, trailer, pickedUp }: Object) => {
  const telGuid = G.getGuidFromObject(tel);
  const stopGuid = G.getGuidFromObject(stop);
  const trailerGuid = G.getGuidFromObject(trailer);

  const trailersProp = G.ifElse(G.isTrue(pickedUp), 'pickedUpTrailers', 'droppedTrailers');
  const trailerGuidsProp = G.ifElse(G.isTrue(pickedUp), 'pickedUpTrailerGuids', 'droppedTrailerGuids');

  return P.$all(
    P.$set(
      `currentRoute.tels.${telGuid}.events.${stopGuid}.${trailerGuidsProp}`,
      R.reject(
        (guid: string) => R.equals(guid, trailerGuid),
        R.path(['currentRoute', 'tels', telGuid, GC.FIELD_LOAD_STOPS, stopGuid, trailerGuidsProp], state),
      ),
    ),
    P.$set(
      `currentRoute.tels.${telGuid}.events.${stopGuid}.${trailersProp}`,
      R.reject(
        ({ guid }: Object) => R.equals(guid, trailerGuid),
        R.path(['currentRoute', 'tels', telGuid, GC.FIELD_LOAD_STOPS, stopGuid, trailersProp], state),
      ),
    ),
    state,
  );
};

const recalculateTelDistancesSuccess = (state: Object, data: Object) => (
  P.$set(`currentRoute.tels.${data.guid}.events`, data.events, state)
);

const recalculateTelDistancesWithMoveItemSuccess = (state: Object, data: Object) => {
  const { guid, events, routeBuilderStore } = data;
  if (R.isNil(R.path(['routeErrors', guid, 'eventsErrors'], routeBuilderStore))) {
    return P.$set(`currentRoute.tels.${guid}.events`, events, routeBuilderStore);
  }
  return P.$all(
    P.$drop(`routeErrors.${guid}`),
    P.$set(`currentRoute.tels.${guid}.events`, events),
    routeBuilderStore,
  );
};

const cleanCloEventQuickFilter = (state: Object) => (
  P.$all(
    P.$set('cloEventFilterParams', {}),
    P.$set('cloEventPagination', initialState.cloEventPagination),
    P.$set('cloEventList', R.filter(R.propEq(true, 'selected'), state.cloEventList)),
    state,
  )
);

const setFilterProps = (state: Object, data: Array) => P.$set('filterProps', data, state);

const setCloEventQuickFilterParams = (state: Object, data: Object) => (
  P.$set('cloEventFilterParams', data, state)
);

const cleanTelQuickFilter = (state: Object) => (
  P.$all(
    P.$set('telList', []),
    P.$set('telFilterParams', {}),
    P.$set('telPagination', initialState.telPagination),
    state,
  )
);

const setTelQuickFilterParams = (state: Object, data: Object) => (
  P.$set('telFilterParams', data, state)
);

const resetCloEventListAndPagination = (state: Object) => (
  P.$all(
    P.$set('cloEventPagination', initialState.cloEventPagination),
    P.$set('cloEventList', R.filter(R.propEq(true, 'selected'), state.cloEventList)),
    state,
  )
);

const resetTelListAndPagination = (state: Object) => (
  P.$all(
    P.$set('telList', []),
    P.$set('telPagination', initialState.telPagination),
    state,
  )
);

const resetInboundTelListAndPagination = (state: Object) => (
  P.$all(
    P.$set('inboundTelList', []),
    P.$set('inboundTelPagination', initialState.inboundTelPagination),
    state,
  )
);

const resetOutboundTelListAndPagination = (state: Object) => (
  P.$all(
    P.$set('outboundTelList', []),
    P.$set('outboundTelPagination', initialState.outboundTelPagination),
    state,
  )
);

const resetAllListsAndPaginations = (state: Object) => (
  P.$all(
    P.$set('telList', []),
    P.$set('inboundTelList', []),
    P.$set('outboundTelList', []),
    P.$set('cloEventList', initialState.cloEventList),
    P.$set('telPagination', initialState.telPagination),
    P.$set('cloEventPagination', initialState.cloEventPagination),
    P.$set('inboundTelPagination', initialState.inboundTelPagination),
    P.$set('outboundTelPagination', initialState.outboundTelPagination),
    state,
  )
);

const setAvailableItems = (state: Object, data: Object) => (
  P.$all(
    P.$set('currentRouteItems', data),
    P.$set('currentRouteInitialItems', data),
    state,
  )
);

const setAvailableContainers = (state: Object, data: Object) => (
  P.$all(
    P.$set('currentRouteContainers', data),
    P.$set('currentRouteInitialContainers', data),
    state,
  )
);

const editItem = (state: Object, data: Object) => (
  P.$set(`currentRouteItems.${data.itemInternalId}`, data, state)
);

const updateContainer = (state: Object, data: Object) => (
  P.$set(`currentRouteContainers.${data.containerInternalId}`, data, state)
);

const addNewItemOnUI = (state: Object, props: Object) => {
  const { stop, data, telGuid, eventGuid } = props;

  const id = G.generateGuid();
  const isCloItem = G.isLoadTypeClo(stop);

  let item = R.mergeRight({
    guid: id,
    isNew: true,
    selected: false,
    itemInternalId: id,
  }, data);

  if (isCloItem) {
    const { cloGuid, telGuid, loadGuid } = stop;

    item = R.mergeRight(item, {
      cloGuid,
      telGuid,
      loadGuid,
      loadType: GC.LOAD_TYPE_CLO,
    });
  }

  return P.$all(
    P.$set(`currentRouteItems.${id}`, item),
    P.$add(`currentRoute.tels.${telGuid}.events.${eventGuid}.itemIds`, id),
    P.$add(`currentRoute.tels.${telGuid}.events.${eventGuid}.items`, item),
    state,
  );
};

const addItemsForDrop = (state: Object, data: Object) => {
  const itemIds = R.uniq(R.concat(
    R.pathOr(
      [],
      ['currentRoute', 'tels', data.telGuid, GC.FIELD_LOAD_STOPS, data.eventGuid, 'itemIds'],
      state,
    ),
    data.items,
  ));
  const items = R.map(
    (id: string) => R.path(['currentRouteItems', id], state),
    itemIds,
  );
  return P.$all(
    P.$set(`currentRoute.tels.${data.telGuid}.events.${data.eventGuid}.items`, items),
    P.$set(`currentRoute.tels.${data.telGuid}.events.${data.eventGuid}.itemIds`, itemIds),
    P.$set(
      'currentRouteItems',
      R.mergeRight(
        state.currentRouteItems,
        R.indexBy(
          R.prop('itemInternalId'),
          R.map(
            (id: string) => R.assoc('selected', true, state.currentRouteItems[id]),
            data.items,
          ),
        ),
      ),
    ),
    state,
  );
};

const addItemsClosForDrop = (state: Object, { stop, items, loadItems }: Object) => {
  const { guid, telGuid } = stop;
  const newState = P.$all(
    P.$set(
      'currentRouteItems',
      R.mergeRight(
        state.currentRouteItems,
        R.indexBy(
          R.prop(GC.FIELD_ITEM_INTERNAL_ID),
          R.map(
            (id: string) => R.assoc('selected', true, state.currentRouteItems[id]),
            items,
          ),
        ),
      ),
    ),
    P.$set(
      `currentRoute.tels.${telGuid}.events.${guid}.itemIds`,
      R.compose(
        R.uniq,
        R.concat(R.pathOr([], ['currentRoute', 'tels', telGuid, GC.FIELD_LOAD_STOPS, guid, 'itemIds'], state)),
        R.concat(items),
        R.map(R.prop(GC.FIELD_ITEM_INTERNAL_ID)),
      )(loadItems),
    ),
    P.$set(
      `currentRoute.tels.${telGuid}.events.${guid}.items`,
      loadItems,
    ),
    state,
  );
  if (R.isNil(R.path(['routeErrors'], state))) return newState;
  return P.$drop(`routeErrors.${telGuid}`, newState);
};

const addItemsForPickup = (state: Object, { items, telGuid, eventGuid }: Object) => {
  const { currentRoute, currentRouteItems } = state;

  const currentEvent = R.pathOr(
    [],
    [GC.SYSTEM_LIST_TELS, telGuid, GC.FIELD_LOAD_STOPS, eventGuid],
    currentRoute,
  );

  const newItems = R.map(
    (id: string) => R.assoc('selected', false, G.getPropFromObject(id, currentRouteItems)),
    items,
  );

  return (
    P.$all(
      P.$set(
        'currentRouteItems',
        R.mergeRight(currentRouteItems, R.indexBy(R.prop('itemInternalId'), newItems)),
      ),
      P.$set(
        `currentRoute.tels.${telGuid}.events.${eventGuid}.itemIds`,
        R.uniq(R.concat(R.pathOr([], [GC.FIELD_STOP_ITEM_IDS], currentEvent), items)),
      ),
      P.$set(
        `currentRoute.tels.${telGuid}.events.${eventGuid}.items`,
        R.uniq(R.concat(R.pathOr([], [GC.FIELD_STOP_ITEMS], currentEvent), newItems)),
      ),
      state,
    )
  );
};

const addItemsClosForPickup = (state: Object, { stop, items, loadItems }: Object) => {
  const { currentRoute, currentRouteItems } = state;

  const { guid, telGuid } = stop;

  const newItems = R.map(
    (id: string) => R.assoc('selected', false, G.getPropFromObject(id, currentRouteItems)),
    items,
  );

  return (
    P.$all(
      P.$set(
        'currentRouteItems',
        R.mergeRight(currentRouteItems, R.indexBy(R.prop(GC.FIELD_ITEM_INTERNAL_ID), newItems)),
      ),
      P.$set(
        `currentRoute.tels.${telGuid}.events.${guid}.itemIds`,
        R.compose(
          R.uniq,
          R.concat(R.pathOr([], ['tels', telGuid, GC.FIELD_LOAD_STOPS, guid, 'itemIds'], currentRoute)),
          R.concat(items),
          R.map(({ itemInternalId }: Object) => itemInternalId),
        )(loadItems),
      ),
      P.$set(
        `currentRoute.tels.${telGuid}.events.${guid}.items`,
        R.concat(R.uniqBy(R.prop(GC.FIELD_GUID), loadItems), newItems),
      ),
      state,
    )
  );
};

const cleanBuilder = (state: Object) => (
  P.$all(
    P.$set('editedClos', {}),
    P.$set('selectedTels', {}),
    P.$set('routeErrors', null),
    P.$set('currentRouteItems', {}),
    P.$set('globalFilterValue', ''),
    P.$set('deletedEventGuids', []),
    P.$set('unassignedCloEvents', []),
    P.$set('globalFilterValueET', ''),
    P.$set('globalFilterValueIT', ''),
    P.$set('globalFilterValueOT', ''),
    P.$set('branchGuidForRequest', null),
    P.$set('telList', H.uncheckAllItemsAndRemoveDetails(state.telList)),
    P.$set('inboundTelList', H.uncheckAllItemsAndRemoveDetails(state.inboundTelList)),
    P.$set('currentRoute', R.assoc(GC.FIELD_BRANCH_GUID, state.branchGuid, emptyRoute)),
    P.$set('outboundTelList', H.uncheckAllItemsAndRemoveDetails(state.outboundTelList)),
    P.$set('currentRouteInitialState', R.assoc(GC.FIELD_BRANCH_GUID, state.branchGuid, emptyRoute)),
    P.$set(
      'cloEventList',
      H.uncheckAllItemsRemoveDetailsAndRemovePlannedEvents(state.cloEventList, state.hidePlanned),
    ),
    state,
  )
);

const setCurrentRouteInitialState = (state: Object) => (
  P.$all(
    P.$set('currentRoute', state.currentRouteInitialState),
    P.$set('currentRouteItems', state.currentRouteInitialItems),
    state,
  )
);

const setRouteErrors = (state: Object, data: any) => (
  P.$set('routeErrors', data, state)
);

const addNewRateToLoadPlannerTel = (state: Object, data: Object) => (
  P.$set(`currentRoute.tels.${data.telGuid}.rate`, data.rate, state)
);

const cleanTelRate = (state: Object, guid: Object) => (
  P.$set(`currentRoute.tels.${guid}.rate`, null, state)
);

const getCrossDockLocationsSuccess = (state: Object, data: Array = []) => (
  P.$set(
    'crossDockLocationList',
    R.compose(
      R.indexBy(R.prop(GC.FIELD_TEMPLATE_ID)),
      R.map((loc: Object) => R.compose(
        R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR_2),
        R.assoc(
          GC.FIELD_CONTACTS,
          R.map(
            R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR),
            R.prop(GC.FIELD_CONTACTS, loc),
          ),
        ),
      )(loc)),
    )(data),
    state,
  )
);

const setInboundTerminals = (state: Object, data: Array) => (
  P.$set('inboundTerminals', data, state)
);

const setOutboundTerminals = (state: Object, data: Array) => (
  P.$set('outboundTerminals', data, state)
);

const removeTelFromPlanner = (state: Object, guid: string) => H.removeTelFromPlanner(state, guid);

const setPlannerMode = (state: Object, mode: string) => (
  P.$set('plannerMode', mode, state)
);

const toggleHidePlanned = (state: Object) => (
  P.$toggle('hidePlanned', state)
);

const deleteTel = (state: Object, data: Object) => H.handleDeleteTel(state, data);

const setGlobalFilterValue = (state: Object, data: string) => (
  P.$set('globalFilterValue', data, state)
);

const setGlobalFilterValueET = (state: Object, data: string) => (
  P.$set('globalFilterValueET', data, state)
);

const setGlobalFilterValueIT = (state: Object, data: string) => (
  P.$set('globalFilterValueIT', data, state)
);

const setGlobalFilterValueOT = (state: Object, data: string) => (
  P.$set('globalFilterValueOT', data, state)
);

const resetEditedCloEvents = (state: Object) => (
  P.$all(
    P.$set('editedClos', {}),
    P.$set('unassignedCloEvents', []),
    state,
  )
);

const getRateListByGuidsSuccess = (state: Object, data: Arary) => {
  const { currentRoute } = state;

  const rates = G.indexByGuid(data);

  const tels = R.compose(
    R.map((tel: Object) => {
      const rateGuid = R.path([GC.SYSTEM_OBJECT_RATE, GC.FIELD_GUID], tel);

      const rate = G.getPropFromObject(rateGuid, rates);

      if (G.isNotNil(rate)) {
        return R.assoc(GC.SYSTEM_OBJECT_RATE, rate, tel);
      }

      return tel;
    }),
    R.prop(GC.SYSTEM_LIST_TELS),
  )(currentRoute);

  return P.$set('currentRoute.tels', tels, state);
};

const getEventListByGuidsSuccess = (state: Object, data: Array) => {
  const { cloEventList, currentRoute, currentRouteItems } = state;

  const events = G.indexByGuid(data);
  const itemsFromEvents = R.indexBy(R.prop(GC.FIELD_ITEM_INTERNAL_ID), G.getItemsFromEventsUniqByGuid(data));

  const deletedRouteItemIds = R.compose(
    R.keys,
    R.filter(({ isNew }: Object) => G.isNilOrFalse(isNew)),
    R.pick(R.difference(R.keys(currentRouteItems), R.keys(itemsFromEvents))),
  )(currentRouteItems);

  const tels = R.compose(
    R.map((tel: Object) => R.assoc(
      GC.FIELD_LOAD_STOPS,
      R.compose(
        R.map((event: Object) => {
          const { guid, items } = event;

          const cloName = G.getPropFromObject(C.CLO_NAME, event);

          const matchEvent = G.getPropFromObject(guid, events);

          if (G.isNotNil(matchEvent)) {
            const itemsMergeData = H.getItemsMergeData({
              cloName,
              eventItems: R.filter(R.prop('isNew'), items),
              matchEventItems: G.getPropFromObject(GC.FIELD_STOP_ITEMS, matchEvent),
            });

            if (R.not(R.eqProps(GC.FIELD_VERSION, matchEvent, event))) {
              return R.mergeAll([matchEvent, itemsMergeData, R.pick([C.CLO_NAME, GC.FIELD_TEL_EVENT_INDEX], event)]);
            }

            return R.mergeRight(event, itemsMergeData);
          }

          if (G.isNotEmpty(deletedRouteItemIds)) {
            const eventItemsWithoutDeleted = R.compose(
              R.omit(deletedRouteItemIds),
              R.indexBy(R.prop(GC.FIELD_ITEM_INTERNAL_ID)),
            )(items);

            return R.mergeRight(event, {
              [GC.FIELD_STOP_ITEMS]: R.values(eventItemsWithoutDeleted),
              [GC.FIELD_STOP_ITEM_IDS]: R.keys(eventItemsWithoutDeleted),
            });
          }

          return event;
        }),
        R.prop(GC.FIELD_LOAD_STOPS),
      )(tel),
      tel,
    )),
    R.prop(GC.SYSTEM_LIST_TELS),
  )(currentRoute);

  const newCurrentRouteItems = H.getCurrentRouteItems({
    cloEventList,
    itemsFromEvents,
    currentRouteItems,
    deletedRouteItemIds,
  });

  return P.$all(
    P.$set('currentRoute.tels', tels),
    P.$set('currentRouteItems', newCurrentRouteItems),
    state,
  );
};

const setCloEventsTableTitleSort = (state: Object, data: Object) => {
  const { cloEventsTitleSortValues } = state;
  const sortValues = G.getTableTitleSortValues(cloEventsTitleSortValues, data);

  return P.$set('cloEventsTitleSortValues', sortValues, state);
};

const setTelTableTitleSort = (state: Object, data: Object) => {
  const { telTitleSortValues } = state;
  const sortValues = G.getTableTitleSortValues(telTitleSortValues, data);

  return P.$set('telTitleSortValues', sortValues, state);
};

const setInboundTableTitleSort = (state: Object, data: Object) => {
  const { inboundTitleSortValues } = state;
  const sortValues = G.getTableTitleSortValues(inboundTitleSortValues, data);

  return P.$set('inboundTitleSortValues', sortValues, state);
};

const setOutboundTableTitleSort = (state: Object, data: Object) => {
  const { outboundTitleSortValues } = state;
  const sortValues = G.getTableTitleSortValues(outboundTitleSortValues, data);

  return P.$set('outboundTitleSortValues', sortValues, state);
};

const setCloEventsTableTitleFilter = (state: Object, data: Object) => {
  const { cloEventsTableTitleFilters } = state;
  const filterValues = G.getTableTitleFilterValues(cloEventsTableTitleFilters, data);

  return P.$set('cloEventsTableTitleFilters', filterValues, state);
};

const setTelTableTitleFilter = (state: Object, data: Object) => {
  const { telTableTitleFilters } = state;
  const filterValues = G.getTableTitleFilterValues(telTableTitleFilters, data);

  return P.$set('telTableTitleFilters', filterValues, state);
};

const setInboundTableTitleFilter = (state: Object, data: Object) => {
  const { inboundTableTitleFilters } = state;
  const filterValues = G.getTableTitleFilterValues(inboundTableTitleFilters, data);

  return P.$set('inboundTableTitleFilters', filterValues, state);
};

const setOutboundTableTitleFilter = (state: Object, data: Object) => {
  const { outboundTableTitleFilters } = state;
  const filterValues = G.getTableTitleFilterValues(outboundTableTitleFilters, data);

  return P.$set('outboundTableTitleFilters', filterValues, state);
};

const setDeletedEventGuids = (state: Object, data: Array = []) => (
  P.$set('deletedEventGuids', data, state)
);

const addTrailersToStop = (state: Object, data: Object) => {
  const { tel, event, guids, trailers, pickedUp } = data;

  const telGuid = G.getGuidFromObject(tel);
  const eventGuid = G.getGuidFromObject(event);

  const trailersProp = G.ifElse(G.isTrue(pickedUp), 'pickedUpTrailers', 'droppedTrailers');
  const trailerGuidsProp = G.ifElse(G.isTrue(pickedUp), 'pickedUpTrailerGuids', 'droppedTrailerGuids');

  return P.$all(
    P.$set(
      `currentRoute.tels.${telGuid}.events.${eventGuid}.${trailersProp}`,
      R.concat(
        R.path(['currentRoute', 'tels', telGuid, 'events', eventGuid, trailersProp], state),
        trailers,
      ),
    ),
    P.$set(
      `currentRoute.tels.${telGuid}.events.${eventGuid}.${trailerGuidsProp}`,
      R.concat(
        R.path(['currentRoute', 'tels', telGuid, 'events', eventGuid, trailerGuidsProp], state),
        guids,
      ),
    ),
    state,
  );
};

const getCloTelsAndSetToStoreSuccess = (state: Object, { tels, cloGuid }: Object) => (
  P.$set(`telsByCloGuidList.${cloGuid}`, tels, state)
);

const setCloRelatedTelListLoading = (state: Object, data: boolean) => (
  P.$set('cloRelatedTelListLoading', data, state)
);

const getRouteTemplatesSuccess = (state: Object, data: Array) => (
  P.$set('routeTemplates', data, state)
);

const createRouteTemplateSuccess = (state: Object, data: Object) => (
  P.$set(`routeTemplates.${G.getGuidFromObject(data)}`, data, state)
);

// clo event list for map
const getCloEventsListForMapSuccess = (state: Object, data: Object) => (
  P.$set('cloEventListForMap', data.results, state)
);

const setCloEventListForMapLoading = (state: Object, data: boolean = false) => (
  P.$set('cloEventListForMapLoading', data, state)
);

const getStopInfoSuccess = (state: Object, data: Object) => (
  P.$set(`stopsInfoList.${G.getGuidFromObject(data)}`, data, state)
);

const uncheckItemsOnAllLists = (state: Object) => (
  P.$all(
    P.$set('telList', H.uncheckAllItemsAndRemoveDetails(state.telList)),
    P.$set('inboundTelList', H.uncheckAllItemsAndRemoveDetails(state.inboundTelList)),
    P.$set('outboundTelList', H.uncheckAllItemsAndRemoveDetails(state.outboundTelList)),
    P.$set(
      'cloEventList',
      H.uncheckAllItemsRemoveDetailsAndRemovePlannedEvents(state.cloEventList, state.hidePlanned),
    ),
    state,
  )
);

const cleanTelDetails = (state: Object, telGuid: string) => P.$set(
  'telList',
  R.map(
    (tel: Object) => {
      const { guid } = tel;

      if (R.equals(guid, telGuid)) return R.dissoc('details', tel);

      return tel;
    },
    state.telList,
  ),
  state,
);

const removeContainerFromStop = (state: Object, { id, stop, isPickupContainer }: Object) => {
  const containersToFilter = R.of(Array, id);
  const stopTel = R.path(['currentRoute', 'tels', stop.telGuid], state);

  const tels = H.getTelsFilteredFromContainers(
    R.pathOr({}, ['currentRoute', 'tels'], state),
    stopTel,
    stop,
    isPickupContainer,
    containersToFilter,
  );

  const telsToMap = R.compose(
    R.map((tel: Object) => R.assoc(GC.FIELD_LOAD_STOPS, R.values(R.prop(GC.FIELD_LOAD_STOPS, tel)), tel)),
    R.values,
  )(tels);

  return P.$all(
    P.$set('currentRoute.tels', tels),
    P.$set('currentRouteItems', H.mapRouteItems(telsToMap)),
    P.$set('currentRouteContainers', H.refreshRouteContainers(tels)),
    state,
  );
};

const addDropContainersToStop = (state: Object, data: Object) => {
  const { telGuid, eventGuid, droppedContainers, droppedContainerIds } = data;

  return P.$all(
    P.$set(
      `currentRoute.tels.${telGuid}.events.${eventGuid}.droppedContainers`,
      R.concat(
        R.path(['currentRoute', 'tels', telGuid, 'events', eventGuid, 'droppedContainers'], state),
        droppedContainers,
      ),
    ),
    P.$set(
      `currentRoute.tels.${telGuid}.events.${eventGuid}.droppedContainerIds`,
      R.concat(
        R.path(['currentRoute', 'tels', telGuid, 'events', eventGuid, 'droppedContainerIds'], state),
        droppedContainerIds,
      ),
    ),
    state,
  );
};

const addPickupContainersToStop = (state: Object, data: Object) => {
  const { telGuid, eventGuid, pickedUpContainers, pickedUpContainerIds } = data;

  return P.$all(
    P.$set(
      `currentRoute.tels.${telGuid}.events.${eventGuid}.pickedUpContainers`,
      R.concat(
        R.path(['currentRoute', 'tels', telGuid, 'events', eventGuid, 'pickedUpContainers'], state),
        pickedUpContainers,
      ),
    ),
    P.$set(
      `currentRoute.tels.${telGuid}.events.${eventGuid}.pickedUpContainerIds`,
      R.concat(
        R.path(['currentRoute', 'tels', telGuid, 'events', eventGuid, 'pickedUpContainerIds'], state),
        pickedUpContainerIds,
      ),
    ),
    state,
  );
};

const addNewContainerToStop = (state: Object, data: Object) => {
  const { telGuid, eventGuid, container } = data;

  const currentRouteContainers = R.assoc(
    R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, container),
    container,
    state.currentRouteContainers,
  );

  return P.$all(
    P.$set('currentRouteContainers', currentRouteContainers),
    P.$add(`currentRoute.tels.${telGuid}.events.${eventGuid}.pickedUpContainers`, container),
    P.$add(
      `currentRoute.tels.${telGuid}.events.${eventGuid}.pickedUpContainerIds`,
      R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, container),
    ),
    state,
  );
};

export default createReducer({
  [A.editItem]: editItem,
  [A.selectTel]: selectTel,
  [A.toggleTel]: toggleTel,
  [A.deleteTel]: deleteTel,
  [A.setTelList]: setTelList,
  [A.cleanBuilder]: cleanBuilder,
  [A.cleanTelRate]: cleanTelRate,
  [A.addTelsToList]: addTelsToList,
  [A.setTelDetails]: setTelDetails,
  [A.sortTelEvents]: sortTelEvents,
  [A.setTelReports]: setTelReports,
  [A.setRouteErrors]: setRouteErrors,
  [A.addNewItemOnUI]: addNewItemOnUI,
  [A.setFilterProps]: setFilterProps,
  [A.setPlannerMode]: setPlannerMode,
  [A.selectCloEvent]: selectCloEvent,
  [A.toggleCloEvent]: toggleCloEvent,
  [A.setCloEventList]: setCloEventList,
  [A.addItemsForDrop]: addItemsForDrop,
  [A.setCurrentRoute]: setCurrentRoute,
  [A.cleanTelDetails]: cleanTelDetails,
  [A.updateContainer]: updateContainer,
  [A.addTrailersToStop]: addTrailersToStop,
  [A.addItemsForPickup]: addItemsForPickup,
  [A.setTelListLoading]: setTelListLoading,
  [A.setAvailableItems]: setAvailableItems,
  [A.setInboundTelList]: setInboundTelList,
  [A.toggleHidePlanned]: toggleHidePlanned,
  [A.removeTelsFromList]: removeTelsFromList,
  [A.resetTelPagination]: resetTelPagination,
  [A.setSelectedTelList]: setSelectedTelList,
  [A.removeItemFromStop]: removeItemFromStop,
  [A.setOutboundTelList]: setOutboundTelList,
  [A.getStopInfoSuccess]: getStopInfoSuccess,
  [A.setCurrentTelReport]: setCurrentTelReport,
  [A.setCloEventsReports]: setCloEventsReports,
  [A.addItemsClosForDrop]: addItemsClosForDrop,
  [A.cleanTelQuickFilter]: cleanTelQuickFilter,
  [A.selectTelOnAllLists]: selectTelOnAllLists,
  [A.setInboundTerminals]: setInboundTerminals,
  [A.setTelTableTitleSort]: setTelTableTitleSort,
  [A.setOutboundTerminals]: setOutboundTerminals,
  [A.removeTelFromPlanner]: removeTelFromPlanner,
  [A.setGlobalFilterValue]: setGlobalFilterValue,
  [A.resetEditedCloEvents]: resetEditedCloEvents,
  [A.setDeletedEventGuids]: setDeletedEventGuids,
  [A.removeTrailerFromStop]: removeTrailerFromStop,
  [A.addItemsClosForPickup]: addItemsClosForPickup,
  [A.removeCloItemFromStop]: removeCloItemFromStop,
  [A.setInitialPlannerData]: setInitialPlannerData,
  [A.addNewContainerToStop]: addNewContainerToStop,
  [A.setTelTableTitleFilter]: setTelTableTitleFilter,
  [A.setAvailableCloReports]: setAvailableCloReports,
  [A.setAvailableTelReports]: setAvailableTelReports,
  [A.setCloEventListLoading]: setCloEventListLoading,
  [A.setInitialCurrentRoute]: setInitialCurrentRoute,
  [A.setGlobalFilterValueET]: setGlobalFilterValueET,
  [A.setGlobalFilterValueIT]: setGlobalFilterValueIT,
  [A.setGlobalFilterValueOT]: setGlobalFilterValueOT,
  [A.uncheckItemsOnAllLists]: uncheckItemsOnAllLists,
  [A.setAvailableContainers]: setAvailableContainers,
  [A.resetCloEventPagination]: resetCloEventPagination,
  [A.setSelectedCloEventList]: setSelectedCloEventList,
  [A.setTelQuickFilterParams]: setTelQuickFilterParams,
  [A.addNewTelToRouteBuilder]: addNewTelToRouteBuilder,
  [A.addExistedTerminalToTel]: addExistedTerminalToTel,
  [A.setBranchGuidForRequest]: setBranchGuidForRequest,
  [A.removeContainerFromStop]: removeContainerFromStop,
  [A.addDropContainersToStop]: addDropContainersToStop,
  [A.setInboundTableTitleSort]: setInboundTableTitleSort,
  [A.setCurrentCloEventReport]: setCurrentCloEventReport,
  [A.cleanCloEventQuickFilter]: cleanCloEventQuickFilter,
  [A.getConfigsByNamesSuccess]: getConfigsByNamesSuccess,
  [A.uncheckAndRemoveCloEvent]: uncheckAndRemoveCloEvent,
  [A.getRouteTemplatesSuccess]: getRouteTemplatesSuccess,
  [A.setInboundTelListLoading]: setInboundTelListLoading,
  [A.setOutboundTelListLoading]: setOutboundTelListLoading,
  [A.setOutboundTableTitleSort]: setOutboundTableTitleSort,
  [A.getCloEventDetailsSuccess]: getCloEventDetailsSuccess,
  [A.resetTelListAndPagination]: resetTelListAndPagination,
  [A.getRateListByGuidsSuccess]: getRateListByGuidsSuccess,
  [A.addPickupContainersToStop]: addPickupContainersToStop,
  [A.createRouteTemplateSuccess]: createRouteTemplateSuccess,
  [A.setInboundTableTitleFilter]: setInboundTableTitleFilter,
  [A.setCloEventsTableTitleSort]: setCloEventsTableTitleSort,
  [A.getEventListByGuidsSuccess]: getEventListByGuidsSuccess,
  [A.setCurrentInboundTelReport]: setCurrentInboundTelReport,
  [A.addNewRateToLoadPlannerTel]: addNewRateToLoadPlannerTel,
  [A.setOutboundTableTitleFilter]: setOutboundTableTitleFilter,
  [A.setCurrentRouteInitialState]: setCurrentRouteInitialState,
  [A.setRouteBuilderInitialState]: setRouteBuilderInitialState,
  [A.setCurrentOutboundTelReport]: setCurrentOutboundTelReport,
  [A.selectCloEventForTelSuccess]: selectCloEventForTelSuccess,
  [A.addUnassignedEventToPlanner]: addUnassignedEventToPlanner,
  [A.resetAllListsAndPaginations]: resetAllListsAndPaginations,
  [A.setCloRelatedTelListLoading]: setCloRelatedTelListLoading,
  [A.setCloEventsTableTitleFilter]: setCloEventsTableTitleFilter,
  [A.setCloEventQuickFilterParams]: setCloEventQuickFilterParams,
  [A.setCurrentTelReportWithRoute]: setCurrentTelReportWithRoute,
  [A.getCrossDockLocationsSuccess]: getCrossDockLocationsSuccess,
  [A.removeStopFromRouteBuilderTel]: removeStopFromRouteBuilderTel,
  [A.addTerminalToLoadPlannerTelUI]: addTerminalToLoadPlannerTelUI,
  [A.setInitPlannerDataFromTemplate]: setInitPlannerDataFromTemplate,
  [A.resetCloEventListAndPagination]: resetCloEventListAndPagination,
  [A.recalculateTelDistancesSuccess]: recalculateTelDistancesSuccess,
  [A.addNewCloStopToRouteBuilderTel]: addNewCloStopToRouteBuilderTel,
  [A.updateCloStopsOnPlannerSuccess]: updateCloStopsOnPlannerSuccess,
  [A.getCloTelsAndSetToStoreSuccess]: getCloTelsAndSetToStoreSuccess,
  [A.resetInboundTelListAndPagination]: resetInboundTelListAndPagination,
  [A.addTerminalPickupToLoadPlannerTel]: addTerminalPickupToLoadPlannerTel,
  [A.resetOutboundTelListAndPagination]: resetOutboundTelListAndPagination,
  [A.updateStopOnRouteBuilderTelSuccess]: updateStopOnRouteBuilderTelSuccess,
  [A.addNewStopToRouteBuilderTelSuccess]: addNewStopToRouteBuilderTelSuccess,
  [A.addCloEventToInterTerminalTelSuccess]: addCloEventToInterTerminalTelSuccess,
  [A.setDispatchPlannerEventsOpenedFromPage]: setDispatchPlannerEventsOpenedFromPage,
  [A.recalculateTelDistancesWithMoveItemSuccess]: recalculateTelDistancesWithMoveItemSuccess,
  // clo event list for map
  [A.setCloEventListForMapLoading]: setCloEventListForMapLoading,
  [A.getCloEventsListForMapSuccess]: getCloEventsListForMapSuccess,
}, initialState);

import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import { getStatusMessageCodeStatusTypeOptions } from '../../../../helpers/options';
// feature configs
import { isBooleanPlaceholder } from '../../helpers';
import { MAIL_TEMPLATE_PLACEHOLDERS_MAP } from '../../constants';
//////////////////////////////////////////////////

export const defaultStatusMessageFields = {
  [GC.FIELD_STATUS_TYPE]: '',
  [GC.FIELD_STORED_VALUE]: '',
  [GC.FIELD_DISPLAYED_VALUE]: '',
  [GC.FIELD_STATUS_MESSAGE_TRACKING]: false,
  [GC.FIELD_STATUS_MESSAGE_REASON_CODES]: [],
  [GC.FIELD_STATUS_MESSAGE_WARNING_LEVEL]: '',
  [GC.FIELD_STATUS_MESSAGE_REASON_REQUIRED]: false,
};

export const statusMessageValidationSchema = () => Yup.lazy(({ reasonRequired }: Object) => {
  let schema = {
    [GC.FIELD_STORED_VALUE]: G.yupStringRequired,
    [GC.FIELD_DISPLAYED_VALUE]: G.yupStringRequired,
  };

  if (G.isTrue(reasonRequired)) {
    schema = R.assoc(GC.FIELD_STATUS_MESSAGE_REASON_CODES, G.yupArrayRequired, schema);
  }

  return Yup.object().shape(schema);
});

const statusMessageInputWrapperStyles = {
  mb: 25,
  width: 300,
};

export const getStatusMessageFieldSettings = (reasonRequired: boolean) => {
  const fields = [
    {
      type: 'text',
      isRequired: true,
      fieldName: GC.FIELD_STORED_VALUE,
      label: ['titles:stored-value', 'Stored Value'],
      inputWrapperStyles: statusMessageInputWrapperStyles,
    },
    {
      type: 'text',
      isRequired: true,
      fieldName: GC.FIELD_DISPLAYED_VALUE,
      label: ['titles:default-value', 'Default Value'],
      inputWrapperStyles: statusMessageInputWrapperStyles,
    },
    {
      type: 'reactSelect',
      fieldName: GC.FIELD_STATUS_TYPE,
      label: ['titles:stop-status', 'Stop Status'],
      options: getStatusMessageCodeStatusTypeOptions(),
      inputWrapperStyles: statusMessageInputWrapperStyles,
    },
    {
      type: 'reactSelect',
      fieldName: GC.FIELD_STATUS_MESSAGE_WARNING_LEVEL,
      label: ['titles:warning-level', 'Warning Level'],
      inputWrapperStyles: statusMessageInputWrapperStyles,
      options: [
        {
          value: GC.STATUS_MESSAGE_WARNING_LEVEL_TYPE_WARNING,
          label: G.getWindowLocale('titles:warning', 'Warning'),
        },
        {
          value: GC.STATUS_MESSAGE_WARNING_LEVEL_TYPE_CRITICAL,
          label: G.getWindowLocale('titles:critical', 'Critical'),
        },
      ],
    },
    {
      type: 'toggle',
      label: ['titles:tracking', 'Tracking'],
      fieldName: GC.FIELD_STATUS_MESSAGE_TRACKING,
      inputWrapperStyles: statusMessageInputWrapperStyles,
    },
    {
      type: 'toggle',
      fieldName: GC.FIELD_STATUS_MESSAGE_REASON_REQUIRED,
      inputWrapperStyles: statusMessageInputWrapperStyles,
      label: ['titles:reason-required', 'Reason Required'],
    },
  ];

  if (G.isTrue(reasonRequired)) {
    return R.append({
      isMulti: true,
      isRequired: true,
      type: 'reactSelect',
      options: 'reasonsOptions',
      useMenuPortalTarget: true,
      inputWrapperStyles: { width: 300 },
      fieldName: GC.FIELD_STATUS_MESSAGE_REASON_CODES,
      label: ['titles:status-reason', 'Status Reason'],
    }, fields);
  }

  return fields;
};

export const getDefaultMailTemplateFields = (props: Object) => {
  const { mailTemplate, templateType, templateNameKey } = props;

  let defaultMailTemplateFields = {
    [GC.FIELD_NAME]: '',
    [GC.FIELD_TEMPLATE_TYPE]: R.or(templateType, ''),
    [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_REFERENCE_TYPES]: [],
    [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_TEMPLATE_TEMPLATE_KEY]: R.or(templateNameKey, ''),
    [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_SUBJECT]:
      R.pathOr('', ['defaultMailSubject'], mailTemplate),
  };

  R.map(
    ({ key, defaultValue }: Object) => {
      const getDefaultValueToUse = () => {
        if (isBooleanPlaceholder(key)) return R.equals(defaultValue, 'yes');

        return R.or(defaultValue, '');
      };

      defaultMailTemplateFields = R.assoc(
        key,
        getDefaultValueToUse(),
        defaultMailTemplateFields,
      );

      return defaultMailTemplateFields;
    },
    R.pathOr([], ['placeholders'], mailTemplate),
  );

  return defaultMailTemplateFields;
};

const getValidationSchemaString = (key: string) => {
  if (R.includes(key, [MAIL_TEMPLATE_PLACEHOLDERS_MAP.BODY, MAIL_TEMPLATE_PLACEHOLDERS_MAP.FAREWELL])) {
    return Yup.string().nullable(true).max(2000, G.getShouldBeFromToCharLocaleTxt(0, 2000));
  }

  if (R.includes(key, [MAIL_TEMPLATE_PLACEHOLDERS_MAP.ADDRESS1, MAIL_TEMPLATE_PLACEHOLDERS_MAP.ADDRESS2])) {
    return Yup.string().nullable(true).max(255, G.getShouldBeFromToCharLocaleTxt(0, 255));
  }

  if (R.includes(key, [MAIL_TEMPLATE_PLACEHOLDERS_MAP.EMAIL])) {
    return Yup.string()
      .nullable(true)
      .email(G.getFieldIsNotValidEmailTxt())
      .max(255, G.getShouldBeFromToCharLocaleTxt(0, 255));
  }

  if (R.includes(key, [MAIL_TEMPLATE_PLACEHOLDERS_MAP.PHONE])) {
    return Yup.string()
      .nullable(true)
      .max(15, G.getShouldBeFromToCharLocaleTxt(0, 15))
      .matches(GC.PHONE_NUMBER_REGEXP, G.getShouldBePhoneLocaleTxt());
  }

  return Yup.string()
    .nullable(true)
    .max(50, G.getShouldBeFromToLocaleTxt(0, 50));
};

export const getMailTemplateValidationSchema = (mailTemplate: Object) => {
  let schema = {
    [GC.FIELD_TEMPLATE_TYPE]: G.yupStringRequired,
    [GC.FIELD_NAME]: G.yupStringRequired.max(50, G.getShouldBeFromToCharLocaleTxt(1, 50)),
    [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_TEMPLATE_TEMPLATE_KEY]: G.yupStringRequired,
    [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_SUBJECT]: G.yupStringRequired
      .max(100, G.getShouldBeFromToCharLocaleTxt(1, 100)),
  };

  R.map(
    ({ key, required }: Object) => {
      let keySchema = getValidationSchemaString(key);

      if (G.isTrue(required)) keySchema = keySchema.required(G.getRequiredLocaleTxt());

      schema = R.assoc(key, keySchema, schema);

      return schema;
    },
    R.pathOr([], ['placeholders'], mailTemplate),
  );

  return Yup.object().shape(schema);
};

const inputWrapperStyles = {
  mb: 25,
  width: 220,
};

export const getMailTemplateFieldSettings = ({ disabled, templateNameKey }: Object) => {
  const fields = [
    {
      disabled,
      type: 'select',
      isRequired: true,
      shouldCustomChange: true,
      fieldName: GC.FIELD_TEMPLATE_TYPE,
      options: 'mailTemplateTypesOptions',
      label: ['titles:object-trigger', 'Object Trigger'],
      inputWrapperStyles: { ...inputWrapperStyles, mr: 20 },
      customChangeHandler: (event: Object, _: Object, props: Object) => {
        const { handleChange, setTemplateType, setTemplateNameKey } = props;

        handleChange(event);

        setTemplateNameKey('');
        setTemplateType(R.pathOr('', ['currentTarget', GC.FIELD_VALUE], event));
      },
    },
    {
      type: 'select',
      isRequired: true,
      shouldCustomChange: true,
      options: 'mailTemplateNamesOptions',
      label: ['titles:template', 'Template'],
      customDisabledFunction: 'handleDisableTemplateName',
      inputWrapperStyles: { ...inputWrapperStyles, mr: 20 },
      fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_TEMPLATE_TEMPLATE_KEY,
      customChangeHandler: (event: Object, _: Object, props: Object) => {
        const { handleChange, setTemplateNameKey, setReferenceVariables, setInterpolationVariables } = props;

        handleChange(event);

        setReferenceVariables([]);
        setInterpolationVariables([]);
        setTemplateNameKey(R.pathOr('', ['currentTarget', GC.FIELD_VALUE], event));
      },
    },
  ];

  if (G.isNotNilAndNotEmpty(templateNameKey)) {
    return R.concat(
      fields,
      [
        {
          type: 'text',
          isRequired: true,
          inputWrapperStyles,
          fieldName: GC.FIELD_NAME,
          label: ['titles:template-name', 'Template Name'],
        },
        {
          type: 'text',
          isRequired: true,
          inputWrapperStyles: { mb: 25, width: 700 },
          label: ['titles:mail-subject', 'Mail Subject'],
          fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_SUBJECT,
        },
      ],
    );
  }

  return fields;
};

export const referencesField = [
  {
    isMulti: true,
    type: 'reactSelect',
    shouldCustomChange: true,
    options: GC.FIELD_REFERENCES,
    label: ['titles:references', 'References'],
    inputWrapperStyles: { ...inputWrapperStyles, zIndex: 13 },
    fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_REFERENCE_TYPES,
  },
];

export const getMainGroupFields = (placeholders: Array) => R.map(
  ({ key, required, displayedName }: Object) => {
    const isBooleanValue = isBooleanPlaceholder(key);
    const isBody = R.equals(key, MAIL_TEMPLATE_PLACEHOLDERS_MAP.BODY);
    const isFarewell = R.equals(key, MAIL_TEMPLATE_PLACEHOLDERS_MAP.FAREWELL);

    const editorInputWrapperStyles = {
      mb: 25,
      width: '100%',
      zIndex: G.ifElse(isBody, 12, 11),
    };

    const getType = () => {
      if (R.or(isBody, isFarewell)) return 'editor';

      if (G.isTrue(isBooleanValue)) return 'toggle';

      return 'text';
    };

    return {
      fieldName: key,
      type: getType(),
      isRequired: required,
      shouldCustomChange: R.or(isBody, isFarewell),
      label: [`titles:${displayedName}`, displayedName],
      inputWrapperStyles: G.ifElse(
        R.or(isBody, isFarewell),
        editorInputWrapperStyles,
        { ...inputWrapperStyles, mr: 20 },
      ),
    };
  },
  placeholders,
);

export const getSignatureGroupFields = (placeholders: Array) => R.map(
  ({ key, required, displayedName }: Object) => ({
    fieldName: key,
    inputWrapperStyles,
    isRequired: required,
    label: [`titles:${displayedName}`, displayedName],
    type: G.ifElse(R.equals(key, MAIL_TEMPLATE_PLACEHOLDERS_MAP.PHONE), 'phoneNumber', 'text'),
  }),
  placeholders,
);

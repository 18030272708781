import * as R from 'ramda';
import React, { useCallback } from 'react';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// utilities
import routesMap from '../../../../utilities/routes';
// ui
import { Box, Flex } from '../../../../ui';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.light.blue');

const GoToConfiguration = (props: Object) => {
  const { entityType } = props;

  const configurationTabUri = R.equals(entityType, 'trailer') ?
  `${routesMap.configTrailer}?tab=${GC.TAB_FLEET_TRAILER_MAINTENANCE}` :
  `${routesMap.configTruck}?tab=${GC.TAB_FLEET_TRUCK_MAINTENANCE}`;

  const goToConfiguration = useCallback(() => G.goToRoute(configurationTabUri), []);

  return (
    <Flex m='0px 15px' justifyContent='center'>
      <Box mr={15}>
        {G.getWindowLocale('titles:have-to-add-document-type-config', 'Have to add document type config')}
      </Box>
      <Box
        cursor='pointer'
        color={blueColor}
        onClick={goToConfiguration}
      >
        {G.getWindowLocale('actions:go-to-configuration', 'Go to configuration')}
      </Box>
    </Flex>
  );
};

export default GoToConfiguration;

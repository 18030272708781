import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
// feature configs
import {
  fields,
  defaultFields,
  getRowOptions,
  validationSchemaObject,
} from '../settings';
//////////////////////////////////////////////////

export const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultFields,
      props.initialValues,
    ),
    validationSchema: Yup.object().shape(validationSchemaObject),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
  }),
  pure,
);

const StatusActionsConfigForm = (props: Object) => {
  const {
    values,
    loadType,
    handleSubmit,
    statusOptions,
  } = props;

  const optionsForSelect = {
    statusOptions,
    firstRowOptions: getRowOptions(values, loadType, true),
    secondRowOptions: getRowOptions(values, loadType, false),
  };

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...optionsForSelect}
        {...G.getFormikProps(props)}
        fields={fields}
        fieldsWrapperStyles={{ pt: 11, justifyContent: 'space-between' }}
      />
      <FormFooter />
    </form>
  );
};

export default enhance(StatusActionsConfigForm);

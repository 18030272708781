import React from 'react';
// forms
import { Fieldset2 } from '../../../../forms/formik';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box } from '../../../../ui';
//////////////////////////////////////////////////

const fields = [
  {
    isMulti: true,
    type: 'reactSelect',
    fieldName: 'notifyUsers',
    options: 'userGeneralListFullNameOptions',
    inputWrapperStyles: { mr: 15, width: 300 },
    label: ['titles:notify-users', 'Notify Users'],
  },
  {
    type: 'multiEmail',
    shouldOverrideEmails: true,
    fieldName: GC.FIELD_MAIL_TO,
    inputWrapperStyles: { width: 300 },
    label: ['titles:notify-emails', 'Notify Emails'],
  },
];

const Notify = (props: Object) => {
  const {
    userGeneralListFullNameOptions,
  } = props;

  return (
    <Box p={15}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={fields}
        userGeneralListFullNameOptions={userGeneralListFullNameOptions}
        fieldsWrapperStyles={{ px: 0, justifyContent: 'space-between' }}
      />
    </Box>
  );
};

export default Notify;

import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers, withPropsOnChange } from 'react-recompose';
// components
import { ActionBox } from '../../../components/action-box';
import { ConfirmComponent } from '../../../components/confirm';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncGetFleetVendorCompanyNameList } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Flex, IconWrapper } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature fleet-profile
import SimpleForm from '../forms';
import { makeSelectFleetVendorList } from '../selectors';
import {
  getFleetVendorListSuccess,
  assignVendorToDriverRequest,
  assignOrUnAssignVendorRequest,
  unAssignVendorFromDriverRequest,
} from '../actions';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');
const lightBlueColor = G.getTheme('colors.light.blue');

const wrapperStyles = {
  mr: 10,
  p: '5px',
  height: 30,
  border: '1px solid',
  borderRadius: '4px',
  whiteSpace: 'nowrap',
  color: lightBlueColor,
  textTransform: 'uppercase',
  borderColor: darkBlueColor,
  bg: G.getTheme('colors.white'),
};

const AssignVendor = (props: Object) => {
  const {
    vendorName,
    fleetVendorGuid,
    handleAssignVendor,
    handleUnassignVendor,
    handleGoToProfileByProfileType,
  } = props;

  if (G.isNilOrEmpty(fleetVendorGuid)) {
    return (
      <Flex
        {...wrapperStyles}
        cursor='pointer'
        fontWeight='bold'
        onClick={() => handleAssignVendor()}
      >
        {G.getWindowLocale('titles:assign', 'Assign')} {G.getWindowLocale('titles:vendor', 'Vendor')}
      </Flex>
    );
  }

  return (
    <Flex {...wrapperStyles}>
      <ActionBox
        boxStyles={{ maxWidth: 250, fontWeight: 'bold' }}
        text={`${G.getWindowLocale('titles:vendor', 'Vendor')} ${vendorName}`}
        action={() => handleGoToProfileByProfileType({ type: 'vendor', guid: fleetVendorGuid })}
      />
      <IconWrapper ml={10} cursor='pointer' onClick={() => handleAssignVendor()}>
        {I.pencil(darkBlueColor)}
      </IconWrapper>
      <IconWrapper ml={10} cursor='pointer' onClick={() => handleUnassignVendor()}>
        {I.trash(darkBlueColor)}
      </IconWrapper>
    </Flex>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  fleetVendorList: makeSelectFleetVendorList(state),
});

const inputWrapperStyles = { mb: 25, width: 270 };

const vendorField = {
  isRequired: true,
  type: 'reactSelect',
  options: 'vendorOptions',
  fieldName: GC.FIELD_VENDOR_GUID,
  label: ['titles:select-vendor', 'Select Vendor'],
  inputWrapperStyles: R.assoc('zIndex', 12, inputWrapperStyles),
};

const assignVendorToDriverFields = [
  vendorField,
  {
    type: 'toggle',
    inputWrapperStyles,
    fieldName: GC.FIELD_PAYABLE,
    label: ['titles:create-driver-invoice', 'Create Driver Invoice'],
  },
];

const validationSchema = Yup.object().shape({ [GC.FIELD_VENDOR_GUID]: G.yupStringRequired });

const enhance = compose(
  connect(
    mapStateToProps,
    {
      getFleetVendorListSuccess,
      assignVendorToDriverRequest,
      assignOrUnAssignVendorRequest,
      unAssignVendorFromDriverRequest,
    },
  ),
  withAsyncGetFleetVendorCompanyNameList,
  withPropsOnChange(['fleetVendorList', 'fleetVendorGuid'], ({ fleetVendorList, fleetVendorGuid }: Object) => {
    if (G.isNilOrEmpty(fleetVendorList)) return;

    const vendorOptions = G.mapNameGuidObjectPropsToLabelValueObject(fleetVendorList);

    const vendorName = R.compose(
      R.pathOr(null, [GC.FIELD_NAME]),
      R.find(R.propEq(fleetVendorGuid, GC.FIELD_GUID)),
    )(fleetVendorList);

    return { vendorName, vendorOptions };
  }),
  withHandlers({
    handleAssignVendor: (props: Object) => () => {
      const {
        type,
        payable,
        openModal,
        vendorOptions,
        fleetVendorGuid,
        primaryObjectGuid,
        assignVendorToDriverRequest,
        assignOrUnAssignVendorRequest,
      } = props;

      const isDriver = R.equals(type, 'driver');

      const submitAction = (values: Object, options: Object) => {
        const { vendorGuid } = values;

        if (isDriver) return assignVendorToDriverRequest(values);

        assignOrUnAssignVendorRequest({
          ...options,
          type,
          vendorGuid,
          primaryObjectGuid,
        });
      };

      const component = (
        <SimpleForm
          submitAction={submitAction}
          validationSchema={validationSchema}
          optionsForSelect={{ vendorOptions }}
          initialValues={{ payable, [GC.FIELD_VENDOR_GUID]: fleetVendorGuid }}
          fieldSettings={G.ifElse(isDriver, assignVendorToDriverFields, R.of(Array, vendorField))}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: G.getWindowLocale('titles:assign-vendor', 'Assign Vendor'),
        },
      };

      openModal(modal);
    },
    handleUnassignVendor: (props: Object) => () => {
      const {
        type,
        openModal,
        vendorName,
        fleetVendorGuid,
        primaryObjectGuid,
        assignOrUnAssignVendorRequest,
        unAssignVendorFromDriverRequest,
      } = props;


      const textLocale = G.getWindowLocale(
        'messages:driver-unassign:confirmation:text',
        'Are you sure, you want unassign',
      );

      const component = <ConfirmComponent name={vendorName} textLocale={textLocale} />;

      const action = () => {
        if (R.equals(type, 'driver')) {
          return unAssignVendorFromDriverRequest(fleetVendorGuid);
        }

        assignOrUnAssignVendorRequest({
          type,
          unassign: true,
          primaryObjectGuid,
          [GC.FIELD_VENDOR_GUID]: fleetVendorGuid,
        });
      };

      const modal = {
        component,
        options: {
          width: 600,
          title: `${G.getWindowLocale('titles:unassign', 'Unassign')} ${
            G.getWindowLocale('titles:vendor', 'Vendor')}`,
          controlButtons: [
            {
              action,
              type: 'button',
              name: G.getWindowLocale('actions:submit', 'Submit'),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export default enhance(AssignVendor);

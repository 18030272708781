import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import React, { useState } from 'react';
import {
  pure,
  compose,
  withProps,
  withState,
  lifecycle,
  withHandlers,
} from 'react-recompose';
// components
import { FormFooter2 } from '../../../../components/form-footer';
// features
import { validationSchemaCostsObject } from '../../../work-order/components/costs-section';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import {
  withAsyncConfigs,
  withAsyncSequence,
  withAsyncInvoiceStatusConfigs,
  withAsyncGetServiceVendorListAvailable,
  withAsyncGetUserGeneralListFullNameOnDidMount,
} from '../../../../hocs';
// hooks
import { useAiRecognize } from '../../../../hooks';
// ui
import { Flex, scrollableContainerCss4px } from '../../../../ui';
// feature fleet
import {
  withAddServiceVendor,
  withAsyncGetEquipmentServiceByEntityTypeAndServiceGuid,
  withAsyncGetAvailableForServiceEquipmentComponentsByEntityType,
} from '../../equipment-service/hocs';
import {
  serviceFieldSettings,
  invoiceFieldSettings,
  serviceDefaultFields,
  invoiceDefaultFields2,
} from '../../equipment-service/settings/field-settings';
//
import LeftSection from './left-section';
import RightSection from './right-section';
import UpdateRightSection from './update-right-section';
import { withMaintenanceNotes } from '../hooks/use-maintenance-notes';
//////////////////////////////////////////////////

// TODO: with edit mode

const fieldNotRequired = Yup.string().nullable(true);
const fieldRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

const chargesValidationSchema = Yup.array().of(Yup.object().shape({
  [GC.FIELD_TOTAL]: G.yupNumberRequired,
  [GC.FIELD_DISPLAYED_NAME]: fieldRequired,
}));

const validationSchema = Yup.lazy((values: Object) => {
  const { performedDate, serviceVendorType } = values;

  let schema = R.map(
    ({ isRequired }: Object) => G.ifElse(isRequired, fieldRequired),
    serviceFieldSettings,
  );

  if (G.isNotNilAndNotEmpty(performedDate)) {
    schema = {
      ...schema,
      [GC.FIELD_SERVICE_VENDOR_GUID]: fieldRequired,
      [GC.SYSTEM_OBJECT_INVOICE]: Yup.object()
        .shape(validationSchemaCostsObject)
        .shape({
          ...R.map(() => fieldNotRequired, invoiceFieldSettings),
          [GC.FIELD_CURRENCY]: fieldRequired,
          [GC.FIELD_CHARGES]: chargesValidationSchema,
          [GC.FIELD_NET_DAYS]: Yup.number()
            .nullable(true)
            .min(0, G.getShouldBePositiveLocaleTxt())
            .typeError(G.getShouldBeNumericLocaleTxt())
            .max(10000, G.getShouldBeFromToLocaleTxt(0, 10000)),
          [GC.FIELD_INVOICE_NUMBER]: G.ifElse(
            R.equals(serviceVendorType, GC.SERVICE_VENDOR_TYPE_FLEET_SERVICE),
            fieldRequired,
            fieldNotRequired,
          ),
          [GC.FIELD_INVOICE_DATE]: G.ifElse(
            R.equals(serviceVendorType, GC.SERVICE_VENDOR_TYPE_FLEET_SERVICE),
            fieldRequired,
            fieldNotRequired,
          ),
        }),
    };
  }

  return Yup.object().shape(schema);
});

const setInitialValues = (equipmentService: Object) => {
  if (G.isNilOrEmpty(equipmentService)) return null;

  const { invoice } = equipmentService;

  const serviceType = R.pathOr(null, [GC.FIELD_SERVICE_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], equipmentService);

  if (G.isNilOrEmpty(invoice)) {
    return R.assoc(GC.FIELD_SERVICE_TYPE, serviceType, equipmentService);
  }

  const status = R.pathOr(null, [GC.SYSTEM_OBJECT_INVOICE, GC.FIELD_STATUS, GC.FIELD_CONFIG_GUID], equipmentService);
  const glCode = R.pathOr(
    null,
    [GC.SYSTEM_OBJECT_INVOICE, GC.FIELD_GL_CODE, GC.FIELD_DROPDOWN_OPTION_GUID],
    equipmentService,
  );

  return {
    ...equipmentService,
    serviceType,
    serviceVendorType: R.pathOr(null, [GC.SYSTEM_OBJECT_SERVICE_VENDOR, GC.FIELD_TYPE], equipmentService),
    [GC.SYSTEM_OBJECT_INVOICE]: {
      ...invoice,
      status,
      glCode,
    },
  };
};

const serviceVendorTypes = R.join(
  ',',
  [GC.SERVICE_VENDOR_TYPE_FLEET_SERVICE, GC.SERVICE_VENDOR_TYPE_FLEET_SELF_SERVICE],
);

const makeFilesFormDataAndDocumentTypes = (data: Array) => {
  if (G.isNilOrEmpty(data)) return { files: [], documentTypes: [] };

  const filteredData = R.reject(({ file, documentType }: Object) =>
    R.or(G.isNilOrEmpty(file), G.isNilOrEmpty(documentType)), data);

  const files = [];

  const documentTypes = [];

  if (G.isNotNilAndNotEmpty(filteredData)) {
    filteredData.forEach(({ file, documentType }: any) => {
      files.push(file);

      documentTypes.push(documentType);
    });
  }

  return { files, documentTypes };
};

const enhance = compose(
  withState('aiUploadDoc', 'setAiUploadDoc', null),
  withState('maintenanceDocs', 'setMaintenanceDocs', null),
  withProps(() => ({
    type: 'fleetService',
    configsNamesArray: [
      GC.INVOICE_GL_CODE,
      GC.FLEET_EQUIPMENT_SERVICE_TYPE,
      GC.GENERAL_BRANCH_DEFAULT_CURRENCY,
      GC.SERVICE_VENDOR_VENDOR_SERVICE_TYPE,
      GC.TRUCK_MAINTENANCE_AI_UPLOAD_DEFAULT_DOCUMENT_TYPE,
      GC.TRUCK_MAINTENANCE_DOCUMENTS_DEFAULT_DOCUMENT_TYPE,
      GC.TRAILER_MAINTENANCE_AI_UPLOAD_DEFAULT_DOCUMENT_TYPE,
      GC.TRAILER_MAINTENANCE_DOCUMENTS_DEFAULT_DOCUMENT_TYPE,
    ],
  })),
  withAsyncConfigs,
  withAsyncInvoiceStatusConfigs,
  withAsyncGetUserGeneralListFullNameOnDidMount,
  withAsyncGetServiceVendorListAvailable(serviceVendorTypes),
  withAsyncGetAvailableForServiceEquipmentComponentsByEntityType,
  withFormik({
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues, asyncSequence, equipmentService }: Object) => {
      const defaultFields = G.isNotNilAndNotEmpty(asyncSequence) ?
        {...serviceDefaultFields, [GC.FIELD_SERVICE_ID]: asyncSequence, [GC.SYSTEM_OBJECT_INVOICE]: null } :
        R.assoc(GC.SYSTEM_OBJECT_INVOICE, null, serviceDefaultFields);

      return G.setInitialFormikValues(defaultFields, initialValues, setInitialValues(equipmentService));
    },
    handleSubmit: (values: Object, { props }: Object) => {
      const {
        isEditMode,
        submitAction,
        asyncSequence,
        maintenanceDocs,
        maintenanceNotes,
      } = props;

      let valuesToUse = G.mapObjectEmptyStringFieldsToNull(values);

      if (R.pathEq(asyncSequence, [GC.FIELD_SERVICE_ID], values)) {
        valuesToUse = R.assoc(GC.FIELD_SERVICE_ID, null, valuesToUse);
      }

      if (R.and(R.not(isEditMode), G.isNotNilAndNotEmpty(maintenanceNotes))) {
        valuesToUse = R.assoc('notes', maintenanceNotes, valuesToUse);
      }

      if (G.isNotNilAndNotEmpty(maintenanceDocs)) {
        const { files, documentTypes } = makeFilesFormDataAndDocumentTypes(maintenanceDocs);

        valuesToUse = { ...valuesToUse, files, documentTypes };
      }

      submitAction(valuesToUse);
    },
  }),
  withHandlers({
    handleToggleInvoiceSection: (props: Object) => () => {
      const { values, touched, asyncConfigs, setTouched, setFieldValue } = props;

      if (G.isNilOrEmpty(R.prop(GC.SYSTEM_OBJECT_INVOICE, values))) {
        if (R.propEq(true, GC.SYSTEM_OBJECT_INVOICE, touched)) {
          const invoiceFieldsTouched = R.mapObjIndexed((value: any, key: string) => {
            if (R.equals(key, GC.FIELD_CHARGES)) {
              return R.map(() => ({
                [GC.FIELD_TOTAL]: true,
                [GC.FIELD_COMMENTS]: true,
                [GC.FIELD_DISPLAYED_NAME]: true,
              }), value);
            }

            return true;
          }, invoiceDefaultFields2);

          setTouched(R.assoc(GC.SYSTEM_OBJECT_INVOICE, invoiceFieldsTouched, touched));
        }

        const branchCurrency = G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, asyncConfigs);

        setFieldValue(GC.SYSTEM_OBJECT_INVOICE, R.assoc(GC.FIELD_CURRENCY, branchCurrency, invoiceDefaultFields2));
      }
    },
  }),
  withAddServiceVendor,
  withProps((props: Object) => {
    const { entityType, asyncConfigs } = props;

    const invoiceStatusOptions = G.mapCustomConfigOptionsFromProps('FleetServiceIStatusConfig', props, true);

    const glCodeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      asyncConfigs,
      GC.INVOICE_GL_CODE,
      true,
    );

    const equipmentServiceTypeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      asyncConfigs,
      GC.FLEET_EQUIPMENT_SERVICE_TYPE,
      true,
    );

    const truckAiUploadDefaultDocumentType = G.getConfigValueFromStore(
      GC.TRUCK_MAINTENANCE_AI_UPLOAD_DEFAULT_DOCUMENT_TYPE,
      asyncConfigs,
    );

    const truckDocumentsDefaultDocumentType = G.getConfigValueFromStore(
      GC.TRUCK_MAINTENANCE_DOCUMENTS_DEFAULT_DOCUMENT_TYPE,
      asyncConfigs,
    );

    const trailerAiUploadDefaultDocumentType = G.getConfigValueFromStore(
      GC.TRAILER_MAINTENANCE_AI_UPLOAD_DEFAULT_DOCUMENT_TYPE,
      asyncConfigs,
    );

    const trailerDocumentsDefaultDocumentType = G.getConfigValueFromStore(
      GC.TRAILER_MAINTENANCE_DOCUMENTS_DEFAULT_DOCUMENT_TYPE,
      asyncConfigs,
    );

    const aiUploadDefaultDocumentType = R.equals(entityType, 'trailer') ?
      trailerAiUploadDefaultDocumentType : truckAiUploadDefaultDocumentType;

    const documentsDefaultDocumentType = R.equals(entityType, 'trailer') ?
      trailerDocumentsDefaultDocumentType : truckDocumentsDefaultDocumentType;

    return {
      glCodeOptions,
      invoiceStatusOptions,
      equipmentServiceTypeOptions,
      aiUploadDefaultDocumentType,
      documentsDefaultDocumentType,
    };
  }),
  lifecycle({
    componentDidUpdate(prevProps: Object) {
      const props = this.props;

      const {
        setFieldValue,
        serviceVendorListAvailableOptions,
      } = props;

      const getServiceVendorGuid = R.path(['values', GC.FIELD_SERVICE_VENDOR_GUID]);

      const serviceVendorGuid = getServiceVendorGuid(props);

      if (G.notEquals(getServiceVendorGuid(props), getServiceVendorGuid(prevProps))) {
        if (G.isNotNilAndNotEmpty(serviceVendorListAvailableOptions)) {
          const serviceVendorType = R.compose(
            R.pathOr(null, [GC.FIELD_TYPE]),
            R.find(R.propEq(serviceVendorGuid, GC.FIELD_VALUE)),
          )(serviceVendorListAvailableOptions);

          setFieldValue('serviceVendorType', serviceVendorType);
        }
      }
    },
  }),
  pure,
);

const MaintenanceForm = (props: Object) => {
  const { isEditMode, handleSubmit } = props;

  const { aiRecognizeRequest, aiRecognizeLoading } = useAiRecognize({ type: 'MAINTENANCE' });

  const [leftActiveTab, setLeftActiveTab] = useState(0);

  const [rightActiveTab, setRightActiveTab] = useState(0);

  const RightSectionComponent = G.isTrue(isEditMode) ? UpdateRightSection : RightSection;

  return (
    <form onSubmit={handleSubmit}>
      <Flex
        overflow='auto'
        alignItems='start'
        height='calc(100vh - 60px)'
        css={scrollableContainerCss4px}
      >
        <LeftSection
          {...props}
          leftActiveTab={leftActiveTab}
          setLeftActiveTab={setLeftActiveTab}
          aiRecognizeLoading={aiRecognizeLoading}
        />
        <RightSectionComponent
          {...props}
          rightActiveTab={rightActiveTab}
          setRightActiveTab={setRightActiveTab}
          aiRecognizeRequest={aiRecognizeRequest}
        />
      </Flex>
      <FormFooter2
        boxStyles={{
          p: 15,
          borderTop: '1px solid',
          bg: G.getTheme('colors.whiteGrey'),
          borderColor: G.getTheme('colors.lightGrey'),
        }}
      />
    </form>
  );
};

const enhanceCreate = compose(
  withMaintenanceNotes({}),
  withProps({
    sequenceConfigName: GC.FLEET_GENERAL_EQUIPMENT_SERVICE_ID_SEQUENCE,
    autogenerateConfigName: GC.FLEET_GENERAL_EQUIPMENT_SERVICE_ID_AUTOGENERATED,
    configsNamesArray:
      [GC.FLEET_GENERAL_EQUIPMENT_SERVICE_ID_SEQUENCE, GC.FLEET_GENERAL_EQUIPMENT_SERVICE_ID_AUTOGENERATED],
  }),
  withAsyncSequence,
  enhance,
);

const enhanceEdit = compose(
  withProps({ isEditMode: true}),
  withState('documents', 'setDocuments', null),
  withState('maintenanceNotes', 'setMaintenanceNotes', null),
  withState('localItemList', 'setLocalItemList', R.prop('documentList')),
  withAsyncGetEquipmentServiceByEntityTypeAndServiceGuid,
  enhance,
);

export const EditMaintenanceForm = enhanceEdit(MaintenanceForm);

export default enhanceCreate(MaintenanceForm);

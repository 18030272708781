import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import {
  changeCommissionAssignmentSuccess,
  toggleMarkAsHotLoadByLoadTypeSuccess,
  toggleMarkAsFastLoadByLoadTypeSuccess,
} from '../../../common/actions';
// features
import { updateNotificationsStore } from '../../sockets-v2/actions';
import { setDataToListItemByPropName } from '../../dispatch-board-new/load/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature load-details-new
import * as A from './actions';
import * as H from '../helpers';
import * as C from '../constants';
//////////////////////////////////////////////////

const initialState = {
  events: {},
  details: {},
  charges: [],
  mailList: [],
  documents: [],
  invoiceTotals: [],
  loadCustomStatuses: [],
  claimGeneralDetails: {},
  expandedLists: [false, false, false],
  hiddenTabs: {
    itemList: false,
    containerList: false,
    vehicleItemList: false,
  },
  notification: {
    list: [],
    empty: true,
    unreadCount: 0,
  },
  messageCenter: {
    noteList: [],
    chatMessageList: [],
    activeTab: 'chatMessageList',
  },
  activeLists: [C.ACTIVE_LIST_REFERENCES, C.ACTIVE_LIST_REFERENCES],
  loadConfigs: {
    statusMessageConfigs: [],
    configsByNames: {
      configs: {},
      dropdowns: {},
    },
    configGroups: {
      ui: {},
      invoice: {},
      general: {},
      communication: {},
      statusMessages: [],
    },
  },
  lists: {
    itemList: [],
    claimList: [],
    containerList: [],
    referenceList: [],
    vehicleItemList: [],
    customerRateList: [],
    statusMessageList: [],
    externalSystemList: [],
    customerInvoiceList: [],
    notificationList: {
      list: [],
      totalCount: 0,
      pagination: {
        offset: 0,
        limit: 15,
      },
    },
  },
  linkedLoadList: null,
  relatedLoadList: null,
  relatedLoadListLoading: false,
};

const setInitialState = () => initialState;

const setValueToStore = (state: Object, { path, value }: Object) => (
  P.$set(path, value, state)
);

const updateOrderDetailsSuccess = (state: Object, data: Object) => (
  P.$set('details', R.mergeRight(state.details, data), state)
);

const getAvailableLoadCustomStatusListSuccess = (state: Object, data: Object) => (
  P.$set('loadCustomStatuses', data, state)
);

const changeActiveListSuccess = (state: Object, { listName, tableNumber }: Object) => (
  P.$set(`activeLists.${tableNumber}`, listName, state)
);

const getOrderInvoiceTotalsSuccess = (state: Object, data: Object) => (
  P.$set('invoiceTotals', data, state)
);

const toggleExpandedListsSuccess = (state: Object, { expanded, tableNumber }: Object) => {
  if (G.isNilOrEmpty(expanded)) return P.$toggle(`expandedLists.${tableNumber}`, state);

  return P.$set(`expandedLists.${tableNumber}`, expanded, state);
};

const getOrderDetailsSuccess = (state: Object, data: Object) => (
  P.$set('details', data, state)
);

const getOrderStatusSuccess = (state: Object, data: Object) => (
  P.$set('details.status', data, state)
);

// cost allocations
const getOrderCostAllocationsSuccess = (state: Object, data: Object) => (
  P.$set('details.costAllocations', data, state)
);

// configs
const getOrderConfigByGroupNamesSuccess = (state: Object, data: Object) => (
  P.$set('loadConfigs.configGroups', data, state)
);

const getStatusMessagesConfigListSuccess = (state: Object, data: Object) => (
  P.$set('loadConfigs.statusMessageConfigs', data, state)
);

const getOrderConfigsByNamesSuccess = (state: Object, data: Object) => (
  P.$set('loadConfigs.configsByNames', data, state)
);

// items
const getOrderItemListSuccess = (state: Object, data: Object) => {
  const grouped = R.groupBy(R.prop(GC.FIELD_ITEM_TYPE), data);
  const itemList = R.pathOr([], [GC.ITEM_TYPE_GENERAL], grouped);
  const vehicleItemList = R.map(R.assoc('expanded', false), R.pathOr([], [GC.ITEM_TYPE_VEHICLE], grouped));
  const hiddenTabs = R.mergeRight(
    state.hiddenTabs,
    {
      itemList: G.isNilOrEmpty(itemList),
      vehicleItemList: G.isNilOrEmpty(vehicleItemList),
    },
  );

  return P.$all(
    P.$set('hiddenTabs', hiddenTabs),
    P.$set('lists.itemList', itemList),
    P.$set('lists.vehicleItemList', vehicleItemList),
    state,
  );
};

const toggleOrderVehicleItemSuccess = (state: Object, data: Object) => {
  const { itemGuid, damages, tableNumber } = data;

  const itemList = R.pathOr([], ['lists', 'vehicleItemList'], state);
  const index = R.findIndex(R.propEq(itemGuid, GC.FIELD_GUID), itemList);
  const expandedItem = R.path([index, 'expanded'], itemList);
  if (G.isFalse(expandedItem)) {
    return P.$all(
      P.$set(`lists.vehicleItemList.${index}.expanded`, true),
      P.$set(`lists.vehicleItemList.${index}.damages`, damages),
      P.$set(`expandedLists.${tableNumber}`, R.gt(R.length(damages), 1)),
      state,
    );
  }

  return P.$set(`lists.vehicleItemList.${index}.expanded`, false, state);
};

const createOrUpdateOrderVehicleItemDamageSuccess = (state: Object, data: Object) => {
  const { guid, itemGuid } = data;

  const vehicleItemList = R.pathOr([], ['lists', 'vehicleItemList'], state);
  const itemIndex = R.findIndex(R.propEq(itemGuid, GC.FIELD_GUID), vehicleItemList);
  const damages = R.pathOr([], [itemIndex, 'damages'], vehicleItemList);
  const damageIndex = R.findIndex(R.propEq(guid, GC.FIELD_GUID), damages);

  if (R.lt(damageIndex, 0)) {
    return P.$add(`lists.vehicleItemList.${itemIndex}.damages`, data, state);
  }

  return P.$set(`lists.vehicleItemList.${itemIndex}.damages.${damageIndex}`, data, state);
};

const removeOrderVehicleItemDamageSuccess = (state: Object, data: Object) => {
  const { guid, itemGuid } = data;

  const vehicleItemList = R.pathOr([], ['lists', 'vehicleItemList'], state);
  const itemIndex = R.findIndex(R.propEq(itemGuid, GC.FIELD_GUID), vehicleItemList);
  const damages = R.pathOr([], [itemIndex, 'damages'], vehicleItemList);
  const damageIndex = R.findIndex(R.propEq(guid, GC.FIELD_GUID), damages);

  return P.$drop(`lists.vehicleItemList.${itemIndex}.damages.${damageIndex}`, state);
};

// containers
const getOrderContainerListSuccess = (state: Object, data: Object) => (
  P.$all(
    P.$set('lists.containerList', data),
    P.$set('hiddenTabs.containerList', G.isNilOrEmpty(data)),
    state,
  )
);

// notifications
const getOrderNotificationListSuccess = (state: Object, { empty, results }: Object) => {
  if (G.isTrue(empty)) return state;
  const list = R.map((item: Object) => {
    const { guid, read, createdDate, notification } = item;

    const warningLevel = G.getPropFromObject(GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_LEVEL, notification);
    const trigger = G.getEnumLocale(
      G.getPropFromObject(GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER, notification),
    );

    return {
      read,
      guid,
      trigger,
      createdDate,
      warningLevel,
      [GC.FIELD_MESSAGE]: G.getPropFromObject(GC.FIELD_MESSAGE, notification),
    };
  }, results);
  const unreadCount = R.compose(
    R.length,
    R.filter(({ read }: Object) => G.isFalse(read)),
  )(list);

  return P.$set('notification', { list, empty, unreadCount }, state);
};

const hideOrderNotificationsSuccess = (state: Object) => (
  P.$all(
    P.$set('notification.unreadCount', 0),
    P.$set(
      'notification.list',
      R.map(
        (item: Object) => R.assoc('read', true, item),
        R.path(['notification', 'list'], state),
      ),
    ),
    state,
  )
);

const markOrderNotificationAsReadSuccess = (state: Object, guid: string) => {
  const updatedList = R.map(
    (item: Object) => {
      if (R.equals(item.guid, guid)) {
        return R.mergeRight(item, { read: true });
      }

      return item;
    },
    state.notification.list);

  return P.$all(
    P.$set('notification.list', updatedList),
    P.$set('notification.unreadCount', state.notification.unreadCount - 1),
    state,
  );
};

// events
const getOrderEventsSuccess = (state: Object, data: Object) => (
  P.$set('events', R.indexBy(R.prop(GC.FIELD_GUID), data), state)
);

const updateAppointmentSuccess = (state: Object, data: Object) => {
  const events = R.compose(
    R.map((event: Object) => {
      if (R.eqProps(GC.FIELD_GUID, event, data)) {
        return R.mergeRight(event, data);
      }
      return event;
    }),
    R.path(['details', 'events']),
  )(state);
  return (
    P.$all(
      P.$set('details.events', events),
      P.$set('events', R.indexBy(R.prop(GC.FIELD_GUID), events)),
      state,
    )
  );
};

// references
const getOrderReferenceListSuccess = (state: Object, { data, tableNumber }: Object) => {
  const referenceList = R.sortBy(({ primary }: Object) => R.not(primary), data);

  return H.setActiveListDataToStore(
    state,
    referenceList,
    C.ACTIVE_LIST_REFERENCES,
    tableNumber,
  );
};

const createOrderReferenceSuccess = (state: Object, data: Object) => {
  const { lists: { referenceList } } = state;
  if (R.isNil(referenceList)) return P.$set('lists.referenceList', R.of(Array, data), state);
  const { primary } = data;
  if (primary) {
    const oldReferenceList = R.map(R.assoc(GC.FIELD_PRIMARY, false), referenceList);
    const newReferenceList = R.prepend(data, oldReferenceList);

    return P.$set('lists.referenceList', newReferenceList, state);
  }

  return P.$add('lists.referenceList', data, state);
};

const updateOrderReferenceSuccess = (state: Object, data: Object) => {
  const { lists: { referenceList } } = state;
  const { guid, primary } = data;
  const index = R.findIndex(R.pathEq(guid, [GC.FIELD_GUID]), referenceList);
  if (primary) {
    const newReferenceList = R.compose(
      R.sortBy(({ primary }: Object) => R.not(primary)),
      R.map((item: Object) => G.ifElse(
        R.pathEq(guid, [GC.FIELD_GUID], item),
        data,
        R.assoc(GC.FIELD_PRIMARY, false, item),
      )),
    )(referenceList);

    return P.$all(
      P.$set('details.references', newReferenceList),
      P.$set('lists.referenceList', newReferenceList),
      state,
    );
  }

  return P.$set(`lists.referenceList.${index}`, data, state);
};

const removeOrderReferenceSuccess = (state: Object, guid: string) => H.removeListItemFromStore(
  state,
  guid,
  'referenceList',
);

// status messages
const getOrderStatusMessageListSuccess = (state: Object, { data, tableNumber }: Object) => {
  const { events } = state;
  const statusMessageList = H.getStatusMessageListWithLoadEvent(data, events);

  return H.setActiveListDataToStore(
    state,
    statusMessageList,
    C.ACTIVE_LIST_STATUS_MESSAGES,
    tableNumber,
  );
};

const createOrderStatusMessageSuccess = (state: Object, data: Object) => (
  P.$add('lists.statusMessageList', R.head(H.getStatusMessageListWithLoadEvent(R.of(Array, data), state.events)), state)
);

const createOrderMultipleStatusMessageSuccess = (state: Object, data: Object) => {
  const { events, lists: { statusMessageList } } = state;

  const mappedStatusMessages = H.getStatusMessageListWithLoadEvent(data, events);
  const newStatusMessageList = R.concat(statusMessageList, mappedStatusMessages);

  return P.$set('lists.statusMessageList', newStatusMessageList, state);
};

const updateOrderStatusMessageSuccess = (state: Object, data: Object) => {
  const { guid } = data;
  const { events, lists: { statusMessageList } } = state;
  const index = R.findIndex(R.pathEq(guid, [GC.FIELD_GUID]), statusMessageList);
  const statusMessage = R.head(H.getStatusMessageListWithLoadEvent(R.of(Array, data), events));

  return P.$set(`lists.statusMessageList.${index}`, statusMessage, state);
};

const removeOrderStatusMessageSuccess = (state: Object, guid: string) => H.removeListItemFromStore(
  state,
  guid,
  'statusMessageList',
);

// customer invoices
const getOrderCustomerInvoiceListSuccess = (state: Object, { data, tableNumber }: Object) => H.setActiveListDataToStore(
  state,
  data,
  C.ACTIVE_LIST_CUSTOMER_INVOICES,
  tableNumber,
);

const createOrderCustomerInvoiceSuccess = (state: Object, data: Object) => (
  P.$add('lists.customerInvoiceList', data, state)
);

const updateOrderCustomerInvoiceSuccess = (state: Object, data: Object) => H.setUpdatedListItemToStore(
  data,
  state,
  'customerInvoiceList',
);

const removeOrderCustomerInvoiceSuccess = (state: Object, guid: string) => H.removeListItemFromStore(
  state,
  guid,
  'customerInvoiceList',
);

// customer rates
const getOrderCustomerRateListSuccess = (state: Object, { data, tableNumber }: Object) => H.setActiveListDataToStore(
  state,
  data,
  C.ACTIVE_LIST_CUSTOMER_RATES,
  tableNumber,
);

const createOrderCustomerRateSuccess = (state: Object, data: Object) => (
  P.$add('lists.customerRateList', data, state)
);

const updateOrderCustomerRateSuccess = (state: Object, data: Object) => H.setUpdatedListItemToStore(
  data,
  state,
  'customerRateList',
);

const removeOrderCustomerRateSuccess = (state: Object, guid: string) => H.removeListItemFromStore(
  state,
  guid,
  'customerRateList',
);

const selectOrderCustomerRateSuccess = (state: Object, data: string) => {
  const { lists: { customerRateList } } = state;
  const { guid } = data;

  const newList = R.map((item: Object) => {
    const prevSelected = item.selected;
    const selected = R.propEq(guid, GC.FIELD_GUID, item);
    const itemVersion = G.getPropFromObject(GC.FIELD_VERSION, item);
    const version = G.ifElse(R.or(selected, prevSelected), R.inc(itemVersion), itemVersion);

    return R.mergeRight(item, { version, selected });
  }, customerRateList);

  return P.$set('lists.customerRateList', newList, state);
};

// documents
const getOrderDocumentListSuccess = (state: Object, data: Object) => (
  P.$set('documents', data, state)
);

const createOrderDocumentSuccess = (state: Object, data: Object) => (
  P.$add('documents', data, state)
);

const updateOrderDocumentSuccess = (state: Object, data: Object) => {
  const { documents } = state;
  const { guid } = data;
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), documents);

  return P.$set(`documents.${index}`, data, state);
};

const removeOrderDocumentSuccess = (state: Object, guid: Object) => {
  const { documents } = state;
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), documents);

  return P.$drop(`documents.${index}`, state);
};

// mails
const getOrderMailListSuccess = (state: Object, data: Object) => (
  P.$set('mailList', data, state)
);

// message center
const getOrderChatMessageListSuccess = (state: Object, data: Object) => (
  P.$set('messageCenter.chatMessageList', data, state)
);

const changeMessageCenterActiveTabSuccess = (state: Object, tab: string) => (
  P.$set('messageCenter.activeTab', tab, state)
);

const getOrderNoteListSuccess = (state: Object, data: Object) => (
  P.$set('messageCenter.noteList', data, state)
);

const createOrderNoteSuccess = (state: Object, data: Object) => (
  P.$add('messageCenter.noteList', data, state)
);

const createOrderChatMessageSuccess = (state: Object, data: Object) => (
  P.$add('messageCenter.chatMessageList', data, state)
);

const updateOrderNoteSuccess = (state: Object, data: Object) => {
  const { messageCenter: { noteList } } = state;
  const { guid, pinned } = data;
  if (pinned) {
    const mappedList = R.map((item: Object) => G.ifElse(
      R.propEq(guid, GC.FIELD_GUID, item),
      data,
      G.ifElse(
        item.pinned,
        R.mergeRight(item, { pinned: false, version: R.inc(item.version) }),
        R.assoc('pinned', false, item),
      ),
    ), noteList);

    return P.$set('messageCenter.noteList', mappedList, state);
  }
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), noteList);

  return P.$set(`messageCenter.noteList.${index}`, data, state);
};

const updateOrderChatMessageSuccess = (state: Object, data: Object) => {
  const { messageCenter: { chatMessageList } } = state;
  const { guid } = data;
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), chatMessageList);

  return P.$set(`messageCenter.chatMessageList.${index}`, data, state);
};

const removeOrderNoteSuccess = (state: Object, guid: string) => {
  const { messageCenter: { noteList } } = state;
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), noteList);

  return P.$drop(`messageCenter.noteList.${index}`, state);
};

const removeOrderChatMessageSuccess = (state: Object, guid: string) => {
  const { messageCenter: { chatMessageList } } = state;
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), chatMessageList);

  return P.$drop(`messageCenter.chatMessageList.${index}`, state);
};

// payments
const updateOrderBillToSuccess = (state: Object, data: Object) => (
  P.$set('details.billTo', data, state)
);

// related loads
const getRelatedLoadListRequest = (state: Object) => (
  P.$set('relatedLoadListLoading', true, state)
);

const getRelatedLoadListSuccess = (state: Object, data: Array=[]) => (
  P.$all(
    P.$set('relatedLoadList', data),
    P.$set('relatedLoadListLoading', false),
    state,
  )
);

const updateRelatedLoadList = (state: Object, data: Array = []) => {
  const { details, relatedLoadList } = state;

  const { guid, propName, data: customStatus } = data;

  if (R.or(G.isNilOrEmpty(details), R.not(R.equals(propName, GC.FIELD_CUSTOM_STATUS)))) return state;

  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), relatedLoadList);

  return P.$set(`relatedLoadList.${index}.customStatus`, customStatus, state);
};

const getLinkedLoadListSuccess = (state: Object, data: Array = []) => (
  P.$set('linkedLoadList', R.map(R.assoc('isLinked', true), data), state)
);

// sockets
const updateOrderNotificationStore = (state: Object, { guid, read, notification }: Object = {}) => {
  const { details, notification: { list, unreadCount } } = state;

  const objectGuid = R.path(['objectGuid'], notification);
  if (G.notEquals(objectGuid, G.getGuidFromObject(details))) return state;
  const newList = R.prepend(R.mergeRight(notification, { guid, read }), list);

  return P.$all(
    P.$set('notification.list', newList),
    P.$set('notification.unreadCount', R.inc(unreadCount)),
    state,
  );
};

// hot load
const toggleMarkAsHotLoadByLoadType = (state: Object, { loadType, fromPage }: Object) => {
  if (R.or(G.notEquals(fromPage, 'details'), G.isFalse(G.isLoadTypeClo(loadType)))) return state;

  return P.$toggle('details.hot', state);
};

// commission assignment
const changeOrderCommissionAssignmentSuccess = (state: Object, { data, loadType, fromPage, fieldName }: Object) => {
  if (R.or(G.notEquals(fromPage, 'details'), G.isFalse(G.isLoadTypeClo(loadType)))) return state;

  return P.$set(`details.${fieldName}`, data, state);
};

// fast load
const toggleMarkAsFast = (state: Object, { loadType, fromPage }: Object) => {
  if (R.or(G.notEquals(fromPage, 'details'), G.isFalse(G.isLoadTypeClo(loadType)))) return state;

  return P.$toggle('details.fastLoad', state);
};

// approve clo
const approveCloSuccess = (state: Object) => {
  if (G.isNilOrEmpty(state)) return state;

  return P.$all(
    P.$set('details.approved', true),
    P.$set('details.approvedBy', G.getAmousCurrentUserLoginIdFromWindow()),
    state,
  );
};

// transportation mode grouping
const getOrderTransportationModeGroupingListSuccess = (state: Object, data: Object) => (
  P.$set('transportationModeGroupingList', data, state)
);

// claim
const getClaimDetailsSuccess = (state: Object, { data, tableNumber }: Object) => H.setActiveListDataToStore(
  state,
  data,
  C.ACTIVE_LIST_CLAIMS,
  tableNumber,
);

const setClaimSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return P.$set('lists.claimList', [], state);

  return P.$set('lists.claimList', R.of(Array, data), state);
};

const getClaimGeneralDetailsSuccess = (state: Object, data: Object) => (
  P.$set('claimGeneralDetails', data, state)
);

// external system
const getExternalSystemListSuccess = (state: Object, { data, tableNumber }: Object) => H.setActiveListDataToStore(
  state,
  data,
  C.ACTIVE_LIST_EXTERNAL_SYSTEM_LIST,
  tableNumber,
);

const removeCloFromExternalSystemSuccess = (state: Object, guid: string) => H.removeListItemFromStore(
  state,
  guid,
  C.ACTIVE_LIST_EXTERNAL_SYSTEM_LIST,
);

export default createReducer({
  // common
  [A.setInitialState]: setInitialState,
  [A.setValueToStore]: setValueToStore,
  [A.getOrderStatusSuccess]: getOrderStatusSuccess,
  [A.getOrderDetailsSuccess]: getOrderDetailsSuccess,
  [A.updateOrderDetailsSuccess]: updateOrderDetailsSuccess,
  [A.toggleExpandedListsSuccess]: toggleExpandedListsSuccess,
  [A.getOrderInvoiceTotalsSuccess]: getOrderInvoiceTotalsSuccess,
  [A.getAvailableLoadCustomStatusListSuccess]: getAvailableLoadCustomStatusListSuccess,
  // hot load
  [toggleMarkAsHotLoadByLoadTypeSuccess]: toggleMarkAsHotLoadByLoadType,
  // fast load
  [toggleMarkAsFastLoadByLoadTypeSuccess]: toggleMarkAsFast,
  // cost allocations
  [A.getOrderCostAllocationsSuccess]: getOrderCostAllocationsSuccess,
  // table
  [A.changeActiveListSuccess]: changeActiveListSuccess,
  // configs
  [A.getOrderConfigsByNamesSuccess]: getOrderConfigsByNamesSuccess,
  [A.getOrderConfigByGroupNamesSuccess]: getOrderConfigByGroupNamesSuccess,
  [A.getStatusMessagesConfigListSuccess]: getStatusMessagesConfigListSuccess,
  // events
  [A.getOrderEventsSuccess]: getOrderEventsSuccess,
  [A.updateAppointmentSuccess]: updateAppointmentSuccess,
  // notifications
  [A.hideOrderNotificationsSuccess]: hideOrderNotificationsSuccess,
  [A.getOrderNotificationListSuccess]: getOrderNotificationListSuccess,
  [A.markOrderNotificationAsReadSuccess]: markOrderNotificationAsReadSuccess,
  // containers
  [A.getOrderContainerListSuccess]: getOrderContainerListSuccess,
  // items
  [A.getOrderItemListSuccess]: getOrderItemListSuccess,
  [A.toggleOrderVehicleItemSuccess]: toggleOrderVehicleItemSuccess,
  [A.removeOrderVehicleItemDamageSuccess]: removeOrderVehicleItemDamageSuccess,
  [A.createOrUpdateOrderVehicleItemDamageSuccess]: createOrUpdateOrderVehicleItemDamageSuccess,
  // references
  [A.updateOrderReferenceSuccess]: updateOrderReferenceSuccess,
  [A.createOrderReferenceSuccess]: createOrderReferenceSuccess,
  [A.removeOrderReferenceSuccess]: removeOrderReferenceSuccess,
  [A.getOrderReferenceListSuccess]: getOrderReferenceListSuccess,
  // status messages
  [A.removeOrderStatusMessageSuccess]: removeOrderStatusMessageSuccess,
  [A.createOrderStatusMessageSuccess]: createOrderStatusMessageSuccess,
  [A.updateOrderStatusMessageSuccess]: updateOrderStatusMessageSuccess,
  [A.getOrderStatusMessageListSuccess]: getOrderStatusMessageListSuccess,
  [A.createOrderMultipleStatusMessageSuccess]: createOrderMultipleStatusMessageSuccess,
  // customer invoices
  [A.updateOrderCustomerInvoiceSuccess]: updateOrderCustomerInvoiceSuccess,
  [A.removeOrderCustomerInvoiceSuccess]: removeOrderCustomerInvoiceSuccess,
  [A.createOrderCustomerInvoiceSuccess]: createOrderCustomerInvoiceSuccess,
  [A.getOrderCustomerInvoiceListSuccess]: getOrderCustomerInvoiceListSuccess,
  // customer rates
  [A.selectOrderCustomerRateSuccess]: selectOrderCustomerRateSuccess,
  [A.removeOrderCustomerRateSuccess]: removeOrderCustomerRateSuccess,
  [A.updateOrderCustomerRateSuccess]: updateOrderCustomerRateSuccess,
  [A.createOrderCustomerRateSuccess]: createOrderCustomerRateSuccess,
  [A.getOrderCustomerRateListSuccess]: getOrderCustomerRateListSuccess,
  // documents
  [A.removeOrderDocumentSuccess]: removeOrderDocumentSuccess,
  [A.createOrderDocumentSuccess]: createOrderDocumentSuccess,
  [A.updateOrderDocumentSuccess]: updateOrderDocumentSuccess,
  [A.getOrderDocumentListSuccess]: getOrderDocumentListSuccess,
  // mails
  [A.getOrderMailListSuccess]: getOrderMailListSuccess,
  // message center
  [A.updateOrderNoteSuccess]: updateOrderNoteSuccess,
  [A.removeOrderNoteSuccess]: removeOrderNoteSuccess,
  [A.createOrderNoteSuccess]: createOrderNoteSuccess,
  [A.getOrderNoteListSuccess]: getOrderNoteListSuccess,
  [A.createOrderChatMessageSuccess]: createOrderChatMessageSuccess,
  [A.updateOrderChatMessageSuccess]: updateOrderChatMessageSuccess,
  [A.removeOrderChatMessageSuccess]: removeOrderChatMessageSuccess,
  [A.getOrderChatMessageListSuccess]: getOrderChatMessageListSuccess,
  [A.changeMessageCenterActiveTabSuccess]: changeMessageCenterActiveTabSuccess,
  // payments
  [A.updateOrderBillToSuccess]: updateOrderBillToSuccess,
  // related/linked loads
  [setDataToListItemByPropName]: updateRelatedLoadList,
  [A.getLinkedLoadListSuccess]: getLinkedLoadListSuccess,
  [A.getRelatedLoadListRequest]: getRelatedLoadListRequest,
  [A.getRelatedLoadListSuccess]: getRelatedLoadListSuccess,
  // commission assignment
  [changeCommissionAssignmentSuccess]: changeOrderCommissionAssignmentSuccess,
  // sockets
  [updateNotificationsStore]: updateOrderNotificationStore,
  // approve clo
  [A.approveCloSuccess]: approveCloSuccess,
  // transportation mode grouping
  [A.getOrderTransportationModeGroupingListSuccess]: getOrderTransportationModeGroupingListSuccess,
  // claim
  [A.setClaimSuccess]: setClaimSuccess,
  [A.getClaimDetailsSuccess]: getClaimDetailsSuccess,
  [A.getClaimGeneralDetailsSuccess]: getClaimGeneralDetailsSuccess,
  // external system
  [A.getExternalSystemListSuccess]: getExternalSystemListSuccess,
  [A.removeCloFromExternalSystemSuccess]: removeCloFromExternalSystemSuccess,
}, initialState);

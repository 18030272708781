import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, Flex } from '../../../ui';
// icons
import * as I from '../../../svgs';
// features dashboards
import { calculatePercentageChange } from '../helpers';
//////////////////////////////////////////////////

const Description = (props: Object) => {
  const { total, prevTotal, description, withoutPercentage, revertLowPercentageTextColor } = props;

  if (G.isNilOrEmpty(description)) {
    return (
      <Flex>
        <Box
          fontSize={14}
          color='dark.mainDark'
        >
          -
        </Box>
      </Flex>
    );
  }

  if (G.isTrue(withoutPercentage)) {
    return (
      <Flex>
        <Box
          fontSize={14}
          color='dark.mainDark'
        >
          {description}
        </Box>
      </Flex>
    );
  }

  const percentage = calculatePercentageChange(total, prevTotal);

  if (R.equals(percentage, 'N/A')) {
    return (
      <Flex
        gap={4}
        fontSize={14}
        color='dark.mainDark'
        flexDirection='column'
      >
        <Flex height={27}>
          <Box>N/A</Box>
        </Flex>
        <Box color='dark.darkGrey' fontSize={13}>{description}</Box>
      </Flex>
    );
  }

  if (R.gt(percentage, 0)) {
    return (
      <Flex
        gap={4}
        fontSize={14}
        color='dark.mainDark'
        flexDirection='column'
      >
        <Flex>
          <Box>{I.trendingUp()}</Box>
          <Box
            mx='5px'
            fontSize={14}
            color='softGreen'
          >
            {`${percentage}%`}
          </Box>
        </Flex>
        <Box
          fontSize={13}
          textWrap='pretty'
          textAlign='center'
          color='dark.darkGrey'
        >
          {description}
        </Box>
      </Flex>
    );
  }

  if (R.lt(percentage, 0)) {
    const percentageColor = revertLowPercentageTextColor ? 'softGreen' : 'darkRed2';

    return (
      <Flex
        gap={4}
        fontSize={14}
        color='dark.mainDark'
        flexDirection='column'
      >
        <Flex>
          <Box>{I.trendingDown()}</Box>
          <Box
            mx='5px'
            fontSize={14}
            color={percentageColor}
          >
            {`${percentage}%`}
          </Box>
        </Flex>
        <Box
          fontSize={13}
          textWrap='pretty'
          textAlign='center'
          color='dark.darkGrey'
        >
          {description}
        </Box>
      </Flex>
    );
  }

  return (
    <Box
      fontSize={14}
      textWrap='pretty'
      textAlign='center'
      color='dark.mainDark'
    >
      {description}
    </Box>
  );
};

const formattedComponent = (text: string, normalSize: number = 19) => {
  if (G.isNilOrEmpty(text)) return null;

  const formatted = text.trim().split(' ');

  return G.mapIndexed((value: string, index: number) => {
    let fontSize = normalSize;

    if (R.equals(index % 2, 1)) {
      fontSize = R.subtract(normalSize, 2);
    }

    return <Box fontSize={fontSize}>{value}</Box>;
  }, formatted);
};

const TotalCard = (props: Object) => {
  const {
    mr,
    width,
    title,
    total,
    prevTotal,
    boxShadow,
    formatter,
    totalSuffix,
    description,
    withoutPercentage,
    revertLowPercentageTextColor,
  } = props;

  const formatted = G.isFunction(formatter) ? formattedComponent(formatter(total)) : G.toLocaleString(total);

  return (
    <Flex
      p={10}
      bg='white'
      height={120}
      mr={mr || 15}
      borderRadius={10}
      alignItems='center'
      width={width || 185}
      boxShadow={boxShadow}
      flexDirection='column'
      justifyContent='space-between'
    >
      <Box
        fontSize={14}
        color='titleGrey2'
      >
        {title}
      </Box>
      <Flex
        pt={5}
        gap={4}
        fontSize={19}
        fontWeight='bold'
        color='dark.mainDark'
        alignItems='baseline'
      >
        <Flex gap={4} alignItems='baseline'>{formatted}</Flex>
        <Box fontSize={17}>{totalSuffix}</Box>
      </Flex>
      <Description
        total={total}
        prevTotal={prevTotal}
        description={description}
        withoutPercentage={withoutPercentage}
        revertLowPercentageTextColor={revertLowPercentageTextColor}
      />
    </Flex>
  );
};

export default TotalCard;

import * as R from 'ramda';
import { createSelector } from 'reselect';
// report-common
import { getReportSelectors } from '../../report-common';
//////////////////////////////////////////////////

const {
  store,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} = getReportSelectors('geoFencingLocation');

const makeSelectItemList = () => createSelector(
  store,
  ({ itemList }: Object) => R.sortBy(R.prop('index'), R.values(itemList)),
);

const makeSelectReportType = () => createSelector(
  store,
  ({ reportType }: Object) => reportType,
);

const makeSelectCurrentLocation = () => createSelector(
  store,
  ({ currentLocation }: Object) => currentLocation,
);

const makeSelectTrailersByLocation = () => createSelector(
  store,
  ({ trailersByLocation }: Object) => trailersByLocation,
);

const makeSelectTrailerRouteHistory = () => createSelector(
  store,
  ({ milestoneLogList }: Object) => milestoneLogList,
);

const makeSelectConfigs = () => createSelector(
  store,
  ({ configs }: Object) => configs,
);

export {
  // report
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectReportType,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  // configs
  makeSelectConfigs,
  // location
  makeSelectCurrentLocation,
  // fleet/trailer
  makeSelectTrailersByLocation,
  makeSelectTrailerRouteHistory,
};

import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
// feature config
import {
  publicCLOFieldSettings,
  defaultPublicCLOFields,
  publicCLOValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    validationSchema: publicCLOValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultPublicCLOFields,
      props.initialValues,
    ),
  }),
  pure,
);

const PublicCLOForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...props}
      {...props.optionsForSelect}
      fields={publicCLOFieldSettings}
      fieldsWrapperStyles={{ pt: 11 }}
      handlers={{ handleDisableUserLoginId: () => G.isFalse(props.isSuperAdmin) }}
    />
    <FormFooter />
  </form>
);

export default enhance(PublicCLOForm);

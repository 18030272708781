import React from 'react';
import * as R from 'ramda';
// components
import { getTripStatusOptions, getOrderStatusOptions } from '../../../components/filter/settings';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { ENUMS } from '../../../constants/enums';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, Span } from '../../../ui';
// feature dispatch-board-new
import * as C from '../constants';
import LoadDetails from '../load/components/load-details';
import OrderDetails from '../order/components/order-details';
import {
  ClaimCell,
  BranchCell,
  StatusCell,
  LastDropCell,
  TelEarningsCell,
  CloEarningsCell,
  FirstPickupCell,
  ToggleEventsCell,
  CheckboxIconsCell,
  StatusActionsCell,
  MessagesNotesCell,
  ToggleShipUnitCell,
  CloCarrierFleetCell,
  TelCarrierFleetCell,
  PrimaryReferenceCell,
} from '../components/table-cells';
//////////////////////////////////////////////////

const getCommonReportFields = (expanded: boolean) => ([
  { name: C.LEFT_TABLE_CELL, freezed: true, p: '0px', sequence: 0 },
  { name: GC.FIELD_PRIMARY_REFERENCE_VALUE, sequence: 1 },
  { name: GC.FIELD_STATUS, sequence: 3 },
  { name: GC.FIELD_BRANCH, sequence: 4 },
  { name: GC.FIELD_FIRST_PICKUP, sequence: 5 },
  {
    sequence: 6,
    clickableTitle: true,
    name: C.TOGGLE_SHIP_UNIT_CELL,
    customTitleComponent: () => (
      <Flex height='100%' cursor='pointer'>
        {I.itemBox(G.getThemeByCond(expanded, 'colors.dark.blue', 'colors.greyMatterhorn'))}
      </Flex>
    ),
  },
  { name: C.TOGGLE_EVENTS_CELL, sequence: 7 },
  { name: GC.FIELD_LAST_DROP, sequence: 8 },
  { name: GC.FIELD_LOAD_CARRIER_FLEET, sequence: 10 },
  { name: GC.FIELD_LOAD_EARNINGS, sequence: 11 },
]);

export const getLoadTableReport = (expanded: boolean = false, showMode: boolean = false) => {
  let fields = [
    ...getCommonReportFields(expanded),
    { name: C.ACTIONS_CELL, freezedRight: true, p: '0px', sequence: 12, right: 120 },
    { name: C.MESSAGES_NOTES_CELL, freezedRight: true, sequence: 13, right: 50 },
  ];
  const useContainers = G.getAmousConfigByNameFromWindow(GC.CLO_GENERAL_USE_CONTAINERS);

  if (G.isTrue(useContainers)) {
    fields = R.insert(2, { name: GC.GRC.EVENT_CONTAINERS_CONTAINER_NUMBER_WITH_INITIAL, sequence: 2 }, fields);
  }

  if (G.isTrue(showMode)) {
    fields = R.insert(9, { name: GC.FIELD_MODE, sequence: 9 }, fields);
  }

  if (G.hasAmousCurrentUserPermissions([PC.CLAIM_READ, PC.CLAIM_WRITE])) {
    const index = R.findIndex(R.propEq(GC.FIELD_STATUS, GC.FIELD_NAME), fields);

    fields = R.insert(
      index,
      { sequence: index, name: `closInfo.${GC.SYSTEM_OBJECT_CLAIM}.${GC.FIELD_CLAIM_NUMBER}` },
      fields,
    );
  }

  return { fields };
};

export const getOrderTableReport = (expanded: boolean = false, showMode: boolean = false) => {
  let fields = [
    ...getCommonReportFields(expanded),
    { name: C.ACTIONS_CELL, freezedRight: true, p: '0px', sequence: 12, right: 120 },
    { name: C.MESSAGES_NOTES_CELL, freezedRight: true, sequence: 13, right: 50 },
  ];
  const useContainers = G.getAmousConfigByNameFromWindow(GC.CLO_GENERAL_USE_CONTAINERS);

  if (G.isTrue(useContainers)) {
    fields = R.insert(2, { name: GC.GRC.CONTAINER_NUMBERS_WITH_INITIAL, sequence: 2 }, fields);
  }

  if (G.isTrue(showMode)) {
    fields = R.insert(9, { name: GC.FIELD_MODE, sequence: 9 }, fields);
  }

  if (G.hasAmousCurrentUserPermissions([PC.CLAIM_READ, PC.CLAIM_WRITE])) {
    const index = R.findIndex(R.propEq(GC.FIELD_STATUS, GC.FIELD_NAME), fields);

    fields = R.insert(index, { name: `${GC.SYSTEM_OBJECT_CLAIM}.${GC.FIELD_CLAIM_NUMBER}`, sequence: index }, fields);
  }

  return { fields };
};

export const getFilterProps = (loadType: string, modeOptions: Array = [], customStatusOptions: Array = []) => {
  const statusOptions = G.isLoadTypeClo(loadType) ?
    R.concat(getOrderStatusOptions(), customStatusOptions) :
    R.concat(getTripStatusOptions(), customStatusOptions);

  let filterProps = {
    [GC.FIELD_PRIMARY_REFERENCE_VALUE]: {
      type: 'string',
      value: GC.FIELD_PRIMARY_REFERENCE_VALUE,
    },
    [GC.FIELD_STATUS]: {
      type: 'string',
      value: GC.FIELD_STATUS,
      options: statusOptions,
    },
    [GC.FIELD_BRANCH]: {
      type: 'string',
      value: GC.FIELD_BRANCH,
    },
    [GC.FIELD_FIRST_PICKUP]: {
      type: 'string',
      value: GC.FIELD_FIRST_PICKUP,
    },
    [GC.FIELD_LAST_DROP]: {
      type: 'string',
      value: GC.FIELD_LAST_DROP,
    },
    [GC.FIELD_MODE]: {
      type: 'string',
      value: GC.FIELD_MODE,
      options: modeOptions,
    },
    [GC.FIELD_LOAD_CARRIER_FLEET]: {
      type: 'string',
      value: GC.FIELD_LOAD_CARRIER_FLEET,
    },
  };

  const useContainers = G.getAmousConfigByNameFromWindow(GC.CLO_GENERAL_USE_CONTAINERS);

  if (useContainers) {
    const fieldName = G.ifElse(
      G.isLoadTypeClo(loadType),
      GC.GRC.CONTAINER_NUMBERS_WITH_INITIAL,
      GC.GRC.EVENT_CONTAINERS_CONTAINER_NUMBER_WITH_INITIAL,
    );

    filterProps = R.assoc(fieldName, { type: 'string', value: fieldName }, filterProps);
  }

  if (G.hasAmousCurrentUserPermissions([PC.CLAIM_READ, PC.CLAIM_WRITE])) {
    const isClo = G.isLoadTypeClo(loadType);

    const fieldName = G.ifElse(
      isClo,
      `${GC.SYSTEM_OBJECT_CLAIM}.${GC.FIELD_CLAIM_NUMBER}`,
      `closInfo.${GC.SYSTEM_OBJECT_CLAIM}.${GC.FIELD_CLAIM_NUMBER}`,
    );

    filterProps = R.assoc(fieldName, { type: 'string', value: fieldName, collection: R.not(isClo) }, filterProps);
  }

  return filterProps;
};

const commonTableSettings = {
  titleFontSize: 11,
  allowEditBtn: true,
  titleRowHeight: 55,
  tableRowHeight: 65,
  useMainColors: true,
  allowSelectAll: true,
  moreBtnCellWidth: 50,
  checkBoxCellWidth: 0,
  searchableTitles: true,
  allowSelectItems: true,
  maxHeight: 'fit-content',
};

export const loadTableSettings = {
  ...commonTableSettings,
  expandedDetailsComponent: ({ data, parentProps, callbackData }: Object) => {
    const { shownDetails, rateMarginViolationInfo } = parentProps;

    const load = R.assoc(
      GC.SYSTEM_OBJECT_RATE_MARGIN_VIOLATION_INFO,
      rateMarginViolationInfo,
      data,
    );

    return (
      <LoadDetails
        load={load}
        shownDetails={shownDetails}
        tableIndex={G.getPropFromObject('tableIndex', callbackData)}
      />
    );
  },
};

export const orderTableSettings = {
  ...commonTableSettings,
  expandedDetailsComponent: ({ data, parentProps, callbackData }: Object) => (
    <OrderDetails
      load={data}
      shownDetails={parentProps.shownDetails}
      tableIndex={G.getPropFromObject('tableIndex', callbackData)}
    />
  ),
};

export const getCommonColumnSettings = (
  loadType: string,
  configCurrency: string,
  openFixedPopup: Function,
  autodialApp: string,
  toggle: Function,
  closeFixedPopup: Function,
  handleUpdateContainer: Function,
) => {
  const isClo = G.isLoadTypeClo(loadType);

  const containerColumnName = G.ifElse(
    isClo,
    GC.GRC.CONTAINER_NUMBERS_WITH_INITIAL,
    GC.GRC.EVENT_CONTAINERS_CONTAINER_NUMBER_WITH_INITIAL,
  );

  const claimName = G.ifElse(
    isClo,
    `${GC.SYSTEM_OBJECT_CLAIM}.${GC.FIELD_CLAIM_NUMBER}`,
    `closInfo.${GC.SYSTEM_OBJECT_CLAIM}.${GC.FIELD_CLAIM_NUMBER}`,
  );

  return {
    [GC.FIELD_PRIMARY_REFERENCE_VALUE]: {
      width: 200,
      searchable: true,
      name: G.ifElse(isClo, 'titles:clo-number', 'titles:tel-number'),
      customComponent: ({ data }: Object) => (
        <PrimaryReferenceCell
          data={data}
          isClo={isClo}
          openFixedPopup={openFixedPopup}
        />
      ),
    },
    [GC.FIELD_BRANCH]: {
      width: 200,
      searchable: true,
      name: 'titles:branch',
      customComponent: ({ data }: Object) => <BranchCell data={data} isClo={isClo} openFixedPopup={openFixedPopup} />,
    },
    [GC.FIELD_STATUS]: {
      width: 120,
      searchable: true,
      name: 'titles:status',
      customComponent: ({ data }: Object) => (
        <StatusCell isClo={isClo} data={data} openFixedPopup={openFixedPopup} closeFixedPopup={closeFixedPopup} />
      ),
    },
    [claimName]: {
      width: 150,
      searchable: true,
      name: 'titles:claim',
      customComponent: ({ data }: Object) => (
        <ClaimCell isClo={isClo} data={data} openFixedPopup={openFixedPopup} closeFixedPopup={closeFixedPopup} />
      ),
    },
    [GC.FIELD_FIRST_PICKUP]: {
      width: 200,
      searchable: true,
      name: 'titles:first-pickup',
      customComponent: ({ data }: Object) => <FirstPickupCell data={data} />,
    },
    [GC.FIELD_LAST_DROP]: {
      width: 200,
      searchable: true,
      name: 'titles:last-drop',
      customComponent: ({ data }: Object) => <LastDropCell data={data} />,
    },
    [GC.FIELD_LOAD_CARRIER_FLEET]: {
      width: 200,
      searchable: true,
      name: 'titles:carrier-fleet',
      customComponent: ({ data }: Object) => {
        if (isClo) {
          return (
            <CloCarrierFleetCell
              data={data}
              isClo={isClo}
              openFixedPopup={openFixedPopup}
              closeFixedPopup={closeFixedPopup}
            />
          );
        }

        return (
          <TelCarrierFleetCell
            data={data}
            isClo={isClo}
            openFixedPopup={openFixedPopup}
            closeFixedPopup={closeFixedPopup}
          />
        );
      },
    },
    [GC.FIELD_LOAD_EARNINGS]: {
      width: 225,
      name: 'titles:earnings',
      customComponent: ({ data }: Object) => {
        const options = {
          data,
          toggle,
          autodialApp,
          configCurrency,
          openFixedPopup,
        };

        if (isClo) {
          return <CloEarningsCell {...options} />;
        }

        return <TelEarningsCell {...options} />;
      },
    },
    [containerColumnName]: {
      width: 200,
      searchable: true,
      name: 'titles:containers',
      customComponent: ({ data }: Object) => {
        const telGuid = G.getGuidFromObject(data);
        const containersInfo = R.pathOr([], [GC.FIELD_CONTAINERS_INFO], data);

        const allCompleted = G.ifElse(
          G.isNotNilAndNotEmpty(containersInfo),
          R.all(R.propEq(ENUMS.ENUM_COMPLETED, 'railBillingStatus'), containersInfo),
          false,
        );

        return (
          <Flex height='auto' maxHeight='100%' overflow='auto' flexDirection='column'>
            {
              allCompleted &&
              <Box title={G.getWindowLocale('titles:rail-billing-completed', 'Rail Billing Completed')}>
                {I.checkMarkInRound()}
              </Box>
            }
            <Box>
              {
                containersInfo.map(({ guid, loadGuid, containerNumber, containerInitial }: Object) => (
                  <Span
                    key={guid}
                    cursor='pointer'
                    color={G.getTheme('colors.dark.blue')}
                    onClick={() => handleUpdateContainer({ guid, telGuid, loadGuid })}
                  >
                    {`${G.createStringFromArray([containerInitial, containerNumber], '')} `}
                  </Span>
                ))
              }
            </Box>
          </Flex>
        );
      },
    },
    [GC.FIELD_MODE]: {
      width: 200,
      searchable: true,
      name: 'titles:mode',
    },
  };
};

export const getColumnSettings = (props: Object) => {
  const {
    toggle,
    loadType,
    openModal,
    closeModal,
    autodialApp,
    actionPanel,
    selectedList,
    notifications,
    openFixedPopup,
    configCurrency,
    closeFixedPopup,
    handleSelectLoad,
    hideNotifications,
    handleUpdateContainer,
    handleCallStatusCheck,
    handleMessageNoteClick,
    handleShowLoadReferences,
    markAsReadTelNotification,
  } = props;

  const commonColumnSettings = getCommonColumnSettings(
    loadType,
    configCurrency,
    openFixedPopup,
    autodialApp,
    toggle,
    closeFixedPopup,
    handleUpdateContainer,
  );

  return {
    ...commonColumnSettings,
    [C.TOGGLE_SHIP_UNIT_CELL]: {
      width: 30,
      customComponent: ({ data }: Object) => (
        <ToggleShipUnitCell data={data} toggle={toggle} />
      ),
    },
    [C.TOGGLE_EVENTS_CELL]: {
      width: 30,
      customComponent: ({ data }: Object) => (
        <ToggleEventsCell data={data} toggle={toggle} />
      ),
    },
    [C.LEFT_TABLE_CELL]: {
      width: 80,
      customComponent: ({ data }: Object) => (
        <CheckboxIconsCell
          data={data}
          toggle={toggle}
          selectedList={selectedList}
          notifications={notifications}
          openFixedPopup={openFixedPopup}
          closeFixedPopup={closeFixedPopup}
          handleSelectLoad={handleSelectLoad}
          hideNotifications={hideNotifications}
          markAsReadTelNotification={markAsReadTelNotification}
        />
      ),
    },
    [C.ACTIONS_CELL]: {
      width: 60,
      customComponent: ({ data }: Object) => (
        <StatusActionsCell
          data={data}
          loadType={loadType}
          openFixedPopup={openFixedPopup}
          closeFixedPopup={closeFixedPopup}
          handleCallStatusCheck={handleCallStatusCheck}
          statusActionsObject={R.pathOr(null, [data[GC.FIELD_STATUS]], actionPanel)}
        />
      ),
    },
    [C.MESSAGES_NOTES_CELL]: {
      width: 70,
      customComponent: ({ data }: Object) => (
        <MessagesNotesCell
          data={data}
          loadType={loadType}
          openModal={openModal}
          closeModal={closeModal}
          clickHandler={handleMessageNoteClick}
          handleShowLoadReferences={handleShowLoadReferences}
        />
      ),
    },
  };
};

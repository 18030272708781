import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature config
import {
  carrierSynchronizationFields,
  defaultCarrierSynchronizationFields,
  carrierSynchronizationValidationSchema } from '../settings';
//////////////////////////////////////////////////

const getFieldsSettingsByIntegrationType = (integrationType: string) => {
  const rejectFieldsMap = {
    [GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS]: [GC.FIELD_USERNAME_CAMEL_CASE],
    [GC.CARRIER_SYNCHRONIZATION_TYPE_MY_CARRIER_PACKETS]: [GC.FIELD_CLIENT_ID, GC.FIELD_REMOVE_AFTER_INACTIVITY_DAYS],
  };

  const fieldSettings = R.reject(({ fieldName }: Object) => R.includes(
    fieldName,
    R.propOr([], integrationType, rejectFieldsMap),
  ), carrierSynchronizationFields);

  return fieldSettings;
};

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: carrierSynchronizationValidationSchema,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultCarrierSynchronizationFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
  }),
  pure,
);

const CarrierSynchronizationForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      fieldsWrapperStyles={{ pt: 11 }}
      handlers={{ handleDisableIntegrationType: () => props.isEditMode }}
      fields={getFieldsSettingsByIntegrationType(props.values.integrationType)}
    />
    <FormFooter />
  </form>
);

export default enhance(CarrierSynchronizationForm);

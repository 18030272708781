import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withState, withHandlers, renderNothing } from 'react-recompose';
// components
import { DetailsEvents } from '../../../../components/events';
import { ConfirmComponent } from '../../../../components/confirm';
import { openModal, closeModal } from '../../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// features
import { AuthWrapper } from '../../../permission';
import PC from '../../../permission/role-permission';
import { makeSelectInitialDataLoadedStatus } from '../../../permission/selectors';
import { makeSelectAvailableReferenceTypesByScope } from '../../../reference/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withFixedPopover } from '../../../../hocs';
// ui
import { Box, Grid } from '../../../../ui';
// feature dispatch details new
import * as C from '../../constants';
import ActionsCenter from './actions-center';
import LoadHeader from '../../components/load-header';
import PageHeader from '../../components/page-header';
import TableGroupWrapper from './table-group-wrapper';
import { OrderCardWrapper } from '../../components/cards';
import RelatedDataTable from '../../components/related-data';
import ToggleComponent from '../../components/toggle-component';
import { withOrderPageActions } from '../hocs/with-page-actions';
import { getColumnSettings } from '../../settings/related-data-table-settings';
import { withRightLoadHeaderActions } from '../hocs/with-right-load-header-actions';
import {
  changeActiveList,
  deleteTelFromCloRequest,
  getLinkedLoadListRequest,
  getRelatedLoadListRequest,
  getOrderTransportationModeGroupingListSuccess,
} from '../actions';
import {
  makeSelectEvents,
  makeSelectFirstList,
  makeSelectSecondList,
  makeSelectHiddenTabs,
  makeSelectLoadConfigs,
  makeSelectActiveLists,
  makeSelectOrderDetails,
  makeSelectExpandedLists,
  makeSelectLoadDocuments,
  makeSelectInvoiceTotals,
  makeSelectLinkedLoadList,
  makeSelectActiveListNames,
  makeSelectRelatedLoadList,
  makeSelectOrderTotalWeight,
  makeSelectOrderItemsVolume,
  makeSelectOrderTotalDistance,
  makeSelectCustomerInvoiceList,
  makeSelectSelectedCustomerRate,
  makeSelectRelatedLoadListLoading,
  makeSelectOrderTransportationModeGroupingList,
} from '../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withFixedPopover,
  withState('relatedDataOpened', 'setRelatedDataOpened', true),
  withHandlers({
    handleToggleRelatedData: (props: Object) => () => {
      const {
        relatedData,
        orderDetails,
        linkedLoadList,
        relatedDataOpened,
        setRelatedDataOpened,
        getLinkedLoadListRequest,
        getRelatedLoadListRequest,
      } = props;

      if (relatedDataOpened) return setRelatedDataOpened(false);

      const guid = G.getGuidFromObject(orderDetails);

      if (R.isNil(relatedData)) {
        getRelatedLoadListRequest(guid);
      }

      if (R.isNil(linkedLoadList)) {
        getLinkedLoadListRequest(guid);
      }

      setRelatedDataOpened(true);
    },
    handleDeleteTel: (props: Object) => (guid: string) => {
      const {
        openModal,
        closeModal,
        deleteTelFromCloRequest } = props;
      const component = (
        <ConfirmComponent textLocale={G.getWindowLocale('messages:tel-list:delete-tel')} />
      );
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                deleteTelFromCloRequest(guid);
                closeModal();
              },
            },
          ],
        },
      };
      openModal(modal);
    },
  }),
  branch(
    (props: Object) => R.or(
      R.not(props.initialDataLoaded),
      G.isNilOrEmpty(props.orderDetails),
    ),
    renderNothing,
  ),
  pure,
);

const cards = [
  {
    card: C.CARD_TYPE_PAYMENTS,
    permissions: [
      PC.CLO_RATE_READ,
      PC.CLO_RATE_WRITE,
      PC.CLO_BILL_TO_READ,
      PC.CLO_BILL_TO_WRITE,
    ],
  },
  {
    card: C.CARD_TYPE_DISPATCH,
    permissions: [PC.FLEET_RATE_READ, PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_READ, PC.CARRIER_RATE_WRITE],
  },
  {
    card: C.CARD_TYPE_DOCUMENTS,
    permissions: [PC.CLO_DOCUMENT_READ, PC.CLO_DOCUMENT_WRITE],
  },
  {
    card: C.CARD_TYPE_MESSAGE_CENTER,
  },
];

const EnhancedLoadHeader = withRightLoadHeaderActions(LoadHeader);
const EnhancedPageHeader = withFixedPopover(withOrderPageActions(PageHeader), true);

const getPrimaryReferenceValue = R.compose(
  R.path([GC.FIELD_VALUE]),
  R.find(R.propEq(true, GC.FIELD_PRIMARY)),
  R.pathOr([], [GC.FIELD_REFERENCES]),
);

const GeneralTabComponent = (props: Object) => {
  const {
    events,
    invoices,
    openModal,
    closeModal,
    openLoader,
    hiddenTabs,
    itemsVolume,
    closeLoader,
    loadConfigs,
    activeLists,
    relatedData,
    orderDetails,
    setActiveTab,
    invoiceTotals,
    expandedLists,
    loadDocuments,
    linkedLoadList,
    openFixedPopup,
    activeListNames,
    handleDeleteTel,
    closeFixedPopup,
    orderTotalWeight,
    changeActiveList,
    relatedDataOpened,
    availableRefTypes,
    orderTotalDistance,
    relatedDataLoading,
    selectedCustomerRate,
    handleToggleRelatedData,
    getRelatedLoadListRequest,
    transportationModeGroupingList,
    getTransportationModeGroupingListSuccess,
  } = props;

  const eventsToUse = R.map(
    (event: Object) => R.assoc(
      GC.FIELD_LOAD_REFERENCES,
      R.filter(
        R.propEq(G.getGuidFromObject(event), GC.FIELD_EVENT_GUID),
        R.pathOr([], [GC.FIELD_LOAD_REFERENCES], orderDetails),
      ),
      event,
    ),
    events,
  );
  const referenceTypes = R.map(
    (item: Object) => ({ label: R.prop(GC.FIELD_NAME, item), value: R.prop(GC.FIELD_GUID, item) }),
    availableRefTypes,
  );
  const configCurrency = G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DEFAULT_CURRENCY);
  const autodialApp = G.getConfigValueFromStore(
    GC.UI_LOADBOARD_AUTODIALAPPNAME,
    R.path(['configGroups', GC.UI_CONFIG_GROUP, 'configs'], loadConfigs),
  );
  const commonComponentProps = {
    openModal,
    closeModal,
    openLoader,
    closeLoader,
    load: orderDetails,
    selectedCustomerRate,
  };
  const cardProps = {
    ...commonComponentProps,
    invoices,
    loadConfigs,
    autodialApp,
    invoiceTotals,
    loadDocuments,
  };
  const tableGroupProps = {
    ...commonComponentProps,
    events,
    hiddenTabs,
    itemsVolume,
    loadConfigs,
    loadDocuments,
    orderTotalWeight,
    changeActiveList,
    orderTotalDistance,
    selectedCustomerRate,
  };
  const loadHeaderProps = {
    openModal,
    closeModal,
    openLoader,
    loadConfigs,
    closeLoader,
    configCurrency,
    load: orderDetails,
    fromPage: 'details',
    transportationModeGroupingList,
    totalDistance: orderTotalDistance,
    selectedRate: selectedCustomerRate,
    getTransportationModeGroupingListSuccess,
    customerRateTransportationModeGuid: R.path([GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID], selectedCustomerRate),
    approvalRequired: G.getConfigValueFromStore(
      GC.CLO_GENERAL_APPROVAL_REQUIRED,
      R.path(['configGroups', GC.CLO_CONFIG_GROUP], loadConfigs),
    ),
  };
  const pageHeaderProps = {
    openModal,
    setActiveTab,
    load: orderDetails,
    [GC.FIELD_PRIMARY_REFERENCE_VALUE]: getPrimaryReferenceValue(orderDetails),
    showFast: G.getConfigValueFromStore(GC.UI_SHOW_FAST_LOAD_INDICATOR, loadConfigs.configsByNames),
  };
  const actionCenterProps = {
    ...commonComponentProps,
    itemsVolume,
    loadConfigs,
    orderTotalWeight,
    orderTotalDistance,
    selectedCustomerRate,
    transportationModeGroupingList,
    getTransportationModeGroupingListSuccess,
  };
  const loadGuid = G.getGuidFromObject(orderDetails);
  const branchGuid = G.getBranchGuidFromObject(orderDetails);
  const loadType = G.getPropFromObject(GC.FIELD_LOAD_TYPE, orderDetails);
  const whiteColor = G.getTheme('colors.white');
  const greyBoxShadow = G.getTheme('colors.boxShadowGrey');
  const relatedDataList = R.concat(R.or(relatedData, []), R.or(linkedLoadList, []));

  return (
    <Box mx='auto' maxWidth={1550}>
      <EnhancedPageHeader {...pageHeaderProps} activeTab='general' />
      <Box
        mb={25}
        bg={whiteColor}
        borderRadius='4px'
        boxShadow={greyBoxShadow}
      >
        <EnhancedLoadHeader {...loadHeaderProps} />
        <DetailsEvents
          loadType={loadType}
          loadGuid={loadGuid}
          events={eventsToUse}
          branchGuid={branchGuid}
          loads={orderDetails.tels}
          referenceTypes={referenceTypes}
          fromPage={GC.PAGE_DISPATCH_DETAILS_NEW_ORDER} />
        <AuthWrapper has={[PC.TEL_READ, PC.TEL_WRITE]}>
          <ToggleComponent
            opened={relatedDataOpened}
            toggle={handleToggleRelatedData}
            openedText={G.getWindowLocale('titles:hide-related-trips', 'Hide Related Trips')}
            closedText={G.getWindowLocale('titles:show-related-trips', 'Show Related Trips')} />
        </AuthWrapper>
        {
          relatedDataOpened &&
          <AuthWrapper display='block' has={[PC.TEL_READ, PC.TEL_WRITE]}>
            <RelatedDataTable
              loadType={loadType}
              itemList={relatedDataList}
              loading={relatedDataLoading}
              configCurrency={configCurrency}
              handleDeleteTel={handleDeleteTel}
              totalCount={R.pathOr(0, ['length'], relatedData)}
              columnSettings={
                getColumnSettings({
                  openModal,
                  closeModal,
                  configCurrency,
                  openFixedPopup,
                  closeFixedPopup,
                  loadType: GC.LOAD_TYPE_TEL,
                  refresh: () => getRelatedLoadListRequest(loadGuid),
                })
              }
            />
          </AuthWrapper>
        }
      </Box>
      <Box
        mb={25}
        bg={whiteColor}
        borderRadius='4px'
        boxShadow={greyBoxShadow}
      >
        <ActionsCenter {...actionCenterProps} />
      </Box>
      <Grid
        gridGap={20}
        gridTemplateColumns='repeat(auto-fill, minmax(320px, 1fr))'
      >
        {
          cards.map(({ card, permissions }: Object, index: number) => (
            <AuthWrapper key={index} width='100%' has={permissions}>
              <OrderCardWrapper {...cardProps} cardType={card} />
            </AuthWrapper>
          ))
        }
      </Grid>
      {
        activeListNames.map((activeListName: string, index: number) => (
          <TableGroupWrapper
            {...tableGroupProps}
            key={index}
            tableNumber={index}
            activeListName={activeListName}
            expandedList={expandedLists[index]}
            itemList={R.path([activeListName, 'list'], activeLists)}
            totalCount={R.path([activeListName, 'totalCount'], activeLists)}
            pagination={R.path([activeListName, 'pagination'], activeLists)}
          />
        ))
      }
    </Box>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  events: makeSelectEvents(state),
  firstList: makeSelectFirstList(state),
  secondList: makeSelectSecondList(state),
  hiddenTabs: makeSelectHiddenTabs(state),
  loadConfigs: makeSelectLoadConfigs(state),
  activeLists: makeSelectActiveLists(state),
  orderDetails: makeSelectOrderDetails(state),
  loadDocuments: makeSelectLoadDocuments(state),
  relatedData: makeSelectRelatedLoadList(state),
  expandedLists: makeSelectExpandedLists(state),
  invoiceTotals: makeSelectInvoiceTotals(state),
  invoices: makeSelectCustomerInvoiceList(state),
  itemsVolume: makeSelectOrderItemsVolume(state),
  linkedLoadList: makeSelectLinkedLoadList(state),
  activeListNames: makeSelectActiveListNames(state),
  orderTotalWeight: makeSelectOrderTotalWeight(state),
  orderTotalDistance: makeSelectOrderTotalDistance(state),
  selectedCustomerRate: makeSelectSelectedCustomerRate(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  relatedDataLoading: makeSelectRelatedLoadListLoading(state),
  availableRefTypes: makeSelectAvailableReferenceTypesByScope(state),
  transportationModeGroupingList: makeSelectOrderTransportationModeGroupingList(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  openLoader,
  closeLoader,
  changeActiveList,
  deleteTelFromCloRequest,
  getLinkedLoadListRequest,
  getRelatedLoadListRequest,
  getTransportationModeGroupingListSuccess: getOrderTransportationModeGroupingListSuccess,
})(enhance(GeneralTabComponent));

import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withState, withPropsOnChange } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature config
import {
  userMonitoringFieldsMap,
  userMonitoringFieldKeysGroup,
  getUserMonitoringValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    validationSchema: getUserMonitoringValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => {
      const data = R.compose(
        R.mergeRight(values),
        R.pick(GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR),
        R.pathOr({}, ['initialValues']),
      )(props);

      props.submitAction(data);
    },
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      { [GC.FIELD_USER_MONITORING_APP]: GC.USER_MONITORING_APP_INTEGRATION_TYPE_SMARTLOOK },
      initialValues,
    ),
  }),
  withState('prevProps', 'setPrevProps', {}),
  withPropsOnChange(
    (props: Object, nextProps: Object) => {
      const currentApp = R.path(['values', GC.FIELD_USER_MONITORING_APP], props);
      const nextApp = R.path(['values', GC.FIELD_USER_MONITORING_APP], nextProps);

      return G.notEquals(currentApp, nextApp);
    },
    ({ values, setValues }: Object) => {
      const monitoringApp = G.getPropFromObject(GC.FIELD_USER_MONITORING_APP, values);
      const fields = R.compose(
        R.prepend(G.getPropFromObject(GC.FIELD_USER_MONITORING_APP, userMonitoringFieldsMap)),
        R.map((key: string) => G.getPropFromObject(key, userMonitoringFieldsMap)),
        R.pathOr([], [monitoringApp]),
      )(userMonitoringFieldKeysGroup);
      const defaultValues = R.compose(
        R.map(R.pathOr(null, ['defaultValue'])),
        R.indexBy(R.prop('fieldName')),
      )(fields);
      setValues(R.mergeRight(defaultValues, R.pick(R.keys(defaultValues), values)));

      return {
        fields,
      };
    },
  ),
  pure,
);

const UserMonitoringFormComponent = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...props.optionsForSelect}
      {...G.getFormikProps(props)}
      fields={R.pathOr([], ['fields'], props)}
      optionsForSelect={props.optionsForSelect}
      fieldsWrapperStyles={{ pt: 11, flexDirection: 'column' }}
      handlers={{ handleDisableIntegrationType: () => props.isEditMode }}
    />
    <FormFooter />
  </form>
);

export default enhance(UserMonitoringFormComponent);

import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import {
  ACCESSORIALS_RATE_TYPE_CONTAIN,
  getDistanceCalculatorConfigIntegrationTypes,
  DEFAULT_DRIVER_ASSESSORIAL_RATE_TYPE_OPTIONS,
  getDistanceCalculatorConfigCalculateByOptions,
} from '../../../../helpers/options';
//////////////////////////////////////////////////

const stringNotRequired = Yup.string().nullable(true);
const stringRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

const inputWrapperStyles = {
  mb: 25,
  width: 270,
};

export const defaultDistanceCalculatorConfigFields = {
  [GC.FIELD_NAME]: null,
  [GC.FIELD_LOGIN]: null,
  [GC.FIELD_API_KEY]: null,
  [GC.FIELD_PASSWORD]: null,
  [GC.FIELD_CALCULATE_BY]: null,
  [GC.FIELD_COMPANY_CODE]: null,
  [GC.FIELD_API_KEY_NAME]: null,
  [GC.FIELD_INTEGRATION_TYPE]: GC.DISTANCE_CALCULATION_TYPE_GOOGLE,
};

const distanceCalculatorTypesWithApiKey = [
  GC.DISTANCE_CALCULATION_TYPE_GOOGLE,
  GC.DISTANCE_CALCULATION_TYPE_AMAZON,
  GC.DISTANCE_CALCULATION_TYPE_TOM_TOM,
  GC.DISTANCE_CALCULATION_TYPE_PC_MILER,
];

export const distanceCalculatorConfigFieldSettings = [
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_NAME,
    label: ['titles:config-name'],
  },
  {
    type: 'select',
    inputWrapperStyles,
    label: ['titles:integration-type'],
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    customDisabledFunction: 'handleDisableIntegrationType',
    options: getDistanceCalculatorConfigIntegrationTypes(),
  },
  {
    type: 'text',
    label: ['titles:api-key'],
    fieldName: GC.FIELD_API_KEY,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: ({ isEditMode, values: { integrationType } = {} }: Object) => {
        const elseCond = R.includes(integrationType, distanceCalculatorTypesWithApiKey);

        return G.ifElse(
          isEditMode,
          'none',
          G.ifElse(
            elseCond,
            'block',
            'none',
          ),
        );
      },
    },
  },
  {
    type: 'text',
    label: ['titles:login'],
    fieldName: GC.FIELD_LOGIN,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: ({ values: { integrationType } = {} }: Object) => G.ifElse(
        R.equals(integrationType, GC.DISTANCE_CALCULATION_TYPE_PRO_MILES),
        'block',
        'none',
      ),
    },
  },
  {
    type: 'text',
    label: ['titles:password'],
    fieldName: GC.FIELD_PASSWORD,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: ({ values: { integrationType } = {} }: Object) => G.ifElse(
        R.includes(
          integrationType,
          [GC.DISTANCE_CALCULATION_TYPE_PRO_MILES, GC.DISTANCE_CALCULATION_TYPE_RAND_MCNALLY],
        ),
        'block',
        'none',
      ),
    },
  },
  {
    type: 'text',
    label: ['titles:company-code'],
    fieldName: GC.FIELD_COMPANY_CODE,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: ({ values: { integrationType } = {} }: Object) => G.ifElse(
        R.includes(
          integrationType,
          [GC.DISTANCE_CALCULATION_TYPE_PRO_MILES, GC.DISTANCE_CALCULATION_TYPE_RAND_MCNALLY],
        ),
        'block',
        'none',
      ),
    },
  },
  {
    type: 'select',
    label: ['titles:calculate-by'],
    fieldName: GC.FIELD_CALCULATE_BY,
    options: getDistanceCalculatorConfigCalculateByOptions(),
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: ({ values: { integrationType } = {} }: Object) => G.ifElse(
        R.equals(integrationType, GC.DISTANCE_CALCULATION_TYPE_RAND_MCNALLY),
        'block',
        'none',
      ),
    },
  },
  {
    label: ['titles:api-key-name'],
    fieldName: GC.FIELD_API_KEY_NAME,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: ({ values: { integrationType } = {} }: Object) => G.ifElse(
        R.equals(integrationType, GC.DISTANCE_CALCULATION_TYPE_AMAZON),
        'block',
        'none',
      ),
    },
  },
];

export const getDistanceCalculatorConfigValidationSchema = (isEditMode: boolean, integrationType: string) => {
  let schema = {
    [GC.FIELD_NAME]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt()),
    [GC.FIELD_INTEGRATION_TYPE]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt()),
  };

  if (R.and(G.isFalse(isEditMode), R.includes(integrationType, distanceCalculatorTypesWithApiKey))) {
    schema = R.assoc(
      GC.FIELD_API_KEY,
      Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
      schema,
    );
  }

  if (R.equals(integrationType, GC.DISTANCE_CALCULATION_TYPE_PRO_MILES)) {
    schema = R.mergeRight(
      schema,
      {
        [GC.FIELD_LOGIN]: stringRequired,
        [GC.FIELD_COMPANY_CODE]: stringRequired,
        [GC.FIELD_PASSWORD]: G.ifElse(G.isTrue(isEditMode), stringNotRequired, stringRequired),
      },
    );
  }

  if (R.equals(integrationType, GC.DISTANCE_CALCULATION_TYPE_RAND_MCNALLY)) {
    schema = R.mergeRight(
      schema,
      {
        [GC.FIELD_COMPANY_CODE]: stringRequired,
        [GC.FIELD_CALCULATE_BY]: stringRequired,
        [GC.FIELD_PASSWORD]: G.ifElse(G.isTrue(isEditMode), stringNotRequired, stringRequired),
      },
    );
  }

  if (R.equals(integrationType, GC.DISTANCE_CALCULATION_TYPE_AMAZON)) {
    schema = R.assoc(GC.FIELD_API_KEY_NAME, G.yupStringRequired, schema);
  }

  return schema;
};

export const defaultAccessorialSectionFields = {
  [GC.FIELD_CHARGE_RATE_NAME]: '',
  [GC.FIELD_CHARGE_RATE_TYPE]: '',
  [GC.FIELD_CHARGE_RATE_UNIT]: '',
  [GC.FIELD_ADVANCE_PAYMENT]: false,
  [GC.FIELD_ACCESSORIAL_SERVICE]: '',
  [GC.FIELD_CHARGE_NON_TAXABLE]: false,
  [GC.FIELD_CHARGE_RATE_NAME_STORED]: '',
  [GC.FIELD_CHARGE_INTERNAL_EXPENSE]: false,
  [GC.FIELD_ACCESSORIAL_FUEL_RELATED]: false,
};

export const accessorialValidationSchema = Yup.lazy(({ rateType }: Object) => Yup.object().shape({
  [GC.FIELD_CHARGE_RATE_NAME]: G.yupStringRequired,
  [GC.FIELD_CHARGE_RATE_TYPE]: G.yupStringRequired,
  [GC.FIELD_CHARGE_RATE_NAME_STORED]: G.yupStringRequired,
  [GC.FIELD_CHARGE_RATE_UNIT]: G.ifElse(
    R.includes(rateType, ACCESSORIALS_RATE_TYPE_CONTAIN),
    G.yupStringRequired,
    G.yupStringNotRequired,
  ),
}));

const accessorialInputWrapperStyles = {
  mb: 25,
  width: 200,
};

export const accessorialFieldSettings = [{
  fields: [
    {
      type: 'text',
      isRequired: true,
      fieldName: GC.FIELD_CHARGE_RATE_NAME_STORED,
      label: ['titles:stored-value', 'Stored Value'],
      inputWrapperStyles: accessorialInputWrapperStyles,
    },
    {
      type: 'text',
      isRequired: true,
      fieldName: GC.FIELD_CHARGE_RATE_NAME,
      label: ['titles:default-value', 'Default Value'],
      inputWrapperStyles: accessorialInputWrapperStyles,
    },
    {
      type: 'select',
      isRequired: true,
      shouldCustomChange: true,
      fieldName: GC.FIELD_CHARGE_RATE_TYPE,
      label: ['titles:rate-type', 'Rate Type'],
      inputWrapperStyles: accessorialInputWrapperStyles,
      options: DEFAULT_DRIVER_ASSESSORIAL_RATE_TYPE_OPTIONS,
      customChangeHandler: (event: Object, _: Object, { handleChange, setFieldValue }: Object) => {
        handleChange(event);
        setFieldValue(GC.FIELD_CHARGE_RATE_UNIT, '');
      },
    },
    {
      type: 'select',
      options: 'serviceOptions',
      label: ['titles:service', 'Service'],
      fieldName: GC.FIELD_ACCESSORIAL_SERVICE,
      inputWrapperStyles: accessorialInputWrapperStyles,
    },
    {
      type: 'select',
      options: 'rateUnitOptions',
      fieldName: GC.FIELD_CHARGE_RATE_UNIT,
      label: ['titles:rate-unit', 'Rate Unit'],
      isRequired: ({ values: { rateType }}: Object) => R.includes(rateType, ACCESSORIALS_RATE_TYPE_CONTAIN),
      inputWrapperStyles: {
        ...accessorialInputWrapperStyles,
        display: ({ values: { rateType }}: Object) => G.ifElse(
          R.includes(rateType, ACCESSORIALS_RATE_TYPE_CONTAIN),
          'block',
          'none',
        ),
      },
    },
  ],
},
{
  fields: [
    {
      type: 'toggle',
      fieldName: GC.FIELD_ACCESSORIAL_FUEL_RELATED,
      label: ['titles:fuel-related', 'Fuel Related'],
      inputWrapperStyles: accessorialInputWrapperStyles,
    },
    {
      type: 'toggle',
      fieldName: GC.FIELD_CHARGE_NON_TAXABLE,
      label: ['titles:non-taxable', 'Non Taxable'],
      inputWrapperStyles: accessorialInputWrapperStyles,
    },
    {
      type: 'toggle',
      fieldName: GC.FIELD_CHARGE_INTERNAL_EXPENSE,
      inputWrapperStyles: accessorialInputWrapperStyles,
      label: ['titles:internal-expense', 'Internal Expense'],
    },
    {
      type: 'toggle',
      fieldName: GC.FIELD_ADVANCE_PAYMENT,
      inputWrapperStyles: accessorialInputWrapperStyles,
      label: ['titles:advance-payment', 'Advance Payment'],
    },
  ],
}];

export const loadCustomStatusFieldSettings = [
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:name'],
    fieldName: GC.FIELD_NAME,
  },
  {
    isMulti: true,
    inputWrapperStyles,
    type: 'reactSelect',
    label: ['titles:applicable-for'],
    fieldName: GC.FIELD_APPLICABLE_FOR,
    options: [
      {
        value: GC.LOAD_TYPE_CLO,
        label: G.getWindowLocale('titles:clo', 'Order'),
      },
      {
        value: GC.LOAD_TYPE_TEL,
        label: G.getWindowLocale('titles:tel', 'Trip'),
      },
    ],
  },
  {
    type: 'select',
    inputWrapperStyles,
    label: ['titles:warning-level'],
    fieldName: GC.FIELD_WARNING_LEVEL,
    options: [
      GC.EMPTY_OPTION_OBJECT,
      {
        value: GC.NOTIFICATION_WARNING_LEVEL_TYPE_WARNING,
        label: G.getWindowLocale('titles:warning', 'Warning'),
      },
      {
        value: GC.NOTIFICATION_WARNING_LEVEL_TYPE_CRITICAL,
        label: G.getWindowLocale('titles:critical', 'Critical'),
      },
    ],
  },
];

export const defaultLoadCustomStatusFields = {
  [GC.FIELD_NAME]: null,
  [GC.FIELD_WARNING_LEVEL]: null,
  [GC.FIELD_APPLICABLE_FOR]: null,
};

export const loadCustomStatusValidationSchema = Yup.object().shape({
  [GC.FIELD_NAME]: G.yupStringRequired,
  [GC.FIELD_WARNING_LEVEL]: G.yupStringRequired,
  [GC.FIELD_APPLICABLE_FOR]: G.yupArrayRequired,
});

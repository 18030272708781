import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { makeSelectLoader } from '../../../../components/loader/selectors';
import { openModal, closeModal } from '../../../../components/modal/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature configs
import * as H from '../../helpers';
import ConfigComponent from '../../components/config-component';
import LoadCustomStatusForm from './components/load-custom-status-form';
import DistanceCalculatorConfigForm from './components/distance-calculator-config-form';
import TransportationModeGroupingForm from './components/transportation-mode-grouping-form';
import { getDefaultDropdownConfigFormComponent } from '../../components/dropdown-config-modal';
import { itemPageConfigEnhancer, defaultPageConfigEnhancer, withTransportationModeMapping } from '../../hocs';
import {
  updateConfigsRequest,
  deleteAccessorialRequest,
  createAccessorialRequest,
  updateAccessorialRequest,
  removeLoadCustomStatusRequest,
  restoreInheritedByConfigTypeRequest,
  createOrUpdateLoadCustomStatusRequest,
  removeDistanceCalculatorConfigRequest,
  createDistanceCalculatorConfigRequest,
  updateDistanceCalculatorConfigRequest,
  removeTransportationModeGroupingRequest,
  removeTransportationModeConfigMappingRequest,
  createOrUpdateTransportationModeGroupingRequest,
  createOrUpdateTransportationModeConfigMappingRequest,
} from '../../actions';
import {
  makeSelectConfigOptions,
  makeSelectServicesOptions,
  makeSelectConfigDropdowns,
  makeSelectAccessorialsList,
  makeSelectConfigInitialValues,
  makeSelectConfigGuidsToItemsMap,
  makeSelectConfigInheritedValues,
  makeSelectLoadCustomStatusConfigList,
  makeSelectDistanceCalculatorConfigList,
  makeSelectTransportationModeGroupingList,
  makeSelectTransportationModeConfigMappingList,
} from '../../selectors';
// general
import AccessorialsForm from './components/accessorials-form';
//////////////////////////////////////////////////

const getModalWithComponent = (component: any, title: string, width: any = 320) => ({
  p: 15,
  component,
  options: {
    title,
    width,
    height: 'auto',
    overflow: 'auto',
    maxHeight: '90vh',
  },
});

const enhance = compose(
  defaultPageConfigEnhancer(GC.GENERAL_CONFIG_GROUP),
  itemPageConfigEnhancer,
  withTransportationModeMapping(GC.GENERAL_CONFIG_GROUP),
  withHandlers({
    handleClickEndLabel: (props: Object) => (configName: string, popupTitle: string) => {
      const {
        openModal,
        dropdowns,
        closeModal,
        configOptions,
        createAccessorialRequest,
        handleAddTransportationModeMapping,
        createDistanceCalculatorConfigRequest,
        createOrUpdateLoadCustomStatusRequest,
        createOrUpdateTransportationModeGroupingRequest,
      } = props;

      const title = R.or(popupTitle, 'titles:add-item');
      const modalContent = getDefaultDropdownConfigFormComponent(configName, props);

      if (R.equals(configName, GC.CUSTOM_TRANSPORTATION_MODE_GROUPING_CONFIG)) {
        const component = (
          <TransportationModeGroupingForm
            dropdowns={dropdowns}
            submitAction={createOrUpdateTransportationModeGroupingRequest}
          />
        );

        const modal = {
          component,
          p: '15px 10px 15px 15px',
          options: {
            title: G.getAddTitle(['titles:transportation-mode-grouping', 'Transportation Mode Grouping']),
          },
        };

        return openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_ACCESSORIALS_CONFIG)) {
        const content = (
          <AccessorialsForm
            closeModal={closeModal}
            createAccessorialRequest={createAccessorialRequest}
          />
        );

        const modal = G.getDefaultModalOptions2(content, G.getWindowLocale(title));

        return openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_DISTANCE_CALCULATOR_CONFIG)) {
        const component = (
          <DistanceCalculatorConfigForm
            isEditMode={false}
            closeModal={closeModal}
            optionsForSelect={configOptions}
            submitAction={createDistanceCalculatorConfigRequest}
          />
        );

        const modal = getModalWithComponent(
          component,
          G.getWindowLocale('titles:distance-calculator-config', 'Distance Calculator Config'),
          300,
        );

        return openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_TRANSPORTATION_MODE_MAPPING_CONFIG)) {
        return handleAddTransportationModeMapping();
      }

      if (R.equals(configName, GC.CUSTOM_LOAD_CUSTOM_STATUS_CONFIG)) {
        const component = (
          <LoadCustomStatusForm
            closeModal={closeModal}
            submitAction={createOrUpdateLoadCustomStatusRequest}
          />
        );

        const title = G.getAddTitle(['titles:load-custom-status', 'Load Custom Status']);
        const modal = getModalWithComponent(component, title, 300);

        return openModal(modal);
      }

      const modal = H.getModalOptions(modalContent, title);

      openModal(modal);
    },
    handleEditDistanceCalculatorConfig: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, configOptions, updateDistanceCalculatorConfigRequest } = props;

      const component = (
        <DistanceCalculatorConfigForm
          isEditMode={true}
          closeModal={closeModal}
          initialValues={initialValues}
          optionsForSelect={configOptions}
          submitAction={updateDistanceCalculatorConfigRequest}
        />
      );

      const modal = getModalWithComponent(
        component,
        G.getWindowLocale('titles:distance-calculator-config', 'Distance Calculator Config'),
        300,
      );

      openModal(modal);
    },
    handleEditLoadCustomStatusConfig: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, createOrUpdateLoadCustomStatusRequest } = props;

      const title = G.getEditTitle(['titles:load-custom-status', 'Load Custom Status']);

      const component = (
        <LoadCustomStatusForm
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateLoadCustomStatusRequest}
        />
      );

      const modal = getModalWithComponent(component, title, 300);

      openModal(modal);
    },
    handleEditEntity: (props: Object) => (entity: string, popupTitle: string) => {
      const { openModal, closeModal, updateAccessorialRequest } = props;

      const modalContent = (
        <AccessorialsForm
          shouldUpdate={true}
          initialValues={entity}
          closeModal={closeModal}
          updateAccessorialRequest={updateAccessorialRequest}
        />
      );

      const title = R.or(popupTitle, 'titles:edit-item');
      const modal = G.getDefaultModalOptions2(modalContent, G.getWindowLocale(title));

      openModal(modal);
    },
    handleEditTransportationModeGrouping: (props: Object) => (entity: Object) => {
      const { openModal, dropdowns, createOrUpdateTransportationModeGroupingRequest } = props;

      const component = (
        <TransportationModeGroupingForm
          dropdowns={dropdowns}
          initialValues={entity}
          submitAction={createOrUpdateTransportationModeGroupingRequest}
        />
      );

      const modal = {
        component,
        p: '15px 10px 15px 15px',
        options: {
          title: G.getEditTitle(['titles:transportation-mode-grouping', 'Transportation Mode Grouping']),
        },
      };

      openModal(modal);
    },
    handleRemoveEntity: ({ deleteAccessorialRequest }: Object) => (entityGuid: string) => (
      deleteAccessorialRequest(entityGuid)
    ),
    handleRemoveDistanceCalculatorConfig: ({ removeDistanceCalculatorConfigRequest }: Object) => (guid: string) =>
      removeDistanceCalculatorConfigRequest(guid),
    handleRemoveLoadCustomStatusConfig: ({ removeLoadCustomStatusRequest }: Object) => (guid: string) => {
      removeLoadCustomStatusRequest(guid);
    },
    handleRemoveTransportationModeGrouping: ({ removeTransportationModeGroupingRequest }: Object) => (guid: string) =>
      removeTransportationModeGroupingRequest(guid),
  }),
  pure,
);

const ConfigPageComponent = (props: Object) => (
  <ConfigComponent
    {...props}
    useTabs={true}
    pageTitle='titles:config-general'
    groupSettings={GC.GENERAL_CONFIG_GROUP}
  />
);

const mapStateToProps = (state: Object) => ({
  loader: makeSelectLoader()(state),
  servicesOptions: makeSelectServicesOptions()(state),
  accessorialsList: makeSelectAccessorialsList()(state),
  dropdowns: makeSelectConfigDropdowns(state, GC.GENERAL_CONFIG_GROUP),
  configOptions: makeSelectConfigOptions(state, GC.GENERAL_CONFIG_GROUP),
  loadCustomStatusConfigList: makeSelectLoadCustomStatusConfigList()(state),
  initialValues: makeSelectConfigInitialValues(state, GC.GENERAL_CONFIG_GROUP),
  distanceCalculatorConfigList: makeSelectDistanceCalculatorConfigList()(state),
  guidsToItemsMap: makeSelectConfigGuidsToItemsMap(state, GC.GENERAL_CONFIG_GROUP),
  inheritedValues: makeSelectConfigInheritedValues(state, GC.GENERAL_CONFIG_GROUP),
  transportationModeGroupingList: makeSelectTransportationModeGroupingList()(state),
  transportationModeConfigMappingList: makeSelectTransportationModeConfigMappingList()(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  updateConfigsRequest,
  deleteAccessorialRequest,
  createAccessorialRequest,
  updateAccessorialRequest,
  removeLoadCustomStatusRequest,
  restoreInheritedByConfigTypeRequest,
  createOrUpdateLoadCustomStatusRequest,
  createDistanceCalculatorConfigRequest,
  updateDistanceCalculatorConfigRequest,
  removeDistanceCalculatorConfigRequest,
  removeTransportationModeGroupingRequest,
  removeTransportationModeConfigMappingRequest,
  createOrUpdateTransportationModeGroupingRequest,
  createOrUpdateTransportationModeConfigMappingRequest,
})(enhance(ConfigPageComponent));

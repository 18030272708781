import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, Flex } from '../../../ui';
// features dashboards
import LargeCard from './large-card';
import TotalCard from './total-card';
import MiddleCard from './middle-card';
import { TITLES_MAP, CHART_COLORS } from '../constants';
import {
  chartDateMapper,
  getTotalsForCard,
  getChartTitleWithCurrency,
  getFromLastDaysDescription,
  getChartTickFormatterWithCurrency,
} from '../helpers';
//////////////////////////////////////////////////

const { RADIAL_BAR_COLORS } = CHART_COLORS;

const GeneralCharts = (props: Object) => {
  const { filters, dashboard, currencySymbol } = props;

  const { days } = filters;

  const onTimeData = chartDateMapper(dashboard, 'ON_TIME_PERCENT_PICKUP_AND_DROP');

  const fuelTollSpendData = chartDateMapper(dashboard, 'FUEL_AND_TOLL');

  const orderTripRpmData = chartDateMapper(dashboard, 'CLO_TEL_AVERAGE_RATE_PER_MILE');

  const orderRPMData = chartDateMapper(dashboard, 'CLO_AVERAGE_RATE_PER_MILE');

  const telRPMData = chartDateMapper(dashboard, 'TEL_AVERAGE_RATE_PER_MILE');

  const cloStatusData = R.compose(
    R.map((item: Object) => R.assoc('name', TITLES_MAP[item.name], item)),
    R.pathOr([], ['CLOS_BY_STATUS', 'groups']),
  )(dashboard);

  const telStatusData = R.compose(
    G.mapIndexed((item: Object, index: number) => R.assoc(
      'fill',
      RADIAL_BAR_COLORS[index % RADIAL_BAR_COLORS.length],
      item,
    )),
    R.map((item: Object) => R.assoc('name', TITLES_MAP[item.name], item)),
    R.pathOr([], ['TELS_BY_STATUS', 'groups']),
  )(dashboard);

  const driverAvailabilityData = R.compose(
    R.map((item: Object) => R.assoc('name', TITLES_MAP[item.name], item)),
    R.pathOr([], ['ALLOCATION_FOR_DRIVERS', 'groups']),
  )(dashboard);

  const expensesTotalData = R.compose(
    R.map((item: Object) => R.assoc('name', TITLES_MAP[item.name], item)),
    R.pathOr([], ['TOTAL_EXPENSES', 'groups']),
  )(dashboard);

  const fromLastDaysDescription = getFromLastDaysDescription(days);

  const rpmText = G.getRpmTextByUomSystemConfig();

  return (
    <Flex p={20} alignItems='start'>
      <Box width={1080}>
        <Flex mb={20}>
          <TotalCard
            title={TITLES_MAP.ordersTotal}
            description={fromLastDaysDescription}
            {...getTotalsForCard('TOTAL_CLOS', dashboard)}
          />
          <TotalCard
            totalPrefix={currencySymbol}
            title={TITLES_MAP.orderRatesTotal}
            description={fromLastDaysDescription}
            {...getTotalsForCard('TOTAL_OF_CLO_RATES', dashboard)}
          />
          <TotalCard
            totalPrefix={currencySymbol}
            title={TITLES_MAP.marginTotal}
            description={fromLastDaysDescription}
            {...getTotalsForCard('TOTAL_MARGIN', dashboard)}
          />
          <TotalCard
            title={TITLES_MAP.activeDrivers}
            description={TITLES_MAP.fromYesterday}
            {...getTotalsForCard('TOTAL_ACTIVE_DRIVERS_TODAY', dashboard)}
          />
        </Flex>
        <Flex alignItems='start' flexDirection='column'>
          <LargeCard
            data={onTimeData}
            chartType='AREA_AND_LINE'
            lineName={TITLES_MAP.drops}
            areaName={TITLES_MAP.pickups}
            lineDataKey='lateDropsPercent'
            title={TITLES_MAP.latePercent}
            areaDataKey='latePickupsPercent'
            tickFormatter={(tick: string) => (tick === 0 ? '' : `${tick}%`)}
          />
          <LargeCard
            domainY={[0, 20]}
            chartType='2_LINE'
            data={orderTripRpmData}
            lineName={TITLES_MAP.order}
            line2Name={TITLES_MAP.trip}
            ticksY={[0, 4, 8, 12, 16, 20]}
            lineDataKey='averageCloRatePerMile'
            line2DataKey='averageTelRatePerMile'
            title={`${TITLES_MAP.orderTrip} ${rpmText}`}
            tickFormatter={(tick: string) => (tick === 0 ? '' : `${tick} ${currencySymbol}`)}
          />
          <LargeCard
            chartType='BAR'
            data={orderRPMData}
            title={`${TITLES_MAP.order} ${rpmText}`}
            filterChartType='CLO_AVERAGE_RATE_PER_MILE'
            tickFormatter={(tick: string) => (tick === 0 ? '' : `${tick} ${currencySymbol}`)}
          />
          <LargeCard
            chartType='BAR'
            data={telRPMData}
            reverseColors={true}
            title={`${TITLES_MAP.trip} ${rpmText}`}
            filterChartType='TEL_AVERAGE_RATE_PER_MILE'
            tickFormatter={(tick: string) => (tick === 0 ? '' : `${tick} ${currencySymbol}`)}
          />
        </Flex>
      </Box>
      <Box width={520}>
        <Flex alignItems='start' flexDirection='column'>
          <MiddleCard
            chartType='2_LINE'
            lineDataKey='fuel'
            line2DataKey='toll'
            domainY={[0, 20000]}
            data={fuelTollSpendData}
            lineName={TITLES_MAP.fuel}
            line2Name={TITLES_MAP.toll}
            ticksY={[0, 1000, 4000, 8000, 12000, 16000, 20000]}
            tickFormatter={getChartTickFormatterWithCurrency(currencySymbol)}
            title={getChartTitleWithCurrency(TITLES_MAP.fuelTollSpend, currencySymbol)}
          />
          <MiddleCard
            chartType='PIE'
            showPercent={true}
            data={expensesTotalData}
            title={TITLES_MAP.expensesTotal}
            tooltipFormatter={(value: number) => `${currencySymbol} ${G.toLocaleString(value)}`}
          />
          <MiddleCard
            chartType='PIE'
            data={driverAvailabilityData}
            title={TITLES_MAP.driversAvailability}
            pieProps={{ innerRadius: 70, paddingAngle: 10 }}
          />
          <MiddleCard
            data={telStatusData}
            chartType='RADIAL_BAR'
            title={TITLES_MAP.tripStatus}
            tooltipFormatter={(value: number, name: string, props: Object) => [value, R.path(['payload', 'name'], props)]}
          />
          <MiddleCard
            height={460}
            chartType='SCORE'
            data={cloStatusData}
            title={TITLES_MAP.orderStatus}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

export default GeneralCharts;

import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

export const isPortalTypeCarrier = R.equals(GC.PORTAL_TYPE_CARRIER);

export const isPortalTypeCustomer = R.equals(GC.PORTAL_TYPE_CUSTOMER);

export const isPortalTypeBidQuote = R.equals(GC.PORTAL_TYPE_CARRIER_QUOTE);

export const centerGeoPoint = (location: Object = {}) => ({
  lat: location[GC.FIELD_LATITUDE],
  lng: location[GC.FIELD_LONGITUDE],
});

export const renderFields = (
  checkInModal: boolean,
  geoLocationProcess: boolean,
  fields: Array,
) => {
  const defaultFields = [
    GC.FIELD_ZIP,
    GC.FIELD_CITY,
    GC.FIELD_STATE,
    GC.FIELD_ADDRESS,
    GC.FIELD_COUNTRY,
    GC.FIELD_LATITUDE,
    GC.FIELD_LONGITUDE,
    GC.FIELD_STATUS_MESSAGE_CODE,
  ];
  const pickFieldsWithActiveGeoAccess = [
    GC.FIELD_STATUS_MESSAGE_CODE,
  ];
  const pickFields = [
    GC.FIELD_STATUS_MESSAGE_CODE,
    GC.FIELD_STATUS_MESSAGE_DATE,
  ];
  const currentFields = R.indexBy(R.prop('fieldName'), fields);
  let setFields = R.pick(pickFields, currentFields);
  if (checkInModal) {
    setFields = R.pick(defaultFields, currentFields);
    if (geoLocationProcess) {
      setFields = R.pick(pickFieldsWithActiveGeoAccess, currentFields);
    }
  }
  return R.values(setFields);
};

export const setReasonCodeField = (fields: Array, displayFields: Array) => {
  const currentFields = R.find(R.propEq(GC.FIELD_STATUS_MESSAGE_REASON_CODE, 'fieldName'))(fields);
  return R.append(currentFields, displayFields);
};

export const dropReasonCodeField = (displayFields: Array) => R.values(
  R.omit([GC.FIELD_STATUS_MESSAGE_REASON_CODE], R.indexBy(R.prop('fieldName'), displayFields))
);

export const getStopOptions = (props: Object) => {
  let events = props.mappedEventsForMap;
  const proofType = (
    G.ifElse(
      G.isNotNil(R.path(['statusMessageForm'], props)),
      R.path(['statusMessageForm', 'values', GC.UI_FIELD_STATUS_MESSAGE_PROOF_TYPE], props),
      R.path(['docCarrierForm', 'values', GC.UI_FIELD_STATUS_MESSAGE_PROOF_TYPE], props)
    )
  );
  if (R.isNil(proofType)) return;
  if (R.equals(proofType, GC.DOCUMENT_PROOF_TYPE_POP)) {
    events = R.filter((event: Object) => R.equals(event.eventType, GC.EVENT_TYPE_PICKUP), events);
  }
  if (R.equals(proofType, GC.DOCUMENT_PROOF_TYPE_POD)) {
    events = R.filter((event: Object) => R.equals(event.eventType, GC.EVENT_TYPE_DROP), events);
  }
  return G.addEmptyOptionToDropDown(G.createStopOptions(events, true));
};

export const formatStatusReasonCodeOptions = (items: Array) => {
  if (G.isNilOrEmpty(items)) return [];
  const options = R.compose(
    R.map((item: Object) => ({
      name: item.displayedValue,
      value: R.or(item.parentGuid, item.guid),
    })),
    R.filter((item: Object) => G.isNotNilAndNotEmpty(item)),
  )(items);
  return [{ name: G.getWindowLocale('titles:select-status-reason', 'Select Status Reason...'), value: '' }, ...options];
};

export const formatDocTypeOptions = (items: Array) => {
  if (G.isNilOrEmpty(items)) return [];
  const options = R.compose(
    R.map((item: Object) => ({
      name: item.displayedValue,
      value: R.or(item.parentGuid, item.guid),
    })),
    R.filter((item: Object) => G.isNotNilAndNotEmpty(item)),
  )(items);
  return [{ name: G.getWindowLocale('titles:document-type', 'Document Type...'), value: '' }, ...options];
};

export const makeLocationNameTemplateIdString = R.compose(
  R.join(' - '),
  R.filter(G.isNotNilAndNotEmpty),
  R.values,
  R.pick([GC.FIELD_LOCATION_NAME, GC.FIELD_TEMPLATE_ID]),
  R.path(['stop', GC.SYSTEM_OBJECT_LOCATION]),
);
export const fromSnakeToCamelCase = (str: string) =>
  str.toLowerCase().replace(/_([a-z])/g, (match: string, p1: string) => p1.toUpperCase());

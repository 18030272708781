import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
//////////////////////////////////////////////////

const defaultValues = {
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_NAME]: null,
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_COPY_TO]: null,
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_MESSAGE]: null,
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_SUBJECT]: null,
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_REPLY_TO]: null,
};

const inputWrapperStyles = {
  mb: 25,
  width: 370,
};

const fields = [
  {
    type: 'text',
    isRequired: true,
    label: ['titles:name'],
    fieldName: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_NAME,
    inputWrapperStyles: { ...inputWrapperStyles, mt: 10 },
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:reply-to'],
    fieldName: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_REPLY_TO,
  },
  {
    type: 'multiEmail',
    inputWrapperStyles,
    label: ['titles:copy-to'],
    fieldName: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_COPY_TO,
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:subject'],
    fieldName: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_SUBJECT,
  },
  {
    type: 'textarea',
    inputWrapperStyles,
    label: ['titles:message'],
    fieldName: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_MESSAGE,
  },
];

const validationSchema = Yup.object().shape({
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_COPY_TO]: G.yupArrayNotRequired,
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_MESSAGE]: G.yupStringNotRequired,
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_SUBJECT]: G.yupStringNotRequired,
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_REPLY_TO]: G.yupStringNotRequired.email(G.getShouldBeEmailLocaleTxt()),
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_NAME]: G.yupStringRequired.max(255, G.getShouldBeFromToLocaleTxt(0, 255)),
});

const enhance = compose(
  withFormik({
    validationSchema,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultValues,
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) =>
      props.submitAction({ data: values, method: props.method }),
  }),
  pure,
);

const OnboardingPackageForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2 {...G.getFormikProps(props)} fields={fields} />
    <FormFooter />
  </form>
);

export default enhance(OnboardingPackageForm);

import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../../components/confirm';
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// features
import CustomerRateForm, { EditCustomerRateForm } from '../../../rate/customer';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature dispatch-details-new
import * as H from '../../helpers';
import {
  removeLoadCustomerRateRequest,
  updateLoadCustomerRateRequest,
} from '../actions';
//////////////////////////////////////////////////

const EditCustomerRate = ({ rate, rateGuid, openLoader, closeLoader, ...props }: Object) => {
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    const handleGetInitialValues = async () => {
      openLoader();

      const res = await sendRequest('get', endpointsMap.getCloRateItemEndpoint(rateGuid));

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setInitialValues(data);
      } else {
        setInitialValues(rate);
      }

      closeLoader();
    };

    handleGetInitialValues();
  }, []);

  return <EditCustomerRateForm {...props} initialValues={initialValues} />;
};

export const withAddCustomerRate = compose(
  withHandlers({
    handleAddCustomerRate: (props: Object) => (clo: Object) => {
      const {
        load,
        openModal,
        createLoadCustomerRateRequest,
        loadConfigs: { configsByNames },
      } = props;

      const cloTotalData = H.makeCloTotalData(clo, load);

      const cloGuid = R.path([GC.FIELD_GUID], clo);
      const equipment = R.path([GC.FIELD_LOAD_EQUIPMENT], clo);
      const services = R.pathOr([], [GC.FIELD_LOAD_SERVICES], clo);
      const stopCount = R.pathOr(0, ['cloEvents', 'length'], cloTotalData);

      const equipments = G.ifElse(G.isNilOrEmpty(equipment), [], R.of(Array, equipment));

      const handleSendCloRate = (data: Object) =>
        createLoadCustomerRateRequest(R.assoc(GC.FIELD_CLO_GUID, cloGuid, data));

      const component = (
        <CustomerRateForm
          cloGuid={cloGuid}
          stopCount={stopCount}
          stopsQuantity={stopCount}
          handleSendCloRate={handleSendCloRate}
          totalWeight={cloTotalData.cloTotalWeight}
          totalDistance={cloTotalData.cloTotalDistance}
          branchGuid={R.pathOr('', [GC.FIELD_BRANCH_GUID], clo)}
          totalPickupQuantity={cloTotalData.cloTotalPickupQuantity}
          defaultUomFields={H.getDefaultUomFieldsFromConfigs(configsByNames)}
          services={G.mapArrayOfObjectsToPropArray(GC.FIELD_DROPDOWN_OPTION_GUID, services)}
          equipments={G.mapArrayOfObjectsToPropArray(GC.FIELD_DROPDOWN_OPTION_GUID, equipments)}
        />
      );

      const modal = G.getRateModalWithContent(component);

      openModal(modal);
    },
  }),
  pure,
);

export const withLoadCustomerRateTableGroupRowActions = compose(
  connect(
    null,
    {
      openLoader,
      closeLoader,
      removeLoadCustomerRateRequest,
      updateLoadCustomerRateRequest,
    },
  ),
  withHandlers({
    handleEditCustomerRate: (props: Object) => () => {
      const {
        load,
        entity,
        openModal,
        openLoader,
        closeLoader,
        updateLoadCustomerRateRequest,
        loadConfigs: { configsByNames },
        transportationModeGroupingList,
        getTransportationModeGroupingListSuccess,
      } = props;

      const { guid, cloGuid } = entity;

      const clo = R.find(R.pathEq(cloGuid, [GC.FIELD_GUID]), R.prop(GC.FIELD_CLOS, load));

      const cloTotalData = H.makeCloTotalData(clo, load);

      const handleSendCloRate = (values: Object, loadData: Object) =>
        updateLoadCustomerRateRequest({ loadData, values: R.assoc(GC.FIELD_CLO_GUID, cloGuid, values) });

      const component = (
        <EditCustomerRate
          load={load}
          rate={entity}
          rateGuid={guid}
          cloGuid={cloGuid}
          openLoader={openLoader}
          closeLoader={closeLoader}
          handleSendCloRate={handleSendCloRate}
          totalWeight={cloTotalData.cloTotalWeight}
          totalDistance={cloTotalData.cloTotalDistance}
          branchGuid={R.path([GC.FIELD_BRANCH_GUID], clo)}
          transportationModeGroupingList={transportationModeGroupingList}
          stopsQuantity={R.pathOr(0, ['cloEvents', 'length'], cloTotalData)}
          defaultUomFields={H.getDefaultUomFieldsFromConfigs(configsByNames)}
          getTransportationModeGroupingListSuccess={getTransportationModeGroupingListSuccess}
        />
      );

      const modal = G.getRateModalWithContent(component);

      openModal(modal);
    },
    handleRemoveCustomerRate: (props: Object) => () => {
      const {
        entity,
        openModal,
        removeLoadCustomerRateRequest,
      } = props;

      const textLocale = G.getWindowLocale('messages:delete-confirmation-rate', 'Do you want to delete this Rate?');

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => removeLoadCustomerRateRequest(entity[GC.FIELD_GUID]),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
);

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withHandlers, renderNothing } from 'react-recompose';
// components
import { Table } from '../../components/table';
import { TitlePanel } from '../../components/title-panel';
import { EditReport } from '../../components/edit-report';
import { getConfirmModal } from '../../components/confirm';
import { PageActions } from '../../components/page-actions';
import { openModal, closeModal } from '../../components/modal/actions';
// features
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { reportEnhancer } from '../../report-common';
// icons
import * as I from '../../svgs';
// ui
import { ListWrapper, ZOrderWrapper } from '../../ui';
// feature inspections
import { FILTER_PARAMS } from './settings/filter-params';
import InspectionDetails from './components/inspection-details';
import { tableSettings, columnSettings } from './settings/table-settings';
import {
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectListLoading,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
import {
  selectItem,
  setReports,
  setUsedReport,
  toggleDetails,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemsRequest,
  getItemListRequest,
  setTableTitleFilter,
  updateReportRequest,
  createReportRequest,
  setQuickFilterParams,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
} from './actions';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.mainBlue');

const exportPropertyNameMap = {
  [GC.FIELD_TRUCK]: GC.FIELD_TRUCK_GUID,
  [GC.FIELD_DRIVER]: GC.FIELD_DRIVER_GUID,
  [GC.FIELD_TRAILER]: GC.FIELD_TRAILER_GUID,
};

const enhance = compose(
  reportEnhancer,
  withHandlers({
    handleDeleteItems: (props: Object) => (guid: string) => {
      const { itemList, openModal, closeModal, deleteItemsRequest } = props;

      const selectedList = G.isString(guid) ? R.of(Array, guid) : G.getSelectedItemGuids(itemList);

      if (G.isNilOrEmpty(selectedList)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        submitAction: () => deleteItemsRequest(selectedList),
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: `${G.getWindowLocale('messages:delete-confirmation', 'Do you want to delete this')}?`,
      });

      openModal(modalContent);
    },
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          onReportSet={() => getItemListRequest()}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
    handleShowDetails: (props: Object) => (guid: string, entity: Object) => {
      const { openModal, closeModal } = props;
      const { truckGuid, trailerGuid } = entity;

      const width = 'calc(100vw - 100px)';

      const modal = {
        fixedWidth: true,
        wrapperStyles: { width },
        isExpandedContainer: true,
        component: (
          <InspectionDetails
            guid={guid}
            truckGuid={truckGuid}
            closeModal={closeModal}
            trailerGuid={trailerGuid}
          />
        ),
        options: {
          minWidth: width,
          minHeight: '100vh',
          enableResizing: false,
          default: { x: 0, y: 0, width, height: '100vh' },
          style: { backgroundColor: G.getTheme('colors.white') },
        },
      };

      openModal(modal);
    },
    handleExportReportDataRequest: (props: Object) => (params: Object) => {
      const { fleetType, primaryObjectGuid, openedFromFleetProfile, exportReportDataRequest } = props;

      if (R.equals(fleetType, GC.FIELD_DRIVER)) {
        return exportReportDataRequest(R.assoc(GC.FIELD_DRIVER_GUID, primaryObjectGuid, params));
      }

      if (G.isTrue(openedFromFleetProfile)) {
        const searchCriteria = R.compose(
          R.append({
            dataType: 'string',
            operation: 'equal',
            stringValue: primaryObjectGuid,
            propertyName: R.prop(fleetType, exportPropertyNameMap),
          }),
          R.pathOr([], [GC.FIELD_SEARCH_CRITERIA]),
        )(params);

        return exportReportDataRequest(R.assoc(GC.FIELD_SEARCH_CRITERIA, searchCriteria, params));
      }

      exportReportDataRequest(params);
    },
  }),
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    selectItem,
    pagination,
    reportList,
    filterParams,
    toggleDetails,
    tableMaxHeight,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    handleShowDetails,
    handleDeleteItems,
    getItemListRequest,
    handleTableTitleFilter,
    openedFromFleetProfile,
  } = props;

  const allChecked = G.isAllChecked(itemList);

  const actionButtons = [
    {
      iconName: 'eye2',
      iconColor: blueColor,
      action: handleShowDetails,
    },
    {
      iconName: 'trash',
      iconColor: blueColor,
      enableIfEditable: true,
      action: handleDeleteItems,
    },
  ];

  const tableSettingsToUse = G.isTrue(openedFromFleetProfile)
    ? R.mergeRight(tableSettings, { checkBoxCellWidth: 60, allowSelectItems: false, maxHeight: tableMaxHeight })
    : G.getTableSettingsWithMaxHeightByConditions({
      reportList,
      filterParams,
      tableSettings,
      selectedReport,
    });

  const data = {
    loading,
    itemList,
    allChecked,
    totalCount,
    pagination,
    actionButtons,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    toggle: toggleDetails,
    report: selectedReport,
    handleTableTitleFilter,
    onOptionClick: selectItem,
    useSearchableColumns: true,
    withResizableColumns: true,
    useNewTitleFilterInputs: true,
    tableSettings: tableSettingsToUse,
    handleLoadMoreEntities: getItemListRequest,
    filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), FILTER_PARAMS),
  };

  return <Table {...data} />;
};

const Inspections = enhance((props: Object) => {
  const {
    pl,
    height,
    itemList,
    minHeight,
    maxHeight,
    totalCount,
    handleDeleteItems,
    withoutPageActions,
    getItemListRequest,
    resetListAndPagination,
    handleExportReportDataRequest,
  } = props;

  const listActions = [
    {
      action: handleDeleteItems,
      text: G.getWindowLocale('actions:delete', 'Delete'),
      icon: I.trash(G.getTheme('colors.light.mainLight'), 'transparent'),
    },
  ];

  const reloadAction = () => {
    resetListAndPagination();
    setTimeout(() => getItemListRequest(), 100);
  };

  return (
    <ListWrapper
      p={15}
      pl={pl}
      height={height}
      minHeight={minHeight}
      maxHeight={maxHeight}
      bgColor={G.getTheme('pages.layOutBgColor')}
    >
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          display='flex'
          withCount={true}
          withReload={true}
          popperWithCount={true}
          totalCount={totalCount}
          usePortalForFilters={true}
          filterProps={FILTER_PARAMS}
          reloadAction={reloadAction}
          type={GC.INSPECTION_REPORT}
          hiddenRightFilterInfo={false}
          exportReportDataRequest={handleExportReportDataRequest}
          title={G.getWindowLocale('titles:inspections-report', 'Inspections Report')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        {renderTable(props)}
      </ZOrderWrapper>
      {
        R.not(withoutPageActions) &&
        <PageActions
          version={2}
          listActions={listActions}
          shadowColor={G.getTheme('createButton.shadowColor')}
          count={R.filter(R.prop('selected'), R.or(itemList, [])).length}
        />
      }
    </ListWrapper>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  reportList: makeSelectAvailableReports(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  selectItem,
  setReports,
  setUsedReport,
  toggleDetails,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemsRequest,
  getItemListRequest,
  setTableTitleFilter,
  updateReportRequest,
  createReportRequest,
  setQuickFilterParams,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
})(Inspections);

import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { PAYMENT_TERM_OPTIONS, getTelGlobalSearchOptions } from '../../../helpers/options';
// feature configs
import { MultiselectFieldComponent } from '../ui';
//////////////////////////////////////////////////

const generalTab = [
  {
    title: 'titles:primary-reference',
    fields: {
      [GC.TEL_PRIMARY_REFERENCE_TYPE]: {
        type: 'select',
        options: 'telReferenceTypes',
        name: 'titles:primary-reference-type',
        nameForAttribute: GC.TEL_PRIMARY_REFERENCE_TYPE,
      },
      [GC.TEL_PRIMARY_REFERENCE_AUTOGENERATED]: {
        type: 'switcher',
        name: 'titles:auto-primary-reference',
        nameForAttribute: GC.TEL_PRIMARY_REFERENCE_AUTOGENERATED,
      },
      [GC.TEL_PRIMARY_REFERENCE_SEQUENCE]: {
        type: 'select',
        options: 'sequences',
        name: 'titles:primary-reference-sequence',
        nameForAttribute: GC.TEL_PRIMARY_REFERENCE_SEQUENCE,
      },
      [GC.TEL_PRIMARY_REFERENCE_PREFIX]: {
        type: 'input',
        name: 'titles:primary-reference-prefix',
        nameForAttribute: GC.TEL_PRIMARY_REFERENCE_PREFIX,
      },
      [GC.TEL_PRIMARY_REFERENCE_COPY_FROM_CLO]: {
        type: 'switcher',
        name: 'titles:copy-tel-primary-reference-from-clo',
        nameForAttribute: GC.TEL_PRIMARY_REFERENCE_COPY_FROM_CLO,
      },
      [GC.TEL_GENERAL_COPY_REFERENCES]: {
        zIndex: 13,
        type: 'list',
        options: 'cloReferenceTypes',
        name: 'titles:show-clo-refs-on-tel',
        component: MultiselectFieldComponent,
        nameForAttribute: GC.TEL_GENERAL_COPY_REFERENCES,
      },
      [GC.TEL_GENERAL_COPY_ALL_REFERENCES]: {
        type: 'switcher',
        name: 'titles:show-all-order-refs-on-trip',
        nameForAttribute: GC.TEL_GENERAL_COPY_ALL_REFERENCES,
      },
    },
  },
  {
    title: 'titles:dispatch-board',
    fields: {
      [GC.TEL_DISPATCH_BOARD_USED_GLOBAL_SEARCH_TYPES]: {
        type: 'list',
        component: MultiselectFieldComponent,
        options: getTelGlobalSearchOptions(),
        name: 'titles:used-global-search-types',
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.TEL_DISPATCH_BOARD_USED_GLOBAL_SEARCH_TYPES,
      },
      [GC.TEL_DISPATCH_BOARD_DEFAULT_GLOBAL_SEARCH_TYPE]: {
        type: 'select',
        options: 'defaultTelGlobalSearchOptions',
        name: 'titles:default-global-search-type',
        nameForAttribute: GC.TEL_DISPATCH_BOARD_DEFAULT_GLOBAL_SEARCH_TYPE,
      },
    },
  },
  {
    title: 'titles:fleet-assignment',
    fields: {
      [GC.TEL_FLEET_ASSIGNMENT_DISABLE_AVAILABILITY_CHECK]: {
        type: 'switcher',
        name: 'titles:disable-availability-check',
        nameForAttribute: GC.TEL_FLEET_ASSIGNMENT_DISABLE_AVAILABILITY_CHECK,
      },
    },
  },
  {
    title: 'titles:routebuilder-load-planner',
    fields: {
      [GC.TEL_ROUTE_BUILDER_DEFAULT_CROSSDOCK]: {
        zIndex: 15,
        type: 'list',
        options: 'crossDockLocations',
        name: 'titles:default-cross-docks',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.TEL_ROUTE_BUILDER_DEFAULT_CROSSDOCK,
      },
    },
  },
  {
    title: 'titles:general',
    fields: {
      [GC.TEL_GENERAL_RATE_EXPIRATION_TIMEOUT]: {
        type: 'input',
        validate: G.isEmptyOrNumeric,
        name: 'titles:rate-expiration-timeout',
        nameForAttribute: GC.TEL_GENERAL_RATE_EXPIRATION_TIMEOUT,
      },
      [GC.TEL_GENERAL_TRACKING_NUMBER_REQUIRED_FOR_TRANSPORTATION_MODES]: {
        zIndex: 14,
        type: 'list',
        options: 'transportationModes',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        name: 'titles:tracking-number-required-for-transportation-modes',
        nameForAttribute: GC.TEL_GENERAL_TRACKING_NUMBER_REQUIRED_FOR_TRANSPORTATION_MODES,
      },
      [GC.TEL_CLO_RELATED_DOCUMENTS]: {
        zIndex: 13,
        type: 'list',
        options: 'availableDocumentTypes',
        name: 'titles:clo-related-documents',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.TEL_CLO_RELATED_DOCUMENTS,
      },
      [GC.TEL_GENERAL_SYNC_TRAILERS]: {
        type: 'switcher',
        name: 'titles:sync-trailers',
        nameForAttribute: GC.TEL_GENERAL_SYNC_TRAILERS,
      },
      [GC.TEL_GENERAL_SEND_TENDER_DOCUMENT_ON_ACCEPT]: {
        type: 'switcher',
        name: 'titles:send-tender-document-on-accept',
        nameForAttribute: GC.TEL_GENERAL_SEND_TENDER_DOCUMENT_ON_ACCEPT,
      },
      [GC.TEL_GENERAL_ADJUST_DELIVERY_DATE]: {
        type: 'switcher',
        name: 'titles:adjust-delivery-date',
        nameForAttribute: GC.TEL_GENERAL_ADJUST_DELIVERY_DATE,
      },
      [GC.TEL_GENERAL_ALLOW_ADJUST_DELIVERY_DATE_TO_WEEKEND]: {
        type: 'switcher',
        name: 'titles:allow-adjust-delivery-date-to-weekend',
        nameForAttribute: GC.TEL_GENERAL_ALLOW_ADJUST_DELIVERY_DATE_TO_WEEKEND,
      },
      [GC.TEL_GENERAL_ALLOW_DELIVERY_WITHOUT_PICKUP_COMPLETE]: {
        type: 'switcher',
        name: 'titles:allow-delivery-without-pickup-complete',
        nameForAttribute: GC.TEL_GENERAL_ALLOW_DELIVERY_WITHOUT_PICKUP_COMPLETE,
      },
      [GC.TEL_GENERAL_ALLOW_STOP_COMPLETE_WITHOUT_ARRIVAL]: {
        type: 'switcher',
        name: 'titles:allow-complete-stop-without-arrival',
        nameForAttribute: GC.TEL_GENERAL_ALLOW_STOP_COMPLETE_WITHOUT_ARRIVAL,
      },
      [GC.TEL_GENERAL_CREATE_ROUTE_WITH_TEL]: {
        type: 'switcher',
        name: 'titles:create-route-with-trip',
        nameForAttribute: GC.TEL_GENERAL_CREATE_ROUTE_WITH_TEL,
      },
      [GC.TEL_GENERAL_BILL_TO_REPLACE]: {
        type: 'switcher',
        name: 'titles:bill-to-replace',
        nameForAttribute: GC.TEL_GENERAL_BILL_TO_REPLACE,
      },
      [GC.TEL_GENERAL_BILL_TO_LOCATION]: {
        type: 'input',
        name: 'titles:bill-to-template-id',
        nameForAttribute: GC.TEL_GENERAL_BILL_TO_LOCATION,
      },
      [GC.TEL_GENERAL_BILL_TO_PAYMENT_TERMS]: {
        type: 'select',
        name: 'titles:payment-terms',
        options: PAYMENT_TERM_OPTIONS,
        nameForAttribute: GC.TEL_GENERAL_BILL_TO_PAYMENT_TERMS,
      },
      [GC.TEL_GENERAL_DEFAULT_BRANCH]: {
        type: 'select',
        name: 'titles:default-branch',
        nameForAttribute: GC.TEL_GENERAL_DEFAULT_BRANCH,
        options: [
          {
            value: GC.TEL_DEFAULT_BRANCH_TYPE_USER_BRANCH,
            label: G.getWindowLocale('titles:user-branch', 'User Branch'),
          },
          {
            value: GC.TEL_DEFAULT_BRANCH_TYPE_CURRENT_BRANCH,
            label: G.getWindowLocale('titles:current-branch', 'Current Branch'),
          },
          {
            value: GC.TEL_DEFAULT_BRANCH_TYPE_CUSTOMER_BRANCH,
            label: G.getWindowLocale('titles:customer-branch', 'Customer Branch'),
          },
        ],
      },
      [GC.TEL_GENERAL_SYNCHRONIZE_WITH_CLO]: {
        type: 'switcher',
        name: 'titles:synchronize-with-order',
        nameForAttribute: GC.TEL_GENERAL_SYNCHRONIZE_WITH_CLO,
      },
    },
  },
];

const rateTab = [
  {
    title: 'titles:general',
    fields: {
      [GC.TEL_RATE_RECALCULATE_FUEL_CHARGE_ON_STATUSES]: {
        zIndex: 12,
        type: 'list',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        name: 'titles:recalculate-fuel-charge-on-statuses',
        nameForAttribute: GC.TEL_RATE_RECALCULATE_FUEL_CHARGE_ON_STATUSES,
        options: [
          {
            value: GC.LOAD_STATUS_BOOKED_STATUS,
            label: G.getWindowLocale('titles:load-status-booked', 'Booked'),
          },
          {
            value: GC.LOAD_STATUS_IN_TRANSIT,
            label: G.getWindowLocale('titles:load-status-in-transit', 'In Transit'),
          },
          {
            value: GC.LOAD_STATUS_DELIVERED,
            label: G.getWindowLocale('titles:load-status-delivered', 'Delivered'),
          },
        ],
      },
    },
  },
  {
    title: 'titles:driver-rate',
    fields: {
      [GC.TEL_DRIVER_RATE_SHOW_RAILWAY_SECTION]: {
        type: 'switcher',
        name: 'titles:show-chassis-section',
        nameForAttribute: GC.TEL_DRIVER_RATE_SHOW_RAILWAY_SECTION,
      },
      [GC.TEL_DRIVER_RATE_SHOW_START_FINISH_POINT]: {
        type: 'switcher',
        name: 'titles:show-driver-start-finish-point',
        nameForAttribute: GC.TEL_DRIVER_RATE_SHOW_START_FINISH_POINT,
      },
      [GC.TEL_DRIVER_RATE_DEFAULT_SERVICE_TYPE]: {
        type: 'select',
        options: 'serviceTypes',
        name: 'titles:service-type',
        nameForAttribute: GC.TEL_DRIVER_RATE_DEFAULT_SERVICE_TYPE,
      },
      [GC.TEL_DRIVER_RATE_DEFAULT_MODE]: {
        type: 'select',
        options: 'transportationModes',
        name: 'titles:mode-of-transportation',
        nameForAttribute: GC.TEL_DRIVER_RATE_DEFAULT_MODE,
      },
      [GC.TEL_DRIVER_RATE_TRAILER_REQUIRED]: {
        type: 'switcher',
        name: 'titles:trailer-required',
        nameForAttribute: GC.TEL_DRIVER_RATE_TRAILER_REQUIRED,
      },
      [GC.TEL_DRIVER_RATE_START_POINT_REQUIRED]: {
        type: 'switcher',
        name: 'titles:start-point-required',
        nameForAttribute: GC.TEL_DRIVER_RATE_START_POINT_REQUIRED,
      },
      [GC.TEL_DRIVER_RATE_FINISH_POINT_REQUIRED]: {
        type: 'switcher',
        name: 'titles:finish-point-required',
        nameForAttribute: GC.TEL_DRIVER_RATE_FINISH_POINT_REQUIRED,
      },
      [GC.TEL_RATE_UPDATE_RATE_WHEN_CUSTOMER_RATE_CHANGES]: {
        type: 'switcher',
        name: 'titles:update-rate-when-customer-rate-changes',
        nameForAttribute: GC.TEL_RATE_UPDATE_RATE_WHEN_CUSTOMER_RATE_CHANGES,
      },
    },
  },
  {
    title: 'titles:carrier-rate',
    fields: {
      [GC.TEL_CARRIER_RATE_DEFAULT_SERVICE_TYPE]: {
        type: 'select',
        options: 'serviceTypes',
        name: 'titles:service-type',
        nameForAttribute: GC.TEL_CARRIER_RATE_DEFAULT_SERVICE_TYPE,
      },
      [GC.TEL_CARRIER_RATE_DEFAULT_MODE]: {
        type: 'select',
        options: 'transportationModes',
        name: 'titles:mode-of-transportation',
        nameForAttribute: GC.TEL_CARRIER_RATE_DEFAULT_MODE,
      },
    },
  },
  {
    configType: 'custom',
    title: 'titles:carrier-additional-charges',
    configName: GC.CUSTOM_DEFAULT_CARRIER_ACCESSORIAL_CONFIG,
    customConfigName: GC.CUSTOM_DEFAULT_CARRIER_ACCESSORIAL_CONFIG,
    fields: [
      {
        name: 'titles:accessorial',
        nameForAttribute: GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID,
        customLogic: (_: any, { assessorialGuid }: Object, props: Object) =>
          R.path(['configOptions', 'accessorialConfigList', assessorialGuid, GC.FIELD_DISPLAYED_VALUE], props),
      },
      {
        name: 'titles:rate',
        nameForAttribute: GC.FIELD_CHARGE_RATE,
      },
      {
        name: 'titles:currency',
        nameForAttribute: GC.FIELD_CURRENCY,
      },
      {
        name: 'titles:rate-type',
        nameForAttribute: GC.FIELD_CHARGE_RATE_TYPE,
      },
      {
        name: 'titles:rate-unit',
        nameForAttribute: GC.FIELD_CHARGE_RATE_UNIT,
      },
    ],
  },
];

const ConfigTelGroup = {
  generalTab,
  rateTab,
};

export {
  ConfigTelGroup,
};

import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose, withPropsOnChange } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Fieldset2 } from '../../../forms';
// hocs
import {
  withComponentDidMountCallback,
  withAsyncGetUserGeneralListFullName,
  withAsyncGetDriverListWithAssignment,
  withAsyncTruckListChildrenAndCurrent,
} from '../../../hocs';
// feature dispatch-group
import {
  defaultFields,
  itemFormFields,
  validationSchemaObject,
} from '../settings/field-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withAsyncGetUserGeneralListFullName,
  withAsyncGetDriverListWithAssignment,
  withAsyncTruckListChildrenAndCurrent,
  withComponentDidMountCallback({ callbackName: 'getAsyncUserGeneralListFullName' }),
  withComponentDidMountCallback({ callbackName: 'getAsyncDriverListWithAssignment' }),
  withComponentDidMountCallback({ callbackName: 'getAsyncTruckListChildrenAndCurrent' }),
  withPropsOnChange(['asyncDriverListWithAssignment'], (props: Object) => {
    const { asyncDriverListWithAssignment } = props;

    const options = R.map((item: Object) => {
      const { fullText } = G.getUserInfo(item);

      const value = G.getGuidFromObject(item);

      const truckGuid = R.path(['truck', GC.FIELD_GUID], item);

      return { value, truckGuid, label: fullText };
    }, asyncDriverListWithAssignment);

    return {
      'driverList': options,
    };
  }),
  withPropsOnChange(['asyncTruckListChildrenAndCurrent'], (props: Object) => {
    const { asyncTruckListChildrenAndCurrent } = props;

    const options = G.mapUnitIdGuidObjectPropsToLabelValueObject(asyncTruckListChildrenAndCurrent);

    return {
      'truckList': options,
    };
  }),
  withPropsOnChange(['asyncUserGeneralListFullName'], (props: Object) => {
    const { asyncUserGeneralListFullName } = props;

    const options = R.map((item: Object) => {
      const { fullText } = G.getUserInfo(item);

      const value = G.getGuidFromObject(item);

      return { value, label: fullText };
    }, asyncUserGeneralListFullName);

    return {
      'dispatcherList': options,
    };
  }),
  withFormik({
    enableReinitialize: true,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    validationSchema: Yup.object().shape(validationSchemaObject),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
  }),
  pure,
);

const fieldsWrapperStyles = {
  mt: 15,
  alignItems: 'start',
  justifyContent: 'space-between',
};

const ItemForm = (props: Object) => {
  const { truckList, driverList, handleSubmit, dispatcherList } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={itemFormFields}
        truckList={R.or(truckList, [])}
        driverList={R.or(driverList, [])}
        dispatcherList={R.or(dispatcherList, [])}
        fieldsWrapperStyles={fieldsWrapperStyles}
      />
      <FormFooter2 />
    </form>
  );
};

export default enhance(ItemForm);

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose } from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// components
import { TabsMui } from '../../../../components/tabs-mui';
// features
import { startPivotTable } from '../../../pivot-table/actions';
import { makeSelectPivotTableTotalCount } from '../../../pivot-table/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  pivotTableTotalCount: makeSelectPivotTableTotalCount(state),
});

const enhance = compose(
  connect(mapStateToProps, { startPivotTable }),
  pure,
);

const getTabs = (activeTab: number) => [
  {
    value: 0,
    withCount: R.equals(activeTab, 0),
    text: G.getWindowLocale('titles:fleet-equipment-service', 'Fleet: Equipment Service'),
  },
  {
    value: 1,
    withCount: R.equals(activeTab, 1),
    text: G.getWindowLocale('titles:pivot-table', 'Pivot Table'),
  },
];


export const EquipmentServiceTabs = enhance((props: Object) => {
  const {
    activeTab,
    totalCount,
    entityGuid,
    entityType,
    startPivotTable,
    handleSetActiveTab,
    pivotTableTotalCount,
    openedFromFleetProfile,
  } = props;

  const isList = R.equals(activeTab, 0);

  const payload = openedFromFleetProfile
    ? {
      [GC.REPORT_TYPE]: GC.PIVOT_FLEET_EQUIPMENT_SERVICE_REPORT,
      defaultSearchCriteria: [{
        dataType: 'string',
        operation: 'equal',
        stringValue: entityGuid,
        propertyName: G.ifElse(
          R.equals(entityType, GC.FIELD_TRUCK),
          GC.GRC.FLEET_TRUCK_GUID,
          GC.GRC.FLEET_TRAILER_GUID,
        ),
      }],
    } : GC.PIVOT_FLEET_EQUIPMENT_SERVICE_REPORT;

  const handleClickTab = (i: number) => {
    if (isList) startPivotTable(payload);

    handleSetActiveTab(i);
  };

  return (
    <TabsMui
      activeMuiTab={activeTab}
      tabs={getTabs(activeTab)}
      setActiveMuiTab={handleClickTab}
      tabStyles={GC.COMMON_MUI_TAB_STYLES}
      tabsStyles={GC.COMMON_MUI_TABS_STYLES}
      count={G.ifElse(isList, totalCount, pivotTableTotalCount)}
    />
  );
});

import React from 'react';
import * as R from 'ramda';
// components
import { Tabs2 } from '../../../../components/tabs-mui';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

// TODO: with all tabs when API ready

const getTabs1 = (activeTab: number) => [
  {
    value: 0,
    withCount: R.equals(activeTab, 0),
    text: G.getWindowLocale('titles:ai-upload', 'AI Upload'),
  },
  {
    value: 1,
    withCount: R.equals(activeTab, 1),
    text: G.getWindowLocale('titles:documents', 'Documents'),
  },
  {
    value: 2,
    withCount: R.equals(activeTab, 2),
    text: G.getWindowLocale('titles:notes', 'Notes'),
  },
  // {
  //   value: 3,
  //   withCount: R.equals(activeTab, 3),
  //   text: G.getWindowLocale('titles:notify', 'Notify'),
  // },
];

const getTabs2 = (activeTab: number) => [
  {
    value: 0,
    withCount: R.equals(activeTab, 0),
    text: G.getWindowLocale('titles:documents', 'Documents'),
  },
  {
    value: 1,
    withCount: R.equals(activeTab, 1),
    text: G.getWindowLocale('titles:notes', 'Notes'),
  },
  // {
  //   value: 2,
  //   withCount: R.equals(activeTab, 2),
  //   text: G.getWindowLocale('titles:notify', 'Notify'),
  // },
];

const RightTabs = (props: Object) => {
  const {
    aiEnabled,
    rightActiveTab,
    setRightActiveTab,
  } = props;

  const tabs = G.isTrue(aiEnabled) ? getTabs1(rightActiveTab) : getTabs2(rightActiveTab);

  return (
    <Tabs2
      tabs={tabs}
      activeTab={rightActiveTab}
      setActiveTab={setRightActiveTab}
      tabStyles={GC.COMMON_MUI_TAB_STYLES}
      tabsStyles={{ ...GC.COMMON_MUI_TABS_STYLES, m: '15px' }}
    />
  );
};

export default RightTabs;

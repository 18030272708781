import * as R from 'ramda';
import { sub, format, endOfToday, startOfDay } from 'date-fns';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature dashboards
import {
  DAY_RANGE,
  TITLES_MAP,
  RANGE_TYPE_DAYS,
  RANGE_TYPE_MONTHS,
} from './constants';
//////////////////////////////////////////////////

const getFromLastDaysDescription = (days: any) => G.ifElse(
  R.equals(days, DAY_RANGE),
  TITLES_MAP.fromSelectedRange,
  `${TITLES_MAP.fromLast} ${days} ${TITLES_MAP.days}`,
);

const getTotalsForCard = (chartType: string, dashboard: Object) => {
  const total = R.pathOr(0, [chartType, 'value'], dashboard);
  const prevTotal = R.pathOr(0, [chartType, 'previousPeriodValue'], dashboard);

  return { total, prevTotal };
};

const formatDate = (date: string) => format(new Date(date), 'MM/dd/yyyy');

const getFormattedDateRangeFilters = (days: number, dateFormat: any = 'MM/dd/yyyy') => {
  const to = endOfToday();

  const from = startOfDay(sub(to, { days: R.dec(days) }));

  const dateTo = format(to, dateFormat);

  const dateFrom = format(from, dateFormat);

  return { dateTo, dateFrom };
};

const getDataForAreaAndLineChartFromDashboard = (data: Array) => R.map(
  ({ name, groups }: Object) => {
    const ret = { name };

    groups.forEach((g: Object) => {
      ret[g.name] = g.value;
    });

    return ret;
  },
)(data);

const formatByRangeType = (name: string, rangeType: string) => {
  if (R.equals(rangeType, RANGE_TYPE_DAYS)) {
    return G.checkAndConvertMomentInstanceOrStringToFormattedDate(name, 'MMM D');
  }

  if (R.equals(rangeType, RANGE_TYPE_MONTHS)) {
    return name.substring(0, 3);
  }

  return name;
};

const chartDateMapper = (dashboard: Array, propName: string) => R.compose(
  ({ groups = [], rangeType = RANGE_TYPE_DAYS }: Object) => R.map((item: Object) =>
    R.assoc(
      'name',
      formatByRangeType(item.name, rangeType),
      item,
    ),
    groups,
  ),
  R.propOr({}, propName),
)(dashboard);

const getDomainYAndTicksYByChartData = (data: Object) => {
  const max = R.compose(
    R.reduce(R.max, 0),
    R.map((item: Object) => R.reduce((acc: number, { value }: Object) => R.add(acc, value), 0, item.groups)),
    R.pathOr([], ['groups']),
  )(data);

  const magnitude = G.getOrderOfMagnitude(max);

  const nearest = 10 ** magnitude;

  const nearestMax = G.roundToNearest(max, nearest);

  const min = R.divide(nearestMax, 5);

  const ticksY = R.range(0, 5).map(R.multiply(min));

  return { ticksY: [...ticksY, Math.round(max)], domainY: [0, Math.round(max)] };
};

const getLabelFormatter = R.curry((labelFormatter: any, props: Object) => {
  const value = G.getValueFromObject(props);

  if (G.isFunction(labelFormatter)) return labelFormatter(value);

  return value;
});

const getChartNumberRangeFilter = (value: number) => {
  let from = 0;
  let to = 50000;

  if (R.equals('all', value)) return null;

  if (G.isZero(value)) {
    to = 200;
  } else if (R.equals(value, 200)) {
    from = 200;
    to = 500;
  } else if (R.equals(value, 500)) {
    from = 500;
  }

  return { to, from };
};

const getChartTitleWithCurrency = (title: string, currencySymbol: string) => {
  if (R.equals(currencySymbol, GC.DEFAULT_UI_CURRENCY_SYMBOL)) return title;

  return `${title} (${currencySymbol})`;
};

const getChartTickFormatterWithCurrency = R.curry((currencySymbol: string, tick: number) => {
  if (G.isZero(tick)) return '';

  if (R.equals(currencySymbol, GC.DEFAULT_UI_CURRENCY_SYMBOL)) return `${tick} ${currencySymbol}`;

  return tick;
});

const getSortedItems = (sortValues: Object, list: Array, columns: Array) => {
  const sortingList = R.sortBy(R.prop('sequence'), R.values(sortValues));

  const sortingCriteria = R.map((sortOption: Object) => {
    const { name, order } = sortOption;

    const filterParams = R.prop(name, columns);

    if (!filterParams) return () => {};

    const { path, filterType } = filterParams;
    const orderFn = G.ifElse(R.equals(order, 'DESC'), R.descend, R.ascend);

    return orderFn((arg: Object) => {
      const value = R.pathOr('', path, arg);

      switch (filterType) {
        case 'date':
          if (R.equals(name, GC.FIELD_LOAD_BOARD_LAST_MODIFIED_DATE)) {
            return Math.abs(R.subtract(new Date(), new Date(value)));
          }

          return new Date(value).getTime();
        case 'number':
          return +(value || 0);
        default:
          return value;
      }
    });
  }, sortingList);

  return R.sortWith(sortingCriteria, list);
};

const getFilteredItems = (filterValues: Object, list: Array, columns: Array) => {
  const filteringList = R.values(filterValues);

  let forFiltering = list;

  R.forEach((filterOption: Object) => {
    const {
      from,
      dataType,
      stringValue,
      numberValue,
      booleanValue,
      propertyName,
    } = filterOption;

    const filterParams = R.prop(propertyName, columns);

    if (!filterParams) return () => {};

    const { path } = filterParams;

    let toCompare;

    switch (dataType) {
      case 'string':
        toCompare = R.toLower(stringValue);
        break;
      case 'boolean':
        toCompare = booleanValue;
        break;
      case 'number':
        toCompare = +numberValue;
        break;
      case 'date':
        toCompare = format(new Date(from), 'MM/dd/yyyy');
        break;
      default:
        break;
    }

    forFiltering = R.filter((item: Object) => {
      const value = R.path(path, item);

      switch (dataType) {
        case 'string':
          return R.gt(R.toLower(R.or(value, '')).indexOf(toCompare), -1);
        case 'boolean':
          return R.equals(value, toCompare);
        case 'number':
          return R.equals(value, toCompare);
        case 'date':
          return R.equals(toCompare, value);
        default:
          return true;
      }
    }, forFiltering);
  }, filteringList);

  return forFiltering;
};

const filterEmptyParent = (groupByField: string, data: Array) => R.filter(R.compose(
  G.isNotNilAndNotEmpty,
  R.prop(groupByField),
), data);

const getFilteredAndSortedTableData = ({
  tableData,
  tableSettings,
  titleSortValues,
  tableTitleFilters,
}: Object) => {
  const {
    groupByField,
    parentFields,
    columnSettings,
  } = tableSettings;

  if (G.isNilOrEmpty(tableData)) return tableData;

  if (R.and(
    G.isNilOrEmpty(titleSortValues),
    G.isNilOrEmpty(tableTitleFilters),
  )) return filterEmptyParent(groupByField, tableData);

  const fields = R.keys(columnSettings);
  const parentColumnName = R.find((field: string) =>
    R.propEq(true, 'groupParent', R.prop(field, columnSettings)), fields);

  const parentFilter = R.prop(parentColumnName, tableTitleFilters);
  const parentSort = R.prop(parentColumnName, titleSortValues);

  const groupColumns = R.omit([parentColumnName], columnSettings);

  let filteredData = tableData;

  // Filtering
  if (G.isNotNilAndNotEmpty(tableTitleFilters)) {
    if (G.isNotNilAndNotEmpty(parentFilter)) {
      const { stringValue } = parentFilter;

      filteredData = R.filter((item: Object) =>
        R.any((field: string) => R.includes(stringValue, R.propOr('', field, item)), parentFields), tableData);
    }

    const innerFilters = R.omit([parentColumnName], tableTitleFilters);

    if (G.isNotNilAndNotEmpty(innerFilters)) {
      filteredData = R.map(
        (item: Object) => {
          const innerItems = R.propOr([], groupByField, item);

          const filteredItems = getFilteredItems(innerFilters, innerItems, groupColumns);

          return {
            ...item,
            [groupByField]: filteredItems,
          };
        },
        filteredData,
      );
    }
  }

  filteredData = filterEmptyParent(groupByField, filteredData);

  // Sorting
  if (G.isNotNilAndNotEmpty(titleSortValues)) {
    if (G.isNotNilAndNotEmpty(parentSort)) {
      const { order } = parentSort;

      const orderFn = G.ifElse(R.equals(order, 'DESC'), R.descend, R.ascend);

      filteredData = R.sortWith([orderFn(R.prop(GC.FIELD_NAME))], tableData);
    }

    const innerSorting = R.omit([parentColumnName], titleSortValues);

    if (G.isNotNilAndNotEmpty(innerSorting)) {
      filteredData = R.map(
        (item: Object) => {
          const innerItems = R.propOr([], groupByField, item);

          const filteredItems = getSortedItems(innerSorting, innerItems, groupColumns);

          return {
            ...item,
            [groupByField]: filteredItems,
          };
        },
        filteredData,
      );
    }
  }

  return filteredData;
};

const calculatePercentageChange = (newValue: number, oldValue: number) => {
  if (G.isAnyTrue(
    R.lt(oldValue, 0),
    R.lt(newValue, 0),
    G.isZero(oldValue),
  )) return 'N/A';

  const change = R.compose(
    R.multiply(100),
    R.divide(R.__, oldValue),
    R.subtract(newValue),
  )(oldValue);

  return parseFloat(change.toFixed(2));
};

export {
  formatDate,
  chartDateMapper,
  getTotalsForCard,
  getLabelFormatter,
  calculatePercentageChange,
  getChartNumberRangeFilter,
  getChartTitleWithCurrency,
  getFromLastDaysDescription,
  getFormattedDateRangeFilters,
  getFilteredAndSortedTableData,
  getDomainYAndTicksYByChartData,
  getChartTickFormatterWithCurrency,
  getDataForAreaAndLineChartFromDashboard,
};

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, lifecycle, withHandlers, renderNothing } from 'react-recompose';
// components
import { Table } from '../../components/table';
import { EmptyList } from '../../components/table/ui';
import { openModal, closeModal } from '../../components/modal/actions';
// features
import { AuthWrapper } from '../permission';
import PC from '../permission/role-permission';
import DriverProfileSimple from '../driver-profile-simple';
import { makeSelectCurrentUserName } from '../../features/auth/selectors';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import {
  openDriverProfile,
  setInitialState as setDriverProfileSimpleInitialState,
} from '../driver-profile-simple/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover, withSetExpandedContainerOptions } from '../../hocs';
// ui
import { Box, ListWrapper, ZOrderWrapper } from '../../ui';
// utilities
import endpointsMap from '../../utilities/endpoints';
// feature available-driver
import Header from './components/header';
import { Notes } from './components/notes';
import { NoteForm } from './components/note-form';
import { RowActions } from './components/row-actions';
import { getTableSettings } from './settings/table-settings';
import { ReservationForm } from './components/reservation-form';
import UnassignedLoadsList from './components/unassigned-loads-list';
import { getReport, getColumnSettings } from './settings/column-settings';
import {
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectActiveList,
  makeSelectListLoading,
  makeSelectAllDriversList,
  makeSelectUnassignedLoads,
  makeSelectHiddenReportFields,
  makeSelectShowUnassignedLoads,
  makeSelectAllDriversPagination,
} from './selectors';
import {
  getItemListRequest,
  toggleUnassignedLoads,
  deleteReservationRequest,
  getAllDriversListRequest,
  getUnassignedLoadsRequest,
  createOrUpdateDriverNoteRequest,
  createOrUpdateReservationRequest,
  getDriverUnavailablePeriodRequest,
  assignDriverOnAvailableDriverRequest,
} from './actions';
//////////////////////////////////////////////////

const enhance = compose(
  withFixedPopover,
  withSetExpandedContainerOptions,
  withHandlers({
    handleOpenDriverProfile: (props: Object) => (driverGuid: string) => {
      const { openDriverProfile, getDriverUnavailablePeriodRequest } = props;

      getDriverUnavailablePeriodRequest({
        driverGuid,
        callback: (unavailablePeriods: Array) => openDriverProfile({
          unavailablePeriods,
          driverProfileGuid: driverGuid,
        }),
      });
    },
    handleCreateOrUpdateReservation: (props: Object) => ({ guid, truck, reservationEndDate }: Object) => {
      const { openModal, createOrUpdateReservationRequest } = props;

      const reservationDate = G.ifElse(
        G.isNotNilAndNotEmpty(reservationEndDate),
        G.convertDateTimeToConfigTimeZone(reservationEndDate),
        null,
      );

      const component = (
        <ReservationForm
          driverGuid={guid}
          reservationEndDate={reservationDate}
          submitAction={createOrUpdateReservationRequest}
        />
      );

      const title = G.getWindowLocale(...G.ifElse(
        G.isNotNilAndNotEmpty(reservationEndDate),
        ['actions:edit-truck-reservation', 'Edit Truck Reservation'],
        ['titles:reserve-truck', 'Reserve Truck'],
      ));

      const modal = {
        p: 15,
        component,
        options: {
          width: 300,
          height: 'max-content',
          title: `${title} (${R.pathOr('', [GC.FIELD_FLEET_TRUCK_UNIT_ID], truck)})`,
        },
      };

      openModal(modal);
    },
    handleDeleteReservation: (props: Object) => ({ guid, truck }: Object) => {
      const { openModal, deleteReservationRequest } = props;

      const component = (
        <NoteForm
          isRequired={false}
          submitAction={({ text }: Object) => {
            const data = {
              note: text,
              driverGuid: guid,
            };

            deleteReservationRequest(data);
          }}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 'max-content',
          height: 'max-content',
          title: `${G.getWindowLocale('actions:delete-truck-reservation', 'Delete Truck Reservation')} (${
            R.pathOr('', [GC.FIELD_FLEET_TRUCK_UNIT_ID], truck)})`,
        },
      };

      openModal(modal);
    },
    handleOpenNotes: (props: Object) => ({ currentTarget }: Object, { guid }: Object) => {
      const {
        openModal,
        closeModal,
        openFixedPopup,
        closeFixedPopup,
        handleClickEditIcon,
      } = props;

      const contentProps = {
        openModal,
        closeModal,
        width: 350,
        height: 400,
        padding: '8px',
        driverGuid: guid,
        handleClickEditIcon,
        addPermissions: null,
        editPermissions: null,
        closeContent: closeFixedPopup,
        asyncOptions: { params: { driverGuid: guid }},
        asyncEndpoint: endpointsMap.availableDriverNoteList,
        addMessageText: G.getWindowLocale('actions:post', 'Post'),
      };

      const content = <Notes {...contentProps} />;

      openFixedPopup({
        content,
        version: 2,
        position: 'right',
        el: currentTarget,
        shouldNotCloseOnScroll: true,
      });
    },
    handlePatchUpdateNote: (props: Object) => (note: Object, { guid }: Object) => {
      const { createOrUpdateDriverNoteRequest } = props;

      const data = {
        ...note,
        driverGuid: guid,
        isCreate: G.isNilOrEmpty(G.getGuidFromObject(note)),
      };

      createOrUpdateDriverNoteRequest(data);
    },
    handleOpenExpandedContainer: (props: Object) => ({ visitPageGuid, componentType }: Object) => {
      const { closeFixedPopup, handleSetExpandedContainerOptions } = props;

      G.callFunction(closeFixedPopup);

      handleSetExpandedContainerOptions({
        visitPageGuid,
        componentType,
        openContainerAsNewWindow: true,
      });
    },
  }),
  branch(
    ({ initialDataLoaded }: Object) => R.not(initialDataLoaded),
    renderNothing,
  ),
  lifecycle({
    componentWillUnmount() {
      this.props.setDriverProfileSimpleInitialState();
    },
  }),
  pure,
);

const renderRowActions = ({ data, props }: Object) => (
  <AuthWrapper has={[PC.AVAILABLE_DRIVER_LIST_WRITE]}>
    <RowActions {...props} data={data} />
  </AuthWrapper>
);

const AvailableDriverList = (props: Object) => {
  const {
    loading,
    totalCount,
    activeList,
    allDriversList,
    hiddenReportFields,
    showUnassignedLoads,
    availableDriversList,
    allDriversPagination,
    getAllDriversListRequest,
  } = props;

  const isAllDriversList = R.equals(activeList, 'allDrivers');

  const itemList = G.ifElse(isAllDriversList, allDriversList, availableDriversList);

  const data = {
    loading,
    totalCount,
    itemList: R.values(itemList),
    columnSettings: getColumnSettings(props),
    tableSettings: getTableSettings(isAllDriversList),
    report: getReport(isAllDriversList, hiddenReportFields),
    pagination: G.ifElse(isAllDriversList, allDriversPagination, null),
    renderRightStickedComponent: (data: Object) => renderRowActions({ data, props }),
    handleLoadMoreEntities: G.ifElse(isAllDriversList, getAllDriversListRequest, null),
  };

  return (
    <ListWrapper p={15} zi='1'>
      <ZOrderWrapper zIndex='2'>
        <Header {...props} isAllDriversList={isAllDriversList} />
      </ZOrderWrapper>
      {
        G.isNotNilAndNotEmpty(itemList) &&
        <ZOrderWrapper zIndex='1'>
          <Table {...data} />
        </ZOrderWrapper>
      }
      {
        G.isNilOrEmpty(itemList) &&
        <EmptyList>
          {G.getWindowLocale('titles:no-drivers-available', 'No drivers available')}
        </EmptyList>
      }
      {
        G.isTrue(showUnassignedLoads) &&
        <Box
          top={0}
          right={0}
          height='100%'
          zIndex={1101}
          position='fixed'
          bg={G.getTheme('colors.white')}
          boxShadow='0 2px 40px 0 rgba(0, 0, 0, 0.2)'
        >
          <UnassignedLoadsList {...props} />
        </Box>
      }
      <DriverProfileSimple
        isLocationRequired={true}
        hidePendingActivities={true}
        showExpeditedDivision={true}
        showDriverAvailability={true}
        showTruckAdditionalEquipment={true}
      />
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  loading: makeSelectListLoading(state),
  activeList: makeSelectActiveList(state),
  totalCount: makeSelectTotalCount(state),
  userName: makeSelectCurrentUserName(state),
  availableDriversList: makeSelectItemList(state),
  allDriversList: makeSelectAllDriversList(state),
  unassignedLoads: makeSelectUnassignedLoads(state),
  hiddenReportFields: makeSelectHiddenReportFields(state),
  showUnassignedLoads: makeSelectShowUnassignedLoads(state),
  allDriversPagination: makeSelectAllDriversPagination(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  openDriverProfile,
  getItemListRequest,
  toggleUnassignedLoads,
  deleteReservationRequest,
  getAllDriversListRequest,
  getUnassignedLoadsRequest,
  createOrUpdateDriverNoteRequest,
  createOrUpdateReservationRequest,
  getDriverUnavailablePeriodRequest,
  setDriverProfileSimpleInitialState,
  assignDriverOnAvailableDriverRequest,
})(enhance(AvailableDriverList));

import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { PageActions } from '../../../components/page-actions';
import { ConfirmComponent } from '../../../components/confirm';
// forms
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
import { SelectTruckOrTrailerForm } from '../../../forms/forms/select-truck-or-trailer-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { accountingIntegrationOptions } from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
import ReferenceFormComponent from '../../reference/components/reference-form';
// feature fleet-list
import CreateFleet from './create-fleet';
import { withSyncItemsByVIN } from '../hocs';
import CreateFleetItemByReportType from './create-form';
import { getWritePermissionsByReportType, getRemovePermissionByReportType } from '../settings';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');

const itemNameTextMap = {
  [GC.FLEET_TRUCK_REPORT]: G.getWindowLocale('titles:truck', 'Truck'),
  [GC.FLEET_DRIVER_REPORT]: G.getWindowLocale('titles:driver', 'driver'),
  [GC.FLEET_VENDOR_REPORT]: G.getWindowLocale('titles:vendor', 'Vendor'),
  [GC.FLEET_TRAILER_REPORT]: G.getWindowLocale('titles:trailer', 'Trailer'),
  [GC.FLEET_SERVICE_ISSUE_REPORT]: G.getWindowLocale('titles:issue', 'Issue'),
  [GC.FLEET_SHARED_COMPONENT_REPORT]: G.getWindowLocale('titles:shared-component', 'Shared Component'),
  [GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT]: G.getWindowLocale('titles:payroll-accessorial', 'Payroll Accessorial'),
  [GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT]: G.getWindowLocale('titles:payroll-accessorial', 'Payroll Accessorial'),
};

const actionsToPickMap = {
  [GC.FLEET_SERVICE_ISSUE_REPORT]: [],
  [GC.FLEET_SHARED_COMPONENT_REPORT]: [],
  [GC.FLEET_DRIVER_ONBOARDING_REPORT]: [],
  [GC.FLEET_DRIVER_REPORT]: ['addReference', 'remove'],
  [GC.FLEET_VENDOR_REPORT]: ['addReference', 'remove', 'sendToIntegration'],
  [GC.FLEET_TRAILER_REPORT]: ['addReference', 'remove', 'sync', 'syncByReport'],
  [GC.FLEET_TRUCK_REPORT]: ['addReference', 'remove', 'ifta', 'sync', 'iftaByReport', 'syncByReport'],
};

const mainActionsToPickMap = {
  [GC.FLEET_DRIVER_REPORT]: ['createFleet', 'create'],
  [GC.FLEET_SERVICE_ISSUE_REPORT]: ['createTruckIssue', 'createTrailerIssue'],
  [GC.FLEET_EQUIPMENT_SERVICE_REPORT]: ['createTruckEquipmentService', 'createTrailerEquipmentService'],
};

const blueGreyBtnStyles = {
  textColor: G.getTheme('colors.light.darkBlue'),
  bgColor: G.getTheme('colors.light.lightBlueGrey'),
};

const actionsMap = ({
  reportType,
  selectedList,
  handleCreateFleet,
  handleRemoveItems,
  handleGenerateIfta,
  handleAddReference,
  handleSyncSelectedItems,
  handleSyncItemsByReport,
  handleSendToIntegration,
  handleCreateEquipmentService,
  handleCreateFleetServiceIssue,
  handleCreateFleetItemByReportType,
}: Object) => ({
  createFleet: {
    action: handleCreateFleet,
    styles: blueGreyBtnStyles,
    icon: I.plusRound(whiteColor, 25, 25),
    permissions: getWritePermissionsByReportType(reportType),
    text: G.getWindowLocale('actions:add-fleet', 'Add Fleet'),
  },
  addReference: {
    type: 'massAction',
    action: handleAddReference,
    icon: I.plusRound(whiteColor, 25, 25),
    permissions: getWritePermissionsByReportType(reportType),
    text: G.getWindowLocale('actions:add-reference', 'Add Reference'),
  },
  sendToIntegration: {
    type: 'massAction',
    action: handleSendToIntegration,
    icon: I.gear(whiteColor, 25, 25),
    permissions: getWritePermissionsByReportType(reportType),
    text: G.getWindowLocale('actions:send-to-integration', 'Send to Integration'),
  },
  remove: {
    type: 'massAction',
    icon: I.trash(whiteColor, 20, 20),
    action: () => handleRemoveItems(),
    color: G.getTheme('colors.light.mainRed'),
    text: G.getWindowLocale('actions:delete', 'Delete'),
    permissions: getRemovePermissionByReportType(reportType),
  },
  create: {
    hideIfBranchTypeCustomer: true,
    action: handleCreateFleetItemByReportType,
    permissions: getWritePermissionsByReportType(reportType),
    icon: I.createCloIcon(whiteColor, 40, 40, 'transparent'),
    text: `${G.getWindowLocale('actions:add', 'Add')} ${G.getPropFromObject(reportType, itemNameTextMap)}`,
  },
  ifta: {
    type: 'massAction',
    permissions: [PC.IFTA_EXECUTE],
    icon: I.gearSolid(whiteColor, 20, 20),
    action: () => handleGenerateIfta(false, selectedList),
    text: G.getWindowLocale('actions:generate-selected-ifta', 'Generate Selected IFTA'),
  },
  iftaByReport: {
    permissions: [PC.IFTA_EXECUTE],
    icon: I.gearSolid(whiteColor, 20, 20),
    action: () => handleGenerateIfta(true),
    text: G.getWindowLocale('actions:generate-all-ifta', 'Generate All IFTA'),
  },
  sync: {
    type: 'massAction',
    icon: I.vin(whiteColor, 20, 20),
    permissions: [PC.FLEET_TRUCK_WRITE],
    action: () => handleSyncSelectedItems(selectedList),
    text: G.getWindowLocale('actions:vin-lookup-selected', 'VIN Lookup Selected'),
  },
  syncByReport: {
    icon: I.vin(whiteColor, 20, 20),
    action: handleSyncItemsByReport,
    permissions: [PC.FLEET_TRUCK_WRITE],
    text: G.getWindowLocale('actions:vin-lookup-all', 'VIN Lookup All'),
  },
  createTrailerEquipmentService: {
    permissions: [PC.FLEET_TRAILER_WRITE],
    action: () => handleCreateEquipmentService(GC.FIELD_TRAILER, GC.FIELD_TRAILER_GUID),
    text: G.getWindowLocale('actions:add-trailer-equipment-service', 'Add Trailer Equipment Service'),
  },
  createTruckEquipmentService: {
    styles: blueGreyBtnStyles,
    permissions: [PC.FLEET_TRUCK_WRITE],
    action: () => handleCreateEquipmentService(GC.FIELD_TRUCK, GC.FIELD_TRUCK_GUID),
    text: G.getWindowLocale('actions:add-truck-equipment-service', 'Add Truck Equipment Service'),
  },
  createTrailerIssue: {
    permissions: [PC.FLEET_TRAILER_WRITE],
    action: () => handleCreateFleetServiceIssue(GC.FIELD_TRAILER),
    text: G.getAddTitle(['titles:add-trailer-issue', 'Trailer Issue']),
  },
  createTruckIssue: {
    styles: blueGreyBtnStyles,
    permissions: [PC.FLEET_TRUCK_WRITE],
    text: G.getAddTitle(['titles:truck-issue', 'Truck Issue']),
    action: () => handleCreateFleetServiceIssue(GC.FIELD_TRUCK),
  },
});

export const getMassRemoveListConfirmation = () => (
  <ConfirmComponent
    textLocale={G.getWindowLocale(
      'messages:confirm-delete-entities',
      'Are you sure you want to delete these entities?',
    )}
  />
);

const enhance = compose(
  withSyncItemsByVIN,
  withHandlers({
    handleCreateFleet: ({ openModal, closeModal }: Object) => () => {
      const component = <CreateFleet openModal={openModal} closeModal={closeModal} />;

      const modal = {
        p: '0px',
        component,
        options: {},
      };

      openModal(modal);
    },
    handleCreateFleetItemByReportType: (props: Object) => () => {
      const {
        openModal,
        reportType,
        closeModal,
        openLoader,
        closeLoader,
        accessorialConfigs,
      } = props;

      const component = (
        <CreateFleetItemByReportType
          reportType={reportType}
          closeModal={closeModal}
          openLoader={openLoader}
          closeLoader={closeLoader}
          accessorialConfigs={accessorialConfigs}
        />
      );
      const titlesMap = {
        [GC.FLEET_TRUCK_REPORT]: G.getWindowLocale('actions:create-truck', 'Create Truck'),
        [GC.FLEET_DRIVER_REPORT]: G.getWindowLocale('actions:create-driver', 'Create Driver'),
        [GC.FLEET_VENDOR_REPORT]: G.getWindowLocale('actions:create-vendor', 'Create Vendor'),
        [GC.FLEET_TRAILER_REPORT]: G.getWindowLocale('actions:create-trailer', 'Create Trailer'),
        [GC.FLEET_SHARED_COMPONENT_REPORT]: G.getWindowLocale(
          'actions:create-shared-component',
          'Create Shared Component',
        ),
      };
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          title: G.getPropFromObject(reportType, titlesMap),
        },
      };

      openModal(modal);
    },
    handleAddReference: (props: Object) => () => {
      const { openModal, reportType, selectedList, createReferenceRequest } = props;

      if (R.isEmpty(selectedList)) {
        const message = G.getWindowLocale('messages:items:select-item', 'Please, select an Item!');

        return G.showToastrMessageSimple('info', message);
      }

      const scopesMap = {
        [GC.FLEET_TRUCK_REPORT]: GC.REF_SCOPE_NAME_FLEET_TRUCK,
        [GC.FLEET_DRIVER_REPORT]: GC.REF_SCOPE_NAME_FLEET_DRIVER,
        [GC.FLEET_VENDOR_REPORT]: GC.REF_SCOPE_NAME_FLEET_VENDOR,
        [GC.FLEET_TRAILER_REPORT]: GC.REF_SCOPE_NAME_FLEET_TRAILER,
      };
      const component = (
        <ReferenceFormComponent
          scope={G.getPropFromObject(reportType, scopesMap)}
          submitAction={(values: Object) => createReferenceRequest({ values, selectedList })}
        />
      );
      const modal = G.createAddReferenceModalOptions(component);
      openModal(modal);
    },
    handleSendToIntegration: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        selectedList,
        sendListToIntegrationRequest,
      } = props;

      if (R.isEmpty(selectedList)) {
        const message = G.getWindowLocale('messages:items:select-item', 'Please, select an Item!');

        return G.showToastrMessageSimple('info', message);
      }

      const submitAction = (integrationType: string) =>
        sendListToIntegrationRequest({ selectedList, integrationType });

      const componentProps = {
        submitAction,
        fieldWidth: 300,
        optionRequired: true,
        cancelAction: closeModal,
        options: accountingIntegrationOptions(),
        fieldLabel: G.getWindowLocale('titles:integration-type', 'Integration Type'),
      };
      const modal = {
        p: 15,
        options: { width: 330 },
        component: <SelectDropdownForm {...componentProps} />,
      };

      openModal(modal);
    },
    handleCreateEquipmentService: (props: Object) => (entityType: string, entityGuidType: string) => {
      const { openModal, closeModal, openMaintenanceForm } = props;

      const submitAction = (entityGuid: string) => {
        closeModal();

        openMaintenanceForm({
          equipmentServiceEntityGuid: entityGuid,
          equipmentServiceEntityType: entityType,
          equipmentServiceEntityGuidType: entityGuidType,
        });
      };

      const component = (
        <SelectTruckOrTrailerForm submitAction={submitAction} isTruck={R.equals(entityType, GC.FIELD_TRUCK)} />
      );

      const modal = G.getDefaultModalOptions(
        component,
        G.getAddTitle(['titles:equipment-service', 'Equipment Service']),
      );

      openModal(modal);
    },
  }),
  pure,
);

const getMainPageAction = (props: Object) => {
  const { reportType } = props;

  const withoutMainAction = R.includes(
    reportType,
    [GC.FLEET_DRIVER_REPORT, GC.FLEET_SERVICE_ISSUE_REPORT, GC.FLEET_EQUIPMENT_SERVICE_REPORT],
  );

  if (withoutMainAction) return null;

  if (R.equals(reportType, GC.FLEET_DRIVER_ONBOARDING_REPORT)) {
    return R.prop('remove', actionsMap(props));
  }

  return R.prop('create', actionsMap(props));
};

const FleetPageActions = (props: Object) => {
  const { reportType, selectedList } = props;

  const pageActionsPermissions = G.ifElse(
    R.equals(reportType, GC.FLEET_TRUCK_REPORT),
    R.append(PC.IFTA_EXECUTE, getWritePermissionsByReportType(reportType)),
    getWritePermissionsByReportType(reportType),
  );

  return (
    <AuthWrapper has={pageActionsPermissions}>
      <PageActions
        count={R.length(selectedList)}
        mainAction={getMainPageAction(props)}
        shadowColor={G.getTheme('colors.light.grey')}
        listActions={R.values(R.pick(R.pathOr([], [reportType], actionsToPickMap), actionsMap(props)))}
        mainActions={R.values(R.pick(R.pathOr([], [reportType], mainActionsToPickMap), actionsMap(props)))}
      />
    </AuthWrapper>
  );
};

export default enhance(FleetPageActions);

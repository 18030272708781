import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { closeLoader, openLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// utilities
import { sendRequest } from '../../utilities/http';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportSagas } from '../../report-common';
// sagas
import { crudSaga, visitPageSaga } from '../../sagas';
// utilities
import endpointsMap from '../../utilities/endpoints';
// feature inspections
import * as A from './actions';
import {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectTableTitleFilters,
  makeSelectFleetProfileSettings,
} from './selectors';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
//////////////////////////////////////////////////

const requiredFields = [
  GC.FIELD_TRUCK_GUID,
  GC.FIELD_TRAILER_GUID,
];

function* handleGetItemListSaga({ payload }: Object) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const reportParams = yield select(makeSelectUsedReport());

    const {
      fleetProfileType,
      fleetProfileGuid,
      fleetProfileGuidType,
    } = yield select(makeSelectFleetProfileSettings());

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const guids = R.pathOr(null, ['guids'], payload);

    const { limit, offset } = pagination;

    const systemFields = [
      { name: GC.FIELD_BRANCH_GUID, freezed: false, sequence: 100, reference: false },
    ];

    const reqBody = {
      guids,
      limit,
      offset,
      orderFields,
      systemFields,
      currentEnterprise,
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
      fields: G.addRequiredFields(G.getOrElse(reportParams, 'fields', []), requiredFields),
    };

    const options = {
      data: G.setSearchCriteria({ reqBody, filterParams: newFilterParams }),
      params: G.ifElse(
        G.isNilOrEmpty(fleetProfileType),
        null,
        { [fleetProfileGuidType]: fleetProfileGuid },
      ),
    };

    const endpoints = {
      [GC.FIELD_TRUCK]: endpointsMap.truckInspections,
      [GC.FIELD_TRAILER]: endpointsMap.trailerInspections,
    };

    const endpoint = R.pathOr(endpointsMap.fleetInspections, [fleetProfileType], endpoints);

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, guids }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (err) {
    yield put(A.setListLoading(false));
    yield put(closeLoader());

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

function* handleExportReportDataSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const filterParams = yield select(makeSelectFilterParams());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const { name, fields, fileType, driverGuid, orderFields, searchCriteria } = payload;

    const reqBody = {
      fields,
      orderFields,
      currentEnterprise,
      [GC.FIELD_REPORT_NAME]: name,
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const data = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const params = { format: fileType, ...G.isNotNilAndNotEmpty(driverGuid) ? { driverGuid } : {} };

    const options = {
      data,
      params,
      resType: 'arraybuffer',
    };

    const res = yield call(sendRequest, 'post', endpointsMap.fleetInspectionsListExport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handleExportReportDataSaga');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleExportReportDataSaga exception');
  }
}

function* handleDeleteItemsRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = { data: payload };

    yield call(crudSaga, {
      options,
      payload,
      method: 'delete',
      successAction: A.deleteItemsSuccess,
      parentSagaName: 'deleteItemsRequest',
      endpoint: endpointsMap.fleetInspectionsDelete,
    });

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteItemsRequest exception');
  }
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(GC.INSPECTION_REPORT, A, handleGetItemListSaga, { createUpdateReportSuccessCallback });


function* visitInspectionsOnFleetPage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader());
    yield put(A.setInitialState());
    yield put(A.setReportPending());
    yield put(A.setFleetProfileSettings(payload));

    yield call(handleAvailableReportsRequest, { payload: { notSetUsedReport: false } });
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });

    yield put(closeLoader());

    break;
  }
}

function* handleVisitPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader({ showDimmer: true }));

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_INSPECTION_REPORT_PAGE);

    const pageVisited = yield select(makeSelectPageVisited());

    if (G.isTrue(pageVisited)) {
      yield put(A.setInitialStateOmitReport());
    } else {
      yield put(A.setInitialState());
    }

    yield call(handleAvailableReportsRequest, { payload, notSetUsedReport: pageVisited });
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });

    yield put(closeLoader());

    break;
  }
}

function* inspectionsWatcherSaga() {
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.deleteItemsRequest, handleDeleteItemsRequest);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(GC.VISIT_INSPECTION_REPORT_PAGE, handleVisitPageSaga);
  yield takeLatest(A.exportReportDataRequest, handleExportReportDataSaga);
  yield takeLatest(A.visitInspectionsOnFleetPage, visitInspectionsOnFleetPage);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
}

export default inspectionsWatcherSaga;

import React, { memo, Fragment, useState, useCallback } from 'react';
// components
import { TextComponent } from '../../../../components';
import {
  AddDocument2,
  renderConfirmationModal,
} from '../../../../components/activities';
// helpers/constants
import * as G from '../../../../helpers';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, Iframe, scrollableContainerCss4px } from '../../../../ui';
// feature fleet
import GoToConfiguration from './go-to-configuration';
import { prepareAndSetMaintenanceDocs } from './ai-upload';
//////////////////////////////////////////////////

const darkGreyColor = G.getTheme('colors.#7D828C');
const lightGreyColor = G.getTheme('colors.lightGrey');
const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const wrapperStyles = {
  mb: 10,
  p: '7px',
  borderRadius: '4px',
  border: '1px solid',
  color: greyMatterhornColor,
  borderColor: lightGreyColor,
};

const Document = memo((props: Object) => {
  const {
    index,
    document,
    openModal,
    closeModal,
    handleRemoveDocument,
    setMaintenanceDocumentURL,
  } = props;

  const { file, preview } = document;

  const { name, lastModified } = file;

  const formattedCreatedDate = G.convertDateTimeToConfigFormat(G.makeMomentInstance(new Date(lastModified)));

  return (
    <Flex {...wrapperStyles} fontSize={12}>
      <Box width='100%'>
        <Flex justifyContent='space-between'>
          <TextComponent withEllipsis={true} title={name} maxWidth='calc(100% - 75px)'>
            {name}
          </TextComponent>
          <Flex>
            <Box mr={10} cursor='pointer' onClick={() => setMaintenanceDocumentURL(preview)}>
              {I.eye(darkGreyColor)}
            </Box>
            <Box
              cursor='pointer'
              onClick={() =>
                renderConfirmationModal({ openModal, closeModal, action: () => handleRemoveDocument(index) })
              }
            >
              {I.trash(darkGreyColor)}
            </Box>
          </Flex>
        </Flex>
        <Flex mt='5px'>
          <TextComponent
            withEllipsis={true}
            color={darkGreyColor}
            maxWidth='calc(40% - 45px)'
            title={formattedCreatedDate}
          >
            {formattedCreatedDate}
          </TextComponent>
        </Flex>
      </Box>
    </Flex>
  );
});

const DocumentPreview = (props: Object) => {
  const {
    maintenanceDocumentURL,
    setMaintenanceDocumentURL,
  } = props;

  return (
    <Box
      mr={10}
      overflowY='auto'
      p='15px 5px 0 15px'
      height='calc(100% - 110px)'
      css={scrollableContainerCss4px}
    >
      <Box
        mr={10}
        mb={10}
        cursor='pointer'
        textAlign='right'
        onClick={() => setMaintenanceDocumentURL(null)}
        title={G.getWindowLocale('titles:close-document', 'Close Document')}
      >
        {I.closeIcon(darkGreyColor, 15, 15)}
      </Box>
      <Box height='calc(100% - 50px)'>
        <Iframe src={maintenanceDocumentURL} />
      </Box>
    </Box>
  );
};

const Documents = (props: Object) => {
  const {
    openModal,
    closeModal,
    entityType,
    maintenanceDocs,
    setMaintenanceDocs,
    documentsDefaultDocumentType,
  } = props;

  const [maintenanceDocumentURL, setMaintenanceDocumentURL] = useState();

  const handleRemoveDocument = useCallback((index: number) => {
    const newFiles = maintenanceDocs.filter((_: any, i: number) => i !== index);

    setMaintenanceDocs(newFiles);
  }, [maintenanceDocs, setMaintenanceDocs]);

  if (G.isNotNilAndNotEmpty(maintenanceDocumentURL)) {
    return (
      <DocumentPreview
        maintenanceDocumentURL={maintenanceDocumentURL}
        setMaintenanceDocumentURL={setMaintenanceDocumentURL}
      />
    );
  }

  return (
    <Fragment>
      {
        G.isNilOrEmpty(documentsDefaultDocumentType) && <GoToConfiguration entityType={entityType} />
      }
      {
        G.isNotNilAndNotEmpty(maintenanceDocs) &&
        <Box
          mr={10}
          overflowY='auto'
          p='15px 5px 0 15px'
          height='calc(100% - 200px)'
          css={scrollableContainerCss4px}
        >
          {
            maintenanceDocs.map((document: Object, index: number) => (
              <Document
                key={index}
                index={index}
                document={document}
                openModal={openModal}
                closeModal={closeModal}
                handleRemoveDocument={handleRemoveDocument}
                setMaintenanceDocumentURL={setMaintenanceDocumentURL}
              />
            ))
          }
        </Box>
      }
      {
        G.isNotNilAndNotEmpty(documentsDefaultDocumentType) &&
        <AddDocument2
          onDropHandler={(files: Array) => {
            prepareAndSetMaintenanceDocs({
              files,
              maintenanceDocs,
              setMaintenanceDocs,
              defaultDocumentType: documentsDefaultDocumentType,
            });
          }}
        />
      }
    </Fragment>
  );
};

export default Documents;

export { DocumentPreview };

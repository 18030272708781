import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose, withState, withPropsOnChange } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature configs
import { equipmentOptionsMap } from '../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 270,
};

const fieldSettings = (integrationType: string) => [
  {
    type: 'select',
    inputWrapperStyles,
    label: ['titles:integration-type'],
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    customDisabledFunction: 'handleDisableIntegrationType',
    options: [
      {
        label: GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS,
        value: GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS,
      },
      {
        label: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI,
        value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI,
      },
    ],
  },
  {
    inputWrapperStyles,
    type: 'reactSelect',
    label: ['titles:equipment'],
    options: 'equipmentOptions',
    fieldName: GC.FIELD_EQUIPMENT_GUID,
  },
  {
    inputWrapperStyles,
    options: 'externalEquipmentOptions',
    label: ['titles:external-equipment'],
    fieldName: GC.FIELD_EXTERNAL_EQUIPMENT,
    type: G.ifElse(R.equals(integrationType, GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI), 'text', 'reactSelect'),
  },
];

const defaultFields = {
  [GC.FIELD_EQUIPMENT_GUID]: null,
  [GC.FIELD_EXTERNAL_EQUIPMENT]: null,
  [GC.FIELD_INTEGRATION_TYPE]: GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS,
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_EQUIPMENT_GUID]: G.yupStringRequired,
  [GC.FIELD_EXTERNAL_EQUIPMENT]: G.yupStringRequired,
});

const enhance = compose(
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
  }),
  withState('prevProps', 'setPrevProps', {}),
  withPropsOnChange(
    (props: Object, nextProps: Object) => {
      const currentIntegrationType = R.path(['values', GC.FIELD_INTEGRATION_TYPE], props);
      const nextIntegrationType = R.path(['values', GC.FIELD_INTEGRATION_TYPE], nextProps);

      return G.notEquals(currentIntegrationType, nextIntegrationType);
    },
    ({ values, equipmentOptions }: Object) => {
      const integrationType = R.pathOr(GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS, [GC.FIELD_INTEGRATION_TYPE], values);
      const externalEquipmentOptions = R.compose(
        R.values,
        R.mapObjIndexed((label: string, value: string) => ({ label, value })),
        R.pathOr([], [integrationType]),
      )(equipmentOptionsMap);

      return {
        optionsForSelect: { externalEquipmentOptions, equipmentOptions: R.drop(1, equipmentOptions) },
      };
    },
  ),
  pure,
);

const EquipmentMappingConfigForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...props.optionsForSelect}
      {...G.getFormikProps(props)}
      fieldsWrapperStyles={{ pt: 11, flexDirection: 'column' }}
      fields={fieldSettings(R.path(['values', GC.FIELD_INTEGRATION_TYPE], props))}
      handlers={{
        handleDisableIntegrationType: () => props.editMode,
      }}
    />
    <FormFooter />
  </form>
);

export default enhance(EquipmentMappingConfigForm);

import * as R from 'ramda';
import React, { useState, useEffect, useCallback } from 'react';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { ReactSelect } from '../../../../ui';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
import { sendRequest } from '../../../../utilities/http';
//////////////////////////////////////////////////

const useGetSelectProps = ({ branchGuid, homeBaseGuid }: Object) => {
  const [itemList, setItemList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState(null);

  const handleGetItemList = useCallback(async ({ offset = 0, initialGuid }: Object = {}) => {
    setIsLoading(true);

    const searchCriteria = G.ifElse(
      R.isNil(initialGuid),
      [],
      [
        {
          dataType: 'string',
          operation: 'equal',
          stringValue: initialGuid,
          propertyName: GC.FIELD_GUID,
        },
      ],
    );

    const options = {
      data: {
        offset,
        limit: 10,
        searchCriteria,
        [GC.CURRENT_BRANCH]: R.or(branchGuid, G.getAmousCurrentBranchGuidFromWindow()),
        fields: [
          {
            sequence: 0,
            name: GC.FIELD_NAME,
          },
        ],
      },
    };

    const res = await sendRequest('post', endpointsMap.geoFencingLocationList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const mapped = R.map(({ guid, name }: Object = {}) => ({
        [GC.FIELD_VALUE]: guid,
        [GC.FIELD_LABEL]: name,
      }), R.propOr([], 'results', data));

      setTotalCount(data.totalCount);

      setItemList((prev: Array) => (
        G.isZero(offset) ? mapped : R.uniqBy(R.prop(GC.FIELD_VALUE), R.concat(prev, mapped))
      ));
    }

    setIsLoading(false);
  }, []);

  const handleScroll = useCallback(() => {
    if (R.gt(totalCount, R.length(itemList))) {
      handleGetItemList({ offset: R.length(itemList), primaryRefValue: inputValue });
    }
  }, [totalCount, R.length(itemList), inputValue]);

  const handleSearch = useCallback((inputValue: string, { action, prevInputValue }: Object) => {
    if (R.and(R.isEmpty(prevInputValue), R.isEmpty(inputValue))) return;

    setInputValue(inputValue);
    handleGetItemList({ primaryRefValue: inputValue });
  }, []);

  const handleOpenSelectMenu = useCallback(() => {
    if (R.lte(totalCount, 1)) handleGetItemList({ offset: totalCount });
  }, [totalCount]);

  useEffect(() => {
    if (R.isNotNil(homeBaseGuid)) handleGetItemList({ initialGuid: homeBaseGuid });
  }, [homeBaseGuid]);

  return {
    isLoading,
    handleScroll,
    options: itemList,
    onMenuOpen: handleOpenSelectMenu,
    onMenuScrollToBottom: handleScroll,
    onInputChange: G.setDebounce(handleSearch, 500),
  };
};

const useGetAvailableGeofencingLocationNameList = (branchGuid: string) => {
  const [availableList, setAvailableList] = useState(null);

  useEffect(() => {
    const handleGetAvailableList = async () => {
      const options = {
        params: {
          [GC.BRANCH_GUID]: R.or(branchGuid, G.getAmousCurrentBranchGuidFromWindow()),
        },
      };

      const res = await sendRequest('get', endpointsMap.geoFencingLocationAvailableList, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setAvailableList(G.mapNameGuidToLabelValue(data));
      } else {
        setAvailableList([]);
        G.handleFailResponseSimple(res, true, 'useGetAvailableGeofencingLocationNameList');
      }
    };

    handleGetAvailableList();
  }, []);

  return availableList;
};

export const useGetGeofencingLocationNameByGuid = (guid: string) => {
  const [geofencingLocationName, setGeofencingLocationName] = useState(null);

  useEffect(() => {
    if (R.isNil(guid)) return;

    const handleGetGeofencingLocation = async () => {
      const endpoint = endpointsMap.geoFencingLocationByGuid(guid);

      const res = await sendRequest('get', endpoint);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setGeofencingLocationName(R.prop(GC.FIELD_NAME, data));
      } else {
        G.handleFailResponseSimple(res, true, 'handleGetClaimByClaimGuid fail');
      }
    };

    handleGetGeofencingLocation();
  }, [guid]);

  return geofencingLocationName;
};

const HomeBase = ({ values, setFieldValue }: Object) => {
  const { [GC.BRANCH_GUID]: branchGuid, homeBaseGeofencingLocationGuid } = values;

  // const selectProps = useGetSelectProps({ branchGuid, setFieldValue, homeBaseGuid: homeBaseGeofencingLocationGuid });

  const availableList = useGetAvailableGeofencingLocationNameList(branchGuid);

  return (
    <ReactSelect
      // {...selectProps}
      options={R.or(availableList, [])}
      isLoading={R.isNil(availableList)}
      value={homeBaseGeofencingLocationGuid}
      noOptionsMessage={() => G.getWindowLocale('titles:no-options', 'No Options')}
      onChange={(option: Object) => setFieldValue(
        'homeBaseGeofencingLocationGuid',
        R.propOr(null, GC.FIELD_VALUE, option),
      )}
    />
  );
};

export default HomeBase;

import React from 'react';
import * as R from 'ramda';
// components
import { ActionBox } from '../../../components/action-box';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Flex } from '../../../ui';
//////////////////////////////////////////////////

const CommonTrailersFields = [
  { name: GC.FIELD_UNIT_ID, sequence: 1 },
  { name: GC.FIELD_MANUFACTURER, sequence: 2 },
  { name: GC.FIELD_OUTPUT_YEAR, sequence: 3 },
  { name: GC.FIELD_LICENSE_PLATE, sequence: 4 },
  { name: GC.FIELD_AVAILABLE, sequence: 5 },
  { name: GC.FIELD_FLEET_IN_SERVICE, sequence: 6 },
  { name: GC.FIELD_FLEET_IN_SERVICE_DATE, sequence: 7 },
  { name: GC.FIELD_FLEET_OUT_SERVICE_DATE, sequence: 8 },
  { name: 'outOfServiceReasonDisplayedValue', sequence: 9 },
];

const noLocation = G.getWindowLocale('titles:no-location', 'No Location');

//Trailers common
const CommonTrailersTable = {
  titleRowHeight: 50,
  tableRowHeight: 48,
  withResizableColumns: true,
  groupByField: GC.FIELD_TRAILERS,
  columnSettings: {
    [GC.FIELD_UNIT_ID]: {
      width: 200,
      inGroup: true,
      resizable: true,
      fieldType: 'text',
      filterType: 'string',
      name: 'titles:unit-id',
      value: GC.FIELD_UNIT_ID,
      path: [GC.FIELD_UNIT_ID],
      customComponent: ({ data, callbackData }: Object) => {
        const { setExpandedContainerOptions } = callbackData;

        const unitId = R.prop(GC.FIELD_UNIT_ID, data);

        if (G.isNilOrEmpty(unitId)) return null;

        const guid = G.getGuidFromObject(data);

        return (
          <ActionBox
            text={unitId}
            action={() => setExpandedContainerOptions({
              opened: true,
              visitPageGuid: guid,
              componentType: GC.PAGE_FLEET_TRAILER_PROFILE_V2,
            })}
          />
        );
      },
    },
    [GC.FIELD_MANUFACTURER]: {
      width: 250,
      inGroup: true,
      resizable: true,
      fieldType: 'text',
      filterType: 'string',
      name: 'titles:manufacturer',
      value: GC.FIELD_MANUFACTURER,
      path: [GC.FIELD_MANUFACTURER],
    },
    [GC.FIELD_OUTPUT_YEAR]: {
      width: 160,
      inGroup: true,
      resizable: true,
      fieldType: 'text',
      filterType: 'number',
      name: 'titles:outputYear',
      value: GC.FIELD_OUTPUT_YEAR,
      path: [GC.FIELD_OUTPUT_YEAR],
    },
    [GC.FIELD_LICENSE_PLATE]: {
      width: 160,
      inGroup: true,
      resizable: true,
      fieldType: 'text',
      filterType: 'string',
      name: 'titles:licensePlate',
      value: GC.FIELD_LICENSE_PLATE,
      path: [GC.FIELD_LICENSE_PLATE],
    },
    [GC.FIELD_AVAILABLE]: {
      width: 160,
      inGroup: true,
      type: 'boolean',
      resizable: false,
      fieldType: 'boolean',
      filterType: 'boolean',
      name: 'titles:available',
      value: GC.FIELD_AVAILABLE,
      path: [GC.FIELD_AVAILABLE],
    },
    [GC.FIELD_FLEET_IN_SERVICE]: {
      width: 160,
      inGroup: true,
      type: 'boolean',
      resizable: false,
      fieldType: 'boolean',
      filterType: 'boolean',
      name: 'titles:in-service',
      value: GC.FIELD_FLEET_IN_SERVICE,
      path: [GC.FIELD_FLEET_IN_SERVICE],
    },
    [GC.FIELD_FLEET_IN_SERVICE_DATE]: {
      width: 210,
      inGroup: true,
      resizable: false,
      fieldType: 'date',
      filterType: 'date',
      name: 'titles:in-service-date',
      value: GC.FIELD_FLEET_IN_SERVICE_DATE,
      path: [GC.FIELD_FLEET_IN_SERVICE_DATE],
    },
    [GC.FIELD_FLEET_OUT_SERVICE_DATE]: {
      width: 210,
      inGroup: true,
      resizable: false,
      fieldType: 'date',
      filterType: 'date',
      name: 'titles:out-service-date',
      value: GC.FIELD_FLEET_OUT_SERVICE_DATE,
      path: [GC.FIELD_FLEET_OUT_SERVICE_DATE],
    },
    outOfServiceReasonDisplayedValue: {
      width: 200,
      inGroup: true,
      resizable: true,
      fieldType: 'text',
      filterType: 'string',
      name: 'titles:out-service-reason',
      value: 'outOfServiceReasonDisplayedValue',
      path: ['outOfServiceReasonDisplayedValue'],
    },
  },
};

//Trailers By Location
const TrailersByLocationTable = {
  ...CommonTrailersTable,
  report: {
    type: 'DashboardTable',
    guid: 'TrailerByLocation',
    fields: CommonTrailersFields,
  },
  parentFields: [
    GC.FIELD_NAME,
    GC.FIELD_CITY,
    GC.FIELD_STATE,
    GC.FIELD_ADDRESS,
    GC.FIELD_COUNTRY,
  ],
  columnSettings: {
    [GC.FIELD_LOCATION_NAME]: {
      width: 350,
      resizable: true,
      fieldType: 'text',
      groupParent: true,
      filterType: 'string',
      name: 'titles:locationName',
      customComponent: ({ data }: Object) => (
        <Flex>
          {G.isNotNilAndNotEmpty(data.name) && `${data.name} (${data.address}, ${G.renderSmallAddressString(data)})`}
          {G.isNilOrEmpty(data.name) && noLocation}
          {` - ${data.count}`}
        </Flex>
      ),
    },
    ...CommonTrailersTable.columnSettings,
  },
};

//Trailers By Idle Time
const TrailersByIdleTimeTable = {
  ...CommonTrailersTable,
  report: {
    type: 'DashboardTable',
    guid: 'TrailerByIdleTime',
    fields: CommonTrailersFields,
  },
  parentFields: [
    GC.FIELD_NAME,
  ],
  columnSettings: {
    [GC.FIELD_NAME]: {
      width: 250,
      resizable: true,
      fieldType: 'text',
      groupParent: true,
      filterType: 'string',
      name: 'titles:range',
      customComponent: ({ data }: Object) => (
        <Flex>
          {data.name}
          {` - ${data.count}`}
        </Flex>
      ),
    },
    ...CommonTrailersTable.columnSettings,
  },
};

const placeholder = { text: 'Choose', key: 'titles:choose' };

const getFilterProps = (fields: Object) => R.mapObjIndexed((
  { name, options, collection, filterType, type = 'string' }: Object,
  value: string,
) => ({
  value,
  options,
  collection,
  placeholder,
  type: R.or(filterType, type),
  [GC.FIELD_NAME]: G.ifElse(
    G.isArray(name),
    `${G.getWindowLocale(name[0])}: ${G.getWindowLocale(name[1])}`,
    G.getWindowLocale(name),
  ),
}), fields);

export {
  getFilterProps,
  TrailersByLocationTable,
  TrailersByIdleTimeTable,
};

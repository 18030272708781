import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// features
import { socketTelWarningReceived, socketDriverCardsTelReceived } from '../sockets-v2/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature drivers-card
import * as A from './actions';
import { mapDriverLoadsToEmptyPeriods, filterDriverLoadsByLocationAndDate } from './helpers';
import {
  ZOOM_TYPE_1X,
  CARD_HEIGHT_170,
  GROUP_TYPE_NONE,
  FILTER_TYPE_NONE,
  GROUP_TYPE_DISPATCHER,
} from './constants';
//////////////////////////////////////////////////

const initialState = {
  searchDate: null,
  zoom: ZOOM_TYPE_1X,
  unassignedLoads: [],
  telNotifications: {},
  driverCardsInfo: null,
  dispatchByTruck: false,
  globalFilterValue: null,
  searchRadiusValue: null,
  minEmptyHoursValue: null,
  groupBy: GROUP_TYPE_NONE,
  loadsByDispatchGroup: {},
  loadRefFilterValue: null,
  warningPeriodConfig: null,
  showUnassignedLoads: false,
  cardHeight: CARD_HEIGHT_170,
  notGroupedAssignedLoads: [],
  driverLoadsByDispatcher: {},
  searchPlaceAutocompleteValue: null,
  searchPlaceAutocompleteResult: null,
  filteredByPlaceAutocompleteLoads: null,
  notGroupedAssignedLoadsPagination: {
    items: [],
    limit: 50,
    offset: 0,
    totalCount: 0,
  },
  filters: {
    dateTo: null,
    dateFrom: null,
    initialFilterDays: null,
    filter: FILTER_TYPE_NONE,
  },
};

const setInitialState = () => initialState;

const setValueToStore = (state: Object, { path, value }: Object) => P.$set(path, value, state);

const toggleUnassignedLoads = (state: Object) => P.$toggle('showUnassignedLoads', state);

const toggleDispatchByTruck = (state: Object) => P.$toggle('dispatchByTruck', state);

const setGroupByToStore = (state: Object, data: string) => P.$set('groupBy', data, state);

const setZoomToStore = (state: Object, data: string) => P.$set('zoom', data, state);

const setWarningPeriodConfig = (state: Object, data: string) => P.$set('warningPeriodConfig', data, state);

const setFiltersToStore = (state: Object, data: string) => P.$set('filters', data, state);

const setGlobalFilterValue = (state: Object, data: string) => P.$set('globalFilterValue', data, state);

const setLoadRefFilterValue = (state: Object, data: string) => P.$set('loadRefFilterValue', data, state);

const setFilterToStore = (state: Object, data: string) => P.$set('filters.filter', data, state);

const setDayFilterToStore = (state: Object, data: string) => P.$set('filters.initialFilterDays', data, state);

const getUnassignedLoadsSuccess = (state: Object, data: Object) => P.$set('unassignedLoads', data, state);

const getDriverCardsInfoSuccess = (state: Object, data: Object) => P.$set('driverCardsInfo', data, state);

const getDriverCardsByTruckInfoSuccess = (state: Object, data: Object) => P.$set('driverCardsByTruckInfo', data, state);

const createDriverOnDriversCardSuccess = (state: Object, data: Object) => {
  const { telGuid } = data;

  const { unassignedLoads } = state;

  const newUnassignedLoads = R.reject(R.propEq(telGuid, GC.FIELD_GUID), unassignedLoads);

  return P.$set('unassignedLoads', newUnassignedLoads, state);
};

const getDriverLoadsByDispatcherSuccess = (state: Object, { data, dispatcherGuid }: Object) => (
  P.$all(
    P.$set('searchDate', null),
    P.$set('searchPlaceAutocompleteValue', null),
    P.$set('searchPlaceAutocompleteResult', null),
    P.$set(`driverLoadsByDispatcher.${dispatcherGuid}`, data),
    state,
  )
);

const getLoadsByDispatchGroupSuccess = (state: Object, { data, dispatchingGroupGuid }: Object) => (
  P.$all(
    P.$set('searchDate', null),
    P.$set('searchPlaceAutocompleteValue', null),
    P.$set('searchPlaceAutocompleteResult', null),
    P.$set(`loadsByDispatchGroup.${dispatchingGroupGuid}`, data),
    state,
  )
);

const getNotGroupedAssignedLoadsSuccess = (state: Object, data: Object) => {
  const { notGroupedAssignedLoadsPagination } = state;
  const { limit } = notGroupedAssignedLoadsPagination;

  const driverLoads = data;

  const taken = R.take(limit, driverLoads);

  return P.$all(
    P.$set('searchDate', null),
    P.$set('notGroupedAssignedLoads', taken),
    P.$set('searchPlaceAutocompleteValue', null),
    P.$set('searchPlaceAutocompleteResult', null),
    P.$set('filteredByPlaceAutocompleteLoads', null),
    P.$set('notGroupedAssignedLoadsPagination.offset', limit),
    P.$set('notGroupedAssignedLoadsPagination.items', driverLoads),
    P.$set('notGroupedAssignedLoadsPagination.totalCount', R.length(driverLoads)),
    state,
  );
};

// TODO: check real pagination with API
const getMoreNotGroupedAssignedLoads = (state: Object) => {
  const { notGroupedAssignedLoadsPagination } = state;
  const { items, limit, offset, totalCount } = notGroupedAssignedLoadsPagination;

  let taken = R.take(offset, items);
  const newOffset = R.add(offset, limit);

  if (R.lt(R.subtract(totalCount, newOffset), limit)) {
    const take = R.add(newOffset, R.subtract(totalCount, newOffset));
    taken = R.take(take, items);
  }

  return P.$all(
    P.$set('notGroupedAssignedLoads', taken),
    P.$set(
      'notGroupedAssignedLoadsPagination.offset',
      G.ifElse(
        R.gt(totalCount, newOffset),
        newOffset,
        totalCount,
      ),
    ),
    state,
  );
};

const setDateRangeFilters = (state: Object, { dateTo, dateFrom }: Object) => (
  P.$all(
    P.$set('filters.dateTo', dateTo),
    P.$set('filters.dateFrom', dateFrom),
    state,
  )
);

const filterByPlaceAutocomplete = (state: Object, result: Object) => {
  const {
    filters,
    searchDate,
    searchRadiusValue,
    minEmptyHoursValue,
    searchPlaceAutocompleteResult,
    notGroupedAssignedLoadsPagination,
  } = state;

  const { items } = notGroupedAssignedLoadsPagination;

  const resultToUse = R.or(result, searchPlaceAutocompleteResult);
  const address = G.getPropFromObject('formattedAddress', resultToUse);

  const mapped = mapDriverLoadsToEmptyPeriods(items, minEmptyHoursValue, filters);

  const filtered = filterDriverLoadsByLocationAndDate(mapped, resultToUse, searchRadiusValue, searchDate);

  return P.$all(
    P.$set('searchPlaceAutocompleteValue', address),
    P.$set('searchPlaceAutocompleteResult', resultToUse),
    P.$set('filteredByPlaceAutocompleteLoads', filtered),
    state,
  );
};

const setSearchPlaceAutocompleteValue = (state: Object, value: Object) => {
  if (G.isNilOrEmpty(value)) {
    return P.$all(
      P.$set('searchPlaceAutocompleteValue', null),
      P.$set('searchPlaceAutocompleteResult', null),
      P.$set('filteredByPlaceAutocompleteLoads', null),
      state,
    );
  }

  return P.$set('searchPlaceAutocompleteValue', value, state);
};

const setSearchRadiusValue = (state: Object, value: Object) => P.$set('searchRadiusValue', value, state);

const setMinEmptyHoursValue = (state: Object, value: Object) => P.$set('minEmptyHoursValue', value, state);

const setSearchDateToStore = (state: Object, value: Object) => {
  if (G.isNilOrEmpty(value)) {
    return P.$all(
      P.$set('searchDate', value),
      P.$set('filteredByPlaceAutocompleteLoads', null),
      state,
    );
  }

  if (G.isValidMoment(value)) {
    const {
      filters,
      searchRadiusValue,
      minEmptyHoursValue,
      searchPlaceAutocompleteResult,
      notGroupedAssignedLoadsPagination,
    } = state;

    const { items } = notGroupedAssignedLoadsPagination;

    const mapped = mapDriverLoadsToEmptyPeriods(items, minEmptyHoursValue, filters);

    const filtered = filterDriverLoadsByLocationAndDate(
      mapped,
      searchPlaceAutocompleteResult,
      searchRadiusValue,
      value,
    );

    return P.$all(
      P.$set('searchDate', value),
      P.$set('filteredByPlaceAutocompleteLoads', filtered),
      state,
    );
  }

  return P.$set('searchDate', value, state);
};

// pending activity
const createPendingActivitySuccess = (state: Object, data: Object) => {
  const { groupBy } = state;
  const { groupGuid, driverGuid } = data;

  const mapper = (item: Object) => {
    const guid = G.getGuidFromObject(item);

    if (R.equals(guid, driverGuid)) return P.$add('pendingActivities', data, item);

    return item;
  };

  if (R.and(G.isNotNilAndNotEmpty(groupBy), G.isNotNilAndNotEmpty(groupGuid))) {
    const groupStateProp = G.ifElse(
      R.equals(groupBy, GROUP_TYPE_DISPATCHER),
      'driverLoadsByDispatcher',
      'loadsByDispatchGroup',
    );

    const newGroupLoads = P.$map(
      mapper,
      `${groupStateProp}.${groupGuid}`,
      state,
    );

    return P.$set(`${groupStateProp}.${groupGuid}`, newGroupLoads, state);
  }

  const newLoads = P.$map(
    mapper,
    'notGroupedAssignedLoads',
    state,
  );

  const newItems = P.$map(
    mapper,
    'notGroupedAssignedLoadsPagination.items',
    state,
  );

  return P.$all(
    P.$set('notGroupedAssignedLoads', newLoads),
    P.$set('notGroupedAssignedLoadsPagination.items', newItems),
    state,
  );
};

const updatePendingActivitySuccess = (state: Object, data: Object) => {
  const { groupBy, groupGuid, driverGuid } = data;

  const activityGuid = G.getGuidFromObject(data);

  const mapper = (item: Object) => {
    const guid = G.getGuidFromObject(item);

    if (R.equals(guid, driverGuid)) {
      const { pendingActivities } = item;

      return R.assoc(
        'pendingActivities',
        R.map((activity: Object) => {
          const acGuid = G.getGuidFromObject(activity);

          if (R.equals(acGuid, activityGuid)) return data;

          return activity;
        }, pendingActivities),
        item,
      );
    }

    return item;
  };

  if (R.and(G.isNotNilAndNotEmpty(groupBy), G.isNotNilAndNotEmpty(groupGuid))) {
    const groupStateProp = G.ifElse(
      R.equals(groupBy, GROUP_TYPE_DISPATCHER),
      'driverLoadsByDispatcher',
      'loadsByDispatchGroup',
    );

    const newGroupLoads = P.$map(
      mapper,
      `${groupStateProp}.${groupGuid}`,
      state,
    );

    return P.$set(`${groupStateProp}.${groupGuid}`, newGroupLoads, state);
  }

  const newLoads = P.$map(
    mapper,
    'notGroupedAssignedLoads',
    state,
  );

  const newItems = P.$map(
    mapper,
    'notGroupedAssignedLoadsPagination.items',
    state,
  );

  return P.$all(
    P.$set('notGroupedAssignedLoads', newLoads),
    P.$set('notGroupedAssignedLoadsPagination.items', newItems),
    state,
  );
};

const removePendingActivitySuccess = (state: Object, data: Object) => {
  const { groupBy, groupGuid, driverGuid } = data;

  const activityGuid = G.getGuidFromObject(data);

  const mapper = (item: Object) => {
    const { pendingActivities } = item;

    const drGuid = G.getGuidFromObject(item);

    if (R.equals(drGuid, driverGuid)) {
      return R.assoc(
        'pendingActivities',
        R.reject(R.propEq(activityGuid, GC.FIELD_GUID), pendingActivities),
        item,
      );
    }

    return item;
  };

  if (R.and(G.isNotNilAndNotEmpty(groupBy), G.isNotNilAndNotEmpty(groupGuid))) {
    const groupStateProp = G.ifElse(
      R.equals(groupBy, GROUP_TYPE_DISPATCHER),
      'driverLoadsByDispatcher',
      'loadsByDispatchGroup',
    );

    const newGroupLoads = P.$map(
      mapper,
      `${groupStateProp}.${groupGuid}`,
      state,
    );

    return P.$set(`${groupStateProp}.${groupGuid}`, newGroupLoads, state);
  }

  const newLoads = P.$map(
    mapper,
    'notGroupedAssignedLoads',
    state,
  );

  const newItems = P.$map(
    mapper,
    'notGroupedAssignedLoadsPagination.items',
    state,
  );

  return P.$all(
    P.$set('notGroupedAssignedLoads', newLoads),
    P.$set('notGroupedAssignedLoadsPagination.items', newItems),
    state,
  );
};

// unavailable period
const createUnavailablePeriodSuccess = (state: Object, data: Object) => {
  const { groupBy } = state;
  const { groupGuid, driverGuid } = data;

  const mapper = (item: Object) => {
    const guid = G.getGuidFromObject(item);

    if (R.equals(guid, driverGuid)) return P.$add('unavailablePeriods', data, item);

    return item;
  };

  if (R.and(G.isNotNilAndNotEmpty(groupBy), G.isNotNilAndNotEmpty(groupGuid))) {
    const groupStateProp = G.ifElse(
      R.equals(groupBy, GROUP_TYPE_DISPATCHER),
      'driverLoadsByDispatcher',
      'loadsByDispatchGroup',
    );

    const newGroupLoads = P.$map(
      mapper,
      `${groupStateProp}.${groupGuid}`,
      state,
    );

    return P.$set(`${groupStateProp}.${groupGuid}`, newGroupLoads, state);
  }

  const newLoads = P.$map(
    mapper,
    'notGroupedAssignedLoads',
    state,
  );

  const newItems = P.$map(
    mapper,
    'notGroupedAssignedLoadsPagination.items',
    state,
  );

  return P.$all(
    P.$set('notGroupedAssignedLoads', newLoads),
    P.$set('notGroupedAssignedLoadsPagination.items', newItems),
    state,
  );
};

const updateUnavailablePeriodSuccess = (state: Object, data: Object) => {
  const { groupBy, groupGuid, driverGuid } = data;

  const activityGuid = G.getGuidFromObject(data);

  const mapper = (item: Object) => {
    const guid = G.getGuidFromObject(item);

    if (R.equals(guid, driverGuid)) {
      const { unavailablePeriods } = item;

      return R.assoc(
        'unavailablePeriods',
        R.map((activity: Object) => {
          const acGuid = G.getGuidFromObject(activity);

          if (R.equals(acGuid, activityGuid)) return data;

          return activity;
        }, unavailablePeriods),
        item,
      );
    }

    return item;
  };

  if (R.and(G.isNotNilAndNotEmpty(groupBy), G.isNotNilAndNotEmpty(groupGuid))) {
    const groupStateProp = G.ifElse(
      R.equals(groupBy, GROUP_TYPE_DISPATCHER),
      'driverLoadsByDispatcher',
      'loadsByDispatchGroup',
    );

    const newGroupLoads = P.$map(
      mapper,
      `${groupStateProp}.${groupGuid}`,
      state,
    );

    return P.$set(`${groupStateProp}.${groupGuid}`, newGroupLoads, state);
  }

  const newLoads = P.$map(
    mapper,
    'notGroupedAssignedLoads',
    state,
  );

  const newItems = P.$map(
    mapper,
    'notGroupedAssignedLoadsPagination.items',
    state,
  );

  return P.$all(
    P.$set('notGroupedAssignedLoads', newLoads),
    P.$set('notGroupedAssignedLoadsPagination.items', newItems),
    state,
  );
};

const removeUnavailablePeriodSuccess = (state: Object, data: Object) => {
  const { groupBy, groupGuid, driverGuid } = data;

  const activityGuid = G.getGuidFromObject(data);

  const mapper = (item: Object) => {
    const { unavailablePeriods } = item;

    const drGuid = G.getGuidFromObject(item);

    if (R.equals(drGuid, driverGuid)) {
      return R.assoc(
        'unavailablePeriods',
        R.reject(R.propEq(activityGuid, GC.FIELD_GUID), unavailablePeriods),
        item,
      );
    }

    return item;
  };

  if (R.and(G.isNotNilAndNotEmpty(groupBy), G.isNotNilAndNotEmpty(groupGuid))) {
    const groupStateProp = G.ifElse(
      R.equals(groupBy, GROUP_TYPE_DISPATCHER),
      'driverLoadsByDispatcher',
      'loadsByDispatchGroup',
    );

    const newGroupLoads = P.$map(
      mapper,
      `${groupStateProp}.${groupGuid}`,
      state,
    );

    return P.$set(`${groupStateProp}.${groupGuid}`, newGroupLoads, state);
  }

  const newLoads = P.$map(
    mapper,
    'notGroupedAssignedLoads',
    state,
  );

  const newItems = P.$map(
    mapper,
    'notGroupedAssignedLoadsPagination.items',
    state,
  );

  return P.$all(
    P.$set('notGroupedAssignedLoads', newLoads),
    P.$set('notGroupedAssignedLoadsPagination.items', newItems),
    state,
  );
};

const updateTripDriverCardColorSuccess = (state: Object, data: Object) => {
  const { color, groupBy, tripGuid, groupGuid, driverGuid } = data;

  const mapper = (item: Object) => {
    const guid = G.getGuidFromObject(item);

    if (R.equals(guid, driverGuid)) {
      const { tels } = item;

      return R.assoc(
        'tels',
        R.map((trip: Object) => {
          const tGuid = G.getGuidFromObject(trip);

          if (R.equals(tGuid, tripGuid)) return R.assoc(GC.FIELD_DRIVER_CARD_COLOR, color, trip);

          return trip;
        }, tels),
        item,
      );
    }

    return item;
  };

  if (R.and(G.isNotNilAndNotEmpty(groupBy), G.isNotNilAndNotEmpty(groupGuid))) {
    const groupStateProp = G.ifElse(
      R.equals(groupBy, GROUP_TYPE_DISPATCHER),
      'driverLoadsByDispatcher',
      'loadsByDispatchGroup',
    );

    const newGroupLoads = P.$map(
      mapper,
      `${groupStateProp}.${groupGuid}`,
      state,
    );

    return P.$set(`${groupStateProp}.${groupGuid}`, newGroupLoads, state);
  }

  const newLoads = P.$map(
    mapper,
    'notGroupedAssignedLoads',
    state,
  );

  const newItems = P.$map(
    mapper,
    'notGroupedAssignedLoadsPagination.items',
    state,
  );

  return P.$all(
    P.$set('notGroupedAssignedLoads', newLoads),
    P.$set('notGroupedAssignedLoadsPagination.items', newItems),
    state,
  );
};

// tel notification socket
const telNotificationReceived = (state: Object, data: Object) => (
  G.storeTelNotificationReceived(state, data)
);

const getTelNotificationsSuccess = (state: Object, data: Array) => (
  G.storeGetTelNotificationsSuccess(state, data)
);

const hideTelNotificationsSuccess = (state: Object, guid: string) => (
  G.storeHideTelNotificationsSuccess(state, guid)
);

// driver cards socket
const getGroupedStateOnReceived = (param: Object) => {
  const { state, mapper, groupBy, driverGuid } = param;

  const groupStateProp = G.ifElse(
    R.equals(groupBy, GROUP_TYPE_DISPATCHER),
    'driverLoadsByDispatcher',
    'loadsByDispatchGroup',
  );

  const groupGuid = R.compose(
    R.prop('groupByGuid'),
    R.find(R.propEq(driverGuid, GC.FIELD_GUID)),
    R.reduce(R.concat, []),
    R.values,
  )(G.getPropFromObject(groupStateProp, state));

  if (G.isNotNilAndNotEmpty(groupGuid)) {
    const newGroupLoads = P.$map(
      mapper,
      `${groupStateProp}.${groupGuid}`,
      state,
    );

    return P.$set(`${groupStateProp}.${groupGuid}`, newGroupLoads, state);
  }

  return state;
};

const driverCardsTelDeleteReceived = (state: Object, body: Object) => {
  const { groupBy } = state;

  const { data, driverGuid } = body;

  const telGuid = G.getGuidFromObject(data);

  const mapper = (item: Object) => {
    const { tels } = item;

    const drGuid = G.getGuidFromObject(item);

    if (R.equals(drGuid, driverGuid)) {
      return R.assoc(
        'tels',
        R.reject(R.propEq(telGuid, GC.FIELD_GUID), tels),
        item,
      );
    }

    return item;
  };

  if (R.and(G.isNotNilAndNotEmpty(groupBy), G.notEquals(groupBy, GROUP_TYPE_NONE))) {
    return getGroupedStateOnReceived({ state, mapper, groupBy, driverGuid });
  }

  const newLoads = P.$map(
    mapper,
    'notGroupedAssignedLoads',
    state,
  );

  const newItems = P.$map(
    mapper,
    'notGroupedAssignedLoadsPagination.items',
    state,
  );

  return P.$all(
    P.$set('notGroupedAssignedLoads', newLoads),
    P.$set('notGroupedAssignedLoadsPagination.items', newItems),
    state,
  );
};

const driverCardsTelReceived = (state: Object, body: Object) => {
  const { groupBy } = state;

  const { data, driverGuid, entityOperation } = body;

  if (R.equals(entityOperation, 'DELETE')) {
    return driverCardsTelDeleteReceived(state, body);
  }

  const mapper = (item: Object) => {
    const guid = G.getGuidFromObject(item);

    if (R.equals(guid, driverGuid)) return P.$add('tels', data, item);

    return item;
  };

  if (R.and(G.isNotNilAndNotEmpty(groupBy), G.notEquals(groupBy, GROUP_TYPE_NONE))) {
    return getGroupedStateOnReceived({ state, mapper, groupBy, driverGuid });
  }

  const newLoads = P.$map(
    mapper,
    'notGroupedAssignedLoads',
    state,
  );

  const newItems = P.$map(
    mapper,
    'notGroupedAssignedLoadsPagination.items',
    state,
  );

  return P.$all(
    P.$set('notGroupedAssignedLoads', newLoads),
    P.$set('notGroupedAssignedLoadsPagination.items', newItems),
    state,
  );
};
//

export default createReducer({
  [A.setZoomToStore]: setZoomToStore,
  [A.setValueToStore]: setValueToStore,
  [A.setInitialState]: setInitialState,
  [A.setFilterToStore]: setFilterToStore,
  [A.setFiltersToStore]: setFiltersToStore,
  [A.setGroupByToStore]: setGroupByToStore,
  [A.setDayFilterToStore]: setDayFilterToStore,
  [A.setDateRangeFilters]: setDateRangeFilters,
  [A.setSearchDateToStore]: setSearchDateToStore,
  [A.setSearchRadiusValue]: setSearchRadiusValue,
  [A.setGlobalFilterValue]: setGlobalFilterValue,
  [A.setLoadRefFilterValue]: setLoadRefFilterValue,
  [A.setMinEmptyHoursValue]: setMinEmptyHoursValue,
  [A.toggleUnassignedLoads]: toggleUnassignedLoads,
  [A.toggleDispatchByTruck]: toggleDispatchByTruck,
  [A.setWarningPeriodConfig]: setWarningPeriodConfig,
  [A.getDriverCardsInfoSuccess]: getDriverCardsInfoSuccess,
  [A.filterByPlaceAutocomplete]: filterByPlaceAutocomplete,
  [A.getUnassignedLoadsSuccess]: getUnassignedLoadsSuccess,
  [A.getTelNotificationsSuccess]: getTelNotificationsSuccess,
  [A.hideTelNotificationsSuccess]: hideTelNotificationsSuccess,
  [A.createPendingActivitySuccess]: createPendingActivitySuccess,
  [A.updatePendingActivitySuccess]: updatePendingActivitySuccess,
  [A.removePendingActivitySuccess]: removePendingActivitySuccess,
  [A.getLoadsByDispatchGroupSuccess]: getLoadsByDispatchGroupSuccess,
  [A.getMoreNotGroupedAssignedLoads]: getMoreNotGroupedAssignedLoads,
  [A.createUnavailablePeriodSuccess]: createUnavailablePeriodSuccess,
  [A.updateUnavailablePeriodSuccess]: updateUnavailablePeriodSuccess,
  [A.removeUnavailablePeriodSuccess]: removeUnavailablePeriodSuccess,
  [A.setSearchPlaceAutocompleteValue]: setSearchPlaceAutocompleteValue,
  [A.createDriverOnDriversCardSuccess]: createDriverOnDriversCardSuccess,
  [A.updateTripDriverCardColorSuccess]: updateTripDriverCardColorSuccess,
  [A.getDriverCardsByTruckInfoSuccess]: getDriverCardsByTruckInfoSuccess,
  [A.getDriverLoadsByDispatcherSuccess]: getDriverLoadsByDispatcherSuccess,
  [A.getNotGroupedAssignedLoadsSuccess]: getNotGroupedAssignedLoadsSuccess,
  //
  [socketTelWarningReceived]: telNotificationReceived,
  [socketDriverCardsTelReceived]: driverCardsTelReceived,
}, initialState);


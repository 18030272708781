import React from 'react';
import { connect } from 'react-redux';
import { pure, compose } from 'react-recompose';
// components
import { makeSelectLoader } from '../../../../components/loader/selectors';
import { openModal, closeModal } from '../../../../components/modal/actions';
// helpers/constants
import * as GC from '../../../../constants';
// feature configs
import ConfigComponent from '../../components/config-component';
import { itemPageConfigEnhancer, defaultPageConfigEnhancer } from '../../hocs';
import {
  updateConfigsRequest,
  restoreInheritedByConfigTypeRequest,
} from '../../actions';
import {
  makeSelectConfigOptions,
  makeSelectConfigDropdowns,
  makeSelectConfigInitialValues,
  makeSelectConfigInheritedValues,
} from '../../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  defaultPageConfigEnhancer(GC.FLEET_GENERAL_CONFIG_GROUP),
  itemPageConfigEnhancer,
  pure,
);

const ConfigFleetGeneralComponent = (props: Object) => (
  <ConfigComponent
    {...props}
    useTabs={true}
    pageTitle='titles:fleet-general'
    groupSettings={GC.FLEET_GENERAL_CONFIG_GROUP}
  />
);

const mapStateToProps = (state: Object) => ({
  loader: makeSelectLoader()(state),
  dropdowns: makeSelectConfigDropdowns(state, GC.FLEET_GENERAL_CONFIG_GROUP),
  configOptions: makeSelectConfigOptions(state, GC.FLEET_GENERAL_CONFIG_GROUP),
  initialValues: makeSelectConfigInitialValues(state, GC.FLEET_GENERAL_CONFIG_GROUP),
  inheritedValues: makeSelectConfigInheritedValues(state, GC.FLEET_GENERAL_CONFIG_GROUP),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  updateConfigsRequest,
  restoreInheritedByConfigTypeRequest,
})(enhance(ConfigFleetGeneralComponent));

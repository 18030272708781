import React from 'react';
// feature fleet-profile/driver
import { FleetAssignment } from '../components/fleet-assignment';
//////////////////////////////////////////////////

export const fleetAssignmentSettings = {
  groupName: 'fleetAssignment',
  Component: ({
    isOpened,
    itemList,
    openModal,
    vendorBranchGuid,
    primaryObjectGuid,
    handleToggleFormGroupTable,
    handleGoToProfileByProfileType,
  }: Object) => (
    <FleetAssignment
      isOpened={isOpened}
      openModal={openModal}
      fleetAssignment={itemList}
      branchGuid={vendorBranchGuid}
      vendorGuid={primaryObjectGuid}
      handleToggleFormGroupTable={handleToggleFormGroupTable}
      handleGoToProfileByProfileType={handleGoToProfileByProfileType}
    />
  ),
};

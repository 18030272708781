import * as R from 'ramda';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// components
import { EmptyList } from '../../../components/list';
import { GroupableTable } from '../../../components';
import { makeSelectLoader } from '../../../components/loader/selectors';
// features
import { setExpandedContainerOptions } from '../../expanded-container/actions';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box } from '../../../ui';
// feature dashboards
import {
  makeSelectTitleSortValues,
  makeSelectTableTitleFilters,
} from '../selectors';
import {
  setTableTitleSort,
  setTableTitleFilter,
} from '../actions';
import { getFilteredAndSortedTableData } from '../helpers';
//////////////////////////////////////////////////

const GroupableTableComponent = (props: Object) => {
  const guid = G.getGuidFromObject(R.prop('report', props));

  const dispatch = useDispatch();
  const { showDimmer } = useSelector(makeSelectLoader());
  const titleSortValues = R.propOr({}, guid, useSelector(makeSelectTitleSortValues()));
  const tableTitleFilters = R.propOr({}, guid, useSelector(makeSelectTableTitleFilters()));

  const handleTableTitleFilter = useCallback((data: Object) => {
    const { isSorting } = data;

    if (isSorting) {
      dispatch(setTableTitleSort({ sort: R.prop('sortData', data), guid }));
    } else {
      const filters = G.createReportFilterFromTitleSearch(data);

      dispatch(setTableTitleFilter({ filters, guid }));
    }
  }, [guid]);

  const handleSetExpandedContainerOptions = useCallback((payload: Object) => {
    dispatch(setExpandedContainerOptions(payload));
  }, []);

  const tableData = R.propOr([], 'data', props);
  const tableSettings = R.prop('tableSettings', props);

  const preparedData = getFilteredAndSortedTableData({
    tableData,
    tableSettings,
    titleSortValues,
    tableTitleFilters,
  });

  if (R.and(
    R.not(showDimmer),
    G.isNilOrEmpty(tableData),
  )) {
    return (
      <Box width='100%' height={300}>
        <EmptyList>
          {G.getWindowLocale('titles:no-data-available', 'No Data Available')}
        </EmptyList>
      </Box>
    );
  }

  return (
    <GroupableTable
      {...props}
      data={preparedData}
      loading={showDimmer}
      titleSortValues={titleSortValues}
      tableTitleFilters={tableTitleFilters}
      handleTableTitleFilter={handleTableTitleFilter}
      callbackData={{
        setExpandedContainerOptions: handleSetExpandedContainerOptions,
      }}
    />
  );
};

export default GroupableTableComponent;


import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
// feature config
import {
  ediExtractorConfigFields,
  defaultEdiExtractorConfigFields,
  ediExtractorConfigValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultEdiExtractorConfigFields,
      props.initialValues,
    ),
    validationSchema: ediExtractorConfigValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
  }),
  pure,
);

const EdiExtractorConfigForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...props.optionsForSelect}
      {...G.getFormikProps(props)}
      handlers={props.handlers}
      fields={ediExtractorConfigFields}
      fieldsWrapperStyles={{ pt: 11, justifyContent: 'space-between' }}
    />
    <FormFooter />
  </form>
);

export default enhance(EdiExtractorConfigForm);

import * as R from 'ramda';
import React from 'react';
import { withHandlers } from 'react-recompose';
// features
import PC from '../../permission/role-permission';
import { AsyncLoadReferencesForm } from '../../reference/components/load-references-form';
// forms
// helpers/constants
import * as G from '../../../helpers';
// feature dispatch-board-new
import AsyncLoadNotes from '../components/async-load-notes';
import AsyncLoadChatMessages from '../components/async-load-chat-messages';
/////////////////////////////////////////////////

export { withSelectLoads } from './with-select-loads';
export { withAsyncLoadNotes } from './with-async-load-notes';
export { withPostToLoadBoard } from './with-post-to-load-board';
export { withAsyncLoadChatMessages } from './with-async-load-chat-messages';
export { withSendTelsToExternalSystem } from './with-send-tels-to-external-system';

export const withLoadMessageNoteClick = withHandlers({
  handleShowLoadReferences: () => ({ guid, loadType, fromPage, openModal, closeModal }: Object) => {
    const isClo = G.isLoadTypeClo(loadType);
    const writePermission = G.hasAmousCurrentUserPermissions(G.ifElse(
      isClo,
      [PC.CLO_REFERENCE_WRITE],
      [PC.TEL_REFERENCE_WRITE],
    ));

    const component = (
      <AsyncLoadReferencesForm
        loadGuid={guid}
        loadType={loadType}
        fromPage={fromPage}
        closeModal={closeModal}
        writePermission={writePermission}
      />
    );
    const modal = {
      component,
      options: {
        top: 200,
        width: 550,
        height: 'auto',
        maxHeight: '80vh',
        overflow: 'visible',
        outsideCloseButton: R.not(writePermission),
      },
      p: '0',
    };
    openModal(modal);
  },
  handleMessageNoteClick: (props: Object) => (e: Object, data: Object) => {
    const { type, guid, loadType } = data;

    const {
      position,
      openModal,
      closeModal,
      openFixedPopup,
      closeFixedPopup,
      toggleLoadDetailsSuccess,
      toggleOrderDetailsRequest,
    } = props;

    const isClo = G.isLoadTypeClo(loadType);
    const chatMessageEditPermission = G.ifElse(
      isClo,
      [PC.CLO_ADMINISTRATION_EXECUTE],
      [PC.TEL_ADMINISTRATION_EXECUTE],
    );
    const chatMessageAddPermission = G.ifElse(
      isClo,
      [PC.CLO_CHAT_MESSAGE_WRITE],
      [PC.TEL_CHAT_MESSAGE_WRITE],
    );
    const isNotes = R.equals(type, 'NOTES');
    const addPermissions = G.ifElse(isNotes, null, chatMessageAddPermission);
    const editPermissions = G.ifElse(isNotes, null, chatMessageEditPermission);
    const addMessageText = G.ifElse(
      isNotes,
      G.getWindowLocale('actions:post', 'Post'),
      G.getWindowLocale('actions:send', 'Send'),
    );
    let content;
    const contentProps = {
      loadType,
      openModal,
      closeModal,
      width: 350,
      height: 400,
      padding: '8px',
      loadGuid: guid,
      addMessageText,
      addPermissions,
      editPermissions,
      closeContent: closeFixedPopup,
    };

    if (isNotes) {
      const refreshLoad = G.ifElse(G.isLoadTypeClo(loadType), toggleOrderDetailsRequest, toggleLoadDetailsSuccess);
      content = (
        <AsyncLoadNotes {...contentProps} refreshLoad={refreshLoad} />
      );
    } else {
      content = (
        <AsyncLoadChatMessages {...contentProps} />
      );
    }

    openFixedPopup({
      content,
      version: 2,
      el: e.currentTarget,
      boxStyles: { zIndex: 10 },
      shouldNotCloseOnScroll: true,
      position: position || 'right',
    });
  },
});

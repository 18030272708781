import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { makeSelectLoader } from '../../../../components/loader/selectors';
import { openModal, closeModal } from '../../../../components/modal/actions';
// features
import { makeSelectCurrentBranchType } from '../../../branch/selectors';
import { makeSelectIsCurrentUserSuperAdmin } from '../../../auth/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature configs
import PublicCLOForm from './components/public-clo';
import VinLookupForm from './components/vin-lookup-form';
import AccountingForm from './components/accounting-form';
import TollChargeForm from './components/toll-charge-form';
import EdiConfigsForm from './components/edi-configs-form';
import FuelCardFormComponent from './components/fuel-card-form';
import ConfigComponent from '../../components/config-component';
import AdvancePaymentForm from './components/advance-payment-form';
import EdiExporterConfigForm from './components/edi-exporter-form';
import LosPricingPlanForm from './components/los-pricing-plan-form';
import LosSubscriptionForm from './components/los-subscription-form';
import EdiExtractorConfigForm from './components/edi-extractor-form';
import TruckTypeMappingForm from './components/truck-type-mapping-form';
import TrailerMappingForm from './components/trailer-type-mapping-form';
import DocumentTypeMappingForm from './components/document-type-mapping';
import DocumentHubConfigForm from './components/document-hub-config-form';
import StatusCodeMappingForm from './components/status-code-mapping-form';
import UserMonitoringFormComponent from './components/user-monitoring-form';
import ServiceTypeMappingForm from './components/service-type-mapping-form';
import DispatchIntegrationForm from './components/dispatch-integration-form';
import TerminalIntegrationForm from './components/terminal-integration-form';
import CarrierSynchronizationForm from './components/carrier-synchronization-form';
import EquipmentMappingConfigForm from './components/equipment-mapping-config-form';
import CarrierPickupRequesterForm from './components/carrier-pickup-requester-form';
import CarrierIntegrationFormComponent from './components/carrier-integration-form';
import { defaultPageConfigEnhancer, withTransportationModeMapping } from '../../hocs';
import { StatusReasonCodeMappingForm } from './components/status-reason-code-mapping-form';
import CustomerIntegrationConfigsForm from './components/customer-integration-config-form';
import FactoringIntegrationConfigForm from './components/factoring-integration-config-form';
import TrailerTrackingIntegrationForm from './components/trailer-tracking-integration-form';
import LoadBoardIntegrationConfigForm from './components/load-board-integration-config-form';
import CrossBorderIntegrationConfigForm from './components/cross-border-integration-config-form';
import ServiceMappingFormComponent, { MultipleServiceMappingForm } from './components/service-mapping-form';
import { CarrierEdiLoaderConfigForm, CarrierEdiExporterConfigForm } from './components/carrier-edi-config-form';
import {
  makeSelectFuelCardList,
  makeSelectConfigOptions,
  makeSelectTollChargeList,
  makeSelectEdiConfigsList,
  makeSelectPublicCLOConfig,
  makeSelectConfigDropdowns,
  makeSelectCarrierSequences,
  makeSelectLosPricingPlanList,
  makeSelectAdvancePaymentList,
  makeSelectConfigInitialValues,
  makeSelectVinLookupConfigList,
  makeSelectLoadBoardConfigList,
  makeSelectServiceMappingFilter,
  makeSelectAccountingConfigList,
  makeSelectTruckTypeMappingList,
  makeSelectLosSubscriptionConfig,
  makeSelectIntegrationConfigList,
  makeSelectEdiExporterConfigList,
  makeSelectStatusCodeMappingList,
  makeSelectConfigInheritedValues,
  makeSelectDocumentHubConfigList,
  makeSelectEdiExtractorConfigList,
  makeSelectServiceTypeMappingList,
  makeSelectTrailerTypeMappingList,
  makeSelectDocumentTypeMappingList,
  makeSelectDispatchIntegrationList,
  makeSelectTerminalIntegrationList,
  makeSelectServiceMappingConfigList,
  makeSelectCarrierEdiLoaderConfigList,
  makeSelectEquipmentMappingConfigList,
  makeSelectStatusReasonCodeMappingList,
  makeSelectCarrierSynchronizationConfig,
  makeSelectCarrierEdiExporterConfigList,
  makeSelectStatusCodeOutboundMappingList,
  makeSelectCarrierPickupRequesterListList,
  makeSelectFactoringIntegrationConfigList,
  makeSelectCarrierIntegrationOverrideList,
  makeSelectTrailerTrackingIntegrationList,
  makeSelectFilteredServiceMappingConfigList,
  makeSelectCarrierRateIntegrationConfigList,
  makeSelectCrossBorderIntegrationConfigList,
  makeSelectUserMonitoringIntegrationConfigList,
  makeSelectStatusReasonCodeOutboundMappingList,
  makeSelectIntegrationTransportationModeConfigMappingList,
} from '../../selectors';
import {
  updateConfigsRequest,
  updateFuelCardRequest,
  createFuelCardRequest,
  removeFuelCardRequest,
  removeEdiConfigsRequest,
  createEdiConfigsRequest,
  setServiceMappingFilter,
  updateEdiConfigsRequest,
  removeServiceMappingRequest,
  removeAdvancePaymentRequest,
  removeVinLookupConfigRequest,
  getServiceMappingListRequest,
  removePublicCLOConfigRequest,
  removeAccountingConfigRequest,
  removeTruckTypeMappingRequest,
  setIntegrationActiveConfigTab,
  createEdiExporterConfigRequest,
  removeTollChargesConfigRequest,
  updateIntegrationConfigRequest,
  createIntegrationConfigRequest,
  removeIntegrationConfigRequest,
  updateEdiExporterConfigRequest,
  removeEdiExporterConfigRequest,
  removeDocumentHubConfigRequest,
  removeStatusCodeMappingRequest,
  removeEdiExtractorConfigRequest,
  updateEdiExtractorConfigRequest,
  createEdiExtractorConfigRequest,
  createServiceMappingListRequest,
  removeServiceTypeMappingRequest,
  removeTrailerTypeMappingRequest,
  removeDocumentTypeMappingRequest,
  removeLosPricingPlanConfigRequest,
  updateLosSubscriptionConfigRequest,
  restoreInheritedByConfigTypeRequest,
  createOrUpdateAdvancePaymentRequest,
  createOrUpdateServiceMappingRequest,
  removeCarrierRateIntegrationRequest,
  removeCarrierPickupRequesterRequest,
  removeCarrierEdiLoaderConfigRequest,
  removeEquipmentMappingConfigRequest,
  createOrUpdatePublicCLOConfigRequest,
  createOrUpdateVinLookupConfigRequest,
  removeStatusReasonCodeMappingRequest,
  createOrUpdateAccountingConfigRequest,
  removeCarrierEdiExporterConfigRequest,
  createOrUpdateTruckTypeMappingRequest,
  removeTerminalIntegrationConfigRequest,
  removeUserMonitoringIntegrationRequest,
  createOrUpdateTollChargesConfigRequest,
  createOrUpdateStatusCodeMappingRequest,
  createOrUpdateDocumentHubConfigRequest,
  removeDispatchIntegrationConfigRequest,
  removeLoadBoardIntegrationConfigRequest,
  removeCarrierIntegrationOverrideRequest,
  createOrUpdateServiceTypeMappingRequest,
  removeFactoringIntegrationConfigRequest,
  createOrUpdateTrailerTypeMappingRequest,
  createOrUpdateDocumentTypeMappingRequest,
  createOrUpdateLosPricingPlanConfigRequest,
  removeCarrierSynchronizationConfigRequest,
  removeCrossBorderIntegrationConfigRequest,
  createOrUpdateCarrierEdiLoaderConfigRequest,
  createOrUpdateEquipmentMappingConfigRequest,
  createOrUpdateCarrierPickupRequesterRequest,
  createOrUpdateCarrierRateIntegrationRequest,
  createOrUpdateStatusReasonCodeMappingRequest,
  removeTransportationModeConfigMappingRequest,
  createOrUpdateCarrierEdiExporterConfigRequest,
  removeTrailerTrackingIntegrationConfigRequest,
  createOrUpdateTerminalIntegrationConfigRequest,
  createOrUpdateUserMonitoringIntegrationRequest,
  createOrUpdateDispatchIntegrationConfigRequest,
  createOrUpdateCarrierIntegrationOverrideRequest,
  createOrUpdateFactoringIntegrationConfigRequest,
  createOrUpdateLoadBoardIntegrationConfigRequest,
  removeLosPricingPlanConfigWithSubstitutionRequest,
  createOrUpdateCarrierSynchronizationConfigRequest,
  createOrUpdateCrossBorderIntegrationConfigRequest,
  createOrUpdateTransportationModeConfigMappingRequest,
  createOrUpdateTrailerTrackingIntegrationConfigRequest,
} from '../../actions';
//////////////////////////////////////////////////

const getModalWithComponent = (component: any, title: string, width: string = 320, overflow: string = 'auto') => ({
  p: 15,
  component,
  options: {
    title,
    width,
    overflow,
    height: 'auto',
    maxHeight: '90vh',
  },
});

const getEdiFormOptions = (configOptions: Object) => ({
  documentTypeOptions: R.propOr([], GC.COMMUNICATION_DOCUMENT_TYPE, configOptions),
  statusCodeList: R.pathOr([], ['allStatusCodeListWithStoredValues'], configOptions),
  availableEdiExportMappers: R.pathOr([], ['availableEdiExportMappers'], configOptions),
  cloSequences: G.addEmptyOptionToDropDown(R.pathOr([], ['cloSequences'], configOptions)),
  availableEdiExportMappersForLoader: R.pathOr([], ['availableEdiExportMappersForLoader'], configOptions),
  cloReferenceTypes: R.prepend(
    GC.EMPTY_OPTION_OBJECT,
    R.pathOr([], ['cloReferenceTypes'], configOptions),
  ),
});

const getCustomerBranchListOptions = R.compose(
  G.mapNameGuidObjectPropsToLabelValueObject,
  R.sortBy(R.compose(R.toLower, R.trim, R.prop(GC.FIELD_NAME))),
);

const getEdiExportConfigOptions = R.compose(
  R.map(({ guid, folderName, customerGuid, [GC.BRANCH_GUID]: exporterBranchGuid }: Object) => ({
    value: guid,
    exporterBranchGuid,
    exporterCustomerGuid: customerGuid,
    label: `${G.getBranchNameFromWindowByGuid(customerGuid)} - ${folderName}`,
  })),
  R.values,
  R.propOr([], 'ediExporterConfigList'),
);

const enhance = compose(
  defaultPageConfigEnhancer(GC.INTEGRATION_CONFIG_GROUP),
  withTransportationModeMapping(GC.INTEGRATION_CONFIG_GROUP),
  withHandlers({
    handleClickEndLabel: (props: Object) => (configName: string) => {
      if (R.equals(configName, GC.CUSTOM_TRANSPORTATION_MODE_MAPPING_CONFIG)) {
        return props.handleAddTransportationModeMapping();
      }

      if (R.equals(configName, GC.CUSTOM_EQUIPMENT_MAPPING_CONFIG)) {
        const equipmentOptions = R.pathOr([], ['configOptions', GC.GENERAL_EQUIPMENTS], props);
        const component = (
          <EquipmentMappingConfigForm
            editMode={false}
            closeModal={props.closeModal}
            equipmentOptions={equipmentOptions}
            submitAction={props.createOrUpdateEquipmentMappingConfigRequest}
          />
        );
        const modal = {
          p: 15,
          component,
          options: {
            title: G.getAddTitle(['titles:equipment-mapping', 'Equipment Mapping']),
          },
        };

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_FACTORING_INTEGRATION_CONFIG)) {
        const cloReferenceTypes = R.pathOr([], ['configOptions', 'cloReferenceTypes'], props);

        const customerInvoiceReferenceTypes = R.pathOr([], ['configOptions', 'customerInvoiceReferenceTypes'], props);

        const component = (
          <FactoringIntegrationConfigForm
            editMode={false}
            closeModal={props.closeModal}
            optionsForSelect={{ cloReferenceTypes, customerInvoiceReferenceTypes }}
            submitAction={props.createOrUpdateFactoringIntegrationConfigRequest}
          />
        );
        const modal = {
          component,
          p: '15px 0',
          options: {
            title: G.getAddTitle(['titles:factoring-integration-config', 'Factoring Integration Config']),
          },
        };

        return props.openModal(modal);
      }

      if (R.includes(
        configName,
        [GC.CUSTOM_STATUS_CODE_MAPPING_CONFIG, GC.CUSTOM_STATUS_CODE_OUTBOUND_MAPPING_CONFIG],
      )) {
        const isOutboundMapping = R.equals(configName, GC.CUSTOM_STATUS_CODE_OUTBOUND_MAPPING_CONFIG);

        const initialValues = G.ifElse(
          isOutboundMapping,
          { [GC.FIELD_INTEGRATION_TYPE]: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI },
          null,
        );

        const component = (
          <StatusCodeMappingForm
            isEditMode={false}
            closeModal={props.closeModal}
            initialValues={initialValues}
            submitAction={props.createOrUpdateStatusCodeMappingRequest}
            mappingType={G.ifElse(isOutboundMapping, 'outboundMapping', 'inboundMapping')}
            allStatusCodeList={R.pathOr([], ['configOptions', 'allStatusCodeList'], props)}
          />
        );

        const titleArray = G.ifElse(
          isOutboundMapping,
          ['titles:status-code-outbound-mapping', 'Status Code Outbound Mapping'],
          ['titles:status-code-inbound-mapping', 'Status Code Inbound Mapping'],
        );

        const modal = getModalWithComponent(component, G.getAddTitle(titleArray), 300, 'unset');

        return props.openModal(modal);
      }

      if (R.includes(
        configName,
        [GC.CUSTOM_STATUS_REASON_CODE_MAPPING_CONFIG, GC.CUSTOM_STATUS_REASON_CODE_OUTBOUND_MAPPING_CONFIG],
      )) {
        const { openModal, createOrUpdateStatusReasonCodeMappingRequest } = props;

        const isOutboundMapping = R.equals(configName, GC.CUSTOM_STATUS_REASON_CODE_OUTBOUND_MAPPING_CONFIG);

        const initialValues = G.ifElse(
          isOutboundMapping,
          { [GC.FIELD_INTEGRATION_TYPE]: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI },
          null,
        );

        const component = (
          <StatusReasonCodeMappingForm
            isEditMode={false}
            initialValues={initialValues}
            submitAction={createOrUpdateStatusReasonCodeMappingRequest}
            mappingType={G.ifElse(isOutboundMapping, 'outboundMapping', 'inboundMapping')}
            statusReasonCodeOptions={R.pathOr([], ['configOptions', GC.COMMUNICATION_REASON_CODE], props)}
          />
        );

        const titleArray = G.ifElse(
          isOutboundMapping,
          ['titles:status-reason-outbound-mapping', 'Status Reason Outbound Mapping'],
          ['titles:status-reason-inbound-mapping', 'Status Reason Inbound Mapping'],
        );

        const modal = getModalWithComponent(component, G.getAddTitle(titleArray), 300, 'unset');

        return openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_CARRIER_SYNCHRONIZATION_CONFIG)) {
        const component = (
          <CarrierSynchronizationForm
            isEditMode={false}
            closeModal={props.closeModal}
            submitAction={props.createOrUpdateCarrierSynchronizationConfigRequest}
          />
        );
        const modal = getModalWithComponent(
          component,
          G.getAddTitle(['titles:carrier-synchronization', 'Carrier Synchronization']),
          300,
        );

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_DISPATCH_INTEGRATION_CONFIG)) {
        const optionsForSelect = {
          trackingStatusCodeList: G.addEmptyOptionToDropDown(
            R.pathOr([], ['configOptions', 'trackingStatusCodeList'], props),
          ),
        };
        const component = (
          <DispatchIntegrationForm
            isEditMode={false}
            closeModal={props.closeModal}
            optionsForSelect={optionsForSelect}
            submitAction={props.createOrUpdateDispatchIntegrationConfigRequest}
          />
        );
        const modal = {
          p: 15,
          component,
          options: {
            height: 'auto',
            overflow: 'auto',
            maxHeight: '90vh',
            title: G.getAddTitle(['titles:tracking-management', 'Tracking Management']),
          },
        };

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_INTEGRATION_CONFIGS_CONFIG)) {
        const component = (
          <CustomerIntegrationConfigsForm
            isEditMode={false}
            closeModal={props.closeModal}
            submitAction={props.createIntegrationConfigRequest}
            optionsForSelect={getEdiFormOptions(props.configOptions)}
          />
        );
        const modal = getModalWithComponent(
          component,
          G.getWindowLocale('titles:add-integration-config', 'Add Integration Config'),
          'max-content',
        );

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_FUEL_CARD_CONFIG)) {
        const component = (
          <FuelCardFormComponent
            disableFuelCardType={false}
            closeModal={props.closeModal}
            submitAction={props.createFuelCardRequest}
          />
        );

        const modal = getModalWithComponent(component, G.getWindowLocale('titles:add-fuel-card', 'Add Fuel Card'), 300);

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_EDI_CONFIG)) {
        const handlers = {
          handleDisableFolder: () => false,
        };
        let initialValues = {};

        if (G.isCurrentBranchTypeCustomer()) {
          initialValues = { [GC.FIELD_CUSTOMER_GUID]: G.getAmousCurrentBranchGuidFromWindow() };
        }

        const component = (
          <EdiConfigsForm
            handlers={handlers}
            initialValues={initialValues}
            closeModal={props.closeModal}
            submitAction={props.createEdiConfigsRequest}
            ediExporters={getEdiExportConfigOptions(props)}
            optionsForSelect={getEdiFormOptions(props.configOptions)}
          />
        );
        const modal = getModalWithComponent(
          component,
          G.getAddTitle(['titles:edi-loader-config', 'EDI Loader Config']),
          600,
        );

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_EDI_EXTRACTOR_CONFIG)) {
        const handlers = {
          handleDisableFolder: () => false,
        };

        const component = (
          <EdiExtractorConfigForm
            isEditMode={false}
            handlers={handlers}
            closeModal={props.closeModal}
            submitAction={props.createEdiExtractorConfigRequest}
            optionsForSelect={getEdiFormOptions(props.configOptions)}
          />
        );

        const title = G.getAddTitle(['titles:edi-extractor-config', 'EDI Extractor Config']);
        const modal = getModalWithComponent(component, title, 600);

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_EDI_EXPORTER_CONFIG)) {
        let initialValues = {};

        if (G.isCurrentBranchTypeCustomer()) {
          initialValues = { [GC.FIELD_CUSTOMER_GUID]: G.getAmousCurrentBranchGuidFromWindow() };
        }

        const component = (
          <EdiExporterConfigForm
            closeModal={props.closeModal}
            initialValues={initialValues}
            submitAction={props.createEdiExporterConfigRequest}
            optionsForSelect={getEdiFormOptions(props.configOptions)}
          />
        );
        const modal = getModalWithComponent(
          component,
          G.getAddTitle(['titles:edi-exporter-config', 'EDI Exporter Config']),
          '900px',
          'unset',
        );

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_CARRIER_RATE_CONFIG)) {
        const optionsForSelect = {
          carrierSequences: R.path(['configOptions', 'carrierSequences'], props),
          [GC.COMMUNICATION_DOCUMENT_TYPE]: R.path(['configOptions', GC.COMMUNICATION_DOCUMENT_TYPE], props),
          availableCarrierPickupRequesterList: G.addEmptyOptionToDropDown(
            R.values(R.path(['configOptions', 'availableCarrierPickupRequesterList'], props)),
          ),
        };
        const component = (
          <CarrierIntegrationFormComponent
            isEditMode={false}
            closeModal={props.closeModal}
            disableIntegrationType={false}
            optionsForSelect={optionsForSelect}
            submitAction={props.createOrUpdateCarrierRateIntegrationRequest}
          />
        );
        const title = G.getAddTitle(['titles:carrier-integration', 'Carrier Integration']);
        const modal = {
          p: 15,
          component,
          options: {
            title,
            width: 'auto',
            height: 'auto',
            maxHeight: '90vh',
            overflow: 'hidden',
          },
        };

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_USER_MONITORING_CONFIG)) {
        const component = (
          <UserMonitoringFormComponent
            isEditMode={false}
            closeModal={props.closeModal}
            disableIntegrationType={false}
            submitAction={props.createOrUpdateUserMonitoringIntegrationRequest}
          />
        );
        const title = G.getAddTitle(['titles:user-monitoring', 'User Monitoring']);
        const modal = {
          p: 15,
          component,
          options: {
            title,
            width: 'auto',
            height: 'auto',
            maxHeight: '90vh',
            overflow: 'hidden',
          },
        };
        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_SERVICE_MAPPING_CONFIG_SECTION)) {
        const optionsForSelect = {
          services: R.pathOr([], ['configOptions', GC.GENERAL_SERVICES], props),
          accessorialServiceCodes: R.pathOr([], ['configOptions', 'accessorialServiceCodes'], props),
        };
        const component = (
          <MultipleServiceMappingForm
            closeModal={props.closeModal}
            disableIntegrationType={false}
            optionsForSelect={optionsForSelect}
            serviceMappingList={props.serviceMappingList}
            submitAction={props.createServiceMappingListRequest}
            services={R.pathOr([], ['configOptions', GC.GENERAL_SERVICES], props)}
          />
        );
        const title = G.getAddTitle(['titles:carrier-service-mapping', 'Carrier Service Mapping']);
        const modal = {
          component,
          p: '15px 0px 15px 15px',
          options: {
            title,
            width: 'auto',
            height: 'auto',
          },
        };

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_ADVANCE_PAYMENT_CONFIG)) {
        const component = (
          <AdvancePaymentForm
            editMode={false}
            closeModal={props.closeModal}
            submitAction={props.createOrUpdateAdvancePaymentRequest}
          />
        );
        const title = G.getAddTitle(['titles:advance-payment', 'Advance Payment']);
        const modal = {
          p: 15,
          component,
          options: {
            title,
            width: 630,
            height: 'auto',
            overflow: 'auto',
            maxHeight: '90vh',
          },
        };

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_LOADBOARD_CONFIG)) {
        const optionsForSelect = {
          transportationModeOptions: R.pathOr([], ['configOptions', GC.GENERAL_MODE_TRANSPORTATION], props),
        };

        const component = (
          <LoadBoardIntegrationConfigForm
            isEditMode={false}
            closeModal={props.closeModal}
            optionsForSelect={optionsForSelect}
            loadBoardConfigList={props.loadBoardConfigList}
            submitAction={props.createOrUpdateLoadBoardIntegrationConfigRequest}
          />
        );

        const title = G.getAddTitle(['titles:external-load-boards', 'External Load Board']);
        const modal = getModalWithComponent(component, title, 300);

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_TOLL_CHARGES_CONFIG)) {
        const component = (
          <TollChargeForm
            isEditMode={false}
            closeModal={props.closeModal}
            submitAction={props.createOrUpdateTollChargesConfigRequest}
          />
        );
        const title = G.getAddTitle(['titles:toll-charge ', 'Toll Charge']);
        const modal = getModalWithComponent(component, title, 300);

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_PUBLIC_CLO_CONFIG)) {
        const optionsForSelect = {
          cloReferenceTypes: G.addEmptyOptionToDropDown(
            R.pathOr([], ['configOptions', 'cloReferenceTypes'], props),
          ),
        };

        const component = (
          <PublicCLOForm
            isEditMode={false}
            closeModal={props.closeModal}
            isSuperAdmin={props.isSuperAdmin}
            optionsForSelect={optionsForSelect}
            submitAction={props.createOrUpdatePublicCLOConfigRequest}
          />
        );

        const title = G.getAddTitle(['titles:public-api-order-config', 'Public API Order Config']);
        const modal = getModalWithComponent(component, title, 300);

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_SERVICE_TYPE_MAPPING_CONFIG)) {
        const component = (
          <ServiceTypeMappingForm
            isEditMode={false}
            closeModal={props.closeModal}
            submitAction={props.createOrUpdateServiceTypeMappingRequest}
            serviceTypeConfigOptions={R.pathOr([], ['configOptions', GC.GENERAL_TRANSPORTATION_SERVICE_TYPE], props)}
          />
        );

        const title = G.getAddTitle(['titles:service-type-mapping', 'Service Type Mapping']);
        const modal = getModalWithComponent(component, title, 300, 'unset');

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_CARRIER_INTEGRATION_OVERRIDE_CONFIG)) {
        const optionsForSelect = {
          carrierSequences: R.path(['configOptions', 'carrierSequences'], props),
          [GC.COMMUNICATION_DOCUMENT_TYPE]: R.path(['configOptions', GC.COMMUNICATION_DOCUMENT_TYPE], props),
          customerBranchList: getCustomerBranchListOptions(
            R.pathOr([], ['configOptions', 'customerBranchList'], props),
          ),
          availableCarrierPickupRequesterList: G.addEmptyOptionToDropDown(
            R.values(R.path(['configOptions', 'availableCarrierPickupRequesterList'], props)),
          ),
        };
        const component = (
          <CarrierIntegrationFormComponent
            override={true}
            isEditMode={false}
            closeModal={props.closeModal}
            disableIntegrationType={false}
            optionsForSelect={optionsForSelect}
            submitAction={props.createOrUpdateCarrierIntegrationOverrideRequest}
          />
        );
        const title = G.getAddTitle(['titles:carrier-integration-override', 'Carrier Integration Override']);
        const modal = {
          p: '0px',
          component,
          options: {
            title,
            width: 'auto',
            height: 'auto',
            maxHeight: '90vh',
            overflowY: 'auto',
            overflowX: 'hidden',
          },
        };

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_INTEGRATION_DOCUMENT_TYPE_MAPPING_CONFIG)) {
        const component = (
          <DocumentTypeMappingForm
            isEditMode={false}
            closeModal={props.closeModal}
            submitAction={props.createOrUpdateDocumentTypeMappingRequest}
            documentTypeConfigOptions={R.path(['configOptions', GC.COMMUNICATION_DOCUMENT_TYPE], props)}
          />
        );
        const modal = {
          p: 15,
          component,
          options: {
            title: G.getAddTitle(['titles:document-type-mapping', 'Document Type Mapping']),
          },
        };

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_INTEGRATION_DOCUMENT_HUB_CONFIG)) {
        const optionsForSelect = {
          telReferenceTypes: R.pathOr([], ['configOptions', 'telReferenceTypes'], props),
          [GC.COMMUNICATION_DOCUMENT_TYPE]: R.path(['configOptions', GC.COMMUNICATION_DOCUMENT_TYPE], props),
        };
        const component = (
          <DocumentHubConfigForm
            isEditMode={false}
            closeModal={props.closeModal}
            optionsForSelect={optionsForSelect}
            submitAction={props.createOrUpdateDocumentHubConfigRequest}
          />
        );
        const modal = {
          p: 15,
          component,
          options: {
            title: G.getAddTitle(['titles:document-hub', 'Document Hub']),
          },
        };

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_INTEGRATION_ACCOUNTING_CONFIG)) {
        const component = (
          <AccountingForm
            isEditMode={false}
            closeModal={props.closeModal}
            submitAction={props.createOrUpdateAccountingConfigRequest}
          />
        );
        const modal = {
          p: 15,
          component,
          options: {
            title: G.getAddTitle(['titles:accounting', 'Accounting']),
          },
        };

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_CARRIER_PICKUP_REQUESTER_CONFIG)) {
        const component = (
          <CarrierPickupRequesterForm
            isEditMode={false}
            closeModal={props.closeModal}
            submitAction={props.createOrUpdateCarrierPickupRequesterRequest}
          />
        );
        const modal = {
          p: 15,
          component,
          options: {
            width: 870,
            title: G.getAddTitle(['titles:carrier-pickup-requester', 'Carrier Pickup Requester']),
          },
        };

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_CARRIER_EDI_LOADER_CONFIG)) {
        const optionsForSelect = {
          carrierOptions: R.pathOr([], ['configOptions', 'carriers'], props),
          telReferenceTypes: R.pathOr([], ['configOptions', 'telReferenceTypes'], props),
          [GC.COMMUNICATION_DOCUMENT_TYPE]: R.pathOr([], ['configOptions', GC.COMMUNICATION_DOCUMENT_TYPE], props),
        };
        const handlers = {
          handleDisableFolder: () => R.not(props.isSuperAdmin),
        };
        const component = (
          <CarrierEdiLoaderConfigForm
            isEditMode={false}
            handlers={handlers}
            closeModal={props.closeModal}
            optionsForSelect={optionsForSelect}
            submitAction={props.createOrUpdateCarrierEdiLoaderConfigRequest}
          />
        );
        const modal = {
          p: 15,
          component,
          options: {
            width: 900,
            title: G.getAddTitle(['titles:carrier-edi-loader-config', 'Carrier EDI Loader Config']),
          },
        };

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_CARRIER_EDI_EXPORTER_CONFIG)) {
        const optionsForSelect = {
          carrierOptions: R.pathOr([], ['configOptions', 'carriers'], props),
          telReferenceTypes: R.pathOr([], ['configOptions', 'telReferenceTypes'], props),
        };
        const handlers = {
          handleDisableFolder: () => R.not(props.isSuperAdmin),
        };
        const component = (
          <CarrierEdiExporterConfigForm
            isEditMode={false}
            handlers={handlers}
            closeModal={props.closeModal}
            optionsForSelect={optionsForSelect}
            submitAction={props.createOrUpdateCarrierEdiExporterConfigRequest}
          />
        );
        const modal = {
          p: 15,
          component,
          options: {
            width: 900,
            title: G.getAddTitle(['titles:carrier-edi-exporter-config', 'Carrier EDI Exporter Config']),
          },
        };

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_VIN_LOOKUP_CONFIG)) {
        const component = (
          <VinLookupForm
            isEditMode={false}
            closeModal={props.closeModal}
            submitAction={props.createOrUpdateVinLookupConfigRequest}
          />
        );

        const title = G.getAddTitle(['titles:vin-lookup-config', 'Vin Lookup Config']);
        const modal = getModalWithComponent(component, title, 300);

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_INTEGRATION_TRAILER_TRACKING_CONFIG)) {
        const component = (
          <TrailerTrackingIntegrationForm
            closeModal={props.closeModal}
            submitAction={props.createOrUpdateTrailerTrackingIntegrationConfigRequest}
          />
        );

        const title = G.getAddTitle(['titles:trailer-tracking-integration', 'Trailer Tracking Integration']);
        const modal = getModalWithComponent(component, title, 300);

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_LOS_PRICING_PLAN_CONFIG)) {
        const { openModal, createOrUpdateLosPricingPlanConfigRequest } = props;

        const component = (
          <LosPricingPlanForm
            isEditMode={false}
            submitAction={createOrUpdateLosPricingPlanConfigRequest}
          />
        );

        const modal = {
          p: 15,
          component,
          options: {
            title: G.getAddTitle(['titles:los-pricing-plan', 'LOS Pricing Plan']),
          },
        };

        openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_TERMINAL_INTEGRATION_CONFIG)) {
        const { openModal, createOrUpdateTerminalIntegrationConfigRequest } = props;

        const component = (
          <TerminalIntegrationForm
            isEditMode={false}
            submitAction={createOrUpdateTerminalIntegrationConfigRequest}
          />
        );

        const modal = {
          p: 15,
          component,
          options: {
            height: 'auto',
            overflow: 'auto',
            maxHeight: '90vh',
            title: G.getAddTitle(['titles:terminal-management', 'Terminal Management']),
          },
        };

        return openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_CROSS_BORDER_INTEGRATION_CONFIG)) {
        const component = (
          <CrossBorderIntegrationConfigForm
            isEditMode={false}
            closeModal={props.closeModal}
            submitAction={props.createOrUpdateCrossBorderIntegrationConfigRequest}
          />
        );

        const title = G.getAddTitle(['titles:cross-border-integration-config', 'Cross Border Integration Config']);
        const modal = getModalWithComponent(component, title, 300);

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_TRUCK_TYPE_MAPPING_CONFIG)) {
        const component = (
          <TruckTypeMappingForm
            isEditMode={false}
            closeModal={props.closeModal}
            submitAction={props.createOrUpdateTruckTypeMappingRequest}
            truckTypeConfigOptions={R.pathOr([], ['configOptions', GC.TRUCK_TRUCK_TYPE], props)}
          />
        );

        const title = G.getAddTitle(['titles:truck-type-mapping', 'Truck Type Mapping']);
        const modal = getModalWithComponent(component, title, 300);

        return props.openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_TRAILER_TYPE_MAPPING_CONFIG)) {
        const component = (
          <TrailerMappingForm
            isEditMode={false}
            closeModal={props.closeModal}
            submitAction={props.createOrUpdateTrailerTypeMappingRequest}
            trailerTypeConfigOptions={R.pathOr([], ['configOptions', GC.TRAILER_TRAILER_TYPE], props)}
          />
        );

        const title = G.getAddTitle(['titles:trailer-type-mapping', 'Trailer Type Mapping']);
        const modal = getModalWithComponent(component, title, 300);

        return props.openModal(modal);
      }
    },
    handleEditFuelCard: (props: Object) => (initialValues: Object) => {
      const component = (
        <FuelCardFormComponent
          isEditMode={true}
          disableFuelCardType={true}
          closeModal={props.closeModal}
          initialValues={initialValues}
          submitAction={props.updateFuelCardRequest}
        />
      );

      const modal = getModalWithComponent(component, G.getWindowLocale('titles:edit-fuel-card', 'Edit Fuel Card'), 300);

      props.openModal(modal);
    },
    handleEditEdiConfigs: (props: Object) => (initialValues: Object) => {
      const handlers = {
        handleDisableFolder: () => R.not(props.isSuperAdmin),
      };

      const component = (
        <EdiConfigsForm
          handlers={handlers}
          closeModal={props.closeModal}
          initialValues={initialValues}
          submitAction={props.updateEdiConfigsRequest}
          ediExporters={getEdiExportConfigOptions(props)}
          optionsForSelect={getEdiFormOptions(props.configOptions)}
        />
      );

      const modal = getModalWithComponent(
        component,
        G.getEditTitle(['titles:edi-configs', 'EDI Configs']),
        600,
      );

      props.openModal(modal);
    },
    handleEditEdiExtractorConfig: (props: Object) => (initialValues: Object) => {
      const handlers = {
        handleDisableFolder: () => R.not(props.isSuperAdmin),
      };

      const component = (
        <EdiExtractorConfigForm
          isEditMode={true}
          handlers={handlers}
          closeModal={props.closeModal}
          initialValues={initialValues}
          submitAction={props.updateEdiExtractorConfigRequest}
          optionsForSelect={getEdiFormOptions(props.configOptions)}
        />
      );

      const modal = getModalWithComponent(
        component,
        G.getWindowLocale('titles:edit-edi-extractor-config', 'Edit EDI Extractor Config'),
        600,
      );

      props.openModal(modal);
    },
    handleEditEdiExporterConfig: (props: Object) => (initialValues: Object) => {
      const component = (
        <EdiExporterConfigForm
          closeModal={props.closeModal}
          submitAction={props.updateEdiExporterConfigRequest}
          optionsForSelect={getEdiFormOptions(props.configOptions)}
          initialValues={R.map(G.fromNullToEmptyString, initialValues)}
        />
      );
      const modal = getModalWithComponent(
        component,
        G.getWindowLocale('titles:edit-edi-exporter-config', 'Edit EDI Exporter Config'),
        '900px',
        'unset',
      );

      props.openModal(modal);
    },
    handleEditPublicCLOConfig: (props: Object) => (initialValues: Object) => {
      const {
        openModal,
        closeModal,
        isSuperAdmin,
        configOptions,
        createOrUpdatePublicCLOConfigRequest,
      } = props;

      const optionsForSelect = {
        cloReferenceTypes: G.addEmptyOptionToDropDown(R.pathOr([], ['cloReferenceTypes'], configOptions)),
      };

      const component = (
        <PublicCLOForm
          isEditMode={true}
          closeModal={closeModal}
          isSuperAdmin={isSuperAdmin}
          initialValues={initialValues}
          optionsForSelect={optionsForSelect}
          submitAction={createOrUpdatePublicCLOConfigRequest}
        />
      );

      const modal = getModalWithComponent(
        component,
        G.getEditTitle(['titles:public-api-order-config', 'Public API Order Config']),
        300,
      );

      openModal(modal);
    },
    handleEditIntegrationConfig: (props: Object) => (initialValues: Object) => {
      const component = (
        <CustomerIntegrationConfigsForm
          isEditMode={true}
          closeModal={props.closeModal}
          initialValues={initialValues}
          submitAction={props.updateIntegrationConfigRequest}
          optionsForSelect={getEdiFormOptions(props.configOptions)}
        />
      );

      const modal = getModalWithComponent(
        component,
        G.getEditTitle(['titles:customer-integration-configs', 'Customer Integration Configs']),
        'max-content',
      );

      props.openModal(modal);
    },
    handleEditCarrierRateIntegration: (props: Object) => (initialValues: Object) => {
      const optionsForSelect = {
        carrierSequences: R.path(['configOptions', 'carrierSequences'], props),
        [GC.COMMUNICATION_DOCUMENT_TYPE]: R.path(['configOptions', GC.COMMUNICATION_DOCUMENT_TYPE], props),
        availableCarrierPickupRequesterList: G.addEmptyOptionToDropDown(
          R.values(R.path(['configOptions', 'availableCarrierPickupRequesterList'], props)),
        ),
      };
      const component = (
        <CarrierIntegrationFormComponent
          isEditMode={true}
          disableIntegrationType={true}
          closeModal={props.closeModal}
          initialValues={initialValues}
          optionsForSelect={optionsForSelect}
          submitAction={props.createOrUpdateCarrierRateIntegrationRequest}
        />
      );
      const title = G.getEditTitle(['titles:carrier-integration', 'Carrier Integration']);
      const modal = {
        p: 15,
        component,
        options: {
          title,
          width: 'auto',
          height: 'auto',
          maxHeight: '90vh',
          overflow: 'hidden',
        },
      };
      props.openModal(modal);
    },
    handleEditUserMonitoringIntegration: (props: Object) => (initialValues: Object) => {
      const component = (
        <UserMonitoringFormComponent
          isEditMode={true}
          disableIntegrationType={true}
          closeModal={props.closeModal}
          initialValues={initialValues}
          submitAction={props.createOrUpdateUserMonitoringIntegrationRequest}
        />
      );
      const title = G.getEditTitle(['titles:user-monitoring', 'User Monitoring']);
      const modal = {
        p: 15,
        component,
        options: {
          title,
          width: 'auto',
          height: 'auto',
          maxHeight: '90vh',
          overflow: 'hidden',
        },
      };
      props.openModal(modal);
    },
    handleEditServiceMappers: (props: Object) => (initialValues: Object) => {
      const optionsForSelect = {
        services: R.pathOr([], ['configOptions', GC.GENERAL_SERVICES], props),
        accessorialServiceCodes: R.pathOr([], ['configOptions', 'accessorialServiceCodes'], props),
      };
      const component = (
        <ServiceMappingFormComponent
          isEditMode={true}
          disableIntegrationType={true}
          closeModal={props.closeModal}
          initialValues={initialValues}
          optionsForSelect={optionsForSelect}
          serviceMappingList={props.serviceMappingList}
          submitAction={props.createOrUpdateServiceMappingRequest}
          services={R.pathOr([], ['configOptions', GC.GENERAL_SERVICES], props)}
        />
      );
      const title = G.getEditTitle(['titles:carrier-rate-service-mapping', 'Carrier Rate Service Mapping']);
      const modal = getModalWithComponent(component, title, '300px', 'unset');

      props.openModal(modal);
    },
    handleEditAdvancePayment: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, createOrUpdateAdvancePaymentRequest } = props;
      const component = (
        <AdvancePaymentForm
          editMode={true}
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateAdvancePaymentRequest}
        />
      );
      const title = G.getEditTitle(['titles:advance-payment', 'Advance Payment']);
      const modal = {
        p: 15,
        component,
        options: {
          title,
          width: 660,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
        },
      };
      openModal(modal);
    },
    handleEditLoadBoardIntegrationConfig: (props: Object) => (initialValues: Object) => {
      const optionsForSelect = {
        transportationModeOptions: R.pathOr([], ['configOptions', GC.GENERAL_MODE_TRANSPORTATION], props),
      };

      const component = (
        <LoadBoardIntegrationConfigForm
          isEditMode={true}
          closeModal={props.closeModal}
          optionsForSelect={optionsForSelect}
          loadBoardConfigList={props.loadBoardConfigList}
          submitAction={props.createOrUpdateLoadBoardIntegrationConfigRequest}
          initialValues={{
            ...initialValues,
            ...R.propOr({}, 'data', initialValues),
          }}
        />
      );

      const title = G.getEditTitle(['titles:external-load-boards', 'External Load Board']);
      const modal = getModalWithComponent(component, title, 300);

      props.openModal(modal);
    },
    handleEditTollChargeConfig: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, createOrUpdateTollChargesConfigRequest } = props;

      const component = (
        <TollChargeForm
          isEditMode={true}
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateTollChargesConfigRequest}
        />
      );

      const title = G.getEditTitle(['titles:toll-charge', 'Toll Charge']);
      const modal = getModalWithComponent(component, title, 300);

      openModal(modal);
    },
    handleEditDispatchIntegrationConfig: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, createOrUpdateDispatchIntegrationConfigRequest } = props;

      const optionsForSelect = {
        trackingStatusCodeList: G.addEmptyOptionToDropDown(
          R.pathOr([], ['configOptions', 'trackingStatusCodeList'], props),
        ),
      };
      const component = (
        <DispatchIntegrationForm
          isEditMode={true}
          closeModal={closeModal}
          initialValues={initialValues}
          optionsForSelect={optionsForSelect}
          submitAction={createOrUpdateDispatchIntegrationConfigRequest}
        />
      );
      const title = G.getEditTitle(['titles:tracking-management', 'Tracking Management']);
      const modal = {
        p: 15,
        component,
        options: {
          title,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
        },
      };

      openModal(modal);
    },
    handleEditStatusCodeMappingConfig: (props: Object) => (entity: Object, mappingType: string = 'inboundMapping') => {
      const { openModal, closeModal, createOrUpdateStatusCodeMappingRequest } = props;

      const component = (
        <StatusCodeMappingForm
          isEditMode={true}
          initialValues={entity}
          closeModal={closeModal}
          mappingType={mappingType}
          submitAction={createOrUpdateStatusCodeMappingRequest}
          allStatusCodeList={R.pathOr([], ['configOptions', 'allStatusCodeList'], props)}
        />
      );

      const titleArray = G.ifElse(
        R.equals(mappingType, 'outboundMapping'),
        ['titles:status-code-outbound-mapping', 'Status Code Outbound Mapping'],
        ['titles:status-code-inbound-mapping', 'Status Code Inbound Mapping'],
      );

      const title = G.getEditTitle(titleArray);
      const modal = getModalWithComponent(component, title, 300, 'unset');

      openModal(modal);
    },
    handleEditStatusReasonCodeMappingConfig: (props: Object) => (entity: Object, mappingType: string = 'inboundMapping') => {
      const { openModal, createOrUpdateStatusReasonCodeMappingRequest } = props;

      const component = (
        <StatusReasonCodeMappingForm
          isEditMode={true}
          initialValues={entity}
          mappingType={mappingType}
          submitAction={createOrUpdateStatusReasonCodeMappingRequest}
          statusReasonCodeOptions={R.pathOr([], ['configOptions', GC.COMMUNICATION_REASON_CODE], props)}
        />
      );

      const titleArray = G.ifElse(
        R.equals(mappingType, 'outboundMapping'),
        ['titles:status-reason-outbound-mapping', 'Status Reason Outbound Mapping'],
        ['titles:status-reason-inbound-mapping', 'Status Reason Inbound Mapping'],
      );

      const title = G.getEditTitle(titleArray);
      const modal = getModalWithComponent(component, title, 300, 'unset');

      openModal(modal);
    },
    handleEditServiceTypeMapping: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, configOptions, createOrUpdateServiceTypeMappingRequest } = props;

      const component = (
        <ServiceTypeMappingForm
          isEditMode={true}
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateServiceTypeMappingRequest}
          serviceTypeConfigOptions={R.pathOr([], [GC.GENERAL_TRANSPORTATION_SERVICE_TYPE], configOptions)}
        />
      );

      const title = G.getEditTitle(['titles:service-type-mapping', 'Service Type Mapping']);
      const modal = getModalWithComponent(component, title, 300, 'unset');

      openModal(modal);
    },
    handleEditCarrierSynchronization: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, createOrUpdateCarrierSynchronizationConfigRequest } = props;

      const component = (
        <CarrierSynchronizationForm
          isEditMode={true}
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateCarrierSynchronizationConfigRequest}
        />
      );
      const title = G.getEditTitle(['titles:carrier-synchronization', 'Carrier Synchronization']);
      const modal = getModalWithComponent(component, title, 300);

      openModal(modal);
    },
    handleEditCarrierIntegrationOverride: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, configOptions, createOrUpdateCarrierIntegrationOverrideRequest } = props;

      const optionsForSelect = {
        carrierSequences: R.path(['configOptions', 'carrierSequences'], props),
        [GC.COMMUNICATION_DOCUMENT_TYPE]: G.getPropFromObject(GC.COMMUNICATION_DOCUMENT_TYPE, configOptions),
        customerBranchList: getCustomerBranchListOptions(R.pathOr([], ['customerBranchList'], configOptions)),
        availableCarrierPickupRequesterList: G.addEmptyOptionToDropDown(
          R.values(R.path(['configOptions', 'availableCarrierPickupRequesterList'], props)),
        ),
      };
      const component = (
        <CarrierIntegrationFormComponent
          override={true}
          isEditMode={true}
          closeModal={closeModal}
          disableIntegrationType={true}
          initialValues={initialValues}
          optionsForSelect={optionsForSelect}
          submitAction={createOrUpdateCarrierIntegrationOverrideRequest}
        />
      );
      const title = G.getEditTitle(['titles:carrier-integration', 'Carrier Integration']);
      const modal = {
        p: '0px',
        component,
        options: {
          title,
          width: 'auto',
          height: 'auto',
          maxHeight: '90vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        },
      };

      openModal(modal);
    },
    handleEditDocumentTypeMapping: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, createOrUpdateDocumentTypeMappingRequest } = props;

      const component = (
        <DocumentTypeMappingForm
          isEditMode={true}
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateDocumentTypeMappingRequest}
          documentTypeConfigOptions={R.path(['configOptions', GC.COMMUNICATION_DOCUMENT_TYPE], props)}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          title: G.getEditTitle(['titles:document-type-mapping', 'Document Type Mapping']),
        },
      };

      openModal(modal);
    },
    handleEditDocumentHubConfig: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, createOrUpdateDocumentHubConfigRequest } = props;

      const optionsForSelect = {
        telReferenceTypes: R.pathOr([], ['configOptions', 'telReferenceTypes'], props),
        [GC.COMMUNICATION_DOCUMENT_TYPE]: R.path(['configOptions', GC.COMMUNICATION_DOCUMENT_TYPE], props),
      };
      const component = (
        <DocumentHubConfigForm
          isEditMode={true}
          closeModal={closeModal}
          initialValues={initialValues}
          optionsForSelect={optionsForSelect}
          submitAction={createOrUpdateDocumentHubConfigRequest}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          title: G.getEditTitle(['titles:document-hub', 'Document Hub']),
        },
      };

      openModal(modal);
    },
    handleEditAccountingConfig: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, createOrUpdateAccountingConfigRequest } = props;

      const component = (
        <AccountingForm
          isEditMode={true}
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateAccountingConfigRequest}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          title: G.getEditTitle(['titles:accounting', 'Accounting']),
        },
      };

      openModal(modal);
    },
    handleEditCarrierPickupRequester: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, createOrUpdateCarrierPickupRequesterRequest } = props;

      const component = (
        <CarrierPickupRequesterForm
          isEditMode={true}
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateCarrierPickupRequesterRequest}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 870,
          title: G.getEditTitle(['titles:carrier-pickup-requester', 'Carrier Pickup Requester']),
        },
      };

      openModal(modal);
    },
    handleEditCarrierEdiLoaderConfig: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, isSuperAdmin, createOrUpdateCarrierEdiLoaderConfigRequest } = props;

      const optionsForSelect = {
        telReferenceTypes: R.pathOr([], ['configOptions', 'telReferenceTypes'], props),
        [GC.COMMUNICATION_DOCUMENT_TYPE]: R.pathOr([], ['configOptions', GC.COMMUNICATION_DOCUMENT_TYPE], props),
      };
      const handlers = {
        handleDisableFolder: () => R.not(isSuperAdmin),
      };
      const component = (
        <CarrierEdiLoaderConfigForm
          isEditMode={true}
          handlers={handlers}
          closeModal={closeModal}
          initialValues={initialValues}
          optionsForSelect={optionsForSelect}
          submitAction={createOrUpdateCarrierEdiLoaderConfigRequest}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 900,
          title: G.getEditTitle(['titles:carrier-edi-loader-config', 'Carrier EDI Loader Config']),
        },
      };

      return openModal(modal);
    },
    handleEditCarrierEdiExporterConfig: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, isSuperAdmin, createOrUpdateCarrierEdiExporterConfigRequest } = props;

      const optionsForSelect = {
        telReferenceTypes: R.pathOr([], ['configOptions', 'telReferenceTypes'], props),
      };
      const handlers = {
        handleDisableFolder: () => R.not(isSuperAdmin),
      };
      const component = (
        <CarrierEdiExporterConfigForm
          isEditMode={true}
          handlers={handlers}
          closeModal={closeModal}
          initialValues={initialValues}
          optionsForSelect={optionsForSelect}
          submitAction={createOrUpdateCarrierEdiExporterConfigRequest}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 900,
          title: G.getEditTitle(['titles:carrier-edi-exporter-config', 'Carrier EDI Exporter Config']),
        },
      };

      return openModal(modal);
    },
    handleEditVinLookupConfig: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, createOrUpdateVinLookupConfigRequest } = props;

      const component = (
        <VinLookupForm
          isEditMode={true}
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateVinLookupConfigRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 300,
          title: G.getEditTitle(['titles:vin-lookup-config', 'Vin Lookup Config']),
        },
      };

      return openModal(modal);
    },
    handleEditFactoringIntegrationConfig: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, createOrUpdateFactoringIntegrationConfigRequest } = props;

      const cloReferenceTypes = R.pathOr([], ['configOptions', 'cloReferenceTypes'], props);

      const customerInvoiceReferenceTypes = R.pathOr([], ['configOptions', 'customerInvoiceReferenceTypes'], props);

      const component = (
        <FactoringIntegrationConfigForm
          editMode={true}
          closeModal={closeModal}
          initialValues={initialValues}
          optionsForSelect={{ cloReferenceTypes, customerInvoiceReferenceTypes }}
          submitAction={createOrUpdateFactoringIntegrationConfigRequest}
        />
      );
      const modal = {
        component,
        p: '15px 0',
        options: {
          title: G.getEditTitle(['titles:factoring-integration-config', 'Factoring Integration Config']),
        },
      };

      return openModal(modal);
    },
    handleEditEquipmentMappingConfig: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, configOptions, createOrUpdateEquipmentMappingConfigRequest } = props;

      const equipmentOptions = R.pathOr([], [GC.GENERAL_EQUIPMENTS], configOptions);
      const component = (
        <EquipmentMappingConfigForm
          editMode={true}
          closeModal={closeModal}
          initialValues={initialValues}
          equipmentOptions={equipmentOptions}
          submitAction={createOrUpdateEquipmentMappingConfigRequest}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          title: G.getAddTitle(['titles:equipment-mapping', 'Equipment Mapping']),
        },
      };

      return openModal(modal);
    },
    handleEditTrailerTrackingIntegration: (props: Object) => (initialValues: Object) => {
      const {
        openModal,
        closeModal,
        createOrUpdateTrailerTrackingIntegrationConfigRequest,
      } = props;

      const component = (
        <TrailerTrackingIntegrationForm
          editMode={true}
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateTrailerTrackingIntegrationConfigRequest}
        />
      );

      const title = G.getEditTitle(['titles:trailer-tracking-integration', 'Trailer Tracking Integration']);
      const modal = getModalWithComponent(component, title, 300);

      openModal(modal);
    },
    handleEditLosSubscriptionConfig: (props: Object) => (initialValues: Object) => {
      const { openModal, updateLosSubscriptionConfigRequest } = props;

      const branchGuid = G.getAmousCurrentUserBranchGuidFromWindow();

      const sequenceOptions = G.addEmptyOptionToDropDown(R.pathOr([], ['configOptions', 'branchSequences'], props));

      const component = (
        <LosSubscriptionForm
          isEditMode={true}
          branchGuid={branchGuid}
          initialValues={initialValues}
          sequenceOptions={sequenceOptions}
          submitAction={updateLosSubscriptionConfigRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: G.getEditTitle(['titles:los-subscription', 'LOS Subscription']),
        },
      };

      openModal(modal);
    },
    handleEditLosPricingPlanConfig: (props: Object) => (initialValues: Object) => {
      const { openModal, createOrUpdateLosPricingPlanConfigRequest } = props;

      const component = (
        <LosPricingPlanForm
          isEditMode={true}
          initialValues={initialValues}
          submitAction={createOrUpdateLosPricingPlanConfigRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: G.getEditTitle(['titles:los-pricing-plan', 'LOS Pricing Plan']),
        },
      };

      openModal(modal);
    },
    handleEditTerminalIntegrationConfig: (props: Object) => (initialValues: Object) => {
      const { openModal, createOrUpdateTerminalIntegrationConfigRequest } = props;

      const component = (
        <TerminalIntegrationForm
          isEditMode={true}
          initialValues={initialValues}
          submitAction={createOrUpdateTerminalIntegrationConfigRequest}
        />
      );

      const title = G.getEditTitle(['titles:terminal-management', 'Terminal Management']);

      const modal = {
        p: 15,
        component,
        options: {
          title,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
        },
      };

      openModal(modal);
    },
    handleEditCrossBorderIntegrationConfig: (props: Object) => (initialValues: Object) => {
      const {
        openModal,
        closeModal,
        createOrUpdateCrossBorderIntegrationConfigRequest,
      } = props;


      const component = (
        <CrossBorderIntegrationConfigForm
          editMode={true}
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateCrossBorderIntegrationConfigRequest}
        />
      );

      const title = G.getEditTitle(['titles:cross-border-integration-config', 'Cross Border Integration Config']);
      const modal = getModalWithComponent(component, title, 300);

      openModal(modal);
    },
    handleEditTruckTypeMappingConfig: (props: Object) => (initialValues: Object) => {
      const {
        openModal,
        closeModal,
        createOrUpdateTruckTypeMappingRequest,
      } = props;


      const component = (
        <TruckTypeMappingForm
          editMode={true}
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateTruckTypeMappingRequest}
          truckTypeConfigOptions={R.pathOr([], ['configOptions', GC.TRUCK_TRUCK_TYPE], props)}
        />
      );

      const title = G.getEditTitle(['titles:truck-type-mapping', 'Truck Type Mapping']);
      const modal = getModalWithComponent(component, title, 300);

      openModal(modal);
    },
    handleEditTrailerTypeMappingConfig: (props: Object) => (initialValues: Object) => {
      const {
        openModal,
        closeModal,
        createOrUpdateTrailerTypeMappingRequest,
      } = props;


      const component = (
        <TrailerMappingForm
          editMode={true}
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateTrailerTypeMappingRequest}
          trailerTypeConfigOptions={R.pathOr([], ['configOptions', GC.TRAILER_TRAILER_TYPE], props)}
        />
      );

      const title = G.getEditTitle(['titles:trailer-type-mapping', 'Trailer Type Mapping']);
      const modal = getModalWithComponent(component, title, 300);

      openModal(modal);
    },
    handleRemoveFuelCard: ({ removeFuelCardRequest }: Object) => (guid: string) => removeFuelCardRequest(guid),
    handleRemoveEdiConfigs: ({ removeEdiConfigsRequest }: Object) => (guid: string) => removeEdiConfigsRequest(guid),
    handleRemoveEdiExtractorConfig: ({ removeEdiExtractorConfigRequest }: Object) => (guid: string) =>
      removeEdiExtractorConfigRequest(guid),
    handleRemoveEdiExporterConfig: ({ removeEdiExporterConfigRequest }: Object) => (guid: string) =>
      removeEdiExporterConfigRequest(guid),
    handleRemoveIntegrationConfig: ({ removeIntegrationConfigRequest }: Object) => (guid: string) =>
      removeIntegrationConfigRequest(guid),
    handleRemoveCarrierRateIntegration: ({ removeCarrierRateIntegrationRequest }: Object) => (guid: string) =>
      removeCarrierRateIntegrationRequest(guid),
    handleRemoveUserMonitoringIntegration: ({ removeUserMonitoringIntegrationRequest }: Object) => (guid: string) =>
      removeUserMonitoringIntegrationRequest(guid),
    handleRemoveServiceMapping: ({ removeServiceMappingRequest }: Object) => (guid: string) =>
      removeServiceMappingRequest(guid),
    handleRemoveAdvancePayment: ({ removeAdvancePaymentRequest }: Object) => (guid: string) =>
      removeAdvancePaymentRequest(guid),
    handleRemoveLoadBoardIntegrationConfig: (props: Object) => (guid: string) => {
      const { loadBoardConfigList, removeLoadBoardIntegrationConfigRequest } = props;

      const integrationType = R.compose(
        R.path([GC.FIELD_INTEGRATION_TYPE]),
        R.find(R.propEq(guid, GC.FIELD_GUID)),
      )(loadBoardConfigList);

      removeLoadBoardIntegrationConfigRequest({ guid, integrationType });
    },
    handleRemoveTollChargeConfig: ({ removeTollChargesConfigRequest }: Object) => (guid: string) =>
      removeTollChargesConfigRequest(guid),
    handleRemoveDispatchIntegrationConfig: ({ removeDispatchIntegrationConfigRequest }: Object) => (guid: string) =>
      removeDispatchIntegrationConfigRequest(guid),
    handleRemoveStatusCodeMappingConfig: ({ removeStatusCodeMappingRequest }: Object) => (guid: string) =>
      removeStatusCodeMappingRequest({ guid }),
    handleRemoveStatusCodeOutboundMappingConfig: ({ removeStatusCodeMappingRequest }: Object) => (guid: string) =>
      removeStatusCodeMappingRequest({ guid, mappingType: 'outboundMapping' }),
    handleRemoveStatusReasonCodeMappingConfig: ({ removeStatusReasonCodeMappingRequest }: Object) => (guid: string) =>
      removeStatusReasonCodeMappingRequest({ guid }),
    handleRemoveStatusReasonCodeOutboundMappingConfig: ({ removeStatusReasonCodeMappingRequest }: Object) =>
      (guid: string) => removeStatusReasonCodeMappingRequest({ guid, mappingType: 'outboundMapping' }),
    handleRemovePublicCLOConfig: ({ removePublicCLOConfigRequest }: Object) => (guid: string) =>
      removePublicCLOConfigRequest(guid),
    handleRemoveServiceTypeMapping: ({ removeServiceTypeMappingRequest }: Object) => (guid: string) =>
      removeServiceTypeMappingRequest(guid),
    handleRemoveCarrierSynchronization: ({ removeCarrierSynchronizationConfigRequest }: Object) => (guid: string) =>
      removeCarrierSynchronizationConfigRequest(guid),
    handleRemoveCarrierIntegrationOverride: ({ removeCarrierIntegrationOverrideRequest }: Object) => (guid: string) =>
      removeCarrierIntegrationOverrideRequest(guid),
    handleRemoveDocumentTypeMapping: ({ removeDocumentTypeMappingRequest }: Object) => (guid: string) =>
      removeDocumentTypeMappingRequest(guid),
    handleRemoveDocumentHubConfig: ({ removeDocumentHubConfigRequest }: Object) => (guid: string) =>
      removeDocumentHubConfigRequest(guid),
    handleRemoveAccountingConfig: ({ removeAccountingConfigRequest }: Object) => (guid: string) =>
      removeAccountingConfigRequest(guid),
    handleRemoveCarrierPickupRequester: ({ removeCarrierPickupRequesterRequest }: Object) => (guid: string) =>
      removeCarrierPickupRequesterRequest(guid),
    handleRemoveCarrierEdiLoaderConfig: ({ removeCarrierEdiLoaderConfigRequest }: Object) => (guid: string) =>
      removeCarrierEdiLoaderConfigRequest(guid),
    handleRemoveCarrierEdiExporterConfig: ({ removeCarrierEdiExporterConfigRequest }: Object) => (guid: string) =>
      removeCarrierEdiExporterConfigRequest(guid),
    handleRemoveVinLookupConfig: ({ removeVinLookupConfigRequest }: Object) => (guid: string) =>
      removeVinLookupConfigRequest(guid),
    handleRemoveFactoringIntegrationConfig: ({ removeFactoringIntegrationConfigRequest }: Object) => (guid: string) =>
      removeFactoringIntegrationConfigRequest(guid),
    handleRemoveEquipmentMappingConfig: ({ removeEquipmentMappingConfigRequest }: Object) => (guid: string) =>
      removeEquipmentMappingConfigRequest(guid),
    handleRemoveTrailerTrackingIntegrationConfig: ({ removeTrailerTrackingIntegrationConfigRequest }: Object) =>
      (guid: string) => removeTrailerTrackingIntegrationConfigRequest(guid),
    handleRemoveTerminalIntegrationConfig: ({ removeTerminalIntegrationConfigRequest }: Object) => (guid: string) =>
      removeTerminalIntegrationConfigRequest(guid),
    handleRemoveLosPricingPlanConfig: (props: Object) => (guid: string) => {
      const {
        losPricingPlanList,
        removeLosPricingPlanConfigRequest,
        removeLosPricingPlanConfigWithSubstitutionRequest,
      } = props;

      const availablePricingPlansOptions = G.mapNameGuidObjectPropsToLabelValueObject(R.reject(
        R.propEq(guid, GC.FIELD_GUID), losPricingPlanList),
      );

      const hanldeRemoveLosPricingPlanConfigWithSubstitution = ({ guid, substitutionGuid }: Object) => {
        removeLosPricingPlanConfigWithSubstitutionRequest({ guid, substitutionGuid });
      };

      removeLosPricingPlanConfigRequest({
        guid,
        availablePricingPlansOptions,
        hanldeRemoveLosPricingPlanConfigWithSubstitution,
      });
    },
    handleRemoveCrossBorderIntegrationConfig: ({ removeCrossBorderIntegrationConfigRequest }: Object) =>
      (guid: string) => removeCrossBorderIntegrationConfigRequest(guid),
    handleRemoveTruckTypeMappingConfig: ({ removeTruckTypeMappingRequest }: Object) =>
      (guid: string) => removeTruckTypeMappingRequest(guid),
    handleRemoveTrailerTypeMappingConfig: ({ removeTrailerTypeMappingRequest }: Object) =>
      (guid: string) => removeTrailerTypeMappingRequest(guid),
  }),
  pure,
);

const getConfigOptions = (props: Object) => {
  const { configOptions, dispatchIntegrationList } = props;

  const trackingSystemOptions = G.mapNameGuidToLabelValue(R.values(dispatchIntegrationList));

  return R.assoc('trackingSystemOptions', trackingSystemOptions, configOptions);
};

const ConfigPageComponent = (props: Object) => (
  <ConfigComponent
    {...props}
    useTabs={true}
    pageTitle='titles:integration'
    configOptions={getConfigOptions(props)}
    groupSettings={GC.INTEGRATION_CONFIG_GROUP}
  />
);

const mapStateToProps = (state: Object) => ({
  loader: makeSelectLoader()(state),
  fuelCardList: makeSelectFuelCardList()(state),
  ediConfigsList: makeSelectEdiConfigsList()(state),
  tollChargeList: makeSelectTollChargeList()(state),
  publicCLOConfig: makeSelectPublicCLOConfig()(state),
  carrierSequences: makeSelectCarrierSequences()(state),
  currentBranchType: makeSelectCurrentBranchType()(state),
  isSuperAdmin: makeSelectIsCurrentUserSuperAdmin()(state),
  advancePaymentList: makeSelectAdvancePaymentList()(state),
  losPricingPlanList: makeSelectLosPricingPlanList()(state),
  loadBoardConfigList: makeSelectLoadBoardConfigList()(state),
  vinLookupConfigList: makeSelectVinLookupConfigList()(state),
  serviceMappingFilter: makeSelectServiceMappingFilter()(state),
  accountingConfigList: makeSelectAccountingConfigList()(state),
  truckTypeMappingList: makeSelectTruckTypeMappingList()(state),
  losSubscriptionConfig: makeSelectLosSubscriptionConfig()(state),
  integrationConfigList: makeSelectIntegrationConfigList()(state),
  documentHubConfigList: makeSelectDocumentHubConfigList()(state),
  ediExporterConfigList: makeSelectEdiExporterConfigList()(state),
  serviceMappingList: makeSelectServiceMappingConfigList()(state),
  statusCodeMappingList: makeSelectStatusCodeMappingList()(state),
  ediExtractorConfigList: makeSelectEdiExtractorConfigList()(state),
  serviceTypeMappingList: makeSelectServiceTypeMappingList()(state),
  trailerTypeMappingList: makeSelectTrailerTypeMappingList()(state),
  documentTypeMappingList: makeSelectDocumentTypeMappingList()(state),
  dispatchIntegrationList: makeSelectDispatchIntegrationList()(state),
  terminalIntegrationList: makeSelectTerminalIntegrationList()(state),
  carrierSynchronization: makeSelectCarrierSynchronizationConfig()(state),
  dropdowns: makeSelectConfigDropdowns(state, GC.INTEGRATION_CONFIG_GROUP),
  carrierEdiLoaderConfigList: makeSelectCarrierEdiLoaderConfigList()(state),
  equipmentMappingConfigList: makeSelectEquipmentMappingConfigList()(state),
  configOptions: makeSelectConfigOptions(state, GC.INTEGRATION_CONFIG_GROUP),
  statusReasonCodeMappingList: makeSelectStatusReasonCodeMappingList()(state),
  carrierPickupRequesterList: makeSelectCarrierPickupRequesterListList()(state),
  carrierEdiExporterConfigList: makeSelectCarrierEdiExporterConfigList()(state),
  statusCodeOutboundMappingList: makeSelectStatusCodeOutboundMappingList()(state),
  carrierRateIntegrationList: makeSelectCarrierRateIntegrationConfigList()(state),
  initialValues: makeSelectConfigInitialValues(state, GC.INTEGRATION_CONFIG_GROUP),
  trailerTrackingIntegrationList: makeSelectTrailerTrackingIntegrationList()(state),
  factoringIntegrationConfigList: makeSelectFactoringIntegrationConfigList()(state),
  carrierIntegrationOverrideList: makeSelectCarrierIntegrationOverrideList()(state),
  inheritedValues: makeSelectConfigInheritedValues(state, GC.INTEGRATION_CONFIG_GROUP),
  crossBorderIntegrationConfigList: makeSelectCrossBorderIntegrationConfigList()(state),
  userMonitoringIntegrationList: makeSelectUserMonitoringIntegrationConfigList()(state),
  filteredServiceCodeMappingConfigList: makeSelectFilteredServiceMappingConfigList()(state),
  statusReasonCodeOutboundMappingList: makeSelectStatusReasonCodeOutboundMappingList()(state),
  transportationModeConfigMappingList: makeSelectIntegrationTransportationModeConfigMappingList()(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  updateConfigsRequest,
  updateFuelCardRequest,
  createFuelCardRequest,
  removeFuelCardRequest,
  removeEdiConfigsRequest,
  createEdiConfigsRequest,
  updateEdiConfigsRequest,
  setServiceMappingFilter,
  removeServiceMappingRequest,
  removeAdvancePaymentRequest,
  removeVinLookupConfigRequest,
  removePublicCLOConfigRequest,
  getServiceMappingListRequest,
  removeAccountingConfigRequest,
  removeTruckTypeMappingRequest,
  removeStatusCodeMappingRequest,
  createEdiExporterConfigRequest,
  removeIntegrationConfigRequest,
  updateIntegrationConfigRequest,
  removeTollChargesConfigRequest,
  createIntegrationConfigRequest,
  updateEdiExporterConfigRequest,
  removeEdiExporterConfigRequest,
  removeDocumentHubConfigRequest,
  createServiceMappingListRequest,
  updateEdiExtractorConfigRequest,
  createEdiExtractorConfigRequest,
  removeEdiExtractorConfigRequest,
  removeServiceTypeMappingRequest,
  removeTrailerTypeMappingRequest,
  removeDocumentTypeMappingRequest,
  removeLosPricingPlanConfigRequest,
  updateLosSubscriptionConfigRequest,
  createOrUpdateAdvancePaymentRequest,
  restoreInheritedByConfigTypeRequest,
  createOrUpdateServiceMappingRequest,
  removeCarrierRateIntegrationRequest,
  removeCarrierPickupRequesterRequest,
  removeCarrierEdiLoaderConfigRequest,
  removeEquipmentMappingConfigRequest,
  createOrUpdatePublicCLOConfigRequest,
  createOrUpdateVinLookupConfigRequest,
  removeStatusReasonCodeMappingRequest,
  createOrUpdateAccountingConfigRequest,
  removeCarrierEdiExporterConfigRequest,
  createOrUpdateTruckTypeMappingRequest,
  removeUserMonitoringIntegrationRequest,
  createOrUpdateStatusCodeMappingRequest,
  createOrUpdateTollChargesConfigRequest,
  removeDispatchIntegrationConfigRequest,
  createOrUpdateDocumentHubConfigRequest,
  removeTerminalIntegrationConfigRequest,
  removeCarrierIntegrationOverrideRequest,
  createOrUpdateServiceTypeMappingRequest,
  removeLoadBoardIntegrationConfigRequest,
  removeFactoringIntegrationConfigRequest,
  createOrUpdateTrailerTypeMappingRequest,
  createOrUpdateDocumentTypeMappingRequest,
  createOrUpdateLosPricingPlanConfigRequest,
  removeCrossBorderIntegrationConfigRequest,
  removeCarrierSynchronizationConfigRequest,
  createOrUpdateEquipmentMappingConfigRequest,
  createOrUpdateCarrierPickupRequesterRequest,
  createOrUpdateCarrierRateIntegrationRequest,
  createOrUpdateCarrierEdiLoaderConfigRequest,
  createOrUpdateStatusReasonCodeMappingRequest,
  removeTransportationModeConfigMappingRequest,
  createOrUpdateCarrierEdiExporterConfigRequest,
  removeTrailerTrackingIntegrationConfigRequest,
  createOrUpdateUserMonitoringIntegrationRequest,
  createOrUpdateDispatchIntegrationConfigRequest,
  createOrUpdateTerminalIntegrationConfigRequest,
  createOrUpdateFactoringIntegrationConfigRequest,
  createOrUpdateCarrierIntegrationOverrideRequest,
  createOrUpdateLoadBoardIntegrationConfigRequest,
  setActiveConfigTab: setIntegrationActiveConfigTab,
  removeLosPricingPlanConfigWithSubstitutionRequest,
  createOrUpdateCarrierSynchronizationConfigRequest,
  createOrUpdateCrossBorderIntegrationConfigRequest,
  createOrUpdateTransportationModeConfigMappingRequest,
  createOrUpdateTrailerTrackingIntegrationConfigRequest,
})(enhance(ConfigPageComponent));

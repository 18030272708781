import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// components
import { ItemIcons } from '../../../components/item-icons';
// features
import { withLoadMessageNoteClick } from '../../dispatch-board-new/hocs';
import { MessagesNotesCell } from '../../dispatch-board-new/components/table-cells';
import { getCommonColumnSettings } from '../../dispatch-board-new/settings/table-settings';
// ui
import { Flex } from '../../../ui';
//////////////////////////////////////////////////

export const report = {
  fields: [
    { name: 'LEFT_TABLE_CELL', freezed: true, p: '0px', sequence: 0 },
    { name: GC.FIELD_PRIMARY_REFERENCE_VALUE, sequence: 1 },
    { name: GC.FIELD_BRANCH, sequence: 2 },
    { name: GC.FIELD_STATUS, sequence: 3 },
    { name: GC.FIELD_FIRST_PICKUP, sequence: 4 },
    { name: GC.FIELD_LAST_DROP, sequence: 5 },
    { name: GC.FIELD_LOAD_CARRIER_FLEET, sequence: 6 },
    { name: GC.FIELD_LOAD_EARNINGS, sequence: 7 },
    { name: 'MESSAGES_NOTES_CELL', freezedRight: true, sequence: 13, right: '0' },
  ],
};

export const tableSettings = {
  titleRowHeight: 30,
  tableRowHeight: 65,
  allowEditBtn: false,
  useMainColors: true,
  checkBoxCellWidth: 0,
  allowSelectItems: false,
  maxHeight: 'fit-content',
};

export const IconsCell = ({ data }: Object) => {
  const { hot, isLinked, fastLoad, itemsInfo } = data;

  const hotBorderColor = G.getTheme('colors.light.mainRed');
  const anyHazardous = R.path([GC.FIELD_ANY_HAZARDOUS], itemsInfo);
  const temperatureLow = R.path([GC.FIELD_ITEM_TEMPERATURE_LOW], itemsInfo);
  const temperatureHigh = R.path([GC.FIELD_ITEM_TEMPERATURE_HIGH], itemsInfo);
  const borderLeft = G.ifElse(G.isTrue(hot), '5px solid', null);
  const showFast = G.getAmousConfigByNameFromWindow(GC.UI_SHOW_FAST_LOAD_INDICATOR);

  return (
    <Flex
      p='5px'
      height='100%'
      flexDirection='column'
      justifyContent='center'
      borderLeft={borderLeft}
      borderColor={hotBorderColor}
    >
      {
        G.isOneNotNilOrNotEmpty([anyHazardous, temperatureLow, temperatureHigh]) &&
        <ItemIcons
          anyHazardous={anyHazardous}
          temperatureLow={temperatureLow}
          temperatureHigh={temperatureHigh}
        />
      }
      {
        R.and(fastLoad, showFast) &&
        <div>{G.getWindowLocale('titles:fast', 'Fast', { caseAction: 'upperCase' })}</div>
      }
      {
        isLinked &&
        <div>{G.getWindowLocale('titles:crossdock', 'Crossdock', { caseAction: 'upperCase' })}</div>
      }
    </Flex>
  );
};

const MessagesNotesComponent = withLoadMessageNoteClick((props: Object) => (
  <MessagesNotesCell
    {...props}
    clickHandler={props.handleMessageNoteClick}
    pinnedNoteStyles={{ width: '100%', left: '0' }}
  />
));

export const getColumnSettings = ({
  refresh,
  loadType,
  openModal,
  closeModal,
  configCurrency,
  openFixedPopup,
  closeFixedPopup,
}: Object) => ({
  ...getCommonColumnSettings(loadType, configCurrency, openFixedPopup),
  LEFT_TABLE_CELL: {
    width: 100,
    customComponent: ({ data }: Object) => <IconsCell data={data} />,
  },
  MESSAGES_NOTES_CELL: {
    width: 70,
    customComponent: ({ data }: Object) => {
      const refreshHandlerName = G.isLoadTypeClo(loadType) ? 'toggleOrderDetailsRequest' : 'toggleLoadDetailsSuccess';

      const componentProps = {
        data,
        loadType,
        openModal,
        closeModal,
        openFixedPopup,
        closeFixedPopup,
        hideReferences: true,
        [refreshHandlerName]: refresh,
      };

      return (
        <MessagesNotesComponent {...componentProps} />
      );
    },
  },
});

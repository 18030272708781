import * as R from 'ramda';
import React, { Fragment } from 'react';
import { pure, compose, lifecycle, withHandlers } from 'react-recompose';
// components
import { Table } from '../../../../components/table';
import { TextComponent } from '../../../../components/text';
import { ConfirmComponent } from '../../../../components/confirm';
// features
import { AuthWrapper } from '../../../permission';
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, StyledLink, ActionButton, ZOrderWrapper } from '../../../../ui';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
import { sendRequest, sendRequestWithQSParamsSerializer } from '../../../../utilities/http';
// feature fleet
import { withCreateOrUpdateEquipmentServiceDocument } from '../hocs';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

const tableSettings = {
  maxHeight: 320,
  titleRowHeight: 30,
  tableRowHeight: 30,
  useMainColors: true,
  moreBtnCellWidth: 0,
  allowEditBtn: false,
  checkBoxCellWidth: 0,
  allowSelectAll: false,
  expandableItems: false,
  allowSelectItems: false,
};

export const DocumentActions = (props: Object) => {
  const {
    entity,
    disabled,
    entityType,
    handleDownloadFile,
    handleRemoveDocument,
    handleOpenFilePreview,
    handleCreateOrUpdateEquipmentServiceDocument,
  } = props;

  return (
    <AuthWrapper has={G.ifElse(R.equals(entityType, GC.FIELD_TRUCK), [PC.FLEET_TRUCK_WRITE], [PC.FLEET_TRAILER_WRITE])}>
      <Flex width='100%'>
        {
          G.isFalse(disabled) &&
          <Fragment>
            <Box mx='6px' cursor='pointer' onClick={() => handleRemoveDocument(G.getGuidFromObject(entity))}>
              {I.trash(darkBlueColor)}
            </Box>
            <Box mx='6px' cursor='pointer' onClick={() => handleCreateOrUpdateEquipmentServiceDocument(entity)}>
              {I.pencil(darkBlueColor)}
            </Box>
          </Fragment>
        }
        {
          R.or(
            G.isNotNilAndNotEmpty(G.getPropFromObject(GC.FIELD_FILE_PUBLIC_LINK, entity)),
            G.isNotNilAndNotEmpty(G.getPropFromObject(GC.FIELD_FILE_URI, entity)),
          ) &&
          <Fragment>
            <Box mx='6px' cursor='pointer' onClick={() => handleOpenFilePreview(entity)}>
              {I.eye(darkBlueColor)}
            </Box>
            <Box mx='6px' cursor='pointer' onClick={() => handleDownloadFile(entity)}>
              {I.downloadDocument(darkBlueColor)}
            </Box>
          </Fragment>
        }
      </Flex>
    </AuthWrapper>
  );
};

const settings = {
  documentActions: {
    width: 110,
    customComponent: ({ data, callbackData }: Object) => <DocumentActions {...callbackData} entity={data} />,
  },
  [GC.FIELD_DOCUMENT_FILE_NAME]: {
    width: 200,
    name: 'titles:document-name',
    customComponent: ({ data }: Object) => {
      const url = G.getPropFromObject(GC.FIELD_DOCUMENT_URL, data);
      const documentFileName = G.getPropFromObject(GC.FIELD_DOCUMENT_FILE_NAME, data);
      const title = R.or(documentFileName, url);

      return (
        <TextComponent title={title} display='block' maxWidth='90%' withEllipsis={true}>
          {
            G.isNotNilAndNotEmpty(url) &&
            <StyledLink
              target='_blank'
              textDecoration='underline'
              href={G.makeURLString(url)}
              color={G.getTheme('colors.light.blue')}
            >
              {url}
            </StyledLink>
          }
          {G.isNotNilAndNotEmpty(documentFileName) && documentFileName}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: {
    width: 150,
    name: 'titles:document-type',
    customComponent: ({ data }: Object) => {
      const title = R.pathOr(null, [GC.FIELD_DOCUMENT_DOCUMENT_TYPE, GC.FIELD_DISPLAYED_VALUE], data);

      return (
        <TextComponent title={title} display='block' maxWidth='90%' withEllipsis={true}>
          {title}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_CREATED_BY]: {
    width: 130,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 130,
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 130,
    name: 'titles:updated-by',
    value: GC.FIELD_LAST_MODIFIED_BY,
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 130,
    type: 'date',
    name: 'titles:updated-date',
  },
};

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(settings)),
};

export const AddDocumentButton = withCreateOrUpdateEquipmentServiceDocument((props: Object) => (
  <ActionButton
    px='8px'
    borderRadius='5px'
    mb={R.propOr(null, 'mb', props)}
    height={R.propOr(20, 'height', props)}
    fontSize={R.propOr(11, 'fontSize', props)}
    onClick={() => props.handleCreateOrUpdateEquipmentServiceDocument()}
  >
    {G.getWindowLocale('titles:add-document', 'Add Document')}
  </ActionButton>
));

const EquipmentServiceDocuments = (props: Object) => {
  const { p, documentList, localItemList, useComponentState, showAddDocumentButton } = props;

  const itemList = G.ifElse(useComponentState, localItemList, documentList);

  return (
    <Box p={R.or(p, 20)} maxWidth={1030}>
      {
        showAddDocumentButton && <AddDocumentButton {...props} height={30} mb={15} fontSize={null} />
      }
      <ZOrderWrapper zIndex='1'>
        <Table
          report={report}
          itemList={itemList}
          callbackData={props}
          hasSelectable={false}
          columnSettings={settings}
          tableSettings={tableSettings}
        />
      </ZOrderWrapper>
    </Box>
  );
};

const enhance = compose(
  withCreateOrUpdateEquipmentServiceDocument,
  withHandlers({
    removeEquipmentServiceDocumentByEntityTypeRequest: (props: Object) => async (guid: string) => {
      const {
        entityType,
        closeModal,
        openLoader,
        closeLoader,
        documentList,
        localItemList,
        setItemDetails,
        setLocalItemList,
        useComponentState,
        primaryObjectGuid,
      } = props;

      openLoader();
      const endpoint = endpointsMap.removeFleetEquipmentServiceEntityDocument(guid, entityType);

      const res = await sendRequest('delete', endpoint);

      const { status } = res;

      if (G.isResponseSuccess(status)) {
        G.callFunction(closeModal);

        G.showToastrMessageSimple(
          'success',
          G.getWindowLocale('messages:success:200-201', 'The request has succeeded'),
        );

        const details = R.reject(
          R.propEq(guid, GC.FIELD_GUID),
          G.ifElse(useComponentState, localItemList, documentList),
        );

        if (G.isTrue(useComponentState)) setLocalItemList(details);

        setItemDetails({ details, primaryObjectGuid });
      } else {
        G.handleFailResponseSimple(res);
      }

      closeLoader();
    },
  }),
  withHandlers({
    handleGetDocumentList: (props: Object) => async () => {
      const {
        entityType,
        openLoader,
        closeLoader,
        setItemDetails,
        setLocalItemList,
        useComponentState,
        primaryObjectGuid,
      } = props;

      G.callFunction(openLoader);
      const endpoint = endpointsMap.fleetEquipmentServiceEntityDocumentList(entityType);

      const options = {
        params: { primaryObjectGuid },
      };

      const res = await sendRequest('get', endpoint, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        if (useComponentState) setLocalItemList(data);

        setItemDetails({ details: data, primaryObjectGuid });
      } else {
        G.handleFailResponse(res);
      }

      G.callFunction(closeLoader);
    },
    handleOpenFilePreview: (props: Object) => async ({ guid, filePublicLink }: Object) => {
      const { entityType, openLoader, closeLoader } = props;

      if (G.isNotNilAndNotEmpty(filePublicLink)) return G.openFileByPublicLink(filePublicLink);

      openLoader();

      const options = {
        resType: 'arraybuffer',
      };

      const endpoint = endpointsMap.fleetEquipmentServiceEntityDocumentDownload(guid, entityType);

      const res = await sendRequestWithQSParamsSerializer('get', endpoint, options);

      const { status } = res;
      if (G.isResponseSuccess(status)) {
        G.openFileInWindowFromArrayBufferResponse(res);
      } else {
        G.handleFailResponseSimple(res);
      }

      closeLoader();
    },
    handleDownloadFile: (props: Object) => async ({ guid }: Object) => {
      const { entityType, openLoader, closeLoader } = props;

      openLoader();

      const options = {
        resType: 'arraybuffer',
      };

      const endpoint = endpointsMap.fleetEquipmentServiceEntityDocumentDownload(guid, entityType);

      const res = await sendRequestWithQSParamsSerializer('get', endpoint, options);

      const { status } = res;

      if (G.isResponseSuccess(status)) {
        G.saveFileFromResponse(res);

        G.showToastrMessageSimple(
          'success',
          G.getWindowLocale('messages:success:200-201', 'The request has succeeded'),
        );
      } else {
        G.handleFailResponseSimple(res);
      }

      closeLoader();
    },
    handleRemoveDocument: (props: Object) => (guid: string) => {
      const {
        openModal,
        removeEquipmentServiceDocumentByEntityTypeRequest,
      } = props;

      const textLocale = `${G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')} ${
        G.getWindowLocale('titles:equipment-service-document', 'Equipment Service Document')
      }`;

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => removeEquipmentServiceDocumentByEntityTypeRequest(guid),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { documentList, localItemList, useComponentState, handleGetDocumentList } = this.props;

      const itemList = G.ifElse(useComponentState, localItemList, documentList);

      if (G.isNilOrEmpty(itemList)) handleGetDocumentList();
    },
  }),
  pure,
);

export default enhance(EquipmentServiceDocuments);

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// features
import FleetMap from '../../fleet-map';
import { openMapPageFromFleetList } from '../../fleet-map/actions';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { ListWrapper } from '../../../ui';
//////////////////////////////////////////////////

const FleetListMap = (props: Object) => {
  const { tabs, reportType } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch((openMapPageFromFleetList(reportType)));
  }, [reportType]);

  return (
    <ListWrapper
      pl='0px'
      p='30px 15px 60px 0px'
      bgColor={G.getTheme('colors.white')}
    >
      {tabs}
      <FleetMap {...props} />
    </ListWrapper>
  );
};

export default FleetListMap;

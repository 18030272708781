import * as R from 'ramda';
import React from 'react';
import { Waypoint } from 'react-waypoint';
// components
import { LocalLoader } from '../../../components/local-loader';
import { Table, TitleRowComponent } from '../../../components/table';
// features
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, StickedFlex, IconWrapper } from '../../../ui';
// feature dispatch-report
import * as H from '../helpers';
import {
  getFilterProps,
  loadTableSettings,
  orderTableSettings,
  getLoadTableReport,
  getOrderTableReport,
} from '../settings/table-settings';
/////////////////////////////////////////////////

const getReport = (isLoadTypeTel: boolean, shipUnitsExpanded: boolean, showMode: boolean = false) => {
  let report;

  const hasNotBranchPermissions = G.hasNotAmousCurrentUserPermissions([
    PC.CLO_ENTERPRISE_READ,
    PC.TEL_ENTERPRISE_READ,
    PC.CLO_ENTERPRISE_WRITE,
    PC.TEL_ENTERPRISE_WRITE,
  ]);

  const hasNotEarningsPermissions = G.hasNotAmousCurrentUserPermissions(R.of(Array, PC.SHOW_EARNINGS_EXECUTE));

  const hasNotMessagesReferencesPermissions = G.hasNotAmousCurrentUserPermissions(G.ifElse(
    isLoadTypeTel,
    [PC.TEL_CHAT_MESSAGE_READ, PC.TEL_CHAT_MESSAGE_WRITE, PC.TEL_REFERENCE_READ, PC.TEL_REFERENCE_WRITE],
    [PC.CLO_CHAT_MESSAGE_READ, PC.CLO_CHAT_MESSAGE_WRITE, PC.CLO_REFERENCE_READ, PC.CLO_REFERENCE_WRITE],
  ));

  if (isLoadTypeTel) {
    report = getLoadTableReport(shipUnitsExpanded, showMode);
  } else if (R.not(G.isCurrentUserTypeCustomer())) {
    report = getOrderTableReport(shipUnitsExpanded, showMode);
  } else {
    report = G.omitReportFields(
      getOrderTableReport(shipUnitsExpanded),
      [GC.FIELD_LOAD_EARNINGS, GC.FIELD_LOAD_CARRIER_FLEET],
    );
  }

  if (hasNotBranchPermissions) {
    report = G.omitReportFields(report, [GC.FIELD_BRANCH]);
  }

  if (R.and(hasNotMessagesReferencesPermissions, G.isCurrentUserTypeCarrier())) {
    report = R.compose(
      R.assocPath(['fields', R.subtract(R.length(report.fields), 2), 'right'], 50),
      R.assoc('fields', R.init(report.fields)),
    )(report);
  }

  if (hasNotEarningsPermissions) {
    report = G.omitReportFields(report, [GC.FIELD_LOAD_EARNINGS]);
  }

  return report;
};

const GroupTable = (props: Object) => {
  const {
    report,
    groupBy,
    routeTab,
    itemList,
    groupInfo,
    totalCount,
    selectItem,
    pagination,
    tableIndex,
    isLoadTypeTel,
    rowBgFunction,
    tableSettings,
    columnSettings,
    getItemListRequest,
    handleClickEditIcon,
  } = props;

  const elementActionsComponent = (data: Object) => (
    <AuthWrapper has={[PC.TEL_READ, PC.TEL_WRITE]}>
      <IconWrapper
        px={12}
        cursor='pointer'
        onClick={(e: Object) => handleClickEditIcon(e, R.assoc('tableIndex', tableIndex, data))}
      >
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>
  );

  let subTitle = null;

  if (G.notEquals(groupBy, GC.ORGANIZE_BY_NO_GROUPING)) {
    const bgColor = G.getTheme(G.ifElse(isLoadTypeTel, 'colors.light.sweetPink', 'colors.light.teaGreen'));

    subTitle = (
      <Box height={20} fontSize={10} bg={bgColor}>
        <StickedFlex
          pl={16}
          left='0px'
          height='100%'
          alignItems='center'
          width='fit-content'
        >
          {H.getTableTitleName(groupInfo, groupBy, routeTab)}
        </StickedFlex>
      </Box>
    );
  }

  const allChecked = false;

  const tableData = {
    report,
    itemList,
    subTitle,
    allChecked,
    totalCount,
    pagination,
    rowBgFunction,
    columnSettings,
    hasSelectable: true,
    withoutBorder: true,
    withoutWaypoint: true,
    onOptionClick: selectItem,
    callbackData: { tableIndex },
    tableWrapperOverflow: 'unset',
    useNewTitleFilterInputs: true,
    handleLoadMoreEntities: getItemListRequest,
    renderRightStickedComponent: elementActionsComponent,
    tableSettings: R.assoc('withTitleRow', false, tableSettings),
    rowStyles: {
      my: '5px',
      borderTop: true,
      borderColor: 'colors.bgGrey',
      activeBorderColor: 'colors.bgGrey',
    },
  };

  return <Table {...tableData} />;
};

const DispatchBoardTable = (props: Object) => {
  const {
    groups,
    groupBy,
    routeTab,
    totalCount,
    pagination,
    modeOptions,
    selectedList,
    rowBgFunction,
    columnSettings,
    titleSortValues,
    handleSelectAll,
    tableTitleFilters,
    shipUnitsExpanded,
    handleClickEditIcon,
    handleTitleCellClick,
    handleLoadMoreEntities,
    handleTableTitleFilter,
    showTransportationMode,
    loadCustomStatusOptions,
  } = props;

  const withWaypoint = G.isAllTrue(
    R.gt(totalCount, R.path(['offset'], pagination)),
    R.gte(R.path(['offset'], pagination), R.path(['limit'], pagination)),
  );

  const isLoadTypeTel = R.equals(routeTab, GC.LOAD_TYPE_TEL);
  const report = getReport(isLoadTypeTel, shipUnitsExpanded, showTransportationMode);
  const titleRowReport = R.assoc('fields', R.tail(report.fields), report);

  const tableSettings = {
    ...G.ifElse(isLoadTypeTel, loadTableSettings, orderTableSettings),
    handleTitleCellClick,
  };

  const titleRowTableSettings = G.ifElse(
    G.isCurrentUserTypeCarrier(),
    R.mergeRight(tableSettings, {
      allowSelectAll: false,
      allowSelectItems: false,
    }),
    R.assoc('checkBoxCellWidth', 80, tableSettings),
  );

  const allChecked = R.eqProps(
    'length',
    selectedList,
    R.reduce((acc: Array, group: Object) => R.concat(acc, R.values(group.loads)), [], groups),
  );

  return (
    <Box
      width='100%'
      overflow='auto'
      maxHeight='calc(100vh - 140px)'
      bg={G.getTheme('colors.bgLightGrey')}
    >
      <TitleRowComponent
        itemList={groups}
        lastFreezedIndex={0}
        report={titleRowReport}
        allChecked={allChecked}
        useNewTitleFilterInputs={true}
        columnSettings={columnSettings}
        titleSortValues={titleSortValues}
        tableSettings={titleRowTableSettings}
        tableTitleFilters={tableTitleFilters}
        handleTableTitleFilter={handleTableTitleFilter}
        onOptionClick={() => handleSelectAll(allChecked)}
        filterProps={getFilterProps(routeTab, modeOptions, loadCustomStatusOptions)}
      />
      {
        groups.map((group: Object, i: number) => (
          <GroupTable
            key={i}
            tableIndex={i}
            report={report}
            groupBy={groupBy}
            routeTab={routeTab}
            withTitleRow={false}
            isLoadTypeTel={isLoadTypeTel}
            tableSettings={tableSettings}
            rowBgFunction={rowBgFunction}
            columnSettings={columnSettings}
            itemList={R.values(group.loads)}
            groupInfo={group.groupingObject}
            handleClickEditIcon={handleClickEditIcon}
          />
        ))
      }
      {
        withWaypoint &&
        <div>
          <Waypoint onEnter={handleLoadMoreEntities} />
          <LocalLoader minWidth={340} localLoaderOpen={true} />
        </div>
      }
    </Box>
  );
};

export default DispatchBoardTable;

import * as R from 'ramda';
import { useFormik } from 'formik';
import React, { useMemo } from 'react';
// components
import { TextComponent } from '../../../../components/text';
// forms
import { Fieldset2 } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, Flex, Grid, MainActionButton, scrollableContainerCss4px } from '../../../../ui';
// feature fleet-profile
import FleetProfilePhoto from '../../components/profile-photo';
import { useGetGeofencingLocationNameByGuid } from './home-base';
import { generalDetailsSettings } from '../settings/general-details';
//////////////////////////////////////////////////

const MainCard = (props: Object) => {
  const { shared, configGroup, generalDetails, handleUpdateGeneralDetails } = props;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: generalDetails,
    onSubmit: handleUpdateGeneralDetails,
    validationSchema: generalDetailsSettings.validationSchema,
  });

  const { values, handleSubmit } = formik;

  const optionsForSelect = R.pick(
    [GC.TRAILER_OUT_SERVICE_REASON, GC.TRAILER_UNAVAILABILITY_REASON],
    R.propOr({}, 'dropdownOptions', configGroup),
  );

  return (
    <Box
      pt={10}
      pb={20}
      bg='white'
      width='100%'
      borderRadius={16}
      boxShadow='cardBoxShadow'
    >
      <Box mt={25} mx='auto' width='max-content'>
        <FleetProfilePhoto
          type='trailer'
          shared={shared}
          guid={G.getGuidFromObject(generalDetails)}
        />
      </Box>
      <form onSubmit={handleSubmit}>
        <Fieldset2
          {...formik}
          {...optionsForSelect}
          shared={shared}
          fields={generalDetailsSettings.firstFields.fieldSettings}
          fieldsWrapperStyles={{ mt: 20, flexDirection: 'column' }}
        />
        <MainActionButton
          mx='auto'
          type='submit'
          disabled={R.equals(generalDetails, values)}
          display={G.ifElse(R.or(shared, R.equals(generalDetails, values)), 'none', 'block')}
        >
          {G.getWindowLocale('titles:update', 'Update')}
        </MainActionButton>
      </form>
    </Box>
  );
};

const Card = ({ rows, shared, title, handleOpenGeneralDetails }: Object) => (
  <Box
    pt={10}
    pb={20}
    bg='white'
    width='100%'
    borderRadius={16}
    boxShadow='cardBoxShadow'
  >
    <Flex
      px={20}
      my={15}
      fontSize={16}
      flexWrap='wrap'
      fontWeight='bold'
      color='greyMatterhorn'
    >
      {title}
      {
        G.isFalse(shared) &&
        <MainActionButton ml={20} height={25} onClick={handleOpenGeneralDetails}>
          {G.getWindowLocale('actions:edit', 'Edit')}
        </MainActionButton>
      }
    </Flex>
    <Box px={20} maxHeight={400} overflowY='auto' css={scrollableContainerCss4px}>
      {
        rows.map(({ label, value }: Object, index: number) => (
          <Flex
            py={10}
            key={index}
            color='greyMatterhorn'
          >
            <TextComponent mr={10}>{G.getWindowLocale(label)}:</TextComponent>
            <TextComponent
              fontWeight='bold'
              withEllipsis={true}
              title={R.or(value, '-')}
              maxWidth='calc(100% - 140px)'
            >
              {R.or(value, '-')}
            </TextComponent>
          </Flex>
        ))
      }
    </Box>
  </Box>
);

const TrailerCards = (props: Object) => {
  const { shared, configGroup, generalDetails, handleUpdateGeneralDetails, handleOpenGeneralDetails } = props;

  const {
    // primary details
    unitId,
    outputYear,
    modelSeries,
    legacyUnitId,
    serialNumber,
    manufacturer,
    trailerModel,
    inServiceDate,
    outServiceDate,
    trailerTypeDisplayedValue,
    homeBaseGeofencingLocationGuid,
    // registration
    titleState,
    titleNumber,
    licensePlate,
    registrationState,
    registrationExpiredDate,
  } = generalDetails;

  const homeBase = useGetGeofencingLocationNameByGuid(homeBaseGeofencingLocationGuid);

  const primaryDetailsFields = useMemo(() => [
    {
      value: unitId,
      label: 'titles:unit-id',
    },
    {
      value: legacyUnitId,
      label: 'titles:legacy-unit-id',
    },
    {
      label: 'titles:type',
      value: trailerTypeDisplayedValue,
    },
    {
      value: inServiceDate,
      label: 'titles:in-service-date',
    },
    {
      value: outServiceDate,
      label: 'titles:out-service-date',
    },
    {
      value: serialNumber,
      label: 'titles:serial-number-vin',
    },
    {
      value: manufacturer,
      label: 'titles:manufacturer',
    },
    {
      value: outputYear,
      label: 'titles:year',
    },
    {
      value: trailerModel,
      label: 'titles:model',
    },
    {
      value: modelSeries,
      label: 'titles:series',
    },
    {
      value: homeBase,
      label: 'titles:home-base',
    },
  ], [
    unitId,
    homeBase,
    outputYear,
    modelSeries,
    legacyUnitId,
    serialNumber,
    manufacturer,
    trailerModel,
    inServiceDate,
    outServiceDate,
    trailerTypeDisplayedValue,
  ]);

  const registrationFields = useMemo(() => [
    {
      value: licensePlate,
      label: 'titles:license-plate',
    },
    {
      value: registrationState,
      label: 'titles:registration-state',
    },
    {
      value: registrationExpiredDate,
      label: 'titles:registration-expiration-date',
    },
    {
      value: titleState,
      label: 'titles:title-state',
    },
    {
      value: titleNumber,
      label: 'titles:title-number',
    },
  ], [titleState, titleNumber, licensePlate, registrationState, registrationExpiredDate]);

  return (
    <Grid my={20} gridGap={20} gridTemplateColumns='repeat(auto-fill, minmax(420px, 1fr))'>
      <MainCard
        shared={shared}
        configGroup={configGroup}
        generalDetails={generalDetails}
        handleUpdateGeneralDetails={handleUpdateGeneralDetails}
      />
      <Card
        shared={shared}
        rows={primaryDetailsFields}
        handleOpenGeneralDetails={() => handleOpenGeneralDetails(0)}
        title={G.getWindowLocale('titles:primary-details', 'Primary Details')}
      />
      <Card
        shared={shared}
        rows={registrationFields}
        handleOpenGeneralDetails={() => handleOpenGeneralDetails(1)}
        title={G.getWindowLocale('titles:registration', 'Registration')}
      />
    </Grid>
  );
};

export default TrailerCards;

import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature configs
import {
  glCodeMappingFieldSettings,
  defaultGLCodeMappingFields,
  glCodeMappingValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const glCodeTypeOptions = [
  {
    value: GC.INVOICE_MAPPING_TYPE_INVOICE,
    label: G.getWindowLocale('titles:invoice', 'Invoice'),
  },
  {
    value: GC.INVOICE_MAPPING_TYPE_LINE_HAUL,
    label: G.getWindowLocale('titles:line-haul', 'Line Haul'),
  },
  {
    value: GC.INVOICE_MAPPING_TYPE_DISCOUNT,
    label: G.getWindowLocale('titles:discount', 'Discount'),
  },
  {
    value: GC.INVOICE_MAPPING_TYPE_FUEL,
    label: G.getWindowLocale('titles:fuel', 'Fuel'),
  },
  {
    value: GC.INVOICE_MAPPING_TYPE_ASSESSORIALS,
    label: G.getWindowLocale('titles:accessorials', 'Accessorials'),
  },
];

const getOptionsForSelect = (props: Object) => {
  const { values, glCodeList, accessorialList, initialValues, glCodeMappingList, orderTypeOptions } = props;

  const scope = G.getPropFromObject(GC.FIELD_SCOPE, values);
  const orderTypeOptionsWithEmpty = G.addEmptyOptionToDropDown(orderTypeOptions);
  const glCodeTypeOptionsWithEmpty = G.addEmptyOptionToDropDown(glCodeTypeOptions);
  const accessorialGLTypes = R.compose(
    R.map(R.map(R.prop(GC.FIELD_ACCESSORIAL_CONFIG_GUID))),
    R.groupBy(R.prop(GC.FIELD_SCOPE)),
    R.filter(R.propEq(GC.INVOICE_MAPPING_TYPE_ASSESSORIALS, GC.FIELD_GL_CODE_TYPE)),
  )(glCodeMappingList);
  const accessorialOptions = R.compose(
    G.addEmptyOptionToDropDown,
    R.map(({ displayedValue, originalConfigGuid }: Object) => ({
      [GC.FIELD_LABEL]: displayedValue,
      [GC.FIELD_VALUE]: originalConfigGuid,
      // TODO: check API logic here or remove
      // disabled: R.and(
      //   R.includes(originalConfigGuid, R.pathOr([], [scope], accessorialGLTypes)),
      //   G.notEquals(originalConfigGuid, G.getPropFromObject(GC.FIELD_ACCESSORIAL_CONFIG_GUID, initialValues)),
      // ),
    })),
  )(accessorialList);
  const glCodeOptions = R.compose(
    G.addEmptyOptionToDropDown,
    R.map((item: Object) => ({
      [GC.FIELD_VALUE]: G.getParentGuidOrGuidFromObject(item),
      [GC.FIELD_LABEL]: G.getPropFromObject(GC.FIELD_DISPLAYED_VALUE, item),
    })),
  )(glCodeList);

  return {
    glCodeOptions,
    accessorialOptions,
    orderTypeOptions: orderTypeOptionsWithEmpty,
    glCodeTypeOptions: glCodeTypeOptionsWithEmpty,
  };
};

const enhance = compose(
  withFormik({
    validationSchema: glCodeMappingValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultGLCodeMappingFields,
      initialValues,
    ),
  }),
  withHandlers({
    handleChangeScope: ({ setValues }: Object) => (event: Object) => {
      const values = R.assoc(
        GC.FIELD_SCOPE,
        R.path(['currentTarget', GC.FIELD_VALUE], event),
        defaultGLCodeMappingFields,
      );

      setValues(values);
    },
  }),
  pure,
);

const GLCodeMappingForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      {...getOptionsForSelect(props)}
      fields={glCodeMappingFieldSettings}
      handleCustomChange={props.handleChangeScope}
      fieldsWrapperStyles={{ pt: 11, flexDirection: 'column' }}
    />
    <FormFooter />
  </form>
);

export default enhance(GLCodeMappingForm);

import React from 'react';
import * as R from 'ramda';
// components
import { ActionBox } from '../../../../components/action-box';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet-profile
import { TableCell } from '../../components/table-cells';
//////////////////////////////////////////////////

const settings = {
  [GC.FIELD_SOURCE]: {
    width: 120,
    name: 'titles:source',
  },
  [GC.FIELD_LOG_DATE]: {
    width: 220,
    type: 'date',
    name: 'titles:date',
  },
  [GC.FIELD_TOTAL_DISTANCE]: {
    width: 120,
    name: 'titles:total-distance',
    customComponent: (row: Object) => {
      const { totalDistance, totalDistanceUom } = R.propOr({}, 'data', row);

      if (G.isNilOrEmpty(totalDistance)) return null;

      return <TableCell text={`${totalDistance} ${G.getUomLocale(totalDistanceUom)}`} />;
    },
  },
  [GC.FIELD_ODOMETER]: {
    width: 120,
    name: 'titles:odometer',
  },
  [GC.GRC.DRIVER_FULL_NAME]: {
    width: 150,
    name: 'titles:driver-full-name',
    customComponent: ({ data, fieldData, callbackData }: Object) => {
      if (R.isNil(fieldData)) return null;

      const { handleGoToProfileByProfileType } = callbackData;

      const guid = R.prop(GC.GRC.DRIVER_GUID, data);

      return (
        <ActionBox
          text={fieldData}
          action={() => handleGoToProfileByProfileType({ guid, type: 'driver' })}
        />
      );
    },
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 220,
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 220,
    type: 'date',
    name: 'titles:update-on',
  },
};

const columnSettings = R.map(R.pick(['type', 'name', 'width', 'sortable', 'searchable', 'customComponent']), settings);

const placeholder = { text: 'Choose', key: 'titles:choose' };

const filterProps = R.mapObjIndexed((
  { name, options, collection, filterType, disableFilter, type = 'string' }: Object,
  value: string,
) => ({
  value,
  options,
  collection,
  placeholder,
  disableFilter,
  type: R.or(filterType, type),
  [GC.FIELD_NAME]: G.getWindowLocale(name),
}), settings);

const reportFields = G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(settings));

const makeRequestPayload = ({ primaryObjectGuid, primaryObjectBranchGuid }: Object) => ({
  fields: reportFields,
  [GC.CURRENT_BRANCH]: primaryObjectBranchGuid,
  systemFields: [
    { sequence: 1, name: GC.GRC.DRIVER_GUID },
    { sequence: 2, name: GC.FIELD_TOTAL_DISTANCE_UOM },
  ],
  searchCriteria: [
    {
      operation: 'equal',
      dataType: 'string',
      stringValue: primaryObjectGuid,
      propertyName: GC.FIELD_TRUCK_GUID,
    },
  ],
});

const makeExportRequestPayload = ({ primaryObjectGuid }: Object) => ({
  searchCriteria: [
    {
      operation: 'equal',
      dataType: 'string',
      stringValue: primaryObjectGuid,
      propertyName: GC.FIELD_TRUCK_GUID,
    },
  ],
});

const FILTER_PARAMS = R.values(filterProps);

const dailyLogSettings = {
  filterProps,
  columnSettings,
  isReport: true,
  actionsPicker: [],
  noExportable: false,
  groupName: 'dailyLog',
  useSearchableColumns: true,
  withResizableColumns: true,
  useNewTitleFilterInputs: true,
  reportType: GC.FLEET_TRUCK_DAILY_LOG_REPORT,
  formGroupTitleArr: ['titles:daily-log', 'Daily Log'],
  tableCallbackDataProps: ['handleGoToProfileByProfileType'],
  endpoints: {
    list: 'truckDailyLogList',
    export: 'truckDailyLogListExport',
  },
  additionalTableSettings: {
    searchableTitles: true,
    tableWrapperProps: {
      minHeight: 320,
      maxHeight: 'calc(100vh - 700px)',
    },
  },
  // helpers
  makeRequestPayload,
  makeExportRequestPayload,
};

export { FILTER_PARAMS, dailyLogSettings };

import * as Yup from 'yup';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = { mb: 25, width: 200 };

export const validationSchemaObject = {
  [GC.FIELD_NAME]: G.yupStringRequired,
  truckGuids: Yup.array().of(Yup.string()).nullable(true),
  driverGuids: Yup.array().of(Yup.string()).nullable(true),
  dispatcherGuids: Yup.array().of(Yup.string()).nullable(true),
};

export const defaultFields = {
  truckGuids: [],
  driverGuids: [],
  dispatcherGuids: [],
  [GC.FIELD_NAME]: null,
};

const customChangeHandler = (data: Array, field: Object, props: Object) => {
  const { values, setValues, driverList } = props;

  const { truckGuids } = values;

  const truckGuidsToUse = R.compose(
    R.uniq,
    R.concat(truckGuids),
    R.filter(G.isNotNil),
    R.map((item: string) => R.prop('truckGuid', item)),
    R.filter((item: Object) => R.includes(G.getValueFromObject(item), data)),
  )(driverList);

  return setValues({
    ...values,
    driverGuids: data,
    truckGuids: truckGuidsToUse,
  });
};

export const itemFormFields = [
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_NAME,
    label: ['titles:name', 'Name'],
  },
  {
    isMulti: true,
    inputWrapperStyles,
    customChangeHandler,
    type: 'reactSelect',
    options: 'driverList',
    fieldName: 'driverGuids',
    shouldCustomChange: true,
    useMenuPortalTarget: true,
    valueContainerMaxHeight: 200,
    useMultiSelectMaxHeight: true,
    label: ['titles:drivers', 'Drivers'],
  },
  {
    isMulti: true,
    inputWrapperStyles,
    type: 'reactSelect',
    options: 'truckList',
    fieldName: 'truckGuids',
    useMenuPortalTarget: true,
    valueContainerMaxHeight: 200,
    useMultiSelectMaxHeight: true,
    label: ['titles:trucks', 'Trucks'],
  },
  {
    isMulti: true,
    inputWrapperStyles,
    type: 'reactSelect',
    options: 'dispatcherList',
    useMenuPortalTarget: true,
    fieldName: 'dispatcherGuids',
    valueContainerMaxHeight: 200,
    useMultiSelectMaxHeight: true,
    label: ['titles:dispatchers', 'Dispatchers'],
  },
];

import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  compose,
  lifecycle,
  withState,
  withProps,
  withHandlers,
} from 'react-recompose';
// components
import { Tabs2 } from '../../../../components/tabs-mui';
import { FormFooter2 } from '../../../../components/form-footer';
// features
import { makeSelectMasterSettings } from '../../../branch/selectors';
// forms
import { Fieldset2 } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hooks
import { useAiRecognize } from '../../../../hooks';
// icons
import { AmousLogoAiComponent } from '../../../../svgs/amous-animated-logo/animated-logo';
// ui
import { Box, Flex, scrollableContainerCss4px } from '../../../../ui';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
import { sendRequest } from '../../../../utilities/http';
// fleet-profile
import {
  makeSelectDriverExpenseTypes,
  makeSelectAvailableAssignable,
} from '../../selectors';
import {
  getDriverExpenseTypesSuccess,
  getAvailableAssignableSuccess,
  createOrUpdateDriverExpenseRequest,
} from '../../actions';
import ExpenseAiUpload from './expense-ai-upload';
import ExpenseDocuments from './expense-documents';
import ExpenseDocumentsUpdate from './expense-documents-update';
//////////////////////////////////////////////////

const requirableTypeOptions = [
  { label: G.getWindowLocale('titles:non-payroll', 'Non-Payroll'), value: 'NON_REQUIRABLE' },
  { label: G.getWindowLocale('titles:driver-payroll', 'Driver Payroll'), value: GC.INVOICE_SCOPE_TYPE_DRIVER },
  { label: G.getWindowLocale('titles:vendor-payroll', 'Vendor Payroll'), value: GC.INVOICE_SCOPE_TYPE_VENDOR },
];

const defaultFields = {
  [GC.FIELD_EXPENSE_TYPE_GUID]: null,
  [GC.FIELD_TRUCK_GUID]: null,
  [GC.FIELD_TRAILER_GUID]: null,
  [GC.FIELD_DATE]: null,
  [GC.FIELD_TOTAL]: null,
  [GC.FIELD_CURRENCY]: null,
  [GC.FIELD_ADDRESS]: null,
  [GC.FIELD_DESCRIPTION]: null,
  [GC.FIELD_EXPENSE_REQUIRABLE_TYPE]: null,
  files: null,
};

const getValidationSchema = (values: Object, types: Object) => {
  const { typeGuid } = values;

  let schema = {
    files: Yup.array().notRequired(),
    [GC.FIELD_DATE]: G.yupStringRequired,
    [GC.FIELD_CURRENCY]: G.yupStringRequired,
    [GC.FIELD_EXPENSE_TYPE_GUID]: G.yupStringRequired,
    [GC.FIELD_EXPENSE_REQUIRABLE_TYPE]: G.yupStringNotRequired,
    [GC.FIELD_ADDRESS]: Yup.string()
      .nullable(true)
      .notRequired()
      .max(255, G.getShouldBeLessOrEqualLocaleTxt(255)),
    [GC.FIELD_DESCRIPTION]: Yup.string()
      .nullable(true)
      .max(5000, G.getShouldBeLessOrEqualLocaleTxt(2000)),
    [GC.FIELD_TOTAL]: Yup.number()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .positive(G.getShouldBePositiveLocaleTxt())
      .max(99999, G.getShouldBeLessOrEqualLocaleTxt(99999)),
  };

  if (R.pathEq(GC.CONFIGURATION_MAINTENANCE_TYPE_TRUCK, [typeGuid, GC.FIELD_CONFIGURATION_MAINTENANCE_TYPE], types)) {
    schema = R.assoc(GC.FIELD_TRUCK_GUID, G.yupStringRequired, schema);
  }

  if (R.pathEq(GC.CONFIGURATION_MAINTENANCE_TYPE_TRAILER, [typeGuid, GC.FIELD_CONFIGURATION_MAINTENANCE_TYPE], types)) {
    schema = R.assoc(GC.FIELD_TRAILER_GUID, G.yupStringRequired, schema);
  }

  return schema;
};

const inputWrapperStyles = { mb: 25, width: 200 };

const getFieldDisplay = (props: Object) => {
  const { type, values, indexedExpenseTypes } = props;

  const { typeGuid } = values;

  const condition = R.or(G.isNilOrEmpty(typeGuid), R.and(
    G.isNotNilAndNotEmpty(typeGuid),
    R.not(R.pathEq(
      type,
      [typeGuid, GC.FIELD_CONFIGURATION_MAINTENANCE_TYPE],
      indexedExpenseTypes,
    )),
  ));

  if (condition) return 'none';
};

const getFields = (values: Object, indexedExpenseTypes: Object) => [
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    options: 'expenseTypes',
    shouldCustomChange: true,
    label: ['titles:expense-type'],
    fieldName: GC.FIELD_EXPENSE_TYPE_GUID,
  },
  {
    isRequired: true,
    isClearable: true,
    type: 'datePicker',
    inputWrapperStyles,
    timeSelection: true,
    label: ['titles:date'],
    fieldName: GC.FIELD_DATE,
    calendarInputWrapperStyles: { width: '100%' },
  },
  {
    inputWrapperStyles,
    shouldCustomChange: true,
    label: ['titles:address'],
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS,
  },
  {
    inputWrapperStyles,
    type: 'reactSelect',
    options: requirableTypeOptions,
    fieldName: GC.FIELD_EXPENSE_REQUIRABLE_TYPE,
    label: ['titles:requirable-type', 'Requirable Type'],
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:total'],
    fieldName: GC.FIELD_TOTAL,
  },
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    fieldName: GC.FIELD_CURRENCY,
    options: R.tail(GC.CURRENCY_OPTIONS),
    label: ['titles:currency', 'Currency'],
  },
  {
    isRequired: true,
    options: 'trucks',
    type: 'reactSelect',
    label: ['titles:truck'],
    fieldName: GC.FIELD_TRUCK_GUID,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: getFieldDisplay({
        values,
        indexedExpenseTypes,
        type: GC.CONFIGURATION_MAINTENANCE_TYPE_TRUCK,
      }),
    },
  },
  {
    isRequired: true,
    type: 'reactSelect',
    options: 'trailers',
    label: ['titles:trailer'],
    fieldName: GC.FIELD_TRAILER_GUID,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: getFieldDisplay({
        values,
        indexedExpenseTypes,
        type: GC.CONFIGURATION_MAINTENANCE_TYPE_TRAILER,
      }),
    },
  },
  {
    type: 'textarea',
    label: ['titles:description'],
    fieldName: GC.FIELD_DESCRIPTION,
    inputWrapperStyles: { mb: 25, width: 415 },
  },
];

const withAvailableTruckAndTrailers = compose(
  withState(
    'trucks',
    'setTrucks',
    ({ availableAssignable }: Object) => R.pathOr(null, ['availableTrucks'], availableAssignable),
  ),
  withState(
    'trailers',
    'setTrailers',
    ({ availableAssignable }: Object) => R.pathOr(null, ['availableTrailers'], availableAssignable),
  ),
  withState(
    'expenseTypes',
    'setExpenseTypes',
    R.pathOr([], ['expenseTypeList']),
  ),
  withProps(({ trucks, trailers, expenseTypes = [] }: Object) => ({
    indexedExpenseTypes: R.indexBy(R.prop(GC.FIELD_GUID), expenseTypes),
    optionsForSelect: {
      trucks,
      trailers,
      expenseTypes: R.map(
        ({ name, guid }: Object) => ({
          [GC.FIELD_VALUE]: guid,
          [GC.FIELD_LABEL]: name,
        }),
        expenseTypes,
      ),
    },
  })),
  withHandlers({
    getAvailableEntitiesRequest: (props: Object) => async (groupName: string) => {
      const {
        setTrucks,
        openLoader,
        setTrailers,
        closeLoader,
        initialValues,
        getAvailableAssignableSuccess,
      } = props;

      G.callFunction(openLoader);

      const options = {
        params: {
          currentDriverGuid: initialValues.driverGuid,
          [GC.BRANCH_GUID]: initialValues.enterpriseGuid,
        },
      };

      const isTrucks = R.equals(groupName, 'availableTrucks');

      const endpoint = G.ifElse(isTrucks, 'getAvailableTrucks', 'getAvailableTrailers');

      const res = await sendRequest('get', endpointsMap[endpoint], options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const options = R.map(({ guid, unitId, lastName, firstName }: Object) => ({
          [GC.FIELD_VALUE]: guid,
          [GC.FIELD_LABEL]: R.or(unitId, `${firstName} ${lastName}`),
        }), R.or(data, []));

        if (isTrucks) {
          setTrucks(options);
        } else {
          setTrailers(options);
        }

        getAvailableAssignableSuccess({ options, groupName });
      } else {
        G.handleException('error getAvailableEntitiesRequest');
      }

      G.callFunction(closeLoader);
    },
    getExpenseTypes: (props: Object) => async () => {
      const {
        openLoader,
        closeLoader,
        initialValues,
        setExpenseTypes,
        getDriverExpenseTypesSuccess,
      } = props;

      G.callFunction(openLoader);

      const options = {
        params: {
          [GC.BRANCH_GUID]: initialValues.enterpriseGuid,
        },
      };

      const res = await sendRequest('get', endpointsMap.driverExpenseTypeList, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setExpenseTypes(data);
        getDriverExpenseTypesSuccess(data);
      } else {
        G.handleException('error getExpenseTypes');
      }

      G.callFunction(closeLoader);
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        trucks,
        trailers,
        expenseTypeList,
        getExpenseTypes,
        getAvailableEntitiesRequest,
      } = this.props;

      if (R.isNil(trucks)) getAvailableEntitiesRequest('availableTrucks');

      if (R.isNil(trailers)) getAvailableEntitiesRequest('availableTrailers');

      if (R.isNil(expenseTypeList)) getExpenseTypes();
    },
  }),
  pure,
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  expenseTypeList: makeSelectDriverExpenseTypes(state),
  availableAssignable: makeSelectAvailableAssignable(state),
});

const makeFiles = (data: Array) => {
  if (G.isNilOrEmpty(data)) return [];

  return R.compose(
    R.map(R.prop('file')),
    R.reject(({ file }: Object) => G.isNilOrEmpty(file)),
  )(data);
};

const enhance = compose(
  withState('documents', 'setDocuments', null),
  withState('aiUploadDoc', 'setAiUploadDoc', null),
  connect(mapStateToProps, {
    getDriverExpenseTypesSuccess,
    getAvailableAssignableSuccess,
    createOrUpdateDriverExpenseRequest,
  }),
  withAvailableTruckAndTrailers,
  withFormik({
    validationSchema: ({ indexedExpenseTypes }: Object) => Yup.lazy((values: Object) => (
      Yup.object().shape(getValidationSchema(values, indexedExpenseTypes))
    )),
    handleSubmit: (values: Object, { props }: Object) => {
      const {
        documents,
        createOrUpdateDriverExpenseRequest,
      } = props;

      let valuesToUse = G.mapObjectEmptyStringFieldsToNull(values);

      if (G.isNotNilAndNotEmpty(documents)) {
        const files = makeFiles(documents);

        valuesToUse = { ...valuesToUse, files };
      }

      createOrUpdateDriverExpenseRequest(valuesToUse);
    },
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
  }),
  withHandlers({
    handleCustomChange: (props: Object) => (value: string, name: string) => {
      const { values, setValues, indexedExpenseTypes } = props;

      const type = R.path([value, GC.FIELD_CONFIGURATION_MAINTENANCE_TYPE], indexedExpenseTypes);

      let valuesToSet = {
        ...values,
        [name]: value,
      };

      if (R.equals(type, GC.CONFIGURATION_MAINTENANCE_TYPE_TRUCK)) {
        valuesToSet = {
          ...valuesToSet,
          [GC.FIELD_TRAILER_GUID]: '',
        };
      } else if (R.equals(type, GC.CONFIGURATION_MAINTENANCE_TYPE_TRAILER)) {
        valuesToSet = {
          ...valuesToSet,
          [GC.FIELD_TRUCK_GUID]: '',
        };
      } else {
        valuesToSet = {
          ...valuesToSet,
          [GC.FIELD_TRUCK_GUID]: '',
          [GC.FIELD_TRAILER_GUID]: '',
        };
      }

      setValues(valuesToSet);
    },
    handleSelectLocation: ({ setFieldValue }: Object) => ({ formattedAddress }: Object) => (
      setFieldValue(GC.FIELD_ADDRESS, formattedAddress)
    ),
    handleRemovePrevFile: ({ setFieldValue }: Object) => () => setFieldValue('deleteFile', true),
  }),
  pure,
);

const AiLoading = () => (
  <Flex mt={100} width={530} alignItems='center' justifyContent='center'>
    <AmousLogoAiComponent />
  </Flex>
);

const LeftSection = (props: Object) => {
  const {
    values,
    optionsForSelect,
    aiRecognizeLoading,
    handleCustomChange,
    indexedExpenseTypes,
    handleSelectLocation,
    disableSelectDriverField,
  } = props;

  if (G.isTrue(aiRecognizeLoading)) return <AiLoading />;

  return (
    <Box
      p={15}
      width={445}
      height='100%'
      flexShrink={0}
      overflow='auto'
      borderRight='1px solid'
      css={scrollableContainerCss4px}
      borderColor={G.getTheme('colors.lightGrey')}
    >
      <Fieldset2
        {...optionsForSelect}
        {...G.getFormikProps(props)}
        optionsForSelect={optionsForSelect}
        handleCustomChange={handleCustomChange}
        fields={getFields(values, indexedExpenseTypes)}
        customSelectLocationFunction={handleSelectLocation}
        fieldsWrapperStyles={{ mt: 15, justifyContent: 'space-between' }}
        handlers={{ disableSelectDriverField: () => disableSelectDriverField }}
      />
    </Box>
  );
};

const getTabs1 = (activeTab: number) => [
  {
    value: 0,
    withCount: R.equals(activeTab, 0),
    text: G.getWindowLocale('titles:ai-upload', 'AI Upload'),
  },
  {
    value: 1,
    withCount: R.equals(activeTab, 1),
    text: G.getWindowLocale('titles:documents', 'Documents'),
  },
];

const getTabs2 = (activeTab: number) => [
  {
    value: 0,
    withCount: R.equals(activeTab, 0),
    text: G.getWindowLocale('titles:documents', 'Documents'),
  },
];

const RightTabs = (props: Object) => {
  const {
    aiEnabled,
    rightActiveTab,
    setRightActiveTab,
  } = props;

  const tabs = G.isTrue(aiEnabled) ? getTabs1(rightActiveTab) : getTabs2(rightActiveTab);

  return (
    <Tabs2
      tabs={tabs}
      activeTab={rightActiveTab}
      setActiveTab={setRightActiveTab}
      tabStyles={GC.COMMON_MUI_TAB_STYLES}
      tabsStyles={{ ...GC.COMMON_MUI_TABS_STYLES, m: '15px' }}
    />
  );
};

const activeTabContentMap1 = {
  0: ExpenseAiUpload,
  1: ExpenseDocuments,
};

const activeTabContentMap2 = {
  0: ExpenseDocuments,
};

const activeTabContentUpdateMap1 = {
  0: ExpenseAiUpload,
  1: ExpenseDocumentsUpdate,
};

const activeTabContentUpdateMap2 = {
  0: ExpenseDocumentsUpdate,
};

const RightSection = (props: Object) => {
  const {
    rightActiveTab,
    setRightActiveTab,
  } = props;

  const masterSettings = useSelector(makeSelectMasterSettings());

  const aiEnabled = R.pathOr(false, [GC.FIELD_BRANCH_SETTINGS_AI_ENABLED], masterSettings);

  const contentMap = G.isTrue(aiEnabled) ? activeTabContentMap1 : activeTabContentMap2;

  const Component = contentMap[rightActiveTab];

  return (
    <Box
      flexGrow={1}
      height='100%'
      minWidth={620}
      flexShrink={0}
      overflow='auto'
      css={scrollableContainerCss4px}
    >
      <RightTabs
        aiEnabled={aiEnabled}
        rightActiveTab={rightActiveTab}
        setRightActiveTab={setRightActiveTab}
      />
      {
        G.isNilOrEmpty(Component) ? null : <Component {...props} />
      }
    </Box>
  );
};

const UpdateRightSection = (props: Object) => {
  const {
    rightActiveTab,
    setRightActiveTab,
  } = props;

  const masterSettings = useSelector(makeSelectMasterSettings());

  const aiEnabled = R.pathOr(false, [GC.FIELD_BRANCH_SETTINGS_AI_ENABLED], masterSettings);

  const contentMap = G.isTrue(aiEnabled) ? activeTabContentUpdateMap1 : activeTabContentUpdateMap2;

  const Component = contentMap[rightActiveTab];

  return (
    <Box
      flexGrow={1}
      height='100%'
      minWidth={620}
      flexShrink={0}
      overflow='auto'
      css={scrollableContainerCss4px}
    >
      <RightTabs
        aiEnabled={aiEnabled}
        rightActiveTab={rightActiveTab}
        setRightActiveTab={setRightActiveTab}
      />
      {
        G.isNilOrEmpty(Component) ? null : <Component {...props} />
      }
    </Box>
  );
};

const ExpenseForm = (props: Object) => {
  const { values, handleSubmit } = props;

  const { aiRecognizeRequest, aiRecognizeLoading } = useAiRecognize({ type: 'EXPENSE' });

  const [rightActiveTab, setRightActiveTab] = useState(0);

  const isEditMode = G.isNotNilAndNotEmpty(G.getGuidFromObject(values));

  const RightSectionComponent = G.isTrue(isEditMode) ? UpdateRightSection : RightSection;

  return (
    <form onSubmit={handleSubmit}>
      <Flex
        overflow='auto'
        alignItems='start'
        height='calc(100vh - 60px)'
        css={scrollableContainerCss4px}
      >
        <LeftSection
          {...props}
          aiRecognizeLoading={aiRecognizeLoading}
        />
        <RightSectionComponent
          {...props}
          rightActiveTab={rightActiveTab}
          setRightActiveTab={setRightActiveTab}
          aiRecognizeRequest={aiRecognizeRequest}
        />
      </Flex>
      <FormFooter2
        boxStyles={{
          p: 15,
          borderTop: '1px solid',
          bg: G.getTheme('colors.whiteGrey'),
          borderColor: G.getTheme('colors.lightGrey'),
        }}
      />
    </form>
  );
};

export default enhance(ExpenseForm);

import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

export const defaultDeactivationRuleValues = {
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_TRIGGER]: null,
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_PERCENT]: null,
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_AVERAGE_HOURS]: null,
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_PERIOD_IN_DAYS]: null,
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_DEACTIVATION_DAYS]: null,
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_MINIMUM_LOAD_COUNT]: null,
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_INSURANCE_TYPE_GUIDS]: null,
};

const inputWrapperStyles = {
  mb: 25,
  width: 270,
};

const triggerOptions = [
  {
    value: GC.CARRIER_DEACTIVATION_RULE_TRIGGER_INSURANCE_EXPIRATION,
    label: G.getEnumLocale(GC.CARRIER_DEACTIVATION_RULE_TRIGGER_INSURANCE_EXPIRATION),
  },
  {
    value: GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_DECLINE,
    label: G.getEnumLocale(GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_DECLINE),
  },
  {
    value: GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_TEL,
    label: G.getEnumLocale(GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_TEL),
  },
  {
    value: GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_PICKUP,
    label: G.getEnumLocale(GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_PICKUP),
  },
  {
    value: GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_DROP,
    label: G.getEnumLocale(GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_DROP),
  },
  {
    value: GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_EVENT_COMPLETE_BY_CARRIER,
    label: G.getEnumLocale(GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_EVENT_COMPLETE_BY_CARRIER),
  },
  {
    value: GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_INVOICE_TIME,
    label: G.getEnumLocale(GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_INVOICE_TIME),
  },
  {
    value: GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_DOCUMENT_TIME,
    label: G.getEnumLocale(GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_DOCUMENT_TIME),
  },
];

const percentTriggers = [
  GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_DECLINE,
  GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_TEL,
  GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_DROP,
  GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_PICKUP,
  GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_EVENT_COMPLETE_BY_CARRIER,
];

export const getDeactivationRuleFields = (values: Object, isEdit: boolean = false) => {
  const { trigger } = values;

  const triggerField = {
    isRequired: true,
    disabled: isEdit,
    type: 'reactSelect',
    options: triggerOptions,
    shouldCustomChange: true,
    label: ['titles:trigger', 'Trigger'],
    fieldName: GC.FIELD_CARRIER_DEACTIVATION_RULE_TRIGGER,
    inputWrapperStyles: { ...inputWrapperStyles, zIndex: 11 },
  };

  if (G.isNilOrEmpty(trigger)) return R.of(Array, triggerField);

  if (R.equals(trigger, GC.CARRIER_DEACTIVATION_RULE_TRIGGER_INSURANCE_EXPIRATION)) {
    return [
      triggerField,
      {
        type: 'text',
        isRequired: true,
        inputWrapperStyles,
        label: ['titles:deactivation-days', 'Deactivation Days'],
        fieldName: GC.FIELD_CARRIER_DEACTIVATION_RULE_DEACTIVATION_DAYS,
      },
      {
        isMulti: true,
        type: 'reactSelect',
        options: 'insuranceTypeOptions',
        label: ['titles:insurance-types', 'Insurance Types'],
        inputWrapperStyles: { ...inputWrapperStyles, zIndex: 10 },
        fieldName: GC.FIELD_CARRIER_DEACTIVATION_RULE_INSURANCE_TYPE_GUIDS,
      },
    ];
  }

  const commonFields = [
    {
      type: 'text',
      inputWrapperStyles,
      label: ['titles:minimum-load-count', 'Minimum Load Count'],
      fieldName: GC.FIELD_CARRIER_DEACTIVATION_RULE_MINIMUM_LOAD_COUNT,
    },
    {
      type: 'text',
      inputWrapperStyles,
      label: ['titles:period-in-days', 'Period In Days'],
      fieldName: GC.FIELD_CARRIER_DEACTIVATION_RULE_PERIOD_IN_DAYS,
    },
  ];

  if (R.includes(trigger, percentTriggers)) {
    return [
      triggerField,
      ...commonFields,
      {
        type: 'text',
        isRequired: true,
        inputWrapperStyles,
        label: ['titles:percent', 'Percent'],
        fieldName: GC.FIELD_CARRIER_DEACTIVATION_RULE_PERCENT,
      },
    ];
  }

  return [
    triggerField,
    ...commonFields,
    {
      type: 'text',
      isRequired: true,
      inputWrapperStyles,
      label: ['titles:average-hours', 'Average Hours'],
      fieldName: GC.FIELD_CARRIER_DEACTIVATION_RULE_AVERAGE_HOURS,
    },
  ];
};

const insuranceExpirationValidationSchema = {
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_TRIGGER]: G.yupStringRequired,
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_INSURANCE_TYPE_GUIDS]: G.yupArrayRequired,
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_DEACTIVATION_DAYS]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(0, G.getShouldBeFromToLocaleTxt(0, 365))
    .max(365, G.getShouldBeFromToLocaleTxt(0, 365)),
};

const commonValidationSchema = {
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_TRIGGER]: G.yupStringRequired,
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_MINIMUM_LOAD_COUNT]: Yup.number()
    .nullable(true)
    .notRequired()
    .min(0, G.getShouldBeFromToLocaleTxt(0, 1000))
    .max(1000, G.getShouldBeFromToLocaleTxt(0, 1000)),
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_PERIOD_IN_DAYS]: Yup.number()
    .nullable(true)
    .notRequired()
    .min(0, G.getShouldBeFromToLocaleTxt(0, 365))
    .max(365, G.getShouldBeFromToLocaleTxt(0, 365)),
};

export const getDeactivationRuleValidationSchema = ({ trigger }: Object) => {
  if (R.equals(trigger, GC.CARRIER_DEACTIVATION_RULE_TRIGGER_INSURANCE_EXPIRATION)) {
    return insuranceExpirationValidationSchema;
  }

  if (R.includes(trigger, percentTriggers)) {
    return {
      ...commonValidationSchema,
      [GC.FIELD_CARRIER_DEACTIVATION_RULE_PERCENT]: Yup.number()
        .nullable(true)
        .required(G.getRequiredLocaleTxt())
        .min(0, G.getShouldBeFromToLocaleTxt(0, 100))
        .max(100, G.getShouldBeFromToLocaleTxt(0, 100)),
    };
  }

  return {
    ...commonValidationSchema,
    [GC.FIELD_CARRIER_DEACTIVATION_RULE_AVERAGE_HOURS]: Yup.number()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .min(0, G.getShouldBeFromToLocaleTxt(0, 672))
      .max(672, G.getShouldBeFromToLocaleTxt(0, 672)),
  };
};

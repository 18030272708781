import * as Yup from 'yup';
import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 370,
};

const defaultValues = {
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_URL]: null,
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_FILE]: null,
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_DESCRIPTION]: null,
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_DOCUMENT_NAME]: null,
};

const fields = (initialValues: Object) => [
  {
    type: 'text',
    label: ['titles:document-name'],
    fieldName: GC.FIELD_DOCUMENT_DOCUMENT_NAME,
    customDisabledFunction: 'handleDisableFileName',
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: ({ values }: Object) =>
        G.setFieldsGroupDisplay(values, GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_URL, initialValues),
    },
  },
  {
    type: 'text',
    isRequired: true,
    label: ['titles:url'],
    fieldName: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_URL,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: ({ values }: Object) =>
        G.setFieldsGroupDisplay(values, GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_FILE, initialValues),
    },
  },
  {
    type: 'file',
    width: '100%',
    isRequired: true,
    label: ['titles:upload-file'],
    fieldName: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_FILE,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      flexWrap: 'wrap',
      display: ({ values }: Object) =>
        G.setFieldsGroupDisplay(values, GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_URL, initialValues),
    },
  },
  {
    type: 'textarea',
    inputWrapperStyles,
    label: ['titles:description'],
    fieldName: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_DESCRIPTION,
  },
];

const validationSchema = Yup.lazy(({ url, file }: Object) => {
  let schema = {
    [GC.FIELD_DOCUMENT_URL]: Yup.string().nullable(true).url(G.getShouldBeUrlLocaleTxt()),
    [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_DOCUMENT_NAME]: Yup.string()
      .nullable(true)
      .max(255, G.getShouldBeFromToLocaleTxt(0, 255)),
  };

  if (G.isAllNilOrEmpty([url, file])) {
    schema = {
      ...schema,
      [GC.FIELD_DOCUMENT_URL]: Yup.string().nullable(true).required(G.getShouldBeFileOrUrlLocaleTxt()),
      [GC.FIELD_DOCUMENT_UPLOAD]: Yup.string().nullable(true).required(G.getShouldBeFileOrUrlLocaleTxt()),
    };
  }

  return Yup.object().shape(schema);
});

const enhance = compose(
  withFormik({
    validationSchema,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultValues,
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const { endpoint, submitAction, primaryObjectGuid } = props;

      const data = R.compose(
        R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, primaryObjectGuid),
        G.mapObjectEmptyStringFieldsToNull,
      )(values);

      submitAction({ data, endpoint });
    },
  }),
  withHandlers({
    handleDisableFileName: ({ values, initialValues }: Object) => () => R.and(
      G.isNotNilAndNotEmpty(R.prop(GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_FILE, values)),
      R.equals(
        R.prop(GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_FILE, values),
        R.prop(GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_FILE, initialValues),
      ),
    ),
  }),
  pure,
);

const OnboardingPackageDocumentForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      fieldsWrapperStyles={{ pt: 11 }}
      fields={fields(props.initialValues)}
      handlers={{ handleDisableFileName: props.handleDisableFileName }}
      fileName={R.pathOr(
        G.getWindowLocale('titles:select-file', 'Select File'),
        ['values', GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_FILE],
        props,
      )}
    />
    <FormFooter />
  </form>
);

export default enhance(OnboardingPackageDocumentForm);

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  renderNothing,
} from 'react-recompose';
// components
import { TabsMuiEnhanced } from '../../../components';
// features
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, Flex, Text } from '../../../ui';
// feature dashboards
import DashboardFilters from './dashboard-filters';
import {
  DASHBOARD_TYPE_FLEET_GENERAL,
  DASHBOARD_TYPE_FLEET_TRAILER,
  DASHBOARD_TYPE_FLEET_ACCOUNTING,
} from '../constants';
import {
  makeSelectFilters,
  makeSelectDashboardType,
} from '../selectors';
import {
  setDateFiltersToStore,
  setDashboardTypeToStore,
  setEnterpriseFilterToStore,
} from '../actions';
//////////////////////////////////////////////////

const getTabs = () => [
  {
    value: DASHBOARD_TYPE_FLEET_GENERAL,
    text: G.getWindowLocale('titles:general', 'General'),
  },
  {
    value: DASHBOARD_TYPE_FLEET_ACCOUNTING,
    text: G.getWindowLocale('titles:accounting', 'Accounting'),
  },
  {
    value: DASHBOARD_TYPE_FLEET_TRAILER,
    text: G.getWindowLocale('titles:trailers', 'Trailers'),
  },
];

const Header = (props: Object) => {
  const {
    filters,
    noTitle,
    dashboardType,
    setDateFiltersToStore,
    setDashboardTypeToStore,
    setEnterpriseFilterToStore,
  } = props;

  const minWidth = G.ifElse(R.equals(dashboardType, DASHBOARD_TYPE_FLEET_TRAILER), 2150, 1635);

  return (
    <Box
      px={20}
      pt={20}
      bg='white'
      minWidth={minWidth}
    >
      { R.not(noTitle) && (
        <Text
          fontSize={22}
          fontWeight='bold'
          color='dark.mainDark'
        >
          {G.getWindowLocale('titles:dashboard', 'Dashboard')}
        </Text>
      )}
      <Flex alignItems='end' justifyContent='space-between'>
        <TabsMuiEnhanced
          tabs={getTabs()}
          defaultActiveMuiTab={dashboardType}
          handleClickMuiTab={setDashboardTypeToStore}
        />
        <DashboardFilters
          filters={filters}
          setDateFiltersToStore={setDateFiltersToStore}
          setEnterpriseFilterToStore={setEnterpriseFilterToStore}
        />
      </Flex>
    </Box>
  );
};

const enhance = compose(
  branch(
    ({ initialDataLoaded }: Object) => R.not(initialDataLoaded),
    renderNothing,
  ),
  pure,
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  filters: makeSelectFilters(state),
  dashboardType: makeSelectDashboardType(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  setDateFiltersToStore,
  setDashboardTypeToStore,
  setEnterpriseFilterToStore,
})(enhance(Header));

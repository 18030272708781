import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature geo-fencing-location
import { locationSettings } from './settings/location-settings';
//////////////////////////////////////////////////

const IDLE_MODE = 'IDLE';
const EDIT_MODE = 'EDIT';
const VIEW_MODE = 'VIEW';

const filterProps = R.mapObjIndexed(
  ({ name, options, collection, placeholder, filterType = 'string' }: Object, value: string) => {
    let optionsToUse = options;

    if (G.isFunction(options)) optionsToUse = options();

    return ({
      value,
      collection,
      options: optionsToUse,
      [GC.FIELD_TYPE]: filterType,
      placeholder: G.ifElse(
        R.and(R.equals(filterType, 'selectMultiple'), R.isNil(placeholder)),
        { text: 'Choose a status', key: 'titles:choose-a-status' },
        placeholder,
      ),
      [GC.FIELD_NAME]: G.ifElse(
        G.isArray(name),
        // eslint-disable-next-line max-len
        `${G.getWindowLocale(name[0])}: ${G.getWindowLocale(name[1])}${G.ifElse(R.gt(R.length(name), 2), `: ${G.getWindowLocale(name[2])}`, '')}`,
        G.getWindowLocale(name, '...'),
      ),
    });
  },
  locationSettings,
);

const prepareLocationForSubmitting = (data: object) => R.compose(
  R.assoc(GC.FIELD_ADDRESS, R.prop(GC.FIELD_ADDRESS_1, data)),
  R.assoc(GC.FIELD_FLEET_LOCATION_RADIUS_UOM, G.getFootOrMeterUomByUomSystem()),
  R.pick([
    GC.FIELD_ZIP,
    GC.FIELD_CITY,
    GC.FIELD_NAME,
    GC.FIELD_TYPE,
    GC.FIELD_STATE,
    GC.FIELD_COUNTRY,
    GC.FIELD_LATITUDE,
    GC.FIELD_LONGITUDE,
    GC.FIELD_FLEET_LOCATION_RADIUS,
  ]),
)(data);

const remapLocationValues = (data: Object) => R.mergeLeft({
  [GC.FIELD_ADDRESS_1]: R.propOr('', GC.FIELD_ADDRESS, data),
  [GC.FIELD_TYPE]: R.pathOr(null, [GC.FIELD_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], data),
}, data);

const getDistanceUomLocale = () => {
  if (G.isMetricUomSystem()) return G.getWindowLocale('titles:meters', 'Meters');

  return G.getWindowLocale('titles:feet', 'Feet');
};

const coefficient = 3.28084;

const metersToFeet = (meters: number) => G.toFixed(meters * coefficient, 0);

const feetToMeters = (feet: number) => G.toFixed(feet / coefficient, 0);

const degCoefficient = 110574;

const differenceByRadius = (radius: number) => G.ifElse(G.isNotNil(radius), radius / degCoefficient, 0.1);

const convertLocationWithRadius = (location: Object) => {
  const radius = R.prop(GC.FIELD_FLEET_LOCATION_RADIUS, location);
  const radiusUom = R.prop(GC.FIELD_FLEET_LOCATION_RADIUS_UOM, location);

  const isMetric = G.isMetricUomSystem();
  const currentUom = G.ifElse(isMetric, GC.UOM_METER, GC.UOM_FOOT);

  if (R.equals(currentUom, radiusUom)) return location;

  const currentLocation = R.assoc([GC.FIELD_FLEET_LOCATION_RADIUS_UOM], currentUom, location);

  if (isMetric) {
    return R.assoc([GC.FIELD_FLEET_LOCATION_RADIUS], feetToMeters(radius), currentLocation);
  }

  return R.assoc([GC.FIELD_FLEET_LOCATION_RADIUS], metersToFeet(radius), currentLocation);
};

export {
  IDLE_MODE,
  EDIT_MODE,
  VIEW_MODE,
  filterProps,
  metersToFeet,
  feetToMeters,
  differenceByRadius,
  remapLocationValues,
  getDistanceUomLocale,
  convertLocationWithRadius,
  prepareLocationForSubmitting,
};

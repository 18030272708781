import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withState, withPropsOnChange } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature config
import { serviceTypeOptionsMap } from '../constants';
import {
  serviceTypeMappingFieldSettings,
  defaultServiceTypeMappingFields,
  serviceTypeMappingValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultServiceTypeMappingFields,
      initialValues,
    ),
    validationSchema: serviceTypeMappingValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
  }),
  withState('prevProps', 'setPrevProps', {}),
  withPropsOnChange(
    (props: Object, nextProps: Object) => {
      const currentIntegrationType = R.path(['values', GC.FIELD_INTEGRATION_TYPE], props);
      const nextIntegrationType = R.path(['values', GC.FIELD_INTEGRATION_TYPE], nextProps);

      return G.notEquals(currentIntegrationType, nextIntegrationType);
    },
    (props: Object) => {
      const { values, serviceTypeConfigOptions } = props;

      const integrationType = G.getPropFromObject(GC.FIELD_INTEGRATION_TYPE, values);

      const serviceTypeOptions = R.compose(
        R.values,
        R.mapObjIndexed((label: string, value: string) => ({ value, label })),
        R.pathOr([], [integrationType]),
      )(serviceTypeOptionsMap);

      return {
        optionsForSelect: { serviceTypeOptions, serviceTypeConfigOptions },
      };
    },
  ),
  pure,
);

const ServiceTypeMappingForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...props.optionsForSelect}
      {...G.getFormikProps(props)}
      fieldsWrapperStyles={{ pt: 11 }}
      fields={serviceTypeMappingFieldSettings}
      handlers={{ disableIntegrationType: () => props.isEditMode }}
    />
    <FormFooter />
  </form>
);

export default enhance(ServiceTypeMappingForm);

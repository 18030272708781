export const tableSettings = {
  maxHeight: 320,
  cellFontSize: 12,
  titleFontSize: 12,
  titleRowHeight: 50,
  allowEditBtn: true,
  useMainColors: true,
  allowSelectAll: false,
  checkBoxCellWidth: 54,
  allowSelectItems: false,
  emptyListPadding: '10px 0',
  tableRowHeight: 'max-content',
  checkBoxCellJustifyContent: 'flex-start',
};

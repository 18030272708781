import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature configs
import {
  marginViolationRuleFieldSettings,
  defaultMarginViolationRuleFields,
  getMarginViolationRuleValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const getOptions = ({ initialValues, transportationModes, marginViolationRuleList }: Object) => R.map(
  (mode: Object) => R.assoc(
    'disabled',
    G.isNotNil(R.find(
      ({ transportationModeGuids }: Object) => R.includes(
        G.getPropFromObject(GC.FIELD_VALUE, mode),
        transportationModeGuids,
      ),
      R.compose(
        R.values,
        R.omit(G.ifElse(
          G.isNotNilAndNotEmpty(initialValues),
          R.of(Array, G.getGuidFromObject(initialValues)),
          [],
        )),
        G.indexByGuid,
      )(marginViolationRuleList),
    )),
    mode,
  ),
  transportationModes,
);

const enhance = compose(
  withFormik({
    validationSchema: () => Yup.lazy((values: Object) => (
      Yup.object().shape(getMarginViolationRuleValidationSchema(values))
    )),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultMarginViolationRuleFields,
      initialValues,
    ),
  }),
  pure,
);

const MarginViolationRuleForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      transportationModes={getOptions(props)}
      fields={marginViolationRuleFieldSettings}
      fieldsWrapperStyles={{ pt: 11, flexDirection: 'column' }}
    />
    <FormFooter />
  </form>
);

export default enhance(MarginViolationRuleForm);

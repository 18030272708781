import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  lifecycle,
  withState,
  withHandlers,
  renderNothing,
  withPropsOnChange,
} from 'react-recompose';
// components
import { PageTabs } from '../../../../components/page-tabs';
import { PageTitle } from '../../../../components/page-title';
import { ConfirmComponent } from '../../../../components/confirm';
import { openModal, closeModal } from '../../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../../components/loader/actions';
import PopupWithReportAndQuickFilters from '../../../../components/filter/report-and-quick-filters';
// features
import Audit2 from '../../../audit2';
import PC from '../../../permission/role-permission';
import FuelCardListComponent from '../../../fuel-cards';
import TollChargesListComponent from '../../../toll-charges';
import { cleanQuickFilter } from '../../../report-format/actions';
import { changeUserPasswordRequest } from '../../../profile/actions';
import { visitFuelCardsOnFleetPage } from '../../../fuel-cards/actions';
import { visitTollChargesOnFleetPage } from '../../../toll-charges/actions';
import { makeSelectQuickFilteredParams } from '../../../report-format/selectors';
import { makeSelectInitialDataLoadedStatus } from '../../../permission/selectors';
import { makeSelectExpandedContainerOpened } from '../../../expanded-container/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// forms
import { ChangePasswordForm } from '../../../../forms/forms/change-password-form';
// icons
import * as I from '../../../../svgs';
// ui
import { FixedFlex, CancelButton, PageHeaderWrapper } from '../../../../ui';
// hocs
import { withAsyncGetDispatchingGroupList } from '../../../../hocs';
// feature fleet
import * as C from '../../constants';
import { SimpleWrapper } from '../../ui';
import LatestKnownLocationHeaderTitle from '../../components/latest-known-location-header-title';
// feature fleet/driver
import AssignPanel from './assign-panel';
import { EditDriverWrapper } from '../ui';
import EditDriverDocs from './edit-docs-tab';
import { EDIT_DRIVER_DETAIL } from '../constants';
import EditDriverDetails from './edit-details-tab';
import AdvancePaymentTab from './advance-payment-tab';
import EditDriverCompensation from './edit-compensation-tab';
import { getCompFilterParams } from '../settings/filter-params';
import { withComplexInitialValues } from '../../hocs/complex-initial-values';
import {
  makeSelectFormErrors,
  makeSelectAssignInfo,
  makeSelectUsersGeneral,
  makeSelectDriverConfigs,
  makeSelectBranchConfigs,
  makeSelectInitialValues,
  makeSelectCollapsedGroup,
  makeSelectIntegrationList,
  makeSelectDriverFormValues,
  makeSelectCurrentDriverGuid,
  makeSelectLatestKnownLocationList,
} from '../selectors';
import {
  getDriverDocRequest,
  toggleDriverFormGroup,
  getDriverCitizenRequest,
  getDriverLicenseRequest,
  getDriverDailyHoursRequest,
  getDriverDailyHoursSuccess,
  getAdvancePaymentListRequest,
  getDriverAccessorialsRequest,
  getDriverCertificationRequest,
  getDriverCompensationsRequest,
  validateBeforeChangeDriverTab,
  getFuelCardListByDriverRequest,
  getDeductionSettingsListRequest,
  changeAssignedToDivisionRequest,
  getPayrollAssessorialListRequest,
  getPayrollCompensationRateRequest,
  getMonthlyPayrollDeductionRequest,
} from '../actions';
//////////////////////////////////////////////////

const renderTitle = ({ initialValues }: Object) => {
  const firstName = R.pathOr('', [GC.FIELD_FIRST_NAME], initialValues);
  const lastName = R.pathOr('', [GC.FIELD_LAST_NAME], initialValues);
  const nickName = R.pathOr('', [GC.FIELD_NICK_NAME], initialValues);

  const prefix = G.getWindowLocale('titles:fleet-driver', 'Fleet: Driver');

  if (G.isNilOrEmpty(firstName)) return `${prefix}`;

  return `${prefix} ${firstName} ${lastName} ${nickName}`;
};

const enhance = compose(
  withAsyncGetDispatchingGroupList,
  withPropsOnChange(['asyncGetDispatchingGroupList'], (props: Object) => {
    const { asyncGetDispatchingGroupList } = props;
    const options = R.map((item: Object) => {
      const { name } = item;
      const value = G.getGuidFromObject(item);

      return { value, label: name };
    }, asyncGetDispatchingGroupList);

    return {
      'dispatchingGroupOptions': options,
    };
  }),
  reduxForm({
    form: EDIT_DRIVER_DETAIL,
    enableReinitialize: true,
  }),
  withState('initialValues', 'setInitialValues', ({ initialValues }: Object) => initialValues),
  withPropsOnChange(
    ['complexInitialValues'],
    (props: Object) => (
      props.setInitialValues(props.complexInitialValues)
    ),
  ),
  withHandlers({
    handleCleanFilter: (props: Object) => () => {
      props.cleanQuickFilter();
      props.getDriverCompensationsRequest();
    },
  }),
  withHandlers({
    handleToggleFormGroup: ({ toggleDriverFormGroup }: Object) => (fieldName: string) =>
      toggleDriverFormGroup(fieldName),
    handleTabNav: (props: Object) => () => {
      const driverGuid = R.path(['initialValues', GC.FIELD_GUID], props);
      const commonTabOptions = {
        touch: props.touch,
        fleet: C.FLEET_DRIVER,
        formName: EDIT_DRIVER_DETAIL,
        tabName: props.stateActiveTab,
      };
      const detailTab = (tabName: string) => ({
        ...commonTabOptions,
        tabAction: () => {
          props.handleSetActiveTab(tabName);
          props.cleanQuickFilter();
        },
      });
      const docsTab = (tabName: string) => ({
        ...commonTabOptions,
        tabAction: () => {
          props.cleanQuickFilter();
          props.handleSetActiveTab(tabName);
          props.getDriverDocRequest(driverGuid);
          props.getDriverLicenseRequest(driverGuid);
          props.getDriverCitizenRequest(driverGuid);
          props.getDriverCertificationRequest(driverGuid);
        },
      });
      const paymentTab = (tabName: string) => ({
        ...commonTabOptions,
        tabAction: () => {
          props.handleSetActiveTab(tabName);
          props.getDriverDailyHoursSuccess([]);
          props.getDriverAccessorialsRequest();
          props.getDriverCompensationsRequest();
          props.getDriverDailyHoursRequest(driverGuid);
          props.getDeductionSettingsListRequest(driverGuid);
          props.getPayrollAssessorialListRequest(driverGuid);
          props.getMonthlyPayrollDeductionRequest(driverGuid);
          props.getPayrollCompensationRateRequest(driverGuid);
        },
      });
      const fuelCardsTab = (tabName: string) => ({
        ...commonTabOptions,
        tabAction: () => {
          props.handleSetActiveTab(tabName);
          props.visitFuelCardsOnFleetPage({ driverGuid, [GC.FIELD_TRUCK_GUID]: null });
        },
      });
      const tollChargesTab = (tabName: string) => ({
        ...commonTabOptions,
        tabAction: () => {
          props.handleSetActiveTab(tabName);
          props.visitTollChargesOnFleetPage({ driverGuid, [GC.FIELD_TRUCK_GUID]: null });
        },
      });
      const iconColor = 'icons.iconColor';
      const iconColorGrey = 'icons.iconColorGrey';
      let tabs = [
        {
          frontIcon: G.ifElse(
            R.equals(props.stateActiveTab, C.FLEET_TAB_DETAILS),
            I.searchDocument(G.getTheme(iconColor)),
            I.searchDocument(G.getTheme(iconColorGrey)),
          ),
          tabName: C.FLEET_TAB_DETAILS,
          label: G.getWindowLocale('titles:general-details', 'General Details'),
          action: (tabName: string) => (props.validateBeforeChangeDriverTab(detailTab(tabName))),
        },
        {
          frontIcon: G.ifElse(
            R.equals(props.stateActiveTab, C.FLEET_TAB_DOCUMENTS),
            I.documents(G.getTheme(iconColor)),
            I.documents(G.getTheme(iconColorGrey)),
          ),
          tabName: C.FLEET_TAB_DOCUMENTS,
          label: G.getWindowLocale('titles:license-documents', 'License/Documents'),
          action: (tabName: string) => props.validateBeforeChangeDriverTab(docsTab(tabName)),
        },
        {
          frontIcon: G.ifElse(
            R.equals(props.stateActiveTab, C.FLEET_TAB_PAYMENT),
            I.payment(G.getTheme(iconColor)),
            I.payment(G.getTheme(iconColorGrey)),
          ),
          tabName: C.FLEET_TAB_PAYMENT,
          label: G.getWindowLocale('titles:rating-engine', 'Rating Engine'),
          action: (tabName: string) => props.validateBeforeChangeDriverTab(paymentTab(tabName)),
        },
        {
          tabName: C.FLEET_TAB_FUEL_CARDS,
          label: G.getWindowLocale('titles:fuel-transactions', 'Fuel Transactions'),
          action: (tabName: string) => props.validateBeforeChangeDriverTab(fuelCardsTab(tabName)),
          frontIcon: G.ifElse(
            R.equals(props.stateActiveTab, C.FLEET_TAB_FUEL_CARDS),
            I.fuel(G.getTheme(iconColor)),
            I.fuel(G.getTheme(iconColorGrey)),
          ),
        },
        {
          tabName: C.FLEET_TAB_TOLL_CHARGES,
          permissions: [PC.TOLL_CHARGE_READ, PC.TOLL_CHARGE_WRITE],
          label: G.getWindowLocale('titles:toll-charges', 'Toll Charges'),
          action: (tabName: string) => props.validateBeforeChangeDriverTab(tollChargesTab(tabName)),
          frontIcon: G.ifElse(
            R.equals(props.stateActiveTab, C.FLEET_TAB_TOLL_CHARGES),
            I.tollRoad(G.getTheme(iconColor)),
            I.tollRoad(G.getTheme(iconColorGrey)),
          ),
        },
      ];
      if (G.isTrue(G.showAdvancePayment())) {
        const advancePaymentTabAction = (tabName: string) => ({
          ...commonTabOptions,
          tabAction: () => {
            props.handleSetActiveTab(tabName);
            props.getAdvancePaymentListRequest(driverGuid);
          },
        });
        const advancePaymentTab = {
          tabName: C.FLEET_TAB_ADVANCE_PAYMENT,
          label: G.getWindowLocale('titles:advance-payment', 'Advance Payment'),
          action: (tabName: string) => props.validateBeforeChangeDriverTab(advancePaymentTabAction(tabName)),
          frontIcon: I.check(
            G.getThemeByCond(R.equals(props.stateActiveTab, C.FLEET_TAB_ADVANCE_PAYMENT), iconColor, iconColorGrey),
          ),
        };
        tabs = R.append(advancePaymentTab, tabs);
      }

      return tabs;
    },
    handleChangePassword: (props: Object) => () => {
      const { openModal, closeModal, initialValues, changeUserPasswordRequest } = props;

      const component = (
        <ChangePasswordForm
          closeModal={closeModal}
          changePasswordRequest={
            (values: Object) => (
              changeUserPasswordRequest(R.assoc('userGuid', initialValues.userGuid, values))
            )
          }
        />
      );

      const modal = G.getDefaultModalOptions(
        component,
        G.getWindowLocale('titles:create-new-password', 'Create New Password'),
      );

      openModal(modal);
    },
    handleClickCancel: (props: Object) => () => {
      const { closeModal, expandedContainerOpened, closeExpandedContainer } = props;

      if (expandedContainerOpened) return closeExpandedContainer();

      const component = (
        <ConfirmComponent
          textLocale={G.getWindowLocale(
            'messages:confirmation-leave-page',
            'All changes will be lost. Are you sure you want leave page?',
          )}
        />
      );
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:leave', 'Leave'),
              action: () => {
                closeModal();

                G.historyGoBack();
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleCancel: ({ expandedContainerOpened, closeExpandedContainer }: Object) => () => {
      if (expandedContainerOpened) return closeExpandedContainer();

      G.historyGoBack();
    },
  }),
  branch(
    (props: Object) => (
      R.and(
        R.not(props.initialDataLoaded),
        G.isNilOrEmpty(props.currentDriverGuid),
      )
    ),
    renderNothing,
  ),
  lifecycle({
    componentDidMount() {
      this.props.getAsyncGetDispatchingGroupList();
    },
  }),
  pure,
);

const renderTab = (props: Object) => {
  const auditProps = {
    useFullScreen: true,
    requestOptions: {
      [GC.FIELD_TYPE]: GC.AUDIT_TYPE_DRIVER,
      [GC.FIELD_OBJECT_GUID]: R.path(['initialValues', GC.FIELD_GUID], props),
    },
  };

  const tabs = {
    audit: () => <Audit2 {...auditProps} />,
    [C.FLEET_TAB_DETAILS]: () => (
      <EditDriverDetails
        {...props}
        branchGuid={R.path(['complexInitialValues', GC.FIELD_BRANCH_GUID], props)}
      />
    ),
    [C.FLEET_TAB_DOCUMENTS]: () => <EditDriverDocs {...props} />,
    [C.FLEET_TAB_PAYMENT]: () => (
      <EditDriverCompensation
        {...props}
        branchGuid={R.path(['initialValues', GC.FIELD_BRANCH_GUID], props)}
      />
    ),
    [C.FLEET_TAB_TOLL_CHARGES]: () => <TollChargesListComponent pl='0px' title={renderTitle(props)} />,
    [C.FLEET_TAB_FUEL_CARDS]: () => (
      <FuelCardListComponent
        pl='0px'
        pageActionsBottom={70}
        title={renderTitle(props)}
        fleetType={GC.FIELD_DRIVER}
        openedFromFleetProfile={true}
        primaryObjectGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
        driverLastName={R.path(['initialValues', GC.FIELD_LAST_NAME], props)}
        driverFirstName={R.path(['initialValues', GC.FIELD_FIRST_NAME], props)}
        assignedTruckGuid={R.pathOr(null, ['assignInfo', GC.SYSTEM_OBJECT_TRUCK, GC.FIELD_GUID], props)}
        defaultBranchCurrency={G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, props.branchConfigs)}
      />
    ),
    [C.FLEET_TAB_ADVANCE_PAYMENT]: () => (
      <AdvancePaymentTab
        openModal={props.openModal}
        closeModal={props.closeModal}
        formValues={props.formValues}
        handleToggleFormGroup={props.handleToggleFormGroup}
      />
    ),
  };

  return tabs[props.stateActiveTab]();
};

const PageHeader = (props: Object) => (
  <PageHeaderWrapper
    zI='13'
    aI='flex-end'
    position='relative'
    justify='flex-start'
    height='max-content'
  >
    <SimpleWrapper maxWidth='30%' minWidth='fit-content' whiteSpace='nowrap'>
      <PageTitle
        mr='32px'
        showStatus={true}
        title={renderTitle(props)}
        status={R.path(['formValues', GC.FIELD_DRIVER_EMPLOYED], props)}
      />
    </SimpleWrapper>
    <SimpleWrapper width='50%' maxWidth='50%' whiteSpace='nowrap'>
      {
        R.and(
          G.notContain(
            props.stateActiveTab,
            [
              C.FLEET_TAB_PAYMENT,
              C.FLEET_TAB_FUEL_CARDS,
              C.FLEET_TAB_ADVANCE_PAYMENT,
            ],
          ),
          G.isTrue(R.path(['formValues', GC.FIELD_DRIVER_EMPLOYED], props)),
        ) && <AssignPanel {...props} />
      }
      {
        R.and(
          G.isNotNilAndNotEmpty(props.filterParams),
          R.equals(props.stateActiveTab, 'payment'),
        )
        && (
          <PopupWithReportAndQuickFilters
            version={2}
            filterCount={1}
            popperWithCount={true}
            filteredParams={props.filterParams}
            cleanFilter={props.handleCleanFilter}
            filterProps={G.setFilterByParams(props.refList, getCompFilterParams)}
          />
        )
      }
    </SimpleWrapper>
    <SimpleWrapper ml='auto' maxWidth='20%' jc='flex-end' whiteSpace='nowrap'>
      {
        G.isNotNilAndNotEmpty(props.latestKnownLocations)
        && (
          <LatestKnownLocationHeaderTitle
            latestKnownLocations={props.latestKnownLocations}
          />
        )
      }
    </SimpleWrapper>
  </PageHeaderWrapper>
);

const EditFormComponent = enhance((props: Object) => {
  const { handleCancel, handleTabNav, stateActiveTab, handleSetActiveTab, expandedContainerOpened } = props;

  return (
    <EditDriverWrapper
      mb={60}
      background={G.getTheme('colors.white')}
      pl={G.ifElse(expandedContainerOpened, 25)}
      height={G.ifElse(expandedContainerOpened, '100vh')}
      overflowY={G.ifElse(expandedContainerOpened, 'auto')}
      pb={G.ifElse(
        R.and(
          expandedContainerOpened,
          R.includes(stateActiveTab, [C.FLEET_TAB_DETAILS, C.FLEET_TAB_DOCUMENTS, C.FLEET_TAB_PAYMENT]),
        ),
        60,
      )}
    >
      <PageTabs
        zI={20}
        height={200}
        withAudit={true}
        isLabelExist={true}
        tabs={handleTabNav()}
        activeTab={stateActiveTab}
        setActiveTab={handleSetActiveTab}
      />
      {
        G.notContain(stateActiveTab, [C.FLEET_TAB_FUEL_CARDS, C.FLEET_TAB_TOLL_CHARGES]) &&
        <PageHeader {...props} />
      }
      {renderTab(props)}
      {
        G.notContain(stateActiveTab, [C.FLEET_TAB_DETAILS, C.FLEET_TAB_DOCUMENTS]) &&
        <FixedFlex
          pl={70}
          left='0px'
          height={60}
          bottom='0px'
          width='100%'
          zIndex={12}
          borderTop='1px solid'
          bg={G.getTheme('forms.actionsFooter.bgColor')}
          borderColor={G.getTheme('forms.actionsFooter.borderTopColor')}
        >
          <CancelButton width={100} onClick={() => handleCancel()}>
            {G.getWindowLocale('actions:cancel', 'Cancel')}
          </CancelButton>
        </FixedFlex>
      }
    </EditDriverWrapper>
  );
});

const EditFormWithComplexInitialValues = withComplexInitialValues((props: Object) => (
  <EditFormComponent {...props} initialValues={props.complexInitialValues} />
));

const mapStateToProps = (state: Object) => createStructuredSelector({
  assignInfo: makeSelectAssignInfo(state),
  usersGeneral: makeSelectUsersGeneral(state),
  initialValues: makeSelectInitialValues(state),
  branchConfigs: makeSelectBranchConfigs(state),
  driverConfigs: makeSelectDriverConfigs(state),
  collapsedGroup: makeSelectCollapsedGroup(state),
  integrationList: makeSelectIntegrationList(state),
  filterParams: makeSelectQuickFilteredParams(state),
  currentDriverGuid: makeSelectCurrentDriverGuid(state),
  errors: makeSelectFormErrors(state, EDIT_DRIVER_DETAIL),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  latestKnownLocations: makeSelectLatestKnownLocationList(state),
  expandedContainerOpened: makeSelectExpandedContainerOpened(state),
  formValues: makeSelectDriverFormValues(state, EDIT_DRIVER_DETAIL),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  openLoader,
  closeLoader,
  cleanQuickFilter,
  getDriverDocRequest,
  toggleDriverFormGroup,
  getDriverCitizenRequest,
  getDriverLicenseRequest,
  visitFuelCardsOnFleetPage,
  changeUserPasswordRequest,
  getDriverDailyHoursRequest,
  getDriverDailyHoursSuccess,
  visitTollChargesOnFleetPage,
  getDriverAccessorialsRequest,
  getAdvancePaymentListRequest,
  getDriverCertificationRequest,
  getDriverCompensationsRequest,
  validateBeforeChangeDriverTab,
  getFuelCardListByDriverRequest,
  changeAssignedToDivisionRequest,
  getDeductionSettingsListRequest,
  getPayrollAssessorialListRequest,
  getMonthlyPayrollDeductionRequest,
  getPayrollCompensationRateRequest,
})(EditFormWithComplexInitialValues);

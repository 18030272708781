import styled, { css, keyframes } from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Flex, Text } from '../../ui';
//////////////////////////////////////////////////

const pulseScale = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const whiteColor = G.getTheme('colors.white');
const softGreenColor = G.getTheme('colors.softGreen');
const mainRedColor = G.getTheme('colors.dark.mainRed');

const pulseBackground = (pulseColor: string) => keyframes`
  0% {
    background-color: ${whiteColor};
  }
  50% {
    background-color: ${pulseColor}20;
  }
  100% {
    background-color: ${whiteColor};
  }
`;

const VehicleComponentCircle = styled(Flex)`
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-width: 4px;
  border-radius: 50%;
  position: absolute;
  align-items: center;
  border-style: solid;
  pointer-events: all;
  justify-content: center;
  background-clip: padding-box;

  ${({ flip }: Object) => flip && css`
    & {
      transform: scaleX(-1);
    }
  `}

  ${({ passed }: Object) => passed && css`
    & {
      color: ${() => whiteColor};
      background-color: ${() => softGreenColor};
      border-color: ${() => `${softGreenColor}80`};
    }
  `}

  ${({ failed }: Object) => failed && css`
    & {
      color: ${() => whiteColor};
      background-color: ${() => mainRedColor};
      border-color: ${() => `${mainRedColor}80`};
    }
  `}

  ${({ animate }: Object) => animate && css`
    & {
      animation-delay: 0s;
      animation-duration: 0.4s;
      animation-fill-mode: both;
      animation-iteration-count: 3;
      animation-name: ${pulseScale};
      animation-timing-function: ease-in-out;
    }
  `}
`;

const SectionComponentUI = styled(Flex)`
  gap: 12px;
  width: 100%;
  padding: 8px 0;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid ${() => G.getTheme('colors.borderGray')};

  ${({ animate, failed }: Object) => animate && css`
    & {
      animation-delay: 0;
      animation-duration: 0.4s;
      animation-fill-mode: both;
      animation-iteration-count: 3;
      animation-timing-function: ease-in-out;
      animation-name: ${pulseBackground(
        G.ifElse(
          failed,
          `${mainRedColor}`,
          `${softGreenColor}`,
        ))};
    }
  `}
`;

const ComponentStatusUI = styled(Text)`
  font-size: 14px;
  font-weight: bold;

  ${({ passed }: Object) => passed && css`
    & {
      color: ${() => softGreenColor};
    }
  `}

  ${({ failed }: Object) => failed && css`
    & {
      color: ${() => mainRedColor};
    }
  `}
`;

export {
  ComponentStatusUI,
  SectionComponentUI,
  VehicleComponentCircle,
};


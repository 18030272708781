import { connect } from 'react-redux';
import React, { useCallback } from 'react';
// components
import { ActionBox } from '../../../components/action-box';
import { openModal } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box } from '../../../ui';
//////////////////////////////////////////////////

const boxShadowColor = G.getTheme('colors.boxShadowGrey');

const PreviewModal = ({ filePublicLink, fileNotAvailable }: Object) => (
  <Box height='90vh'>
    {
      filePublicLink ?
        <img
          width='auto'
          height='100%'
          src={filePublicLink}
          alt={fileNotAvailable}
        /> :
        fileNotAvailable
    }
  </Box>
);

const ImagePreview = (props: Object) => {
  const {
    height,
    openModal,
    showOnAction,
    filePublicLink,
    documentFilename,
    thumbnailFilePublicLink,
  } = props;

  const fileNotAvailable = G.getWindowLocale('messages:file-not-available', 'The file is not available');

  const handleClick = useCallback(() => {
    const component = <PreviewModal filePublicLink={filePublicLink} fileNotAvailable={fileNotAvailable} />;

    const modal = {
      p: '0',
      component,
      options: {
        title: '',
        minWidth: 500,
        height: '90vh',
        outsideCloseButton: true,
      },
    };

    openModal(modal);
  }, []);

  const title = `${G.getWindowLocale('actions:click-to-view', 'Click To View')} ${documentFilename}`;

  return (
    <React.Fragment>
      { G.isNilOrEmpty(showOnAction) && (
        <Box cursor='pointer' height={height || 30} boxShadow={`0 0 8px 0 ${boxShadowColor}`}>
          <img
            width='auto'
            title={title}
            height={height || 30}
            onClick={handleClick}
            alt={fileNotAvailable}
            src={thumbnailFilePublicLink}
          />
        </Box>
      )}
      { G.isNotNilAndNotEmpty(showOnAction) && (
        <ActionBox
          text={showOnAction}
          action={handleClick}
        />
      )}
    </React.Fragment>
  );
};

export default connect(null, { openModal })(ImagePreview);

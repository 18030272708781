import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature config
import {
  terminalIntegrationFieldSettings,
  defaultTerminalIntegrationFields,
  terminalIntegrationValidationSchemaObject,
} from '../settings';
//////////////////////////////////////////////////

const veracoreFields = [
  GC.FIELD_INTEGRATION_TYPE,
  GC.FIELD_ENABLED,
  GC.FIELD_USERNAME_CAMEL_CASE,
  GC.FIELD_PASSWORD,
  GC.FIELD_DOMAIN,
  GC.FIELD_OWNER_ID,
  GC.FIELD_SYSTEM_ID,
];

const fieldsToPick = ({ integrationType }: Object) => {
  const map = {
    [GC.TERMINAL_INTEGRATION_TYPE_VERACORE]: veracoreFields,
  };

  return R.pathOr([], [integrationType], map);
};

const enhance = compose(
  withFormik({
    validationSchema: terminalIntegrationValidationSchemaObject,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultTerminalIntegrationFields,
      initialValues,
    ),
  }),
  pure,
);

const TerminalIntegrationForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...props.optionsForSelect}
      {...G.getFormikProps(props)}
      fieldsWrapperStyles={{ pt: 11, flexDirection: 'column' }}
      handlers={{ disableIntegrationType: () => props.isEditMode }}
      fields={R.values(R.pick(fieldsToPick(props.values), terminalIntegrationFieldSettings))}
    />
    <FormFooter />
  </form>
);

export default enhance(TerminalIntegrationForm);

import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// ui
import { Box } from '../../../../../ui';
// feature configs
import {
  makeSelectDataSourceList,
  makeSelectPopupFormValues,
  makeSelectIndexedDataSourceList,
} from '../../../selectors';
import {
  documentFields,
  getValidOptions,
  defaultDocumentFields,
  getDocumentFormatOptions,
  getPrintableSectionOptions,
  documentTemplateValidationSchemaObject,
} from '../../../settings/document-template-group';
//////////////////////////////////////////////////

export const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultDocumentFields,
      props.initialValues,
    ),
    validationSchema: Yup.object().shape(documentTemplateValidationSchemaObject),
    handleSubmit: (values: Object, { props }: Object) =>
      props.submitAction(G.mapObjectEmptyStringFieldsToNull(values)),
  }),
  withHandlers({
    handleChange: (props: Object) => (event: Object) => {
      const { values, setValues, setFieldValue } = props;

      const value = R.path(['currentTarget', GC.FIELD_VALUE], event);
      const fieldName = R.path(['currentTarget', GC.FIELD_NAME], event);

      if (R.equals(fieldName, GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DATA_SOURCE)) {
        const valuesToSet = {
          [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_FORMAT]: '',
          [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DATA_SOURCE]: value,
          [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_PRINTABLE_SECTION]: '',
        };

        return setValues(R.mergeRight(values, valuesToSet));
      } else if (R.equals(fieldName, GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_PRINTABLE_SECTION)) {
        return setValues(R.mergeRight(
          values,
          {
            [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_FORMAT]: '',
            [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_PRINTABLE_SECTION]: value,
          },
        ));
      }

      setFieldValue(fieldName, value);
    },
    handleDisableField: (props: Object) => (fieldName: Object) =>
      G.isNilOrEmpty(R.path(['values', fieldName], props)),
  }),
  pure,
);

const getCustomerList = (props: Object) => {
  const sortByName = R.sortBy(R.compose(R.toLower, R.prop('name')));

  return R.compose(
    G.prependEmptyLabelValueOption,
    sortByName,
    R.tail,
  )(R.pathOr([], ['customerBranchList'], props));
};

const DocumentConfigFormComponent = (props: Object) => {
  const { values, documentTypes, dataSourceList, indexedDataSourceList } = props;

  const optionsForSelect = {
    customerBranchList: getCustomerList(props),
    dataSourceList: getValidOptions(R.or(dataSourceList, [])),
    [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DOCUMENT_TYPE_GUID]: R.propOr(
      [],
      GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DOCUMENT_TYPE_GUID,
      documentTypes,
    ),
    [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_FORMAT]: getDocumentFormatOptions(
      values,
      indexedDataSourceList,
    ),
    [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_PRINTABLE_SECTION]: getPrintableSectionOptions(
      R.prop(GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DATA_SOURCE, values),
      indexedDataSourceList,
    ),
  };

  return (
    <form onSubmit={props.handleSubmit}>
      <Fieldset2
        {...optionsForSelect}
        {...G.getFormikProps(props)}
        fieldsGroupMargin={10}
        fields={documentFields}
        justifyContent='space-between'
        handleChange={props.handleChange}
        fieldsWrapperStyles={{ pt: 11, justifyContent: 'space-between' }}
        fileName={R.pathOr(
          G.getWindowLocale('titles:select-file', 'Select File'),
          ['initialValues', GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_FILE],
          props,
        )}
        handlers={{
          handleDisablePrintableSection: () =>
            props.handleDisableField(GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DATA_SOURCE),
          handleDocumentFormat: () =>
            props.handleDisableField(GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_PRINTABLE_SECTION),
        }}
      />
      <FormFooter />
    </form>
  );
};

const mapStateToProps = (state: Object) => ({
  formValues: makeSelectPopupFormValues()(state),
  dataSourceList: makeSelectDataSourceList()(state),
  indexedDataSourceList: makeSelectIndexedDataSourceList()(state),
});

export default connect(mapStateToProps)(enhance(DocumentConfigFormComponent));

import React from 'react';
import * as R from 'ramda';
import { withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { PopperComponent } from '../../../components/popper';
import { HighlightedText } from '../../../components/highlighted-text';
// features
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, AbsoluteBox, IconWrapper, RelativeFlex, scrollableContainerCss4px } from '../../../ui';
// utilities
import T from '../../../theme';
// feature available-driver
import { LoadActions } from './load-actions';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');
const lightBlueColor = G.getTheme('colors.light.blue');
const borderColor = G.getTheme('listActions.borderColor');
const mainLightColor = G.getTheme('colors.light.mainLight');
const lightYellowColor = G.getTheme('colors.light.yellow');
const stopRejectColor = G.getTheme('dispatchBoardPopper.stopRejectColor');
const stopSuccessColor = G.getTheme('dispatchBoardPopper.stopSuccessColor');

const getClosInfo = (clos: Array) => {
  if (G.isNilOrEmpty(clos)) return null;

  const string = G.createStringFromArrayWithProp(clos, ', ', GC.FIELD_BRANCH_BRANCH_NAME);

  if (G.isNilOrEmpty(string)) return null;

  return string;
};

const enhance = withHandlers({
  handleClickEditIcon: ({ openFixedPopup, handleOpenTelDetails, handleAddTelDriverRate }: Object) =>
    ({ currentTarget }: Object, entity: Object) => openFixedPopup({
      version: 2,
      position: 'right',
      el: currentTarget,
      content: (
        <LoadActions
          entity={entity}
          handleOpenTelDetails={handleOpenTelDetails}
          handleAddTelDriverRate={handleAddTelDriverRate}
        />
      ),
    }),
});

const UnassignedLoad = enhance((props: Object) => {
  const {
    clos,
    status,
    lastEvent,
    firstEvent,
    enterprise,
    handleClickEditIcon,
    handleOpenTelDetails,
    primaryReferenceValue,
    handleAddTelDriverRate,
  } = props;

  const closInfo = getClosInfo(clos);
  const statusBorderColor = R.path([GC.FIELD_STATUS, status], T);
  const branchName = R.pathOr('', [GC.FIELD_BRANCH_NAME], enterprise);
  const lastEventDate = G.getPropFromObject(GC.FIELD_LATE_DATE, lastEvent);
  const firstEventDate = G.getPropFromObject(GC.FIELD_EARLY_DATE, firstEvent);
  const lastEventLocation = G.getPropFromObject(GC.FIELD_LOCATION, lastEvent);
  const itemsCount = R.pathOr(0, [GC.FIELD_ITEMS_INFO, GC.FIELD_COUNT], props);
  const firstEventLocation = G.getPropFromObject(GC.FIELD_LOCATION, firstEvent);

  return (
    <Flex
      p='2px'
      mb={15}
      ml='0px'
      minHeight={60}
      alignItems='start'
      borderRadius='3px'
      border='1px solid'
      bg={mainLightColor}
      flexDirection='column'
      borderLeft='3px solid'
      justifyContent='space-between'
      borderLeftColor={statusBorderColor}
      boxShadow='0 0 10px 0 rgba(215, 215, 215, 0.5)'
      borderColor={G.getTheme('colors.light.darkGrey')}
    >
      <Box width='100%' height='100%' overflow='auto'>
        <Flex width='100%' overflow='auto' justifyContent='space-between'>
          <Flex overflow='auto' maxWidth='calc(100% - 80px)' css={scrollableContainerCss4px}>
            <Box
              mr={10}
              fontWeight={600}
              cursor='pointer'
              color={darkBlueColor}
              textDecoration='underline'
              onClick={handleOpenTelDetails}
              title={G.getWindowLocale('actions:go-to-tel', 'Go to TEL')}
            >
              <HighlightedText text={primaryReferenceValue} highlightBgColor={lightYellowColor} />
            </Box>
            <Box mr={10} fontWeight={600} color={darkBlueColor}>
              <HighlightedText text={branchName} highlightBgColor={lightYellowColor} />
            </Box>
            <Box mr='5px' fontSize={14} color={statusBorderColor}>{G.getWindowLocale(GC.statusLocaleMap[status])}</Box>
            {
              closInfo &&
              <Flex mr={10} fontWeight={600}>
                <TextComponent
                  maxWidth={145}
                  title={closInfo}
                  withEllipsis={true}
                  color={darkBlueColor}
                  display='inline-block'
                >
                  {closInfo}
                </TextComponent>
              </Flex>
            }
          </Flex>
          <Flex pt='3px'>
            <AuthWrapper has={[PC.FLEET_RATE_WRITE]}>
              <IconWrapper
                cursor='pointer'
                onClick={handleAddTelDriverRate}
                title={G.getWindowLocale('actions:assign-driver-rate', 'Assign Driver Rate')}
              >
                {I.driver(lightBlueColor, 13, 13)}
              </IconWrapper>
            </AuthWrapper>
            <RelativeFlex mx='6px' title={`${G.getWindowLocale('titles:items')} - ${itemsCount}`}>
              {I.itemBox(lightBlueColor, 13, 13)}
              <AbsoluteBox
                height={13}
                zIndex={11}
                minWidth={13}
                fontSize='9px'
                borderRadius='8px'
                border='1px solid'
                bg={lightBlueColor}
                color={mainLightColor}
                left='calc(100% - 7px)'
                justifyContent='center'
                bottom='calc(100% - 7px)'
                borderColor={mainLightColor}
              >
                {itemsCount}
              </AbsoluteBox>
            </RelativeFlex>
            <AuthWrapper has={[PC.TEL_READ, PC.TEL_WRITE]}>
              <IconWrapper pr='1px' pl='3px' cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, props)}>
                {I.threePointer(darkBlueColor, 12, 12)}
              </IconWrapper>
            </AuthWrapper>
          </Flex>
        </Flex>
        <Flex width='100%' justifyContent='space-between'>
          <Box flexGrow={1}>
            <Flex fontSize={12} justifyContent='space-between'>
              <Flex mr={10}>
                <PopperComponent
                  zi={1002}
                  type='hover'
                  position='top'
                  borderColor={borderColor}
                  content={
                    <Box>
                      <Box>{firstEventDate}</Box>
                      <Box>{G.concatLocationFields(firstEventLocation)}</Box>
                    </Box>
                  }
                >
                  <Flex
                    mr='5px'
                    p='1px 4px'
                    fontSize={11}
                    fontWeight='bold'
                    border='1px solid'
                    borderRadius='8px'
                    color={stopSuccessColor}
                    borderColor={stopSuccessColor}
                    bg={G.getTheme('dispatchBoardPopper.stopIconBg')}
                  >
                    P-{R.pathOr(1, [GC.FIELD_EVENTS_INFO, GC.FIELD_PICKUP_COUNT], props)}
                  </Flex>
                </PopperComponent>
                <Box>
                  <Box>{firstEventDate}</Box>
                  <Box>{G.concatLocationFields(firstEventLocation, [GC.FIELD_CITY, GC.FIELD_STATE])}</Box>
                </Box>
              </Flex>
              <Flex>
                <PopperComponent
                  zi={1002}
                  type='hover'
                  position='top'
                  borderColor={borderColor}
                  content={
                    <Box>
                      <Box>{lastEventDate}</Box>
                      <Box>{G.concatLocationFields(lastEventLocation)}</Box>
                    </Box>
                  }
                >
                  <Flex
                    mr='5px'
                    p='1px 4px'
                    fontSize={11}
                    fontWeight='bold'
                    border='1px solid'
                    borderRadius='8px'
                    color={stopRejectColor}
                    borderColor={stopRejectColor}
                    bg={G.getTheme('dispatchBoardPopper.stopIconBg')}
                  >
                    D-{R.pathOr(1, [GC.FIELD_EVENTS_INFO, GC.FIELD_DROP_COUNT], props)}
                  </Flex>
                </PopperComponent>
                <Box>
                  <Box>{lastEventDate}</Box>
                  <Box>{G.concatLocationFields(lastEventLocation, [GC.FIELD_CITY, GC.FIELD_STATE])}</Box>
                </Box>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
});

export default UnassignedLoad;

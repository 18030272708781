import * as R from 'ramda';
import * as Yup from 'yup';
// features
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import { fleetServiceVendorTypeOptions } from '../../../../helpers/options';
//////////////////////////////////////////////////

const fieldNotRequired = Yup.string().nullable(true);
const fieldRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

const commonProps = {
  width: 250,
};

const documentProps = {
  width: 400,
  inputWrapperStyles: {
    mb: 25,
  },
};

export const documentFieldsSettings = {
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: {
    ...documentProps,
    type: 'select',
    isRequired: true,
    options: 'documentTypeOptions',
    fieldName: GC.FIELD_DOCUMENT_DOCUMENT_TYPE,
    label: G.getWindowLocale('titles:document-type', 'Document Type'),
  },
  [GC.FIELD_DOCUMENT_URL]: {
    width: 400,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_DOCUMENT_URL,
    label: G.getWindowLocale('titles:url', 'URL'),
    inputWrapperStyles: {
      mb: 25,
      display: (props: Object) => {
        const { file, documentFilename } = R.pathOr({}, ['values'], props);

        return G.ifElse(R.or(G.isNotNil(file), G.isNotNil(documentFilename)), 'none', 'block');
      },
    },
  },
  [GC.FIELD_DOCUMENT_UPLOAD]: {
    width: 400,
    type: 'file',
    isRequired: true,
    fieldName: GC.FIELD_DOCUMENT_UPLOAD,
    label: G.getWindowLocale('titles:upload-file', 'Upload File'),
    inputWrapperStyles: {
      display: (props: Object) => {
        const { url, version, documentFilename } = R.pathOr({}, ['values'], props);

        return G.ifElse(
          R.or(G.isNotNilAndNotEmpty(url), R.and(G.isNotNil(version), R.isNil(documentFilename))),
          'none',
          'block',
        );
      },
    },
  },
};

export const defaultDocumentFields = R.map(() => null, documentFieldsSettings);

export const documentValidationSchema = Yup.lazy((values: Object) => {
  const { url, file } = values;

  const fileAndUrlValidation = G.ifElse(
    G.isAllNilOrEmpty([url, file]),
    fieldRequired,
    fieldNotRequired,
  );

  return Yup.object().shape({
    [GC.FIELD_DOCUMENT_URL]: fileAndUrlValidation,
    [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: fieldRequired,
    [GC.FIELD_DOCUMENT_UPLOAD]: fileAndUrlValidation,
    [GC.FIELD_DOCUMENT_DOCUMENT_NAME]: fieldNotRequired,
  });
});

export const serviceFieldSettings = {
  [GC.FIELD_SERVICE_ID]: {
    ...commonProps,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_SERVICE_ID,
    label: G.getWindowLocale('titles:service-id', 'Service ID'),
  },
  [GC.FIELD_SERVICE_DATE]: {
    ...commonProps,
    isRequired: true,
    isClearable: true,
    type: 'datePicker',
    fieldName: GC.FIELD_SERVICE_DATE,
    label: G.getWindowLocale('titles:planned-service-date', 'Planned Service Date'),
  },
  [GC.FIELD_APPROVED_BY]: {
    ...commonProps,
    type: 'text',
    fieldName: GC.FIELD_APPROVED_BY,
    label: G.getWindowLocale('titles:approved-by', 'Approved By'),
  },
  [GC.FIELD_SERVICE_TYPE]: {
    ...commonProps,
    isRequired: true,
    type: 'select',
    withClickZIndex: true,
    fieldName: GC.FIELD_SERVICE_TYPE,
    options: 'equipmentServiceTypeOptions',
    label: G.getWindowLocale('titles:service-type', 'Service Type'),
  },
  [GC.FIELD_SERVICE_VENDOR_GUID]: {
    width: 180,
    type: 'reactSelect',
    fieldName: GC.FIELD_SERVICE_VENDOR_GUID,
    options: 'serviceVendorListAvailableOptions',
    label: G.getWindowLocale('titles:service-vendor', 'Service Vendor'),
  },
  [GC.FIELD_INTERVAL]: {
    ...commonProps,
    type: 'number',
    fieldName: GC.FIELD_INTERVAL,
    label: G.getWindowLocale('titles:interval', 'Interval'),
  },
  [GC.FIELD_INTERVAL_UOM]: {
    ...commonProps,
    type: 'select',
    fieldName: GC.FIELD_INTERVAL_UOM,
    label: G.getWindowLocale('titles:interval-uom', 'Interval UOM'),
    options: [
      GC.EMPTY_OPTION_OBJECT,
      { value: GC.UOM_DAYS, label: G.getUomLocale(GC.UOM_DAYS) },
      { value: GC.UOM_HOURS, label: G.getUomLocale(GC.UOM_HOURS) },
      { value: GC.UOM_MILES, label: G.getUomLocale(GC.UOM_MILES) },
      { value: GC.UOM_KILOMETERS, label: G.getUomLocale(GC.UOM_KILOMETERS) },
    ],
  },
  [GC.FIELD_COMMENTS]: {
    ...commonProps,
    type: 'text',
    fieldName: GC.FIELD_COMMENTS,
    label: G.getWindowLocale('titles:comments', 'Comments'),
  },
  [GC.FIELD_PERFORMED_DATE]: {
    ...commonProps,
    isClearable: true,
    type: 'datePicker',
    fieldName: GC.FIELD_PERFORMED_DATE,
    label: G.getWindowLocale('titles:performed-date', 'Performed Date'),
  },
  [GC.FIELD_PERFORMED_VALUE]: {
    ...commonProps,
    type: 'number',
    fieldName: GC.FIELD_PERFORMED_VALUE,
    label: G.getWindowLocale('titles:performed-value', 'Performed Value'),
  },
  [GC.FIELD_PERFORMED_TYPE]: {
    ...commonProps,
    type: 'select',
    fieldName: GC.FIELD_PERFORMED_TYPE,
    label: G.getWindowLocale('titles:performed-type', 'Performed Type'),
    options: [
      GC.EMPTY_OPTION_OBJECT,
      { value: GC.UOM_HOURS, label: G.getUomLocale(GC.UOM_HOURS) },
      { value: GC.UOM_MILES, label: G.getUomLocale(GC.UOM_MILES) },
      { value: GC.UOM_KILOMETERS, label: G.getUomLocale(GC.UOM_KILOMETERS) },
    ],
  },
  [GC.FIELD_COMPONENT_GUIDS]: {
    ...commonProps,
    isMulti: true,
    type: 'reactSelect',
    inputWrapperStyles: { zIndex: 13 },
    fieldName: GC.FIELD_COMPONENT_GUIDS,
    options: 'availableForServiceEquipmentComponents',
    label: G.getWindowLocale('titles:equipment-components', 'Equipment Components'),
  },
};

const invoiceStyles = {
  width: 250,
  inputWrapperStyles: {
    mb: 25,
  },
};

export const invoiceFieldSettings = {
  [GC.FIELD_INVOICE_NUMBER]: {
    ...invoiceStyles,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_INVOICE_NUMBER,
    label: G.getWindowLocale('titles:invoice-number', 'Invoice Number'),
  },
  [GC.FIELD_INVOICE_STATUS]: {
    ...invoiceStyles,
    type: 'select',
    options: 'invoiceStatusOptions',
    fieldName: GC.FIELD_INVOICE_STATUS,
    label: G.getWindowLocale('titles:invoice-status', 'Invoice Status'),
  },
  [GC.FIELD_GL_CODE]: {
    ...invoiceStyles,
    type: 'select',
    options: 'glCodeOptions',
    fieldName: GC.FIELD_GL_CODE,
    label: G.getWindowLocale('titles:gl-code', 'GL Code'),
    disabled: () => G.hasNotAmousCurrentUserPermissions(PC.GL_CODE_WRITE),
  },
  [GC.FIELD_PO_NUMBER]: {
    ...invoiceStyles,
    type: 'text',
    fieldName: GC.FIELD_PO_NUMBER,
    label: G.getWindowLocale('titles:po-number', 'PO Number'),
  },
  [GC.FIELD_INVOICE_DATE]: {
    ...invoiceStyles,
    isRequired: true,
    isClearable: true,
    type: 'datePicker',
    shouldCustomChange: true,
    fieldName: GC.FIELD_INVOICE_DATE,
    label: G.getWindowLocale('titles:invoice-date', 'Invoice Date'),
    customChangeHandler2: ({ value, props }: Object) => {
      const { values, setValues } = props;

      const invoiceDate = G.convertInstanceToDefaultDateFormat(value);
      const performedDate = R.propOr(invoiceDate, GC.FIELD_PERFORMED_DATE, values);

      const newValues = R.compose(
        R.assoc(GC.FIELD_PERFORMED_DATE, performedDate),
        R.assocPath([GC.SYSTEM_OBJECT_INVOICE, GC.FIELD_INVOICE_DATE], invoiceDate),
      )(values);

      return setValues(newValues);
    },
  },
  [GC.FIELD_INVOICE_NET_DAYS]: {
    ...invoiceStyles,
    type: 'text',
    fieldName: GC.FIELD_INVOICE_NET_DAYS,
    label: G.getWindowLocale('titles:net-days', 'Ned Days'),
  },
  [GC.FIELD_INVOICE_PAYMENT_DUE_DATE]: {
    ...invoiceStyles,
    isClearable: true,
    type: 'datePicker',
    fieldName: GC.FIELD_INVOICE_PAYMENT_DUE_DATE,
    label: G.getWindowLocale('titles:payment-due-date', 'Payment Due Date'),
  },
  [GC.FIELD_INVOICE_CHECK_NUMBER]: {
    ...invoiceStyles,
    type: 'text',
    fieldName: GC.FIELD_INVOICE_CHECK_NUMBER,
    label: G.getWindowLocale('titles:check-number', 'Check Number'),
  },
  [GC.FIELD_INVOICE_CHECK_DATE]: {
    ...invoiceStyles,
    isClearable: true,
    type: 'datePicker',
    fieldName: GC.FIELD_INVOICE_CHECK_DATE,
    label: G.getWindowLocale('titles:check-date', 'Check Date'),
  },
  [GC.FIELD_CHECK_AMOUNT]: {
    ...invoiceStyles,
    type: 'text',
    fieldName: GC.FIELD_CHECK_AMOUNT,
    label: G.getWindowLocale('titles:check-amount', 'Check Amount'),
  },
  [GC.FIELD_INVOICE_DEPOSIT_DATE]: {
    ...invoiceStyles,
    isClearable: true,
    type: 'datePicker',
    fieldName: GC.FIELD_INVOICE_DEPOSIT_DATE,
    label: G.getWindowLocale('titles:deposit-date', 'Deposit Date'),
  },
  [GC.FIELD_INVOICE_ACH_PAYMENT_CONFIRMATION_NUMBER]: {
    width: 250,
    type: 'text',
    fieldName: GC.FIELD_INVOICE_ACH_PAYMENT_CONFIRMATION_NUMBER,
    label: G.getWindowLocale('titles:ach-payment-confirmation', 'ACH Payment Confirmation'),
  },
  [GC.FIELD_COMMENTS]: {
    p: 15,
    width: 500,
    type: 'textarea',
    fieldName: GC.FIELD_COMMENTS,
    label: G.getWindowLocale('titles:comments', 'Comments'),
  },
  [GC.FIELD_CURRENCY]: {
    width: 250,
    type: 'select',
    isRequired: true,
    fieldName: GC.FIELD_CURRENCY,
    options: GC.CURRENCY_OPTIONS,
    label: G.getWindowLocale('titles:currency', 'Currency'),
  },
};

export const chargesFieldSettings = [
  {
    width: 200,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_DISPLAYED_NAME,
    label: G.getWindowLocale('titles:charge-description', 'Charge Description'),
  },
  {
    width: 200,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_TOTAL,
    label: G.getWindowLocale('titles:total', 'Total'),
  },
  {
    width: 300,
    type: 'text',
    fieldName: GC.FIELD_COMMENTS,
    label: G.getWindowLocale('titles:comments', 'Comments'),
  },
];

export const chargeDefaultSettings = () => ({
  id: G.genShortId(),
  [GC.FIELD_TOTAL]: null,
  [GC.FIELD_COMMENTS]: null,
  [GC.FIELD_DISPLAYED_NAME]: null,
});

export const invoiceDefaultFields = {
  ...R.map(({ defaultValue = null }: Object) => defaultValue, invoiceFieldSettings),
  [GC.FIELD_CHARGES]: R.of(Array, chargeDefaultSettings()),
};

export const invoiceDefaultFields2 = {
  ...R.map(({ defaultValue = null }: Object) => defaultValue, invoiceFieldSettings),
  [GC.FIELD_CHARGES]: [],
};

export const serviceDefaultFields = R.map(({ defaultValue = null }: Object) => defaultValue, serviceFieldSettings);

const chargesValidationSchema = Yup.array().of(Yup.object().shape({
  [GC.FIELD_TOTAL]: G.yupNumberRequired,
  [GC.FIELD_DISPLAYED_NAME]: fieldRequired,
}));

export const validationSchema = Yup.lazy((values: Object) => {
  const { performedDate, serviceVendorType } = values;

  let schema = R.map(
    ({ isRequired }: Object) => G.ifElse(isRequired, fieldRequired),
    serviceFieldSettings,
  );

  if (G.isNotNilAndNotEmpty(performedDate)) {
    schema = {
      ...schema,
      [GC.FIELD_SERVICE_VENDOR_GUID]: fieldRequired,
      [GC.SYSTEM_OBJECT_INVOICE]: Yup.object().shape({
        ...R.map(() => fieldNotRequired, invoiceFieldSettings),
        [GC.FIELD_CURRENCY]: fieldRequired,
        [GC.FIELD_CHARGES]: chargesValidationSchema,
        [GC.FIELD_NET_DAYS]: Yup.number()
          .nullable(true)
          .min(0, G.getShouldBePositiveLocaleTxt())
          .typeError(G.getShouldBeNumericLocaleTxt())
          .max(10000, G.getShouldBeFromToLocaleTxt(0, 10000)),
        [GC.FIELD_INVOICE_NUMBER]: G.ifElse(
          R.equals(serviceVendorType, GC.SERVICE_VENDOR_TYPE_FLEET_SERVICE),
          fieldRequired,
          fieldNotRequired,
        ),
        [GC.FIELD_INVOICE_DATE]: G.ifElse(
          R.equals(serviceVendorType, GC.SERVICE_VENDOR_TYPE_FLEET_SERVICE),
          fieldRequired,
          fieldNotRequired,
        ),
      }),
    };
  }

  return Yup.object().shape(schema);
});

const serviceVendorStyles = {
  width: 250,
  inputWrapperStyles: {
    mb: 25,
  },
};

export const serviceVendorFieldSettings = [
  {
    ...serviceVendorStyles,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_NAME,
    label: G.getWindowLocale('titles:company-name', 'Company Name'),
  },
  {
    ...serviceVendorStyles,
    type: 'select',
    fieldName: GC.FIELD_TYPE,
    options: fleetServiceVendorTypeOptions(),
    label: G.getWindowLocale('titles:type', 'Type'),
    defaultValue: GC.SERVICE_VENDOR_TYPE_FLEET_SERVICE,
  },
  {
    ...serviceVendorStyles,
    isMulti: true,
    type: 'reactSelect',
    fieldName: GC.FIELD_SERVICE_TYPES,
    options: 'serviceVendorServiceTypeOptions',
    label: G.getWindowLocale('titles:service-type', 'Service Type'),
  },
  {
    ...serviceVendorStyles,
    type: 'text',
    fieldName: GC.FIELD_CARRIER_DBA,
    label: G.getWindowLocale('titles:dba', 'DBA'),
  },
  {
    ...serviceVendorStyles,
    type: 'text',
    fieldName: GC.FIELD_ACCOUNTING_EXTERNAL_ID,
    label: G.getWindowLocale('titles:accounting-external-id', 'Accounting External ID'),
  },
  {
    ...serviceVendorStyles,
    type: 'text',
    fieldName: GC.FIELD_US_DOT_NUMBER,
    label: G.getWindowLocale('titles:usdot-number', 'USDOT Number'),
  },
  {
    ...serviceVendorStyles,
    type: 'text',
    fieldName: GC.FIELD_MC_NUMBER,
    label: G.getWindowLocale('titles:mc-number', 'MC Number'),
  },
  {
    ...serviceVendorStyles,
    type: 'text',
    fieldName: GC.FIELD_CARRIER_DUNS_NUMBER,
    label: G.getWindowLocale('titles:duns-number', 'DUNS Number'),
  },
  {
    ...serviceVendorStyles,
    type: 'text',
    fieldName: GC.FIELD_LICENSE_NUMBER,
    label: G.getWindowLocale('titles:license-number', 'License Number'),
  },
  {
    width: 250,
    type: 'text',
    fieldName: GC.FIELD_CARRIER_URL,
    label: G.getWindowLocale('titles:url', 'URL'),
  },
];

const locationFieldSettings = [
  {
    ...serviceVendorStyles,
    type: 'text',
    fieldName: GC.FIELD_LOCATION_NAME,
    label: G.getWindowLocale('titles:location-name', 'Location Name'),
  },
  {
    width: 250,
    isRequired: true,
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS_1,
    inputWrapperStyles: { mb: 25, zIndex: 13 },
    label: G.getWindowLocale('titles:address1', 'titles:address1'),
  },
  {
    ...serviceVendorStyles,
    type: 'text',
    fieldName: GC.FIELD_ADDRESS_2,
    label: G.getWindowLocale('titles:address2', 'Address2'),
  },
  {
    ...serviceVendorStyles,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_CITY,
    label: G.getWindowLocale('titles:city', 'City'),
  },
  {
    ...serviceVendorStyles,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_STATE,
    label: G.getWindowLocale('titles:state', 'State'),
  },
  {
    ...serviceVendorStyles,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_ZIP,
    label: G.getWindowLocale('titles:zip', 'Zip'),
  },
  {
    width: 250,
    isRequired: true,
    type: 'countrySelect',
    fieldName: GC.FIELD_COUNTRY,
    inputWrapperStyles: { mb: 25, width: '100%' },
    label: G.getWindowLocale('titles:country', 'Country'),
  },
];

export const serviceVendorPayToLocationFieldSettings = [
  ...R.assocPath([1, 'inputWrapperStyles', 'zIndex'], 14, locationFieldSettings),
  {
    width: 250,
    type: 'select',
    fieldName: GC.FIELD_CARRIER_FINANCE_LOCATION_PAY_TYPE,
    label: G.getWindowLocale('titles:pay-type', 'Pay Type'),
    options: [
      GC.EMPTY_OPTION_OBJECT,
      {
        value: GC.PAYMENT_TERM_OPTION_FACTORING_COMPANY,
        label: G.getWindowLocale('titles:factoring-company', 'Factoring Company'),
      },
      {
        value: GC.PAYMENT_TERM_OPTION_DIRECT_PAY,
        label: G.getWindowLocale('titles:direct-pay', 'Direct Pay'),
      },
    ],
  },
  {
    width: 250,
    type: 'datePicker',
    fieldName: GC.FIELD_CARRIER_FINANCE_LOCATION_START_DATE,
    label: G.getWindowLocale('titles:start-date', 'Start Date'),
  },
  {
    width: 250,
    type: 'text',
    fieldName: GC.FIELD_NET_DAYS,
    label: G.getWindowLocale('titles:net-days', 'Net Days'),
  },
];

export const serviceVendorPrimaryLocationFieldSettings = [
  ...locationFieldSettings,
  {
    ...serviceVendorStyles,
    type: 'text',
    fieldName: GC.FIELD_CONTACT_NAME,
    label: G.getWindowLocale('titles:contact-name', 'Contact Name'),
  },
  {
    ...serviceVendorStyles,
    type: 'phoneNumber',
    fieldName: GC.FIELD_PHONE,
    label: G.getWindowLocale('titles:phone', 'Phone'),
  },
  {
    ...serviceVendorStyles,
    type: 'number',
    fieldName: GC.FIELD_PHONE_EXTENSION,
    label: G.getWindowLocale('titles:phone-extension', 'Phone Extension'),
  },
  {
    width: 250,
    type: 'multiEmail',
    fieldName: GC.FIELD_EMAILS,
    label: G.getWindowLocale('titles:emails', 'Emails'),
  },
];

export const defaultServiceVendorFields = R.compose(
  R.mergeRight({ [GC.SYSTEM_OBJECT_PAY_TO_LOCATION]: null, [GC.SYSTEM_OBJECT_PRIMARY_LOCATION]: null }),
  R.map(({ defaultValue = null }: Object) => defaultValue),
  R.indexBy(R.prop('fieldName')),
)(serviceVendorFieldSettings);

export const defaultServiceVendorPayToLocationFields = R.compose(
  R.map(() => null),
  R.indexBy(R.prop('fieldName')),
)(serviceVendorPayToLocationFieldSettings);

export const defaultServiceVendorPrimaryLocationFields = R.compose(
  R.map(() => null),
  R.indexBy(R.prop('fieldName')),
)(serviceVendorPrimaryLocationFieldSettings);

const serviceVendorGeneralSchema = {
  [GC.FIELD_NAME]: fieldRequired,
  [GC.FIELD_SERVICE_TYPES]: Yup.array().nullable(true),
  [GC.FIELD_CARRIER_DBA]: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToCharLocaleTxt(0, 70))
    .max(70, G.getShouldBeFromToCharLocaleTxt(0, 70)),
  [GC.FIELD_ACCOUNTING_EXTERNAL_ID]: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToCharLocaleTxt(0, 100))
    .max(100, G.getShouldBeFromToCharLocaleTxt(0, 100)),
  [GC.FIELD_US_DOT_NUMBER]: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToCharLocaleTxt(0, 12))
    .max(12, G.getShouldBeFromToCharLocaleTxt(0, 12)),
  [GC.FIELD_MC_NUMBER]: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToCharLocaleTxt(0, 8))
    .max(8, G.getShouldBeFromToCharLocaleTxt(0, 8)),
  [GC.FIELD_CARRIER_DUNS_NUMBER]: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToCharLocaleTxt(0, 100))
    .max(100, G.getShouldBeFromToCharLocaleTxt(0, 100)),
  [GC.FIELD_LICENSE_NUMBER]: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToCharLocaleTxt(0, 40))
    .max(40, G.getShouldBeFromToCharLocaleTxt(0, 40)),
};

const locationCommonSchema = {
  [GC.FIELD_ZIP]: fieldRequired,
  [GC.FIELD_CITY]: fieldRequired,
  [GC.FIELD_STATE]: fieldRequired,
  [GC.FIELD_COUNTRY]: fieldRequired,
  [GC.FIELD_ADDRESS_1]: fieldRequired,
  [GC.FIELD_ADDRESS_2]: fieldNotRequired,
  [GC.FIELD_LOCATION_NAME]: fieldRequired,
};

const serviceVendorPayToLocationSchema = {
  ...locationCommonSchema,
  [GC.FIELD_NET_DAYS]: fieldNotRequired,
  [GC.FIELD_CARRIER_FINANCE_LOCATION_PAY_TYPE]: fieldRequired,
  [GC.FIELD_CARRIER_FINANCE_LOCATION_START_DATE]: fieldRequired,
};

const serviceVendorPrimaryLocationSchema = {
  ...locationCommonSchema,
  [GC.FIELD_PHONE]: fieldNotRequired,
  [GC.FIELD_CONTACT_NAME]: fieldNotRequired,
  [GC.FIELD_EMAILS]: Yup.array().nullable(true),
};

export const serviceVendorValidationSchema = (props: Object) => Yup.lazy(() => {
  const { payToLocationExpanded, primaryLocationExpanded } = props;

  let schema = serviceVendorGeneralSchema;

  if (G.isTrue(payToLocationExpanded)) {
    schema = R.assoc(GC.SYSTEM_OBJECT_PAY_TO_LOCATION, Yup.object().shape(serviceVendorPayToLocationSchema), schema);
  }

  if (G.isTrue(primaryLocationExpanded)) {
    schema = R.assoc(GC.SYSTEM_OBJECT_PRIMARY_LOCATION, Yup.object().shape(serviceVendorPrimaryLocationSchema), schema);
  }

  return Yup.object().shape(schema);
});

import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withState, withProps, withPropsOnChange } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { getTransportationModeTypeOptions } from '../../../helpers/options';
// feature configs
import { externalTransportationModeOptionsMap } from '../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 270,
};

export const fieldSettings = {
  [GC.FIELD_INTEGRATION_TYPE]: {
    type: 'select',
    inputWrapperStyles,
    label: ['titles:integration-type'],
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    defaultValue: GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS,
    options: [
      {
        label: GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS,
        value: GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS,
      },
      {
        label: 'Manitoulin',
        value: GC.CARRIER_RATE_INTEGRATION_TYPE_MANITOULIN,
      },
      {
        label: GC.CARRIER_RATE_INTEGRATION_TYPE_GLS,
        value: GC.CARRIER_RATE_INTEGRATION_TYPE_GLS,
      },
      {
        value: GC.CARRIER_SYNCHRONIZATION_TYPE_MY_CARRIER_PACKETS,
        label: G.getEnumLocale(GC.CARRIER_SYNCHRONIZATION_TYPE_MY_CARRIER_PACKETS),
      },
      {
        label: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI,
        value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI,
      },
    ],
  },
  [GC.FIELD_TRANSPORTATION_MODE_GUID]: {
    inputWrapperStyles,
    type: 'reactSelect',
    label: ['titles:mode'],
    options: 'transportationModeOptions',
    fieldName: GC.FIELD_TRANSPORTATION_MODE_GUID,
  },
  [GC.FIELD_ICON_TYPE]: {
    inputWrapperStyles,
    type: 'reactSelect',
    fieldName: GC.FIELD_ICON_TYPE,
    label: ['titles:type-of-icon'],
    options: getTransportationModeTypeOptions(),
  },
  [GC.EXTERNAL_TRANSPORTATION_MODE]: {
    inputWrapperStyles,
    type: 'reactSelect',
    label: ['titles:external-mode'],
    options: 'externalTransportationModes',
    fieldName: GC.EXTERNAL_TRANSPORTATION_MODE,
  },
};

export const defaultTransportationModeConfigMappingFields = {
  [GC.FIELD_ICON_TYPE]: null,
  [GC.EXTERNAL_TRANSPORTATION_MODE]: null,
  [GC.FIELD_TRANSPORTATION_MODE_GUID]: null,
  [GC.FIELD_INTEGRATION_TYPE]: GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS,
};

export const validationSchemaObject = {
  [GC.FIELD_ICON_TYPE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.EXTERNAL_TRANSPORTATION_MODE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_TRANSPORTATION_MODE_GUID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
};

const enhance = compose(
  withProps(({ initialValues }: Object) => {
    const fieldsToPick = G.ifElse(
      R.propEq(GC.INTEGRATION_CONFIG_GROUP, 'configGroup', initialValues),
      [GC.FIELD_INTEGRATION_TYPE, GC.FIELD_TRANSPORTATION_MODE_GUID, GC.EXTERNAL_TRANSPORTATION_MODE],
      [GC.FIELD_TRANSPORTATION_MODE_GUID, GC.FIELD_ICON_TYPE],
    );

    const values = R.compose(
      R.mergeDeepLeft(initialValues),
      R.map(R.pathOr(null, ['defaultValue'])),
      R.pick(fieldsToPick),
    )(fieldSettings);
    const validationSchema = R.pick(fieldsToPick, validationSchemaObject);

    return {
      values,
      validationSchema,
      fields: R.values(R.pick(fieldsToPick, fieldSettings)),
    };
  }),
  withFormik({
    mapPropsToValues: ({ values }: Object) => values,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    validationSchema: ({ validationSchema }: Object) => Yup.object().shape(validationSchema),
  }),
  withState('prevProps', 'setPrevProps', {}),
  withPropsOnChange(
    (props: Object, nextProps: Object) => {
      const currentIntegrationType = R.path(['values', GC.FIELD_INTEGRATION_TYPE], props);
      const nextIntegrationType = R.path(['values', GC.FIELD_INTEGRATION_TYPE], nextProps);

      return G.notEquals(currentIntegrationType, nextIntegrationType);
    },
    ({ values, transportationModeOptions, ...props }: Object) => {
      const integrationType = R.pathOr(GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS, [GC.FIELD_INTEGRATION_TYPE], values);
      const externalTransportationModes = R.compose(
        R.values,
        R.mapObjIndexed((label: string, value: string) => ({ label, value })),
        R.pathOr([], [integrationType]),
      )(externalTransportationModeOptionsMap);

      let fields = R.prop('fields', props);

      if (R.equals(integrationType, GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI)) {
        fields = R.assocPath([2, 'type'], 'text', fields);
      }

      return {
        fields,
        optionsForSelect: { transportationModeOptions, externalTransportationModes },
      };
    },
  ),
  pure,
);

const TransportationModeConfigMappingForm = ({ fields, handleSubmit, optionsForSelect, ...props }: Object) => (
  <form onSubmit={handleSubmit}>
    <Fieldset2
      {...optionsForSelect}
      {...G.getFormikProps(props)}
      fields={fields}
      fieldsWrapperStyles={{ pt: 10, flexDirection: 'column' }}
    />
    <FormFooter />
  </form>
);

export default enhance(TransportationModeConfigMappingForm);

import React, { useState, useCallback } from 'react';
//////////////////////////////////////////////////

const useMaintenanceNotes = (initialNotes: any = []) => {
  const [maintenanceNotes, setMaintenanceNotes] = useState(initialNotes);

  const getNotes = useCallback(() => maintenanceNotes, [maintenanceNotes]);

  const addNote = useCallback((note: Object) => {
    setMaintenanceNotes((prevNotes: Array) => [...prevNotes, note]);
  }, []);

  const updateNote = useCallback((index: number, updatedNote: Object) => {
    setMaintenanceNotes((prevNotes: Array) =>
      prevNotes.map((note: Object, i: number) => (i === index ? updatedNote : note)),
    );
  }, []);

  const deleteNote = useCallback((index: number) => {
    setMaintenanceNotes((prevNotes: Array) => prevNotes.filter((_: any, i: number) => i !== index));
  }, []);

  return {
    addNote,
    getNotes,
    updateNote,
    deleteNote,
    maintenanceNotes,
  };
};

const withMaintenanceNotes = ({ initialNotes = [] }: any) => (Component: Function) => (props: Object) => {
  const hook = useMaintenanceNotes(initialNotes);

  return <Component {...props} {...hook} />;
};


export default useMaintenanceNotes;

export {
  useMaintenanceNotes,
  withMaintenanceNotes,
};

import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////


const getInspectionData = (data: Object) => {
  const { issues, sections } = data;

  const indexedIssues = R.indexBy(R.prop(GC.FIELD_INSPECTION_COMPONENT_GUID), issues);

  return R.assoc(
    'sections',
    R.map((section: Object) => {
      const { components } = section;

      return R.assoc(
        'components',
        R.map((component: Object) => R.assoc(
          'issue',
          R.pathOr(null, [G.getGuidFromObject(component)], indexedIssues),
          component,
        ), components),
        section,
      );
    }, sections),
    data,
  );
};

export { getInspectionData };

import * as R from 'ramda';
import React, { Fragment } from 'react';
// components
import {
  AddDocument2,
} from '../../../../components/activities';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, Flex, Iframe, MainActionButton } from '../../../../ui';
//////////////////////////////////////////////////

export const prepareAndSetDocuments = ({
  doc,
  files,
  aiUpload,
  documents,
  setDocuments,
  defaultDocumentType,
}: Object) => {
  if (R.and(G.isNilOrEmpty(files), G.isNilOrEmpty(doc))) {
    return setDocuments(null);
  }

  if (G.isTrue(aiUpload)) {
    return setDocuments(R.prepend(doc, documents));
  }

  const filesWithProps = files.map((file: Object) => ({
    file,
    guid: G.generateGuid(),
    preview: G.createObjectURL(file),
    documentType: defaultDocumentType,
  }));

  const newDocs = R.concat(R.or(documents, []), filesWithProps);

  return setDocuments(newDocs);
};

const ExpenseAiUpload = (props: Object) => {
  const {
    values,
    setValues,
    documents,
    aiUploadDoc,
    setDocuments,
    setAiUploadDoc,
    aiRecognizeRequest,
    aiUploadDefaultDocumentType,
  } = props;

  const aiUploadGuid = G.getGuidFromObject(aiUploadDoc);

  const filePreview = R.path(['preview'], aiUploadDoc);
  const type = R.path(['file', 'type'], aiUploadDoc);

  return (
    <Fragment>
      {
        G.isNilOrEmpty(filePreview) &&
        <Flex justifyContent='center' flexDirection='column'>
          <AddDocument2
            m='0px 15px'
            onDropHandler={(files: Array) => {
              const file = R.head(files || []);

              const callback = (expense: Object) => {
                let newValues = G.mergeWithoutNullUndefinedOrEmptyString(values, expense);

                const date = G.getPropFromObject(GC.FIELD_DATE, newValues);

                if (R.and(G.isNotNilAndNotEmpty(date), G.isValidMoment(date))) {
                  newValues = R.assoc(
                    GC.FIELD_DATE,
                    G.convertInstanceToDefaultDateTimeFormat(date),
                    newValues,
                  );
                }

                setValues(newValues);
              };

              aiRecognizeRequest(file, callback);

              const doc = {
                file,
                guid: G.generateGuid(),
                preview: G.createObjectURL(file),
                documentType: aiUploadDefaultDocumentType,
              };

              setAiUploadDoc(doc);

              prepareAndSetDocuments({
                doc,
                documents,
                aiUpload: true,
                setDocuments,
                aiUploadDefaultDocumentType,
              });
            }}
          />
        </Flex>
      }
      {
        filePreview &&
        <Flex
          pl={15}
          fontSize={14}
          justifyContent='space-between'
        >
          <MainActionButton
            type='button'
            onClick={() => {
              setAiUploadDoc(null);

              const newDocs = R.reject(R.propEq(aiUploadGuid, GC.FIELD_GUID), documents);

              setDocuments(newDocs);
            }}
          >
            {G.getWindowLocale('actions:remove-file', 'Remove File')}
          </MainActionButton>
        </Flex>
      }
      { filePreview &&
        <Box p={15} height='calc(100% - 100px)'>
          <Iframe type={type} src={filePreview} />
        </Box>
      }
    </Fragment>
  );
};

export default ExpenseAiUpload;

import React from 'react';
import * as R from 'ramda';
import { pure, compose, lifecycle, withState, withHandlers } from 'react-recompose';
// components
import BranchSearch from './branch-search';
import { getTripStatusOptions } from '../../../components/filter/settings';
import { SelectMui, DatePickerMui, QuickFilterOutsideControl3, PlacesAutocompleteInputMui } from '../../../components';
// features
import DriverRateForm from '../../rate/driver';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, RelativeFlex, MainActionButton } from '../../../ui';
// feature available-driver
import { DateFilters } from './date-filters';
import UnassignedLoad from './unassigned-load';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

const getUnassignedLoadsRequestOptions = (props: Object) => {
  const {
    statuses,
    enterprise,
    dateFilters,
    searchRadius,
    referenceValue,
    searchDateValue,
    searchPlaceLocation,
  } = props;

  const { dateTo, dateFrom } = dateFilters;

  const enterpriseGuid = G.ifElse(Array.isArray(enterprise), null, enterprise);
  const radius = G.ifElse(G.isNotNilAndNotEmpty(searchRadius), G.toNumber(searchRadius), 0);

  return {
    radius,
    statuses,
    enterpriseGuid,
    referenceValue,
    date: searchDateValue,
    location: searchPlaceLocation,
    currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
    dateTo: G.createLocalDateTimeFromInstanceOrISOString(dateTo, G.getDateTimeFormat(true)),
    dateFrom: G.createLocalDateTimeFromInstanceOrISOString(dateFrom, G.getDateTimeFormat(true)),
  };
};

const getDatesFilter = (dayFilter: number = 3) => {
  const dayToUse = R.divide(R.dec(dayFilter), 2);
  const currentDate = G.getCurrentDateWithFormat(GC.DEFAULT_DATE_FORMAT);

  const args = [dayToUse, GC.FIELD_DAYS, GC.DEFAULT_DATE_FORMAT];

  return {
    initialFilterDays: dayFilter,
    dateTo: G.addMomentTimeWithFormat(currentDate, ...args),
    dateFrom: G.subtractMomentTimeWithFormat(currentDate, ...args),
  };
};

export const enhance = compose(
  withState('statuses', 'setStatuses', []),
  withState('enterprise', 'setEnterprise', null),
  withState('searchRadius', 'setSearchRadius', 100),
  withState('referenceValue', 'setReferenceValue', ''),
  withState('searchDateValue', 'setSearchDateValue', null),
  withState('searchPlaceLocation', 'setSearchPlaceLocation', null),
  withState('dateFilters', 'setDateFilters', () => getDatesFilter()),
  withState('searchPlaceAutocomplete', 'setSearchPlaceAutocomplete', null),
  withHandlers({
    handleSetFilterQuickDays: ({ setDateFilters }: Object) => (value: number) => setDateFilters(getDatesFilter(value)),
    handleSetDateFilters: ({ setDateFilters }: Object) => (dates: Object) =>
      setDateFilters((filters: Object) => R.mergeRight(filters, dates)),
    handlePlaceAutocompleteResultCallback: ({ setSearchPlaceLocation }: Object) => (result: Object) => {
      if (G.isNilOrEmpty(result)) return;

      const { latitude, longitude } = result;

      setSearchPlaceLocation({ latitude, longitude });
    },
    handleSearchAction: (props: Object) => () => {
      const { searchRadius, getUnassignedLoadsRequest } = props;

      if (G.isNilOrEmpty(searchRadius)) return;

      getUnassignedLoadsRequest(getUnassignedLoadsRequestOptions(props));
    },
    handleOpenTelDetails: (props: Object) => (item: Object) => {
      const { entity, closeFixedPopup, handleSetExpandedContainerOptions } = props;

      G.callFunction(closeFixedPopup);

      const visitPageGuid = G.getGuidFromObject(G.ifElse(G.isNilOrEmpty(entity), item, entity));

      handleSetExpandedContainerOptions({
        visitPageGuid,
        openContainerAsNewWindow: true,
        componentType: GC.PAGE_DISPATCH_DETAILS_NEW_LOAD,
      });
    },
    handleAddTelDriverRate: (props: Object) => (item: Object) => {
      const { entity, openModal, closeFixedPopup, assignDriverOnAvailableDriverRequest } = props;

      G.callFunction(closeFixedPopup);

      const tel = G.ifElse(G.isNilOrEmpty(entity), item, entity);

      const telGuid = G.getGuidFromObject(tel);
      const branchGuid = G.getBranchGuidFromObject(tel);

      const component = (
        <DriverRateForm
          tel={tel}
          telGuid={telGuid}
          branchGuid={branchGuid}
          configsNamesArray={GC.ADD_RATE_CONFIGS_ARRAY}
          stopCount={R.length(R.pathOr([], [GC.FIELD_LOAD_STOPS], tel))}
          handleSendTelRate={(values: Object) =>
            assignDriverOnAvailableDriverRequest(R.assoc(GC.FIELD_TEL_GUID, telGuid, values))
          }
        />
      );

      const modal = G.getRateModalWithContent(component);

      openModal(modal);
    },
  }),
  lifecycle({
    componentDidMount() {
      const props = this.props;

      const { getUnassignedLoadsRequest } = props;

      getUnassignedLoadsRequest(getUnassignedLoadsRequestOptions(props));
    },
  }),
  pure,
);

const UnassignedLoadsList = (props: Object) => {
  const {
    openModal,
    closeModal,
    enterprise,
    openLoader,
    branchList,
    closeLoader,
    setStatuses,
    searchRadius,
    setEnterprise,
    openFixedPopup,
    searchDateValue,
    setSearchRadius,
    unassignedLoads,
    expandedContainer,
    setReferenceValue,
    handleSearchAction,
    setSearchDateValue,
    handleOpenTelDetails,
    toggleUnassignedLoads,
    handleAddTelDriverRate,
    setSearchPlaceLocation,
    searchPlaceAutocomplete,
    setSearchPlaceAutocomplete,
    handlePlaceAutocompleteResultCallback,
  } = props;

  return (
    <Box
      px={10}
      width={500}
      height='100%'
      overflow='auto'
      bg={G.getTheme('colors.light.mainLight')}
    >
      <Flex p={15} mb={10} justifyContent='space-between'>
        <Box fontSize={20} fontWeight={600} color={darkBlueColor}>
          {G.getWindowLocale('titles:unassigned-loads', 'Unassigned Loads')}
        </Box>
        <Box cursor='pointer' onClick={toggleUnassignedLoads}>{I.closeIcon(darkBlueColor)}</Box>
      </Flex>
      <DateFilters {...props} />
      <RelativeFlex my={20} zIndex={17}>
        <PlacesAutocompleteInputMui
          width={150}
          withCloseIcon={true}
          useOutsideValue={true}
          inputWrapperStyles={{ mr: 15 }}
          outsideValue={searchPlaceAutocomplete}
          setOutsideValue={setSearchPlaceAutocomplete}
          resultCallback={handlePlaceAutocompleteResultCallback}
          label={G.getWindowLocale('titles:search-location', 'Search Location')}
          onCloseCallback={() => {
            setSearchPlaceLocation(null);
            setSearchPlaceAutocomplete(null);
          }}
        />
        <QuickFilterOutsideControl3
          delayTime={300}
          inputStyles={{ width: 80 }}
          outsideValue={searchRadius}
          handleSetFilter={setSearchRadius}
          placeholder={G.getDistanceUomLocale()}
          label={G.getWindowLocale('titles:radius', 'Radius')}
        />
        <DatePickerMui
          width={100}
          m='0px 15px'
          isClearable={true}
          value={searchDateValue}
          withCalendarIcon={false}
          useNewMuiInputField={true}
          label={G.getWindowLocale('titles:search-date', 'Search Date')}
          onChange={(date: Object) => G.isNilOrEmpty(date) && setSearchDateValue(null)}
          onAccept={(date: Object) =>
            G.isNotNilAndNotEmpty(date) && setSearchDateValue(G.convertInstanceToDefaultDateFormat(date))}
        />
        <QuickFilterOutsideControl3
          allowSingleCharacter={true}
          inputStyles={{ width: 105 }}
          handleSetFilter={setReferenceValue}
          allowSetAdditionalFiltersImmediately={true}
          label={`${G.getWindowLocale('titles:filter-by', 'Filter By')} ${G.getWindowLocale('titles:ref', 'Ref:')}`}
        />
      </RelativeFlex>
      <Flex mb={15} justifyContent='space-between' gap={10} color={G.getTheme('colors.greyMatterhorn')}>
        <BranchSearch value={enterprise} selectHandler={setEnterprise} />
        <SelectMui
          wrapperStyles={{ width: 175 }}
          options={getTripStatusOptions()}
          handleChangeCallback={setStatuses}
          label={G.getWindowLocale('titles:status-filter', 'Status Filter')}
        />
        <MainActionButton
          width={100}
          textTransform='uppercase'
          onClick={handleSearchAction}
          disabled={G.isNilOrEmpty(searchRadius)}
        >
          {G.getWindowLocale('titles:search', 'Search')}
        </MainActionButton>
      </Flex>
      {
        G.isNotNilAndNotEmpty(unassignedLoads) &&
        unassignedLoads.map((item: Object) => (
          <UnassignedLoad
            {...item}
            openModal={openModal}
            closeModal={closeModal}
            openLoader={openLoader}
            branchList={branchList}
            closeLoader={closeLoader}
            key={G.getGuidFromObject(item)}
            openFixedPopup={openFixedPopup}
            expandedContainer={expandedContainer}
            handleOpenTelDetails={() => handleOpenTelDetails(item)}
            handleAddTelDriverRate={() => handleAddTelDriverRate(item)}
          />
        ))
      }
    </Box>
  );
};

export default enhance(UnassignedLoadsList);

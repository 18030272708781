import * as R from 'ramda';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
//////////////////////////////////////////////////

const initialValues = {
  comment: null,
  reasonCode: null,
};

const fieldSettings = [
  {
    type: 'reactSelect',
    fieldName: 'reasonCode',
    options: 'availableDeclineReasons',
    label: ['titles:decline-reason', 'Decline Reason'],
    inputWrapperStyles: {
      mb: 25,
      width: 400,
    },
  },
  {
    type: 'textarea',
    fieldName: GC.FIELD_COMMENT,
    label: ['titles:comments', 'Comments'],
    inputWrapperStyles: {
      mb: 25,
      width: 400,
    },
  },
];

const DeclineForm = ({ submitAction, transactionGuid }: Object) => {
  const formik = useFormik({ initialValues, onSubmit: submitAction });

  const [availableDeclineReasons, setAvailableDeclineReasons] = useState([]);

  useEffect(() => {
    const handleGetAvailableDeclineReasons = async () => {
      const options = {
        params: { transactionGuid },
      };

      const res = await sendRequest('get', endpointsMap.receivedCloAvailableDeclineReasons, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const mapped = R.map(({ code, displayedValue }: Object) => ({
          [GC.FIELD_VALUE]: code,
          [GC.FIELD_LABEL]: displayedValue,
        }), R.or(data, []));

        setAvailableDeclineReasons(mapped);
      } else {
        G.handleException('error', 'handleGetAvailableDeclineReasons exception');
      }
    };

    handleGetAvailableDeclineReasons();
  }, []);

  const { handleSubmit } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={20} fontSize={18} textAlign='center'>
        {G.getWindowLocale('messages:before-decline', 'Are you sure you want to decline')}?
      </Box>
      <Fieldset2
        {...G.getFormikProps(formik)}
        fields={fieldSettings}
        availableDeclineReasons={availableDeclineReasons}
        fieldsWrapperStyles={{ flexDirection: 'column' }}
      />
      <FormFooter2 />
    </form>
  );
};

export default DeclineForm;

import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withState, withHandlers, renderNothing } from 'react-recompose';
// common
import { makeSelectDocumentTemplates } from '../../../../common/selectors';
// components
import { DetailsEvents } from '../../../../components/events';
import { ModalWrapperWithZIndex } from '../../../../components/modal/ui';
import { openModal, closeModal } from '../../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// features
import { AuthWrapper } from '../../../permission';
import PC from '../../../permission/role-permission';
import { DispatchPlannerForTelDetails } from '../../../dispatch-planner';
import { makeSelectInitialDataLoadedStatus } from '../../../permission/selectors';
import { DispatchPlannerEventsForTelDetails } from '../../../dispatch-planner-events';
import { makeSelectAvailableReferenceTypesByScope } from '../../../reference/selectors';
import {
  setOpenedFromPage,
  setInitialRouteGuid,
  openDispatchPlannerRequest } from '../../../dispatch-planner/actions';
import {
  setInitialPlannerData,
  openDispatchPlannerEventsRequest,
  setDispatchPlannerEventsOpenedFromPage } from '../../../dispatch-planner-events/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withFixedPopover } from '../../../../hocs';
// ui
import { Box, Grid } from '../../../../ui';
// feature dispatch-details-new
import * as C from '../../constants';
import ActionsCenter from './actions-center';
import LoadHeader from '../../components/load-header';
import PageHeader from '../../components/page-header';
import { LoadCardWrapper } from '../../components/cards';
import RelatedDataTable from '../../components/related-data';
import { withLoadPageActions } from '../hocs/with-page-actions';
import ToggleComponent from '../../components/toggle-component';
import TableGroupWrapper from '../components/table-group-wrapper';
import { getColumnSettings } from '../../settings/related-data-table-settings';
import { withRightLoadHeaderActions } from '../hocs/with-right-load-header-actions';
import {
  changeActiveList,
  setDispatchPlannerOpened,
  getLinkedOrderListRequest,
  getRelatedOrderListRequest,
  setDispatchPlannerEventsOpened,
  suspendResumeCarrierRateEmailsRequest,
  createLoadMultipleStatusMessageRequest,
  getLoadTransportationModeGroupingListSuccess,
} from '../actions';
import {
  makeSelectEvents,
  makeSelectHiddenTabs,
  makeSelectLoadDetails,
  makeSelectLoadConfigs,
  makeSelectActiveLists,
  makeSelectExpandedLists,
  makeSelectLoadDocuments,
  makeSelectLoadTotalWeight,
  makeSelectActiveListNames,
  makeSelectLinkedOrderList,
  makeSelectRelatedOrderList,
  makeSelectLoadInvoiceTotals,
  makeSelectLoadTotalDistance,
  makeSelectCloEventReferences,
  makeSelectPostedShipmentsData,
  makeSelectSelectedCustomerRate,
  makeSelectDispatchPlannerOpened,
  makeSelectLinkedOrdersDocuments,
  makeSelectRelatedOrderListLoading,
  makeSelectSelectedCarrierDriverRate,
  makeSelectDispatchPlannerEventsOpened,
  makeSelectLoadTransportationModeGroupingList,
} from '../selectors';
//////////////////////////////////////////////////

const cards = [
  {
    card: C.CARD_TYPE_PAYMENTS,
    permissions: [
      PC.FLEET_RATE_READ,
      PC.FLEET_RATE_WRITE,
      PC.TEL_BILL_TO_READ,
      PC.CARRIER_RATE_READ,
      PC.TEL_BILL_TO_WRITE,
      PC.CARRIER_RATE_WRITE,
    ],
  },
  {
    card: C.CARD_TYPE_DISPATCH,
    permissions: [PC.FLEET_RATE_READ, PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_READ, PC.CARRIER_RATE_WRITE],
  },
  {
    card: C.CARD_TYPE_DOCUMENTS,
    permissions: [PC.TEL_DOCUMENT_READ, PC.TEL_DOCUMENT_WRITE],
  },
  {
    card: C.CARD_TYPE_MESSAGE_CENTER,
  },
];

const EnhancedLoadHeader = withRightLoadHeaderActions(LoadHeader);
const EnhancedPageHeader = withFixedPopover(withLoadPageActions(PageHeader));

const getPrimaryReferenceValue = R.compose(
  R.path([GC.FIELD_VALUE]),
  R.find(R.propEq(true, GC.FIELD_PRIMARY)),
  R.pathOr([], [GC.FIELD_REFERENCES]),
);

const GoToPlannerBtn = (props: Object) => {
  const { openDispatchPlanner } = props;

  return (
    <AuthWrapper has={[PC.TEL_WRITE]}>
      <Box
        p='5px'
        my={10}
        mx={25}
        fontSize={11}
        cursor='pointer'
        borderRadius='5px'
        display='inline-block'
        onClick={openDispatchPlanner}
        color={G.getTheme('colors.dark.darkBlue')}
        border={`1px solid ${G.getTheme('colors.dark.darkBlue')}`}
      >
        {G.getWindowLocale('titles:edit-events', 'Edit Events')}
      </Box>
    </AuthWrapper>
  );
};

const generalTabEnhance = compose(
  withFixedPopover,
  withState('relatedDataOpened', 'setRelatedDataOpened', true),
  withHandlers({
    openDispatchPlanner: (props: Object) => () => {
      const {
        loadDetails,
        expandedContainer,
        setOpenedFromPage,
        setInitialRouteGuid,
        setInitialPlannerData,
        setDispatchPlannerOpened,
        openDispatchPlannerRequest,
        setDispatchPlannerEventsOpened,
        openDispatchPlannerEventsRequest,
        setDispatchPlannerEventsOpenedFromPage } = props;

      const telGuid = G.getGuidFromObject(loadDetails);
      const routeGuid = R.path([GC.FIELD_ROUTE_GUID], loadDetails);

      if (routeGuid) {
        setInitialRouteGuid(routeGuid);

        if (G.isFalse(expandedContainer)) {
          openDispatchPlannerRequest();
          setOpenedFromPage('DISPATCH_DETAILS_NEW');

          return setDispatchPlannerOpened(true);
        }
        G.goToRoute(GC.ROUTE_PATH_ROUTE_BUILDER);
      } else {
        setInitialPlannerData({ telGuid });

        if (G.isFalse(expandedContainer)) {
          openDispatchPlannerEventsRequest();
          setDispatchPlannerEventsOpenedFromPage('DISPATCH_DETAILS_NEW');

          return setDispatchPlannerEventsOpened(true);
        }
        G.goToRoute(GC.ROUTE_PATH_LOAD_PLANNER);
      }
    },
    handleToggleRelatedData: (props: Object) => () => {
      const {
        relatedData,
        loadDetails,
        linkedOrderList,
        relatedDataOpened,
        setRelatedDataOpened,
        getLinkedOrderListRequest,
        getRelatedOrderListRequest,
      } = props;

      if (relatedDataOpened) return setRelatedDataOpened(false);

      const guid = G.getGuidFromObject(loadDetails);

      if (R.isNil(relatedData)) {
        getRelatedOrderListRequest(guid);
      }

      if (R.isNil(linkedOrderList)) {
        getLinkedOrderListRequest(guid);
      }

      setRelatedDataOpened(true);
    },
  }),
  branch(
    (props: Object) => R.or(
      R.not(props.initialDataLoaded),
      G.isNilOrEmpty(props.loadDetails),
    ),
    renderNothing,
  ),
  pure,
);

const GeneralTab = generalTabEnhance((props: Object) => {
  const {
    events,
    openModal,
    hiddenTabs,
    closeModal,
    openLoader,
    closeLoader,
    loadConfigs,
    loadDetails,
    activeLists,
    relatedData,
    setActiveTab,
    invoiceTotals,
    expandedLists,
    loadDocuments,
    openFixedPopup,
    postedShipment,
    loadTotalWeight,
    activeListNames,
    linkedOrderList,
    closeFixedPopup,
    changeActiveList,
    loadTotalDistance,
    relatedDataOpened,
    availableRefTypes,
    relatedDataLoading,
    cloEventReferences,
    selectedCustomerRate,
    linkedOrdersDocuments,
    handleToggleRelatedData,
    documentTemplateOptions,
    selectedCarrierDriverRate,
    getRelatedOrderListRequest,
    transportationModeGroupingList,
    suspendResumeCarrierRateEmailsRequest,
    createLoadMultipleStatusMessageRequest,
    getTransportationModeGroupingListSuccess,
  } = props;

  const carrier = G.isCurrentUserTypeCarrier();
  const activeListNamesToUse = G.ifElse(carrier, R.take(1, activeListNames), activeListNames);

  const eventsToUse = R.map(
    (event: Object) => {
      if (G.isLoadTypeClo(event)) {
        return R.assoc(
          GC.FIELD_LOAD_REFERENCES,
          R.filter(
            ({ eventGuid }: Object) => R.propEq(eventGuid, GC.FIELD_GUID, event),
            cloEventReferences,
          ),
          event,
        );
      }

      return R.assoc(
        GC.FIELD_LOAD_REFERENCES,
        R.filter(
          R.propEq(G.getGuidFromObject(event), GC.FIELD_EVENT_GUID),
          R.pathOr([], [GC.FIELD_LOAD_REFERENCES], loadDetails),
        ),
        event,
      );
    },
    events,
  );

  const referenceTypes = R.map(
    (item: Object) => ({ label: R.prop(GC.FIELD_NAME, item), value: R.prop(GC.FIELD_GUID, item) }),
    availableRefTypes,
  );

  const configCurrency = G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DEFAULT_CURRENCY);

  const autodialApp = G.getConfigValueFromStore(
    GC.UI_LOADBOARD_AUTODIALAPPNAME,
    R.path(['configGroups', GC.UI_CONFIG_GROUP, 'configs'], loadConfigs),
  );

  const commonComponentProps = {
    openModal,
    closeModal,
    openLoader,
    closeLoader,
    load: loadDetails,
    selectedCarrierDriverRate,
  };

  const cardProps = {
    ...commonComponentProps,
    loadConfigs,
    autodialApp,
    loadDocuments,
    invoiceTotals,
    linkedOrdersDocuments,
    documentTemplateOptions,
    suspendResumeCarrierRateEmailsRequest,
    normalizedCurrency: R.path([GC.SYSTEM_OBJECT_NORMALIZED_TOTAL, GC.FIELD_CURRENCY], selectedCustomerRate),
  };

  const tableGroupProps = {
    ...commonComponentProps,
    events,
    hiddenTabs,
    loadConfigs,
    loadDocuments,
    postedShipment,
    loadTotalWeight,
    linkedOrderList,
    changeActiveList,
    loadTotalDistance,
    selectedCustomerRate,
    linkedOrdersDocuments,
    selectedCarrierDriverRate,
    transportationModeGroupingList,
    getTransportationModeGroupingListSuccess,
  };

  const actionCenterProps = {
    ...commonComponentProps,
    events,
    loadConfigs,
    fromPage: 'details',
    selectedCarrierDriverRate,
  };

  const loadHeaderProps = {
    openModal,
    closeModal,
    openLoader,
    loadConfigs,
    closeLoader,
    configCurrency,
    load: loadDetails,
    fromPage: 'details',
    transportationModeGroupingList,
    totalDistance: loadTotalDistance,
    selectedRate: selectedCarrierDriverRate,
    getTransportationModeGroupingListSuccess,
    customerRateTransportationModeGuid: R.path([GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID], selectedCustomerRate),
  };

  const pageHeaderProps = {
    openModal,
    setActiveTab,
    load: loadDetails,
    [GC.FIELD_PRIMARY_REFERENCE_VALUE]: getPrimaryReferenceValue(loadDetails),
    showFast: G.getConfigValueFromStore(GC.UI_SHOW_FAST_LOAD_INDICATOR, loadConfigs.configsByNames),
  };

  const loadGuid = G.getGuidFromObject(loadDetails);
  const branchGuid = G.getBranchGuidFromObject(loadDetails);
  const loadType = G.getPropFromObject(GC.FIELD_LOAD_TYPE, loadDetails);
  const whiteColor = G.getTheme('colors.white');
  const greyBoxShadow = G.getTheme('colors.boxShadowGrey');
  const relatedDataList = R.concat(R.or(relatedData, []), R.or(linkedOrderList, []));

  return (
    <Box mx='auto' maxWidth={1550}>
      <EnhancedPageHeader {...pageHeaderProps} activeTab='general' />
      <Box
        mb={25}
        bg={whiteColor}
        borderRadius='4px'
        boxShadow={greyBoxShadow}
      >
        <EnhancedLoadHeader {...loadHeaderProps} />
        <GoToPlannerBtn {...props} />
        <DetailsEvents
          loadType={loadType}
          loadGuid={loadGuid}
          events={eventsToUse}
          branchGuid={branchGuid}
          loads={loadDetails.clos}
          loadConfigs={loadConfigs}
          referenceTypes={referenceTypes}
          fromPage={GC.PAGE_DISPATCH_DETAILS_NEW_LOAD}
          loadStatus={R.prop(GC.FIELD_STATUS, loadDetails)}
          createMultipleStatusMessage={createLoadMultipleStatusMessageRequest}
        />
        <AuthWrapper has={[PC.CLO_READ, PC.CLO_WRITE]}>
          <ToggleComponent
            opened={relatedDataOpened}
            toggle={handleToggleRelatedData}
            openedText={G.getWindowLocale('titles:hide-related-orders', 'Hide Related Orders')}
            closedText={G.getWindowLocale('titles:show-related-orders', 'Show Related Orders')}
          />
        </AuthWrapper>
        {
          relatedDataOpened &&
          <AuthWrapper display='block' has={[PC.CLO_READ, PC.CLO_WRITE]}>
            <RelatedDataTable
              loadType={loadType}
              itemList={relatedDataList}
              loading={relatedDataLoading}
              configCurrency={configCurrency}
              totalCount={R.pathOr(0, ['length'], relatedDataList)}
              columnSettings={getColumnSettings({
                openModal,
                closeModal,
                configCurrency,
                openFixedPopup,
                closeFixedPopup,
                loadType: GC.LOAD_TYPE_CLO,
                refresh: () => getRelatedOrderListRequest(loadGuid),
              })}
            />
          </AuthWrapper>
        }
      </Box>
      <Box
        mb={25}
        bg={whiteColor}
        borderRadius='4px'
        boxShadow={greyBoxShadow}
      >
        <ActionsCenter {...actionCenterProps} />
      </Box>
      <Grid
        gridGap={20}
        gridTemplateColumns='repeat(auto-fill, minmax(360px, 1fr))'
      >
        {
          cards.map(({ card, permissions }: Object, index: number) => (
            <AuthWrapper key={index} width='100%' has={permissions}>
              <LoadCardWrapper {...cardProps} cardType={card} relatedDataList={relatedDataList} />
            </AuthWrapper>
          ))
        }
      </Grid>
      {
        activeListNamesToUse.map((activeListName: string, index: number) => (
          <TableGroupWrapper
            {...tableGroupProps}
            key={index}
            tableNumber={index}
            activeListName={activeListName}
            expandedList={expandedLists[index]}
            itemList={R.path([activeListName, 'list'], activeLists)}
            totalCount={R.path([activeListName, 'totalCount'], activeLists)}
            pagination={R.path([activeListName, 'pagination'], activeLists)}
          />
        ))
      }
      {
        props.dispatchPlannerOpened && (
          <ModalWrapperWithZIndex zIndex={12}>
            <DispatchPlannerForTelDetails />
          </ModalWrapperWithZIndex>
        )
      }
      {
        props.dispatchPlannerEventsOpened && (
          <ModalWrapperWithZIndex zIndex={12}>
            <DispatchPlannerEventsForTelDetails />
          </ModalWrapperWithZIndex>
        )
      }
    </Box>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  events: makeSelectEvents(state),
  hiddenTabs: makeSelectHiddenTabs(state),
  loadDetails: makeSelectLoadDetails(state),
  loadConfigs: makeSelectLoadConfigs(state),
  activeLists: makeSelectActiveLists(state),
  expandedLists: makeSelectExpandedLists(state),
  loadDocuments: makeSelectLoadDocuments(state),
  relatedData: makeSelectRelatedOrderList(state),
  linkedOrderList: makeSelectLinkedOrderList(state),
  invoiceTotals: makeSelectLoadInvoiceTotals(state),
  loadTotalWeight: makeSelectLoadTotalWeight(state),
  activeListNames: makeSelectActiveListNames(state),
  postedShipment: makeSelectPostedShipmentsData(state),
  loadTotalDistance: makeSelectLoadTotalDistance(state),
  cloEventReferences: makeSelectCloEventReferences(state),
  selectedCustomerRate: makeSelectSelectedCustomerRate(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  documentTemplateOptions: makeSelectDocumentTemplates(state),
  relatedDataLoading: makeSelectRelatedOrderListLoading(state),
  dispatchPlannerOpened: makeSelectDispatchPlannerOpened(state),
  linkedOrdersDocuments: makeSelectLinkedOrdersDocuments(state),
  availableRefTypes: makeSelectAvailableReferenceTypesByScope(state),
  selectedCarrierDriverRate: makeSelectSelectedCarrierDriverRate(state),
  dispatchPlannerEventsOpened: makeSelectDispatchPlannerEventsOpened(state),
  transportationModeGroupingList: makeSelectLoadTransportationModeGroupingList(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  openLoader,
  closeLoader,
  changeActiveList,
  setOpenedFromPage,
  setInitialRouteGuid,
  setInitialPlannerData,
  setDispatchPlannerOpened,
  getLinkedOrderListRequest,
  openDispatchPlannerRequest,
  getRelatedOrderListRequest,
  setDispatchPlannerEventsOpened,
  openDispatchPlannerEventsRequest,
  suspendResumeCarrierRateEmailsRequest,
  setDispatchPlannerEventsOpenedFromPage,
  createLoadMultipleStatusMessageRequest,
  getTransportationModeGroupingListSuccess: getLoadTransportationModeGroupingListSuccess,
})(GeneralTab);

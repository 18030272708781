import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// ui
import { Box } from '../../../../../ui';
// feature config
import {
  factoringIntegrationFieldsMap,
  factoringIntegrationConfigFieldSettings,
  factoringIntegrationConfigValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const getFieldSettings = (factoringType: string) =>
  R.pick(R.prop(factoringType, factoringIntegrationFieldsMap), factoringIntegrationConfigFieldSettings);

const getMapPropsToValues = ({ initialValues }: Object) => {
  const defaultValues = R.map(
    ({ defaultValue = null }: Object) => defaultValue,
    factoringIntegrationConfigFieldSettings,
  );

  return G.setInitialFormikValues(defaultValues, initialValues);
};

const enhance = compose(
  withFormik({
    mapPropsToValues: getMapPropsToValues,
    validationSchema: factoringIntegrationConfigValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => {
      const factoringType = G.getPropFromObject(GC.FIELD_FACTORING_TYPE, values);
      const data = R.pick(
        R.concat(R.prop(factoringType, factoringIntegrationFieldsMap), GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR),
        values,
      );

      props.submitAction(data);
    },
  }),
  pure,
);

const FactoringIntegrationConfigForm = (props: Object) => (
  <Box p={15} overflowY='auto' maxHeight='calc(90vh - 60px)'>
    <form onSubmit={props.handleSubmit}>
      <Fieldset2
        {...props.optionsForSelect}
        {...G.getFormikProps(props)}
        fieldsWrapperStyles={{ pt: 11, flexDirection: 'column' }}
        handlers={{ disableIntegrationType: () => props.editMode }}
        fields={R.values(getFieldSettings(R.path(['values', GC.FIELD_FACTORING_TYPE], props)))}
      />
      <FormFooter />
    </form>
  </Box>
);

export default enhance(FactoringIntegrationConfigForm);

import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import { createStructuredSelector } from 'reselect';
import { pure, compose, branch, withHandlers, setDisplayName } from 'react-recompose';
// components
import { InfoPair } from '../../../components/info-pair';
import { TextComponent } from '../../../components/text';
import { ItemIcons } from '../../../components/item-icons';
import { FlexContainer } from '../../../components/table/ui';
import { LocalLoader } from '../../../components/local-loader';
import withTracking from '../../../components/tracking/with-tracking';
import { LoadStatusActions } from '../../../components/load-status-actions';
// features
import Notification2 from '../../notification2';
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
import { setExpandedContainerOptions } from '../../expanded-container/actions';
import { loadCustomStatusEnhance2 } from '../../dispatch-details-new/components/load-custom-status';
// forms
import { Checkbox } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, ArrowUp, ArrowDown, AbsoluteBox, RelativeFlex } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
import endpointsMap from '../../../utilities/endpoints';
// feature dispatch-board-new
import AsyncRateInfo from './async-rate-info';
import AsyncReferences from './async-references';
import withDispatchBoardLoadActions from '../hocs/with-load-actions';
import withDispatchBoardOrderActions from '../hocs/with-order-actions';
import { makeSelectConfigShowLocationInsteadLateDate } from '../selectors';
import { withAsyncDataOnDidMount } from '../hocs/with-async-data-on-did-mount';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const blueColor = G.getTheme('colors.dark.blue');
const mainRedColor = G.getTheme('colors.light.mainRed');

const ToggleArrow = ({ styles, expanded, handleToggle }: Object) => {
  if (R.not(expanded)) return <ArrowDown {...styles} onClick={handleToggle} />;

  return <ArrowUp {...styles} onClick={handleToggle} />;
};

const enhanceLeftCell = compose(
  withHandlers({
    handleShowNotifications: (props: Object) => (e: Event, notifications: Object) => {
      const {
        openFixedPopup,
        closeFixedPopup,
        hideNotifications,
        markAsReadTelNotification,
      } = props;

      openFixedPopup({
        version: 2,
        position: 'left',
        wrapperPadding: '0',
        el: e.currentTarget,
        boxStyles: { zIndex: 3 },
        content: (
          <Notification2
            closeFixedPopup={closeFixedPopup}
            readAction={markAsReadTelNotification}
            notifications={R.values(notifications)}
            hideAction={() => hideNotifications(R.path(['data', GC.FIELD_GUID], props))}
            title={`${G.getWindowLocale('actions:hide', 'Hide')}
                    ${G.getWindowLocale('titles:notifications', 'Notifications')}`
            }
          />
        ),
      });
    },
  }),
  pure,
);

export const ToggleEventsCell = ({ data, toggle }: Object) => {
  const guid = G.getGuidFromObject(data);
  const rateGuid = R.prop(GC.FIELD_SELECTED_RATE_GUID, data);
  const noDetails = G.isNilOrEmpty(R.omit(
    [GC.SYSTEM_LIST_ITEMS, GC.FIELD_CONTAINERS],
    R.pathOr({}, ['details'], data),
  ));
  const handleToggle = () => toggle({
    guid,
    rateGuid,
    noDetails,
    [GC.FIELD_PRIMARY_OBJECT_GUID]: guid,
    [GC.FIELD_BRANCH_GUID]: R.prop(GC.FIELD_BRANCH_GUID, data),
    data: {
      shownDetails: 'EVENTS',
      expanded: R.not(data.expanded),
    },
  });
  const anyAppointmentRequired = R.path([GC.FIELD_EVENTS_INFO, GC.FIELD_ANY_APPOINTMENT_REQUIRED], data);

  return (
    <Flex
      width='100%'
      height='100%'
      cursor='pointer'
      onClick={handleToggle}
      flexDirection='column'
      justifyContent='space-around'
    >
      {
        anyAppointmentRequired &&
        <Box title={G.getWindowLocale('titles:appointment-required', 'Appointment Required')}>
          {I.renderInfoAlertIcon(whiteColor, 14, 14, G.getTheme('colors.light.blue'), whiteColor)}
        </Box>
      }
      <ToggleArrow styles={{ color: 'colors.dark.blue' }} expanded={data.expanded} />
    </Flex>
  );
};

export const ToggleShipUnitCell = ({ data, toggle }: Object) => {
  const guid = G.getGuidFromObject(data);
  const noDetails = G.isNilOrEmpty(R.pick(
    [GC.SYSTEM_LIST_ITEMS, GC.FIELD_CONTAINERS],
    R.pathOr({}, ['details'], data),
  ));
  const handleToggle = () => toggle({
    guid,
    noDetails,
    [GC.FIELD_PRIMARY_OBJECT_GUID]: guid,
    [GC.FIELD_BRANCH_GUID]: R.prop(GC.FIELD_BRANCH_GUID, data),
    data: {
      shownDetails: 'SHIP_UNIT',
      expanded: R.not(data.expanded),
    },
  });
  const itemsCount = R.pathOr(0, [GC.FIELD_ITEMS_INFO, GC.FIELD_COUNT], data);
  const iconColor = G.getThemeByCond(G.isTrue(data.expanded), 'colors.dark.blue', 'colors.greyMatterhorn');

  return (
    <RelativeFlex cursor='pointer' onClick={handleToggle}>
      {I.itemBox(iconColor)}
      <AbsoluteBox
        height={16}
        // TODO: remove after testing
        // zIndex={11}
        fontSize={9}
        minWidth={16}
        bg={whiteColor}
        borderRadius={8}
        border='1px solid'
        color={iconColor}
        left='calc(100% - 7px)'
        justifyContent='center'
        borderColor={iconColor}
        bottom='calc(100% - 7px)'
      >
        {itemsCount}
      </AbsoluteBox>
    </RelativeFlex>
  );
};

export const CheckboxIconsCell = enhanceLeftCell((props: Object) => {
  const { data, toggle } = props;

  const guid = G.getGuidFromObject(data);
  const rateGuid = R.prop(GC.FIELD_SELECTED_RATE_GUID, data);
  const notifications = R.pathOr({}, ['notifications', guid], props);
  const anyHazardous = R.path([GC.FIELD_ITEMS_INFO, GC.FIELD_ANY_HAZARDOUS], data);
  const isCarrierRate = G.isNotNil(R.prop(GC.FIELD_SHOWN_CARRIER_ASSIGNMENT, data));
  const temperatureLow = R.path([GC.FIELD_ITEMS_INFO, GC.FIELD_ITEM_TEMPERATURE_LOW], data);
  const temperatureHigh = R.path([GC.FIELD_ITEMS_INFO, GC.FIELD_ITEM_TEMPERATURE_HIGH], data);
  const borderLeft = G.ifElse(G.isTrue(G.getPropFromObject(GC.FIELD_HOT, data)), '5px solid', null);

  const noDetails = G.isNilOrEmpty(R.omit(
    [GC.SYSTEM_LIST_ITEMS, GC.FIELD_CONTAINERS],
    R.pathOr({}, ['details'], data),
  ));

  const handleToggle = () => toggle({
    guid,
    rateGuid,
    noDetails,
    isCarrierRate,
    branchGuid: G.getBranchGuidFromObject(data),
    data: {
      shownDetails: 'ALL',
      expanded: R.not(data.expanded),
    },
  });

  const unreadCount = R.compose(
    R.length,
    R.filter((notification: Object) => R.not(notification.read)),
    R.values,
  )(notifications);

  return (
    <Flex
      p='5px'
      height='100%'
      flexDirection='column'
      justifyContent='center'
      borderLeft={borderLeft}
      borderColor={mainRedColor}
    >
      <Flex pb='6px' width='100%' justifyContent='flex-start'>
        <ToggleArrow
          expanded={data.expanded}
          handleToggle={handleToggle}
          styles={{ mr: '8px', color: 'colors.dark.blue' }}
        />
        {
          R.not(G.isCurrentUserTypeCarrier()) &&
          <Checkbox
            name={guid}
            type='checkbox'
            borderColor='colors.dark.grey'
            onChange={() => props.handleSelectLoad(guid)}
            checked={R.includes(guid, props.selectedList)}
          />
        }
        {
          G.isNotNilAndNotEmpty(notifications) &&
          <RelativeFlex
            mr='6px'
            ml='auto'
            cursor='pointer'
            onClick={(e: Object) => props.handleShowNotifications(e, notifications)}
          >
            {I.bellIcon()}
            <AbsoluteBox
              height={16}
              fontSize={9}
              minWidth={16}
              borderRadius={8}
              border='2px solid'
              color={whiteColor}
              left='calc(100% - 7px)'
              justifyContent='center'
              borderColor={whiteColor}
              bottom='calc(100% - 7px)'
              bg={G.getTheme('icons.iconColor')}
            >
              {unreadCount}
            </AbsoluteBox>
          </RelativeFlex>
        }
      </Flex>
      {
        G.isOneNotNilOrNotEmpty([anyHazardous, temperatureLow, temperatureHigh]) &&
        <ItemIcons
          anyHazardous={anyHazardous}
          temperatureLow={temperatureLow}
          temperatureHigh={temperatureHigh}
        />
      }
    </Flex>
  );
});

const enhanceReferences = compose(
  connect(null, { setExpandedContainerOptions }),
  withHandlers({
    handleOpenDetails: (props: Object) => () => {
      const { data, isClo, setExpandedContainerOptions } = props;

      const guid = G.getGuidFromObject(data);
      const componentType = G.ifElse(isClo, GC.PAGE_DISPATCH_DETAILS_NEW_ORDER, GC.PAGE_DISPATCH_DETAILS_NEW_LOAD);
      const openLoadInSidebar = G.getAmousConfigByNameFromWindow(GC.UI_OPEN_LOAD_IN_SIDEBAR);
      const route = G.ifElse(isClo, routesMap.dispatchDetailsOrder, routesMap.dispatchDetailsLoad)({ guid });
      const routePath = window.location.pathname;

      const routeGuid = R.compose(
        R.find((item: string) => R.equals(R.length(item), 36)),
        R.split('/'),
      )(routePath);

      const sideBarRoutes = [
        GC.ROUTE_PATH_TEL_REPORT_LIST,
        GC.ROUTE_PATH_CLO_REPORT_LIST,
        GC.ROUTE_PATH_CUSTOMER_PORTAL,
        GC.ROUTE_PATH_DISPATCH_BOARD_TEL,
        GC.ROUTE_PATH_DISPATCH_BOARD_CLO,
        GC.ROUTE_PATH_DRIVER_PAYROLL_LIST,
        GC.ROUTE_PATH_CLAIM_MANAGEMENT_LIST,
        routesMap.claimManagementClaimDetails(routeGuid),
      ];

      if (R.or(G.isFalse(openLoadInSidebar), G.notContain(routePath, sideBarRoutes))) {
        return G.goToRoute(route);
      }

      return setExpandedContainerOptions({ opened: true, componentType, visitPageGuid: guid, options: { guid } });
    },
    handleShowReferences: ({ data, isClo, openFixedPopup }: Object) => (e: Object) => {
      const guid = G.getGuidFromObject(data);
      const asyncEndpoint = G.ifElse(
        isClo,
        endpointsMap.cloReferenceList,
        endpointsMap.telReferenceList,
      );
      const asyncOptions = {
        params: G.ifElse(
          isClo,
          { [GC.FIELD_CLO_GUID]: guid },
          { [GC.FIELD_TEL_GUID]: guid },
        ),
      };
      openFixedPopup({
        version: 2,
        position: 'left',
        el: e.currentTarget,
        wrapperPadding: '0px',
        content: (
          <Flex
            bg='white'
            width='100%'
            maxHeight={500}
            overflowY='auto'
            height='max-content'
            flexDirection='column'
            alignItems='flex-start'
          >
            <AsyncReferences asyncOptions={asyncOptions} asyncEndpoint={asyncEndpoint} />
          </Flex>
        ),
      });
    },
  }),
  pure,
);

export const PrimaryReferenceCell = enhanceReferences(({ data, handleOpenDetails, handleShowReferences }: Object) => {
  const primRef = R.prop(GC.FIELD_PRIMARY_REFERENCE_VALUE, data);
  const secRef = R.pathOr([], [GC.FIELD_SECONDARY_REF_VALUE], data);

  return (
    <Box>
      <TextComponent
        maxWidth='100%'
        display='block'
        title={primRef}
        cursor='pointer'
        withEllipsis={true}
        fontSize={[11, 11, 12, 13]}
        onClick={handleOpenDetails}
        color={G.getTheme('colors.dark.blue')}
      >
        {primRef}
      </TextComponent>
      {
        G.isNotEmpty(secRef) &&
        <TextComponent
          mt='6px'
          maxWidth='100%'
          display='block'
          cursor='pointer'
          withEllipsis={true}
          title={R.join(', ', secRef)}
          onClick={handleShowReferences}
          color={G.getTheme('dispatchBoardPopper.textColor')}
        >
          {R.join(', ', secRef)}
        </TextComponent>
      }
    </Box>
  );
});

const enhanceCloBranches = compose(
  withHandlers({
    handleShowBranches: ({ openFixedPopup }: Object) => (e: Object, branches: Array, hasTelPermissions: boolean) => (
      openFixedPopup({
        version: 2,
        position: 'left',
        el: e.currentTarget,
        content: (
          <Flex
            mt='4px'
            p='6px 8px'
            width='100%'
            bg={whiteColor}
            maxHeight={500}
            overflowY='auto'
            height='max-content'
            flexDirection='column'
            alignItems='flex-start'
          >
            {
              branches.map((data: Object, i: number) => {
                const branch = G.getBranchFromWindowByGuid(R.prop(GC.FIELD_BRANCH_GUID, data));

                return (
                  <Flex mb='4px' key={i}>
                    {
                      hasTelPermissions &&
                      <Flex>
                        <Box mr='6px'>{G.getWindowLocale('titles:tel')}:</Box>
                        <Box
                          mr='6px'
                          cursor='pointer'
                          color={blueColor}
                          fontWeight='bold'
                          onClick={() => G.goToRoute(routesMap.dispatchDetailsLoad(G.getGuidFromObject(data)))}
                        >
                          {R.prop(GC.FIELD_PRIMARY_REFERENCE_VALUE, data)}
                        </Box>
                      </Flex>
                    }
                    <Box> - {G.isNotNil(branch) && R.prop(GC.FIELD_BRANCH_NAME, branch)}</Box>
                  </Flex>
                );
              })
            }
          </Flex>
        ),
      })
    ),
  }),
  pure,
);

const CloBranchesInfo = enhanceCloBranches(({ data, handleShowBranches }: Object) => {
  const singleTel = R.pathEq(1, ['telsInfo', 'length'], data);
  const divisionLabel = G.ifElse(
    singleTel,
    G.getWindowLocale('titles:division', 'Division'),
    G.getWindowLocale('titles:divisions', 'Divisions'),
  );
  let divisionValue;

  if (singleTel) {
    const branch = G.getBranchFromWindowByGuid(R.path(['telsInfo', 0, GC.FIELD_BRANCH_GUID], data));
    if (R.isNil(branch)) {
      divisionValue = 0;
    } else {
      divisionValue = R.prop(GC.FIELD_BRANCH_NAME, branch);
    }
  } else {
    divisionValue = R.pathOr(0, ['telsInfo', 'length'], data);
  }

  const clickHandler = (e: Object) => {
    if (G.isNilOrEmpty(data.telsInfo)) return;

    const hasTelPermissions = G.hasAmousCurrentUserPermissions([
      PC.TEL_READ,
      PC.TEL_WRITE,
    ]);
    handleShowBranches(e, data.telsInfo, hasTelPermissions);
  };

  const hasTelBranchPermissions = G.hasAmousCurrentUserPermissions([
    PC.TEL_ENTERPRISE_READ,
    PC.TEL_ENTERPRISE_WRITE,
  ]);
  const hasCloBranchPermissions = G.hasAmousCurrentUserPermissions([
    PC.CLO_ENTERPRISE_READ,
    PC.CLO_ENTERPRISE_WRITE,
  ]);

  return (
    <Flex alignItems='flex-start' flexDirection='column'>
      {
        hasCloBranchPermissions &&
        <Box mb='6px' wordBreak='break-word'>{R.path([GC.FIELD_BRANCH, GC.FIELD_BRANCH_NAME], data)}</Box>
      }
      {
        hasTelBranchPermissions &&
        <Box cursor='pointer' wordBreak='break-word' onClick={clickHandler}>{divisionLabel}: {divisionValue}</Box>
      }
    </Flex>
  );
});

const TelBranchesWithAsyncCloBillTo = withAsyncDataOnDidMount(({ branches, asyncData, asyncLoading }: Object) => {
  const billToObject = R.indexBy(R.prop(GC.FIELD_LOAD_GUID), R.or(asyncData, []));
  const hasCloPermissions = G.hasAmousCurrentUserPermissions([
    PC.CLO_READ,
    PC.CLO_WRITE,
  ]);
  const hasCloBillToPermissions = G.hasAmousCurrentUserPermissions([
    PC.CLO_BILL_TO_READ,
    PC.CLO_BILL_TO_WRITE,
  ]);

  return (
    <LocalLoader minWidth={400} localLoaderOpen={R.or(R.isNil(asyncData), asyncLoading)}>
      <Flex
        mt='4px'
        p='4px 8px'
        width='100%'
        fontSize={12}
        bg={whiteColor}
        maxHeight={500}
        overflowY='auto'
        height='max-content'
        flexDirection='column'
        alignItems='flex-start'
      >
        {
          branches.map((data: Object, i: number) => {
            const branchName = R.prop(GC.FIELD_BRANCH_NAME, data);
            const cloGuid = G.getGuidFromObject(data);
            const billTo = R.path([cloGuid], billToObject);
            const borderLeft = G.ifElse(G.isTrue(G.getPropFromObject(GC.FIELD_HOT, data)), '3px solid', null);
            let billToText = '';

            if (G.isNotNil(billTo)) {
              billToText = G.renderFullAddressString(billTo);
            }

            return (
              <Flex
                key={i}
                mb='4px'
                pl='4px'
                flexDirection='column'
                borderLeft={borderLeft}
                alignItems='flex-start'
                borderColor={mainRedColor}
              >
                <Flex>
                  {
                    hasCloPermissions &&
                    <Flex>
                      <Box mr='6px'>{G.getWindowLocale('titles:clo')}:</Box>
                      <Box
                        mr='6px'
                        cursor='pointer'
                        color={blueColor}
                        fontWeight='bold'
                        onClick={() => G.goToRoute(routesMap.dispatchDetailsOrder(cloGuid))}
                      >
                        {R.prop(GC.FIELD_PRIMARY_REFERENCE_VALUE, data)}
                      </Box>
                    </Flex>
                  }
                  <Box mr='8px'> - {G.isNotNil(branchName) && branchName}</Box>
                </Flex>
                {
                  hasCloBillToPermissions &&
                  <Box ml={40}>{G.getWindowLocale('titles:bill-to')}: {billToText}</Box>
                }
              </Flex>
            );
          })
        }
      </Flex>
    </LocalLoader>
  );
});

const enhanceTelBranches = compose(
  withHandlers({
    handleShowBranches: ({ openFixedPopup }: Object) => (e: Object, branches: Array) => (
      openFixedPopup({
        version: 2,
        position: 'left',
        el: e.currentTarget,
        content: (
          <TelBranchesWithAsyncCloBillTo
            asyncMethod='post'
            branches={branches}
            asyncEndpoint={endpointsMap.cloBillToList}
            asyncOptions={{ data: R.map(G.getGuidFromObject, branches) }}
          />
        ),
      })
    ),
  }),
  pure,
);

const TelBranchesInfo = enhanceTelBranches(({ data, handleShowBranches }: Object) => {
  const singleClo = R.pathEq(1, ['closInfo', 'length'], data);
  const customerLabel = G.ifElse(
    singleClo,
    G.getWindowLocale('titles:customer', 'Customer'),
    G.getWindowLocale('titles:customers', 'Customers'),
  );
  let customerValue;

  if (singleClo) {
    const branch = R.path(['closInfo', 0, GC.FIELD_BRANCH_NAME], data);

    if (R.isNil(branch)) {
      customerValue = 0;
    } else {
      customerValue = branch;
    }
  } else {
    customerValue = R.pathOr(0, ['closInfo', 'length'], data);
  }

  const clickHandler = (e: Object) => {
    if (G.isNilOrEmpty(data.closInfo)) return;

    handleShowBranches(e, data.closInfo);
  };

  const hasTelBranchPermissions = G.hasAmousCurrentUserPermissions([
    PC.TEL_ENTERPRISE_READ,
    PC.TEL_ENTERPRISE_WRITE,
  ]);
  const hasCloBranchPermissions = G.hasAmousCurrentUserPermissions([
    PC.CLO_ENTERPRISE_READ,
    PC.CLO_ENTERPRISE_WRITE,
  ]);

  return (
    <Flex alignItems='flex-start' flexDirection='column'>
      {
        hasTelBranchPermissions &&
        <Box mb='6px' wordBreak='break-word'>{R.path([GC.FIELD_BRANCH, GC.FIELD_BRANCH_NAME], data)}</Box>
      }
      {
        hasCloBranchPermissions &&
        <Box cursor='pointer' wordBreak='break-word' onClick={clickHandler}>{customerLabel}: {customerValue}</Box>
      }
    </Flex>
  );
});

export const BranchCell = ({ data, isClo, openFixedPopup }: Object) => {
  if (isClo) return <CloBranchesInfo data={data} openFixedPopup={openFixedPopup} />;

  return <TelBranchesInfo data={data} openFixedPopup={openFixedPopup} />;
};

export const LoadCustomStatus = ({ data, handleSetLoadCustomStatus }: Object) => {
  const customStatus = G.getPropFromObject(GC.FIELD_CUSTOM_STATUS, data);

  if (G.isNilOrEmpty(customStatus)) return null;

  const text = G.getPropFromObject(GC.FIELD_NAME, customStatus);

  return (
    <TextComponent
      mt='1px'
      title={text}
      maxWidth='100%'
      display='block'
      cursor='pointer'
      fontWeight='bold'
      withEllipsis={true}
      onClick={handleSetLoadCustomStatus}
      color={G.getLoadCustomStatusColor(customStatus)}
    >
      {text}
    </TextComponent>
  );
};

const ArrivedAtPickup = ({ data }: Object) => {
  const eventStatusArrived = R.includes(
    R.path([GC.SYSTEM_OBJECT_FIRST_EVENT, GC.FIELD_STATUS], data),
    [GC.STOP_STATUS_TYPE_ARRIVED, GC.STOP_STATUS_TYPE_CHECKED_IN],
  );

  if (R.not(eventStatusArrived)) return null;

  const text = G.getWindowLocale('titles:arrived-at-pickup', 'Arrived at Pickup');

  return (
    <TextComponent
      mt='1px'
      title={text}
      maxWidth='100%'
      display='block'
      withEllipsis={true}
    >
      {text}
    </TextComponent>
  );
};

const LateTimeToNextStop = ({ data }: Object) => {
  const { estimatedLateTimeToNextStop } = data;

  if (G.isNilOrEmpty(estimatedLateTimeToNextStop)) return null;

  const text = `${G.getWindowLocale('titles:late', 'Late')}:
    ${G.getFormattedDurationFromString(estimatedLateTimeToNextStop, true)}`;

  return (
    <TextComponent
      mt='1px'
      title={text}
      maxWidth='100%'
      display='block'
      withEllipsis={true}
    >
      {text}
    </TextComponent>
  );
};

export const StatusCell = loadCustomStatusEnhance2((props: Object) => {
  const { data, handleSetLoadCustomStatus } = props;

  const { status } = data;

  return (
    <Flex flexDirection='column' alignItems='flex-start'>
      <Box
        cursor='pointer'
        onClick={handleSetLoadCustomStatus}
        color={G.getTheme(`status.${status}`)}
      >
        {G.getWindowLocale(GC.statusLocaleMap[status], status)}
        {
          R.and(
            R.equals(status, GC.LOAD_STATUS_CANCELED),
            G.isNotNil(R.path([GC.FIELD_LOAD_CANCEL_REASON], data)),
          ) &&
          <div title={R.path([GC.FIELD_LOAD_CANCEL_REASON, GC.FIELD_DISPLAYED_VALUE], data)}>
            {R.path([GC.FIELD_LOAD_CANCEL_REASON, GC.FIELD_DISPLAYED_VALUE], data)}
          </div>
        }
      </Box>
      <LoadCustomStatus data={data} handleSetLoadCustomStatus={handleSetLoadCustomStatus} />
      <ArrivedAtPickup data={data} />
      <LateTimeToNextStop data={data} />
    </Flex>
  );
});

const enhanceClaim = compose(
  connect(null, { setExpandedContainerOptions }),
  withHandlers({
    handleOpenDetails: ({ data, setExpandedContainerOptions }: Object) => (guid) => {
      setExpandedContainerOptions({
        opened: true,
        visitPageGuid: guid,
        componentType: GC.PAGE_TYPE_CLAIM__DETAILS,
        options: { [GC.FIELD_GUID]: G.getGuidFromObject(data) },
      });
    },
  }),
);

const ClaimItem = ({ mt = '3px', mr = '0px', claim, handleOpenDetails }: Object) => {
  if (R.isNil(claim)) return null;

  const { guid, status, subStatus, claimDate, claimNumber } = claim;

  const claimStatus = G.getDisplayedValueFromObject(status);
  const claimSubStatus = G.getDisplayedValueFromObject(subStatus);

  return (
    <Fragment>
      <Flex mr={mr}>
        {G.getWindowLocale('titles:claim', 'Claim')}:
        <TextComponent
          ml='5px'
          cursor='pointer'
          fontWeight='bold'
          color='dark.blue'
          title={claimNumber}
          withEllipsis={true}
          maxWidth='calc(100% - 40px)'
          onClick={() => handleOpenDetails(guid)}
        >
          {claimNumber}
        </TextComponent>
      </Flex>
      <Flex mt={mt} mr={mr}>
        {G.getWindowLocale('titles:date', 'Date')}:
        <TextComponent ml='5px' fontWeight='bold' color='light.black'>
          {claimDate}
        </TextComponent>
      </Flex>
      <Flex mt={mt}>
        {G.getWindowLocale('titles:status', 'Status')}:
        <TextComponent
          ml='5px'
          fontWeight='bold'
          title={claimStatus}
          color='light.black'
          withEllipsis={true}
          maxWidth='calc(100% - 40px)'
        >
          {claimStatus}
        </TextComponent>
      </Flex>
      {
        G.isNotNilAndNotEmpty(claimSubStatus) &&
        <Flex mt={mt}>
          {G.getWindowLocale('titles:sub-status', 'Sub Status')}:
          <TextComponent
            ml='5px'
            fontWeight='bold'
            color='light.black'
            withEllipsis={true}
            title={claimSubStatus}
            maxWidth='calc(100% - 62px)'
          >
            {claimSubStatus}
          </TextComponent>
        </Flex>
      }
    </Fragment>
  );
};

const MultipleClaims = (props: Object) => {
  const { closInfo, openFixedPopup, handleOpenDetails } = props;

  const handleOpen = (event: Object) => openFixedPopup({
    version: 2,
    position: 'left',
    wrapperPadding: '0',
    el: event.currentTarget,
    boxStyles: { zIndex: 3 },
    content: (
      <Box p='0 10px 10px 10px'>
        {closInfo.map(({ claim }: Object, index: number) => (
          <Flex mt={10} key={index}>
            <ClaimItem mt='0px' mr='10px' claim={claim} handleOpenDetails={handleOpenDetails} />
          </Flex>
        ))}
      </Box>
    ),
  });

  return (
    <Box
      ontSize={12}
      cursor='pointer'
      fontWeight='bold'
      color='light.mainRed'
      onClick={handleOpen}
    >
      {G.getWindowLocale('titles:multiple-claims', 'Multiple Claims')}
    </Box>
  );
};

export const ClaimCell = enhanceClaim((props: Object) => {
  const { data, isClo, openFixedPopup, handleOpenDetails } = props;

  const { claim, closInfo } = data;

  if (isClo) return <ClaimItem claim={claim} handleOpenDetails={handleOpenDetails} />;

  const closWithClaims = R.filter(R.prop(GC.SYSTEM_OBJECT_CLAIM), R.or(closInfo, []));

  const isMultiple = R.lt(1, R.length(closWithClaims));

  if (isMultiple) {
    return <MultipleClaims closInfo={closInfo} openFixedPopup={openFixedPopup} handleOpenDetails={handleOpenDetails} />;
  }

  return (
    <ClaimItem handleOpenDetails={handleOpenDetails} claim={R.prop(GC.SYSTEM_OBJECT_CLAIM, R.head(closWithClaims))} />
  );
});

const StopCell = ({ address, earlyDate, locationOrLateDate }: Object) => (
  <Box>
    <Box height={16}>{earlyDate}</Box>
    <TextComponent
      fontSize={11}
      maxWidth='100%'
      display='block'
      withEllipsis={true}
      title={locationOrLateDate}
    >
      {locationOrLateDate}
    </TextComponent>
    <TextComponent
      fontSize={11}
      maxWidth='100%'
      display='block'
      title={address}
      withEllipsis={true}
    >
      {address}
    </TextComponent>
  </Box>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  showLocationName: makeSelectConfigShowLocationInsteadLateDate(state),
});

const enhanceStopCell = connect(mapStateToProps);

export const FirstPickupCell = enhanceStopCell(({ data, showLocationName }: Object) => {
  const { firstEvent } = data;

  const earlyDate = G.getEventEarlyDateTime(firstEvent);

  const lateDate = G.getEventLateDateTime(firstEvent);

  const locationOrLateDate = G.ifElse(
    showLocationName,
    R.pathOr('-', [GC.SYSTEM_OBJECT_FIRST_EVENT, GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LOCATION_NAME], data),
    lateDate,
  );

  const address = G.renderSmallAddressString(R.pathOr({}, [GC.SYSTEM_OBJECT_FIRST_EVENT, GC.FIELD_LOCATION], data));

  return <StopCell address={address} earlyDate={earlyDate} locationOrLateDate={locationOrLateDate} />;
});

export const LastDropCell = enhanceStopCell(({ data, showLocationName }: Object) => {
  const { lastEvent } = data;

  const earlyDate = G.getEventEarlyDateTime(lastEvent);

  const lateDate = G.getEventLateDateTime(lastEvent);

  const locationOrLateDate = G.ifElse(
    showLocationName,
    R.pathOr('-', [GC.SYSTEM_OBJECT_LAST_EVENT, GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LOCATION_NAME], data),
    lateDate,
  );

  const address = G.renderSmallAddressString(R.pathOr({}, [GC.SYSTEM_OBJECT_LAST_EVENT, GC.FIELD_LOCATION], data));

  return <StopCell address={address} earlyDate={earlyDate} locationOrLateDate={locationOrLateDate} />;
});

const renderAssignment = (data: Object) => {
  if (R.isNil(R.prop(GC.FIELD_SHOWN_FLEET_ASSIGNMENT, data))) {
    return R.pathOr('-', [GC.FIELD_SHOWN_CARRIER_ASSIGNMENT, GC.FIELD_SHOWN_ASSIGNMENT_CARRIER_NAME], data);
  }

  const primaryDriver = R.path([GC.FIELD_SHOWN_FLEET_ASSIGNMENT, GC.FIELD_PRIMARY_DRIVER], data);

  if (R.isNil(primaryDriver)) return '-';

  return `${R.propOr('', GC.FIELD_FIRST_NAME, primaryDriver)} ${R.propOr('', GC.FIELD_LAST_NAME, primaryDriver)}`;
};

export const CarrierInfo = ({ data }: Object) => {
  let textColor = '';

  const name = R.pathOr('-', [GC.FIELD_SHOWN_CARRIER_ASSIGNMENT, GC.FIELD_SHOWN_ASSIGNMENT_CARRIER_NAME], data);
  const trackingNumber = R.path([GC.FIELD_SHOWN_CARRIER_ASSIGNMENT, GC.FIELD_TRACKING_NUMBER], data);
  const driverTrackingAppInstalled = R.pathOr(
    false,
    [GC.FIELD_SHOWN_CARRIER_ASSIGNMENT, GC.FIELD_DRIVER_TRACKING_APP_INSTALLED],
    data,
  );

  if (G.isTrue(driverTrackingAppInstalled)) {
    textColor = G.getTheme('colors.light.green');
  }

  return (
    <Flex alignItems='flex-start' flexDirection='column'>
      <Box color={textColor} wordBreak='break-word'>{name}</Box>
      {
        trackingNumber &&
        <InfoPair
          fontSize={10}
          lineHeight={1}
          flexWrap='wrap'
          textMaxWidth={150}
          text={trackingNumber}
          label={G.getWindowLocale('titles:tracking-#', 'Tracking #')}
        />
      }
    </Flex>
  );
};

const enhanceCarrierFleetCell = compose(
  withTracking,
  withHandlers({
    handleShowAssignment: ({ data, isClo, openFixedPopup }: Object) => (e: Object) => {
      const guid = G.getGuidFromObject(data);
      const asyncEndpoint = G.ifElse(
        isClo,
        endpointsMap.rateInfoByCloGuid,
        endpointsMap.rateInfoByTelGuid,
      );
      const asyncOptions = {
        params: G.ifElse(
          isClo,
          { [GC.FIELD_CLO_GUID]: guid },
          { [GC.FIELD_TEL_GUID]: guid },
        ),
      };
      openFixedPopup({
        version: 2,
        position: 'left',
        el: e.currentTarget,
        wrapperPadding: '0px',
        boxStyles: { zIndex: 2 },
        content: (
          <AsyncRateInfo isClo={isClo} asyncOptions={asyncOptions} asyncEndpoint={asyncEndpoint} />
        ),
      });
    },
  }),
  pure,
);

const Tracking = (props: Object) => {
  const { data, handleShowTracking } = props;

  const guid = G.getGuidFromObject(data);
  const driverTracked = R.path(['shownFleetAssignment', 'driverTracked'], data);
  const carrierDriverTracked = R.path(['shownCarrierAssignment', 'driverTracked'], data);

  const Icon = () =>
    G.ifElse(
      R.or(driverTracked, carrierDriverTracked),
      I.trackingEnabled(),
      I.trackingDisabled(),
    );

  return (
    <Box
      cursor='pointer'
      onClick={() => handleShowTracking({ guid })}
    >
      <Icon />
    </Box>
  );
};

export const TelCarrierFleetCell = enhanceCarrierFleetCell((props: Object) => {
  const { data, handleShowAssignment, handleShowTracking } = props;

  return (
    <FlexContainer direction='row' justify='space-between' align='center'>
      <Box
        cursor='pointer'
        color={blueColor}
        wordBreak='break-word'
        onClick={handleShowAssignment}
      >
        {R.isNil(R.prop(GC.FIELD_SHOWN_FLEET_ASSIGNMENT, data)) ? <CarrierInfo data={data} /> : renderAssignment(data)}
      </Box>
      {R.not(data.withoutTracking) && <Tracking data={data} handleShowTracking={handleShowTracking} />}
    </FlexContainer>
  );
});

export const CloCarrierFleetCell = enhanceCarrierFleetCell(({ data, handleShowAssignment }: Object) => {
  const multiCarriers = R.compose(
    R.lt(1),
    R.pathOr(0, ['telsInfo', 'length']),
  )(data);

  const noFleetAssignment = R.isNil(R.prop(GC.FIELD_SHOWN_FLEET_ASSIGNMENT, data));

  const noAssignment = R.and(
    noFleetAssignment,
    R.isNil(R.path([GC.FIELD_SHOWN_CARRIER_ASSIGNMENT, GC.FIELD_SHOWN_ASSIGNMENT_CARRIER_NAME], data)),
  );

  if (R.and(noFleetAssignment, R.isNil(R.prop(GC.FIELD_SHOWN_CARRIER_ASSIGNMENT, data)))) {
    return <div>-</div>;
  }

  return (
    <Box cursor='pointer' color={blueColor} onClick={handleShowAssignment}>
      { noFleetAssignment && <CarrierInfo data={data} /> }
      { R.not(noFleetAssignment) && renderAssignment(data) }
      {
        R.and(multiCarriers, R.not(noAssignment)) &&
        G.getWindowLocale('titles:multiple-carriers', 'Multiple Carriers')
      }
    </Box>
  );
});

const getCloTelCostText = (load: Object, distance: number, isClo: boolean) => {
  const currency = G.getCurrencySymbol(R.pathOr(
    GC.DEFAULT_UI_CURRENCY,
    [0, GC.FIELD_CURRENCY],
    R.pathOr([], [GC.FIELD_FINANCE_COST_ALLOCATIONS], load),
  ));

  const costProp = isClo ? GC.FIELD_CLO_COST : GC.FIELD_TEL_COST;
  const total = G.mathRoundNumber(G.getCostFromLoadCostAllocations(load, costProp));
  const label = isClo ? G.getWindowLocale('titles:clo', 'Clo') : G.getWindowLocale('titles:tel', 'Tel');

  const rpmText = G.isNotZero(total) ? `(${G.getRpmByUomSystem(distance, total, currency)})` : '';

  return `${label}: ${currency} ${total} ${rpmText}`;
};

const FinancialOdds = ({ load, isTel, toggle, permissions }: Object) => {
  const { earnings, storedTotalDistance, rateMarginViolationInfo } = load;

  let displayText = '-';
  let textColor = '';

  if (G.isNotNilAndNotEmpty(R.path([GC.FIELD_TOTAL], earnings))) {
    const currencySymbol = G.getCurrencySymbol(earnings.currency);

    displayText = `${currencySymbol} ${G.mathRoundNumber(earnings.total)}`;

    if (isTel) {
      textColor = G.getTheme(G.ifElse(
        G.isAnyTrue(
          R.lt(earnings.total, 0),
          G.isTrue(G.getPropFromObject(GC.FIELD_MIN_MARGIN_VIOLATED, rateMarginViolationInfo)),
          G.isTrue(G.getPropFromObject(GC.FIELD_CRITICAL_MARGIN_VIOLATED, rateMarginViolationInfo)),
        ),
        'colors.light.mainRed',
        'colors.light.green',
      ));
    } else {
      textColor = G.getTheme(G.ifElse(
        R.lt(earnings.total, 0),
        'colors.light.mainRed',
        'colors.light.green',
      ));
    }
  }

  const noDetails = G.isNilOrEmpty(R.omit(
    [GC.SYSTEM_LIST_ITEMS, GC.FIELD_CONTAINERS],
    R.pathOr({}, ['details'], load),
  ));
  const handleToggle = () => G.callFunctionWithArgs(
    toggle,
    {
      noDetails,
      guid: G.getGuidFromObject(load),
      rateGuid: R.prop(GC.FIELD_SELECTED_RATE_GUID, load),
      branchGuid: R.path([GC.BRANCH_LOWERCASE, GC.FIELD_GUID], load),
      data: {
        shownDetails: 'CARDS',
        expanded: R.not(load.expanded),
      },
    },
  );

  return (
    <AuthWrapper
      zIndex='unset'
      has={permissions}
    >
      <Flex onClick={handleToggle} cursor={G.ifElse(toggle, 'pointer', null)}>
        <Box>
          <AuthWrapper has={[PC.FLEET_RATE_READ, PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_READ, PC.CARRIER_RATE_WRITE]}>
            {getCloTelCostText(load, storedTotalDistance)}
          </AuthWrapper>
          <AuthWrapper has={[PC.CLO_RATE_READ, PC.CLO_RATE_WRITE]}>
            {getCloTelCostText(load, storedTotalDistance, true)}
          </AuthWrapper>
          <Box color={textColor}>
            {G.getWindowLocale('titles:margin', 'Margin')}: {displayText}
          </Box>
        </Box>
        {
          G.isFunction(toggle) &&
          <ToggleArrow expanded={load.expanded} styles={{ ml: '8px', color: 'colors.dark.blue' }} />
        }
      </Flex>
    </AuthWrapper>
  );
};

export const TelEarningsCell = ({ data, toggle, configCurrency }: Object) => (
  <Box>
    <AuthWrapper has={[PC.FLEET_RATE_READ, PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_READ, PC.CARRIER_RATE_WRITE]}>
      <FinancialOdds
        load={data}
        isTel={true}
        toggle={toggle}
        configCurrency={configCurrency}
        permissions={[PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE]}
      />
    </AuthWrapper>
  </Box>
);

export const CloEarningsCell = ({ data, toggle, configCurrency }: Object) => (
  <Box>
    <AuthWrapper has={[PC.CLO_RATE_READ, PC.CLO_RATE_WRITE]}>
      <FinancialOdds
        load={data}
        toggle={toggle}
        configCurrency={configCurrency}
      />
    </AuthWrapper>
  </Box>
);

const StatusActionsContent = (props: Object) => {
  const { data, loadType, handleActionClick, closeFixedPopup, statusActionsObject } = props;

  let actions = statusActionsObject;
  let withoutActions = [];

  if (R.and(
    R.pathEq(GC.LOAD_STATUS_CANCELED, [GC.FIELD_STATUS], data),
    G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_SELECTED_RATE_GUID, data)),
  )) {
    withoutActions = R.append(GC.ADD_DRIVER_CARRIER_INVOICE, withoutActions);
  }

  if (R.not(R.path([GC.FIELD_SEND_TO_EDI], data))) {
    withoutActions = R.append(GC.SEND_UPDATE_EDI_OR_API_ACTION, withoutActions);
  }

  const isFleetRate = G.isNotNilAndNotEmpty(G.getPropFromObject(GC.FIELD_SHOWN_FLEET_ASSIGNMENT, data));
  const isCarrierRate = G.isNotNilAndNotEmpty(G.getPropFromObject(GC.FIELD_SHOWN_CARRIER_ASSIGNMENT, data));

  if (R.and(G.isLoadTypeTel(loadType), R.or(isFleetRate, isCarrierRate))) {
    if (G.getHasNotEditRatePermissionsByRateType(isCarrierRate)) {
      withoutActions = R.append(GC.EDIT_DRIVER_CARRIER_RATE_ACTION, withoutActions);
    }
  }

  const firstRow = R.without(withoutActions, actions.firstRow);
  const secondRow = R.without(withoutActions, actions.secondRow);
  actions = R.mergeRight(actions, { firstRow, secondRow });
  const load = R.mergeRight(data, G.getPropFromObject(GC.SYSTEM_OBJECT_RATE_MARGIN_VIOLATION_INFO, data));

  const StatusActions = () => (
    <Box p='2px 8px' height={53} boxShadow={`inset 2px 0 0 0 ${G.getTheme('colors.boxShadowLightGrey')}`}>
      <LoadStatusActions
        load={load}
        data={actions}
        loadType={loadType}
        handleActionClick={(actionName: string) => {
          handleActionClick(actionName, data);
          closeFixedPopup();
        }}
      />
    </Box>
  );

  return <StatusActions />;
};

const withActions = compose(
  branch(
    ({ loadType }: Object) => R.equals(loadType, GC.LOAD_TYPE_TEL),
    withDispatchBoardLoadActions,
    withDispatchBoardOrderActions,
  ),
  pure,
);

const enhanceStatusActionsCell = compose(
  withActions,
  setDisplayName('enhanceStatusActionsCell -> StatusActionsCell'),
  withHandlers({
    handleShowStatusActions: (props: Object) => (e: Object) => (
      props.openFixedPopup({
        version: 2,
        minusTop: 52,
        minusRight: -35,
        position: 'right',
        el: e.currentTarget,
        content: (
          <StatusActionsContent {...props} />
        ),
      })
    ),
  }),
  pure,
);

export const StatusActionsCell = enhanceStatusActionsCell((props: Object) => {
  const { statusActionsObject, handleShowStatusActions } = props;

  if (G.isNilOrEmpty(statusActionsObject)) return null;

  return (
    <Flex p='8px'>
      <Box
        p='4px'
        bg={blueColor}
        cursor='pointer'
        borderRadius='3px'
        color={whiteColor}
        onClick={handleShowStatusActions}
      >{G.getWindowLocale('titles:actions')}</Box>
    </Flex>
  );
});

export const MessagesNotesCell = (props: Object) => {
  const {
    data,
    loadType,
    openModal,
    closeModal,
    clickHandler,
    hideReferences,
    pinnedNoteStyles,
    handleShowLoadReferences,
  } = props;

  const inactiveIconColor = G.getTheme('colors.dark.grey');
  const noteText = R.prop(GC.FIELD_PINNED_NOTE_TEXT, data);
  const noteIconColor = G.ifElse(R.isNil(noteText), inactiveIconColor, blueColor);
  const noteTitle = G.ifElse(
    G.isNotNilAndNotEmpty(noteText),
    noteText,
    G.getWindowLocale('titles:add-note', 'Add Note'),
  );

  const notCarrierOrCustomerUser = R.and(R.not(G.isCurrentUserTypeCarrier()), R.not(G.isCurrentUserTypeCustomer()));
  const lastChatAuthorType = R.prop(GC.FIELD_LAST_CHAT_AUTHOR_TYPE, data);
  const isAuthorTypeCarrierOrDriver = G.isChatMessageAuthorTypeCarrierOrDriver(lastChatAuthorType);

  const notCarrierPortalAndAuthorTypeCarrierOrDriver = R.and(notCarrierOrCustomerUser, isAuthorTypeCarrierOrDriver);
  const carrierPortalAndAuthorNotTypeCarrierOrDriver = R.and(
    R.not(notCarrierOrCustomerUser), R.not(isAuthorTypeCarrierOrDriver),
  );

  const messageIconColor = G.ifElse(
    R.and(
      G.isNotNilAndNotEmpty(lastChatAuthorType),
      R.or(notCarrierPortalAndAuthorTypeCarrierOrDriver, carrierPortalAndAuthorNotTypeCarrierOrDriver),
    ),
    blueColor,
    inactiveIconColor,
  );

  const guid = G.getGuidFromObject(data);
  const isClo = G.isLoadTypeClo(loadType);

  const chatMessagePermissions = G.hasAmousCurrentUserPermissions(G.ifElse(
    isClo,
    [PC.CLO_CHAT_MESSAGE_READ, PC.CLO_CHAT_MESSAGE_WRITE],
    [PC.TEL_CHAT_MESSAGE_READ, PC.TEL_CHAT_MESSAGE_WRITE],
  ));

  const referencePermissions = G.hasAmousCurrentUserPermissions(G.ifElse(
    isClo,
    [PC.CLO_REFERENCE_READ, PC.CLO_REFERENCE_WRITE],
    [PC.TEL_REFERENCE_READ, PC.TEL_REFERENCE_WRITE],
  ));

  return (
    <RelativeFlex justifyContent='space-around'>
      {
        notCarrierOrCustomerUser &&
        <Box
          cursor='pointer'
          title={noteTitle}
          onClick={(e: Object) => clickHandler(e, { type: 'NOTES', guid, loadType })}
        >
          {I.renderFileIcon(noteIconColor)}
        </Box>
      }
      {
        chatMessagePermissions &&
        <Box
          cursor='pointer'
          title={G.getWindowLocale('titles:add-messages', 'Add Messages')}
          onClick={(e: Object) => clickHandler(e, { type: 'MESSAGES', guid, loadType })}
        >
          {I.comment2(messageIconColor, 16, 16)}
        </Box>
      }
      {
        referencePermissions && R.not(G.isTrue(hideReferences)) &&
        <Box
          cursor='pointer'
          title={G.getWindowLocale('titles:references', 'References')}
          onClick={() => handleShowLoadReferences({
            guid,
            loadType,
            openModal,
            closeModal,
            fromPage: GC.PAGE_DISPATCH_BOARD_NEW,
          })}
        >
          {I.list1(G.getTheme('colors.darkGrey'))}
        </Box>
      }
      {
        R.and(G.isNotNilAndNotEmpty(noteText), R.not(G.isCurrentUserTypeCustomer())) &&
        <AbsoluteBox
          bottom='-15px'
          justifyContent='center'
          left={R.pathOr('-55px', ['left'], pinnedNoteStyles)}
          width={R.pathOr('300%', ['width'], pinnedNoteStyles)}
        >
          <TextComponent
            fontSize={11}
            maxWidth='100%'
            display='block'
            cursor='pointer'
            title={noteText}
            fontWeight='bold'
            withEllipsis={true}
            color={G.getTheme('colors.light.black')}
          >
            {noteText}
          </TextComponent>
        </AbsoluteBox>
      }
    </RelativeFlex>
  );
};

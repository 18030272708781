// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fuel-cards
import * as C from '../constants';
//////////////////////////////////////////////////

export const FILTER_PARAMS = [
  {
    type: 'string',
    value: GC.GRC.DRIVER_FIRST_NAME,
    name: `${G.getWindowLocale('titles:driver', 'Driver')}: ${
      G.getWindowLocale('titles:first-name', 'First Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.DRIVER_LAST_NAME,
    name: `${G.getWindowLocale('titles:driver', 'Driver')}: ${
      G.getWindowLocale('titles:last-name', 'Last Name')}`,
  },
  {
    type: 'string',
    value: `${GC.SYSTEM_OBJECT_DRIVER}.${GC.FIELD_FLEET_LOGIN_ID}`,
    name: `${G.getWindowLocale('titles:driver', 'Driver')}: ${
      G.getWindowLocale('titles:login-id', 'Login ID')}`,
  },
  {
    type: 'string',
    value: C.RC.TRUCK_UNIT_ID,
    name: `${G.getWindowLocale('titles:truck', 'Truck')}: ${
      G.getWindowLocale('titles:unit-id', 'Unit ID')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_FUEL_CARDS_PAYROLL_GUID,
    name: G.getWindowLocale('titles:payroll-number', 'Payroll #'),
  },
  {
    type: 'string',
    value: GC.FIELD_FUEL_CARDS_VENDOR_PAYROLL_GUID,
    name: G.getWindowLocale('titles:vendor-payroll-number', 'Vendor Payroll #'),
  },
  {
    type: 'date',
    value: GC.FIELD_FUEL_CARDS_TRANSACTION_DATE,
    name: G.getWindowLocale('titles:transaction-date', 'Transaction Date'),
  },
  {
    type: 'number',
    value: GC.FIELD_FUEL_CARDS_EXCLUDED_TOTAL,
    name: G.getWindowLocale('titles:excluded-total', 'Excluded Total'),
  },
  {
    type: 'number',
    value: GC.FIELD_FUEL_CARDS_PAYABLE_TOTAL,
    name: G.getWindowLocale('titles:payable-total', 'Payable Total'),
  },
  {
    type: 'number',
    value: GC.FIELD_TOTAL,
    name: G.getWindowLocale('titles:-total', 'Total'),
  },
  {
    type: 'string',
    value: GC.FIELD_TRIP_ID,
    name: G.getWindowLocale('titles:trip-id', 'Trip ID'),
  },
  {
    type: 'string',
    value: GC.FIELD_FUEL_CARDS_SOURCE,
    name: G.getWindowLocale('titles:source', 'Source'),
  },
  {
    type: 'string',
    value: GC.FIELD_FUEL_CARDS_TRANSACTION_NUMBER,
    name: G.getWindowLocale('titles:transaction-number', 'Transaction Number'),
  },
  {
    type: 'string',
    value: C.RC.FUEL_CARDS_TRANSACTION_STOP_LOCATION_NAME,
    name: G.getWindowLocale('titles:location-name', 'Location Name'),
  },
  {
    type: 'string',
    value: C.RC.FUEL_CARDS_TRANSACTION_STOP_ADDRESS,
    name: G.getWindowLocale('titles:address', 'Address'),
  },
  {
    type: 'string',
    value: C.RC.FUEL_CARDS_TRANSACTION_STOP_CITY,
    name: G.getWindowLocale('titles:city', 'City'),
  },
  {
    type: 'string',
    value: C.RC.FUEL_CARDS_TRANSACTION_STOP_STATE,
    name: G.getWindowLocale('titles:state', 'State'),
  },
  {
    type: 'string',
    value: C.RC.FUEL_CARDS_TRANSACTION_STOP_COUNTRY,
    name: G.getWindowLocale('titles:country', 'Country'),
  },
  {
    type: 'string',
    value: GC.FIELD_CURRENCY,
    name: G.getWindowLocale('titles:currency', 'Currency'),
  },
  {
    type: 'string',
    value: GC.FIELD_FUEL_CARDS_CARD_ID,
    name: G.getWindowLocale('titles:card-id', 'Card Id'),
  },
  {
    type: 'string',
    value: GC.FIELD_CREATED_BY,
    name: G.getWindowLocale('titles:created-by', 'Created By'),
  },
  {
    type: 'date',
    value: GC.FIELD_CREATED_DATE,
    name: G.getWindowLocale('titles:created-date', 'Created Date'),
  },
  {
    type: 'string',
    value: GC.FIELD_LAST_MODIFIED_BY,
    name: G.getWindowLocale('titles:last-modified-by', 'Updated By'),
  },
  {
    type: 'date',
    value: GC.FIELD_LAST_MODIFIED_DATE,
    name: G.getWindowLocale('titles:last-modified-date', 'Updated On'),
  },
  {
    type: 'string',
    collection: true,
    value: C.RC.FUEL_CARDS_LINES_PRODUCT_NAME,
    name: G.getWindowLocale('titles:product-name', 'Product Name'),
  },
  {
    type: 'number',
    collection: true,
    value: C.RC.FUEL_CARDS_LINES_QUANTITY,
    name: G.getWindowLocale('titles:quantity', 'Quantity'),
  },
  {
    type: 'string',
    collection: true,
    value: C.RC.FUEL_CARDS_LINES_UOM,
    name: G.getWindowLocale('titles:uom', 'UOM'),
  },
];

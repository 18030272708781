import React, { Fragment } from 'react';
import * as R from 'ramda';
// components
import { Label } from '../../../components/label';
import { InfoPair } from '../../../components/info-pair';
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, StyledLink, BoxHovered } from '../../../ui';
// features drivers-card
import { getDuration } from '../helpers';
import { CARD_HEIGHT_170 } from '../constants';
import { withPendingActivity } from '../hocs/with-pending-activity';
//////////////////////////////////////////////////

const unknownLocale = G.getWindowLocale('titles:unknown', 'Unknown');

const bgGreyColor = G.getTheme('colors.bgGrey');
const borderColor = G.getTheme('colors.#E4E4E4');
const darkGreyColor = G.getTheme('colors.darkGrey');
const lightBlueColor = G.getTheme('colors.light.blue');
const lightGreyColor = G.getTheme('colors.light.grey');
const darkMainColor = G.getTheme('colors.dark.mainDark');
const lightMainColor = G.getTheme('colors.light.mainLight');

const infoPairCommonStyles = {
  fontSize: 13,
  lineHeight: 1,
  fontWeight: 'normal',
  textColor: darkGreyColor,
  actionTextColor: darkMainColor,
};

const getAvailability = (driver: Object) => {
  const { unavailablePeriods } = driver;

  const sortedPeriods = G.sortListByDate(unavailablePeriods, 'startDate');

  const currentDate = G.getCurrentDate();

  let unavailable = false;
  let sumDays = 0;
  let sumHours = 0;

  const unavailableIndexes = [];

  sortedPeriods.forEach((item: Object, index: number, arr: Array) => {
    const { endDate, startDate } = item;

    const prevIndex = R.dec(index);

    const prevItem = arr[prevIndex];

    const inUnavailable = R.includes(prevIndex, unavailableIndexes);

    if (R.and(G.isObject(prevItem), inUnavailable)) {
      const prevEndDate = R.path(['endDate'], prevItem);

      const same = G.isSameMoment(prevEndDate, startDate);

      if (same) {
        const duration = getDuration(startDate, endDate);

        if (G.isNilOrEmpty(duration)) return;

        unavailableIndexes.push(index);

        const { days, hours } = duration;

        sumDays = R.add(sumDays, days);

        sumHours = R.add(sumHours, hours);
      }
    }

    if (G.isBetween(currentDate, startDate, endDate, '[]')) {
      const duration = getDuration(currentDate, endDate);

      if (G.isNilOrEmpty(duration)) return;

      unavailableIndexes.push(index);

      unavailable = true;

      const { days, hours } = duration;

      sumDays = R.add(sumDays, days);

      sumHours = R.add(sumHours, hours);
    }
  });

  const unavailableText = `
    ${G.getWindowLocale('titles:available-in', 'Available in')}
    ${sumDays} ${G.getWindowLocale('titles:days', 'Days')}
    ${sumHours} ${G.getWindowLocale('titles:hours', 'Hours')}
  `;

  return { unavailable, unavailableText };
};

const getDriverInfo = (driver: Object) => {
  const truckNumber = R.pathOr('', ['truckUnitId'], driver);

  const trailers = G.createStringFromArray(R.pathOr([], ['trailerUnitIds'], driver));

  const { fullText, avatarText } = G.getUserInfo(driver);

  return {
    trailers,
    fullText,
    avatarText,
    truckNumber,
  };
};

const Avatar = ({ driver, groupGuid, secondaryDriver, openDriverProfile }: Object) => {
  const { pendingActivities, unavailablePeriods } = driver;

  const { fullText, avatarText } = getDriverInfo(driver);

  const content = G.isNotNilAndNotEmpty(secondaryDriver) ? I.groupIcon2() : avatarText;

  const title = G.isNotNilAndNotEmpty(secondaryDriver) ? null : fullText;

  const cursor = G.isNotNilAndNotEmpty(secondaryDriver) ? null : 'pointer';

  const onClick = G.isNotNilAndNotEmpty(secondaryDriver) ?
    null :
    () => G.callFunctionWithArgs(
      openDriverProfile,
      {
        pendingActivities,
        unavailablePeriods,
        driverGroupGuid: groupGuid,
        driverProfileGuid: G.getGuidFromObject(driver),
      },
    )
  ;

  return (
    <Flex
      mr={10}
      width={30}
      height={30}
      title={title}
      cursor={cursor}
      onClick={onClick}
      border='1px solid'
      borderRadius='50%'
      alignItems='center'
      justifyContent='center'
      borderColor={lightGreyColor}
      background='rgba(255, 255, 255, 0.5)'
    >
      {content}
    </Flex>
  );
};

const getDriverText = (driver: Object) => {
  const { primaryDriverGuid } = driver;

  const { fullText, firstLastNames } = G.getUserInfo(driver);

  const { unavailable, unavailableText } = getAvailability(driver);

  let driverText = firstLastNames;

  let driverTitle = fullText;

  if (G.isNotNilAndNotEmpty(primaryDriverGuid)) driverText = `T. ${driverText}`;

  if (G.isTrue(unavailable)) {
    driverText = `${driverText} (${unavailableText})`;

    driverTitle = `${driverTitle} (${unavailableText})`;
  }

  return {
    driverText,
    driverTitle,
  };
};

const getCustomerRatesInfo = (driver: Object) => {
  const {
    mileageRate,
    totalDistanceUom,
    customerRatesTotal,
    customerRatesTotalCurrency,
  } = driver;

  const currencySymbol = G.getCurrencySymbol(customerRatesTotalCurrency);

  const grossText = `${G.getWindowLocale('titles:gross', 'Gross')}: ${currencySymbol}${G.mathRoundNumber(customerRatesTotal, 1)}`; // eslint-disable-line

  if (G.isNilOrEmpty(mileageRate)) return grossText;

  const crpmText = G.getCrpmText(totalDistanceUom);

  return `${grossText}, ${crpmText}: ${currencySymbol}${G.mathRoundNumber(mileageRate)}`;
};

const getTripRatesInfo = (driver: Object) => {
  const {
    telRatesTotal,
    telMileageRate,
    totalDistanceUom,
    telRatesTotalCurrency,
  } = driver;

  const currencySymbol = G.getCurrencySymbol(telRatesTotalCurrency);

  const tripRates = `${G.getWindowLocale('titles:trip-rates', 'Trip Rates')}: ${currencySymbol}${G.mathRoundNumber(telRatesTotal, 1)}`; // eslint-disable-line

  if (G.isNilOrEmpty(telMileageRate)) return tripRates;

  const rpmText = G.getRpmText(totalDistanceUom);

  return `${tripRates}, ${rpmText}: ${currencySymbol}${G.mathRoundNumber(telMileageRate)}`;
};

const getRatesInfo = (driver: Object) => {
  const customerRatesInfo = getCustomerRatesInfo(driver);

  const tripRatesInfo = getTripRatesInfo(driver);

  return `${customerRatesInfo}. ${tripRatesInfo}`;
};

const DriversInfo = (props: Object) => {
  const { driver, groupGuid, secondaryDriver, openDriverProfile } = props;

  const { pendingActivities, unavailablePeriods } = driver;

  const { driverText, driverTitle } = getDriverText(driver);

  const ratesInfo = getRatesInfo(driver);

  const { fullText: secondaryDriverTitle, firstLastNames: secondaryDriverName } = G.getUserInfo(secondaryDriver);

  return (
    <Fragment>
      <Flex
        width='100%'
        justifyContent='space-between'
        mb={G.isNilOrEmpty(secondaryDriver) ? '3px' : 0}
      >
        <BoxHovered
          cursor='pointer'
          alignSelf='flex-start'
          hoverColor={lightBlueColor}
        >
          <TextComponent
            maxWidth={180}
            display='block'
            withEllipsis={true}
            title={driverTitle}
            onClick={() => G.callFunctionWithArgs(
              openDriverProfile,
              {
                pendingActivities,
                unavailablePeriods,
                driverGroupGuid: groupGuid,
                driverProfileGuid: G.getGuidFromObject(driver),
              },
            )}
          >
            {driverText}
          </TextComponent>
        </BoxHovered>
        <Flex
          p='3px'
          width={150}
          fontSize={11}
          bg='titleDarkBlue'
          borderRadius='4px'
          justifyContent='center'
          color={G.getTheme('colors.white')}
        >
          <TextComponent
            maxWidth={150}
            display='block'
            title={ratesInfo}
            withEllipsis={true}
          >
            {R.replace('Gross', 'G', ratesInfo)}
          </TextComponent>
        </Flex>
      </Flex>
      {
        G.isNotNilAndNotEmpty(secondaryDriver) &&
        <BoxHovered
          cursor='pointer'
          alignSelf='flex-start'
          textDecoration='underline'
          hoverColor={lightBlueColor}
        >
          <TextComponent
            fontSize={12}
            maxWidth={180}
            display='block'
            withEllipsis={true}
            title={secondaryDriverTitle}
            onClick={() => G.callFunctionWithArgs(
              openDriverProfile,
              {
                driverGroupGuid: groupGuid,
                driverProfileGuid: G.getGuidFromObject(secondaryDriver),
                pendingActivities: R.pathOr([], ['pendingActivities'], secondaryDriver),
                unavailablePeriods: R.pathOr([], ['unavailablePeriods'], secondaryDriver),
              },
            )}
          >
            {secondaryDriverName}
          </TextComponent>
        </BoxHovered>
      }
    </Fragment>
  );
};

const FleetInfo = (props: Object) => {
  const { driver, handleAddPendingActivity } = props;

  const { trailers, truckNumber } = getDriverInfo(driver);

  const addActivityLocale = G.getWindowLocale('titles:add-activity', 'Add Activity');

  return (
    <Flex
      height='100%'
      flexDirection='column'
      justifyContent='space-around'
    >
      <DriversInfo {...props} />
      <Flex>
        <Box width={240}>
          <InfoPair
            {...infoPairCommonStyles}
            py='0px'
            textMaxWidth={180}
            text={truckNumber}
            label={`${G.getWindowLocale('titles:truck', 'Truck')}#`}
          />
          <InfoPair
            {...infoPairCommonStyles}
            py='0px'
            text={trailers}
            textMaxWidth={180}
            label={`${G.getWindowLocale('titles:trailer', 'Trailer')}#`}
          />
        </Box>
        <Box
          p='2px'
          borderRadius='4px'
          background={bgGreyColor}
          onClick={() => handleAddPendingActivity(G.getGuidFromObject(driver))}
        >
          <Label iconWrapperWidth={15} frontIcon={I.clock()} labelP='0 3px 0 3px'>
            <TextComponent
              fontSize={13}
              display='block'
              color={darkGreyColor}
              title={addActivityLocale}
            >
              {addActivityLocale}
            </TextComponent>
          </Label>
        </Box>
      </Flex>
    </Flex>
  );
};

const Driver = (props: Object) => {
  const {
    driver,
    groupGuid,
    cardHeight,
    openDriverProfile,
    handleAddPendingActivity,
  } = props;

  const { phoneNumber, secondaryDriver, domicileTerminal, lastKnownLocation } = driver;

  const fullHeight = R.equals(cardHeight, CARD_HEIGHT_170);

  const lnl = G.concatLocationFields(lastKnownLocation);

  return (
    <Flex
      py='3px'
      width={400}
      borderRadius={10}
      alignItems='start'
      bg={lightMainColor}
      height={cardHeight}
      flexDirection='column'
      justifyContent='space-around'
      border={`1px solid ${borderColor}`}
    >
      <Flex
        px={10}
        height={60}
        width='100%'
        alignItems='center'
      >
        <Avatar
          driver={driver}
          groupGuid={groupGuid}
          secondaryDriver={secondaryDriver}
          openDriverProfile={openDriverProfile}
        />
        <FleetInfo
          lnl={lnl}
          driver={driver}
          groupGuid={groupGuid}
          fullHeight={fullHeight}
          secondaryDriver={secondaryDriver}
          openDriverProfile={openDriverProfile}
          handleAddPendingActivity={handleAddPendingActivity}
        />
      </Flex>
      <Flex
        px={10}
        width='100%'
        alignItems='start'
        borderTop={`1px solid ${borderColor}`}
        py={G.ifElse(fullHeight, '5px', '3px')}
        flexDirection={G.ifElse(fullHeight, 'column', 'row')}
        borderBottom={G.ifElse(fullHeight, `1px solid ${borderColor}`, 'none')}
      >
        <Label
          cursor='initial'
          frontIcon={I.phone2()}
          mb={G.ifElse(fullHeight, '5px', 0)}
        >
          <TextComponent
            display='block'
            withEllipsis={true}
            title={phoneNumber}
            maxWidth={G.ifElse(fullHeight, 350, 120)}
          >
            <StyledLink
              href={`tel:${phoneNumber}`}
            >
              {R.or(phoneNumber, unknownLocale)}
            </StyledLink>
          </TextComponent>
        </Label>
        <Label frontIcon={I.locationPin()}>
          <TextComponent
            title={lnl}
            display='block'
            withEllipsis={true}
            color={darkMainColor}
            maxWidth={G.ifElse(fullHeight, 350, 210)}
          >
            {R.or(lnl, unknownLocale)}
          </TextComponent>
        </Label>
      </Flex>
      {
        fullHeight &&
        <Flex px={10}>
          <InfoPair
            {...infoPairCommonStyles}
            textMaxWidth={300}
            text={R.or(domicileTerminal, unknownLocale)}
            label={G.getWindowLocale('titles:domicile', 'Domicile')}
          />
        </Flex>
      }
    </Flex>
  );
};

export default withPendingActivity(Driver);

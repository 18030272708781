import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// features
import { dataRangeEnumLocale } from '../dashboards/constants';
// icons
import { truckOnMap, fleetsOnMap, driverOnMap, trailerOnMap } from '../../svgs';
//////////////////////////////////////////////////

export const fleetTypeObjectMap = {
  all: 'all',
  trucks: 'trucks',
  drivers: 'drivers',
  trailers: 'trailers',
};

export const fleetLNLNameMap = {
  trailers: 'latestLocation',
  trucks: 'truckLatestLocation',
  drivers: 'driverLatestLocation',
};

export const fleetMapIcon = {
  all: fleetsOnMap,
  trucks: truckOnMap,
  drivers: driverOnMap,
  trailers: trailerOnMap,
};

export const renderFleetMapIcon = (fleetType: string) => (
  fleetMapIcon[fleetType]()
);

export const getLatLngFromFleets = (fleets: Array) => (
  R.compose(
    R.prop('latLng'),
    R.head(),
  )(fleets)
);

export const mapFleets = (lnlPropName: string, fleets: Array) => {
  const grouped = {};
  R.forEach((item: Object) => {
    const lnl = R.prop(lnlPropName, item);

    if (G.isNilOrEmpty(lnl)) return;

    const lat = Math.round(R.path([GC.FIELD_LATITUDE], lnl) * 1000) / 1000;
    const lng = Math.round(R.path([GC.FIELD_LONGITUDE], lnl) * 1000) / 1000;

    const resultItem = {
      lnl,
      latLng: { lat, lng },
      shortid: G.genShortId(),
      fleetObj: R.omit(lnlPropName, item),
    };

    let exist = false;

    R.forEachObjIndexed((value: Array, key: string) => {
      const item = R.find(R.propEq(resultItem.latLng, 'latLng'), value);

      if (G.isNotNil(item)) {
        exist = true;
        grouped[key].push(resultItem);
      }
    }, grouped);

    if (G.isFalse(exist)) {
      grouped[G.genShortId()] = R.of(Array, resultItem);
    }
  }, fleets);

  return grouped;
};

export const groupByLatLng = (
  propName: string,
  fleets: Array,
) => {
  if (R.and(R.isNil(propName), R.isNil(fleets))) return null;
  const grouped = mapFleets(propName, fleets);
  return R.values(grouped);
};

export const countFleetEntities = (locations: Object) =>
  R.reduce((a: number, b: Array) => R.add(a, R.length(b)), 0, locations);

export const getFleetsLength = (fleet: Object) => R.sum([
  countFleetEntities(fleet.trucks),
  countFleetEntities(fleet.drivers),
  countFleetEntities(fleet.trailers),
]);

export const getMultiSwitchOptions = (fleet: Object) => [
  {
    width: 80,
    value: 'all',
    name: `${G.getWindowLocale('titles:all', 'All')} (${getFleetsLength(fleet)})`,
  },
  {
    width: 80,
    value: 'drivers',
    name: `${G.getWindowLocale('titles:drivers', 'Drivers')} (${countFleetEntities(fleet.drivers)})`,
  },
  {
    width: 80,
    value: 'trucks',
    name: `${G.getWindowLocale('titles:trucks', 'Trucks')} (${countFleetEntities(fleet.trucks)})`,
  },
  {
    width: 80,
    value: 'trailers',
    name: `${G.getWindowLocale('titles:trailers', 'Trailers')} (${countFleetEntities(fleet.trailers)})`,
  },
];

const getEnterpriseOptions = (fleetType: string, fleets: Object) => R.compose(
  R.uniqBy(R.prop(GC.BRANCH_GUID)),
  R.map(
    R.pick([GC.BRANCH_GUID, GC.BRANCH_NAME]),
  ),
  R.prop(fleetType),
)(fleets);

export const getDivisionFilterOptions = (
  activeFleetType: string,
  fleets: Object,
) => {
  const amousBranches = G.getItemFromWindow('amousBranches');

  const enterpriseGuidList = R.uniqBy(
    R.prop(GC.BRANCH_GUID),
    [
      ...(
        R.or(R.equals(activeFleetType, 'all'), R.equals(activeFleetType, 'trailers')) ?
        getEnterpriseOptions('trailers', fleets) : []
      ),
      ...(
        R.or(R.equals(activeFleetType, 'all'), R.equals(activeFleetType, 'drivers')) ?
        getEnterpriseOptions('drivers', fleets) : []
      ),
      ...(
        R.or(R.equals(activeFleetType, 'all'), R.equals(activeFleetType, 'trucks')) ?
        getEnterpriseOptions('trucks', fleets) : []
      ),
    ]);

  return R.reject(
    R.compose(
      G.isNilOrEmpty,
      R.prop('label'),
    ),
    R.map((division: string) => {
      const { enterpriseGuid, enterpriseName } = division;

      const branch = R.prop(enterpriseGuid, amousBranches);

      return {
        value: enterpriseGuid,
        label: R.or(enterpriseName, R.prop('enterpriseName', branch)),
      };
    }, enterpriseGuidList),
  );
};

export const idleTimeOptions = R.map(
  ([value, label]: Array) => ({
    label,
    value,
  }),
  R.toPairs(dataRangeEnumLocale),
);

export const getFilteredByEnterpriseFleetsEntity = R.curry(
  (enterpriseFilter: any, fleets: Object, type: string) => R.compose(
    (entities: Array) => G.ifElse(
      G.isNotNilAndNotEmpty(enterpriseFilter),
      R.filter(
        R.compose(
          R.flip(R.includes)(enterpriseFilter),
          R.prop(GC.BRANCH_GUID),
        ),
        entities,
      ),
      entities,
    ),
    R.prop(type),
  )(fleets),
);

export const getFilteredByIdleTimeTrailers = R.curry((idleTimeFilter: any, trailers: Object) => (
  G.isNotNilAndNotEmpty(idleTimeFilter) ?
  R.filter(
    R.compose(
      R.equals(idleTimeFilter),
      R.path(['latestLocation', 'idleTime']),
    ),
    trailers,
  ) :
  trailers
));

export const getFilteredByUnitIdTrailers = R.curry((unitIdFilter: any, trailers: Object) => (
  G.isNotNilAndNotEmpty(unitIdFilter) ?
  R.filter(
    R.compose(
      R.includes(R.toLower(unitIdFilter)),
      R.toLower,
      R.prop(GC.FIELD_UNIT_ID),
    ),
    trailers,
  ) :
  trailers
));

export const getFilteredByTypeLocations = (typeFilter: any, locations: Object) => (
  G.isNotNilAndNotEmpty(typeFilter) ?
  R.filter(
    R.compose(
      R.equals(typeFilter),
      R.prop(`${GC.FIELD_TYPE}.${GC.FIELD_DROPDOWN_OPTION_GUID}`),
    ),
    locations,
  ) :
  locations
);

export const convertRadiusFromMetersToPixels = (zoom: number, radius: number, latitude: number) => {
  const tileSize = 256;
  const earthCircumference = 40008000;

  const latitudeInRadians = latitude * (Math.PI / 180);
  const metersPerPixel = (earthCircumference * Math.cos(latitudeInRadians)) / (tileSize * (2 ** zoom));

  return radius / metersPerPixel;
};

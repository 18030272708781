import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { ITEM_DIMENSIONS_OPTIONS } from '../../../helpers/options';
//////////////////////////////////////////////////

export const rowHeight = 46;

export const locationTableSettings = {
  rowHeight,
  fontSize: 11,
  cellFontSize: 14,
  titleFontSize: 12,
  maxHeight: '100%',
  checkBoxCellGap: 5,
  allowEditBtn: true,
  useMainColors: true,
  allowSelectAll: true,
  checkBoxCellWidth: 85,
  searchableTitles: true,
  allowSelectItems: false,
  titleRowHeight: rowHeight,
  tableRowHeight: rowHeight,
  fixLocalLoaderHeight: true,
  withResizableColumns: false,
  checkBoxCellJustifyContent: 'flex-start',
};

const locationFields = [
  { name: GC.FIELD_NAME, sequence: 1 },
  { name: `${GC.FIELD_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`, sequence: 2 },
  { name: GC.FIELD_ADDRESS, sequence: 3 },
  { name: GC.FIELD_CITY, sequence: 4 },
  { name: GC.FIELD_STATE, sequence: 5 },
  { name: GC.FIELD_ZIP, sequence: 6 },
  { name: GC.FIELD_COUNTRY, sequence: 7 },
  { name: GC.FIELD_LATITUDE, sequence: 8 },
  { name: GC.FIELD_LONGITUDE, sequence: 9 },
  { name: GC.FIELD_FLEET_LOCATION_RADIUS, sequence: 10 },
  { name: GC.FIELD_FLEET_LOCATION_RADIUS_UOM, sequence: 11 },
  { name: GC.FIELD_CREATED_DATE, sequence: 12 },
  { name: GC.FIELD_CREATED_BY, sequence: 13 },
  { name: GC.FIELD_LAST_MODIFIED_DATE, sequence: 14 },
  { name: GC.FIELD_LAST_MODIFIED_BY, sequence: 15 },
  { name: GC.FIELD_BRANCH_DOT_BRANCH_NAME, sequence: 16 },
  { name: GC.FIELD_TRAILER_COUNT, sequence: 17 },
  { name: `${GC.FIELD_TYPE}.${GC.FIELD_DROPDOWN_OPTION_GUID}`, sequence: 18 },
];

export const geoFencingLocationReport = {
  fields: locationFields,
  name: 'geo-fencing-location-report',
  type: GC.GEO_FENCING_LOCATION_REPORT,
};

export const locationSettings = {
  [GC.FIELD_NAME]: {
    name: 'titles:name',
  },
  [`${GC.FIELD_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    name: 'titles:type',
  },
  [GC.FIELD_ADDRESS]: {
    name: 'titles:address',
  },
  [GC.FIELD_CITY]: {
    name: 'titles:city',
  },
  [GC.FIELD_STATE]: {
    name: 'titles:state',
  },
  [GC.FIELD_ZIP]: {
    name: 'titles:zip',
  },
  [GC.FIELD_COUNTRY]: {
    name: 'titles:country',
  },
  [GC.FIELD_FLEET_LOCATION_RADIUS]: {
    type: 'number',
    filterType: 'number',
    name: 'titles:radius',
  },
  [GC.FIELD_FLEET_LOCATION_RADIUS_UOM]: {
    name: 'titles:radius-uom',
    type: 'select',
    fieldName: GC.FIELD_INTERVAL_UOM,
    label: 'titles:radius-uom',
    options: ITEM_DIMENSIONS_OPTIONS,
  },
  [GC.FIELD_CREATED_DATE]: {
    fieldType: 'date',
    filterType: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_CREATED_BY]: {
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    fieldType: 'date',
    filterType: 'date',
    name: 'titles:last-modified-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    name: 'titles:last-modified-by',
  },
  [GC.FIELD_BRANCH_DOT_BRANCH_NAME]: {
    name: 'titles:branch',
  },
};

export const locationColumnSettings = {
  [GC.FIELD_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:name',
  },
  [`${GC.FIELD_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 200,
    searchable: true,
    name: 'titles:type',
  },
  [GC.FIELD_ADDRESS]: {
    width: 200,
    searchable: true,
    name: 'titles:address',
  },
  [GC.FIELD_CITY]: {
    width: 200,
    searchable: true,
    name: 'titles:city',
  },
  [GC.FIELD_STATE]: {
    width: 200,
    searchable: true,
    name: 'titles:state',
  },
  [GC.FIELD_ZIP]: {
    width: 200,
    searchable: true,
    name: 'titles:zip',
  },
  [GC.FIELD_COUNTRY]: {
    width: 200,
    searchable: true,
    name: 'titles:country',
  },
  [GC.FIELD_FLEET_LOCATION_RADIUS]: {
    width: 200,
    type: 'text',
    searchable: true,
    name: 'titles:radius',
  },
  [GC.FIELD_FLEET_LOCATION_RADIUS_UOM]: {
    width: 200,
    type: 'select',
    searchable: true,
    name: 'titles:radius-uom',
    fieldName: GC.FIELD_FLEET_LOCATION_RADIUS_UOM,
    label: G.getWindowLocale('titles:radius-uom', 'Radius UOM'),
    options: ITEM_DIMENSIONS_OPTIONS,
    customComponent: ({ data }: Object) => R.compose(
      R.pathOr('', [GC.FIELD_LABEL]),
      R.find(R.propEq(G.getPropFromObject(GC.FIELD_FLEET_LOCATION_RADIUS_UOM, data), GC.FIELD_VALUE)),
    )(ITEM_DIMENSIONS_OPTIONS),
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    searchable: true,
    name: 'titles:created-date',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    searchable: true,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    searchable: true,
    name: 'titles:last-modified-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 200,
    searchable: true,
    name: 'titles:last-modified-by',
  },
  [GC.FIELD_BRANCH_DOT_BRANCH_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:branch',
  },
};

export const fleetTrailerFields = [
  { name: GC.FIELD_UNIT_ID, sequence: 1 },
  { name: 'enterprise.guid', sequence: 2 },
  { name: 'enterprise.enterpriseName', sequence: 3 },
  { name: 'trailerLatestLocation.latitude', sequence: 4 },
  { name: 'trailerLatestLocation.longitude', sequence: 5 },
];

import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, lifecycle, withState } from 'react-recompose';
// components
import { Tabs2 } from '../../../../../components/tabs-mui';
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// hocs
import { withAsyncGetBranchListByType } from '../../../../../hocs';
// feature config
import { CUSTOMER_EDI_PICKUP_DROP_STOP_TYPES_MAP } from '../constants';
import { ediConfigsFields, defaultEdiConfigsFields, ediConfigsValidationSchema } from '../settings';
//////////////////////////////////////////////////

const tabs = [
  { text: G.getWindowLocale('titles:general', 'General') },
  { text: G.getWindowLocale('titles:order', 'Order') },
];

const filedKeysMap = [
  [
    GC.FIELD_CUSTOMER_GUID,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_FOLDER_NAME,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_TYPE,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_VALUE,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_PRIMARY_REFERENCE,
    GC.FIELD_SEND_RESPONSE,
    GC.FIELD_EXPORTER_CONFIG_GUID,
  ],
  [
    GC.FIELD_PICKUP_STOP_TYPES,
    GC.FIELD_DROP_STOP_TYPES,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_DEFAULT_ITEM_ID,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_PRIMARY_NUMBER,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_PO_NUMBER_REFERENCE,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_ORDER_IDENTIFICATION_REFERENCE,
    GC.FIELD_CUSTOM_MAPPER,
    GC.FIELD_AUTO_UPDATE_EVENT_DATES,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_USE_ITEM_TEMPLATES,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_USE_LOCATION_TEMPLATES,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_OVERRIDE_CONFIGURED_BILL_TO,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_LOADER_LAST_TWO_NUMBERS_ARE_PENNIES,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_AUTO_ACCEPT,
    GC.FIELD_AUTO_CREATE_ORDER,
    GC.FIELD_USE_SHIPMENT_NUMBER_AS_PICKUP_NUMBER,
    GC.FIELD_GENERATE_PRO_NUMBER,
    GC.FIELD_PRO_NUMBER_REFERENCE,
    GC.FIELD_PRO_NUMBER_SEQUENCE,
    'instructionsField',
    'createItems',
    'itemTemplateId',
  ],
];

const getFieldSettingsByActiveTab = (activeTab: number) => R.compose(
  R.values,
  R.pick(R.propOr([], activeTab, filedKeysMap)),
  R.indexBy(R.prop('fieldName')),
)(ediConfigsFields);

const enhance = compose(
  withState('activeTab', 'setActiveTab', 0),
  withState('availableEdiExports', 'setAvailableEdiExporters', []),
  withAsyncGetBranchListByType(GC.BRANCH_TYPE_ENUM_CUSTOMER),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultEdiConfigsFields,
      props.initialValues,
    ),
    validationSchema: ediConfigsValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
  }),
  lifecycle({
    componentDidMount() {
      const {
        ediExporters,
        setAvailableEdiExporters,
        initialValues: {
          version,
          customerGuid,
          exporterConfigGuid,
          [GC.BRANCH_GUID]: branchGuid = G.getAmousCurrentBranchGuidFromWindow(),
        } = {},
      } = this.props;

      if (R.isNil(version)) return;

      const availableEdiExporters = R.filter(({ value, exporterBranchGuid, exporterCustomerGuid }: Object) => R.and(
        R.equals(branchGuid, exporterBranchGuid),
        R.or(
          R.equals(value, exporterConfigGuid),
          R.equals(exporterCustomerGuid, customerGuid),
        ),
      ), ediExporters);

      setAvailableEdiExporters(availableEdiExporters);
    },
    componentDidUpdate(prevProps: Object) {
      const {
        ediExporters,
        setFieldValue,
        setAvailableEdiExporters,
        values: {
          customerGuid,
          exporterConfigGuid,
          [GC.BRANCH_GUID]: branchGuid = G.getAmousCurrentBranchGuidFromWindow(),
        } = {},
      } = this.props;

      const { values: { customerGuid: prevCustomerGuid, exporterConfigGuid: prevExporterConfigGuid } = {} } = prevProps;

      if (R.or(
        G.notEquals(customerGuid, prevCustomerGuid),
        G.notEquals(exporterConfigGuid, prevExporterConfigGuid),
      )) {
        const availableEdiExporters = R.filter(
          ({ exporterCustomerGuid, exporterBranchGuid }: Object) => R.and(
            R.equals(branchGuid, exporterBranchGuid),
            R.equals(exporterCustomerGuid, customerGuid),
          ),
          ediExporters,
        );

        setAvailableEdiExporters(availableEdiExporters);
      }

      if (G.notEquals(customerGuid, prevCustomerGuid)) setFieldValue(GC.FIELD_EXPORTER_CONFIG_GUID, null);
    },
  }),
  pure,
);

const getAvailableStopTypeOptions = (stopTypes: Array) => R.compose(
  R.values,
  R.mapObjIndexed((label: string, value: string) => ({ value, [GC.FIELD_LABEL]: `${label} (${value})` })),
  R.omit(stopTypes),
)(CUSTOMER_EDI_PICKUP_DROP_STOP_TYPES_MAP);


const EdiConfigsForm = (props: Object) => {
  const {
    values,
    handlers,
    activeTab,
    handleSubmit,
    setActiveTab,
    branchListByType,
    availableEdiExports,
  } = props;

  const optionsForSelect = R.mergeRight(
    props.optionsForSelect,
    {
      branchListByType,
      availableEdiExports,
      availableDropStopTypes: getAvailableStopTypeOptions(R.pathOr([], [GC.FIELD_PICKUP_STOP_TYPES], values)),
      availablePickupStopTypes: getAvailableStopTypeOptions(R.pathOr([], [GC.FIELD_DROP_STOP_TYPES], values)),
    },
  );

  return (
    <form onSubmit={handleSubmit}>
      <Tabs2
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabStyles={GC.COMMON_MUI_TAB_STYLES}
        tabsStyles={GC.COMMON_MUI_TABS_STYLES}
      />
      <Fieldset2
        {...optionsForSelect}
        {...G.getFormikProps(props)}
        handlers={handlers}
        justifyContent='space-between'
        fields={getFieldSettingsByActiveTab(activeTab)}
        fieldsWrapperStyles={{ pt: 25, justifyContent: 'space-between' }}
      />
      <FormFooter />
    </form>
  );
};

export default enhance(EdiConfigsForm);

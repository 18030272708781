import React from 'react';
import * as R from 'ramda';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// feature available-driver
import { NoteCell, StatusCell, CellComponent } from '../components/table-cells';
//////////////////////////////////////////////////

const mainBlueColor = G.getTheme('colors.mainBlue');

export const getColumnSettings = ({
  handlePatchUpdateNote,
  handleOpenDriverProfile,
  handleOpenExpandedContainer,
}: Object) => ({
  [GC.FIELD_PREFER_DISTANCE_RATE]: {
    width: 120,
    name: 'titles:rate',
    customComponent: ({ data }: Object) => {
      const preferDistanceRate = R.pathOr('', [GC.FIELD_PREFER_DISTANCE_RATE], data);
      const preferDistanceRateUom = R.pathOr('', [GC.FIELD_PREFER_DISTANCE_RATE_UOM], data);

      if (G.isNilOrEmpty(preferDistanceRate)) return null;

      const singleText = `$${preferDistanceRate} / ${G.getUomLocale(preferDistanceRateUom, 'abbreviation')}`;

      return (
        <CellComponent
          singleText={singleText}
          icon={I.moneyWithArrow(mainBlueColor, 15, 15)}
        />
      );
    },
  },
  [GC.FIELD_DRIVER]: {
    width: 200,
    name: 'titles:driver',
    customComponent: ({ data }: Object) => {
      const driverGuid = R.pathOr('', [GC.FIELD_GUID], data);
      const lastName = R.pathOr('', [GC.FIELD_LAST_NAME], data);
      const firstName = R.pathOr('', [GC.FIELD_FIRST_NAME], data);
      const phoneNumber = R.pathOr('', [GC.FIELD_PHONE_NUMBER], data);

      const mainText = `${firstName} ${lastName}`;

      const handleClickMainText = G.ifElse(
        G.hasAmousCurrentUserPermissions([PC.FLEET_DRIVER_READ, PC.FLEET_DRIVER_WRITE]),
        () => handleOpenDriverProfile(driverGuid),
        null,
      );

      return (
        <CellComponent
          mainText={mainText}
          subText={phoneNumber}
          handleClickMainText={handleClickMainText}
          icon={I.renderDriverIcon(mainBlueColor, 15, 15)}
        />
      );
    },
  },
  [GC.FIELD_TRUCK_UNIT_ID]: {
    width: 180,
    name: 'titles:truck',
    customComponent: ({ data }: Object) => {
      const { truck } = data;

      const truckUnitId = R.pathOr('', [GC.FIELD_FLEET_TRUCK_UNIT_ID], truck);

      if (G.isNilOrEmpty(truckUnitId)) return null;

      const truckGuid = R.pathOr('', [GC.FIELD_GUID], truck);
      const truckType = R.pathOr('', [GC.FIELD_TRUCK_TYPE], truck);

      const handleClickMainText = G.ifElse(
        G.hasAmousCurrentUserPermissions([PC.FLEET_TRUCK_READ, PC.FLEET_TRUCK_WRITE]),
        () => handleOpenExpandedContainer({
          visitPageGuid: truckGuid,
          componentType: GC.PAGE_FLEET_TRUCK_PROFILE_V2,
        }),
        null,
      );

      return (
        <CellComponent
          subText={truckType}
          mainText={truckUnitId}
          handleClickMainText={handleClickMainText}
          icon={I.truckGroup2(mainBlueColor, 15, 15)}
        />
      );
    },
  },
  [GC.FIELD_TRUCK]: {
    width: 250,
    name: ['titles:truck', 'titles:dimensions'],
    customComponent: ({ data }: Object) => {
      const { truck } = data;

      const gvwt = R.pathOr('', [GC.FIELD_GVWT], truck);
      const width = R.pathOr('', [GC.FIELD_WIDTH], truck);
      const height = R.pathOr('', [GC.FIELD_HEIGHT], truck);
      const length = R.pathOr('', [GC.FIELD_LENGTH], truck);
      const widthUom = R.pathOr('', [GC.FIELD_WIDTH_UOM], truck);
      const heightUom = R.pathOr('', [GC.FIELD_HEIGHT_UOM], truck);
      const lengthUom = R.pathOr('', [GC.FIELD_LENGTH_UOM], truck);
      const gvwtWeightType = R.pathOr('', [GC.FIELD_GVWT_WEIGHT_TYPE], truck);

      const doorDimension = R.pathOr({}, ['doorDimension'], truck);
      const doorWidth = R.pathOr('', ['doorWidth'], doorDimension);
      const doorHeight = R.pathOr('', ['doorHeight'], doorDimension);
      const doorWidthUom = R.pathOr('', ['doorWidthUom'], doorDimension);
      const doorHeightUom = R.pathOr('', ['doorHeightUom'], doorDimension);

      const doorDimensionsArray = [doorWidth, doorHeight];
      const truckDimensionsArray = [width, height, length];

      if (G.isAllTrue(
        G.isNilOrEmpty(gvwt),
        G.isAnyNilOrEmpty(doorDimensionsArray),
        G.isAnyNilOrEmpty(truckDimensionsArray),
      )) {
        return null;
      }

      let mainText = '';

      if (G.isAllNotNilOrNotEmpty(truckDimensionsArray)) {
        mainText = `${
          length} ${G.getUomLocale(lengthUom, 'abbreviation')} x ${
          width} ${G.getUomLocale(widthUom, 'abbreviation')} x ${
          height} ${G.getUomLocale(heightUom, 'abbreviation')}`;
      }

      let subText = '';

      if (G.isNotNilAndNotEmpty(gvwt)) {
        subText = `${gvwt} ${G.getUomLocale(gvwtWeightType, 'abbreviation')}`;
      }

      if (G.isAllNotNilOrNotEmpty(doorDimensionsArray)) {
        const doorDimensionText = `${G.getWindowLocale('text:door', 'Door')}: ${
          doorWidth} ${G.getUomLocale(doorWidthUom, 'abbreviation')} x ${
          doorHeight} ${G.getUomLocale(doorHeightUom, 'abbreviation')}`;

        subText = G.ifElse(
          G.isNotEmptyString(subText),
          `${subText}, ${doorDimensionText}`,
          doorDimensionText,
        );
      }

      return (
        <CellComponent
          subText={subText}
          mainText={mainText}
          icon={I.truck(mainBlueColor, 15, 15)}
        />
      );
    },
  },
  [GC.FIELD_STATUS]: {
    width: 140,
    name: 'titles:status',
    customComponent: ({ data }: Object) => {
      const { status, telGuid, reserved, statusSource, telPrimaryReferenceValue } = data;

      if (G.isNilOrEmpty(status)) return null;

      const handleOpenTelDetails = G.ifElse(
        G.hasAmousCurrentUserPermissions([PC.TEL_READ, PC.TEL_WRITE]),
        () => handleOpenExpandedContainer({
          visitPageGuid: telGuid,
          componentType: GC.PAGE_DISPATCH_DETAILS_NEW_LOAD,
        }),
        null,
      );

      return (
        <StatusCell
          status={status}
          telGuid={telGuid}
          reserved={reserved}
          statusSource={statusSource}
          handleOpenTelDetails={handleOpenTelDetails}
          telPrimaryReferenceValue={telPrimaryReferenceValue}
        />
      );
    },
  },
  [GC.FIELD_RESERVED_BY]: {
    width: 250,
    name: 'titles:reserved-by',
    customComponent: ({ data }: Object) => {
      const { reservedBy, reservationEndDate } = data;

      if (G.isNilOrEmpty(reservedBy)) return null;

      const subText = `${G.getWindowLocale('titles:exp', 'exp')}: ${
        G.convertDateTimeToConfigTimeZone(reservationEndDate)}`;

      return (
        <CellComponent
          subText={subText}
          mainText={reservedBy}
          icon={I.clock(mainBlueColor, 15, 15)}
        />
      );
    },
  },
  [GC.FIELD_DISTANCE]: {
    width: 120,
    name: 'titles:distance',
    customComponent: ({ data }: Object) => {
      const { distance } = data;

      if (G.isNilOrEmpty(distance)) return null;

      const singleText = `${distance} ${G.getDistanceUomLocale()}`;

      return (
        <CellComponent
          singleText={singleText}
          icon={I.analyticMaps2(mainBlueColor, 15, 15)}
        />
      );
    },
  },
  [GC.FIELD_LOCATION]: {
    width: 350,
    name: 'titles:location',
    customComponent: ({ data }: Object) => {
      const { location, futureAvailabilityDate, futureAvailabilityLocation } = data;

      if (G.isAllNilOrEmpty([location, futureAvailabilityLocation])) return null;

      const locationString = G.concatLocationFields(R.or(futureAvailabilityLocation, location));

      const lastModifiedDate = R.pathOr('', [GC.FIELD_LAST_MODIFIED_DATE], location);

      const locationDateToUse = G.ifElse(
        G.isNotNilAndNotEmpty(futureAvailabilityLocation),
        futureAvailabilityDate,
        lastModifiedDate,
      );

      return (
        <CellComponent
          mainText={R.or(locationString, 'N/A')}
          icon={I.locationMarker(mainBlueColor, 15, 15)}
          subText={G.convertDateTimeToConfigTimeZone(locationDateToUse)}
        />
      );
    },
  },
  [GC.FIELD_NOTE]: {
    width: 350,
    name: 'titles:note',
    customComponent: ({ data }: Object) => {
      const note = R.pathOr({}, [GC.FIELD_NOTE], data);

      return (
        <NoteCell
          note={note}
          handlePatchUpdateNote={(note: Object) => handlePatchUpdateNote(note, data)}
        />
      );
    },
  },
});

export const getReport = (isAllDriversList: boolean, hiddenReportFields: Array<string>) => {
  let fieldNames = [
    GC.FIELD_PREFER_DISTANCE_RATE,
    GC.FIELD_DRIVER,
    GC.FIELD_TRUCK_UNIT_ID,
    GC.FIELD_TRUCK,
    GC.FIELD_STATUS,
    GC.FIELD_NOTE,
    GC.FIELD_LOCATION,
    GC.FIELD_RESERVED_BY,
  ];

  if (G.isFalse(isAllDriversList)) fieldNames = R.insert(6, GC.FIELD_DISTANCE, fieldNames);

  const fields = fieldNames.map((name: string, index: number) => {
    if (G.notContain(name, hiddenReportFields)) {
      return ({ name, sequence: index });
    }

    return null;
  });

  return { fields: R.filter(G.isNotNil, fields) };
};

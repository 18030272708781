import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// common
import { showRequestStatusModal } from '../../../common/actions';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import {
  checkReportFunction,
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../../components/edit-report/helpers';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// report-common
import { generateDefaultReport } from '../../../report-common';
// utilities
import routesMap from '../../../utilities/routes';
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature fleet-list
import * as A from '../actions';
import { getFilterParamsByReportType } from '../settings';
// import fleetVisitPagesWatcherSaga from './visit-page-sagas';
import {
  makeSelectReportType,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectFilterParams,
  makeSelectConfigByNames,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectGlobalFilterValue,
  makeSelectTableTitleFilters,
  makeSelectFleetProfileSettings,
} from '../selectors';
//////////////////////////////////////////////////

const fleetEndpoints = {
  [GC.FLEET_TRUCK_REPORT]: {
    create: endpointsMap.truck,
    print: endpointsMap.truckPrint,
    list: endpointsMap.listFleetTrucks,
    xml: endpointsMap.getTruckXMLEndpoint,
    reference: endpointsMap.truckReference,
    export: endpointsMap.fleetTruckExportReport,
    syncByReport: endpointsMap.syncTruckByReport,
    syncSelected: endpointsMap.syncSelectedTrucks,
  },
  [GC.FLEET_DRIVER_REPORT]: {
    create: endpointsMap.driver,
    print: endpointsMap.driverPrint,
    list: endpointsMap.listFleetDrivers,
    xml: endpointsMap.getDriverXMLEndpoint,
    reference: endpointsMap.driverReference,
    export: endpointsMap.fleetDriverExportReport,
  },
  [GC.FLEET_VENDOR_REPORT]: {
    create: endpointsMap.fleetVendor,
    list: endpointsMap.fleetVendorList,
    reference: endpointsMap.vendorReference,
    sendListToIntegration: endpointsMap.sendFleetVendorListToIntegration,
  },
  [GC.FLEET_TRAILER_REPORT]: {
    create: endpointsMap.trailer,
    print: endpointsMap.trailerPrint,
    list: endpointsMap.listFleetTrailers,
    xml: endpointsMap.getTrailerXMLEndpoint,
    reference: endpointsMap.trailerReference,
    export: endpointsMap.fleetTrailerExportReport,
    syncByReport: endpointsMap.syncTrailerByReport,
    syncSelected: endpointsMap.syncSelectedTrailers,
  },
  [GC.FLEET_SHARED_COMPONENT_REPORT]: {
    create: endpointsMap.fleetSharedComponent,
    list: endpointsMap.fleetSharedComponentList,
  },
  [GC.FLEET_DRIVER_ONBOARDING_REPORT]: {
    create: endpointsMap.fleetDriverOnboardingReport,
    list: endpointsMap.fleetDriverOnboardingReportList,
  },
  [GC.FLEET_EQUIPMENT_SERVICE_REPORT]: {
    list: endpointsMap.fleetEquipmentServiceList,
    export: endpointsMap.fleetEquipmentServiceListExport,
    truck: {
      print: endpointsMap.truckPrint,
      create: endpointsMap.truckService,
      list: endpointsMap.truckServiceList,
      remove: endpointsMap.getCurrentTruckServiceEndpoint,
    },
    trailer: {
      print: endpointsMap.trailerPrint,
      create: endpointsMap.trailerService,
      list: endpointsMap.trailerServiceList,
      remove: endpointsMap.getCurrentTrailerServiceEndpoint,
    },
  },
  [GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT]: {
    create: endpointsMap.payrollAssessorial,
    list: endpointsMap.payrollAssessorialList,
    remove: endpointsMap.removePayrollAssessorial,
  },
  [GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT]: {
    create: endpointsMap.fleetVendorPayrollAccessorial,
    remove: endpointsMap.removeVendorPayrollAccessorial,
    list: endpointsMap.fleetVendorPayrollAccessorialList,
  },
  [GC.FLEET_SERVICE_ISSUE_REPORT]: {
    list: endpointsMap.fleetServiceIssueList,
    export: endpointsMap.fleetServiceIssueListExport,
    massDelete: endpointsMap.fleetServiceIssueMassDelete,
    truck: {
      create: endpointsMap.truckServiceIssue,
      list: endpointsMap.truckServiceIssueList,
      changeStatus: endpointsMap.getTruckServiceIssueStatusEndpoint,
    },
    trailer: {
      create: endpointsMap.trailerServiceIssue,
      list: endpointsMap.trailerServiceIssueList,
      changeStatus: endpointsMap.getTrailerServiceIssueStatusEndpoint,
    },
  },
};

const getFleetProfileRouteByReportType = (guid: string, reportType: string) => {
  const fleetProfileRoutes = {
    [GC.FLEET_DRIVER_REPORT]: routesMap[G.getFleetProfileRoutePathNameByFleetType()](guid),
    [GC.FLEET_TRUCK_REPORT]: routesMap[G.getFleetProfileRoutePathNameByFleetType('truck')](guid),
    [GC.FLEET_VENDOR_REPORT]: routesMap[G.getFleetProfileRoutePathNameByFleetType('vendor')](guid),
    [GC.FLEET_TRAILER_REPORT]: routesMap[G.getFleetProfileRoutePathNameByFleetType('trailer')](guid),
  };

  return G.getPropFromObject(reportType, fleetProfileRoutes);
};

function* getIsPaidOnPayrollRequest({ payload }: Object) {
  try {
    const { data, reportType } = payload;

    const { results, totalCount } = data;

    const configs = yield select(makeSelectConfigByNames());

    const isVendor = R.equals(reportType, GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT);

    const endpointName = G.ifElse(
      isVendor,
      'vendorPayrollAssessorialsPaidOnPayroll',
      'driverPayrollAssessorialsPaidOnPayroll',
    );

    const endpoint = R.prop(endpointName, endpointsMap);

    const options = {
      data: R.map(G.getGuidFromObject, R.or(results, [])),
    };

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { status, data: isPaidOnPayrollData } = res;

    if (G.isResponseSuccess(status)) {
      const mappedResults = R.map((item: Object) => {
        const { guid, historicalPaidAmount } = item;

        const deductionDisabled = R.prop(guid, isPaidOnPayrollData);

        const configName = G.ifElse(
          isVendor,
          GC.INVOICE_FLEET_VENDOR_PAYROLL_ALLOW_DELETE_PAID_DEDUCTION,
          GC.INVOICE_TEL_PAYROLL_ALLOW_DELETE_PAID_DEDUCTION,
        );

        const config = G.getConfigValueFromStore(configName, configs);
        const withoutRemove = R.and(G.isFalse(config), R.or(deductionDisabled, R.gt(historicalPaidAmount, 0)));

        return R.mergeRight(item, { withoutRemove, deductionDisabled });
      }, R.or(results, []));

      yield put(A.getItemListSuccess({ totalCount, results: mappedResults }));
    } else {
      yield call(G.handleFailResponse, res, 'getIsPaidOnPayrollRequest fail');
    }
  } catch (error) {
    yield put(A.setListLoading(false));

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getIsPaidOnPayrollRequest exception');
  }
}

export function* getItemListRequest(action: Object) {
  try {
    yield put(A.setListLoading(true));

    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.path(['defaultReport'], reportParams),
    )) return yield put(A.setListLoading(false));

    const pagination = yield select(makeSelectPagination());
    const reportType = yield select(makeSelectReportType());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const globalFilterValue = yield select(makeSelectGlobalFilterValue());

    const {
      fleetProfileType,
      fleetProfileGuid,
      fleetProfileGuidType,
    } = yield select(makeSelectFleetProfileSettings());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    let fields = G.getOrElse(reportParams, 'fields', []);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    let systemFields = [
      { name: GC.FIELD_BRANCH_GUID, freezed: false, sequence: 100, reference: false },
    ];

    const showIssues = R.and(
      R.pathEq(true, ['showIssues'], reportParams),
      R.includes(reportType, [GC.FLEET_TRUCK_REPORT, GC.FLEET_DRIVER_REPORT, GC.FLEET_TRAILER_REPORT]),
    );

    if (showIssues) {
      const issueFields = [
        { name: GC.FIELD_ISSUES, freezed: false, sequence: 200, reference: false, collection: true },
        { name: GC.FIELD_ISSUES_COUNT, freezed: false, sequence: 201, reference: false },
        { name: GC.FIELD_MAX_ISSUE_WARNING_LEVEL, freezed: false, sequence: 202, reference: false },
        // TODO: check why VENDOR_GUID here
        { name: GC.GRC.VENDOR_GUID, freezed: false, sequence: 203, reference: false, collection: true },
      ];

      systemFields = R.concat(systemFields, issueFields);
    }

    if (R.includes(reportType, [GC.FLEET_TRUCK_REPORT, GC.FLEET_TRAILER_REPORT])) {
      systemFields = R.append(
        { name: GC.GRC.DRIVER_GUID, freezed: false, sequence: 204, reference: false, collection: true },
        systemFields,
      );

      if (R.equals(reportType, GC.FLEET_TRAILER_REPORT)) {
        systemFields = R.append(
          {
            sequence: 204,
            freezed: false,
            collection: true,
            reference: false,
            name: GC.GRC.SHARED_TO_BRANCHES_GUID,
          },
          systemFields,
        );
      }
    }

    if (R.includes(reportType, [GC.FLEET_DRIVER_REPORT, GC.FLEET_TRUCK_REPORT, GC.FLEET_TRAILER_REPORT])) {
      systemFields = R.append(
        { name: GC.GRC.VENDOR_GUID, freezed: false, sequence: 205, reference: false, collection: false },
        systemFields,
      );
    }

    if (R.equals(reportType, GC.FLEET_SHARED_COMPONENT_REPORT)) {
      systemFields = [
        { name: GC.FIELD_TRUCK_GUID, freezed: false, sequence: 100, reference: false },
        { name: GC.FIELD_BRANCH_GUID, freezed: false, sequence: 101, reference: false },
        { name: GC.FIELD_TRAILER_GUID, freezed: false, sequence: 102, reference: false },
        { name: GC.GRC.FLEET_TRUCK_UNIT_ID, freezed: false, sequence: 103, reference: false },
        { name: GC.GRC.FLEET_TRAILER_UNIT_ID, freezed: false, sequence: 104, reference: false },
      ];
    }

    if (R.equals(reportType, GC.FLEET_EQUIPMENT_SERVICE_REPORT)) {
      systemFields = [
        { name: GC.FIELD_BRANCH_GUID, freezed: false, sequence: 100, reference: false },
        { name: GC.GRC.FLEET_TRUCK_GUID, freezed: false, sequence: 101, reference: false },
        { name: GC.GRC.FLEET_TRAILER_GUID, freezed: false, sequence: 102, reference: false },
        { name: GC.GRC.FLEET_TRUCK_UNIT_ID, freezed: false, sequence: 103, reference: false },
        { name: GC.GRC.FLEET_TRAILER_UNIT_ID, freezed: false, sequence: 104, reference: false },
      ];
    }

    if (R.includes(
      reportType,
      [GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT, GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT],
    )) {
      systemFields = [
        {
          sequence: 100,
          freezed: false,
          reference: false,
          name: G.ifElse(
            R.equals(reportType, GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT),
            GC.GRC.DRIVER_GUID,
            GC.GRC.VENDOR_GUID,
          ),
        },
        {
          sequence: 101, freezed: false, reference: false, name: GC.FIELD_HISTORICAL_PAID_AMOUNT,
        },
      ];

      fields = R.reject(({ name }: Object) => R.includes(
        name,
        [GC.FIELD_FUEL_RELATED, GC.FIELD_NON_TAXABLE],
      ), fields);
    }

    if (R.equals(reportType, GC.FLEET_SERVICE_ISSUE_REPORT)) {
      systemFields = [
        { name: GC.FIELD_TRUCK_GUID, freezed: false, sequence: 100, reference: false },
        { name: GC.FIELD_CREATED_BY, freezed: false, sequence: 101, reference: false },
        { name: GC.FIELD_BRANCH_GUID, freezed: false, sequence: 102, reference: false },
        { name: GC.FIELD_TRAILER_GUID, freezed: false, sequence: 103, reference: false },
        { name: GC.FIELD_WORK_ORDER_GUID, freezed: false, sequence: 104, reference: false },
        { name: GC.GRC.FLEET_TRUCK_UNIT_ID, freezed: false, sequence: 105, reference: false },
        { name: GC.GRC.FLEET_TRAILER_UNIT_ID, freezed: false, sequence: 106, reference: false },
      ];
    }

    const reqBody = {
      ...pagination,
      fields,
      orderFields,
      systemFields,
      globalFilterValue,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      guids: R.pathOr(null, ['payload', 'guids'], action),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const options = {
      data: G.setSearchCriteria({ filterParams: newFilterParams, reqBody }),
      params: G.ifElse(
        G.isNilOrEmpty(fleetProfileType),
        null,
        { [fleetProfileGuidType]: fleetProfileGuid },
      ),
    };

    const endpoint = R.or(
      R.path([reportType, fleetProfileType, 'list'], fleetEndpoints),
      R.path([reportType, 'list'], fleetEndpoints),
    );

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (R.includes(
        reportType,
        [GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT, GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT],
      )) {
        yield call(getIsPaidOnPayrollRequest, { payload: { data, reportType } });
      } else {
        yield put(A.getItemListSuccess(data));
      }

      yield put(A.setListLoading(false));
    } else {
      yield put(A.setListLoading(false));

      yield call(G.handleFailResponse, res, 'getItemListRequest fail');
    }
  } catch (error) {
    yield put(A.setListLoading(false));

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getItemListRequest exception');
  }
}

export function* getAvailableReportsRequest({ payload }: Object) {
  try {
    const { setUsedReport } = payload;

    const reportType = yield select(makeSelectReportType());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const params = {
      reportType,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };

    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const reports = G.getReportsSortedBySeqFreez(data);
      const checkedReports = checkReportFunction(reports);
      yield put(A.setReports(checkedReports));

      if (R.not(R.prop('length', reports))) {
        return yield put(A.setUsedReport(generateDefaultReport(reportType)));
      }

      if (G.isTrue(setUsedReport)) {
        const defaultReport = G.findDefaultReport(checkedReports);
        const usedReport = R.or(defaultReport, generateDefaultReport(reportType));

        yield put(A.setUsedReport(usedReport));
      }
    } else {
      yield call(G.handleFailResponse, res, 'getAvailableReportsRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getAvailableReportsRequest exception');
  }
}

function* createReportRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, payload),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.report, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const report = R.head(checkReportFunction(R.of(Array, data)));

      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(report)));
      yield put(A.getAvailableReportsRequest({ setUsedReport: false }));
      yield put(A.getItemListRequest());
    } else {
      yield call(G.handleFailResponse, res, 'createReportRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createReportRequest exception');
  }
}

function* updateReportRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'put', endpointsMap.report, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const report = R.head(checkReportFunction(R.of(Array, data)));

      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(report)));
      yield put(A.getAvailableReportsRequest({ setUsedReport: false }));
      yield put(A.getItemListRequest());
    } else {
      yield call(G.handleFailResponse, res, 'updateReportRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateReportRequest exception');
  }
}

export function* changeDefaultReportRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data: payload });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(getAvailableReportsRequest, { payload: { setUsedReport: true } });

      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest());
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeDefaultReportSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleChangeDefaultReportSaga exception');
  }
}

function* exportReportDataRequest({ payload }: Object) {
  try {
    const {
      name,
      fields,
      fileType,
      orderFields,
    } = payload;

    yield put(openLoader({ showDimmer: true }));

    const reportType = yield select(makeSelectReportType());
    const filterParams = yield select(makeSelectFilterParams());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const reqBody = {
      fields,
      orderFields,
      [GC.FIELD_REPORT_NAME]: name,
      [GC.CURRENT_BRANCH]: branchGuid,
      searchCriteria: transformSearchCriteriaBeforeReportPost(payload.searchCriteria),
    };
    const params = { format: fileType };
    const sendData = G.setSearchCriteria({ filterParams: newFilterParams, reqBody });
    const options = { params, data: sendData, resType: 'arraybuffer' };
    const endpoint = R.path([reportType, 'export'], fleetEndpoints);

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'exportReportDataRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'exportReportDataRequest exception');
  }
}
// report

// reference
function* createReferenceRequest({ payload }: Object) {
  try {
    const { values, selectedList } = payload;

    yield put(openLoader());
    const reportType = yield select(makeSelectReportType());
    const endpoint = R.path([reportType, 'reference'], fleetEndpoints);
    const options = {
      data: R.assoc('primaryObjectGuids', selectedList, values),
    };

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest());
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createReferenceRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createReferenceRequest exception');
  }
}

// item
const getShouldRequestItemListByGuid = (reportType: string) => R.includes(reportType, [
  GC.FLEET_SERVICE_ISSUE_REPORT,
  GC.FLEET_EQUIPMENT_SERVICE_REPORT,
  GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT,
  GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT,
]);

function* createFleetItemRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const reportType = yield select(makeSelectReportType());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    let endpoint = R.path([reportType, 'create'], fleetEndpoints);

    const isFleetServiceIssueReport = R.equals(reportType, GC.FLEET_SERVICE_ISSUE_REPORT);
    const isEquipmentServiceReport = R.equals(reportType, GC.FLEET_EQUIPMENT_SERVICE_REPORT);

    if (R.or(isEquipmentServiceReport, isFleetServiceIssueReport)) {
      const { fleetProfileType } = yield select(makeSelectFleetProfileSettings());

      endpoint = R.path([reportType, R.pathOr(fleetProfileType, ['entityType'], payload), 'create'], fleetEndpoints);
    }

    const values = R.pathOr(payload, ['values'], payload);

    const options = {
      data: isFleetServiceIssueReport || isEquipmentServiceReport
        ? G.makeDataForMultipleDocuments(values)
        : R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, values),
    };

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      if (R.equals(reportType, GC.FLEET_SHARED_COMPONENT_REPORT)) {
        yield put(A.createFleetItemSuccess(data));

        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      } else if (getShouldRequestItemListByGuid(reportType)) {
        yield put(A.getItemListRequest({ guids: R.of(Array, G.getGuidFromObject(data)) }));

        if (R.equals(reportType, GC.FLEET_SERVICE_ISSUE_REPORT)) {
          G.callFunction(G.getPropFromObject('successCallback', payload));
        }
      } else {
        yield call(G.goToRoute, getFleetProfileRouteByReportType(G.getGuidFromObject(data), reportType));
      }
    } else {
      yield call(G.handleFailResponse, res, 'createFleetItemRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createFleetItemRequest exception');
  }
}

function* updateFleetItemRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const reportType = yield select(makeSelectReportType());

    let endpoint = R.path([reportType, 'create'], fleetEndpoints);

    if (R.includes(reportType, [GC.FLEET_SERVICE_ISSUE_REPORT, GC.FLEET_EQUIPMENT_SERVICE_REPORT])) {
      const { fleetProfileType } = yield select(makeSelectFleetProfileSettings());

      endpoint = R.path([reportType, R.pathOr(fleetProfileType, ['entityType'], payload), 'create'], fleetEndpoints);
    }

    const options = { data: R.pathOr(payload, ['values'], payload) };

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      if (getShouldRequestItemListByGuid(reportType)) {
        yield put(A.getItemListRequest({ guids: R.of(Array, G.getGuidFromObject(data)) }));
      } else {
        yield put(A.updateFleetItemSuccess(data));
      }

      G.callFunctionWithArgs(G.getPropFromObject('successCallback', payload), data);
    } else {
      yield call(G.handleFailResponse, res, 'updateFleetItemRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateFleetItemRequest exception');
  }
}

function* removeItemRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const reportType = yield select(makeSelectReportType());

    let endpoint = R.path([reportType, 'create'], fleetEndpoints);

    if (R.equals(reportType, GC.FLEET_SERVICE_ISSUE_REPORT)) {
      endpoint = R.path([reportType, 'massDelete'], fleetEndpoints);
    }

    if (R.includes(
      reportType,
      [
        GC.FLEET_SHARED_COMPONENT_REPORT,
        GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT,
        GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT,
      ],
    )) {
      const map = {
        [GC.FLEET_SHARED_COMPONENT_REPORT]: endpointsMap.getFleetSharedComponent,
        [GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT]: endpointsMap.getPayrollAssessorialEndpoint,
        [GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT]: endpointsMap.getVendorPayrollAccessorialEndpoint,
      };

      endpoint = R.prop(reportType, map)(R.head(payload));
    }

    const isEquipmentServiceReport = R.equals(reportType, GC.FLEET_EQUIPMENT_SERVICE_REPORT);

    if (isEquipmentServiceReport) {
      const { fleetProfileType } = yield select(makeSelectFleetProfileSettings());

      const { guids, fleetType } = payload;

      endpoint = G.ifElse(
        R.equals(R.or(fleetProfileType, fleetType), GC.FIELD_TRUCK),
        endpointsMap.getCurrentTruckServiceEndpoint,
        endpointsMap.getCurrentTrailerServiceEndpoint,
      )(R.head(guids));
    }

    const options = G.ifElse(
      G.notContain(reportType, [GC.FLEET_SHARED_COMPONENT_REPORT, GC.FLEET_EQUIPMENT_SERVICE_REPORT]),
      { data: payload },
    );

    const res = yield call(sendRequest, 'delete', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield put(A.removeItemSuccess(
        G.ifElse(isEquipmentServiceReport, G.getPropFromObject('guids', payload), payload),
      ));

      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeItemRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeItemRequest exception');
  }
}

function* printItemRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const reportType = yield select(makeSelectReportType());

    let endpoint = R.path([reportType, 'print'], fleetEndpoints);

    let options = {
      data: payload,
      resType: 'arraybuffer',
    };

    if (R.equals(reportType, GC.FLEET_EQUIPMENT_SERVICE_REPORT)) {
      const { fleetProfileType } = yield select(makeSelectFleetProfileSettings());

      options = { ...options, data: R.omit(['entityType'], payload) };
      endpoint = R.path([reportType, R.pathOr(fleetProfileType, ['entityType'], payload), 'print'], fleetEndpoints);
    }

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res, `${reportType}.pdf`);

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'printItemRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'printItemRequest exception');
  }
}

function* getItemXMLRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const reportType = yield select(makeSelectReportType());
    const endpoint = R.path([reportType, 'xml'], fleetEndpoints)(payload);

    const res = yield call(sendRequest, 'get', endpoint, { resType: 'arraybuffer' });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res);
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTrailerXML fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetTrailerXML exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

// sync by vin
function* syncItemsByVINRequest({ payload }: Object) {
  try {
    const { report, guids, byReport } = payload;

    yield put(openLoader());
    const reportType = yield select(makeSelectReportType());
    const endpoint = R.path([reportType, G.ifElse(byReport, 'syncByReport', 'syncSelected')], fleetEndpoints);
    const options = {
      data: G.ifElse(G.isTrue(byReport), report, guids),
    };

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination());
      yield call(getItemListRequest);
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'syncItemsByVINRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'syncItemsByVINRequest exception');
  }
}

// ifta
function* generateIftaRequest({ payload }: Object) {
  try {
    const { data, byReport } = payload;

    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const endpoint = G.ifElse(G.isTrue(byReport), endpointsMap.generateIftaByReport, endpointsMap.generateIfta);
    const options = {
      data: R.assoc(G.ifElse(G.isTrue(byReport), GC.CURRENT_BRANCH, GC.BRANCH_GUID), branchGuid, data),
    };

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'generateIftaRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'generateIftaRequest exception');
  }
}

// trailer
function* shareOrCancelSharingTrailerRequest({ payload }: Object) {
  try {
    const { guid, share } = payload;

    yield put(openLoader());

    const options = { data: payload };
    const endpoint = R.prop(G.ifElse(share, 'shareTrailer', 'trailerCancelSharing'), endpointsMap);

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getItemListRequest({ guids: R.of(Array, guid) }));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'shareOrCancelSharingTrailerRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'shareOrCancelSharingTrailerRequest exception');
  }
}

// shared component
function* assignSharedComponentRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      data: payload,
    };

    const res = yield call(sendRequest, 'put', endpointsMap.reassignFleetSharedComponent, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.resetListAndPagination());
      yield call(getItemListRequest);
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'assignSharedComponentRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'assignSharedComponentRequest exception');
  }
}

function* sendListToIntegrationRequest({ payload }: Object) {
  try {
    const { selectedList, integrationType } = payload;

    yield put(openLoader());
    const options = {
      data: selectedList,
      params: { integrationType },
    };
    const reportType = yield select(makeSelectReportType());
    const endpoint = R.path([reportType, 'sendListToIntegration'], fleetEndpoints);
    const res = yield call(sendRequest, 'post', endpoint, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const { totalCount, successCount } = data;

      yield put(A.resetListAndPagination());
      yield call(getItemListRequest);
      yield put(closeModal());
      const errors = R.compose(
        R.values,
        R.mapObjIndexed((messageArray: array, key: string) => ({ key, messageArray })),
        R.pathOr({}, ['errors']),
      )(data);
      const title = `${G.getWindowLocale('titles:accounting', 'Accounting')} ${
        G.getWindowLocale('titles:export-result', 'Export Result')}`;

      yield put(showRequestStatusModal({
        title,
        errors,
        totalCount,
        successCount,
        status: G.getPropFromObject(GC.FIELD_STATUS, data),
      }));
    } else {
      yield call(G.handleFailResponse, res, 'sendListToIntegrationRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, 'error', 'sendListToIntegrationRequest exception');
  }
}

// driver
function* setFleetDriverActiveTab({ payload }: Object) {
  try {
    yield put(openLoader());

    yield put(A.setReportPending());
    yield put(A.setReportType(payload));
    yield put(A.setFilterProps(getFilterParamsByReportType(payload)));
    yield call(getAvailableReportsRequest, { payload: { setUsedReport: true } });
    yield put(A.getItemListRequest());

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'setFleetDriverActiveTab exception');
  }
}

// driver onboarding
function* getDriverListByDriverOnboardingGuidRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.getDriversByOnboardingGuid(payload);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getDriverListByDriverOnboardingGuidSuccess({
        data,
        driverOnboardingGuid: payload,
      }));
    } else {
      G.handleFailResponse(res, 'getDriverListByDriverOnboardingGuidRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getDriverListByDriverOnboardingGuidRequest exception');
  }
}

// configs
function* handleGetConfigsByNamesRequestSaga() {
  try {
    const reportType = yield select(makeSelectReportType());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const payrollAssessorialNames = [
      GC.INVOICE_TEL_PAYROLL_ALLOW_DELETE_PAID_DEDUCTION,
      GC.INVOICE_FLEET_VENDOR_PAYROLL_ALLOW_DELETE_PAID_DEDUCTION,
    ];

    const namesMap = {
      [GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT]: payrollAssessorialNames,
      [GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT]: payrollAssessorialNames,
      [GC.FLEET_EQUIPMENT_SERVICE_REPORT]: [GC.TRUCK_DOCUMENT_TYPE, GC.TRAILER_DOCUMENT_TYPE],
    };

    const namesArray = G.getPropFromObject(reportType, namesMap);

    if (G.isNilOrEmpty(namesArray)) return;

    const options = {
      params: {
        names: R.join(',', namesArray),
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getConfigsByNamesSuccess(G.mapConfigValuesByName(data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetConfigsByNamesRequestSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetConfigsByNamesRequestSaga exception');
  }
}

function* getAccessorialConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.accessorialsListEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAccessorialConfigListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getAccessorialConfigListRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getAccessorialConfigListRequest exception');
  }
}

// mass create
function* massCreateFleetRequest({ payload }: Object) {
  try {
    const { setValues, fleetDtos, handleSetActiveTab, setResetListOnCloseModal } = payload;

    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: { fleetDtos, [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.fleetAssignmentMassCreate, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const errors = data.errors;

      if (G.isNotNilAndNotEmpty(errors)) {
        const errorMessages = R.reduce((acc: Array, item: Object) => {
          const { index, errorMessages } = item;

          const message = `${G.getWindowLocale('titles:fleet', 'Fleet')} - ${index} ${
            R.join(' ', errorMessages)
          }`;

          return R.append(message, acc);
        }, [], errors);

        G.handlePartialSuccessErrors(errorMessages);

        if (G.notEquals(R.length(errors), R.length(fleetDtos))) {
          const indexes = R.map(R.prop('index'), errors);
          const failedFleets = R.compose(
            G.mapIndexed((item: Object, index: number) => R.assoc('index', R.inc(index), item)),
            R.filter(({ index }: Object) => R.includes(index, indexes)),
          )(fleetDtos);

          handleSetActiveTab(0);
          setResetListOnCloseModal(true);
          setValues({ fleetDtos: failedFleets });
        }
      } else {
        yield put(closeModal());
        yield put(A.resetListAndPagination());
        yield call(getItemListRequest);
        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      }
    } else {
      yield call(G.handleFailResponse, res, 'massCreateFleetRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'massCreateFleetRequest exception');
  }
}

// payroll accessorial
function* getPayrollAccessorialChargesByPayrollAccessorialGuidRequest({ payload }: Object) {
  try {
    const { isVendor, payrollAssessorialGuid } = payload;

    const options = {
      params: { payrollAssessorialGuid },
    };

    const endpointName = G.ifElse(
      isVendor,
      'vendorPayrollChargesByPayrollAssessorial',
      'driverPayrollChargesByPayrollAssessorial',
    );

    const endpoint = R.prop(endpointName, endpointsMap);

    const res = yield call(sendRequest, 'get', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getPayrollAccessorialChargesByPayrollAccessorialGuidSuccess({ data, payrollAssessorialGuid }));
    } else {
      yield call(G.handleFailResponse, res, 'getPayrollAccessorialChargesByPayrollAccessorialGuidRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getPayrollAccessorialChargesByPayrollAccessorialGuidRequest exception');
  }
}

// fleet service issue
function* changeFleetServiceIssueStatusRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { values, entityType } = payload;

    const guid = G.getGuidFromObject(values);

    const endpoint = R.path(
      [GC.FLEET_SERVICE_ISSUE_REPORT, entityType, 'changeStatus'],
      fleetEndpoints,
    )(guid);

    const res = yield call(sendRequest, 'put', endpoint, { params: values });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getItemListRequest({ guids: R.of(Array, guid) }));

      yield call(G.showToastrMessage, 'success', 'messages:success:save');
    } else {
      yield call(G.handleFailResponse, res, 'changeFleetServiceIssueStatusRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'changeFleetServiceIssueStatusRequest exception');
  }
}

function* fleetListWatcherSaga() {
  // TODO: remove after testing
  // visit page
  // yield fork(fleetVisitPagesWatcherSaga);
  // report
  yield takeLatest(A.getItemListRequest, getItemListRequest);
  yield takeLatest(A.updateReportRequest, updateReportRequest);
  yield takeLatest(A.createReportRequest, createReportRequest);
  yield takeLatest(A.exportReportDataRequest, exportReportDataRequest);
  yield takeLatest(A.changeDefaultReportRequest, changeDefaultReportRequest);
  yield takeLatest(A.getAvailableReportsRequest, getAvailableReportsRequest);
  // item
  yield takeLatest(A.printItemRequest, printItemRequest);
  yield takeLatest(A.getItemXMLRequest, getItemXMLRequest);
  yield takeLatest(A.removeItemRequest, removeItemRequest);
  yield takeLatest(A.updateFleetItemRequest, updateFleetItemRequest);
  yield takeLatest(A.createReferenceRequest, createReferenceRequest);
  yield takeLatest(A.createFleetItemRequest, createFleetItemRequest);
  // mass create
  yield takeLatest(A.massCreateFleetRequest, massCreateFleetRequest);
  // sync by vin
  yield takeLatest(A.syncItemsByVINRequest, syncItemsByVINRequest);
  // ifta
  yield takeLatest(A.generateIftaRequest, generateIftaRequest);
  // shared component
  yield takeLatest(A.assignSharedComponentRequest, assignSharedComponentRequest);
  // send to integration
  yield takeLatest(A.sendListToIntegrationRequest, sendListToIntegrationRequest);
  // driver
  yield takeLatest(A.setFleetDriverActiveTab, setFleetDriverActiveTab);
  // driver onboarding
  yield takeLatest(A.getDriverListByDriverOnboardingGuidRequest, getDriverListByDriverOnboardingGuidRequest);
  // trailer
  yield takeLatest(A.shareOrCancelSharingTrailerRequest, shareOrCancelSharingTrailerRequest);
  // configs
  yield takeLatest(A.getConfigsByNamesRequest, handleGetConfigsByNamesRequestSaga);
  yield takeLatest(A.getAccessorialConfigListRequest, getAccessorialConfigListRequest);
  // payroll accessorial
  yield takeLatest(
    A.getPayrollAccessorialChargesByPayrollAccessorialGuidRequest,
    getPayrollAccessorialChargesByPayrollAccessorialGuidRequest,
  );
  // fleet service issue
  yield takeLatest(A.changeFleetServiceIssueStatusRequest, changeFleetServiceIssueStatusRequest);
}

export default fleetListWatcherSaga;

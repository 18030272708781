import * as R from 'ramda';
import { FieldArray } from 'formik';
import React, { Fragment } from 'react';
// features
import PC from '../../../permission/role-permission';
import { CostsSection } from '../../../work-order/components/costs-section';
// components
import { TextComponent } from '../../../../components/text';
import { FormSectionHeader } from '../../../../components/form-section-header';
// forms
import { Fieldset2 } from '../../../../forms/formik';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
import { AmousLogoAiComponent } from '../../../../svgs/amous-animated-logo/animated-logo';
// ui
import { Box, Flex } from '../../../../ui';
// feature fleet
import { getInvoiceTotalValue } from '../../equipment-service/components/form';
import {
  chargeDefaultSettings,
} from '../../equipment-service/settings/field-settings';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

const fieldsWrapperStyles = { mb: 30, width: '100%' };

const inputWrapperStyles = { mr: 15, flexGrow: 1, flexBasis: 110 };

const inputWrapperStyles2 = { flexGrow: 1, flexBasis: 110 };

const inputWrapperStyles3 = { mr: 15, flexGrow: 1, flexBasis: 75 };

const inputWrapperStyles4 = { flexGrow: 1, flexBasis: 70 };

const fieldsetSettings = [
  {
    fieldsWrapperStyles,
    fields: [
      {
        type: 'text',
        isRequired: true,
        inputWrapperStyles,
        label: ['titles:invoice-number', 'Invoice Number'],
        fieldName: `${GC.SYSTEM_OBJECT_INVOICE}.${GC.FIELD_INVOICE_NUMBER}`,
      },
      {
        type: 'select',
        inputWrapperStyles,
        options: 'invoiceStatusOptions',
        label: ['titles:invoice-status', 'Invoice Status'],
        fieldName: `${GC.SYSTEM_OBJECT_INVOICE}.${GC.FIELD_INVOICE_STATUS}`,
      },
      {
        isRequired: true,
        isClearable: true,
        type: 'datePicker',
        inputWrapperStyles,
        shouldCustomChange: true,
        label: ['titles:invoice-date', 'Invoice Date'],
        fieldName: `${GC.SYSTEM_OBJECT_INVOICE}.${GC.FIELD_INVOICE_DATE}`,
        customChangeHandler2: ({ value, props }: Object) => {
          const { values, setValues } = props;

          // TODO: check if we need that logic

          const invoiceDate = G.convertInstanceToDefaultDateFormat(value);
          const performedDate = R.propOr(invoiceDate, GC.FIELD_PERFORMED_DATE, values);

          const newValues = R.compose(
            R.assoc(GC.FIELD_PERFORMED_DATE, performedDate),
            R.assocPath([GC.SYSTEM_OBJECT_INVOICE, GC.FIELD_INVOICE_DATE], invoiceDate),
          )(values);

          return setValues(newValues);
        },
      },
      {
        type: 'select',
        isRequired: true,
        options: GC.CURRENCY_OPTIONS,
        label: ['titles:currency', 'Currency'],
        inputWrapperStyles: inputWrapperStyles2,
        fieldName: `${GC.SYSTEM_OBJECT_INVOICE}.${GC.FIELD_CURRENCY}`,
      },
    ],
  },
  {
    fieldsWrapperStyles,
    fields: [
      {
        type: 'select',
        inputWrapperStyles,
        options: 'glCodeOptions',
        label: ['titles:gl-code', 'GL Code'],
        fieldName: `${GC.SYSTEM_OBJECT_INVOICE}.${GC.FIELD_GL_CODE}`,
        disabled: () => G.hasNotAmousCurrentUserPermissions(PC.GL_CODE_WRITE),
      },
      {
        type: 'text',
        inputWrapperStyles,
        label: ['titles:po-number', 'PO Number'],
        fieldName: `${GC.SYSTEM_OBJECT_INVOICE}.${GC.FIELD_PO_NUMBER}`,
      },
      {
        type: 'text',
        inputWrapperStyles,
        label: ['titles:net-days', 'Ned Days'],
        fieldName: `${GC.SYSTEM_OBJECT_INVOICE}.${GC.FIELD_INVOICE_NET_DAYS}`,
      },
      {
        isClearable: true,
        type: 'datePicker',
        inputWrapperStyles: inputWrapperStyles2,
        label: ['titles:payment-due-date', 'Payment Due Date'],
        fieldName: `${GC.SYSTEM_OBJECT_INVOICE}.${GC.FIELD_INVOICE_PAYMENT_DUE_DATE}`,
      },
    ],
  },
  {
    fieldsWrapperStyles,
    fields: [
      {
        type: 'text',
        inputWrapperStyles: inputWrapperStyles3,
        label: ['titles:check-number', 'Check Number'],
        fieldName: `${GC.SYSTEM_OBJECT_INVOICE}.${GC.FIELD_INVOICE_CHECK_NUMBER}`,
      },
      {
        isClearable: true,
        type: 'datePicker',
        inputWrapperStyles,
        shouldCustomChange: true,
        label: ['titles:check-date', 'Check Date'],
        fieldName: `${GC.SYSTEM_OBJECT_INVOICE}.${GC.FIELD_INVOICE_CHECK_DATE}`,
      },
      {
        type: 'text',
        inputWrapperStyles: inputWrapperStyles3,
        label: ['titles:check-amount', 'Check Amount'],
        fieldName: `${GC.SYSTEM_OBJECT_INVOICE}.${GC.FIELD_CHECK_AMOUNT}`,
      },
      {
        isClearable: true,
        type: 'datePicker',
        inputWrapperStyles,
        label: ['titles:deposit-date', 'Deposit Date'],
        fieldName: `${GC.SYSTEM_OBJECT_INVOICE}.${GC.FIELD_INVOICE_DEPOSIT_DATE}`,
      },
      {
        type: 'text',
        label: ['titles:ach', 'ACH'],
        inputWrapperStyles: inputWrapperStyles4,
        fieldName: `${GC.SYSTEM_OBJECT_INVOICE}.${GC.FIELD_INVOICE_ACH_PAYMENT_CONFIRMATION_NUMBER}`,
      },
    ],
  },
  {
    fieldsWrapperStyles: { width: '100%' },
    fields: [
      {
        type: 'textarea',
        inputStyles: {
          p: 15,
          height: 100,
        },
        inputWrapperStyles: {
          flexGrow: 1,
          flexBasis: 300,
        },
        label: ['titles:comments', 'Comments'],
        fieldName: `${GC.SYSTEM_OBJECT_INVOICE}.${GC.FIELD_COMMENTS}`,
      },
    ],
  },
];

const chargeFieldSettings = [
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_DISPLAYED_NAME,
    label: ['titles:name', 'Name'],
    inputWrapperStyles: { mr: 15, width: 200 },
  },
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_TOTAL,
    label: ['titles:total', 'Total'],
    inputWrapperStyles: { mr: 15, width: 100 },
  },
  {
    type: 'text',
    fieldName: GC.FIELD_COMMENTS,
    inputWrapperStyles: { width: 240 },
    label: ['titles:comments', 'Comments'],
  },
];

const ChargesSection = (props: Object) => {
  const { push, remove, chargesTotal } = props;

  const charges = R.pathOr([], ['values', GC.SYSTEM_OBJECT_INVOICE, GC.FIELD_CHARGES], props);

  const chargesLength = R.length(charges);

  return (
    <FormSectionHeader
      expanded={G.isNotNilAndNotEmpty(charges)}
      action={() => push(chargeDefaultSettings())}
      title={G.getWindowLocale('titles:charges', 'Charges')}
    >
      {
        charges.map((_: any, index: number) => (
          <Flex my={25} key={index}>
            <Box
              mx={10}
              display='flex'
              cursor='pointer'
              alignItems='center'
              onClick={() => remove(index)}
              title={G.getWindowLocale('titles:remove', 'Remove')}
            >
              {I.trash(darkBlueColor)}
            </Box>
            <Fieldset2
              {...G.getFormikProps(props)}
              {...G.getArrayFormikProps(props)}
              itemIndex={index}
              arrayLength={chargesLength}
              fields={chargeFieldSettings}
              arrayName={`${GC.SYSTEM_OBJECT_INVOICE}.${GC.FIELD_CHARGES}`}
            />
          </Flex>
        ))
      }
      <Flex px={15} width='100%' flexShrink={0} justifyContent='flex-end'>
        <Box
          mr='8px'
          fontWeight={700}
          textTransform='uppercase'
          color={G.getTheme('colors.darkGrey')}
        >
          {G.getWindowLocale('titles:subtotal', 'Subtotal')}:
        </Box>
        <TextComponent
          maxWidth={300}
          display='block'
          fontWeight={700}
          title={chargesTotal}
          withEllipsis={true}
        >
          {chargesTotal}
        </TextComponent>
      </Flex>
    </FormSectionHeader>
  );
};

const AiLoading = () => (
  <Flex alignItems='center' justifyContent='center'>
    <AmousLogoAiComponent />
  </Flex>
);

const InvoiceForm = (props: Object) => {
  const {
    values,
    glCodeOptions,
    aiRecognizeLoading,
    invoiceStatusOptions,
  } = props;

  if (G.isTrue(aiRecognizeLoading)) return <AiLoading />;

  const chargesTotal = getInvoiceTotalValue(values);

  return (
    <Fragment>
      <Box p={15}>
        <Fieldset2
          {...G.getFormikProps(props)}
          grouped={true}
          fields={fieldsetSettings}
          glCodeOptions={glCodeOptions}
          invoiceStatusOptions={invoiceStatusOptions}
        />
      </Box>
      <FieldArray
        name={`${GC.SYSTEM_OBJECT_INVOICE}.${GC.FIELD_CHARGES}`}
        render={(arrayHelpers: Object) => <ChargesSection {...props} {...arrayHelpers} chargesTotal={chargesTotal} />}
      />
      <CostsSection
        {...props}
        version={2}
        additionTotal={chargesTotal}
        objectName={GC.SYSTEM_OBJECT_INVOICE}
      />
    </Fragment>
  );
};

export default InvoiceForm;

import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const store = R.path(['fleetProfile', 'trailer']);

const selectTrailerGeneralDetails = createSelector(
  R.path(['fleetProfile', 'trailer', 'generalDetails']),
  (generalDetails: Object) => {
    if (R.isNil(generalDetails)) return;

    const {
      trailerType,
      outOfServiceReason,
      unavailabilityReason,
    } = generalDetails;

    return {
      ...generalDetails,
      trailerTypeDisplayedValue: G.getDisplayedValueFromObject(trailerType),
      [GC.FIELD_TRAILER_TYPE]: G.getDropdownOptionGuidFromObject(trailerType),
      [GC.FIELD_OUT_OF_SERVICE_REASON]: R.propOr('', GC.FIELD_DROPDOWN_OPTION_GUID, outOfServiceReason),
      [GC.FIELD_UNAVAILABILITY_REASON]: R.propOr('', GC.FIELD_DROPDOWN_OPTION_GUID, unavailabilityReason),
    };
  },
);

const makeSelectTrailerGeneralTabEntities = () => createSelector(
  store,
  R.pick([
    'references',
    'insurances',
    'integrationList',
    'lastKnownLocation',
  ]),
);

const makeSelectTrailerLastKnownLocationTabEntities = () => createSelector(
  store,
  R.pick(['lastKnownLocation']),
);

const makeSelectTrailerMaintenanceTabEntities = () => createSelector(
  store,
  R.pick(['equipmentComponent']),
);

const makeSelectTrailerSpecificationsTabEntities = () => createSelector(
  store,
  R.pick(['locations', 'ownership', 'specification']),
);

const makeSelectTrailerDocumentsTabEntities = () => createSelector(
  store,
  (state: Object) => {
    const { documents } = state;

    const documentsFilter = R.path(['formGroupFilters', 'documentsFilter'], state);

    const filteredDocuments = R.compose(
      R.filter((item: Object) => R.or(
        G.isNilOrEmpty(documentsFilter),
        R.pathEq(documentsFilter, [GC.FIELD_DOCUMENT_DOCUMENT_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], item),
      )),
      R.pathOr([], ['documents']),
    )(state);

    return {
      documents: G.ifElse(G.isNotNil(documents), filteredDocuments, documents),
    };
  },
);

const makeSelectTrailerTrackingTabEntities = () => createSelector(
  store,
  R.pick(['trailerTrackingList']),
);

const makeSelectGeneralUsersAndDispatchingGroup = () => createSelector(
  R.path(['fleetProfile', 'generalUserList']),
  R.path(['fleetProfile', 'dispatchingGroup']),
  (generalUserList: Array, dispatchingGroup: Array) => ({ generalUserList, dispatchingGroup }),
);

export {
  // common
  selectTrailerGeneralDetails,
  // tabs
  makeSelectTrailerGeneralTabEntities,
  makeSelectTrailerTrackingTabEntities,
  makeSelectTrailerDocumentsTabEntities,
  makeSelectTrailerMaintenanceTabEntities,
  makeSelectGeneralUsersAndDispatchingGroup,
  makeSelectTrailerSpecificationsTabEntities,
  makeSelectTrailerLastKnownLocationTabEntities,
};

import * as R from 'ramda';
import { useSelector } from 'react-redux';
import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Grid } from '../../../ui';
// feature geo-fencing-location
import * as H from '../helpers';
import LocationMap from './location-map';
import TrailerList from './trailer-list';
import { makeSelectListLoading, makeSelectTrailersByLocation } from '../selectors';
//////////////////////////////////////////////////

const ViewLocationPopup = (props: Object) => {
  const { closeModal, fromTrailer, currentLocation} = props;

  const loading = useSelector(makeSelectListLoading());
  const trailerList = useSelector(makeSelectTrailersByLocation());

  const [divisionFilter, setDivisionFilter] = useState([]);

  const position = R.pick(['latitude', 'longitude'], R.or(currentLocation, {}));

  const radius = R.prop(GC.FIELD_FLEET_LOCATION_RADIUS, currentLocation);

  const handleSetDivisionFilter = useCallback(
    (divisions: any) => setDivisionFilter(R.pluck('value', divisions)),
    [],
  );

  const filteredTrailersList = useMemo(() => (
    G.isNotNilAndNotEmpty(divisionFilter) ?
    R.filter(
      R.compose(
        R.flip(R.includes)(divisionFilter),
        R.prop('enterprise.guid'),
      ),
      trailerList,
    ) :
    trailerList
  ), [divisionFilter, trailerList]);

  useEffect(() => () => closeModal(), []);

  return (
    <Box
      width={1020}
      maxHeight='85vh'
      overflow='hidden'
      p='20px 15px 15px 15px'
    >
      <Grid gap={15} gridTemplateColumns='350px 1fr'>
        <TrailerList
          loading={loading}
          fromTrailer={fromTrailer}
          trailerList={trailerList}
          currentLocation={currentLocation}
          filteredTrailersList={filteredTrailersList}
          handleSetDivisionFilter={handleSetDivisionFilter}
        />
        <LocationMap mode={H.VIEW_MODE} radius={radius} position={position} trailerList={filteredTrailersList} />
      </Grid>
    </Box>
  );
};

export default ViewLocationPopup;

import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import React, { memo, useState, useCallback } from 'react';
// components
import DimmerComponent from '../../../components/loader/dimmer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hooks
import { useModalAndLoaderActions } from '../../../hooks';
// utilities
import routesMap from '../../../utilities/routes';
// feature fleet-profile
import TrailerCards from './components/cards';
import TrailerTabsGroups from './components/tabs-group';
import { generalDetailsSettings } from './settings/general-details';
import FleetProfileWrapper from '../components/fleet-profile-wrapper';
import GeneralDetails, { PageHeader } from '../forms/general-details';
import { useFleetProfileTabsView } from '../hooks/use-fleet-profile-tabs-view';
import { setInitialState, updateTrailerGeneralDetailsRequest } from '../actions';
import { useFleetProfileWrapperActions } from '../hooks/use-fleet-profile-wrapper-actions';
import { makeSelectConfigGroup, makeSelectCollapsedGroup, selectTrailerGeneralDetails } from '../selectors';
//////////////////////////////////////////////////

const TrailerGeneralDetails = memo((props: Object) => {
  const { configGroup, initialValues: { unitId } } = props;

  const title = `${G.getWindowLocale('titles:fleet-trailer', 'Fleet Trailer')} ${unitId}`;

  const optionsForSelect = R.pick(
    [
      GC.TRAILER_TRAILER_TYPE,
      GC.TRAILER_OUT_SERVICE_REASON,
      GC.TRAILER_UNAVAILABILITY_REASON,
    ],
    R.propOr({}, 'dropdownOptions', configGroup),
  );

  const trailerProps = {
    ...generalDetailsSettings,
    title,
    active: true,
    type: 'trailer',
    optionsForSelect,
    showStatus: true,
    hasAssignment: true,
  };

  return <GeneralDetails {...props} {...trailerProps} />;
});

const FleetProfileTrailer = ({ handleGoBack, expandedContainer, closeExpandedContainer }: Object) => {
  const [saveAndClose, setSaveAndClose] = useState(false);

  const configGroup = useSelector(makeSelectConfigGroup());
  const collapsedGroup = useSelector(makeSelectCollapsedGroup());
  const generalDetails = useSelector(selectTrailerGeneralDetails);

  const dispatch = useDispatch();

  const commonActions = useModalAndLoaderActions();

  const { openModal, closeModal } = commonActions;

  const handleClose = useCallback(() => {
    dispatch(setInitialState());

    if (G.isFunction(closeExpandedContainer)) return closeExpandedContainer();

    G.goToRoute(routesMap.fleetTrailerList);
  }, []);

  const {
    handleToggleFormGroupTable,
    handleUpdateGeneralDetails,
    handleGoToProfileByProfileType,
  } = useFleetProfileWrapperActions({
    dispatch,
    closeModal,
    handleClose,
    saveAndClose,
    generalDetails,
    setSaveAndClose,
    expandedContainer,
    updateGeneralDetailsRequest: updateTrailerGeneralDetailsRequest,
  });

  const handleOpenGeneralDetails = useCallback((openedSection: string) => {
    const component = (
      <TrailerGeneralDetails
        {...commonActions}
        mb='0px'
        showCloseButton={true}
        shouldCloseModal={true}
        tabsCollapsedView={true}
        withoutPageHeader={true}
        configGroup={configGroup}
        hasFormSectionTitles={true}
        saveAndClose={saveAndClose}
        openedSection={openedSection}
        initialValues={generalDetails}
        shouldNotDisableSubmitBtn={true}
        setSaveAndClose={setSaveAndClose}
        submitAction={handleUpdateGeneralDetails}
        driverAssignmentGuid={R.prop(GC.FIELD_DRIVER_GUID, generalDetails)}
      />
    );

    const modal = {
      p: '0px',
      component,
      options: {
        width: 1200,
        title: G.getWindowLocale('titles:general-details', 'General Details'),
      },
    };

    openModal(modal);
  }, [configGroup, generalDetails, handleUpdateGeneralDetails]);

  const { setTabsView, tabsCollapsedView } = useFleetProfileTabsView();

  const defaultProps = {
    ...commonActions,
    configGroup,
    tabsCollapsedView,
    fleetType: 'trailer',
  };

  const { guid, unitId, sharedToEnterpriseGuids } = R.or(generalDetails, {});

  const shared = R.includes(G.getAmousCurrentBranchGuidFromWindow(), R.or(sharedToEnterpriseGuids, []));

  if (G.isNilOrEmpty(guid)) return <DimmerComponent tableCount={3} rectangleCount={4} />;

  return (
    <FleetProfileWrapper
      handleClose={handleClose}
      handleGoBack={handleGoBack}
      expandedContainer={expandedContainer}
    >
      <PageHeader
        {...defaultProps}
        active={true}
        type='trailer'
        shared={shared}
        showStatus={true}
        configGroup={configGroup}
        initialValues={generalDetails}
        hasAssignment={G.isFalse(shared)}
        handleGoToProfileByProfileType={handleGoToProfileByProfileType}
        driverAssignmentGuid={R.prop(GC.FIELD_DRIVER_GUID, generalDetails)}
        title={`${G.getWindowLocale('titles:fleet-trailer', 'Fleet Trailer')} ${unitId}`}
      />
      {
        tabsCollapsedView &&
        <TrailerCards
          {...defaultProps}
          shared={shared}
          configGroup={configGroup}
          generalDetails={generalDetails}
          handleOpenGeneralDetails={handleOpenGeneralDetails}
          handleUpdateGeneralDetails={handleUpdateGeneralDetails}
        />
      }
      <TrailerTabsGroups
        {...defaultProps}
        shared={shared}
        primaryObjectGuid={guid}
        setTabsView={setTabsView}
        primaryObjectUnitId={unitId}
        collapsedGroup={collapsedGroup}
        handleToggleFormGroupTable={handleToggleFormGroupTable}
        handleUpdateGeneralDetails={handleUpdateGeneralDetails}
        handleGoToProfileByProfileType={handleGoToProfileByProfileType}
        primaryObjectBranchGuid={G.getBranchGuidFromObject(generalDetails)}
      />
    </FleetProfileWrapper>
  );
};

export default FleetProfileTrailer;

import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
import { smtpEncryptionTypeOptions, integrationMailConfigTypeOptions } from '../../../../../helpers/options';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 270,
};

const fields = [
  {
    type: 'toggle',
    inputWrapperStyles,
    label: ['titles:active'],
    fieldName: GC.FIELD_ENABLED,
  },
  {
    type: 'select',
    inputWrapperStyles,
    label: ['titles:integration-type'],
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    options: integrationMailConfigTypeOptions(),
    customDisabledFunction: 'handleDisableIntegrationType',
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:default-email'],
    fieldName: GC.FIELD_DEFAULT_EMAIL,
    fieldGroupDisplay: (_: any, { integrationType }: Object) => G.ifElse(
      R.includes(integrationType, [GC.INTEGRATION_MAIL_CONFIG_TYPE_CUSTOM, GC.INTEGRATION_MAIL_CONFIG_TYPE_OUTLOOK]),
      'block',
      'none',
    ),
  },
  {
    type: 'text',
    label: ['titles:client-id'],
    fieldName: GC.FIELD_CLIENT_ID,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: (props: Object) => G.ifElse(
        R.pathEq(GC.INTEGRATION_MAIL_CONFIG_TYPE_CUSTOM, ['values', GC.FIELD_INTEGRATION_TYPE], props),
        'none',
      ),
    },
  },
  {
    type: 'text',
    label: ['titles:client-secret'],
    fieldName: GC.FIELD_CLIENT_SECRET,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: (props: Object) => G.ifElse(
        R.pathEq(GC.INTEGRATION_MAIL_CONFIG_TYPE_CUSTOM, ['values', GC.FIELD_INTEGRATION_TYPE], props),
        'none',
      ),
    },
  },
  {
    type: 'text',
    label: ['titles:tenant-id'],
    fieldName: GC.FIELD_TENANT_ID,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: ({ values: { integrationType } = {} }: Object) => G.ifElse(
        R.equals(integrationType, GC.INTEGRATION_MAIL_CONFIG_TYPE_OUTLOOK),
        'block',
        'none',
      ),
    },
  },
  {
    type: 'text',
    label: ['titles:user-name'],
    fieldName: GC.FIELD_USERNAME,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: ({ values: { integrationType } = {} }: Object) => G.ifElse(
        R.equals(integrationType, GC.INTEGRATION_MAIL_CONFIG_TYPE_OUTLOOK),
        'block',
        'none',
      ),
    },
  },
  {
    type: 'text',
    label: ['titles:password'],
    fieldName: GC.FIELD_PASSWORD,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: ({ values: { integrationType } = {} }: Object) => G.ifElse(
        R.equals(integrationType, GC.INTEGRATION_MAIL_CONFIG_TYPE_CUSTOM),
        'block',
        'none',
      ),
    },
  },
  {
    type: 'text',
    label: ['titles:host'],
    fieldName: GC.FIELD_HOST,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: ({ values: { integrationType } = {} }: Object) => G.ifElse(
        R.equals(integrationType, GC.INTEGRATION_MAIL_CONFIG_TYPE_CUSTOM),
        'block',
        'none',
      ),
    },
  },
  {
    type: 'text',
    label: ['titles:port'],
    fieldName: GC.FIELD_PORT,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: ({ values: { integrationType } = {} }: Object) => G.ifElse(
        R.equals(integrationType, GC.INTEGRATION_MAIL_CONFIG_TYPE_CUSTOM),
        'block',
        'none',
      ),
    },
  },
  {
    type: 'select',
    options: smtpEncryptionTypeOptions(),
    label: ['titles:smtp-encryption-type'],
    fieldName: GC.FIELD_SMTP_ENCRYPTION_TYPE,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: ({ values: { integrationType } = {} }: Object) => G.ifElse(
        R.equals(integrationType, GC.INTEGRATION_MAIL_CONFIG_TYPE_CUSTOM),
        'block',
        'none',
      ),
    },
  },
  {
    type: 'toggle',
    label: ['titles:disable-ssl-certificate-validation'],
    fieldName: GC.FIELD_DISABLE_SSL_CERTIFICATE_VALIDATION,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: ({ values: { integrationType } = {} }: Object) => G.ifElse(
        R.equals(integrationType, GC.INTEGRATION_MAIL_CONFIG_TYPE_CUSTOM),
        'flex',
        'none',
      ),
    },
  },
];

const defaultValues = {
  [GC.FIELD_PORT]: null,
  [GC.FIELD_HOST]: null,
  [GC.FIELD_PASSWORD]: null,
  [GC.FIELD_ENABLED]: false,
  [GC.FIELD_USERNAME]: null,
  [GC.FIELD_TENANT_ID]: null,
  [GC.FIELD_CLIENT_ID]: null,
  [GC.FIELD_DEFAULT_EMAIL]: null,
  [GC.FIELD_CLIENT_SECRET]: null,
  [GC.FIELD_DISABLE_SSL_CERTIFICATE_VALIDATION]: false,
  [GC.FIELD_SMTP_ENCRYPTION_TYPE]: GC.SMTP_ENCRYPTION_TYPE_NONE,
  [GC.FIELD_INTEGRATION_TYPE]: GC.INTEGRATION_MAIL_CONFIG_TYPE_OUTLOOK,
};

const validationSchema = ({ editMode }: Object) => Yup.lazy(({ integrationType }: Object) => {
  let schema = {
    [GC.FIELD_TENANT_ID]: G.yupStringRequired,
    [GC.FIELD_CLIENT_ID]: G.yupStringRequired,
    [GC.FIELD_CLIENT_SECRET]: G.yupStringRequired,
    [GC.FIELD_INTEGRATION_TYPE]: G.yupStringRequired,
    [GC.FIELD_DEFAULT_EMAIL]: G.yupStringRequired.email(G.getShouldBeEmailLocaleTxt()),
  };

  if (R.equals(integrationType, GC.INTEGRATION_MAIL_CONFIG_TYPE_GMAIL)) {
    schema = R.omit([GC.FIELD_TENANT_ID, GC.FIELD_DEFAULT_EMAIL], schema);
  }

  if (G.isTrue(editMode)) {
    schema = R.dissoc(GC.FIELD_CLIENT_SECRET, schema);
  }

  if (R.equals(integrationType, GC.INTEGRATION_MAIL_CONFIG_TYPE_CUSTOM)) {
    schema = {
      [GC.FIELD_HOST]: G.yupStringRequired,
      [GC.FIELD_PORT]: G.yupStringRequired,
      [GC.FIELD_USERNAME]: G.yupStringRequired,
      [GC.FIELD_PASSWORD]: G.ifElse(R.not(editMode), G.yupStringRequired),
      [GC.FIELD_DEFAULT_EMAIL]: G.yupStringRequired.email(G.getShouldBeEmailLocaleTxt()),
    };
  }

  return Yup.object().shape(schema);
});

const IntegrationMailConfig = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={fields}
      fieldsWrapperStyles={{ pt: 11, flexDirection: 'column' }}
      handlers={{ handleDisableIntegrationType: () => G.isTrue(props.editMode) }}
    />
    <FormFooter />
  </form>
);

export const enhance = compose(
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultValues,
      initialValues,
    ),
  }),
  pure,
);

export default enhance(IntegrationMailConfig);

import { createAction } from 'redux-act';
//////////////////////////////////////////////////

// common
export const getDriverGeneralDetailsRequest = createAction('getDriverGeneralDetailsRequest');
export const updateDriverGeneralDetailsRequest = createAction('updateDriverGeneralDetailsRequest');
// configs
export const getDriverBranchConfigsRequest = createAction('getDriverBranchConfigsRequest');
// safety violation
export const toggleSafetyViolationItem = createAction('toggleSafetyViolationItem');
export const getSafetyViolationListByEntityTypeSuccess = createAction('getSafetyViolationListByEntityTypeSuccess');
export const removeSafetyViolationEntityByEntityTypeRequest =
  createAction('removeSafetyViolationEntityByEntityTypeRequest');
export const removeSafetyViolationEntityByEntityTypeSuccess =
  createAction('removeSafetyViolationEntityByEntityTypeSuccess');
export const createOrUpdateSafetyViolationEntityByEntityTypeSuccess =
  createAction('createOrUpdateSafetyViolationEntityByEntityTypeSuccess');
export const createOrUpdateSafetyViolationEntityByEntityTypeRequest =
  createAction('createOrUpdateSafetyViolationEntityByEntityTypeRequest');
// assignment
export const getDriverAssignmentRequest = createAction('getDriverAssignmentRequest');
export const getDriverAssignmentSuccess = createAction('getDriverAssignmentSuccess');
export const getAvailableAssignableRequest = createAction('getAvailableAssignableRequest');
export const getAvailableAssignableSuccess = createAction('getAvailableAssignableSuccess');
export const assignOrUnassignEntityToDriverRequest = createAction('assignOrUnassignEntityToDriverRequest');
// assign vendor
export const assignVendorToDriverRequest = createAction('assignVendorToDriverRequest');
export const unAssignVendorFromDriverRequest = createAction('unAssignVendorFromDriverRequest');
// monthly payroll deduction
export const getDriverPayrollChargesIsPaidOnPayrollRequest =
  createAction('getDriverPayrollChargesIsPaidOnPayrollRequest');
export const getDriverPayrollChargesIsPaidOnPayrollSuccess =
  createAction('getDriverPayrollChargesIsPaidOnPayrollSuccess');
// payroll accessorials
export const getDriverPayrollAccessorialsIsPaidOnPayrollRequest =
  createAction('getDriverPayrollAccessorialsIsPaidOnPayrollRequest');
export const getDriverPayrollAccessorialsIsPaidOnPayrollSuccess =
  createAction('getDriverPayrollAccessorialsIsPaidOnPayrollSuccess');
// advance payment
export const updateAdvancePaymentStatusRequest = createAction('updateAdvancePaymentStatusRequest');
export const updateAdvancePaymentStatusSuccess = createAction('updateAdvancePaymentStatusSuccess');
// filters
export const setUnavailablePeriodDatesFilter = createAction('setUnavailablePeriodDatesFilter');
export const setUnavailablePeriodReasonFilter = createAction('setUnavailablePeriodReasonFilter');
// expense
export const getDriverExpenseTypesSuccess = createAction('getDriverExpenseTypesSuccess');
export const createOrUpdateDriverExpenseRequest = createAction('createOrUpdateDriverExpenseRequest');
export const deleteDriverExpenseDocumentRequest = createAction('deleteDriverExpenseDocumentRequest');
export const deleteDriverExpenseDocumentSuccess = createAction('deleteDriverExpenseDocumentSuccess');
export const downloadDriverExpenseDocumentRequest = createAction('downloadDriverExpenseDocumentRequest');
export const approveOrDeclineDriverExpenseRequest = createAction('approveOrDeclineDriverExpenseRequest');

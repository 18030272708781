import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../helpers';
// feature driver-card
import { GROUP_TYPE_NONE, GROUP_TYPE_DISPATCHER } from './constants';
//////////////////////////////////////////////////

const selectDriversCardStore = (state: Object) => state.driversCard;

const makeSelectFilters = () => createSelector(
  selectDriversCardStore,
  ({ filters }: Object) => filters,
);

const makeSelectInitialFilterDays = () => createSelector(
  selectDriversCardStore,
  ({ filters }: Object) => filters.initialFilterDays,
);

const makeSelectGroupBy = () => createSelector(
  selectDriversCardStore,
  ({ groupBy }: Object) => groupBy,
);

const makeSelectGlobalFilterValue = () => createSelector(
  selectDriversCardStore,
  ({ globalFilterValue }: Object) => globalFilterValue,
);

const makeSelectLoadRefFilterValue = () => createSelector(
  selectDriversCardStore,
  ({ loadRefFilterValue }: Object) => loadRefFilterValue,
);

const makeSelectUnassignedLoads = () => createSelector(
  selectDriversCardStore,
  ({ unassignedLoads }: Object) => unassignedLoads,
);

const makeSelectNotGroupedAssignedLoads = () => createSelector(
  selectDriversCardStore,
  ({ notGroupedAssignedLoads }: Object) => notGroupedAssignedLoads,
);

const makeSelectNotGroupedAssignedLoadsPagination = () => createSelector(
  selectDriversCardStore,
  ({ notGroupedAssignedLoadsPagination }: Object) => notGroupedAssignedLoadsPagination,
);

const makeSelectShowUnassignedLoads = () => createSelector(
  selectDriversCardStore,
  ({ showUnassignedLoads }: Object) => showUnassignedLoads,
);

const makeSelectTelNotifications = () => createSelector(
  selectDriversCardStore,
  ({ telNotifications }: Object) => telNotifications,
);

const makeSelectOpenedDriverProfile = () => createSelector(
  selectDriversCardStore,
  ({ openedDriverProfile }: Object) => openedDriverProfile,
);

const makeSelectDriverProfileGuid = () => createSelector(
  selectDriversCardStore,
  ({ driverProfileGuid }: Object) => driverProfileGuid,
);

const makeSelectWarningPeriodConfig = () => createSelector(
  selectDriversCardStore,
  ({ warningPeriodConfig }: Object) => warningPeriodConfig,
);

const makeSelectDriverProfile = () => createSelector(
  selectDriversCardStore,
  (state: Object) => {
    const { driverProfiles, driverProfileGuid } = state;

    const driverProfile = R.path([driverProfileGuid], driverProfiles);

    return driverProfile;
  },
);

const makeSelectZoom = () => createSelector(
  selectDriversCardStore,
  ({ zoom }: Object) => zoom,
);

const makeSelectSearchRadiusValue = () => createSelector(
  selectDriversCardStore,
  ({ searchRadiusValue }: Object) => searchRadiusValue,
);

const makeSelectMinEmptyHoursValue = () => createSelector(
  selectDriversCardStore,
  ({ minEmptyHoursValue }: Object) => minEmptyHoursValue,
);

const makeSelectFilteredByPlaceAutocompleteLoads = () => createSelector(
  selectDriversCardStore,
  ({ filteredByPlaceAutocompleteLoads }: Object) => filteredByPlaceAutocompleteLoads,
);

const makeSelectSearchPlaceAutocompleteValue = () => createSelector(
  selectDriversCardStore,
  ({ searchPlaceAutocompleteValue }: Object) => searchPlaceAutocompleteValue,
);

const makeSelectSearchPlaceAutocompleteResult = () => createSelector(
  selectDriversCardStore,
  ({ searchPlaceAutocompleteResult }: Object) => searchPlaceAutocompleteResult,
);

const makeSelectLoadsByDispatchGroup = () => createSelector(
  selectDriversCardStore,
  ({ loadsByDispatchGroup }: Object) => loadsByDispatchGroup,
);

const makeSelectDriverLoadsByDispatcher = () => createSelector(
  selectDriversCardStore,
  ({ driverLoadsByDispatcher }: Object) => driverLoadsByDispatcher,
);

const makeSelectDriverCardsInfo = () => createSelector(
  selectDriversCardStore,
  ({ dispatchByTruck, driverCardsInfo, driverCardsByTruckInfo }: Object) => {
    if (G.isTrue(dispatchByTruck)) return driverCardsByTruckInfo;

    return driverCardsInfo;
  },
);

const makeSelectSearchDate = () => createSelector(
  selectDriversCardStore,
  ({ searchDate }: Object) => searchDate,
);

const makeSelectCardView = () => createSelector(
  selectDriversCardStore,
  ({ cardView }: Object) => cardView,
);

const makeSelectCardHeight = () => createSelector(
  selectDriversCardStore,
  ({ cardHeight }: Object) => cardHeight,
);

const makeSelectDispatchByTruck = () => createSelector(
  selectDriversCardStore,
  ({ dispatchByTruck }: Object) => dispatchByTruck,
);

const makeSelectDriversCount = () => createSelector(
  selectDriversCardStore,
  ({
    groupBy,
    loadsByDispatchGroup,
    driverLoadsByDispatcher,
    filteredByPlaceAutocompleteLoads,
    notGroupedAssignedLoadsPagination,
  }: Object) => {
    if (R.and(G.isNotNilAndNotEmpty(groupBy), G.notEquals(groupBy, GROUP_TYPE_NONE))) {
      const list = G.ifElse(
        R.equals(groupBy, GROUP_TYPE_DISPATCHER),
        driverLoadsByDispatcher,
        loadsByDispatchGroup,
      );

      return R.compose(
        R.sum,
        R.map(R.length),
        R.reject(R.isNil),
        R.values,
      )(list);
    }

    if (G.isNotNilAndNotEmpty(filteredByPlaceAutocompleteLoads)) {
      return R.length(filteredByPlaceAutocompleteLoads);
    }

    return R.pathOr(0, ['totalCount'], notGroupedAssignedLoadsPagination);
  },
);

export {
  makeSelectZoom,
  makeSelectFilters,
  makeSelectGroupBy,
  makeSelectCardView,
  makeSelectCardHeight,
  makeSelectSearchDate,
  makeSelectDriversCount,
  makeSelectDriverProfile,
  makeSelectDriverCardsInfo,
  makeSelectUnassignedLoads,
  makeSelectDispatchByTruck,
  makeSelectTelNotifications,
  makeSelectInitialFilterDays,
  makeSelectDriverProfileGuid,
  makeSelectGlobalFilterValue,
  makeSelectSearchRadiusValue,
  makeSelectLoadRefFilterValue,
  makeSelectMinEmptyHoursValue,
  makeSelectWarningPeriodConfig,
  makeSelectOpenedDriverProfile,
  makeSelectShowUnassignedLoads,
  makeSelectLoadsByDispatchGroup,
  makeSelectDriverLoadsByDispatcher,
  makeSelectNotGroupedAssignedLoads,
  makeSelectSearchPlaceAutocompleteValue,
  makeSelectSearchPlaceAutocompleteResult,
  makeSelectFilteredByPlaceAutocompleteLoads,
  makeSelectNotGroupedAssignedLoadsPagination,
};

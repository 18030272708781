import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 270,
};

export const fieldSettings = (integrationType: string) => [
  {
    type: 'select',
    inputWrapperStyles,
    label: ['titles:integration-type'],
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    customDisabledFunction: 'handleDisableIntegrationType',
    options: [
      {
        label: 'Skybitz',
        value: GC.TRAILER_TRACKING_INTEGRATION_TYPE_SKYBITZ,
      },
      {
        label: 'Power Fleet',
        value: GC.FLEET_INTEGRATION_TYPE_POWER_FLEET,
      },
      {
        label: 'Spireon',
        value: GC.FLEET_INTEGRATION_SPIREON,
      },
    ],
  },
  {
    type: 'toggle',
    inputWrapperStyles,
    label: ['titles:active'],
    fieldName: GC.FIELD_ENABLED,
  },
  {
    type: 'text',
    label: ['titles:client-id'],
    fieldName: GC.FIELD_CLIENT_ID,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: G.ifElse(
        R.equals(integrationType, GC.FLEET_INTEGRATION_TYPE_POWER_FLEET),
        'none',
      ),
    },
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_CLIENT_SECRET,
    label: [G.ifElse(
      R.equals(integrationType, GC.FLEET_INTEGRATION_TYPE_POWER_FLEET),
      'titles:token',
      'titles:client-secret',
    )],
  },
];

const defaultFields = {
  [GC.FIELD_ENABLED]: true,
  [GC.FIELD_CLIENT_ID]: null,
  [GC.FIELD_CLIENT_SECRET]: null,
  [GC.FIELD_INTEGRATION_TYPE]: GC.TRAILER_TRACKING_INTEGRATION_TYPE_SKYBITZ,
};

const validationSchema = ({ editMode }: Object) => Yup.lazy(({ integrationType }: string) => Yup.object().shape({
  [GC.FIELD_CLIENT_ID]: G.ifElse(
    G.notEquals(integrationType, GC.FLEET_INTEGRATION_TYPE_POWER_FLEET),
    G.yupStringRequired,
  ),
  [GC.FIELD_CLIENT_SECRET]: G.ifElse(
    G.isTrue(editMode),
    G.yupStringNotRequired,
    G.yupStringRequired,
  ),
}));

const enhance = compose(
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
  }),
  pure,
);

const TrailerTrackingIntegrationForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...props.optionsForSelect}
      {...G.getFormikProps(props)}
      fieldsWrapperStyles={{ pt: 11 }}
      fields={fieldSettings(R.path(['values', GC.FIELD_INTEGRATION_TYPE], props))}
      handlers={{
        handleDisableIntegrationType: () => props.editMode,
      }}
    />
    <FormFooter />
  </form>
);

export default enhance(TrailerTrackingIntegrationForm);

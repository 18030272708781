import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withState, withHandlers, withPropsOnChange } from 'react-recompose';
// common
import { makeSelectDocumentTemplates } from '../../../common/selectors';
// component
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { withDispatchActions } from '../../rate/hocs';
import ItemForm from '../../item/components/item-form';
import StopForm from '../../new-do/outside-use/stop-form';
import DropItemsForm from '../../dispatch-details-new/forms/drop-items-form';
import ContainerForm from '../../dispatch-planner/components/container-form';
import { withAddTrailer } from '../../dispatch-planner/hocs/with-add-trailer';
import { setExpandedContainerOptions } from '../../expanded-container/actions';
import { withPlannerRates } from '../../dispatch-planner/hocs/with-planner-rates';
// forms
import SelectTerminal from '../../../forms/forms/select-terminal';
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncInitialDataOnDidMount } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature dispatch-planner-events
import * as H from '../helpers';
import Confirm from './confirm';
import LoadMap from './load-map';
import LoadInner from './load-inner';
import LoadHeader from './load-header';
import { CLO_NAME } from '../constants';
import AddCloStops from './add-clo-stops';
import SelectClosItems from './select-clos-items';
import withLoadDocuments from '../hocs/with-load-documents';
import CrossDockLocationForm from './cross-dock-location-form';
import { ApplyRouteTemplateForm } from './apply-route-template-form';
import {
  makeSelectEditedClos,
  makeSelectItemsForDrop,
  makeSelectCurrentRoute,
  makeSelectCloEventList,
  makeSelectItemsForPickup,
  makeSelectRouteTemplates,
  makeSelectDefaultUomFields,
  makeSelectOptionsForSelect,
  makeSelectCurrentRouteItems,
  makeSelectCurrentBranchConfigs,
  makeSelectCrossDockLocationList,
  makeSelectExistedTerminalOptions,
  makeSelectCurrentRouteContainers,
  makeSelectCurrentRouteInitialState,
} from '../selectors';
import {
  editItem,
  deleteTel,
  cleanTelRate,
  sortTelEvents,
  addNewItemOnUI,
  addItemsForDrop,
  updateContainer,
  addItemsForPickup,
  addTrailersToStop,
  removeItemFromStop,
  addItemsClosForDrop,
  addExchangeTerminal,
  removeTelFromPlanner,
  removeTrailerFromStop,
  removeCloItemFromStop,
  addItemsClosForPickup,
  addRouteTemplateStops,
  addNewContainerToStop,
  dispatchTelRateRequest,
  addExistedTerminalToTel,
  removeContainerFromStop,
  addDropContainersToStop,
  uncheckAndRemoveCloEvent,
  addPickupContainersToStop,
  addStopsToExchangeTerminal,
  addNewStopToRouteBuilderTel,
  dispatchCarrierTelRateRequest,
  removeStopFromRouteBuilderTel,
  recalculateTelDistancesRequest,
  addNewCloStopToRouteBuilderTel,
  updateCloStopsOnPlannerRequest,
  getCloTelsAndAddToPlannerRequest,
  updateStopOnRouteBuilderTelRequest,
} from '../actions';
//////////////////////////////////////////////////

const ItemsClosForDrop = (props: Object) => {
  const itemOptions = R.compose(
    R.map(({ itemId, itemInternalId }: Object) => ({
      label: itemId,
      value: itemInternalId,
    })),
    H.getTelItemsForDrop,
  )(props);
  const cloOptions = R.compose(
    R.map((cloId: Array) => ({
      value: cloId,
      label: R.pathOr('Unknown CLO', ['clos', cloId, 0, CLO_NAME], props),
    })),
    R.keys,
  )(props.clos);

  return (
    <SelectClosItems
      {...props}
      closObj={props.clos}
      options={itemOptions}
      cloOptions={cloOptions}
      itemOptions={itemOptions}
      eventGuid={R.path(['stop', GC.FIELD_GUID, props])}
      handleActionLoadItem={(data: Object) => props.handleAddItemsClosForDrop(data, props.stop)}
    />
  );
};

const ItemsForPickup = (props: Object) => {
  const options = R.compose(
    R.map(({ itemId, itemInternalId }: Object) => ({
      label: itemId,
      value: itemInternalId,
    })),
  )(props.items);

  return (
    <Box>
      <Flex mb={10}>
        <Box mr='8px'>
          {G.getWindowLocale('titles:add-item-msg', 'Add new item to this stop')}
        </Box>
        <Box
          cursor='pointer'
          onClick={() => {
            props.closeModal();
            props.handleCreateNewItem(props.stop);
          }}
        >
          {I.plusRound(G.getTheme('colors.light.darkRed'))}
        </Box>
      </Flex>
      <DropItemsForm
        {...props}
        options={options}
        defaultItems={[]}
        eventGuid={R.path(['stop', 'guid'], props)}
        handleActionLoadItem={(data: Object) => props.handleAddItemsForPickup(data, props.stop)}
      />
    </Box>
  );
};

const ItemsClosForPickup = (props: Object) => {
  const itemOptions = R.compose(
    R.map((item: Object) => ({
      label: item.itemId,
      value: item.itemInternalId,
    })),
  )(props.items);
  const cloOptions = R.compose(
    R.map((cloId: Array) => ({
      value: cloId,
      label: R.pathOr('Unknown CLO', ['clos', cloId, 0, CLO_NAME], props),
    })),
    R.keys,
  )(props.clos);

  return (
    <Box>
      <Flex mb={10}>
        <Box mr='8px'>
          {G.getWindowLocale('titles:add-item-msg', 'Add new item to this stop')}
        </Box>
        <Box
          cursor='pointer'
          onClick={() => {
            props.closeModal();
            props.handleCreateNewItem(props.stop);
          }}
        >
          {I.plusRound(G.getTheme('colors.light.darkRed'))}
        </Box>
      </Flex>
      <SelectClosItems
        {...props}
        closObj={props.clos}
        options={itemOptions}
        cloOptions={cloOptions}
        itemOptions={itemOptions}
        eventGuid={R.path(['stop', 'guid'], props)}
        handleActionLoadItem={(data: Object) => props.handleAddItemsClosForPickup(data, props.stop)}
      />
    </Box>
  );
};

const ItemsForDrop = (props: Object) => {
  const options = R.compose(
    R.map((item: Object) => ({
      label: item.itemId,
      value: item.itemInternalId,
    })),
    H.getTelItemsForDrop,
  )(props);

  return (
    <DropItemsForm
      {...props}
      defaultItems={[]}
      options={options}
      eventGuid={R.path(['stop', GC.FIELD_GUID], props)}
      handleActionLoadItem={(data: Object) => props.handleAddItemsForDrop(data, props.stop)}
    />
  );
};

const CloItemsForDrop = (props: Object) => {
  const options = R.compose(
    R.map((item: Object) => ({
      label: R.prop(GC.FIELD_ITEM_ID, item),
      value: R.prop(GC.FIELD_ITEM_INTERNAL_ID, item),
    })),
    H.getCloItemsForDrop,
  )(props);

  return (
    <DropItemsForm
      {...props}
      defaultItems={[]}
      options={options}
      eventGuid={R.path(['stop', GC.FIELD_GUID], props)}
      handleActionLoadItem={(data: Object) => props.handleAddItemsForDrop(data, props.stop)}
    />
  );
};

const getBranchGuid = (props: Object) => {
  let branchGuid = R.path([GC.FIELD_TEL, GC.FIELD_BRANCH_GUID], props);

  if (G.isNilOrEmpty(branchGuid)) {
    branchGuid = R.pathOr(G.getAmousCurrentBranchGuidFromWindow(), ['currentRoute', GC.FIELD_BRANCH_GUID], props);
  }

  return branchGuid;
};

const enhance = compose(
  withLoadDocuments,
  withDispatchActions,
  withPlannerRates({ page: GC.PAGE_DISPATCH_PLANNER_EVENTS }),
  withState('telVisibility', 'setTelVisibility', null),
  withHandlers({
    handleAddNewItemOnUI: (props: Object) => (data: Object, stop: Object) => (
      props.addNewItemOnUI({
        stop,
        data,
        eventGuid: stop.guid,
        telGuid: stop.telGuid,
      })
    ),
    handleAddItemsForPickup: (props: Object) => ({ items }: Object, stop: Object) => (
      props.addItemsForPickup({
        items,
        eventGuid: stop.guid,
        telGuid: stop.telGuid,
      })
    ),
    handleAddItemsClosForPickup: (props: Object) => ({ items, loadItems }: Object, stop: Object) => (
      props.addItemsClosForPickup({
        stop,
        items,
        loadItems: R.concat(stop.items, loadItems),
      })
    ),
  }),
  withHandlers({
    handleAddItemsForDrop: (props: Object) => ({ items }: Object, stop: Object) => (
      props.addItemsForDrop({
        items,
        eventGuid: stop.guid,
        telGuid: stop.telGuid,
      })
    ),
    handleAddItemsClosForDrop: (props: Object) => ({ items, loadItems }: Object, stop: Object) => (
      props.addItemsClosForDrop({
        stop,
        items,
        loadItems: R.concat(stop.items, loadItems),
      })
    ),
    handleCreateNewItem: (props: Object) => (stop: Object) => {
      const { tel, openModal, closeModal, handleAddNewItemOnUI } = props;

      const componentProps = {
        openModal,
        closeModal,
        withItemTemplateSearch: true,
        branchGuid: getBranchGuid(props),
        submitAction: (values: Object) => {
          const { guid, loadType } = tel;

          const data = R.mergeRight(
            values,
            { loadType, loadGuid: guid, eventGuid: G.getGuidFromObject(stop) },
          );

          handleAddNewItemOnUI(data, stop);

          closeModal();
        },
      };

      const component = <ItemForm {...componentProps} />;

      const modal = {
        p: '0px',
        component,
        options: {
          width: 'auto',
          height: 'auto',
          title: G.getAddTitle(['titles:item', 'Item']),
        },
      };

      openModal(modal);
    },
    handleAddCloStop: (props: Object) => (cloGuid: string) => {
      const {
        tel,
        openModal,
        closeModal,
        editedClos,
        cloEventList,
        branchConfigs,
        updateCloStopsOnPlannerRequest,
        getCloTelsAndAddToPlannerRequest,
      } = props;

      getCloTelsAndAddToPlannerRequest(cloGuid);

      let cloEvents = R.path([cloGuid], editedClos);

      const matchEvent = R.find(
        (event: Object) => R.and(
          G.isNotNilAndNotEmpty(R.prop('details', event)),
          R.propEq(cloGuid, GC.FIELD_CLO_GUID, event),
        ),
        cloEventList,
      );

      const cloName = R.prop(GC.GRC.CLO_PRIMARY_REFERENCE_VALUE, matchEvent);

      if (G.isNilOrEmpty(cloEvents)) {
        cloEvents = R.propOr([], 'details', matchEvent);
      }

      const items = R.compose(
        R.indexBy(R.prop(GC.FIELD_GUID)),
        R.reduce((acc: Array, event: Object) => R.concat(acc, event.items), []),
        R.values,
      )(cloEvents);

      const load = {
        [GC.FIELD_LOAD_STOPS]: cloEvents,
      };

      const component = (
        <AddCloStops
          load={load}
          items={items}
          cloGuid={cloGuid}
          cloName={cloName}
          openModal={openModal}
          closeModal={closeModal}
          eventList={cloEventList}
          branchConfigs={branchConfigs}
          fromPage={GC.PAGE_DISPATCH_PLANNER_EVENTS}
          branchGuid={R.prop(GC.GRC.CLO_BRANCH_GUID, matchEvent)}
          saveStops={(stopsData: Object) => (
            updateCloStopsOnPlannerRequest({
              cloName,
              cloGuid,
              stopsData,
              telGuid: R.prop(GC.FIELD_GUID, tel),
            })
          )}
        />
      );
      const modal = {
        p: '0',
        component,
        options: {
          maxWidth: 700,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
          width: 'fit-content',
          title: G.getWindowLocale('titles:edit-clo', 'Edit CLO'),
        },
      };
      openModal(modal);
    },
    handleSelectEventsForExchangePoint: (props: Object) => ({ tel, split, cloEvents, templateId }: Object) => {
      const { openModal, closeModal, addStopsToExchangeTerminal } = props;

      closeModal();
      const stopOptions = G.createStopOptions(cloEvents, true);
      const component = (
        <SelectDropdownForm
          isMulti={true}
          options={stopOptions}
          cancelAction={closeModal}
          initialValues={{ option: R.map(G.getGuidFromObject, cloEvents) }}
          fieldLabel={G.getWindowLocale('titles:select-stops', 'Select Stops')}
          submitAction={(values: Array) => {
            const events = R.map((guid: string) => R.find(R.propEq(guid, GC.FIELD_GUID), cloEvents), values);
            addStopsToExchangeTerminal({ tel, split, events, templateId });
            closeModal();
          }}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          width: 'fit-content',
        },
      };
      openModal(modal);
    },
  }),
  withHandlers({
    handleToggleFullScreenView: ({ toggleFullScreenView }: Object) => () => (
      toggleFullScreenView()
    ),
    handleClickDeleteLoad: ({ tel, deleteTel }: Object) => () => (
      deleteTel(G.getGuidFromObject(tel))
    ),
    handleClickUnassignTel: ({ tel, removeTelFromPlanner }: Object) => () => (
      removeTelFromPlanner(G.getGuidFromObject(tel))
    ),
    handleClickLoadMap: (props: Object) => () => {
      const { tel, closeModal } = props;

      const component = <LoadMap {...tel} close={closeModal} />;
      const modal = {
        p: '0px',
        component,
        options: {
          height: 'auto',
          width: '100vh',
          maxWidth: '100%',
          overflow: 'auto',
        },
      };
      props.openModal(modal);
    },
    handleRecalculateLoadDistances: ({ tel, recalculateTelDistancesRequest }: Object) => () => (
      recalculateTelDistancesRequest(G.getGuidFromObject(tel))
    ),
    handleClickAddNewStop: (props: Object) => (options: Object) => {
      const { tel, openModal, closeModal } = props;
      const { branchGuid, eventType, loadGuid, loadType } = options;

      const order = R.compose(
        R.inc,
        R.length,
        R.values,
        R.path([GC.FIELD_LOAD_STOPS]),
      )(tel);
      const initialValues = {
        eventType,
        [GC.FIELD_LOAD_FCFS]: true,
        [GC.FIELD_TEL_EVENT_INDEX]: order,
      };

      const handleSubmit = (location: Object) => {
        closeModal();
        props.addNewStopToRouteBuilderTel({
          location,
          loadGuid,
          loadType,
          eventType,
        });
      };
      const modalContent = (
        <StopForm
          {...props}
          branchGuid={branchGuid}
          withoutReferences={true}
          submitHandler={handleSubmit}
          initialValues={initialValues}
          fromPage={GC.PAGE_DISPATCH_PLANNER_EVENTS}
          configsNamesArray={[GC.TEMPLATES_LOCATION_TYPE]}
        />
      );
      const modal = {
        p: '0px',
        component: modalContent,
        options: {
          title: '',
          width: 700,
          overflow: 'auto',
          maxHeight: '80vh',
        },
      };
      openModal(modal);
    },
    handleClickEditStop: (props: Object) => (event: Object) => {
      const { tel, openModal, closeModal, updateStopOnRouteBuilderTelRequest } = props;

      const initialValues = R.mergeRight(
        event,
        G.resetLocationTypeFromDropdownOption(G.getPropFromObject(GC.FIELD_LOCATION, event)),
      );

      const telGuid = G.getGuidFromObject(tel);

      const handleSubmit = (location: Object) => {
        closeModal();

        updateStopOnRouteBuilderTelRequest({
          telGuid,
          stop: {
            location,
            loadGuid: telGuid,
            loadType: GC.LOAD_TYPE_TEL,
            eventGuid: G.getGuidFromObject(event),
            [GC.FIELD_STOP_TYPE]: R.prop(GC.FIELD_STOP_TYPE, event),
          },
        });
      };

      const modalContent = (
        <StopForm
          {...props}
          withoutReferences={true}
          submitHandler={handleSubmit}
          initialValues={initialValues}
          fromPage={GC.PAGE_DISPATCH_PLANNER_EVENTS}
          branchGuid={R.prop(GC.FIELD_BRANCH_GUID, tel)}
          configsNamesArray={[GC.TEMPLATES_LOCATION_TYPE]}
        />
      );

      const modal = {
        p: '0px',
        component: modalContent,
        options: {
          title: '',
          width: 700,
          overflow: 'auto',
          maxHeight: '80vh',
        },
      };

      openModal(modal);
    },
    handleClickRemoveStop: (props: Object) => (stop: Object) => {
      props.removeStopFromRouteBuilderTel(stop);
      props.sortTelEvents(props.tel.guid);
      props.recalculateTelDistancesRequest(props.tel.guid);
    },
    handleClickAddTerminal: (props: Object) => () => {
      const options = H.getAvailableExistedTerminalOptions(
        props.existedTerminalOptions,
        R.path(['tel', GC.FIELD_LOAD_STOPS], props),
      );
      const component = (
        <SelectTerminal
          options={options}
          closeModal={props.closeModal}
          handleSubmitAction={(data: Object) => props.addExistedTerminalToTel(R.assoc(
            'telGuid', R.path(['tel', GC.FIELD_GUID], props), data,
          ))}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 'min-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:choose-terminal-and-type', 'Please, choose Terminal and Stop Type'),
        },
      };
      props.openModal(modal);
    },
    handleClickAddItems: (props: Object) => (stop: Object) => {
      const { tel, openModal, currentRoute, itemsForPickup, handleCreateNewItem } = props;

      const { stopType, eventType, location } = stop;

      let component;
      let options;

      const isCloStop = G.isLoadTypeClo(stop);
      const isPickup = G.isEventTypePickup(eventType);
      const isTerminal = G.isStopTypeTerminal(stopType);

      if (R.and(R.not(isPickup), isCloStop)) {
        component = <CloItemsForDrop stop={stop} {...props} />;

        options = {
          title: G.getWindowLocale('titles:select-items-for-drop', 'Select Items For Drop'),
        };
      } else if (isPickup) {
        let availableClosForPickup = null;
        const availableItems = H.getAvailableItemsForPickup(stop, itemsForPickup);

        if (isTerminal) {
          availableClosForPickup = H.getAvailableTerminalPickupClos(
            tel,
            R.values(R.path([GC.SYSTEM_LIST_TELS], currentRoute)),
            R.path([GC.FIELD_TEMPLATE_ID], location),
          );
        }

        if (R.and(G.isNilOrEmpty(availableItems), G.isNilOrEmpty(availableClosForPickup))) {
          return handleCreateNewItem(stop);
        }

        if (G.isNilOrEmpty(availableClosForPickup)) {
          component = <ItemsForPickup {...props} stop={stop} items={availableItems} />;
        } else {
          component = (
            <ItemsClosForPickup
              {...props}
              stop={stop}
              items={availableItems}
              clos={availableClosForPickup}
            />
          );
        }

        options = {
          height: 'auto',
          maxHeight: '80vh',
          title: G.getWindowLocale('titles:select-items-for-pickup', 'Select Items For Pickup'),
        };
      } else {
        let availableClosForDrop = null;

        if (isTerminal) {
          availableClosForDrop = H.getAvailableDropClosWithItems(tel);
        }

        if (G.isNilOrEmpty(availableClosForDrop)) {
          component = <ItemsForDrop stop={stop} {...props} />;
        } else {
          component = (
            <ItemsClosForDrop
              {...props}
              stop={stop}
              clos={availableClosForDrop}
            />
          );
        }

        options = {
          title: G.getWindowLocale('titles:select-items-for-drop', 'Select Items For Drop'),
        };
      }

      const modal = {
        p: 15,
        options,
        component,
      };

      openModal(modal);
    },
    handleRemoveItem: ({ removeItemFromStop }: Object) => (stop: Object, id: Object) => (
      removeItemFromStop({ stop, id })
    ),
    handleRemoveCloItem: ({ tel, removeCloItemFromStop }: Object) => (stopGuid: Object, cloGuid: string) => (
      removeCloItemFromStop({ stopGuid, cloGuid, telGuid: tel.guid })
    ),
    handleEditItem: (props: Object) => (stop: Object, id: Object) => {
      const { items, editItem, openModal, closeModal } = props;

      const item = G.getPropFromObject(id, items);

      const componentProps = {
        openModal,
        closeModal,
        initialValues: item,
        submitAction: (data: Object) => {
          editItem(R.assoc(CLO_NAME, G.getPropFromObject(CLO_NAME, item), data));

          closeModal();
        },
      };

      const component = <ItemForm {...componentProps} />;

      const modal = {
        p: '0px',
        component,
        options: {
          width: 'auto',
          height: 'auto',
          title: G.getEditTitle(['titles:item', 'Item']),
        },
      };

      openModal(modal);
    },
    handleEditContainer: (props: Object) => (stop: Object, id: string) => {
      const { tel, openModal, closeModal, containers, containerTypes, updateContainer } = props;

      const initialContainer = R.pathOr({}, [id], containers);

      const component = (
        <ContainerForm
          openModal={openModal}
          closeModal={closeModal}
          containerTypes={containerTypes}
          initialContainer={initialContainer}
          branchGuid={R.prop(GC.FIELD_BRANCH_GUID, tel)}
          submitAction={({ pickedUpContainers }: Object) => {
            const data = R.compose(
              R.assoc(GC.FIELD_CONTAINER_INTERNAL_ID, id),
              R.mergeRight(initialContainer),
              R.head,
            )(pickedUpContainers);

            updateContainer(data);
            closeModal();
          }}
        />
      );

      const modal = {
        p: '10px',
        component,
        options: {
          width: 615,
          title: G.getWindowLocale('titles:edit-container', 'Edit Container'),
        },
      };

      openModal(modal);
    },
    handleClickAddDropContainer: (props: Object) => (stop: Object) => {
      const { tel, openModal, closeModal, currentRoute, addDropContainersToStop } = props;

      const isCloStop = G.isLoadTypeClo(stop);

      let containerOptions;
      let availableContainers;

      if (isCloStop) {
        availableContainers = H.getAvailableContainersForCloDrop(
          currentRoute.tels,
          R.prop(GC.FIELD_CLO_GUID, stop),
        );

        containerOptions = R.compose(
          R.map((container: Object) => ({
            value: R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, container),
            label: `${H.getContainerInfo(container)} (${R.prop(CLO_NAME, container)})`,
          })),
          R.filter(R.propEq(R.prop(GC.FIELD_CLO_GUID, stop), GC.FIELD_LOAD_GUID)),
        )(availableContainers);
      } else {
        availableContainers = H.getAvailableContainersForDrop(tel);

        containerOptions = R.map((container: Object) => ({
          value: R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, container),
          label: `${H.getContainerInfo(container)} (${R.prop(CLO_NAME, container)})`,
        }), availableContainers);
      }

      const component = (
        <SelectDropdownForm
          isMulti={true}
          cancelAction={closeModal}
          options={containerOptions}
          fieldLabel={G.getWindowLocale('titles:select-containers', 'Select Containers')}
          submitAction={(value: Array) => {
            const droppedContainers = R.compose(
              R.values,
              R.pick(value),
              R.indexBy(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID)),
            )(availableContainers);

            addDropContainersToStop({
              droppedContainers,
              telGuid: G.getGuidFromObject(tel),
              eventGuid: G.getGuidFromObject(stop),
              droppedContainerIds: R.map(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID), droppedContainers),
            });

            closeModal();
          }}
        />
      );

      const modal = { p: 15, component, options: {} };

      openModal(modal);
    },
    handleClickAddPickupContainer: (props: Object) => (stop: Object) => {
      const {
        tel,
        openModal,
        closeModal,
        currentRoute,
        containerTypes,
        addNewContainerToStop,
        addPickupContainersToStop,
      } = props;

      const isCloStop = G.isLoadTypeClo(stop);

      let component;
      let options = {};

      if (isCloStop) {
        component = (
          <ContainerForm
            openModal={openModal}
            closeModal={closeModal}
            containerTypes={containerTypes}
            branchGuid={R.prop(GC.FIELD_BRANCH_GUID, tel)}
            submitAction={(values: Object) => {
              const containerData = {
                ...R.pick([CLO_NAME, GC.FIELD_CLO_GUID, GC.FIELD_LOAD_TYPE], stop),
                ...R.head(R.prop(GC.FIELD_STOP_PICKED_UP_CONTAINERS, values)),
              };

              addNewContainerToStop({
                telGuid: G.getGuidFromObject(tel),
                eventGuid: G.getGuidFromObject(stop),
                container: H.createContainerFromFormValuesAndStop(
                  containerData,
                  stop,
                ),
              });

              closeModal();
            }}
          />
        );

        options = {
          width: 615,
          title: G.getWindowLocale('titles:add-container', 'Add Container'),
        };
      } else {
        const availableContainers = H.getAvailableContainersForPickup(stop, currentRoute);

        const containerOptions = R.map((container: Object) => ({
          value: R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, container),
          label: `${H.getContainerInfo(container)} (${R.prop(CLO_NAME, container)})`,
        }), availableContainers);

        component = (
          <SelectDropdownForm
            isMulti={true}
            cancelAction={closeModal}
            options={containerOptions}
            fieldLabel={G.getWindowLocale('titles:select-containers', 'Select Containers')}
            submitAction={(value: Array) => {
              const pickedUpContainers = R.compose(
                R.values,
                R.pick(value),
                R.indexBy(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID)),
              )(availableContainers);

              addPickupContainersToStop({
                pickedUpContainers,
                telGuid: G.getGuidFromObject(tel),
                eventGuid: G.getGuidFromObject(stop),
                pickedUpContainerIds: R.map(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID), pickedUpContainers),
              });
              closeModal();
            }}
          />
        );
      }

      const modal = { p: 15, options, component };

      openModal(modal);
    },
    handleAddExchangeTerminal: (props: Object) => (split: boolean=false) => {
      const {
        tel,
        openModal,
        closeModal,
        crossDocks,
        currentRoute,
        addStopsToExchangeTerminal,
        handleSelectEventsForExchangePoint,
      } = props;

      if (G.isNilOrEmpty(R.path([GC.SYSTEM_LIST_TELS], currentRoute))) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('titles:create-tel-first', 'You need to create a TEL first'),
        );
      }

      const options = G.addEmptyOptionToDropDown(G.getLocationOptions(R.values(crossDocks)));
      const cloEvents = R.compose(
        R.filter((stop: Object) => G.isStopDrop(stop)),
        R.values,
        R.prop(GC.FIELD_LOAD_STOPS),
      )(tel);
      let submit;

      if (R.equals(R.length(cloEvents), 0)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale(
            'messages:add-order-drops-to-trip',
            'Please, add an order drop or terminal drop to add an exchange point',
          ),
        );
      }

      if (R.gt(R.length(cloEvents), 1)) {
        submit = (templateId: string) => handleSelectEventsForExchangePoint({ tel, split, cloEvents, templateId });
      } else {
        submit = (templateId: string) => addStopsToExchangeTerminal({ tel, split, templateId, events: cloEvents });
      }

      const component = (
        <CrossDockLocationForm
          singleSelect={true}
          setLocations={submit}
          closeModal={closeModal}
          locationOptions={options}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          width: 'fit-content',
          title: G.getWindowLocale('titles:select-location', 'Select Locations'),
        },
      };
      openModal(modal);
    },
    handleApplyRouteTemplate: (props: Object) => () => {
      const { tel, openModal, routeTemplates, addRouteTemplateStops } = props;

      const routeTemplateOptions = H.getRouteTemplateOptions(R.values(routeTemplates));

      const component = (
        <ApplyRouteTemplateForm
          tel={tel}
          submitAction={addRouteTemplateStops}
          routeTemplates={routeTemplateOptions}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 550,
          title: G.getWindowLocale('titles:apply-route-template', 'Apply Route Template'),
        },
      };

      openModal(modal);
    },
    handleSelectCloToAddStops: (props: Object) => () => {
      const { tel, openModal, closeModal, handleAddCloStop } = props;

      const { cloPrimaryReferenceValues } = tel;

      const cloOptions = R.values(R.mapObjIndexed(
        (label: string, value: string) => ({ label, value }),
        cloPrimaryReferenceValues,
      ));

      if (R.equals(R.length(cloOptions), 1)) {
        return handleAddCloStop(R.path([0, 'value'], cloOptions));
      }

      const component = (
        <SelectDropdownForm
          options={cloOptions}
          cancelAction={closeModal}
          fieldLabel={G.getWindowLocale('titles:select-clo', 'Select CLO')}
          submitAction={(value: string) => {
            closeModal();
            handleAddCloStop(value);
          }}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          width: 'fit-content',
        },
      };
      openModal(modal);
    },
    handleRemoveCloStopFromPlanner: (props: Object) => (event: Object) => {
      const {
        openModal,
        closeModal,
        uncheckAndRemoveCloEvent,
      } = props;

      const title = G.getWindowLocale('messages:remove-stop', 'Are you sure you want to remove stop?');

      const component = (
        <Confirm
          submitData={event}
          closeModal={closeModal}
          handleSubmitAction={uncheckAndRemoveCloEvent}
          cancelText={G.getWindowLocale('actions:cancel', 'Cancel')}
          actionText={G.getWindowLocale('actions:confirm', 'Confirm')}
          text={G.getWindowLocale(
            'titles:all-clo-events-will-be-removed',
            'All Order events will be removed from route',
          )}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title,
          width: 'min-content',
          height: 'max-content',
        },
      };
      openModal(modal);
    },
    handleOpenDetails: (props: Object) => () => {
      const { tel, setExpandedContainerOptions } = props;

      const { isNew } = tel;

      if (isNew) return;

      const guid = G.getGuidFromObject(tel);

      setExpandedContainerOptions({
        opened: true,
        options: { guid },
        visitPageGuid: guid,
        componentType: GC.PAGE_DISPATCH_DETAILS_NEW_LOAD,
      });
    },
    handleOpenCloDetails: ({ setExpandedContainerOptions }: Object) => (guid: string) => setExpandedContainerOptions({
      opened: true,
      options: { guid },
      visitPageGuid: guid,
      componentType: GC.PAGE_DISPATCH_DETAILS_NEW_ORDER,
    }),
  }),
  withAddTrailer,
  withPropsOnChange(
    ['mode'],
    ({ setTelVisibility }: Object) => setTelVisibility(null),
  ),
  pure,
);

const Load = enhance((props: Object) => {
  const {
    tel,
    mode,
    items,
    withError,
    containers,
    routeChanged,
    currentRoute,
    cleanTelRate,
    telVisibility,
    sortTelEvents,
    itemsExpanded,
    handleEditItem,
    handleRemoveItem,
    setTelVisibility,
    handleOpenDetails,
    handleAddDocument,
    handleClickLoadMap,
    handleRemoveCloItem,
    handleClickAddItems,
    handleClickEditStop,
    handleEditContainer,
    handleOpenCloDetails,
    handleClickAddTrailer,
    handleClickDeleteLoad,
    removeTrailerFromStop,
    handleClickRemoveStop,
    handleDispatchTelRate,
    handleClickAddNewStop,
    handleClickUnassignTel,
    handleClickAddTerminal,
    handleAddTelDriverRate,
    handleAddTelCarrierRate,
    removeContainerFromStop,
    currentRouteInitialState,
    handleApplyRouteTemplate,
    handleAddExchangeTerminal,
    handleSelectCloToAddStops,
    handleUpdateTelDriverRate,
    handleUpdateTelCarrierRate,
    handleCancelDispatchTelRate,
    handleClickAddDropContainer,
    handlePrintCreateLoadDocument,
    handleClickAddPickupContainer,
    handleRecalculateLoadDistances,
    handleRemoveCloStopFromPlanner,
  } = props;

  const isExpanded = H.checkTelExpandedOnPlanner(
    R.values(R.path([GC.FIELD_TEL, GC.FIELD_LOAD_STOPS], props)),
    mode,
    telVisibility,
  );

  return (
    <Box
      minWidth={600}
      maxHeight='fit-content'
      border={G.ifElse(G.isFalse(withError), 'unset', '1px solid')}
      borderColor={G.ifElse(G.isFalse(withError), 'unset', G.getTheme('colors.light.mainRed'))}
    >
      <LoadHeader
        tel={tel}
        error={withError}
        loadGuid={tel.guid}
        loadName={tel.loadName}
        isExpanded={isExpanded}
        cleanTelRate={cleanTelRate}
        routeChanged={routeChanged}
        currentRoute={currentRoute}
        sortTelEvents={sortTelEvents}
        setTelVisibility={setTelVisibility}
        handleOpenDetails={handleOpenDetails}
        handleAddDocument={handleAddDocument}
        branchGuid={tel[GC.FIELD_BRANCH_GUID]}
        handleDispatch={handleDispatchTelRate}
        handleClickLoadMap={handleClickLoadMap}
        loadTotal={H.getTelTotalInfo(tel, items)}
        handleAddCloStop={handleSelectCloToAddStops}
        handleClickDeleteLoad={handleClickDeleteLoad}
        handleClickAddNewStop={handleClickAddNewStop}
        handleClickUnassignTel={handleClickUnassignTel}
        handleClickAddTerminal={handleClickAddTerminal}
        handleAddTelDriverRate={handleAddTelDriverRate}
        handleAddTelCarrierRate={handleAddTelCarrierRate}
        handleCancelDispatch={handleCancelDispatchTelRate}
        currentRouteInitialState={currentRouteInitialState}
        handleApplyRouteTemplate={handleApplyRouteTemplate}
        handleUpdateTelDriverRate={handleUpdateTelDriverRate}
        handleAddExchangeTerminal={handleAddExchangeTerminal}
        handleUpdateTelCarrierRate={handleUpdateTelCarrierRate}
        handlePrintCreateLoadDocument={handlePrintCreateLoadDocument}
        handleRecalculateLoadDistances={handleRecalculateLoadDistances}
      />
      {
        isExpanded &&
        <Droppable droppableId={tel.guid} direction='horizontal'>
          {(provided: Object, snapshot: Object) => (
            <Flex ref={provided.innerRef}>
              <LoadInner
                tel={tel}
                items={items}
                noDimmer={true}
                error={withError}
                snapshot={snapshot}
                showComponent={true}
                containers={containers}
                clos={currentRoute.clos}
                itemsExpanded={itemsExpanded}
                handleEditItem={handleEditItem}
                handleRemoveItem={handleRemoveItem}
                handleClickEditStop={handleClickEditStop}
                handleClickAddItems={handleClickAddItems}
                handleRemoveCloItem={handleRemoveCloItem}
                handleEditContainer={handleEditContainer}
                handleOpenCloDetails={handleOpenCloDetails}
                handleClickRemoveStop={handleClickRemoveStop}
                handleClickAddTrailer={handleClickAddTrailer}
                removeTrailerFromStop={removeTrailerFromStop}
                removeContainerFromStop={removeContainerFromStop}
                handleClickAddDropContainer={handleClickAddDropContainer}
                handleClickAddPickupContainer={handleClickAddPickupContainer}
                handleRemoveCloStopFromPlanner={handleRemoveCloStopFromPlanner}
              />
              {provided.placeholder}
            </Flex>
          )}
        </Droppable>
      }
    </Box>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  editedClos: makeSelectEditedClos(state),
  items: makeSelectCurrentRouteItems(state),
  currentRoute: makeSelectCurrentRoute(state),
  itemsForDrop: makeSelectItemsForDrop(state),
  cloEventList: makeSelectCloEventList(state),
  routeTemplates: makeSelectRouteTemplates(state),
  itemsForPickup: makeSelectItemsForPickup(state),
  crossDocks: makeSelectCrossDockLocationList(state),
  optionsForSelect: makeSelectOptionsForSelect(state),
  defaultUomFields: makeSelectDefaultUomFields(state),
  containers: makeSelectCurrentRouteContainers(state),
  branchConfigs: makeSelectCurrentBranchConfigs(state),
  documentTemplates: makeSelectDocumentTemplates(state),
  existedTerminalOptions: makeSelectExistedTerminalOptions(state),
  currentRouteInitialState: makeSelectCurrentRouteInitialState(state),
});

const actions = {
  editItem,
  deleteTel,
  openModal,
  openLoader,
  closeModal,
  closeLoader,
  cleanTelRate,
  sortTelEvents,
  addNewItemOnUI,
  addItemsForDrop,
  updateContainer,
  addTrailersToStop,
  addItemsForPickup,
  removeItemFromStop,
  addItemsClosForDrop,
  addExchangeTerminal,
  removeTelFromPlanner,
  removeTrailerFromStop,
  addItemsClosForPickup,
  removeCloItemFromStop,
  addRouteTemplateStops,
  addNewContainerToStop,
  dispatchTelRateRequest,
  addExistedTerminalToTel,
  removeContainerFromStop,
  addDropContainersToStop,
  uncheckAndRemoveCloEvent,
  addPickupContainersToStop,
  addStopsToExchangeTerminal,
  addNewStopToRouteBuilderTel,
  setExpandedContainerOptions,
  dispatchCarrierTelRateRequest,
  removeStopFromRouteBuilderTel,
  recalculateTelDistancesRequest,
  addNewCloStopToRouteBuilderTel,
  updateCloStopsOnPlannerRequest,
  getCloTelsAndAddToPlannerRequest,
  updateStopOnRouteBuilderTelRequest,
};

export const LoadWithAsyncData = connect(mapStateToProps, actions)(withAsyncInitialDataOnDidMount(Load));

export default connect(mapStateToProps, actions)(Load);

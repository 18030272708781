import React from 'react';
import * as R from 'ramda';
// features
import Audit2 from '../../../audit2';
import PC from '../../../permission/role-permission';
import FuelCardListComponent from '../../../fuel-cards';
import InspectionsListComponent from '../../../inspections';
import TollChargesListComponent from '../../../toll-charges';
import TaskManagementReport from '../../../task-management/report';
// helpers/constants
import * as G from '../../../../helpers';
// feature fleet-profile
import { Tab, TabsGroupWrapper, tabsGroupWrapperEnhance } from '../../components/tabs-group-wrapper';
// feature fleet-profile/driver
import {
  withAuditTab,
  withTasksTab,
  withInspectionsTab,
  withTollChargesTab,
  withGeneralSettings,
  withPayrollSettings,
  withExpensesSettings,
  withDailyLogSettings,
  withFuelTransactionsTab,
  withRatingEngineSettings,
  withAdvancePaymentSettings,
  withLicenseDocumentsSettings,
  withUnavailablePeriodSettings,
} from '../hocs/with-tab-group-settings';
/////////////////////////////////////////////

const AuditTab = withAuditTab(Audit2);
const GeneralTab = withGeneralSettings(Tab);
const PayrollTab = withPayrollSettings(Tab);
const ExpensesTab = withExpensesSettings(Tab);
const DailyLogTab = withDailyLogSettings(Tab);
const TasksTab = withTasksTab(TaskManagementReport);
const RatingEngineTab = withRatingEngineSettings(Tab);
const AdvancePaymentTab = withAdvancePaymentSettings(Tab);
const LicenseDocumentsTab = withLicenseDocumentsSettings(Tab);
const UnavailablePeriodsTab = withUnavailablePeriodSettings(Tab);
const InspectionsTab = withInspectionsTab(InspectionsListComponent);
const TollChargesTab = withTollChargesTab(TollChargesListComponent);
const FuelTransactionsTab = withFuelTransactionsTab(FuelCardListComponent);

const ActiveTab = (props: Object) => {
  const { activeTab, primaryObjectGuid, tabsCollapsedView } = props;

  const commonProps = {
    primaryObjectGuid,
    tabsCollapsedView,
  };

  const tabsMap = [
    <GeneralTab key={1} {...props} />,
    <LicenseDocumentsTab key={2} {...props} />,
    <RatingEngineTab key={3} {...props} />,
    <PayrollTab key={4} {...props} />,
    <InspectionsTab key={5} {...props} />,
    <FuelTransactionsTab key={6} {...props} />,
    <TollChargesTab key={7} {...commonProps} />,
    <DailyLogTab key={8} {...props} />,
    <ExpensesTab key={9} {...props} />,
    <AdvancePaymentTab key={10} {...props} />,
    <UnavailablePeriodsTab key={11} {...props} />,
    <TasksTab key={12} tabsCollapsedView={tabsCollapsedView} />,
    <AuditTab key={13} {...commonProps} />,
  ];

  return R.pathOr(null, [activeTab], tabsMap);
};

const tabs = () => [
  { text: G.getWindowLocale('titles:general', 'General') },
  { text: G.getWindowLocale('titles:license-documents', 'License/Documents') },
  { text: G.getWindowLocale('titles:rating-engine', 'Rating Engine') },
  { text: G.getWindowLocale('titles:payroll', 'Payroll') },
  { text: G.getWindowLocale('titles:inspections', 'Inspections') },
  {
    permissions: [PC.FLEET_FUEL_CARD_READ, PC.FLEET_FUEL_CARD_WRITE],
    text: G.getWindowLocale('titles:fuel-transactions', 'Fuel Transactions'),
  },
  {
    permissions: [PC.TOLL_CHARGE_READ, PC.TOLL_CHARGE_WRITE],
    text: G.getWindowLocale('titles:toll-charges', 'Toll Charges'),
  },
  { text: G.getWindowLocale('titles:daily-log', 'Daily Log') },
  { text: G.getWindowLocale('titles:expenses', 'Expenses') },
  { text: G.getWindowLocale('titles:advance-payment', 'Advance Payment') },
  { text: G.getWindowLocale('titles:time-off', 'Time Off') },
  {
    text: G.getWindowLocale('titles:tasks', 'Tasks'),
    permissions: G.ifElse(
      R.includes('task_management', window.location.pathname),
      [],
      [PC.TASK_READ, PC.TASK_WRITE],
    ),
  },
  { text: G.getWindowLocale('titles:audit', 'Audit') },
];

const DriverTabsGroups = (props: Object) => (
  <TabsGroupWrapper
    tabs={tabs()}
    activeTab={props.activeTab}
    setTabsView={props.setTabsView}
    tabsCollapsedView={props.tabsCollapsedView}
    handleSetActiveTab={props.handleSetActiveTab}
  >
    <ActiveTab {...props} />
  </TabsGroupWrapper>
);

export default tabsGroupWrapperEnhance(DriverTabsGroups);

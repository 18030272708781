import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import {
  updateTelOnLoadBoardSuccess,
  addPostedShipmentStateSuccess,
  updatePostedShipmentStateSuccess,
  deletePostedShipmentStateSuccess,
  changeCommissionAssignmentSuccess,
  toggleMarkAsHotLoadByLoadTypeSuccess,
  toggleMarkAsFastLoadByLoadTypeSuccess,
} from '../../../common/actions';
// features
import { setDataToListItemByPropName } from '../../dispatch-board-new/order/actions';
import {
  socketTelRateReceived,
  updateNotificationsStore,
  socketTelDocumentReceived,
  socketTelLoadStatusReceived,
  socketTelRateStatusReceived,
  socketCloStatusToTelReceived,
  socketTelChatMessageReceived,
  socketTelEventStatusReceived,
  socketTelStatusMessageReceived,
  socketTelCostAllocationsReceived,
  socketPostedShipmentStateReceived,
} from '../../sockets-v2/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature load-details-new
import * as A from './actions';
import * as H from '../helpers';
import * as C from '../constants';
//////////////////////////////////////////////////

const initialState = {
  events: {},
  details: {},
  charges: [],
  mailList: [],
  documents: [],
  invoiceTotals: [],
  documentTemplates: {},
  loadCustomStatuses: [],
  claimGeneralDetails: {},
  linkedOrdersDocuments: [],
  hiddenTabs: {
    itemList: false,
    containerList: false,
    vehicleItemList: false,
    crossBorderIntegrationList: true,
  },
  notification: {
    list: [],
    empty: true,
    unreadCount: 0,
  },
  messageCenter: {
    noteList: [],
    chatMessageList: [],
    activeTab: 'chatMessageList',
  },
  dispatchPlannerOpened: false,
  isAnyEnabledCrossBorder: false,
  dispatchPlannerEventsOpened: false,
  expandedLists: [false, false, false],
  activeLists: [C.ACTIVE_LIST_REFERENCES, C.ACTIVE_LIST_REFERENCES],
  loadConfigs: {
    statusMessageConfigs: [],
    configsByNames: {
      configs: {},
      dropdowns: {},
    },
    configGroups: {
      ui: {},
      invoice: {},
      general: {},
      communication: {},
      statusMessages: [],
    },
  },
  lists: {
    itemList: [],
    orderList: [],
    containerList: [],
    loadBoardList: [],
    referenceList: [],
    macroPointList: [],
    vehicleItemList: [],
    customerRateList: [],
    statusMessageList: [],
    driverInvoiceList: [],
    advancePaymentList: [],
    carrierInvoiceList: [],
    externalSystemList: [],
    linkedOrderRefList: [],
    customerInvoiceList: [],
    driverCarrierRateList: [],
    serviceVendorInvoiceList: [],
    linkedCustomerInvoiceList: [],
    crossBorderIntegrationList: [],
  },
  linkedOrderList: null,
  relatedOrderList: null,
  cloEventReferences: [],
  postedShipmentsData: null,
  relatedOrderListLoading: false,
};

const setInitialState = () => initialState;

const setValueToStore = (state: Object, { path, value }: Object) => (
  P.$set(path, value, state)
);

const updateLoadDetailsSuccess = (state: Object, data: Object) => (
  P.$set('details', R.mergeRight(state.details, data), state)
);

const changeActiveListSuccess = (state: Object, { listName, tableNumber }: Object) => (
  P.$set(`activeLists.${tableNumber}`, listName, state)
);

const getAvailableLoadCustomStatusListSuccess = (state: Object, data: Object) => (
  P.$set('loadCustomStatuses', data, state)
);

const getLoadInvoiceTotalsSuccess = (state: Object, data: Object) => (
  P.$set('invoiceTotals', data, state)
);

const toggleExpandedListsSuccess = (state: Object, { expanded, tableNumber }: Object) => {
  if (G.isNilOrEmpty(expanded)) return P.$toggle(`expandedLists.${tableNumber}`, state);

  return P.$set(`expandedLists.${tableNumber}`, expanded, state);
};

const setDispatchPlannerOpened = (state: Object, data: boolean) => (
  P.$set('dispatchPlannerOpened', data, state)
);

const setDispatchPlannerEventsOpened = (state: Object, data: boolean) => (
  P.$set('dispatchPlannerEventsOpened', data, state)
);

const getLoadDetailsSuccess = (state: Object, data: Object) => (
  P.$set('details', data, state)
);

// configs
const getLoadConfigByGroupNamesSuccess = (state: Object, data: Object) => (
  P.$set('loadConfigs.configGroups', data, state)
);

const getStatusMessagesConfigListSuccess = (state: Object, data: Object) => (
  P.$set('loadConfigs.statusMessageConfigs', data, state)
);

const getLoadConfigsByNamesSuccess = (state: Object, data: Object) => (
  P.$set('loadConfigs.configsByNames', data, state)
);

// events
const getLoadEventsSuccess = (state: Object, data: Object) => (
  P.$set('events', R.indexBy(R.prop(GC.FIELD_GUID), data), state)
);

const updateAppointmentSuccess = (state: Object, data: Object) => {
  const events = R.compose(
    R.map((event: Object) => {
      if (R.eqProps(GC.FIELD_GUID, event, data)) return data;

      return event;
    }),
    R.path(['details', 'events']),
  )(state);

  return (
    P.$all(
      P.$set('details.events', events),
      P.$set('events', R.indexBy(R.prop(GC.FIELD_GUID), events)),
      state,
    )
  );
};

// items
const getLoadItemListSuccess = (state: Object, data: Object) => {
  const grouped = R.groupBy(R.prop(GC.FIELD_ITEM_TYPE), data);
  const itemList = R.map(R.assoc('withoutRowActions', true), R.pathOr([], [GC.ITEM_TYPE_GENERAL], grouped));
  const vehicleItemList = R.map(
    R.mergeRight({ expanded: false, withoutRowActions: true }),
    R.pathOr([], [GC.ITEM_TYPE_VEHICLE], grouped),
  );
  const hiddenTabs = R.mergeRight(
    state.hiddenTabs,
    {
      itemList: G.isNilOrEmpty(itemList),
      vehicleItemList: G.isNilOrEmpty(vehicleItemList),
    },
  );

  return P.$all(
    P.$set('hiddenTabs', hiddenTabs),
    P.$set('lists.itemList', itemList),
    P.$set('lists.vehicleItemList', vehicleItemList),
    state,
  );
};

const toggleLoadVehicleItemSuccess = (state: Object, data: Object) => {
  const { itemGuid, damages, tableNumber } = data;

  const itemList = R.pathOr([], ['lists', 'vehicleItemList'], state);
  const index = R.findIndex(R.propEq(itemGuid, GC.FIELD_GUID), itemList);
  const expandedItem = R.path([index, 'expanded'], itemList);
  if (G.isFalse(expandedItem)) {
    return P.$all(
      P.$set(`lists.vehicleItemList.${index}.expanded`, true),
      P.$set(`lists.vehicleItemList.${index}.damages`, damages),
      P.$set(`expandedLists.${tableNumber}`, R.gt(R.length(damages), 1)),
      state,
    );
  }

  return P.$set(`lists.vehicleItemList.${index}.expanded`, false, state);
};

const createOrUpdateLoadVehicleItemDamageSuccess = (state: Object, data: Object) => {
  const { guid, itemGuid } = data;

  const vehicleItemList = R.pathOr([], ['lists', 'vehicleItemList'], state);
  const itemIndex = R.findIndex(R.propEq(itemGuid, GC.FIELD_GUID), vehicleItemList);
  const damages = R.pathOr([], [itemIndex, 'damages'], vehicleItemList);
  const damageIndex = R.findIndex(R.propEq(guid, GC.FIELD_GUID), damages);

  if (R.lt(damageIndex, 0)) {
    return P.$add(`lists.vehicleItemList.${itemIndex}.damages`, data, state);
  }

  return P.$set(`lists.vehicleItemList.${itemIndex}.damages.${damageIndex}`, data, state);
};

const removeLoadVehicleItemDamageSuccess = (state: Object, data: Object) => {
  const { guid, itemGuid } = data;

  const vehicleItemList = R.pathOr([], ['lists', 'vehicleItemList'], state);
  const itemIndex = R.findIndex(R.propEq(itemGuid, GC.FIELD_GUID), vehicleItemList);
  const damages = R.pathOr([], [itemIndex, 'damages'], vehicleItemList);
  const damageIndex = R.findIndex(R.propEq(guid, GC.FIELD_GUID), damages);

  return P.$drop(`lists.vehicleItemList.${itemIndex}.damages.${damageIndex}`, state);
};

// containers
const getLoadContainerListSuccess = (state: Object, data: Object) => (
  P.$all(
    P.$set('hiddenTabs.containerList', G.isNilOrEmpty(data)),
    P.$set('lists.containerList', R.map(R.assoc('withoutRowActions', true), data)),
    state,
  )
);

// load board
const getLoadBoardListSuccess = (state: Object, data: Object) => (
  P.$set('lists.loadBoardList', data, state)
);

const getPostedShipmentsSuccess = (state: Object, { data, tableNumber }: Object) => (
  P.$set(
    'postedShipmentsData',
    data,
    H.setActiveListDataToStore(
      state,
      R.propOr([], 'states', data),
      C.ACTIVE_LIST_LOAD_BOARDS,
      tableNumber,
    ),
  )
);

const postShipmentsSuccess = (state: Object, { elements }: Object) => {
  const guid = P.$get('details.guid', state);

  if (R.isNil(guid)) return state;

  const data = R.find(R.propEq(guid, GC.FIELD_OBJECT_GUID), elements);

  if (R.isNil(data)) return state;

  return P.$set(
    'postedShipmentsData',
    data,
    H.setActiveListDataToStore(
      state,
      R.propOr([], 'states', data),
      C.ACTIVE_LIST_LOAD_BOARDS,
      null,
    ),
  );
};

const deletePostedShipmentSuccess = (state: Object) => (
  P.$set(
    'postedShipmentsData',
    null,
    H.setActiveListDataToStore(
      state,
      [],
      C.ACTIVE_LIST_LOAD_BOARDS,
      null,
    ),
  )
);

const handlePostedShipmentStateReceived = (state: Object, data: Object) => {
  const list = P.$get(`lists.${C.ACTIVE_LIST_LOAD_BOARDS}`, state);

  if (G.isNilOrEmpty(list)) return state;

  const states = R.map(
    (item: Object) => {
      if (R.eqProps(GC.FIELD_TYPE, item, data)) return data;

      return item;
    },
    list,
  );

  return P.$all(
    P.$set('postedShipmentsData.states', states),
    P.$set(`lists.${C.ACTIVE_LIST_LOAD_BOARDS}`, states),
    state,
  );
};

const handleUpdatePostedShipmentStateSuccess = (state: Object, { data, objectGuid }: Object) => {
  const loadGuid = P.$get('details.guid', state);

  if (G.notEquals(loadGuid, objectGuid)) return state;

  return handlePostedShipmentStateReceived(state, data);
};

const handleAddPostedShipmentStateSuccess = (state: Object, { data, postedShipmentGuid }: Object) => {
  const postedShipment = P.$get('postedShipmentsData', state);

  if (G.notEquals(G.getGuidFromObject(postedShipment), postedShipmentGuid)) return state;

  return P.$all(
    P.$add('postedShipmentsData.states', data),
    P.$add(`lists.${C.ACTIVE_LIST_LOAD_BOARDS}`, data),
    state,
  );
};

const handleDeletePostedShipmentStateSuccess = (state: Object, { guid, objectGuid }: Object) => {
  const loadGuid = P.$get('details.guid', state);

  if (G.notEquals(loadGuid, objectGuid)) return state;

  const list = P.$get(`lists.${C.ACTIVE_LIST_LOAD_BOARDS}`, state);

  const states = R.filter(
    (item: Object) => R.not(R.propEq(guid, GC.FIELD_GUID, item)),
    list,
  );

  return P.$all(
    P.$set('postedShipmentsData.states', states),
    P.$set(`lists.${C.ACTIVE_LIST_LOAD_BOARDS}`, states),
    state,
  );
};

// mails
const getLoadMailListSuccess = (state: Object, data: Object) => (
  P.$set('mailList', data, state)
);

// notifications
const getLoadNotificationListSuccess = (state: Object, { empty, results }: Object) => {
  if (G.isTrue(empty)) return state;
  const list = R.map((item: Object) => {
    const { guid, read, createdDate, notification } = item;

    const warningLevel = G.getPropFromObject(GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_LEVEL, notification);
    const trigger = G.getEnumLocale(
      G.getPropFromObject(GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER, notification),
    );

    return {
      read,
      guid,
      trigger,
      createdDate,
      warningLevel,
      [GC.FIELD_MESSAGE]: G.getPropFromObject(GC.FIELD_MESSAGE, notification),
    };
  }, results);
  const unreadCount = R.compose(
    R.length,
    R.filter(({ read }: Object) => G.isFalse(read)),
  )(list);

  return P.$set('notification', { list, empty, unreadCount }, state);
};

const hideLoadNotificationsSuccess = (state: Object) => (
  P.$all(
    P.$set('notification.unreadCount', 0),
    P.$set(
      'notification.list',
      R.map(
        (item: Object) => R.assoc('read', true, item),
        R.path(['notification', 'list'], state),
      ),
    ),
    state,
  )
);

const markLoadNotificationAsReadSuccess = (state: Object, guid: string) => {
  const updatedList = R.map(
    (item: Object) => {
      if (R.equals(item.guid, guid)) {
        return R.mergeRight(item, { read: true });
      }

      return item;
    },
    state.notification.list,
  );

  return P.$all(
    P.$set('notification.list', updatedList),
    P.$set('notification.unreadCount', state.notification.unreadCount - 1),
    state,
  );
};

// references
const getLoadReferenceListSuccess = (state: Object, { data, tableNumber }: Object) => {
  const referenceList = R.sortBy(({ primary }: Object) => R.not(primary), data);

  return H.setActiveListDataToStore(
    state,
    referenceList,
    C.ACTIVE_LIST_REFERENCES,
    tableNumber,
  );
};

const createLoadReferenceSuccess = (state: Object, data: Object) => {
  const { lists: { referenceList } } = state;
  if (R.isNil(referenceList)) return P.$set('lists.referenceList', R.of(Array, data), state);
  const { primary } = data;
  if (primary) {
    const oldReferenceList = R.map(R.assoc(GC.FIELD_PRIMARY, false), referenceList);
    const newReferenceList = R.prepend(data, oldReferenceList);

    return P.$set('lists.referenceList', newReferenceList, state);
  }

  return P.$add('lists.referenceList', data, state);
};

const updateLoadReferenceSuccess = (state: Object, data: Object) => {
  const { lists: { referenceList } } = state;
  const { guid, primary } = data;
  const index = R.findIndex(R.pathEq(guid, [GC.FIELD_GUID]), referenceList);
  if (primary) {
    const newReferenceList = R.compose(
      R.sortBy(({ primary }: Object) => R.not(primary)),
      R.map((item: Object) => G.ifElse(
        R.pathEq(guid, [GC.FIELD_GUID], item),
        data,
        R.assoc(GC.FIELD_PRIMARY, false, item),
      )),
    )(referenceList);

    return P.$all(
      P.$set('details.references', newReferenceList),
      P.$set('lists.referenceList', newReferenceList),
      state,
    );
  }

  return P.$set(`lists.referenceList.${index}`, data, state);
};

const removeLoadReferenceSuccess = (state: Object, guid: string) => H.removeListItemFromStore(
  state,
  guid,
  'referenceList',
);

const getCloReferencesByEventGuidsSuccess = (state: Object, data: Array) => (
  P.$set('cloEventReferences', data, state)
);

// linked order references
const getLinkedOrderRefListSuccess = (state: Object, { data, tableNumber }: Object) => (
  H.setActiveListDataToStore(
    state,
    data,
    C.ACTIVE_LIST_LINKED_ORDER_REF_LIST,
    tableNumber,
  )
);

// status messages
const getLoadStatusMessageListSuccess = (state: Object, { data, tableNumber }: Object) => {
  const { events } = state;
  const statusMessageList = H.getStatusMessageListWithLoadEvent(data, events);

  return H.setActiveListDataToStore(
    state,
    statusMessageList,
    C.ACTIVE_LIST_STATUS_MESSAGES,
    tableNumber,
  );
};

const createLoadMultipleStatusMessageSuccess = (state: Object, data: Object) => {
  const { events, lists: { statusMessageList } } = state;

  const mappedStatusMessages = H.getStatusMessageListWithLoadEvent(data, events);
  const newStatusMessageList = R.concat(statusMessageList, mappedStatusMessages);

  return P.$set('lists.statusMessageList', newStatusMessageList, state);
};

const createLoadStatusMessageSuccess = (state: Object, data: Object) => (
  P.$add('lists.statusMessageList', R.head(H.getStatusMessageListWithLoadEvent(R.of(Array, data), state.events)), state)
);

const updateLoadStatusMessageSuccess = (state: Object, data: Object) => {
  const { guid } = data;
  const { events, lists: { statusMessageList } } = state;
  const index = R.findIndex(R.pathEq(guid, [GC.FIELD_GUID]), statusMessageList);
  const statusMessage = R.head(H.getStatusMessageListWithLoadEvent(R.of(Array, data), events));

  return P.$set(`lists.statusMessageList.${index}`, statusMessage, state);
};

const removeLoadStatusMessageSuccess = (state: Object, guid: string) => H.removeListItemFromStore(
  state,
  guid,
  'statusMessageList',
);

// macro point
const getMacroPointListSuccess = (state: Object, { data, tableNumber }: Object) =>
  H.setActiveListDataToStore(state, R.of(Array, data), C.ACTIVE_LIST_MACRO_POINT, tableNumber);

// advance payment
const getLoadAdvancePaymentListSuccess = (state: Object, { data, tableNumber }: Object) => {
  const { lists: { driverCarrierRateList } } = state;
  const withoutRowActions = R.compose(
    R.pathEq(GC.RATE_TYPE_CARRIER_RATE, [GC.FIELD_TYPE]),
    R.find(R.propEq(true, 'selected')),
  )(driverCarrierRateList);
  const mappedList = R.map(R.assoc('withoutRowActions', withoutRowActions), data);

  return H.setActiveListDataToStore(state, mappedList, C.ACTIVE_LIST_ADVANCE_PAYMENT, tableNumber);
};

const createLoadAdvancePaymentSuccess = (state: Object, data: Object) => (
  P.$add('lists.advancePaymentList', data, state)
);

const updateLoadAdvancePaymentStatusSuccess = (state: Object, data: Object) => H.setUpdatedListItemToStore(
  data,
  state,
  'advancePaymentList',
);

// driver/carrier rates
const getLoadDriverCarrierRateListSuccess = (state: Object, { data, tableNumber }: Object) =>
  H.setActiveListDataToStore(state, data, C.ACTIVE_LIST_CARRIER_FLEET_RATES, tableNumber);

const createLoadDriverCarrierRateSuccess = (state: Object, data: Object) => {
  const { selected } = data;
  if (selected) {
    return P.$add('lists.driverCarrierRateList', data, H.getStateWithUnselectedRates(state));
  }

  return P.$add('lists.driverCarrierRateList', data, state);
};

const updateLoadDriverCarrierRateSuccess = (state: Object, data: Object) => H.setUpdatedListItemToStore(
  data,
  state,
  'driverCarrierRateList',
);

const removeLoadDriverCarrierRateSuccess = (state: Object, guid: string) => H.removeListItemFromStore(
  state,
  guid,
  'driverCarrierRateList',
);

const selectDriverCarrierRateSuccess = (state: Object, data: Object) => {
  const { guid, fleetAssignment, carrierAssignment } = data;
  const { lists: { driverCarrierRateList } } = state;

  const newList = R.map(
    (item: Object) => {
      if (R.eqProps(GC.FIELD_GUID, item, data)) {
        return data;
      }

      const { version } = item;

      const versionToUse = G.ifElse(item.selected, R.inc(version), version);

      return R.mergeRight(item, { selected: false, version: versionToUse });
    },
    driverCarrierRateList,
  );

  return P.$all(
    P.$set('details.selectedRateGuid', guid),
    P.$set('lists.driverCarrierRateList', newList),
    P.$set(`details.${GC.FIELD_FLEET_ASSIGNMENT_GUID}`, R.pathOr(null, [GC.FIELD_GUID], fleetAssignment)),
    P.$set(`details.${GC.FIELD_CARRIER_ASSIGNMENT_GUID}`, R.pathOr(null, [GC.FIELD_GUID], carrierAssignment)),
    state,
  );
};

// driver invoices
const getLoadDriverInvoiceListSuccess = (state: Object, { data, tableNumber }: Object) => H.setActiveListDataToStore(
  state,
  data,
  C.ACTIVE_LIST_DRIVER_INVOICES,
  tableNumber,
);

const createDriverInvoiceSuccess = (state: Object, data: Object) => (
  P.$add('lists.driverInvoiceList', data, state)
);

const updateLoadDriverInvoiceSuccess = (state: Object, { invoice }: Object) => H.setUpdatedListItemToStore(
  invoice,
  state,
  'driverInvoiceList',
);

const removeLoadDriverInvoiceSuccess = (state: Object, guid: Object) => H.removeListItemFromStore(
  state,
  guid,
  'driverInvoiceList',
);

// carrier invoices
const getLoadCarrierInvoiceListSuccess = (state: Object, { data, tableNumber }: Object) => H.setActiveListDataToStore(
  state,
  data,
  C.ACTIVE_LIST_CARRIER_INVOICES,
  tableNumber,
);

const createLoadCarrierInvoiceSuccess = (state: Object, data: Object) => (
  P.$add('lists.carrierInvoiceList', data, state)
);

const updateLoadCarrierInvoiceSuccess = (state: Object, data: Object) => H.setUpdatedListItemToStore(
  data,
  state,
  'carrierInvoiceList',
);

const removeLoadCarrierInvoiceSuccess = (state: Object, guid: string) => H.removeListItemFromStore(
  state,
  guid,
  'carrierInvoiceList',
);

// service vendor invoices
const getServiceVendorInvoiceListSuccess = (state: Object, { data, tableNumber }: Object) => H.setActiveListDataToStore(
  state,
  data,
  C.ACTIVE_LIST_SERVICE_VENDOR_INVOICES,
  tableNumber,
);

const createServiceVendorInvoiceSuccess = (state: Object, data: Object) => (
  P.$add(`lists.${C.ACTIVE_LIST_SERVICE_VENDOR_INVOICES}`, data, state)
);

const updateServiceVendorInvoiceSuccess = (state: Object, { invoice }: Object) => H.setUpdatedListItemToStore(
  invoice,
  state,
  C.ACTIVE_LIST_SERVICE_VENDOR_INVOICES,
);

const removeServiceVendorInvoiceSuccess = (state: Object, guid: Object) => H.removeListItemFromStore(
  state,
  guid,
  C.ACTIVE_LIST_SERVICE_VENDOR_INVOICES,
);

// customer invoices
const getLoadCustomerInvoiceListSuccess = (state: Object, { data, tableNumber }: Object) => H.setActiveListDataToStore(
  state,
  data,
  C.ACTIVE_LIST_CUSTOMER_INVOICES,
  tableNumber,
);

const createLoadCustomerInvoiceSuccess = (state: Object, data: Object) => (
  P.$add('lists.customerInvoiceList', data, state)
);

const updateLoadCustomerInvoiceSuccess = (state: Object, data: Object) => H.setUpdatedListItemToStore(
  data,
  state,
  'customerInvoiceList',
);

const removeLoadCustomerInvoiceSuccess = (state: Object, guid: string) => H.removeListItemFromStore(
  state,
  guid,
  'customerInvoiceList',
);

// linked customer invoices
const getLinkedCustomerInvoiceListSuccess = (state: Object, { data, tableNumber }: Object) => (
  H.setActiveListDataToStore(
    state,
    data,
    C.ACTIVE_LIST_LINKED_CUSTOMER_INVOICES,
    tableNumber,
  )
);

// customer rates
const getLoadCustomerRateListSuccess = (state: Object, { data, tableNumber }: Object) => H.setActiveListDataToStore(
  state,
  data,
  C.ACTIVE_LIST_CUSTOMER_RATES,
  tableNumber,
);

const createLoadCustomerRateSuccess = (state: Object, data: Object) => (
  P.$add('lists.customerRateList', data, state)
);

const updateLoadCustomerRateSuccess = (state: Object, data: Object) => H.setUpdatedListItemToStore(
  data,
  state,
  'customerRateList',
);

const removeLoadCustomerRateSuccess = (state: Object, guid: string) => H.removeListItemFromStore(
  state,
  guid,
  'customerRateList',
);

const selectLoadCustomerRateSuccess = (state: Object, data: string) => {
  const { lists: { customerRateList } } = state;
  const { guid } = data;

  const newList = R.map((item: Object) => {
    const prevSelected = item.selected;
    const selected = R.propEq(guid, GC.FIELD_GUID, item);
    const itemVersion = G.getPropFromObject(GC.FIELD_VERSION, item);
    const version = G.ifElse(R.or(selected, prevSelected), R.inc(itemVersion), itemVersion);

    return R.mergeRight(item, { version, selected });
  }, customerRateList);

  return P.$set('lists.customerRateList', newList, state);
};

// external system
const getExternalSystemListSuccess = (state: Object, { data, tableNumber }: Object) => H.setActiveListDataToStore(
  state,
  data,
  C.ACTIVE_LIST_EXTERNAL_SYSTEM_LIST,
  tableNumber,
);

const removeTelFromExternalSystemSuccess = (state: Object, guid: string) => H.removeListItemFromStore(
  state,
  guid,
  C.ACTIVE_LIST_EXTERNAL_SYSTEM_LIST,
);

// documents
const getLoadDocumentListSuccess = (state: Object, data: Object) => (
  P.$set('documents', data, state)
);

const getLoadLinkedOrdersDocumentListSuccess = (state: Object, data: Object) => (
  P.$set(
    'linkedOrdersDocuments',
    R.map(R.assoc('isLinkedOrder', true), data),
    state,
  )
);

const updateLoadDocumentSuccess = (state: Object, data: Object) => {
  const { documents, linkedOrdersDocuments } = state;
  const { guid } = data;

  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), documents);
  const linkedIndex = R.findIndex(R.propEq(guid, GC.FIELD_GUID), linkedOrdersDocuments);

  if (G.notEquals(index, -1)) return P.$set(`documents.${index}`, data, state);

  if (G.notEquals(linkedIndex, -1)) {
    return P.$set(`linkedOrdersDocuments.${linkedIndex}`, R.assoc('isLinkedOrder', true, data), state);
  }

  return state;
};

const removeLoadDocumentSuccess = (state: Object, guid: Object) => {
  const { documents } = state;

  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), documents);

  return P.$drop(`documents.${index}`, state);
};

const setDocumentTemplatesToStore = (state: Object, data: Object) => (
  P.$set('documentTemplates', data, state)
);

// message center
const getLoadChatMessageListSuccess = (state: Object, data: Object) => (
  P.$set('messageCenter.chatMessageList', data, state)
);

const changeMessageCenterActiveTabSuccess = (state: Object, tab: string) => (
  P.$set('messageCenter.activeTab', tab, state)
);

const getLoadNoteListSuccess = (state: Object, data: Object) => (
  P.$set('messageCenter.noteList', data, state)
);

const createLoadNoteSuccess = (state: Object, data: Object) => (
  P.$add('messageCenter.noteList', data, state)
);

const updateLoadNoteSuccess = (state: Object, data: Object) => {
  const { messageCenter: { noteList } } = state;
  const { guid, pinned } = data;
  if (pinned) {
    const mappedList = R.map((item: Object) => G.ifElse(
      R.propEq(guid, GC.FIELD_GUID, item),
      data,
      G.ifElse(
        item.pinned,
        R.mergeRight(item, { pinned: false, version: R.inc(item.version) }),
        R.assoc('pinned', false, item),
      ),
    ), noteList);

    return P.$set('messageCenter.noteList', mappedList, state);
  }
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), noteList);

  return P.$set(`messageCenter.noteList.${index}`, data, state);
};

const removeLoadNoteSuccess = (state: Object, guid: string) => {
  const { messageCenter: { noteList } } = state;
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), noteList);

  return P.$drop(`messageCenter.noteList.${index}`, state);
};

// payments
const getLoadBillToSuccess = (state: Object, data: Object) => (
  P.$set('details.billTo', data, state)
);

// sockets
const handleWSLoadStatusTelReceived = (state: Object, data: Object) => (
  P.$set('details', R.mergeRight(state.details, data), state)
);

const wSLoadCostAllocationsSuccess = (state: Object, { data }: Object) => (
  P.$set('details.costAllocations', data, state)
);

const handleSocketTelDocumentReceived = (state: Object, { data, entityOperation }: Object) => {
  const { documents } = state;
  const guid = R.pathOr(data, [GC.FIELD_GUID], data);
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), documents);
  switch (entityOperation) {
    case 'CREATE': return P.$add('documents', data, state);
    case 'DELETE': return P.$drop(`documents.${index}`, state);
    default: return state;
  }
};

const wSTelStatusMessageReceived = (state: Object, { data, entityOperation }: Object) => {
  const { events, lists: { statusMessageList } } = state;

  const guid = R.pathOr(data, [GC.FIELD_GUID], data);
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), statusMessageList);
  let statusMessage = data;

  if (R.includes(entityOperation, ['CREATE', 'UPDATE'])) {
    statusMessage = R.head(H.getStatusMessageListWithLoadEvent(R.of(Array, data), events));
  }

  switch (entityOperation) {
    case 'DELETE': return P.$drop(`lists.statusMessageList.${index}`, state);
    case 'CREATE': return P.$add('lists.statusMessageList', statusMessage, state);
    case 'UPDATE': return P.$set(`lists.statusMessageList.${index}`, statusMessage, state);
    default: return state;
  }
};

const wSLoadChatMessageSuccess = (state: Object, { data, entityOperation }: Object) => {
  const { messageCenter: { chatMessageList } } = state;
  const guid = R.pathOr(data, [GC.FIELD_GUID], data);
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), chatMessageList);
  switch (entityOperation) {
    case 'CREATE': return P.$add('messageCenter.chatMessageList', data, state);
    case 'DELETE': return P.$drop(`messageCenter.chatMessageList.${index}`, state);
    case 'UPDATE': return P.$set(`messageCenter.chatMessageList.${index}`, data, state);
    default: return state;
  }
};

const wSLoadRateReceivedSuccess = (state: Object, { data, entityOperation }: Object) => {
  const { lists: { driverCarrierRateList } } = state;

  const guid = R.pathOr(data, [GC.FIELD_GUID], data);
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), driverCarrierRateList);

  switch (entityOperation) {
    case 'CREATE':
      if (R.propEq(true, 'selected', data)) {
        return P.$add('lists.driverCarrierRateList', data, H.getStateWithUnselectedRates(state));
      }

      return P.$add('lists.driverCarrierRateList', data, state);
    case 'DELETE': return P.$drop(`lists.driverCarrierRateList.${index}`, state);
    case 'UPDATE': return P.$set(`lists.driverCarrierRateList.${index}`, data, state);
    default: return state;
  }
};

const handleWSEventStatusTelReceived = (state: Object, { data }: Object) => {
  const { details } = state;
  const { guid, status, version, eventCheckinDate, eventCompleteDate } = data;

  if (R.isEmpty(details)) return state;

  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), G.getPropFromObject(GC.FIELD_LOAD_STOPS, details));
  const event = R.mergeRight(
    R.path([GC.FIELD_LOAD_STOPS, guid], state),
    { status, version, eventCheckinDate, eventCompleteDate },
  );

  return P.$all(
    P.$set(`events.${guid}`, event),
    P.$set(`details.events.${index}`, event),
    state,
  );
};

const wsCloStatusToTelReceived = (state: Object, { data }: Object) => {
  const { linkedOrderList, relatedOrderList } = state;

  const relatedOrderListWithNewStatuses = R.map((item: Object) => {
    const { guid, status } = item;

    const newStatus = R.pathOr(status, [guid], data);

    return R.assoc(GC.FIELD_STATUS, newStatus, item);
  }, R.or(relatedOrderList, []));

  const linkedOrderListWithNewStatuses = R.map((item: Object) => {
    const { guid, status } = item;

    const newStatus = R.pathOr(status, [guid], data);

    return R.assoc(GC.FIELD_STATUS, newStatus, item);
  }, R.or(linkedOrderList, []));

  return P.$all(
    P.$set('linkedOrderList', linkedOrderListWithNewStatuses),
    P.$set('relatedOrderList', relatedOrderListWithNewStatuses),
    state,
  );
};

const updateLoadNotificationStore = (state: Object, { guid, read, notification }: Object = {}) => {
  const { details, notification: { list, unreadCount } } = state;

  const objectGuid = R.path(['objectGuid'], notification);
  if (G.notEquals(objectGuid, G.getGuidFromObject(details))) return state;
  const newList = R.prepend(R.mergeRight(notification, { guid, read }), list);

  return P.$all(
    P.$set('notification.list', newList),
    P.$set('notification.unreadCount', R.inc(unreadCount)),
    state,
  );
};

// related/linked orders
const getRelatedOrderListRequest = (state: Object) => (
  P.$set('relatedOrderListLoading', true, state)
);

const getRelatedOrderListSuccess = (state: Object, data: Array = []) => (
  P.$all(
    P.$set('relatedOrderList', data),
    P.$set('relatedOrderListLoading', false),
    state,
  )
);

const updateRelatedOrderList = (state: Object, data: Array = []) => {
  const { details, relatedOrderList } = state;

  const { guid, propName, data: customStatus } = data;

  if (R.or(G.isNilOrEmpty(details), G.notEquals(propName, GC.FIELD_CUSTOM_STATUS))) return state;

  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), relatedOrderList);

  return P.$set(`relatedOrderList.${index}.customStatus`, customStatus, state);
};

const getLinkedOrderListSuccess = (state: Object, data: Array = []) => (
  P.$set('linkedOrderList', R.map(R.assoc('isLinked', true), data), state)
);

// hot load
const toggleMarkAsHotLoadByLoadType = (state: Object, { loadType, fromPage }: Object) => {
  if (R.or(G.notEquals(fromPage, 'details'), G.isFalse(G.isLoadTypeTel(loadType)))) return state;

  return P.$toggle('details.hot', state);
};

// commission assignment
const changeLoadCommissionAssignment = (state: Object, { data, loadType, fromPage, fieldName }: Object) => {
  if (R.or(G.notEquals(fromPage, 'details'), G.isFalse(G.isLoadTypeTel(loadType)))) return state;

  return P.$set(`details.${fieldName}`, data, state);
};

// fast load
const toggleMarkAsFast = (state: Object, { loadType, fromPage }: Object) => {
  if (R.or(G.notEquals(fromPage, 'details'), G.isFalse(G.isLoadTypeTel(loadType)))) return state;

  return P.$toggle('details.fastLoad', state);
};

// transportation mode grouping
const getLoadTransportationModeGroupingListSuccess = (state: Object, data: Object) => (
  P.$set('transportationModeGroupingList', data, state)
);

// cross border integration
const getTelCrossBorderIntegrationListSuccess = (state: Object, { data, tableNumber }: Object) =>
  H.setActiveListDataToStore(
    state,
    data,
    C.ACTIVE_LIST_CROSS_BORDER_INTEGRATION_LIST,
    tableNumber,
  );

const updateCrossBorderIntegrationSuccess = (state: Object, data: Object) =>
  H.setUpdatedListItemToStore(
    data,
    state,
    'crossBorderIntegrationList',
  );

const refreshTelCrossBorderIntegrationSuccess = (state: Object, data: Object) =>
  P.$set('lists.crossBorderIntegrationList', R.of([], data), state);

const removeCrossBorderIntegrationSuccess = (state: Object, guid: string) =>
  H.removeListItemFromStore(
    state,
    guid,
    'crossBorderIntegrationList',
  );

const getIsAnyEnabledCrossBorderSuccess = (state: Object, data: Object) =>
  P.$all(
    P.$set('isAnyEnabledCrossBorder', data),
    P.$set('hiddenTabs.crossBorderIntegrationList', R.not(data)),
    state,
  );

// claim
const getClaimGeneralDetailsSuccess = (state: Object, data: Object) => (
  P.$set('claimGeneralDetails', data, state)
);

export default createReducer({
  // common
  [A.setInitialState]: setInitialState,
  [A.setValueToStore]: setValueToStore,
  [A.getLoadDetailsSuccess]: getLoadDetailsSuccess,
  [A.updateLoadDetailsSuccess]: updateLoadDetailsSuccess,
  [A.setDispatchPlannerOpened]: setDispatchPlannerOpened,
  [A.toggleExpandedListsSuccess]: toggleExpandedListsSuccess,
  [A.getLoadInvoiceTotalsSuccess]: getLoadInvoiceTotalsSuccess,
  [A.setDispatchPlannerEventsOpened]: setDispatchPlannerEventsOpened,
  [A.getAvailableLoadCustomStatusListSuccess]: getAvailableLoadCustomStatusListSuccess,
  // hot load
  [toggleMarkAsHotLoadByLoadTypeSuccess]: toggleMarkAsHotLoadByLoadType,
  // fast load
  [toggleMarkAsFastLoadByLoadTypeSuccess]: toggleMarkAsFast,
  // table
  [A.changeActiveListSuccess]: changeActiveListSuccess,
  // configs
  [A.getLoadConfigsByNamesSuccess]: getLoadConfigsByNamesSuccess,
  [A.getLoadConfigByGroupNamesSuccess]: getLoadConfigByGroupNamesSuccess,
  [A.getStatusMessagesConfigListSuccess]: getStatusMessagesConfigListSuccess,
  // events
  [A.getLoadEventsSuccess]: getLoadEventsSuccess,
  [A.updateAppointmentSuccess]: updateAppointmentSuccess,
  // containers
  [A.getLoadContainerListSuccess]: getLoadContainerListSuccess,
  // items
  [A.getLoadItemListSuccess]: getLoadItemListSuccess,
  [A.toggleLoadVehicleItemSuccess]: toggleLoadVehicleItemSuccess,
  [A.removeLoadVehicleItemDamageSuccess]: removeLoadVehicleItemDamageSuccess,
  [A.createOrUpdateLoadVehicleItemDamageSuccess]: createOrUpdateLoadVehicleItemDamageSuccess,
  // load board
  [updateTelOnLoadBoardSuccess]: postShipmentsSuccess,
  [A.getLoadBoardListSuccess]: getLoadBoardListSuccess,
  [A.getPostedShipmentsSuccess]: getPostedShipmentsSuccess,
  [A.deletePostedShipmentSuccess]: deletePostedShipmentSuccess,
  [addPostedShipmentStateSuccess]: handleAddPostedShipmentStateSuccess,
  [socketPostedShipmentStateReceived]: handlePostedShipmentStateReceived,
  [updatePostedShipmentStateSuccess]: handleUpdatePostedShipmentStateSuccess,
  [deletePostedShipmentStateSuccess]: handleDeletePostedShipmentStateSuccess,
  // references
  [A.updateLoadReferenceSuccess]: updateLoadReferenceSuccess,
  [A.createLoadReferenceSuccess]: createLoadReferenceSuccess,
  [A.removeLoadReferenceSuccess]: removeLoadReferenceSuccess,
  [A.getLoadReferenceListSuccess]: getLoadReferenceListSuccess,
  [A.getCloReferencesByEventGuidsSuccess]: getCloReferencesByEventGuidsSuccess,

  // linked order references
  [A.getLinkedOrderRefListSuccess]: getLinkedOrderRefListSuccess,
  // status messages
  [A.removeLoadStatusMessageSuccess]: removeLoadStatusMessageSuccess,
  [A.createLoadStatusMessageSuccess]: createLoadStatusMessageSuccess,
  [A.updateLoadStatusMessageSuccess]: updateLoadStatusMessageSuccess,
  [A.getLoadStatusMessageListSuccess]: getLoadStatusMessageListSuccess,
  [A.createLoadMultipleStatusMessageSuccess]: createLoadMultipleStatusMessageSuccess,
  // driver invoices
  [A.createDriverInvoiceSuccess]: createDriverInvoiceSuccess,
  [A.updateLoadDriverInvoiceSuccess]: updateLoadDriverInvoiceSuccess,
  [A.removeLoadDriverInvoiceSuccess]: removeLoadDriverInvoiceSuccess,
  [A.getLoadDriverInvoiceListSuccess]: getLoadDriverInvoiceListSuccess,
  // carrier invoices
  [A.removeLoadCarrierInvoiceSuccess]: removeLoadCarrierInvoiceSuccess,
  [A.updateLoadCarrierInvoiceSuccess]: updateLoadCarrierInvoiceSuccess,
  [A.createLoadCarrierInvoiceSuccess]: createLoadCarrierInvoiceSuccess,
  [A.getLoadCarrierInvoiceListSuccess]: getLoadCarrierInvoiceListSuccess,
  // service vendor invoices
  [A.createServiceVendorInvoiceSuccess]: createServiceVendorInvoiceSuccess,
  [A.updateServiceVendorInvoiceSuccess]: updateServiceVendorInvoiceSuccess,
  [A.removeServiceVendorInvoiceSuccess]: removeServiceVendorInvoiceSuccess,
  [A.getServiceVendorInvoiceListSuccess]: getServiceVendorInvoiceListSuccess,
  // macro point
  [A.getMacroPointListSuccess]: getMacroPointListSuccess,
  // advance payment
  [A.createLoadAdvancePaymentSuccess]: createLoadAdvancePaymentSuccess,
  [A.getLoadAdvancePaymentListSuccess]: getLoadAdvancePaymentListSuccess,
  [A.updateLoadAdvancePaymentStatusSuccess]: updateLoadAdvancePaymentStatusSuccess,
  // driver/carrier rates
  [A.selectDriverCarrierRateSuccess]: selectDriverCarrierRateSuccess,
  [A.removeLoadDriverCarrierRateSuccess]: removeLoadDriverCarrierRateSuccess,
  [A.createLoadDriverCarrierRateSuccess]: createLoadDriverCarrierRateSuccess,
  [A.updateLoadDriverCarrierRateSuccess]: updateLoadDriverCarrierRateSuccess,
  [A.getLoadDriverCarrierRateListSuccess]: getLoadDriverCarrierRateListSuccess,
  // customer invoices
  [A.updateLoadCustomerInvoiceSuccess]: updateLoadCustomerInvoiceSuccess,
  [A.removeLoadCustomerInvoiceSuccess]: removeLoadCustomerInvoiceSuccess,
  [A.createLoadCustomerInvoiceSuccess]: createLoadCustomerInvoiceSuccess,
  [A.getLoadCustomerInvoiceListSuccess]: getLoadCustomerInvoiceListSuccess,
  [A.getLinkedCustomerInvoiceListSuccess]: getLinkedCustomerInvoiceListSuccess,
  // customer rates
  [A.selectLoadCustomerRateSuccess]: selectLoadCustomerRateSuccess,
  [A.removeLoadCustomerRateSuccess]: removeLoadCustomerRateSuccess,
  [A.updateLoadCustomerRateSuccess]: updateLoadCustomerRateSuccess,
  [A.createLoadCustomerRateSuccess]: createLoadCustomerRateSuccess,
  [A.getLoadCustomerRateListSuccess]: getLoadCustomerRateListSuccess,
  // external system
  [A.getExternalSystemListSuccess]: getExternalSystemListSuccess,
  [A.removeTelFromExternalSystemSuccess]: removeTelFromExternalSystemSuccess,
  // documents
  [A.removeLoadDocumentSuccess]: removeLoadDocumentSuccess,
  [A.updateLoadDocumentSuccess]: updateLoadDocumentSuccess,
  [A.getLoadDocumentListSuccess]: getLoadDocumentListSuccess,
  [A.setDocumentTemplatesToStore]: setDocumentTemplatesToStore,
  [A.getLoadLinkedOrdersDocumentListSuccess]: getLoadLinkedOrdersDocumentListSuccess,
  // message center
  [A.updateLoadNoteSuccess]: updateLoadNoteSuccess,
  [A.removeLoadNoteSuccess]: removeLoadNoteSuccess,
  [A.createLoadNoteSuccess]: createLoadNoteSuccess,
  [A.getLoadNoteListSuccess]: getLoadNoteListSuccess,
  [A.getLoadChatMessageListSuccess]: getLoadChatMessageListSuccess,
  [A.changeMessageCenterActiveTabSuccess]: changeMessageCenterActiveTabSuccess,
  // mails
  [A.getLoadMailListSuccess]: getLoadMailListSuccess,
  // notifications
  [A.hideLoadNotificationsSuccess]: hideLoadNotificationsSuccess,
  [A.getLoadNotificationListSuccess]: getLoadNotificationListSuccess,
  [A.markLoadNotificationAsReadSuccess]: markLoadNotificationAsReadSuccess,
  // payments
  [A.getLoadBillToSuccess]: getLoadBillToSuccess,
  // related/linked orders
  [setDataToListItemByPropName]: updateRelatedOrderList,
  [A.getLinkedOrderListSuccess]: getLinkedOrderListSuccess,
  [A.getRelatedOrderListRequest]: getRelatedOrderListRequest,
  [A.getRelatedOrderListSuccess]: getRelatedOrderListSuccess,
  // commission assignment
  [changeCommissionAssignmentSuccess]: changeLoadCommissionAssignment,
  // transportation mode grouping
  [A.getLoadTransportationModeGroupingListSuccess]: getLoadTransportationModeGroupingListSuccess,
  // claim
  [A.getClaimGeneralDetailsSuccess]: getClaimGeneralDetailsSuccess,
  // cross border integration
  [A.getIsAnyEnabledCrossBorderSuccess]: getIsAnyEnabledCrossBorderSuccess,
  [A.removeCrossBorderIntegrationSuccess]: removeCrossBorderIntegrationSuccess,
  [A.updateCrossBorderIntegrationSuccess]: updateCrossBorderIntegrationSuccess,
  [A.getTelCrossBorderIntegrationListSuccess]: getTelCrossBorderIntegrationListSuccess,
  [A.refreshTelCrossBorderIntegrationSuccess]: refreshTelCrossBorderIntegrationSuccess,
  // Sockets
  [socketTelRateReceived]: wSLoadRateReceivedSuccess,
  [updateNotificationsStore]: updateLoadNotificationStore,
  [socketCloStatusToTelReceived]: wsCloStatusToTelReceived,
  [socketTelChatMessageReceived]: wSLoadChatMessageSuccess,
  [socketTelDocumentReceived]: handleSocketTelDocumentReceived,
  [socketTelStatusMessageReceived]: wSTelStatusMessageReceived,
  [socketTelLoadStatusReceived]: handleWSLoadStatusTelReceived,
  [socketTelEventStatusReceived]: handleWSEventStatusTelReceived,
  [socketTelCostAllocationsReceived]: wSLoadCostAllocationsSuccess,
  [socketTelRateStatusReceived]: updateLoadDriverCarrierRateSuccess,
}, initialState);

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  lifecycle,
  withState,
  withProps,
  withHandlers,
  renderNothing,
  withPropsOnChange,
} from 'react-recompose';
// components
import { Table } from '../../components/table';
import { withTabs } from '../../components/tabs';
import EditReport from '../../components/edit-report';
import { TitlePanel } from '../../components/title-panel';
import { ConfirmComponent } from '../../components/confirm';
import { withPromptModal } from '../../components/edit-report/hocs';
import { transformPropDataFromSelectToString } from '../../components/edit-report/helpers';
// common
import { openModal, closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
import { makeSelectShowDriverOnboardingTab } from '../../common/selectors';
// features
import FleetMap from './tabs/map';
import PC from '../permission/role-permission';
import { AuthWrapper } from '../permission/index';
import { TrailerDashboards } from './tabs/dashboard';
import { makeSelectRoleAvailableList } from '../role/selectors';
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import { setExpandedContainerOptions } from '../expanded-container/actions';
import PivotTableWrapper from '../pivot-table/components/pivot-table-wrapper';
import { makeSelectAvailableReferenceTypesByScope } from '../reference/selectors';
import { EquipmentServiceTabs } from '../fleet/equipment-service/components/tabs';
import { makeSelectExpandedContainerOptions } from '../expanded-container/selectors';
import EquipmentServiceDocuments from '../fleet/equipment-service/components/documents';
import { withEquipmentServiceActions } from '../fleet/equipment-service/hocs/with-equipment-service-actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover, withShowListIssues } from '../../hocs';
// icons
import * as I from '../../svgs';
// ui
import { Box, IconWrapper, ZOrderWrapper } from '../../ui';
// feature fleet-list
import IftaForm from './components/ifta-form';
import RowActions from './components/row-actions';
import DriverTabs from './components/driver-tabs';
import TrucksListTabs from './components/trucks-list-tabs';
import TrailersListTabs from './components/trailers-list-tabs';
import FleetPageActions from './components/fleet-page-actions';
import SharedComponentForm from './components/shared-component-form';
import DriverOnboardingList from './components/driver-onboarding-list';
import PayrollAccessorialForm from './components/payroll-accessorial-form';
import PayrollAccessorialTabs from './components/payroll-accessorial-tabs';
import PayrollAccessorialDetails from './components/payroll-accessorial-details';
import { CreateFleetServiceIssueForm, EditFleetServiceIssueForm } from './components/fleet-service-issue-form';
import {
  getFreezedFieldByReportType,
  getColumnSettingsByReportType,
  getRemovePermissionByReportType,
  getWritePermissionsByReportTypeForElementActions,
  getWritePermissionsByReportTypeForTableActionButtons,
} from './settings';
import {
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectReportType,
  makeSelectFilterProps,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectSelectedList,
  makeSelectDocumentTypes,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectGlobalFilterValue,
  makeSelectTableTitleFilters,
  makeSelectAccessorialConfigs,
} from './selectors';
import {
  setReports,
  selectItem,
  setReportType,
  setUsedReport,
  setInitialState,
  removeItemRequest,
  toggleItemDetails,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setGlobalFilterValue,
  createReferenceRequest,
  createFleetItemRequest,
  updateFleetItemRequest,
  resetListAndPagination,
  exportReportDataRequest,
  setFleetProfileSettings,
  getConfigsByNamesRequest,
  changeDefaultReportRequest,
  sendListToIntegrationRequest,
  changeFleetServiceIssueStatusRequest,
} from './actions';
//////////////////////////////////////////////////

export const tableSettings = {
  minHeight: 320,
  cellFontSize: 13,
  titleFontSize: 14,
  titleRowHeight: 45,
  tableRowHeight: 40,
  allowEditBtn: true,
  useMainColors: true,
  allowSelectAll: true,
  moreBtnCellWidth: 50,
  searchableTitles: true,
};

const enhance = compose(
  withTabs,
  withFixedPopover,
  withShowListIssues({ getListRequestActionName: 'getItemListRequest' }),
  withState('originalReport', 'setOriginalReport', null),
  withState('savedReportState', 'setSavedReportState', {}),
  withState('reportFromPrompt', 'setReportFromPrompt', null),
  withHandlers({
    handleEdit: (props: Object) => (guid: string, entity: Object) => {
      const {
        openModal,
        reportType,
        openLoader,
        closeModal,
        closeLoader,
        currentBranchGuid,
        accessorialConfigs,
        updateFleetItemRequest,
        openedFromFleetProfile,
        setExpandedContainerOptions,
      } = props;

      if (G.notContain(
        reportType,
        [
          GC.FLEET_SERVICE_ISSUE_REPORT,
          GC.FLEET_SHARED_COMPONENT_REPORT,
          GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT,
          GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT,
        ],
      )) {
        const version = G.ifElse(
          G.getAmousConfigByNameFromWindow(GC.UI_USE_NEW_FLEET_PROFILE),
          'v2',
          'v1',
        );

        const componentMap = {
          v1: {
            [GC.FLEET_TRUCK_REPORT]: GC.PAGE_FLEET_TRUCK_PROFILE,
            [GC.FLEET_VENDOR_REPORT]: GC.PAGE_FLEET_VENDOR_PROFILE,
            [GC.FLEET_DRIVER_REPORT]: GC.PAGE_FLEET_DRIVER_PROFILE,
            [GC.FLEET_TRAILER_REPORT]: GC.PAGE_FLEET_TRAILER_PROFILE,
          },
          v2: {
            [GC.FLEET_TRUCK_REPORT]: GC.PAGE_FLEET_TRUCK_PROFILE_V2,
            [GC.FLEET_VENDOR_REPORT]: GC.PAGE_FLEET_VENDOR_PROFILE_V2,
            [GC.FLEET_DRIVER_REPORT]: GC.PAGE_FLEET_DRIVER_PROFILE_V2,
            [GC.FLEET_TRAILER_REPORT]: GC.PAGE_FLEET_TRAILER_PROFILE_V2,
          },
        };

        const componentType = R.path([version, reportType], componentMap);

        return setExpandedContainerOptions({
          opened: true,
          componentType,
          visitPageGuid: guid,
          options: { rowGuid: guid },
        });
      }

      const componentProps = {
        guid,
        closeModal,
        openLoader,
        closeLoader,
        branchGuid: currentBranchGuid,
        submitAction: updateFleetItemRequest,
      };

      const titleMap = {
        [GC.FLEET_SERVICE_ISSUE_REPORT]: ['titles:issue', 'Issue'],
        [GC.FLEET_SHARED_COMPONENT_REPORT]: ['titles:shared-component', 'Shared Component'],
        [GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT]: ['titles:payroll-accessorial', 'Payroll Accessorial'],
        [GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT]: ['titles:payroll-accessorial', 'Payroll Accessorial'],
      };

      let fleetEntityProps = {};

      if (R.equals(reportType, GC.FLEET_SERVICE_ISSUE_REPORT)) {
        const isTruck = G.isNotNilAndNotEmpty(G.getPropFromObject(GC.FIELD_TRUCK_GUID, entity));
        const entityType = G.ifElse(isTruck, GC.FIELD_TRUCK, GC.FIELD_TRAILER);

        fleetEntityProps = {
          isTruck,
          entityType,
          fleetEntityUnitId: G.getPropFromObject(`${entityType}.${GC.FIELD_UNIT_ID}`, entity),
          fleetEntityGuid: G.getPropFromObject(
            G.ifElse(isTruck, GC.FIELD_TRUCK_GUID, GC.FIELD_TRAILER_GUID),
            entity,
          ),
        };
      }

      const componentMap = {
        [GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT]: (
          <PayrollAccessorialForm {...componentProps} {...accessorialConfigs} />
        ),
        [GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT]: (
          <PayrollAccessorialForm
            {...componentProps}
            {...accessorialConfigs}
            isVendor={true}
            fleetVendorGuid={R.prop(GC.GRC.VENDOR_GUID, entity)}
          />
        ),
        [GC.FLEET_SHARED_COMPONENT_REPORT]: (
          <SharedComponentForm
            {...componentProps}
            configsNamesArray={[GC.FLEET_COMPONENT_TYPE, GC.FLEET_COMPONENT_MANUFACTURER]}
          />
        ),
        [GC.FLEET_SERVICE_ISSUE_REPORT]: (
          <EditFleetServiceIssueForm
            {...componentProps}
            {...fleetEntityProps}
            isEdit={true}
            openModal={openModal}
            openedFromFleetProfile={openedFromFleetProfile}
          />
        ),
      };

      const modal = {
        component: R.pathOr(null, [reportType], componentMap),
        p: G.ifElse(R.equals(reportType, GC.FLEET_SERVICE_ISSUE_REPORT), '0px', 15),
        options: {
          height: 'auto',
          title: G.getEditTitle(G.getPropFromObject(reportType, titleMap)),
        },
      };

      openModal(modal);
    },
    handleRemoveItems: (props: Object) => (guid: string) => {
      const { openModal, reportType, selectedList, removeItemRequest } = props;

      const isSingle = G.isString(guid);

      if (R.and(R.isEmpty(selectedList), R.isNil(guid))) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      let confirmLocaleArr = ['messages:confirm-delete-entities', 'Are you sure you want to delete these entities?'];

      if (isSingle) {
        const titleArrMap = {
          [GC.FLEET_DRIVER_REPORT]: ['messages:delete-confirmation-driver', 'Are you sure you want delete driver?'],
          [GC.FLEET_TRUCK_REPORT]: ['messages:truck-delete-confirmation-text', 'Are you sure you want delete truck?'],
          [GC.FLEET_SERVICE_ISSUE_REPORT]: [
            'messages:delete-confirmation-issue',
            'Are you sure you want to delete issue?'],
          [GC.FLEET_VENDOR_REPORT]: [
            'messages:delete-confirmation-text-vendor',
            'Are you sure you want delete vendor?',
          ],
          [GC.FLEET_DRIVER_ONBOARDING_REPORT]: [
            'messages:delete-confirmation-text-driver-onboarding',
            'Are you sure you want delete driver onboarding?',
          ],
          [GC.FLEET_SHARED_COMPONENT_REPORT]: [
            'messages:delete-confirmation-text-shared-component',
            'Are you sure you want delete shared component?',
          ],
          [GC.FLEET_TRAILER_REPORT]: [
            'messages:delete-confirmation-text-trailer',
            'Are you sure you want delete trailer?',
          ],
          [GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT]: [
            'messages:delete-confirmation-text-payroll-accessorial',
            'Are you sure you want delete payroll accessorial?',
          ],
          [GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT]: [
            'messages:delete-confirmation-text-payroll-accessorial',
            'Are you sure you want delete payroll accessorial?',
          ],
        };

        confirmLocaleArr = G.getPropFromObject(reportType, titleArrMap);
      }

      const title = G.getWindowLocale(...confirmLocaleArr);

      const component = <ConfirmComponent textLocale={title} />;

      const modal = {
        component,
        options: {
          width: 600,
          height: 100,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => removeItemRequest(G.ifElse(isSingle, R.of(Array, guid), selectedList)),
            },
          ],
        },
      };

      openModal(modal);
    },
    handleSetGlobalFilter: (props: Object) => (value: string) => {
      const {
        globalFilterValue,
        getItemListRequest,
        setGlobalFilterValue,
        resetListAndPagination,
      } = props;

      if (R.equals(globalFilterValue, value)) return;

      setGlobalFilterValue(value);
      resetListAndPagination();
      getItemListRequest();
    },
    handleGenerateIfta: (props: Object) => (byReport: boolean, truckGuids: string) => {
      const { openModal, closeModal, selectedReport, closeFixedPopup } = props;

      G.callFunction(closeFixedPopup);

      if (R.and(G.isNilOrEmpty(truckGuids), G.isFalse(byReport))) {
        const message = G.getWindowLocale('messages:select-truck', 'Please, select a Truck!');

        return G.showToastrMessageSimple('info', message);
      }

      const initialValues = G.ifElse(
        G.isTrue(byReport),
        R.pick(['fields', 'orderFields', 'searchCriteria'], selectedReport),
        { truckGuids },
      );
      const component = <IftaForm byReport={byReport} closeModal={closeModal} initialValues={initialValues} />;
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          maxWidth: '95vw',
          maxHeight: '95vh',
          width: 'fit-content',
          title: G.getWindowLocale('titles:generate-ifta', 'Generate IFTA'),
        },
      };

      openModal(modal);
    },
    handleCreateFleetServiceIssue: (props: Object) => (
      entityType: string,
      primaryObjectGuid: string,
      primaryObjectUnitId: string,
    ) => {
      const {
        openModal,
        closeModal,
        currentBranchGuid,
        openedFromFleetProfile,
        createFleetItemRequest,
        deactivateOnServiceIssue,
      } = props;

      const isTruck = R.equals(entityType, GC.FIELD_TRUCK);

      const initialValues = G.ifElse(
        G.isNilOrEmpty(primaryObjectGuid),
        null,
        { [G.ifElse(isTruck, GC.FIELD_TRUCK_GUID, GC.FIELD_TRAILER_GUID)]: primaryObjectGuid },
      );

      const component = (
        <CreateFleetServiceIssueForm
          isTruck={isTruck}
          openModal={openModal}
          closeModal={closeModal}
          entityType={entityType}
          initialValues={initialValues}
          branchGuid={currentBranchGuid}
          fleetEntityGuid={primaryObjectGuid}
          submitAction={createFleetItemRequest}
          fleetEntityUnitId={primaryObjectUnitId}
          openedFromFleetProfile={openedFromFleetProfile}
          deactivateOnServiceIssue={deactivateOnServiceIssue}
        />
      );

      const title = G.getAddTitle(
        G.ifElse(isTruck, ['titles:truck-issue', 'Truck Issue'], ['titles:trailer-issue', 'Trailer Issue']),
      );

      const modal = {
        p: '0px',
        component,
        options: { title, height: 'auto' },
      };

      openModal(modal);
    },
    getQuickFilteredListRequest: (props: Object) => () => {
      props.resetListAndPagination();
      props.getItemListRequest(true);
    },
    handleChangeReportParams: ({ setReportFromPrompt }: Object) => (data: Object) =>
      setReportFromPrompt(data),
    handleSetUsedReport: (props: Object) => () => {
      const {
        setUsedReport,
        selectedReport,
        setPromptStatus,
        reportFromPrompt,
        setOriginalReport,
        getItemListRequest,
      } = props;

      if (R.and(
        G.isNotNilAndNotEmpty(reportFromPrompt),
        G.notEquals(selectedReport, reportFromPrompt),
      )) {
        setOriginalReport(selectedReport);
        setUsedReport(reportFromPrompt);
        getItemListRequest();
      }

      setPromptStatus(false);
    },
    handleListRequest: ({ getItemListRequest }: Object) => () => getItemListRequest(),
    handleCleanFilter: ({ resetListAndPagination, getItemListRequest }: Object) => () => {
      resetListAndPagination();
      getItemListRequest();
    },
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const { reportList, setUsedReport, getItemListRequest } = props;

      const selectedReport = R.find(R.propEq(reportGuid, GC.FIELD_GUID), reportList);

      setUsedReport(selectedReport);
      getItemListRequest();
    },
    handleTableTitleFilter: G.handleTableTitleFilter,
    handleExportReportDataRequest: (props: Object) => (params: Object) => {
      const { callbackData, openedFromFleetProfile, exportReportDataRequest } = props;

      if (G.isTrue(openedFromFleetProfile)) {
        const { entityGuid, entityType, primaryObjectGuid } = R.or(callbackData, {});

        const searchCriteria = R.compose(
          R.append({
            dataType: 'string',
            operation: 'equal',
            stringValue: R.or(entityGuid, primaryObjectGuid),
            propertyName: G.ifElse(
              R.equals(entityType, GC.FIELD_TRUCK),
              GC.GRC.FLEET_TRUCK_GUID,
              GC.GRC.FLEET_TRAILER_GUID,
            ),
          }),
          R.pathOr([], [GC.FIELD_SEARCH_CRITERIA]),
        )(params);

        return exportReportDataRequest(R.assoc(GC.FIELD_SEARCH_CRITERIA, searchCriteria, params));
      }

      exportReportDataRequest(params);
    },
  }),
  withPromptModal(),
  branch(
    ({ reportType }: Object) => R.equals(reportType, GC.FLEET_EQUIPMENT_SERVICE_REPORT),
    withEquipmentServiceActions,
  ),
  withHandlers({
    handleClickEditIcon: (props: Object) => (e: Object, item: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <RowActions
            entity={item}
            openModal={props.openModal}
            reportType={props.reportType}
            openLoader={props.openLoader}
            closeModal={props.closeModal}
            closeLoader={props.closeLoader}
            guid={G.getGuidFromObject(item)}
            closeFixedPopup={props.closeFixedPopup}
            currentBranchGuid={props.currentBranchGuid}
            handleGenerateIfta={props.handleGenerateIfta}
            handleCreateRelatedTask={props.handleCreateRelatedTask}
            handleUpdateEquipmentService={props.handleUpdateEquipmentService}
            sendListToIntegrationRequest={props.sendListToIntegrationRequest}
          />
        ),
      })
    ),
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        reportType,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const hasIssues = R.includes(
        reportType,
        [
          GC.FLEET_TRUCK_REPORT,
          GC.FLEET_DRIVER_REPORT,
          GC.FLEET_TRAILER_REPORT,
        ],
      );
      const modalContent = (
        <EditReport
          fields={fields}
          hasIssues={hasIssues}
          setReport={setUsedReport}
          usedReport={selectedReport}
          hasNotifications={hasIssues}
          requestPending={requestPending}
          onReportSet={() => getItemListRequest()}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
        />
      );
      const modal = {
        component: modalContent,
        options: {
          version: 2,
          height: 'auto',
          maxWidth: '98vw',
          width: 'fit-content',
        },
      };

      openModal(modal);
    },
  }),
  branch(
    (props: Object) => {
      const { selectedReport, savedReportState, initialDataLoaded } = props;

      if (G.isNotNilAndNotEmpty(savedReportState)) return false;

      return R.or(
        R.not(initialDataLoaded),
        G.isNilOrEmpty(selectedReport),
      );
    },
    renderNothing,
  ),
  withProps(({ useSavedReportState = true }: Object) => ({ useSavedReportState })),
  withPropsOnChange(['savedReportState'], R.pathOr({}, ['savedReportState'])),
  lifecycle({
    componentDidUpdate(prevProps: Object) {
      const props = this.props;

      const {
        activeTab,
        reportType,
        setReportType,
        getItemListRequest,
        setSavedReportState,
        resetListAndPagination,
        setFleetProfileSettings,
        expandedContainerOptions,
        getConfigsByNamesRequest,
      } = props;

      const { opened, options, visitPageGuid } = R.pathOr({}, ['expandedContainerOptions'], prevProps);

      if (G.isAllTrue(
        G.isTrue(opened),
        G.isZero(activeTab),
        G.isFalse(expandedContainerOptions.opened),
      )) {
        setSavedReportState({});
        setReportType(R.path(['savedReportState', 'reportType'], props));

        if (R.includes(reportType, [
          GC.FLEET_SERVICE_ISSUE_REPORT,
          GC.FLEET_SHARED_COMPONENT_REPORT,
          GC.FLEET_EQUIPMENT_SERVICE_REPORT,
        ])) {
          resetListAndPagination();
          setFleetProfileSettings();

          if (R.equals(reportType, GC.FLEET_EQUIPMENT_SERVICE_REPORT)) {
            getConfigsByNamesRequest();
          }

          return getItemListRequest();
        }

        const guid = R.propOr(visitPageGuid, 'rowGuid', options);

        if (G.isNilOrEmpty(guid)) return;

        getItemListRequest({ guids: R.of(Array, guid) });
      } else if (R.and(G.isFalse(opened), G.isTrue(expandedContainerOptions.opened))) {
        setSavedReportState(R.pick(
          [
            'loading',
            'itemList',
            'reportList',
            'reportType',
            'totalCount',
            'selectedReport',
            'titleSortValues',
            'globalFilterValue',
            'tableTitleFilters',
          ],
          props,
        ));
      }
    },
    componentWillUnmount() {
      const { originalReport, setUsedReport, selectedReport } = this.props;

      if (R.equals(G.getGuidFromObject(originalReport), G.getGuidFromObject(selectedReport))) {
        setUsedReport(originalReport);
      }
    },
  }),
  pure,
);

const renderExpandedDetailsComponent = ({ props, reportType, parentProps }: Object) => {
  if (R.equals(reportType, GC.FLEET_DRIVER_ONBOARDING_REPORT)) {
    return (
      <DriverOnboardingList
        {...parentProps}
        driverOnboardingGuid={G.getGuidFromObject(parentProps)}
        itemList={R.pathOr([], [GC.FIELD_DRIVERS], parentProps)}
      />
    );
  }

  if (R.equals(reportType, GC.FLEET_EQUIPMENT_SERVICE_REPORT)) {
    const isEntityTypeTruck = G.isNotNil(G.getPropFromObject(GC.GRC.FLEET_TRUCK_GUID, parentProps));
    const entityType = G.ifElse(isEntityTypeTruck, GC.FIELD_TRUCK, GC.FIELD_TRAILER);
    const documentTypeDropdownName = G.ifElse(isEntityTypeTruck, GC.TRUCK_DOCUMENT_TYPE, GC.TRAILER_DOCUMENT_TYPE);

    return (
      <EquipmentServiceDocuments
        entityType={entityType}
        primaryObjectGuid={G.getGuidFromObject(parentProps)}
        disabled={R.pathOr(false, ['disabled'], parentProps)}
        documentList={R.pathOr([], ['details'], parentProps)}
        documentTypeOptions={R.pathOr([], ['documentTypes', documentTypeDropdownName], props)}
        {...R.pick(['openModal', 'closeModal', 'openLoader', 'closeLoader'], props)}
      />
    );
  }

  if (R.includes(
    reportType,
    [GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT, GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT],
  )) {
    return (
      <PayrollAccessorialDetails
        {...parentProps}
        itemList={R.prop('details', parentProps)}
        payrollAssessorialGuid={G.getGuidFromObject(parentProps)}
        isVendor={R.equals(reportType, GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT)}
      />
    );
  }

  return null;
};

const getCheckboxCellWidthByReportType = (reportType: string, showIssues: boolean) => {
  if (showIssues) return 111;

  const map = {
    [GC.FLEET_SERVICE_ISSUE_REPORT]: 54,
    [GC.FLEET_SHARED_COMPONENT_REPORT]: 54,
    [GC.FLEET_DRIVER_ONBOARDING_REPORT]: 93,
    [GC.FLEET_EQUIPMENT_SERVICE_REPORT]: 27,
    [GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT]: 93,
    [GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT]: 93,
  };

  return R.pathOr(90, [reportType], map);
};

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    reportType,
    selectItem,
    handleEdit,
    pagination,
    reportList,
    filterProps,
    callbackData,
    filterParams,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    toggleItemDetails,
    handleRemoveItems,
    getItemListRequest,
    handleClickEditIcon,
    handleShowListIssues,
    handleTableTitleFilter,
  } = props;

  if (R.not(selectedReport)) return null;

  const elementActionsComponent = (item: Object) => {
    const isReportWithoutActions = R.includes(
      reportType,
      [
        GC.FLEET_DRIVER_ONBOARDING_REPORT,
        GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT,
        GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT,
      ],
    );

    const isServiceIssueWithWorkOrder = R.and(
      R.equals(reportType, GC.FLEET_SERVICE_ISSUE_REPORT),
      G.isNotNilAndNotEmpty(G.getPropFromObject(GC.FIELD_WORK_ORDER_GUID, item)),
    );

    if (R.or(isReportWithoutActions, isServiceIssueWithWorkOrder)) return null;

    const permissions = getWritePermissionsByReportTypeForElementActions({ item, props, reportType });

    return (
      <AuthWrapper has={permissions}>
        <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, item)}>
          {I.threeDots()}
        </IconWrapper>
      </AuthWrapper>
    );
  };

  const allChecked = G.isAllChecked(itemList);

  const allowSelect = G.notContain(
    reportType,
    [
      GC.FLEET_SERVICE_ISSUE_REPORT,
      GC.FLEET_SHARED_COMPONENT_REPORT,
      GC.FLEET_EQUIPMENT_SERVICE_REPORT,
      GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT,
      GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT,
    ],
  );

  const tableSettingsToUse = {
    ...G.getTableSettingsWithMaxHeightByConditions({
      reportList,
      filterParams,
      tableSettings,
      selectedReport,
    }),
    allowSelectAll: allowSelect,
    allowSelectItems: allowSelect,
    checkBoxCellJustifyContent: 'flex-start',
    checkBoxCellWidth: getCheckboxCellWidthByReportType(reportType, selectedReport.showIssues),
    expandedDetailsComponent: ({ parentProps, callbackData }: Object) =>
      renderExpandedDetailsComponent({ props, reportType, parentProps, callbackData }),
    expandableItems: R.includes(
      reportType,
      [
        GC.FLEET_DRIVER_ONBOARDING_REPORT,
        GC.FLEET_EQUIPMENT_SERVICE_REPORT,
        GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT,
        GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT,
      ],
    ),
  };

  let report = selectedReport;
  const freezedField = getFreezedFieldByReportType(reportType);

  if (G.isNotNilAndNotEmpty(freezedField)) {
    report = G.prependFieldToTableReportFields(selectedReport, freezedField);
  }

  const actionButtons = [
    {
      iconName: 'pencil',
      action: handleEdit,
      getPermissions: (item: Object) => getWritePermissionsByReportTypeForTableActionButtons({ item, reportType }),
    },
    {
      iconName: 'trash',
      action: handleRemoveItems,
      getPermissions: (item: Object) => getRemovePermissionByReportType({ item, reportType }),
    },
  ];

  const data = {
    report,
    loading,
    itemList,
    allChecked,
    totalCount,
    pagination,
    titleSortValues,
    tableTitleFilters,
    handleShowListIssues,
    handleTableTitleFilter,
    toggle: toggleItemDetails,
    onOptionClick: selectItem,
    hasSelectable: allowSelect,
    withResizableColumns: true,
    useNewTitleFilterInputs: true,
    renderRightStickedComponent: elementActionsComponent,
    columnSettings: getColumnSettingsByReportType(reportType),
    tableSettings: R.pathOr(tableSettingsToUse, ['tableSettings'], props),
    callbackData: G.ifElse(G.isNotNilAndNotEmpty(callbackData), callbackData, props),
    filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(filterProps)),
    actionButtons: G.ifElse(G.notEquals(reportType, GC.FLEET_EQUIPMENT_SERVICE_REPORT), actionButtons),
    handleLoadMoreEntities: G.ifElse(
      loading,
      () => {},
      () => getItemListRequest(),
    ),
  };

  return <Table {...data} />;
};

const titlesMap = {
  [GC.FLEET_EQUIPMENT_SERVICE_REPORT]: [],
  [GC.FLEET_SERVICE_ISSUE_REPORT]: ['titles:fleet:issue', 'Fleet: Issue'],
  [GC.FLEET_TRAILER_REPORT]: ['titles:fleet:trailer-edit', 'Fleet: Trailer'],
  [GC.FLEET_TRUCK_REPORT]: ['titles:fleet-list-of-trucks', 'Fleet: List of Trucks'],
  [GC.FLEET_DRIVER_REPORT]: ['titles:fleet-list-of-drivers', 'Fleet: List of Drivers'],
  [GC.FLEET_VENDOR_REPORT]: ['titles:fleet-list-of-vendors', 'Fleet: List of Vendors'],
  [GC.FLEET_SHARED_COMPONENT_REPORT]: ['titles:fleet-shared-component', 'Fleet: Shared Components'],
  [GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT]: [
    'titles:fleet-driver-payroll-accessorial',
    'Fleet Driver Payroll Accessorial',
  ],
  [GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT]: [
    'titles:fleet-vendor-payroll-accessorial',
    'Fleet Vendor Payroll Accessorial',
  ],
};

const reportsWithoutExport = [
  GC.FLEET_VENDOR_REPORT,
  GC.FLEET_SHARED_COMPONENT_REPORT,
  GC.FLEET_DRIVER_ONBOARDING_REPORT,
  GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT,
  GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT,
];

const reportsWithoutGlobalSearch = [
  ...reportsWithoutExport,
  GC.FLEET_SERVICE_ISSUE_REPORT,
  GC.FLEET_EQUIPMENT_SERVICE_REPORT,
];

const renderCustomTitleComponent = (props: Object) => {
  const { totalCount, reportType, customTitleComponent, showDriverOnboardingTab } = props;

  if (G.isNotNilAndNotEmpty(customTitleComponent)) {
    if (G.isFunction(customTitleComponent)) return customTitleComponent(props);

    return customTitleComponent;
  }

  if (R.equals(reportType, GC.FLEET_EQUIPMENT_SERVICE_REPORT)) {
    const { activeTab, callbackData, handleSetActiveTab, openedFromFleetProfile } = props;

    const { entityType, entityGuid } = R.or(callbackData, {});

    return (
      <EquipmentServiceTabs
        activeTab={activeTab}
        entityType={entityType}
        totalCount={totalCount}
        entityGuid={entityGuid}
        handleSetActiveTab={handleSetActiveTab}
        openedFromFleetProfile={openedFromFleetProfile}
      />
    );
  }

  if (R.equals(reportType, GC.FLEET_TRAILER_REPORT)) {
    const { activeTab, handleSetActiveTab } = props;

    return (
      <TrailersListTabs
        activeTab={activeTab}
        totalCount={totalCount}
        handleSetActiveTab={handleSetActiveTab}
      />
    );
  }

  if (R.equals(reportType, GC.FLEET_TRUCK_REPORT)) {
    const { activeTab, handleSetActiveTab } = props;

    return (
      <TrucksListTabs
        activeTab={activeTab}
        totalCount={totalCount}
        handleSetActiveTab={handleSetActiveTab}
      />
    );
  }

  if (R.and(
    G.isTrue(showDriverOnboardingTab),
    R.includes(reportType, [GC.FLEET_DRIVER_REPORT, GC.FLEET_DRIVER_ONBOARDING_REPORT]),
  )) return <DriverTabs totalCount={totalCount} reportType={reportType} />;

  if (G.isAllTrue(
    G.hasAmousCurrentUserPermissions(PC.FLEET_VENDOR_WRITE),
    G.hasAmousCurrentUserPermissions(PC.FLEET_DRIVER_WRITE),
    R.includes(
      reportType,
      [
        GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT,
        GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT,
      ],
    ),
  )) return <PayrollAccessorialTabs totalCount={totalCount} />;

  return null;
};

const FleetList = (props: Object) => {
  const {
    refList,
    openModal,
    activeTab,
    reportType,
    closeModal,
    openLoader,
    closeLoader,
    selectedList,
    selectedReport,
    currentBranchGuid,
    handleRemoveItems,
    handleGenerateIfta,
    accessorialConfigs,
    withoutPageActions,
    openMaintenanceForm,
    openedFromFleetProfile,
    createReferenceRequest,
    sendListToIntegrationRequest,
    handleExportReportDataRequest,
    handleCreateFleetServiceIssue,
  } = props;

  const customTitleComponent = renderCustomTitleComponent(props);

  if (R.and(R.equals(activeTab, 1), R.equals(reportType, GC.FLEET_EQUIPMENT_SERVICE_REPORT))) {
    return (
      <PivotTableWrapper
        {...props}
        pl='0px'
        tabs={customTitleComponent}
        pivotTableMaxHeight={G.ifElse(openedFromFleetProfile, 600, null)}
        p={`${G.ifElse(openedFromFleetProfile, 15, 30)}px 15px 60px 0px`}
        footerPosition={G.ifElse(openedFromFleetProfile, 'unset', 'fixed')}
        listWrapperStyles={G.ifElse(openedFromFleetProfile, { height: 'auto' }, {})}
      />
    );
  }

  if (R.and(
    R.equals(activeTab, 1),
    R.equals(reportType, GC.FLEET_TRAILER_REPORT),
  )) {
    return <TrailerDashboards {...props} tabs={customTitleComponent} />;
  }

  if (R.or(
    R.and(
      R.equals(activeTab, 2),
      R.equals(reportType, GC.FLEET_TRAILER_REPORT),
    ),
    R.and(
      R.equals(activeTab, 1),
      R.equals(reportType, GC.FLEET_TRUCK_REPORT),
    ),
  )) {
    return <FleetMap {...props} tabs={customTitleComponent} />;
  }

  return (
    <Box p='15px 10px 15px 0' overflowX={G.ifElse(openedFromFleetProfile, 'auto', 'unset')}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          withCount={true}
          type={reportType}
          popperWithCount={true}
          withoutQuickFilter={true}
          usedReport={selectedReport}
          hiddenRightFilterInfo={false}
          selectReportFormName='fleetReport'
          customTitleComponent={customTitleComponent}
          exportReportDataRequest={handleExportReportDataRequest}
          noExportable={R.includes(reportType, reportsWithoutExport)}
          title={G.getWindowLocale(...R.pathOr([], [reportType], titlesMap))}
          withGlobalSearchFilter={G.notContain(reportType, reportsWithoutGlobalSearch)}
          refList={G.ifElse(R.equals(reportType, GC.FLEET_DRIVER_ONBOARDING_REPORT), [], refList)}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex='1'>
        {renderTable(props)}
      </ZOrderWrapper>
      {
        R.not(withoutPageActions) &&
        <FleetPageActions
          openModal={openModal}
          reportType={reportType}
          closeModal={closeModal}
          openLoader={openLoader}
          closeLoader={closeLoader}
          selectedList={selectedList}
          selectedReport={selectedReport}
          handleRemoveItems={handleRemoveItems}
          currentBranchGuid={currentBranchGuid}
          accessorialConfigs={accessorialConfigs}
          handleGenerateIfta={handleGenerateIfta}
          openMaintenanceForm={openMaintenanceForm}
          createReferenceRequest={createReferenceRequest}
          sendListToIntegrationRequest={sendListToIntegrationRequest}
          handleCreateFleetServiceIssue={handleCreateFleetServiceIssue}
        />
      }
    </Box>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  reportType: makeSelectReportType(state),
  filterProps: makeSelectFilterProps(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  selectedList: makeSelectSelectedList(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  documentTypes: makeSelectDocumentTypes(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  globalFilterValue: makeSelectGlobalFilterValue(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
  rolesAvailableList: makeSelectRoleAvailableList(state),
  accessorialConfigs: makeSelectAccessorialConfigs(state),
  refList: makeSelectAvailableReferenceTypesByScope(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  showDriverOnboardingTab: makeSelectShowDriverOnboardingTab(state),
  expandedContainerOptions: makeSelectExpandedContainerOptions(state),
});

export default connect(mapStateToProps, {
  openModal,
  setReports,
  selectItem,
  closeModal,
  openLoader,
  closeLoader,
  setReportType,
  setUsedReport,
  setInitialState,
  setTableTitleSort,
  toggleItemDetails,
  removeItemRequest,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setGlobalFilterValue,
  createReferenceRequest,
  createFleetItemRequest,
  updateFleetItemRequest,
  resetListAndPagination,
  exportReportDataRequest,
  setFleetProfileSettings,
  getConfigsByNamesRequest,
  changeDefaultReportRequest,
  setExpandedContainerOptions,
  sendListToIntegrationRequest,
  changeFleetServiceIssueStatusRequest,
})(enhance(FleetList));

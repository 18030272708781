// helpers/constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const generalSection = [
  {
    title: 'titles:general',
    fields: {
      [GC.FLEET_GENERAL_INSPECTION_ID_AUTOGENERATED]: {
        type: 'switcher',
        name: 'titles:auto-inspection-id',
        nameForAttribute: GC.FLEET_GENERAL_INSPECTION_ID_AUTOGENERATED,
      },
      [GC.FLEET_GENERAL_INSPECTION_ID_SEQUENCE]: {
        type: 'select',
        options: 'sequences',
        name: 'titles:inspection-id-sequence',
        nameForAttribute: GC.FLEET_GENERAL_INSPECTION_ID_SEQUENCE,
      },
      [GC.FLEET_GENERAL_WORK_ORDER_NAME_AUTOGENERATED]: {
        type: 'switcher',
        name: 'titles:auto-work-order-name',
        nameForAttribute: GC.FLEET_GENERAL_WORK_ORDER_NAME_AUTOGENERATED,
      },
      [GC.FLEET_GENERAL_WORK_ORDER_NAME_SEQUENCE]: {
        type: 'select',
        options: 'sequences',
        name: 'titles:work-order-name-sequence',
        nameForAttribute: GC.FLEET_GENERAL_WORK_ORDER_NAME_SEQUENCE,
      },
    },
  },
  {
    fields: [],
    configType: 'dropdown',
    editPopupTitle: 'titles:edit',
    title: 'titles:geo-fencing-location-type',
    configName: GC.FLEET_GENERAL_GEO_FENCING_LOCATION_TYPE,
  },
];


const maintenanceSection = [
  {
    title: 'titles:service',
    fields: {
      [GC.FLEET_GENERAL_EQUIPMENT_SERVICE_ID_AUTOGENERATED]: {
        type: 'switcher',
        name: 'titles:auto-service-id',
        nameForAttribute: GC.FLEET_GENERAL_EQUIPMENT_SERVICE_ID_AUTOGENERATED,
      },
      [GC.FLEET_GENERAL_EQUIPMENT_SERVICE_ID_SEQUENCE]: {
        type: 'select',
        options: 'sequences',
        name: 'titles:service-id-sequence',
        nameForAttribute: GC.FLEET_GENERAL_EQUIPMENT_SERVICE_ID_SEQUENCE,
      },
    },
  },
  {
    fields: [],
    configType: 'dropdown',
    title: 'titles:service-type',
    editPopupTitle: 'titles:edit',
    configName: GC.FLEET_EQUIPMENT_SERVICE_TYPE,
  },
  {
    fields: [],
    configType: 'dropdown',
    editPopupTitle: 'titles:edit',
    title: 'titles:component-type',
    configName: GC.FLEET_COMPONENT_TYPE,
  },
  {
    fields: [],
    configType: 'dropdown',
    editPopupTitle: 'titles:edit',
    title: 'titles:component-manufacturer',
    configName: GC.FLEET_COMPONENT_MANUFACTURER,
  },
];

const issueSection = [
  {
    title: 'titles:issue',
    fields: {
      [GC.FLEET_GENERAL_SERVICE_ISSUE_ID_AUTOGENERATED]: {
        type: 'switcher',
        name: 'titles:auto-issue-id',
        nameForAttribute: GC.FLEET_GENERAL_SERVICE_ISSUE_ID_AUTOGENERATED,
      },
      [GC.FLEET_GENERAL_SERVICE_ISSUE_ID_SEQUENCE]: {
        type: 'select',
        options: 'sequences',
        name: 'titles:issue-id-sequence',
        nameForAttribute: GC.FLEET_GENERAL_SERVICE_ISSUE_ID_SEQUENCE,
      },
      [GC.FLEET_GENERAL_SERVICE_ISSUE_AUTO_ASSIGN_SERVICE_VENDOR]: {
        type: 'switcher',
        name: 'titles:issue-auto-assign-service-vendor',
        nameForAttribute: GC.FLEET_GENERAL_SERVICE_ISSUE_AUTO_ASSIGN_SERVICE_VENDOR,
      },
    },
  },
  {
    fields: [],
    configType: 'dropdown',
    title: 'titles:issue-type',
    editPopupTitle: 'titles:edit',
    configName: GC.FLEET_GENERAL_SERVICE_ISSUE_TYPE,
  },
  {
    fields: [],
    configType: 'dropdown',
    editPopupTitle: 'titles:edit',
    title: 'titles:issue-sub-type',
    configName: GC.FLEET_GENERAL_SERVICE_ISSUE_SUB_TYPE,
  },
];

const ConfigFleetGroup = {
  generalSection,
  maintenanceSection,
  issueSection,
};

export {
  ConfigFleetGroup,
};

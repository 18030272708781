// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const allEventFields = [
  {
    label: G.getWindowLocale('titles:location-type', 'Location Type'),
    value: `${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_LOCATION_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
  },
  {
    value: `${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_TEMPLATE_ID}`,
    label: G.getWindowLocale('titles:template-id', 'Template ID'),
  },
  {
    value: `${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_LOCATION_NAME}`,
    label: G.getWindowLocale('titles:location-name', 'Location Name'),
  },
  {
    value: GC.FIELD_STOP_NUMBER,
    label: G.getWindowLocale('titles:pick-up-number', 'Pick Up Number'),
  },
  {
    label: G.getWindowLocale('titles:address1', 'Address1'),
    value: `${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_ADDRESS_1}`,
  },
  {
    label: G.getWindowLocale('titles:address2', 'Address2'),
    value: `${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_ADDRESS_2}`,
  },
  {
    label: G.getWindowLocale('titles:city', 'City'),
    value: `${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_CITY}`,
  },
  {
    label: G.getWindowLocale('titles:state', 'State'),
    value: `${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_STATE}`,
  },
  {
    label: G.getWindowLocale('titles:country', 'Country'),
    value: `${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_COUNTRY}`,
  },
  {
    label: G.getWindowLocale('titles:zip', 'Zip'),
    value: `${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_ZIP}`,
  },
  {
    value: GC.FIELD_EVENT_COMPLETE_DATE,
    label: G.getWindowLocale('titles:complete-date', 'Complete Date'),
  },
  {
    value: GC.FIELD_EARLY_DATE,
    label: G.getWindowLocale('titles:early-date', 'Early Date'),
  },
  {
    value: GC.FIELD_LATE_DATE,
    label: G.getWindowLocale('titles:late-date', 'Late Date'),
  },
  {
    value: GC.FIELD_EVENT_CHECK_IN_DATE,
    label: G.getWindowLocale('titles:check-in-date', 'Check In Date'),
  },
  {
    value: GC.FIELD_ARRIVE_DATE,
    label: G.getWindowLocale('titles:arrive-date', 'Arrive Date'),
  },
];

export default allEventFields;

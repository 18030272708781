import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
import { ENUMS } from '../../../../../constants/enums';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 300,
};

const fields = [
  {
    type: 'select',
    inputWrapperStyles,
    options: 'scopeOptions',
    fieldName: GC.FIELD_SCOPE,
    label: ['titles:scope', 'Scope'],
  },
  {
    type: 'select',
    inputWrapperStyles,
    options: 'defaultNameOptions',
    fieldName: GC.FIELD_DEFAULT_NAME,
    label: ['titles:default-name', 'Default Name'],
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_NAME,
    label: ['titles:name', 'Name'],
  },
];

export const scopeOptions = [
  GC.EMPTY_OPTION_OBJECT,
  {
    value: ENUMS.ENUM_DRIVER_PAYROLL,
    label: G.getWindowLocale('titles:driver-payroll', 'Driver Payroll'),
  },
  {
    value: ENUMS.ENUM_VENDOR_PAYROLL,
    label: G.getWindowLocale('titles:titles:vendor-payroll', 'Vendor Payroll'),
  },
];

const commonDefaultNameOptions = [
  GC.EMPTY_OPTION_OBJECT,
  {
    value: ENUMS.ENUM_FUEL,
    label: G.getEnumLocale(ENUMS.ENUM_FUEL),
  },
  {
    value: ENUMS.ENUM_TOLL,
    label: G.getEnumLocale(ENUMS.ENUM_TOLL),
  },
  {
    value: ENUMS.ENUM_DRIVER_EXPENSE,
    label: G.getEnumLocale(ENUMS.ENUM_DRIVER_EXPENSE),
  },
];

const driverDefaultNameOptions = [
  ...commonDefaultNameOptions,
  {
    value: ENUMS.ENUM_ADVANCED_PAYMENT,
    label: G.getEnumLocale(ENUMS.ENUM_ADVANCED_PAYMENT),
  },
  {
    value: ENUMS.ENUM_TRIP_PAY,
    label: G.getEnumLocale(ENUMS.ENUM_TRIP_PAY),
  },
];

const vendorDefaultNameOptions = [
  ...commonDefaultNameOptions,
  {
    value: ENUMS.ENUM_DRIVER_PAYROLL,
    label: G.getEnumLocale(ENUMS.ENUM_DRIVER_PAYROLL),
  },
];

const getOptionsForSelect = (props: Object) => {
  const { values } = props;

  const scope = G.getPropFromObject(GC.FIELD_SCOPE, values);

  const isDriverScope = R.equals(scope, ENUMS.ENUM_DRIVER_PAYROLL);

  const defaultNameOptions = isDriverScope ? driverDefaultNameOptions : vendorDefaultNameOptions;

  return { scopeOptions, defaultNameOptions };
};

const defaultFields = {
  [GC.FIELD_NAME]: '',
  [GC.FIELD_SCOPE]: '',
  [GC.FIELD_DEFAULT_NAME]: '',
};

const fieldRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

const validationSchema = Yup.object().shape({
  [GC.FIELD_NAME]: fieldRequired,
  [GC.FIELD_SCOPE]: fieldRequired,
  [GC.FIELD_DEFAULT_NAME]: fieldRequired,
});

const enhance = compose(
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
  }),
  pure,
);

const QBIIFAssessorialMappingForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      {...getOptionsForSelect(props)}
      fields={fields}
      fieldsWrapperStyles={{ pt: 11, flexDirection: 'column' }}
    />
    <FormFooter />
  </form>
);

export default enhance(QBIIFAssessorialMappingForm);

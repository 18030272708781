import React from 'react';
import * as R from 'ramda';
// components
import { Tabs2 } from '../../../components/tabs-mui';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

const getTabs = (activeTab: number) => [
  {
    value: 0,
    withCount: R.equals(activeTab, 0),
    text: G.getWindowLocale('titles:trucks', 'Trucks'),
  },
  {
    value: 1,
    withCount: R.equals(activeTab, 1),
    text: G.getWindowLocale('titles:map', 'Map'),
  },
];

const TrucksListTabs = (props: Object) => {
  const { activeTab, totalCount, handleSetActiveTab } = props;

  const isList = R.equals(activeTab, 0);

  const handleClickTab = (i: number) => {
    handleSetActiveTab(i);
  };

  return (
    <Tabs2
      activeTab={activeTab}
      tabs={getTabs(activeTab)}
      setActiveTab={handleClickTab}
      count={G.ifElse(isList, totalCount, null)}
    />
  );
};

export default TrucksListTabs;

import * as R from 'ramda';
import { createSelector } from 'reselect';
// report-common
import { getReportSelectors } from '../../report-common';
//////////////////////////////////////////////////

const {
  store,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectUsedReport,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectReportStatus,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} = getReportSelectors('inspections');

const makeSelectItemList = () => createSelector(
  store,
  ({ itemList }: Object) => R.sortBy(R.prop('index'), R.values(itemList)),
);

const makeSelectFleetProfileSettings = () => createSelector(
  store,
  ({ fleetProfileSettings }: Object) => fleetProfileSettings,
);

export {
  makeSelectItemList,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectUsedReport,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectReportStatus,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  makeSelectFleetProfileSettings,
};

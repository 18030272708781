import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
// ui
import { Box } from '../../../../../ui';
// feature config
import {
  distanceCalculatorConfigFieldSettings,
  defaultDistanceCalculatorConfigFields,
  getDistanceCalculatorConfigValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultDistanceCalculatorConfigFields,
      props.initialValues,
    ),
    validationSchema: ({ isEditMode }: Object) => Yup.lazy(
      ({ integrationType }: Object) => Yup.object().shape(
        getDistanceCalculatorConfigValidationSchema(isEditMode, integrationType),
      ),
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction } = props;
      submitAction(values);
    },
  }),
  pure,
);

const DistanceCalculatorConfigForm = (props: Object) => {
  const {
    isEditMode,
    handleSubmit,
    optionsForSelect,
  } = props;

  const formikProps = G.getFormikProps(props);

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...formikProps}
        isEditMode={isEditMode}
        fieldsWrapperStyles={{ pt: 11 }}
        optionsForSelect={optionsForSelect}
        fields={distanceCalculatorConfigFieldSettings}
        handlers={{ handleDisableIntegrationType: () => isEditMode }}
      />
      <FormFooter />
    </form>
  );
};

export default enhance(DistanceCalculatorConfigForm);

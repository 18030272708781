import React from 'react';
import * as R from 'ramda';
// components
import {
  getEventTypeOptions,
  getStopStatusOptions,
  getTripStatusOptions,
  getOrderStatusOptions,
} from '../../../components/filter/settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  ITEM_TYPE_OPTIONS,
  ITEM_DIMENSIONS_OPTIONS,
  ITEM_ODOMETER_UOM_OPTIONS,
  ITEM_TEMPERATURE_TYPE_OPTIONS,
} from '../../../helpers/options';
// features
import { TableField, UomLocalesTableField } from '../../dispatch-report/components/table-fields';
// ui
import { Box } from '../../../ui';
// feature dispatch-planner-events
import { ADDED_TO_INTER_TERMINAL } from '../constants';
//////////////////////////////////////////////////

export const getColumnSettings = ({ handleAddToInterTerminal }: Object) => ({
  [ADDED_TO_INTER_TERMINAL]: {
    width: 25,
    withoutTitle: true,
    customComponent: ({ data }: Object) => {
      if (R.or(
        G.isStopPickup(data),
        R.isNil(R.prop(GC.GRC.LAST_TERMINAL_DROP_TEMPLATE_ID, data)),
      )) return null;
      return (
        <Box
          p='8px'
          cursor='pointer'
          color={G.getTheme('colors.light.blue')}
          onClick={() => handleAddToInterTerminal(data)}
          bg={G.ifElse(data.isInPlanner, G.getTheme('colors.light.teaGreen'))}
          title={G.getWindowLocale('titles:add-to-inter-terminal', 'Add To Inter-Terminal ')}
        >
          {G.getWindowLocale('titles:it', 'IT')}
        </Box>
      );
    },
  },
  [GC.GRC.TEL_PRIMARY_REF_VALUE]: {
    width: 200,
    searchable: true,
    name: ['titles:tel', 'titles:primary-ref-value'],
  },
  [GC.GRC.TEL_STATUS]: {
    width: 200,
    type: 'enum',
    searchable: true,
    name: ['titles:tel', 'titles:status'],
  },
  [GC.FIELD_EVENT_TYPE]: {
    width: 200,
    type: 'enum',
    searchable: true,
    name: 'titles:event-type',
  },
  [GC.GRC.CLO_PRIMARY_REFERENCE_VALUE]: {
    width: 200,
    searchable: true,
    useExpandedContainer: true,
    guidPropName: GC.FIELD_CLO_GUID,
    type: GC.PAGE_DISPATCH_DETAILS_NEW_ORDER,
    name: ['titles:clo', 'titles:primary-ref-value'],
  },
  [GC.GRC.CLO_STATUS]: {
    width: 180,
    type: 'enum',
    searchable: true,
    name: ['titles:clo', 'titles:status'],
  },
  [GC.GRC.CLO_BRANCH_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:branch'],
  },
  [GC.GRC.CLO_CREATED_BY]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:created-by'],
  },
  [GC.GRC.CLO_CREATED_DATE]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:created-date'],
    customComponent: ({ data }: Object) =>
      G.createLocalDateTimeFromInstanceOrISOString(
        data[GC.GRC.CLO_CREATED_DATE],
        G.getDateTimeFormat(true),
      ),
  },
  [GC.FIELD_STATUS]: {
    width: 200,
    type: 'enum',
    searchable: true,
    name: 'titles:status',
  },
  [GC.FIELD_EARLY_DATE]: {
    width: 200,
    searchable: true,
    name: 'titles:early-date',
  },
  [GC.FIELD_LATE_DATE]: {
    width: 200,
    searchable: true,
    name: 'titles:late-date',
  },
  [GC.GRC.LOCATION_TEMPLATE_ID]: {
    width: 200,
    searchable: true,
    name: 'titles:template-id',
  },
  [GC.GRC.LOCATION_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:location-name',
  },
  [GC.GRC.LOCATION_TYPE]: {
    width: 200,
    searchable: true,
    name: 'titles:location-type',
  },
  [GC.GRC.LOCATION_COUNTRY]: {
    width: 200,
    searchable: true,
    name: 'titles:country',
  },
  [GC.GRC.LOCATION_STATE]: {
    width: 200,
    searchable: true,
    name: 'titles:state',
  },
  [GC.GRC.LOCATION_CITY]: {
    width: 200,
    searchable: true,
    name: 'titles:city',
  },
  [GC.GRC.LOCATION_ADDRESS1]: {
    width: 200,
    searchable: true,
    name: 'titles:address1',
  },
  [GC.GRC.LOCATION_ZIP]: {
    width: 200,
    searchable: true,
    name: 'titles:zip',
  },
  [GC.GRC.LOCATION_COMMENTS]: {
    width: 200,
    searchable: true,
    name: 'titles:comments',
  },
  [GC.GRC.ITEMS_INFO_COUNT]: {
    width: 200,
    searchable: true,
    name: 'titles:items-count',
  },
  [GC.GRC.ITEMS_INFO_WEIGHT]: {
    width: 200,
    searchable: true,
    name: 'titles:items-weight',
    customComponent: (data: Object) => <TableField data={data} type={GC.FIELD_WEIGHT} />,
  },
  [GC.GRC.ITEMS_INFO_QUANTITY]: {
    width: 200,
    searchable: true,
    name: 'titles:items-quantity',
  },
  [GC.GRC.ITEMS_INFO_TEMPERATURE_HIGH]: {
    width: 200,
    searchable: true,
    name: 'titles:temperature-high',
    customComponent: (data: Object) => <TableField data={data} type={GC.FIELD_TEMPERATURE} />,
  },
  [GC.GRC.ITEMS_INFO_TEMPERATURE_LOW]: {
    width: 200,
    searchable: true,
    name: 'titles:temperature-low',
    customComponent: (data: Object) => <TableField data={data} type={GC.FIELD_TEMPERATURE} />,
  },
  [GC.GRC.ITEMS_TEMPERATURE_TYPE]: {
    width: 200,
    type: 'enum',
    searchable: true,
    name: 'titles:temperature-types',
  },
  [GC.GRC.ITEMS_INFO_ANY_HAZARDOUS]: {
    width: 150,
    type: 'boolean',
    searchable: true,
    name: 'titles:any-hazardous',
  },
  [GC.GRC.STOP_CONTAINER_NUMBERS]: {
    width: 200,
    searchable: true,
    name: 'titles:containers',
  },
  [GC.GRC.CLO_EVENTS_LOCATION_STATE]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:all-events', 'titles:states'],
  },
  [GC.GRC.CLO_EVENTS_LOCATION_NAME]: {
    width: 250,
    searchable: true,
    name: ['titles:clo', 'titles:all-events', 'titles:location-names'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_TYPE]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:first-event', 'titles:location-type'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:first-event', 'titles:template-id'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:first-event', 'titles:location-name'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_ADDRESS1]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:first-event', 'titles:address1'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_CITY]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:first-event', 'titles:city'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_STATE]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:first-event', 'titles:state'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_COUNTRY]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:first-event', 'titles:country'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_ZIP]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:first-event', 'titles:zip'],
  },
  [GC.GRC.CLO_FIRST_EVENT_EARLY_DATE]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:first-event', 'titles:early-date'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LATE_DATE]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:first-event', 'titles:late-date'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_TYPE]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:last-event', 'titles:location-type'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:last-event', 'titles:template-id'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:last-event', 'titles:location-name'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_ADDRESS1]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:last-event', 'titles:address1'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_CITY]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:last-event', 'titles:city'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_STATE]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:last-event', 'titles:state'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_COUNTRY]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:last-event', 'titles:country'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_ZIP]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:last-event', 'titles:zip'],
  },
  [GC.GRC.CLO_LAST_EVENT_EARLY_DATE]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:last-event', 'titles:early-date'],
  },
  [GC.GRC.CLO_LAST_EVENT_LATE_DATE]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:last-event', 'titles:late-date'],
  },
  [GC.GRC.LAST_TERMINAL_DROP_TYPE]: {
    width: 200,
    searchable: true,
    name: ['titles:last-terminal-drop', 'titles:location-type'],
  },
  [GC.GRC.LAST_TERMINAL_DROP_TEMPLATE_ID]: {
    width: 200,
    searchable: true,
    name: ['titles:last-terminal-drop', 'titles:template-id'],
  },
  [GC.GRC.LAST_TERMINAL_DROP_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:last-terminal-drop', 'titles:location-name'],
  },
  [GC.GRC.LAST_TERMINAL_DROP_ADDRESS1]: {
    width: 200,
    searchable: true,
    name: ['titles:last-terminal-drop', 'titles:address1'],
  },
  [GC.GRC.LAST_TERMINAL_DROP_CITY]: {
    width: 200,
    searchable: true,
    name: ['titles:last-terminal-drop', 'titles:city'],
  },
  [GC.GRC.LAST_TERMINAL_DROP_STATE]: {
    width: 200,
    searchable: true,
    name: ['titles:last-terminal-drop', 'titles:state'],
  },
  [GC.GRC.LAST_TERMINAL_DROP_COUNTRY]: {
    width: 200,
    searchable: true,
    name: ['titles:last-terminal-drop', 'titles:country'],
  },
  [GC.GRC.LAST_TERMINAL_DROP_ZIP]: {
    width: 200,
    searchable: true,
    name: ['titles:last-terminal-drop', 'titles:zip'],
  },
  [GC.GRC.LAST_TERMINAL_DROP_EARLY_DATE]: {
    width: 200,
    searchable: true,
    name: ['titles:last-terminal-drop', 'titles:early-date'],
  },
  [GC.GRC.LAST_TERMINAL_DROP_LATE_DATE]: {
    width: 200,
    searchable: true,
    name: ['titles:last-terminal-drop', 'titles:late-date'],
  },
  [GC.GRC.CLO_BILL_TO_LOCATION_TYPE_DISPLAYED]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:bill-to', 'titles:location-type'],
  },
  [GC.GRC.CLO_BILL_TO_LOCATION_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:bill-to', 'titles:location-name'],
  },
  [GC.GRC.CLO_BILL_TO_ADDRESS1]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:bill-to', 'titles:address1'],
  },
  [GC.GRC.CLO_BILL_TO_CITY]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:bill-to', 'titles:city'],
  },
  [GC.GRC.CLO_BILL_TO_STATE]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:bill-to', 'titles:state'],
  },
  [GC.GRC.CLO_BILL_TO_COUNTRY]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:bill-to', 'titles:country'],
  },
  [GC.GRC.CLO_BILL_TO_ZIP]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:bill-to', 'titles:zip'],
  },
  [GC.GRC.CLO_BILL_TO_EMAILS_FIELD]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:bill-to', 'titles:emails'],
  },
  [GC.GRC.CLO_BILL_TO_PHONE]: {
    width: 200,
    searchable: true,
    name: ['titles:clo', 'titles:bill-to', 'titles:phone'],
  },
  [GC.GRC.CLO_BILL_TO_PAYMENT_TERM]: {
    width: 200,
    type: 'enum',
    searchable: true,
    name: ['titles:clo', 'titles:bill-to', 'titles:payment-terms'],
  },
  [GC.GRC.ITEMS_MANUFACTURER]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:manufacturer'],
  },
  [GC.GRC.ITEMS_YEAR]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:year'],
  },
  [GC.GRC.ITEMS_MAKE]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:make'],
  },
  [GC.GRC.ITEMS_MODEL]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:model'],
  },
  [GC.GRC.ITEMS_SUB_MODEL]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:sub-model'],
  },
  [GC.GRC.ITEMS_WHEELBASE]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:wheelbase'],
  },
  [GC.GRC.ITEMS_WHEELBASE_UOM]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:wheelbase-uom'],
    customComponent: ({ data }: Object) => <UomLocalesTableField data={data} field={GC.GRC.ITEMS_WHEELBASE_UOM} />,
  },
  [GC.GRC.ITEMS_ODOMETER]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:odometer'],
  },
  [GC.GRC.ITEMS_ODOMETER_UOM]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:odometer-uom'],
    customComponent: ({ data }: Object) => <UomLocalesTableField data={data} field={GC.GRC.ITEMS_ODOMETER_UOM} />,
  },
  [GC.GRC.ITEMS_COLOR]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:color'],
  },
  [GC.GRC.ITEMS_LICENSE_PLATE]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:license-plate'],
  },
  [GC.GRC.ITEMS_VIN]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:vin'],
  },
  [GC.GRC.ITEMS_BAY]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:bay'],
  },
  [GC.GRC.ITEMS_ITEM_TYPE]: {
    width: 200,
    type: 'enum',
    searchable: true,
    name: ['titles:items', 'titles:item-type'],
  },
  [GC.GRC.ITEMS_ITEM_ID]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:item-id'],
  },
  [GC.GRC.ITEMS_ORIGIN_STATE]: {
    width: 250,
    searchable: true,
    name: ['titles:items', 'titles:origin-state'],
  },
  [GC.GRC.ITEMS_ORIGIN_COUNTRY]: {
    width: 250,
    searchable: true,
    name: ['titles:items', 'titles:origin-country'],
  },
});

export const filterProps = [
  // EVENT
  {
    type: 'selectMultiple',
    value: GC.FIELD_EVENT_TYPE,
    options: getEventTypeOptions(),
    name: G.getWindowLocale('titles:event-type', 'Event Type'),
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_STATUS,
    options: getStopStatusOptions(),
    name: G.getWindowLocale('titles:status', 'Status'),
  },
  {
    type: 'string',
    value: GC.GRC.LOCATION_TYPE,
    name: G.getWindowLocale('titles:location-type', 'Location Type'),
  },
  {
    type: 'string',
    value: GC.GRC.LOCATION_TEMPLATE_ID,
    name: G.getWindowLocale('titles:template-id', 'Template ID'),
  },
  {
    type: 'string',
    value: GC.GRC.LOCATION_NAME,
    name: G.getWindowLocale('titles:location-name', 'Location Name'),
  },
  {
    type: 'string',
    value: GC.GRC.LOCATION_ADDRESS1,
    name: G.getWindowLocale('titles:address1', 'Address1'),
  },
  {
    type: 'string',
    value: GC.GRC.LOCATION_CITY,
    name: G.getWindowLocale('titles:city', 'City'),
  },
  {
    type: 'string',
    value: GC.GRC.LOCATION_STATE,
    name: G.getWindowLocale('titles:state', 'State'),
  },
  {
    type: 'string',
    value: GC.GRC.LOCATION_COUNTRY,
    name: G.getWindowLocale('titles:country', 'Country'),
  },
  {
    type: 'string',
    value: GC.GRC.LOCATION_ZIP,
    name: G.getWindowLocale('titles:zip', 'Zip'),
  },
  {
    type: 'date',
    value: GC.FIELD_EARLY_DATE,
    name: G.getWindowLocale('titles:early-date', 'Early Date'),
  },
  {
    type: 'date',
    value: GC.FIELD_LATE_DATE,
    name: G.getWindowLocale('titles:late-date', 'Late Date'),
  },
  {
    type: 'number',
    value: GC.GRC.ITEMS_INFO_COUNT,
    name: `${G.getWindowLocale('titles:items-count', 'Items Count')}`,
  },
  {
    type: 'number',
    value: GC.GRC.ITEMS_INFO_WEIGHT,
    name: `${G.getWindowLocale('titles:items-weight', 'Items Weight')}`,
  },
  {
    type: 'number',
    value: GC.GRC.ITEMS_INFO_QUANTITY,
    name: `${G.getWindowLocale('titles:items-quantity', 'Items Quantity')}`,
  },
  {
    type: 'number',
    value: GC.GRC.ITEMS_INFO_TEMPERATURE_HIGH,
    name: `${G.getWindowLocale('titles:temperature-high', 'Temperature High')}`,
  },
  {
    type: 'number',
    value: GC.GRC.ITEMS_INFO_TEMPERATURE_LOW,
    name: `${G.getWindowLocale('titles:temperature-low', 'Temperature Low')}`,
  },
  {
    collection: true,
    type: 'selectMultiple',
    value: GC.GRC.ITEMS_TEMPERATURE_TYPE,
    options: ITEM_TEMPERATURE_TYPE_OPTIONS,
    name: G.getWindowLocale('titles:temperature-types', 'Temperature Types'),
  },
  {
    type: 'boolean',
    value: GC.GRC.ITEMS_INFO_ANY_HAZARDOUS,
    name: `${G.getWindowLocale('titles:any-hazardous', 'Any Hazardous')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.ITEMS_MANUFACTURER,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:manufacturer', 'Manufacturer')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.ITEMS_ITEM_ID,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:item-id', 'Item ID')}`,
  },
  {
    type: 'number',
    collection: true,
    value: GC.GRC.ITEMS_YEAR,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:year', 'Year')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.ITEMS_MAKE,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:make', 'Make')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.ITEMS_MODEL,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:model', 'Model')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.ITEMS_SUB_MODEL,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:sub-model', 'Sub Model')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: GC.GRC.ITEMS_WHEELBASE,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:wheelbase', 'Wheelbase')
    }`,
  },
  {
    collection: true,
    type: 'selectMultiple',
    options: ITEM_DIMENSIONS_OPTIONS,
    value: GC.GRC.ITEMS_WHEELBASE_UOM,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:wheelbase-uom', 'Wheelbase Uom')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.ITEMS_BAY,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:bay', 'Bay')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: GC.GRC.ITEMS_ODOMETER,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:odometer', 'Odometer')
    }`,
  },
  {
    collection: true,
    type: 'selectMultiple',
    value: GC.GRC.ITEMS_ODOMETER_UOM,
    options: ITEM_ODOMETER_UOM_OPTIONS,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:odometer-uom', 'Odometer Uom')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.ITEMS_COLOR,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:color', 'Color')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.ITEMS_LICENSE_PLATE,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:license-plate', 'License Plate')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.ITEMS_VIN,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:vin', 'VIN')
    }`,
  },
  {
    collection: true,
    type: 'selectMultiple',
    value: GC.GRC.ITEMS_ITEM_TYPE,
    options: R.drop(1, ITEM_TYPE_OPTIONS),
    placeholder: { text: 'Choose', key: 'titles:choose' },
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:item-type', 'Item Type')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.ITEMS_ORIGIN_STATE,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:origin-state', 'Origin State')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.ITEMS_ORIGIN_COUNTRY,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:origin-country', 'Origin Country')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.STOP_CONTAINER_NUMBERS,
    name: G.getWindowLocale('titles:containers'),
  },
  {
    type: 'string',
    value: GC.GRC.LAST_TERMINAL_DROP_TYPE,
    name: `${G.getWindowLocale('titles:last-terminal-drop', 'Last TD')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_TERMINAL_DROP_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:last-terminal-drop', 'Last TD')}: ${
      G.getWindowLocale('titles:template-id', 'Templete ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_TERMINAL_DROP_NAME,
    name: `${G.getWindowLocale('titles:last-terminal-drop', 'Last TD')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_TERMINAL_DROP_ADDRESS1,
    name: `${G.getWindowLocale('titles:last-terminal-drop', 'Last TD')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_TERMINAL_DROP_CITY,
    name: `${G.getWindowLocale('titles:last-terminal-drop', 'Last TD')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_TERMINAL_DROP_STATE,
    name: `${G.getWindowLocale('titles:last-terminal-drop', 'Last TD')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_TERMINAL_DROP_COUNTRY,
    name: `${G.getWindowLocale('titles:last-terminal-drop', 'Last TD')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_TERMINAL_DROP_ZIP,
    name: `${G.getWindowLocale('titles:last-terminal-drop', 'Last TD')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'date',
    value: GC.GRC.LAST_TERMINAL_DROP_EARLY_DATE,
    name: `${G.getWindowLocale('titles:last-terminal-drop', 'Last TD')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.LAST_TERMINAL_DROP_LATE_DATE,
    name: `${G.getWindowLocale('titles:last-terminal-drop', 'Last TD')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  // EVENT
  // CLO
  {
    type: 'string',
    value: GC.GRC.CLO_PRIMARY_REFERENCE_VALUE,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:primary-ref-value', 'Primary Reference Value')}`,
  },
  {
    type: 'selectMultiple',
    value: GC.GRC.CLO_STATUS,
    options: getOrderStatusOptions(),
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:status', 'Status')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_CREATED_BY,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:created-by', 'Created By')}`,
  },
  {
    type: 'date',
    value: GC.GRC.CLO_CREATED_DATE,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:created-date', 'Created Date')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BRANCH_NAME,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:branch', 'Branch')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLO_EVENTS_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:all-events', 'All Events')}: ${
      G.getWindowLocale('titles:states', 'States')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLO_EVENTS_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:all-events', 'All Events')}: ${
      G.getWindowLocale('titles:location-names', 'Location Names')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_TYPE,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:template-id', 'Templete ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_TYPE,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:template-id', 'Tempate ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('ctitles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'date',
    value: GC.GRC.CLO_FIRST_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.CLO_FIRST_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.CLO_LAST_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.CLO_LAST_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_LOCATION_TYPE_DISPLAYED,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_ADDRESS1,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_CITY,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('ctitles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_STATE,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_COUNTRY,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_ZIP,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    collection: true,
    type: 'string:array',
    value: GC.GRC.CLO_BILL_TO_EMAILS_FIELD,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:emails', 'Emails')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_PHONE,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:phone', 'Phone')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_PAYMENT_TERM,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:payment-terms', 'Payment Terms')}`,
  },
  // CLO
  // TEL
  {
    type: 'string',
    value: GC.GRC.TEL_PRIMARY_REF_VALUE,
    name: `${G.getWindowLocale('titles:tel', 'TEL')} ${
      G.getWindowLocale('titles:primary-ref-value', 'Primary Ref Value')}`,
  },
  {
    type: 'selectMultiple',
    value: GC.GRC.TEL_STATUS,
    options: getTripStatusOptions(),
    name: `${G.getWindowLocale('titles:tel', 'TEL')} ${G.getWindowLocale('titles:status', 'Status')}`,
  },
  // TEL
];

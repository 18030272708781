import React, { Fragment } from 'react';
// features
import { CostsSection } from '../../../work-order/components/costs-section';
// forms
import { Fieldset2 } from '../../../../forms/formik';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, Divider } from '../../../../ui';
//////////////////////////////////////////////////

const fieldsWrapperStyles = { mb: 30, width: '100%' };

const inputWrapperStyles = { mr: 15, flexGrow: 1, flexBasis: 110 };

const inputWrapperStyles2 = { flexGrow: 1, flexBasis: 110 };

const fieldsetSettings = [
  {
    fieldsWrapperStyles,
    fields: [
      {
        type: 'text',
        isRequired: true,
        inputWrapperStyles,
        inputStyles: { pr: 15 },
        fieldName: GC.FIELD_SERVICE_ID,
        label: ['titles:service-id', 'Service ID'],
      },
      {
        isRequired: true,
        isClearable: true,
        type: 'datePicker',
        inputWrapperStyles,
        fieldName: GC.FIELD_SERVICE_DATE,
        label: ['titles:planned-service-date', 'Planned Service Date'],
      },
      {
        type: 'select',
        isRequired: true,
        withClickZIndex: true,
        fieldName: GC.FIELD_SERVICE_TYPE,
        options: 'equipmentServiceTypeOptions',
        inputWrapperStyles: inputWrapperStyles2,
        label: ['titles:service-type', 'Service Type'],
      },
    ],
  },
  {
    fieldsWrapperStyles,
    fields: [
      {
        type: 'text',
        inputWrapperStyles,
        fieldName: GC.FIELD_APPROVED_BY,
        label: ['titles:approved-by', 'Approved By'],
      },
      {
        inputWrapperStyles,
        type: 'reactSelect',
        options: 'userGeneralListFullNameOptions',
        fieldName: GC.FIELD_APPROVED_BY_USER_GUID,
        label: ['titles:approved-by-user', 'Approved By User'],
      },
      {
        isMulti: true,
        type: 'reactSelect',
        fieldName: GC.FIELD_COMPONENT_GUIDS,
        options: 'availableForServiceEquipmentComponents',
        inputWrapperStyles: { mr: 0, flexGrow: 1, flexBasis: 110 },
        label: ['titles:equipment-components', 'Equipment Components'],
      },
    ],
  },
  {
    fieldsWrapperStyles,
    fields: [
      {
        type: 'number',
        inputWrapperStyles,
        fieldName: GC.FIELD_INTERVAL,
        label: ['titles:interval', 'Interval'],
      },
      {
        type: 'select',
        fieldName: GC.FIELD_INTERVAL_UOM,
        inputWrapperStyles: inputWrapperStyles2,
        label: ['titles:interval-uom', 'Interval UOM'],
        options: [
          GC.EMPTY_OPTION_OBJECT,
          { value: GC.UOM_DAYS, label: G.getUomLocale(GC.UOM_DAYS) },
          { value: GC.UOM_HOURS, label: G.getUomLocale(GC.UOM_HOURS) },
          { value: GC.UOM_MILES, label: G.getUomLocale(GC.UOM_MILES) },
          { value: GC.UOM_KILOMETERS, label: G.getUomLocale(GC.UOM_KILOMETERS) },
        ],
      },
    ],
  },
  {
    fieldsWrapperStyles,
    fields: [
      {
        isClearable: true,
        type: 'datePicker',
        inputWrapperStyles,
        fieldName: GC.FIELD_PERFORMED_DATE,
        label: ['titles:performed-date', 'Performed Date'],
      },
      {
        type: 'number',
        inputWrapperStyles,
        fieldName: GC.FIELD_PERFORMED_VALUE,
        label: ['titles:performed-value', 'Performed Value'],
      },
      {
        type: 'select',
        fieldName: GC.FIELD_PERFORMED_TYPE,
        inputWrapperStyles: inputWrapperStyles2,
        label: ['titles:performed-type', 'Performed Type'],
        options: [
          GC.EMPTY_OPTION_OBJECT,
          { value: GC.UOM_HOURS, label: G.getUomLocale(GC.UOM_HOURS) },
          { value: GC.UOM_MILES, label: G.getUomLocale(GC.UOM_MILES) },
          { value: GC.UOM_KILOMETERS, label: G.getUomLocale(GC.UOM_KILOMETERS) },
        ],
      },
    ],
  },
  {
    fieldsWrapperStyles: { width: '100%' },
    fields: [
      {
        type: 'textarea',
        inputStyles: {
          p: 15,
          height: 100,
        },
        inputWrapperStyles: {
          flexGrow: 1,
          flexBasis: 300,
        },
        fieldName: GC.FIELD_COMMENTS,
        label: ['titles:comments', 'Comments'],
      },
    ],
  },
];

const ServiceForm = (props: Object) => {
  const {
    equipmentServiceTypeOptions,
    userGeneralListFullNameOptions,
    availableForServiceEquipmentComponents,
  } = props;

  return (
    <Fragment>
      <Box p={15}>
        <Fieldset2
          {...G.getFormikProps(props)}
          grouped={true}
          fields={fieldsetSettings}
          equipmentServiceTypeOptions={equipmentServiceTypeOptions}
          userGeneralListFullNameOptions={userGeneralListFullNameOptions}
          availableForServiceEquipmentComponents={availableForServiceEquipmentComponents}
        />
      </Box>
      {/* TODO: uncomment after API completed */}
      {/* <Divider mb={20} text={G.getWindowLocale('titles:costs', 'Costs', { caseAction: 'upperCase' })} />
      <CostsSection {...props} version={2} /> */}
    </Fragment>
  );
};

export default ServiceForm;

import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature config
import { EXTERNAL_DOCUMENT_TYPE_MAP } from '../constants';
import {
  documentTypeMappingFieldSettings,
  defaultDocumentTypeMappingFields,
  documentTypeMappingValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const getDocumentTypeOptions = (integrationType: string) => R.compose(
  G.addEmptyOptionToDropDown,
  R.values,
  R.mapObjIndexed((label: string, value: string) => ({ value, label })),
  R.pathOr({}, [integrationType]),
)(EXTERNAL_DOCUMENT_TYPE_MAP);

const enhance = compose(
  withFormik({
    validationSchema: documentTypeMappingValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultDocumentTypeMappingFields,
      initialValues,
    ),
  }),
  pure,
);

const DocumentTypeMappingForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      fieldsWrapperStyles={{ pt: 11, flexDirection: 'column' }}
      fields={documentTypeMappingFieldSettings}
      documentTypeConfigOptions={props.documentTypeConfigOptions}
      handlers={{ disableIntegrationType: () => props.isEditMode }}
      documentTypeOptions={getDocumentTypeOptions(
        R.pathOr(GC.DOCUMENT_TYPE_MAPPING_TYPE_HUB_TRAN, ['values', GC.FIELD_INTEGRATION_TYPE], props),
      )}
    />
    <FormFooter />
  </form>
);

export default enhance(DocumentTypeMappingForm);

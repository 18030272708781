import React from 'react';
// components
import { ActionsElement } from '../../../components/actions-element';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
//////////////////////////////////////////////////

const getOptions = (props: Object) => {
  const { entity, handleOpenTelDetails, handleAddTelDriverRate } = props;

  const iconColor = G.getTheme('colors.light.blue');

  return [
    {
      permissions: [PC.TEL_READ, PC.TEL_WRITE],
      action: () => handleOpenTelDetails(entity),
      frontIcon: I.routesLoads(iconColor, 13, 13),
      text: G.getWindowLocale('actions:go-to-details', 'Go to Details'),
    },
    {
      permissions: [PC.FLEET_RATE_WRITE],
      frontIcon: I.driver(iconColor, 13, 13),
      action: () => handleAddTelDriverRate(entity),
      text: G.getWindowLocale('actions:assign-driver-rate', 'Assign Driver Rate'),
    },
  ];
};

export const LoadActions = (props: Object) => <ActionsElement version={2} options={getOptions(props)} />;

import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Flex, AbsoluteWrapper } from '../../../ui';
//////////////////////////////////////////////////

const InfoLengthContainer = ({
  px = 0,
  length,
  width = 20,
  height = 20,
  left = '30px',
  borderRadius = '50%',
}: Object) => (
  <AbsoluteWrapper top='0px' left={left}>
    <Flex
      px={px}
      zIndex={201}
      minWidth={20}
      width={width}
      height={height}
      justifyContent='center'
      borderRadius={borderRadius}
      color={G.getTheme('colors.white')}
      bg={G.getTheme('colors.dark.blue')}
      border={`2px solid ${G.getTheme('colors.boxShadowLightGrey')}`}
    >
      {length}
    </Flex>
  </AbsoluteWrapper>
);

export default InfoLengthContainer;

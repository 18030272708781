import * as R from 'ramda';
import React, { Fragment, useState, useEffect, useCallback } from 'react';
// components
import { Document, AddDocument2, getOperations } from '../../../../components/activities';
// helpers/constants
import * as G from '../../../../helpers';
// hooks
import { useDownloadAndPreviewDocument } from '../../../../hooks';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// ui
import { Box, scrollableContainerCss4px } from '../../../../ui';
// feature fleet
import { DocumentPreview } from './documents';
import GoToConfiguration from './go-to-configuration';
//////////////////////////////////////////////////

const makeBatchFilesData = (
  filesData: Array,
  documentsDefaultDocumentType: string,
  primaryObjectGuid: string,
) => {
  const files = [];

  const documentTypes = [];

  filesData.forEach((file: any) => {
    files.push(file);

    documentTypes.push(documentsDefaultDocumentType);
  });

  return G.makeDataForMultipleDocumentsBatch({ files, documentTypes, primaryObjectGuid });
};

const UpdateDocuments = (props: Object) => {
  const {
    openModal,
    documents,
    closeModal,
    openLoader,
    entityType,
    closeLoader,
    setDocuments,
    primaryObjectGuid,
    documentsDefaultDocumentType,
  } = props;

  const { documentURL, handleDownloadOrPreviewDocument } = useDownloadAndPreviewDocument();

  const [maintenanceDocumentURL, setMaintenanceDocumentURL] = useState(documentURL);

  if (G.isNilOrEmpty(primaryObjectGuid)) return null;

  const handleDocuments = useCallback(async (method: string, options: Object, documentGuid: string) => {
    openLoader();

    const operations = getOperations(documentGuid, true);

    const endpoints = R.equals(entityType, 'trailer') ?
    {
      post: endpointsMap.trailerEquipmentServiceDocument,
      get: endpointsMap.trailerEquipmentServiceDocumentList,
      batch: endpointsMap.trailerEquipmentServiceDocumentBatch,
      delete: endpointsMap.getTrailerEquipmentServiceDocumentEndpoint(documentGuid),
    } :
    {
      post: endpointsMap.truckEquipmentServiceDocument,
      get: endpointsMap.truckEquipmentServiceDocumentList,
      batch: endpointsMap.truckEquipmentServiceDocumentBatch,
      delete: endpointsMap.getTruckEquipmentServiceDocumentEndpoint(documentGuid),
    };

    const requestMethod = R.equals(method, 'batch') ? 'post' : method;

    const requestParams = R.equals(method, 'batch') ? {} : { primaryObjectGuid };

    const res = await sendRequest(
      requestMethod,
      R.prop(method, endpoints),
      { ...options, params: requestParams },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      setDocuments((prev: Object) => {
        const documents = R.call(R.prop(method, operations), prev, data);

        return documents;
      });
    } else {
      G.handleFailResponseSimple(res, 'handleDocuments fail');
    }

    closeLoader();
  }, [primaryObjectGuid]);

  const onClickPreview = useCallback(({ filePublicLink }: Object) => {
    setMaintenanceDocumentURL(filePublicLink);
  }, [setMaintenanceDocumentURL]);

  useEffect(() => {
    if (R.isNil(documents)) handleDocuments('get');
  }, [documents, handleDocuments]);

  useEffect(() => {
    setMaintenanceDocumentURL(documentURL);
  }, [documentURL]);

  if (G.isNotNilAndNotEmpty(maintenanceDocumentURL)) {
    return (
      <DocumentPreview
        maintenanceDocumentURL={maintenanceDocumentURL}
        setMaintenanceDocumentURL={setMaintenanceDocumentURL}
      />
    );
  }

  return (
    <Fragment>
      {
        G.isNilOrEmpty(documentsDefaultDocumentType) && <GoToConfiguration entityType={entityType} />
      }
      {
        G.isNotNilAndNotEmpty(documents) &&
        <Box
          mr={10}
          overflowY='auto'
          p='15px 5px 0 15px'
          height='calc(100% - 200px)'
          css={scrollableContainerCss4px}
        >
          {
            R.or(documents, []).map((document: Object) => (
              <Document
                showPreview={true}
                document={document}
                withThumbnail={true}
                openModal={openModal}
                closeModal={closeModal}
                onClickPreview={onClickPreview}
                handleDocuments={handleDocuments}
                key={G.getGuidFromObject(document)}
                handleDownloadOrPreviewDocument={(data: Object) => {
                  const { guid, actionType, documentFilename } = data;

                  handleDownloadOrPreviewDocument({
                    actionType,
                    documentFilename,
                    returnObjectURL: true,
                    endpoint: endpointsMap.fleetEquipmentServiceEntityDocumentDownload(guid, entityType),
                  });
                }}
              />
            ))
          }
        </Box>
      }
      {
        G.isNotNilAndNotEmpty(documentsDefaultDocumentType) &&
        <AddDocument2
          onDropHandler={(files: Array) => {
            handleDocuments(
              'batch',
              { data: makeBatchFilesData(files, documentsDefaultDocumentType, primaryObjectGuid) },
            );
          }}
        />
      }
    </Fragment>
  );
};

export default UpdateDocuments;

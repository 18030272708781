import * as R from 'ramda';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withState, withProps, lifecycle, withHandlers } from 'react-recompose';
// components
import { ActionBox } from '../../../components/action-box';
import { ConfirmComponent } from '../../../components/confirm';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Flex, IconWrapper, scrollableContainerCss4px } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature fleet-profile
import SimpleForm from '../forms';
import AssignVendor from './assign-vendor';
import WorkingDivision from './working-division';
import {
  makeSelectAssignment,
  makeSelectAvailableAssignable,
} from '../selectors';
import {
  getDriverAssignmentRequest,
  getAvailableAssignableSuccess,
  assignOrUnassignEntityToDriverRequest,
} from '../actions';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

const assignmentMap = {
  primaryDriver: {
    profileType: 'driver',
    entityType: 'primaryDriver',
    entityTitle: ['titles:primary-driver', 'Primary Driver'],
  },
  teamDriver: {
    profileType: 'driver',
    entityType: 'teamDriver',
    entityTitle: ['titles:team-driver', 'Team Driver'],
  },
  truck: {
    entityType: 'truck',
    profileType: 'truck',
    entityTitle: ['titles:truck', 'Truck'],
  },
  trailer: {
    entityType: 'trailers',
    profileType: 'trailer',
    entityTitle: ['titles:trailers', 'Trailers'],
  },
};

const wrapperStyles = {
  mr: 10,
  p: '5px',
  height: 30,
  bg: 'white',
  border: '1px solid',
  borderRadius: '4px',
  color: 'light.blue',
  whiteSpace: 'nowrap',
  borderColor: 'light.blue',
  textTransform: 'uppercase',
};

const makeEntityDisplayName = R.compose(
  R.join(' '),
  R.values,
  R.filter(G.isNotNilAndNotEmpty),
  R.pick([GC.FIELD_NAME, GC.FIELD_UNIT_ID, GC.FIELD_FIRST_NAME, GC.FIELD_LAST_NAME]),
);

const Actions = ({ handleEdit, handleRemove }: Object) => (
  <Fragment>
    <IconWrapper ml={10} cursor='pointer' onClick={handleEdit}>
      {I.pencil(darkBlueColor)}
    </IconWrapper>
    <IconWrapper ml={10} cursor='pointer' onClick={handleRemove}>
      {I.trash(darkBlueColor)}
    </IconWrapper>
  </Fragment>
);

const AssignmentItem = (props: Object) => {
  const {
    entity,
    entityType,
    hasActions,
    entityTitle,
    profileType,
    handleRemoveAssignment,
    handleCreateAssignment,
    handleGoToProfileByProfileType,
  } = props;

  if (G.isNilOrEmpty(entity)) {
    if (R.not(hasActions)) return null;

    return (
      <Flex
        {...wrapperStyles}
        cursor='pointer'
        fontWeight='bold'
        onClick={() => handleCreateAssignment({ entityType })}
      >
        {G.getWindowLocale('titles:assign', 'Assign')} {entityTitle}
      </Flex>
    );
  }

  if (G.isArray(entity)) {
    return (
      <Flex {...wrapperStyles} maxWidth={360} fontWeight='bold'>
        {`${entityTitle}`}
        {
          entity.map(({ guid, unitId }: Object, index: number) => (
            <ActionBox
              key={index}
              text={unitId}
              action={() => handleGoToProfileByProfileType({ guid, type: profileType })}
              boxStyles={{ ml: '5px', fontWeight: 'bold', maxWidth: R.subtract(R.divide(240, R.length(entity)), 5) }}
            />
          ))
        }
        {
          hasActions &&
          <Actions
            handleEdit={() => handleCreateAssignment({ entity, entityType })}
            handleRemove={() => handleRemoveAssignment({ entity, entityType })}
          />
        }
      </Flex>
    );
  }

  return (
    <Flex {...wrapperStyles}>
      <ActionBox
        boxStyles={{ maxWidth: 250, fontWeight: 'bold' }}
        text={`${entityTitle} ${makeEntityDisplayName(entity)}`}
        action={() => handleGoToProfileByProfileType({ type: profileType, guid: G.getGuidFromObject(entity) })}
      />
      {
        hasActions &&
        <Actions
          handleEdit={() => handleCreateAssignment({ entity, entityType })}
          handleRemove={() => handleRemoveAssignment({ entity, entityType })}
        />
      }
    </Flex>
  );
};

const assignEntityToDriverEnhance = compose(
  withState(
    'availableAssignableOptions',
    'setAvailableAssignableOptions',
    ({ groupName, availableAssignable }: Object) => R.pathOr(null, [groupName], availableAssignable),
  ),
  withProps(({ availableAssignableOptions }: Object) => ({
    optionsForSelect: { availableAssignableOptions },
  })),
  withHandlers({
    getAvailableAssignableRequest: (props: Object) => async () => {
      const {
        groupName,
        branchGuid,
        openLoader,
        closeLoader,
        driverAssignmentGuid,
        getAvailableAssignableSuccess,
        setAvailableAssignableOptions,
        getAvailableAssignableEndpoint,
      } = props;

      G.callFunction(openLoader);

      const options = {
        params: {
          [GC.BRANCH_GUID]: branchGuid,
          currentDriverGuid: G.ifElse(G.notEquals(groupName, 'availableTeamDrivers'), driverAssignmentGuid),
          currentPrimaryDriverGuid: G.ifElse(R.equals(groupName, 'availableTeamDrivers'), driverAssignmentGuid),
        },
      };

      const res = await sendRequest('get', getAvailableAssignableEndpoint, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const options = R.map(({ guid, unitId, lastName, firstName }: Object) => ({
          [GC.FIELD_VALUE]: guid,
          [GC.FIELD_LABEL]: R.or(unitId, `${firstName} ${lastName}`),
        }), R.or(data, []));

        setAvailableAssignableOptions(options);
        getAvailableAssignableSuccess({ options, groupName });
      } else {
        G.handleException('error assignEntityToDriverEnhance');
      }

      G.callFunction(closeLoader);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { availableAssignableOptions, getAvailableAssignableRequest } = this.props;

      if (R.isNil(availableAssignableOptions)) getAvailableAssignableRequest();
    },
  }),
  pure,
);

const AssignEntityToDriverForm = assignEntityToDriverEnhance(SimpleForm);

const selectAssignableField = ({ isMulti, label, fieldName }: Object) => ({
  label,
  isMulti,
  fieldName,
  isRequired: true,
  type: 'reactSelect',
  options: 'availableAssignableOptions',
  inputWrapperStyles: { mb: 25, width: 270 },
});

const assignmentFormMap = {
  teamDriver: {
    groupName: 'availableTeamDrivers',
    title: ['titles:team-driver', 'Team Driver'],
    validationSchema: Yup.object().shape({ [GC.FIELD_TEAM_DRIVER_GUID]: G.yupStringRequired }),
    makeInitialValues: (entity: Object) => ({ [GC.FIELD_TEAM_DRIVER_GUID]: R.pathOr(null, [GC.FIELD_GUID], entity) }),
    fieldSettings: R.of(
      Array,
      selectAssignableField({ fieldName: GC.FIELD_TEAM_DRIVER_GUID, label: ['titles:team-driver', 'Team Driver'] }),
    ),
    endpoints: {
      getAvailableAssignable: endpointsMap.getAvailableDrivers,
      assignEntity: (driverGuid: string) => endpointsMap.assignTeamDriverEndpoint(driverGuid),
      unassignEntity: (driverGuid: string) => endpointsMap.unassignTeamDriverEndpoint(driverGuid),
    },
  },
  truck: {
    groupName: 'availableTrucks',
    title: ['titles:truck', 'Truck'],
    validationSchema: Yup.object().shape({ [GC.FIELD_TRUCK_GUID]: G.yupStringRequired }),
    makeInitialValues: (entity: Object) => ({ [GC.FIELD_TRUCK_GUID]: R.pathOr(null, [GC.FIELD_GUID], entity) }),
    fieldSettings: R.of(
      Array,
      selectAssignableField({ fieldName: GC.FIELD_TRUCK_GUID, label: ['titles:truck', 'Truck'] }),
    ),
    endpoints: {
      getAvailableAssignable: endpointsMap.getAvailableTrucks,
      assignEntity: (driverGuid: string) => endpointsMap.assignTruckEndpoint(driverGuid),
      unassignEntity: (driverGuid: string) => endpointsMap.unassignTruckEndpoint(driverGuid),
    },
  },
  trailers: {
    groupName: 'availableTrailers',
    title: ['titles:trailers', 'Trailers'],
    makeInitialValues: (entity: Object, driverGuid: string) => ({
      driverGuid,
      [GC.FIELD_TRAILER_GUIDS]: R.map(R.prop(GC.FIELD_GUID), R.or(entity, [])),
    }),
    fieldSettings: R.of(
      Array,
      selectAssignableField({
        isMulti: true,
        fieldName: GC.FIELD_TRAILER_GUIDS,
        label: ['titles:trailers', 'Trailers'],
      }),
    ),
    validationSchema: Yup.object().shape({
      [GC.FIELD_TRAILER_GUIDS]: G.yupArrayRequired.max(3, G.getShouldBePickOnlyLocaleTxt(3)),
    }),
    endpoints: {
      assignEntity: () => endpointsMap.assignTrailerEndpoint,
      getAvailableAssignable: endpointsMap.getAvailableTrailers,
      unassignEntity: (driverGuid: string) => endpointsMap.unassignTrailerEndpoint(driverGuid),
    },
  },
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  assignment: makeSelectAssignment(state),
  availableAssignable: makeSelectAvailableAssignable(state),
});

const driverAssignmentEnhance = compose(
  connect(
    mapStateToProps,
    {
      getDriverAssignmentRequest,
      getAvailableAssignableSuccess,
      assignOrUnassignEntityToDriverRequest,
    },
  ),
  withProps(({ type, teamDriver, isPrimaryDriver }: Object) => {
    if (G.isFalse(teamDriver)) {
      return {
        hasActions: true,
        assignmentsToPick: ['truck', 'trailer'],
      };
    }

    let assignmentToRemove = type;

    if (R.equals(type, 'driver')) {
      assignmentToRemove = G.ifElse(isPrimaryDriver, 'primaryDriver', 'teamDriver');
    }

    return {
      hasActions: isPrimaryDriver,
      assignmentsToPick: R.without([assignmentToRemove], ['primaryDriver', 'teamDriver', 'truck', 'trailer']),
    };
  }),
  withHandlers({
    handleCreateAssignment: (props: Object) => (options: Object) => {
      const {
        openModal,
        branchGuid,
        openLoader,
        closeLoader,
        availableAssignable,
        driverAssignmentGuid,
        getAvailableAssignableSuccess,
        assignOrUnassignEntityToDriverRequest,
      } = props;

      const { entity, entityType } = options;

      const settings = R.pathOr({}, [entityType], assignmentFormMap);

      const {
        fieldSettings,
        validationSchema,
        makeInitialValues,
        endpoints: { assignEntity, getAvailableAssignable },
      } = settings;

      const initialValues = makeInitialValues(entity, driverAssignmentGuid);

      const submitAction = (values: Object, options: Object) => assignOrUnassignEntityToDriverRequest({
        options,
        driverAssignmentGuid,
        endpoint: assignEntity(driverAssignmentGuid),
        requestPayload: {
          data: G.ifElse(R.equals(entityType, 'trailers'), values),
          params: G.ifElse(G.notEquals(entityType, 'trailers'), values),
        },
      });

      const component = (
        <AssignEntityToDriverForm
          {...settings}
          openLoader={openLoader}
          branchGuid={branchGuid}
          closeLoader={closeLoader}
          submitAction={submitAction}
          fieldSettings={fieldSettings}
          initialValues={initialValues}
          validationSchema={validationSchema}
          availableAssignable={availableAssignable}
          driverAssignmentGuid={driverAssignmentGuid}
          getAvailableAssignableEndpoint={getAvailableAssignable}
          getAvailableAssignableSuccess={getAvailableAssignableSuccess}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: `${G.getWindowLocale('titles:assign', 'Assign')} ${
            G.getWindowLocale(...R.pathOr([], ['title'], settings))}`,
        },
      };

      openModal(modal);
    },
    handleRemoveAssignment: (props: Object) => (options: Object) => {
      const { openModal, driverAssignmentGuid, assignOrUnassignEntityToDriverRequest } = props;

      const { entity, entityType } = options;

      const settings = R.pathOr({}, [entityType], assignmentFormMap);

      const endpoint = R.path(['endpoints', 'unassignEntity'], settings)(driverAssignmentGuid);

      const action = () => assignOrUnassignEntityToDriverRequest({
        endpoint,
        driverAssignmentGuid,
      });

      const textLocale = G.getWindowLocale(
        'messages:driver-unassign:confirmation:text',
        'Are you sure, you want unassign',
      );

      let name = makeEntityDisplayName(entity);

      if (G.isArray(entity)) {
        name = R.join(', ', R.map(R.prop(GC.FIELD_UNIT_ID), entity));
      }

      const component = <ConfirmComponent name={name} textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          title: `${G.getWindowLocale('titles:unassign', 'Unassign')} ${
            G.getWindowLocale(...R.pathOr([], ['title'], settings))}`,
          controlButtons: [
            {
              action,
              type: 'button',
              name: G.getWindowLocale('actions:submit', 'Submit'),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        assignment,
        driverAssignmentGuid,
        getDriverAssignmentRequest,
      } = this.props;

      if (R.and(R.isNil(assignment), G.isNotNil(driverAssignmentGuid))) {
        getDriverAssignmentRequest(driverAssignmentGuid);
      }
    },
  }),
  pure,
);

const DriverAssignment = driverAssignmentEnhance((props: Object) => {
  const { assignment, assignmentsToPick } = props;

  const assignments = R.values(R.pick(assignmentsToPick, assignmentMap));

  return (
    <Fragment>
      {assignments.map((item: Object, index: number) => {
        const { entityType, entityTitle } = item;

        const entity = G.ifElse(
          R.equals(entityType, 'primaryDriver'),
          assignment,
          G.getPropFromObject(entityType, assignment),
        );

        return (
          <AssignmentItem
            {...props}
            {...item}
            key={index}
            entity={entity}
            entityTitle={G.getWindowLocale(...entityTitle)}
          />
        );
      })}
    </Fragment>
  );
});

const Assignment = (props: Object) => (
  <Flex height={45} fontSize={12} overflowX='auto' css={scrollableContainerCss4px}>
    <DriverAssignment {...props} />
    <AssignVendor {...props} />
    <WorkingDivision {...props} />
  </Flex>
);

export default Assignment;

import * as R from 'ramda';
import React, { useRef, useState, useEffect } from 'react';
// components
import { TextComponent } from '../../../components/text';
import { InfoPair2 } from '../../../components/info-pair';
import { LocalLoader } from '../../../components/local-loader';
// feature template-inspection
import * as C from '../../template/inspection/constants';
import vehicleTypesConfig from '../../template/inspection/settings/vehicle-configs/vehicle-types-config';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { asyncGetFileAndCreateSrc } from '../../../helpers/api-async';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// ui
import { Box, Flex, Grid, Text, FixedBox, ActionButton } from '../../../ui';
// feature inspections
import ImagePreview from './image-preview';
import { getInspectionData } from '../helpers';
import { ComponentStatusUI, SectionComponentUI } from '../ui';
import { VehicleImage, IssueDetails, DocumentsPreview } from './section-components';
//////////////////////////////////////////////////

const greyColor = G.getTheme('colors.bgGrey');
const whiteColor = G.getTheme('colors.white');
const boxShadowColor = G.getTheme('colors.boxShadowGrey');
const darkGreyColor = G.getTheme('colors.light.mainDark');
const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const closeButtonStyles = {
  height: 32,
  width: 120,
  p: '4px 8px',
  fontSize: 15,
  bgColor: 'none',
  background: 'none',
  border: '1px solid',
  borderRadius: '5px',
  textColor: greyMatterhornColor,
  borderColor: greyMatterhornColor,
};

const labelColor = G.getTheme('colors.titleGrey2');
const textColor = G.getTheme('colors.black');

const infoPairProps = {
  fontSize: 14,
  fontWeight: 400,
  textColor: labelColor,
  labelFontWeight: 'bold',
  actionTextColor: textColor,
};

const DriverName = ({ data }: Object) => {
  const { firstLastNames } = G.getUserInfo(data);

  if (G.isNilOrEmpty(firstLastNames)) return null;

  return (
    <InfoPair2
      {...infoPairProps}
      textWidth={160}
      labelWidth={100}
      text={firstLastNames}
      label={G.getWindowLocale('titles:driver-name', 'Driver Name')}
    />
  );
};

const InspectionHeader = ({ inspection, signatureSrc }: Object) => (
  <Flex
    width='100%'
    height={100}
    minWidth={1800}
    p='15px 20px 0'
    flexDirection='column'
    alignItems='flex-start'
    color={greyMatterhornColor}
  >
    <Flex
      gap={8}
      flexDirection='column'
      alignItems='flex-start'
    >
      <Box fontSize={22} fontWeight='bold'>
        {G.getWindowLocale('titles:inspection', 'Inspection')} - {R.prop(GC.FIELD_NAME, inspection)}
      </Box>
      <Text>{R.propOr('-', GC.FIELD_DESCRIPTION, inspection)}</Text>
    </Flex>
    <Flex width='100%' justifyContent='space-between'>
      <Flex gap={20} justifyContent='space-between'>
        { G.isNotNilAndNotEmpty(R.prop(GC.FIELD_TRAILER, inspection)) &&
          <InfoPair2
            {...infoPairProps}
            textWidth={100}
            labelWidth={70}
            label={G.getWindowLocale('titles:trailer-number', 'Trailer Number')}
            text={R.pathOr('', [GC.FIELD_TRAILER, GC.FIELD_UNIT_ID], inspection)}
          />
        }
        { G.isNotNilAndNotEmpty(R.prop(GC.FIELD_TRUCK, inspection)) &&
          <InfoPair2
            {...infoPairProps}
            textWidth={100}
            labelWidth={70}
            label={G.getWindowLocale('titles:truck-number', 'Truck Number')}
            text={R.pathOr('', [GC.FIELD_TRUCK, GC.FIELD_UNIT_ID], inspection)}
          />
        }
        <InfoPair2
          {...infoPairProps}
          textWidth={75}
          labelWidth={100}
          label={G.getWindowLocale('titles:load-number', 'Load Number')}
          text={R.propOr('', GC.FIELD_TEL_PRIMARY_REFERENCE_VALUE, inspection)}
        />
        <InfoPair2
          {...infoPairProps}
          textWidth={160}
          labelWidth={100}
          text={R.propOr('', GC.FIELD_TEL_CARRIER_NAME, inspection)}
          label={G.getWindowLocale('titles:carrier-name', 'Carrier Name')}
        />
        <InfoPair2
          {...infoPairProps}
          textWidth={120}
          labelWidth={150}
          label={G.getWindowLocale('titles:driver-phone-number', 'Driver Phone Number')}
          text={R.pathOr('', [GC.SYSTEM_OBJECT_CARRIER_DRIVER, GC.FIELD_PHONE_NUMBER], inspection)}
        />
        <DriverName data={R.pathOr({}, [GC.SYSTEM_OBJECT_CARRIER_DRIVER], inspection)} />
      </Flex>
      <Flex gap={20}>
        <InfoPair2
          {...infoPairProps}
          textWidth={60}
          labelWidth={130}
          text={G.getEnumLocale(R.prop(GC.FIELD_TYPE, inspection))}
          label={G.getWindowLocale('titles:type-of-inspection', 'Type of inspection')}
        />
        <InfoPair2
          {...infoPairProps}
          textWidth={135}
          labelWidth={100}
          label={G.getWindowLocale('titles:conducted-on', 'Conducted on')}
          text={G.convertDateTimeToConfigFormat(R.prop(GC.FIELD_CREATED_DATE, inspection))}
        />
        {
          signatureSrc && (
            <InfoPair2
              {...infoPairProps}
              textWidth={50}
              labelWidth={70}
              text={
                <ImagePreview
                  filePublicLink={signatureSrc}
                  showOnAction={G.getWindowLocale('titles:show', 'Show')}
                  documentFilename={R.prop('signatureFileName', inspection)}
                />
              }
              label={G.getWindowLocale('titles:signature', 'Signature')}
            />
        )}
      </Flex>
    </Flex>
  </Flex>
);

const ComponentStatus = ({ component }: Object) => {
  const { type, failed, options } = component;

  const label = G.ifElse(
    failed,
    G.getWindowLocale('titles:not-passed', 'Not Passed'),
    G.getWindowLocale('titles:passed', 'Passed'),
  );

  if (R.equals(type, C.INSPECTION_PASS_FAIL)) {
    return (
      <ComponentStatusUI passed={R.not(failed)} failed={failed}>
        {label}
      </ComponentStatusUI>
    );
  } else if (G.isAnyTrue(
    R.equals(type, C.INSPECTION_RADIO),
    R.equals(type, C.INSPECTION_CHECKBOX),
    R.equals(type, C.INSPECTION_DROPDOWN),
  )) {
    const selected = R.prop('label', R.find(R.propEq(true, 'selected'), options));
    const selectedText = G.ifElse(G.isNotNilAndNotEmpty(selected), ` - ${selected}`, '');

    return (
      <ComponentStatusUI passed={R.not(failed)} failed={failed}>
        {`${label}${selectedText}`}
      </ComponentStatusUI>
    );
  }

  return null;
};

const SectionComponent = ({
  issue,
  component,
  directionColumn,
  activeVehiclePart,
  setActiveVehiclePart,
}: Object) => {
  const componentRef = useRef();

  const { name, failed, comments, documents } = component;

  const type = R.prop(GC.FIELD_VEHICLE_COMPONENT_TYPE, component);

  const animate = R.equals(type, R.prop('type', activeVehiclePart));
  const timestamp = R.prop('timestamp', activeVehiclePart);

  useEffect(() => {
    if (R.and(animate, timestamp)) {
      componentRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });

      // Trick that allows to restart animation
      componentRef.current.style.animation = null;
      setTimeout(() => {
        componentRef.current.style.animation = 'none';
      }, 1200);
    }
  }, [animate, timestamp]);

  return (
    <SectionComponentUI ref={componentRef} animate={animate} failed={failed}>
      <Text
        fontSize={14}
        cursor='pointer'
        fontWeight='bold'
        onClick={() => setActiveVehiclePart({ type, timestamp: new Date().getTime() })}
      >{name}</Text>
      <Flex pr={5} gap={16} width='100%' justifyContent='space-between'>
        <ComponentStatus component={component} />
        <DocumentsPreview documents={documents} />
      </Flex>
      <IssueDetails issue={issue} directionColumn={directionColumn} />
      {G.isNotNilAndNotEmpty(comments) && (
        <TextComponent
          display='block'
          title={comments}
          fontStyle='italic'
          withEllipsis={true}
          color={darkGreyColor}
          maxWidth='calc(100% - 5px)'
        >
          {comments}
        </TextComponent>
      )}
    </SectionComponentUI>
  );
};

const SectionInspection = ({
  width,
  issues,
  section,
  directionColumn,
  activeVehiclePart,
  maxContainerHeight,
  setActiveVehiclePart,
}: Object) => {
  const { name, document, components } = section;

  const maxHeight = R.or(maxContainerHeight, 455);

  return (
    <Flex
      gap={8}
      width={width}
      flexDirection='column'
      alignItems='flex-start'
      justifyContent='flex-start'
      color={greyMatterhornColor}
    >
      <Flex pr={10} gap={20} width='100%' justifyContent='space-between'>
        <Box fontSize={22} fontWeight='bold'>
          {name}
        </Box>
        {document && <ImagePreview {...document} />}
      </Flex>
      <Flex
        width='100%'
        overflow='auto'
        maxHeight={maxHeight}
        flexDirection='column'
        alignItems='flex-start'
        justifyContent='flex-start'
      >
        {
          G.mapIndexed((component: Object, i: number) => {
            const guid = G.getGuidFromObject(component);
            const issue = R.find(R.propEq(guid, 'inspectionComponentGuid'), issues);

            return (
              <SectionComponent
                key={i}
                issue={issue}
                component={component}
                directionColumn={directionColumn}
                activeVehiclePart={activeVehiclePart}
                setActiveVehiclePart={setActiveVehiclePart}
              />
            );
          }, components)
        }
      </Flex>
    </Flex>
  );
};

const SectionCard = ({ section, issues }: Object) => {
  const { type } = section;

  const [activeVehiclePart, setActiveVehiclePart] = useState(null);

  const sectionConfig = R.prop(type, vehicleTypesConfig);
  const maxContainerHeight = R.prop('maxHeight', sectionConfig);

  const directionColumn = R.or(R.prop('directionColumn', sectionConfig), R.equals(type, C.INSPECTION_GENERAL));
  const containerWidth = directionColumn ? '100%' : 'calc(50% - 10px)';

  return (
    <Flex
      pt={20}
      pl={20}
      gap={20}
      width='100%'
      height={520}
      borderRadius={12}
      alignItems='flex-start'
      justifyContent='flex-start'
      backgroundColor={whiteColor}
      {...(directionColumn && { flexDirection: 'column' })}
    >
      { G.isNotNilAndNotEmpty(sectionConfig) && (
        <VehicleImage
          section={section}
          activeVehiclePart={activeVehiclePart}
          setActiveVehiclePart={setActiveVehiclePart}
        />
      )}
      <SectionInspection
        issues={issues}
        section={section}
        width={containerWidth}
        directionColumn={directionColumn}
        activeVehiclePart={activeVehiclePart}
        maxContainerHeight={maxContainerHeight}
        setActiveVehiclePart={setActiveVehiclePart}
      />
    </Flex>
  );
};

const InspectionDetails = (props: Object) => {
  const {
    guid,
    truckGuid,
    closeModal,
  } = props;

  const [inspection, setInspection] = useState(null);
  const [signatureSrc, setSignatureSrc] = useState(null);

  useEffect(() => {
    const getInspectionDetails = async () => {
      try {
        const endpoint = G.isNotNil(truckGuid) ?
          endpointsMap.getTruckInspectionDetails(guid) :
          endpointsMap.getTrailerInspectionDetails(guid);

        const { data, status } = await sendRequest('get', endpoint);

        if (R.and(G.isNotNilAndNotEmpty(data), G.isResponseSuccess(status))) {
          setInspection(getInspectionData(data));

          if (G.isNotNilAndNotEmpty(R.prop(GC.FIELD_SIGNATURE_FILE_NAME, data))) {
            const src = await asyncGetFileAndCreateSrc(endpointsMap.getFleetInspectionsDownloadSignature(guid));

            setSignatureSrc(src);
          }
        }
      } catch (e) {
        G.handleException('error', 'getInspectionDetails exception');
      }
    };

    getInspectionDetails();
  }, []);

  return (
    <Flex
      overflow='auto'
      flexDirection='column'
      alignItems='flex-start'
      backgroundColor={greyColor}
      height='-webkit-fill-available'
    >
      {
        G.isNotNil(inspection) &&
        <InspectionHeader inspection={inspection} signatureSrc={signatureSrc} />
      }
      {
        G.isNotNil(inspection) ?
          <Grid
            gap={20}
            width='100%'
            p='10px 20px 70px'
            gridTemplateColumns='repeat(2, 868px)'
          >
            {
              G.isNotNilAndNotEmpty(inspection.sections) &&
              R.map((section: Object) => (
                <SectionCard key={G.getGuidFromObject(section)} section={section} issues={inspection.issues} />
              ), inspection.sections)
            }
          </Grid> :
          <LocalLoader localLoaderOpen={true} />
      }
      <FixedBox
        p={15}
        mt='auto'
        bottom='0'
        width='100%'
        bg={whiteColor}
        boxShadow={`0 0 8px 0 ${boxShadowColor}`}
      >
        <ActionButton {...closeButtonStyles} onClick={closeModal}>
          {G.getWindowLocale('actions:close', 'Close')}
        </ActionButton>
      </FixedBox>
    </Flex>
  );
};

export default InspectionDetails;

import React from 'react';
import * as R from 'ramda';
// features
import { InputWrapper } from '../../new-do/forms/formik/input-wrapper';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { ReactSelect } from '../../../ui';
//////////////////////////////////////////////////

const getAmousBranchesOptions = () => R.map(({ guid, enterpriseName }: Object) => ({
  [GC.FIELD_VALUE]: guid,
  [GC.FIELD_LABEL]: enterpriseName,
}), R.values(G.getItemFromWindow('amousBranches')));

const BranchSearch = (props: Object) => {
  const { value, selectHandler } = props;

  return (
    <InputWrapper
      inputWrapperStyles={{ zIndex: 16 }}
      label={G.getWindowLocale('titles:select-customer', 'Select Customer')}
    >
      <ReactSelect
        height={32}
        width={175}
        name='branch'
        value={value}
        isClearable={true}
        options={getAmousBranchesOptions()}
        onChange={(option: Object) => selectHandler(G.getValueFromObject(option))}
      />
    </InputWrapper>
  );
};

export default BranchSearch;

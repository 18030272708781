import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////

const {
  setReports,
  selectItem,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  deleteItemSuccess,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
} = getReportActions();

// config
const getBranchConfigsByNamesSuccess = createAction('getBranchConfigsByNamesSuccess');
// location
const resetLocationList = createAction('resetLocationList');
const getLocationRequest = createAction('getLocationRequest');
const getListItemByGuidRequest = createAction('getListItemByGuidRequest');
const getGeoFencingLocationRequest = createAction('getGeoFencingLocationRequest');
const getGeoFencingLocationSuccess = createAction('getGeoFencingLocationSuccess');
const submitGeoFencingLocationRequest = createAction('submitGeoFencingLocationRequest');
// fleet/trailer
const getFleetTrailerListRequest = createAction('getFleetTrailerListRequest');
const getFleetTrailerListSuccess = createAction('getFleetTrailerListSuccess');
const getFleetTrailerRouteHistoryRequest = createAction('getFleetTrailerRouteHistoryRequest');
const getFleetTrailerRouteHistorySuccess = createAction('getFleetTrailerRouteHistorySuccess');

export {
  // report
  setReports,
  selectItem,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  deleteItemSuccess,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  // configs
  getBranchConfigsByNamesSuccess,
  // location
  resetLocationList,
  getLocationRequest,
  getListItemByGuidRequest,
  getGeoFencingLocationRequest,
  getGeoFencingLocationSuccess,
  submitGeoFencingLocationRequest,
  // fleet/trailer
  getFleetTrailerListRequest,
  getFleetTrailerListSuccess,
  getFleetTrailerRouteHistoryRequest,
  getFleetTrailerRouteHistorySuccess,
};

import * as R from 'ramda';
import React, { memo, useRef, useEffect } from 'react';
// feature components
import { TextComponent } from '../../../components/text';
import { InfoPair2 } from '../../../components/info-pair';
import { PopperComponent } from '../../../components/popper';
// feature template-inspection
import * as C from '../../template/inspection/constants';
import { VehicleImageBox } from '../../template/inspection/ui';
import vehicleTypesConfig from '../../template/inspection/settings/vehicle-configs/vehicle-types-config';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, Text } from '../../../ui';
// feature inspections
import { VehicleComponentCircle } from '../ui';
import ImagePreview from '../components/image-preview';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const orangeColor = G.getTheme('colors.orange');
const darkRedColor = G.getTheme('colors.darkRed');
const yellowColor = G.getTheme('colors.light.yellow');
const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const priorityColorsMap = {
  [GC.PRIORITY_TYPE_LOW]: yellowColor,
  [GC.PRIORITY_TYPE_HIGH]: darkRedColor,
  [GC.PRIORITY_TYPE_MEDIUM]: orangeColor,
};

const DocumentsPreviewContent = memo(({ documents }: Object) => (
  <Flex
    p={15}
    gap={15}
    flexWrap='wrap'
    maxHeight={445}
    flexDirection='column'
  >
    {
      G.isNotNilAndNotEmpty(documents) && R.map((document: Object) => (
        <ImagePreview
          {...document}
          height={200}
          key={G.getGuidFromObject(document)}
        />
      ), documents)
    }
  </Flex>
));

const DocumentsPreview = ({ documents }: Object) => {
  if (G.isNilOrEmpty(documents)) return null;

  return (
    <Flex gap='5px'>
      {
        R.compose(
          R.map((document: Object) => (
            <ImagePreview
              {...document}
              height={35}
              key={G.getGuidFromObject(document)}
            />
          )),
          R.take(4),
        )(documents)
      }
      <Box>({R.length(documents)})</Box>
      <PopperComponent
        type='click'
        content={<DocumentsPreviewContent documents={documents} />}
      >
        {I.nextShape(greyMatterhornColor)}
      </PopperComponent>
    </Flex>
  );
};

const infoPairProps = {
  fontWeight: 400,
  labelFontWeight: 'bold',
};

const IssueDetailsContent = memo(({ issueId, summary, status, priority, location, description }: Object) => (
  <Box p='8px'>
    <Box mb='6px' fontSize={13} fontWeight='bold' color={greyMatterhornColor}>
      {issueId}
    </Box>
    <InfoPair2
      {...infoPairProps}
      text={summary}
      label={G.getWindowLocale('titles:summary', 'Summary')}
    />
    <InfoPair2
      {...infoPairProps}
      text={G.getEnumLocale(status)}
      label={G.getWindowLocale('titles:status', 'Status')}
    />
    <InfoPair2
      {...infoPairProps}
      text={G.getEnumLocale(priority)}
      label={G.getWindowLocale('titles:priority', 'Priority')}
    />
    <InfoPair2
      {...infoPairProps}
      text={G.renderSmallAddressString(location)}
      label={G.getWindowLocale('titles:location', 'Location')}
    />
    <InfoPair2
      {...infoPairProps}
      text={description}
      label={G.getWindowLocale('titles:description', 'Description')}
    />
  </Box>
));

const maxImageWidth = 828;
const maxImageHeight = 480;

const IssueDetails = ({ issue, directionColumn }: Object) => {
  if (R.isNil(issue)) return null;

  const { summary, priority } = issue;

  const color = priorityColorsMap[priority];

  const maxWidth = G.ifElse(directionColumn, R.subtract(maxImageWidth, 40), R.subtract(R.divide(maxImageWidth, 2), 60));

  return (
    <PopperComponent
      type='click'
      content={<IssueDetailsContent {...issue} />}
    >
      <Flex gap={8}>
        <Text color={color} fontWeight='bold'>{G.getWindowLocale('titles:issue', 'Issue')}:</Text>
        <TextComponent
          color={color}
          title={summary}
          display='block'
          maxWidth={maxWidth}
          withEllipsis={true}
        >
          {summary}
        </TextComponent>
      </Flex>
    </PopperComponent>
  );
};

const VehiclePartCircle = ({
  flip,
  name,
  type,
  section,
  position,
  activeVehiclePart,
  setActiveVehiclePart,
}: Object) => {
  const [x, y] = position;
  const { components } = section;

  const circleRef = useRef();

  const vehicleTypeComponent = R.find(R.propEq(type, 'vehicleComponentType'), components);
  const failed = R.prop('failed', vehicleTypeComponent);

  const animate = R.equals(type, R.prop('type', activeVehiclePart));
  const timestamp = R.prop('timestamp', activeVehiclePart);

  useEffect(() => {
    if (R.and(animate, timestamp)) {
      // Trick that allows to restart animation
      circleRef.current.style.animation = null;
      setTimeout(() => {
        circleRef.current.style.animation = 'none';
      }, 1200);
    }
  }, [animate, timestamp]);

  if (G.isNilOrEmpty(failed)) return null;

  return (
    <VehicleComponentCircle
      top={y}
      left={x}
      flip={flip}
      title={name}
      ref={circleRef}
      animate={animate}
      failed={G.isTrue(failed)}
      passed={G.isFalse(failed)}
      onClick={() => setActiveVehiclePart({ type, timestamp: new Date().getTime() })}
    >
      {G.isFalse(failed) && I.checkMark(whiteColor, 20, 20)}
      {G.isTrue(failed) && (
        <Text fontSize={20} mt={-4}>⚠</Text>
      )}
    </VehicleComponentCircle>
  );
};

const VehicleImage = (props: Object) => {
  const {
    section,
    activeVehiclePart,
    setActiveVehiclePart,
  } = props;

  const type = R.prop('type', section);

  const sectionConfig = R.prop(type, vehicleTypesConfig);
  const directionColumn = R.prop('directionColumn', sectionConfig);
  const [width, height] = R.prop('size', sectionConfig);
  const SectionImage = R.prop('image', sectionConfig);
  const Marker = R.prop('marker', sectionConfig);
  const markerStyles = R.propOr({}, 'inspectionMarkerStyles', sectionConfig);
  const flipImage = R.prop('flipImage', sectionConfig);
  const vehicleComponentTypes = R.prop('vehicleComponentTypes', sectionConfig);
  const ratio = width / height;

  let imageWidth = directionColumn ? maxImageWidth : maxImageHeight * ratio;
  let imageHeight = directionColumn ? imageWidth / ratio : maxImageHeight;

  if (R.and(R.not(directionColumn), R.gt(imageWidth, maxImageWidth / 2))) {
    imageWidth = maxImageWidth / 2;
    imageHeight = imageWidth / ratio;
  }

  const containerWidth = directionColumn ? '100%' : 'calc(50% - 10px)';
  const containerHeight = directionColumn ? 'unset' : maxImageHeight;

  const indentTop = R.equals(type, C.INSPECTION_TRAILER_LEFT) ? 40 : 0;

  return (
    <Flex
      pr={20}
      mt={indentTop}
      alignItems='center'
      width={containerWidth}
      justifyContent='center'
      height={containerHeight}
    >
      <VehicleImageBox flip={flipImage}>
        { G.isNotNilAndNotEmpty(Marker) && (
          <Box position='absolute' {...markerStyles}>
            <Marker />
          </Box>
        )}
        <SectionImage width={imageWidth} height={imageHeight} />
        <Box position='absolute' margin='auto' width='100%' height='100%'>
          {
            R.map(([key, value]: Array) => (
              <VehiclePartCircle
                {...value}
                key={key}
                type={key}
                flip={flipImage}
                section={section}
                activeVehiclePart={activeVehiclePart}
                setActiveVehiclePart={setActiveVehiclePart}
              />
            ), R.toPairs(vehicleComponentTypes))
          }
        </Box>
      </VehicleImageBox>
    </Flex>
  );
};

export {
  IssueDetails,
  VehicleImage,
  DocumentsPreview,
};

import * as R from 'ramda';
import React from 'react';
import { withFormik, FieldArray } from 'formik';
import { pure, compose, withState, withPropsOnChange } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// icons
import * as I from '../../../../../svgs';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// ui
import { Box, Flex } from '../../../../../ui';
// feature config
import {
  groupedByIntegrationTypeLabelTypeMap,
  groupedByIntegrationTypePaymentTermsMap,
  groupedByIntegrationTypeBolPaymentTermsMap,
} from '../constants';
import {
  additionalAccountFields,
  carrierIntegrationFieldsMap,
  carrierIntegrationFieldKeysGroup,
  getCarrierIntegrationValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const lightblueColor = G.getTheme('colors.light.blue');

const enhance = compose(
  withFormik({
    validationSchema: getCarrierIntegrationValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => {
      const data = R.compose(
        R.mergeRight(values),
        R.pick(GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR),
        R.pathOr({}, ['initialValues']),
      )(props);

      props.submitAction(data);
    },
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      { [GC.FIELD_INTEGRATION_TYPE]: GC.CARRIER_RATE_INTEGRATION_TYPE_AAA_COOPER },
      initialValues,
    ),
  }),
  withState('prevProps', 'setPrevProps', {}),
  withPropsOnChange(
    (props: Object, nextProps: Object) => {
      const currentIntegrationType = R.path(['values', GC.FIELD_INTEGRATION_TYPE], props);
      const nextIntegrationType = R.path(['values', GC.FIELD_INTEGRATION_TYPE], nextProps);

      return G.notEquals(currentIntegrationType, nextIntegrationType);
    },
    ({ values, override, setValues, optionsForSelect }: Object) => {
      const integrationType = G.getPropFromObject(GC.FIELD_INTEGRATION_TYPE, values);
      let fields = R.compose(
        R.map((key: string) => G.getPropFromObject(key, carrierIntegrationFieldsMap(integrationType))),
        R.pathOr([GC.FIELD_INTEGRATION_TYPE], [integrationType]),
      )(carrierIntegrationFieldKeysGroup);

      if (override) {
        fields = R.compose(
          R.filter(({ fieldName }: Object) => G.notContain(
            fieldName,
            [
              GC.FIELD_ENABLED,
              GC.FIELD_BOL_DOCUMENT_TYPE_GUID,
              GC.FIELD_LABEL_DOCUMENT_TYPE_GUID,
            ],
          )),
          R.prepend(G.getPropFromObject(GC.CUSTOMER_BRANCH_GUID, carrierIntegrationFieldsMap(integrationType))),
        )(fields);
      }

      const defaultValues = R.compose(
        R.assoc(GC.FIELD_ADDITIONAL_ACCOUNTS, []),
        R.map(R.pathOr(null, ['defaultValue'])),
        R.indexBy(R.prop('fieldName')),
      )(fields);
      const labelTypeOptions = R.compose(
        G.addEmptyOptionToDropDown,
        R.values,
        R.mapObjIndexed((label: string, value: string) => ({ value, label })),
        R.pathOr([], [integrationType]),
      )(groupedByIntegrationTypeLabelTypeMap);
      const getPaymentTermsByIntegrationType = R.or(
        G.getPropFromObject(integrationType, groupedByIntegrationTypePaymentTermsMap),
        G.getPropFromObject('default', groupedByIntegrationTypePaymentTermsMap),
      );
      const paymentTermOptions = R.compose(
        G.addEmptyOptionToDropDown,
        R.values,
        R.mapObjIndexed((label: string, value: string) => ({ value, label })),
      )(getPaymentTermsByIntegrationType);
      const getBolPaymentTermsByIntegrationType = R.or(
        G.getPropFromObject(integrationType, groupedByIntegrationTypeBolPaymentTermsMap),
        G.getPropFromObject('default', groupedByIntegrationTypeBolPaymentTermsMap),
      );
      const bolPaymentTermOptions = R.compose(
        G.addEmptyOptionToDropDown,
        R.values,
        R.mapObjIndexed((label: string, value: string) => ({ value, label })),
      )(getBolPaymentTermsByIntegrationType);

      setValues(R.mergeRight(defaultValues, R.pick(R.keys(defaultValues), values)));

      return {
        fields,
        optionsForSelect: R.mergeRight(
          optionsForSelect,
          { labelTypeOptions, paymentTermOptions, bolPaymentTermOptions },
        ),
      };
    },
  ),
  pure,
);

const AdditionalAccountsSection = (props: Object) => {
  const { push, form, remove } = props;

  const additionalAccounts = R.path(['values', GC.FIELD_ADDITIONAL_ACCOUNTS], form);

  return (
    <Box>
      <Flex m='5px' mb={25}>
        <Flex
          cursor='pointer'
          width='max-content'
          onClick={() => push({ [GC.FIELD_ZIP]: null, [GC.FIELD_ACCOUNT_NUMBER]: null })}
        >
          <Box fontWeight='bold' color={lightblueColor}>
            {G.getWindowLocale('titles:additional-accounts', 'Additional Accounts')}
          </Box>
          <Box ml={10}>
            {I.plusRound()}
          </Box>
        </Flex>
      </Flex>
      {
        G.isNotNilAndNotEmpty(additionalAccounts) &&
        additionalAccounts.map((item: any, index: number) => (
          <Flex key={index} width='100%'>
            <Box mr={10} onClick={() => remove(index)}>{I.trash(lightblueColor)}</Box>
            <Fieldset2
              {...form}
              fields={additionalAccountFields(index)}
              fieldsWrapperStyles={{ width: '100%', justifyContent: 'space-between' }}
            />
          </Flex>
        ),
        )
      }
    </Box>
  );
};

const getFormWidth = ({ integrationType, additionalAccounts }: Object, override: boolean = false) => {
  const width = G.ifElse(G.isTrue(override), 300, 270);
  const widthForWiderForms = G.ifElse(G.isTrue(override), 590, 560);

  if (G.isNotNilAndNotEmpty(additionalAccounts)) return 590;

  if (G.notContain(
    integrationType,
    [
      '',
      GC.CARRIER_RATE_INTEGRATION_TYPE_UPS,
      GC.CARRIER_RATE_INTEGRATION_TYPE_RIST,
      GC.CARRIER_RATE_INTEGRATION_TYPE_SMTL,
      GC.CARRIER_RATE_INTEGRATION_TYPE_SEFL,
      GC.CARRIER_RATE_INTEGRATION_TYPE_WARD,
      GC.CARRIER_RATE_INTEGRATION_TYPE_POLARIS,
      GC.CARRIER_RATE_INTEGRATION_TYPE_AAA_COOPER,
      GC.CARRIER_RATE_INTEGRATION_TYPE_A_DUIE_PYLE,
      GC.CARRIER_RATE_INTEGRATION_TYPE_UNISHIPPERS,
      GC.CARRIER_RATE_INTEGRATION_TYPE_PRIORITY_ONE,
      GC.CARRIER_RATE_INTEGRATION_TYPE_T_FORCE_WORLD_WIDE,
    ],
  )) {
    return widthForWiderForms;
  }

  return width;
};

const getFormFooterStyles = (override: boolean = false) => G.ifElse(
  G.isTrue(override),
  { boxStyles: { p: 15, pt: '0px' } },
);

const CarrierIntegrationFormComponent = (props: Object) => (
  <Box mx='auto' width={getFormWidth(props.values, props.override)}>
    <form onSubmit={props.handleSubmit}>
      <Box
        overflowY='auto'
        overflowX='hidden'
        maxHeight='calc(90vh - 100px)'
        p={G.ifElse(G.isTrue(props.override), '15px 15px 0 15px', '0px')}
      >
        <Fieldset2
          {...props.optionsForSelect}
          {...G.getFormikProps(props)}
          justifyContent='space-between'
          fields={R.pathOr([], ['fields'], props)}
          fieldsWrapperStyles={{ pt: 11, justifyContent: 'space-between' }}
          handlers={{ handleDisableIntegrationType: () => props.disableIntegrationType }}
        />
        {
          props.override &&
          <FieldArray
            name={GC.FIELD_ADDITIONAL_ACCOUNTS}
            render={(arrayHelpers: Object) => <AdditionalAccountsSection {...arrayHelpers} />}
          />
        }
      </Box>
      <FormFooter {...getFormFooterStyles(props.override)} />
    </form>
  </Box>
);

export default enhance(CarrierIntegrationFormComponent);


import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature configs
import {
  customerIdMappingFieldSettings,
  defaultCustomerIdMappingFields,
  customerIdMappingValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const getOptions = ({ customerBranchList, customerIdMappingList }: Object) => R.compose(
  G.addEmptyOptionToDropDown,
  R.map(
    (branch: Object) => R.assoc(
      'disabled',
      G.isNotNil(R.find(R.propEq(G.getGuidFromObject(branch), GC.FIELD_CUSTOMER_GUID), customerIdMappingList)),
      branch,
    ),
  ),
)(customerBranchList);

const enhance = compose(
  withFormik({
    validationSchema: customerIdMappingValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultCustomerIdMappingFields,
      initialValues,
    ),
  }),
  pure,
);

const CustomerIdMappingForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      customerBranchList={getOptions(props)}
      fields={customerIdMappingFieldSettings}
      fieldsWrapperStyles={{ pt: 11, flexDirection: 'column' }}
    />
    <FormFooter />
  </form>
);

export default enhance(CustomerIdMappingForm);

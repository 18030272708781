import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature configs
import {
  invoiceStatusRuleFieldSettings,
  defaultInvoiceStatusRuleFields,
  invoiceStatusRuleValidationSchema } from '../settings';
//////////////////////////////////////////////////

export const getInvoiceStatusOptionsByScope = (scope: string, configOptions: Object) => {
  const invoiceStatusOptionsNameMap = {
    [GC.INVOICE_SCOPE_TYPE_VENDOR]: 'driverPayrollStatuses',
    [GC.INVOICE_SCOPE_TYPE_DRIVER]: 'driverPayrollStatuses',
    [GC.INVOICE_SCOPE_TYPE_CARRIER]: 'carrierInvoiceStatuses',
    [GC.INVOICE_SCOPE_TYPE_CUSTOMER]: 'customerInvoiceStatuses',
    [GC.INVOICE_SCOPE_TYPE_SERVICE_VENDOR]: 'carrierInvoiceStatuses',
  };
  const invoiceStatusOptionsName = G.getPropFromObject(scope, invoiceStatusOptionsNameMap);
  const invoiceStatusOptions = R.pathOr([], [invoiceStatusOptionsName], configOptions);

  return G.addEmptyOptionToDropDown(invoiceStatusOptions);
};

const enhance = compose(
  withFormik({
    validationSchema: invoiceStatusRuleValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultInvoiceStatusRuleFields,
      initialValues,
    ),
  }),
  withHandlers({
    handleChangeConditions: ({ values, setValues }: Object) => (conditions: Object) => {
      let newValues = R.assoc(GC.FIELD_CONDITIONS, conditions, values);

      if (G.isNilOrEmpty(conditions)) {
        newValues = R.mergeRight(
          newValues,
          {
            [GC.FIELD_CLO_STATUSES]: null,
            [GC.FIELD_TEL_STATUSES]: null,
            [GC.FIELD_RATE_MISMATCH_VALUE]: 0,
            [GC.FIELD_RATE_MISMATCH_TYPE]: null,
          },
        );
      }

      if (G.notContain(GC.INVOICE_STATUS_RULE_CONDITION_TYPE_LOAD_STATUS)) {
        newValues = R.mergeRight(
          newValues,
          {
            [GC.FIELD_CLO_STATUSES]: null,
            [GC.FIELD_TEL_STATUSES]: null,
          },
        );
      }

      if (G.notContain(GC.INVOICE_STATUS_RULE_CONDITION_TYPE_RATE_TOTAL_MISMATCH)) {
        newValues = R.mergeRight(
          newValues,
          {
            [GC.FIELD_RATE_MISMATCH_VALUE]: 0,
            [GC.FIELD_RATE_MISMATCH_TYPE]: null,
          },
        );
      }

      setValues(newValues);
    },
  }),
  pure,
);

const InvoiceStatusRuleForm = (props: Object) => {
  const {
    values,
    configOptions,
    handleChangeConditions,
    invoiceStatusRulePriorityList,
  } = props;

  const fieldsWrapperStyles = { pt: 11 };

  const optionsForSelect = {
    transportationModes: configOptions.transportationModes,
    priorityOptions: G.addEmptyOptionToDropDown(invoiceStatusRulePriorityList),
    invoiceStatusOptions: getInvoiceStatusOptionsByScope(
      G.getPropFromObject(GC.FIELD_SCOPE, values),
      configOptions,
    ),
  };

  return (
    <form onSubmit={props.handleSubmit}>
      {
        invoiceStatusRuleFieldSettings.map((fields: Array, index: number) => (
          <Fieldset2
            {...optionsForSelect}
            {...G.getFormikProps(props)}
            key={index}
            fields={fields}
            fieldsWrapperStyles={fieldsWrapperStyles}
            handleCustomChange={handleChangeConditions}
            handlers={{ handleCustomChange: handleChangeConditions }}
          />
        ))
      }
      <FormFooter boxStyles={{ mx: '5px' }} />
    </form>
  );
};

export default enhance(InvoiceStatusRuleForm);

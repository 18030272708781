import React from 'react';
import * as R from 'ramda';
// features
import Audit2 from '../../../audit2';
import IftaListComponent from '../../../ifta-report';
import PC from '../../../permission/role-permission';
import FuelCardListComponent from '../../../fuel-cards';
import InspectionsListComponent from '../../../inspections';
import TollChargesListComponent from '../../../toll-charges';
// helpers/constants
import * as G from '../../../../helpers';
// feature fleet-profile
import { Tab, TabsGroupWrapper, tabsGroupWrapperEnhance } from '../../components/tabs-group-wrapper';
// feature fleet-profile/driver
import {
  withAuditTab,
  withIftaReportTab,
  withTollChargesTab,
  withInspectionsTab,
  withGeneralSettings,
  withDailyLogSettings,
  withSpecificationsTab,
  withDocumentsSettings,
  withMaintenanceSettings,
  withFuelTransactionsTab,
  withServiceIssueSettings,
} from '../hocs/with-tab-group-settings';
/////////////////////////////////////////////

const AuditTab = withAuditTab(Audit2);
const GeneralTab = withGeneralSettings(Tab);
const DailyLogTab = withDailyLogSettings(Tab);
const DocumentsTab = withDocumentsSettings(Tab);
const MaintenanceTab = withMaintenanceSettings(Tab);
const SpecificationsTab = withSpecificationsTab(Tab);
const ServiceIssueTab = withServiceIssueSettings(Tab);
const IftaReportTab = withIftaReportTab(IftaListComponent);
const TollChargesTab = withTollChargesTab(TollChargesListComponent);
const InspectionsTab = withInspectionsTab(InspectionsListComponent);
const FuelTransactionsTab = withFuelTransactionsTab(FuelCardListComponent);

const ActiveTab = (props: Object) => {
  const { activeTab, primaryObjectGuid, tabsCollapsedView } = props;

  const commonProps = {
    primaryObjectGuid,
    tabsCollapsedView,
  };

  const tabsMap = [
    <GeneralTab key={1} {...props} />,
    <DocumentsTab key={2} {...props} />,
    <SpecificationsTab key={3} {...props} />,
    <MaintenanceTab key={4} {...props} />,
    <ServiceIssueTab key={5} {...props} />,
    <InspectionsTab key={6} {...props} />,
    <FuelTransactionsTab key={7} {...props} />,
    <TollChargesTab key={8} {...commonProps} />,
    <IftaReportTab key={9} {...commonProps} />,
    <DailyLogTab key={10} {...props} />,
    <AuditTab key={11} {...commonProps} />,
  ];

  return R.pathOr(null, [activeTab], tabsMap);
};

const tabs = [
  { text: G.getWindowLocale('titles:general', 'General') },
  { text: G.getWindowLocale('titles:documents', 'Documents') },
  { text: G.getWindowLocale('titles:specifications', 'Specifications') },
  { text: G.getWindowLocale('titles:maintenance', 'Maintenance') },
  { text: G.getWindowLocale('titles:issues', 'Issues') },
  { text: G.getWindowLocale('titles:inspections', 'Inspections') },
  {
    permissions: [PC.FLEET_FUEL_CARD_READ, PC.FLEET_FUEL_CARD_WRITE],
    text: G.getWindowLocale('titles:fuel-transactions', 'Fuel Transactions'),
  },
  {
    permissions: [PC.TOLL_CHARGE_READ, PC.TOLL_CHARGE_WRITE],
    text: G.getWindowLocale('titles:toll-charges', 'Toll Charges'),
  },
  {
    permissions: [PC.IFTA_EXECUTE],
    text: G.getWindowLocale('titles:ifta-report', 'IFTA Report'),
  },
  { text: G.getWindowLocale('titles:daily-log', 'Daily Log') },
  { text: G.getWindowLocale('titles:audit', 'Audit') },
];

const TruckTabsGroups = (props: Object) => (
  <TabsGroupWrapper
    tabs={tabs}
    activeTab={props.activeTab}
    setTabsView={props.setTabsView}
    tabsCollapsedView={props.tabsCollapsedView}
    handleSetActiveTab={props.handleSetActiveTab}
  >
    <ActiveTab {...props} />
  </TabsGroupWrapper>
);

export default tabsGroupWrapperEnhance(TruckTabsGroups);

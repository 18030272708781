import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import { updateReportFromReportListSuccess } from '../../common/actions';
// report-common
import { getReportReducers } from '../../report-common';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature inspections
import * as A from './actions';
//////////////////////////////////////////////////

const fleetProfileInitSettings = {
  fleetProfileType: null,
  fleetProfileGuid: null,
  fleetProfileGuidType: null,
};

const initial = {
  fleetProfileSettings: fleetProfileInitSettings,
};

const {
  setReports,
  selectItem,
  initialState,
  setUsedReport,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  setInitialStateOmitReport,
  handleUpdateReportFromReportListSuccess,
} = getReportReducers(initial);

const getItemListSuccess = (state: Object, { data, guids }: Object) => {
  const { itemList, pagination } = state;

  const { results, totalCount } = data;

  if (R.and(G.isNotNilAndNotEmpty(guids), R.equals(R.length(results), 1))) {
    const guid = R.path([0, GC.FIELD_GUID], results);

    if (G.isNotNil(R.prop(guid, itemList))) {
      return P.$set(`itemList.${guid}`, R.mergeRight(R.prop(guid, itemList), R.head(results)), state);
    }
  }

  const indexAdditional = G.ifElse(
    R.isNil(itemList),
    0,
    R.length(R.values(itemList)),
  );

  const newItems = results.map((item: Object, i: number) => R.mergeRight(
    item,
    {
      selected: false,
      expanded: false,
      index: R.add(i, indexAdditional),
    },
  ));

  const list = R.mergeRight(itemList, R.indexBy(R.prop('guid'), newItems));
  const newOffset = R.add(pagination.offset, pagination.limit);

  return P.$all(
    P.$set('itemList', list),
    P.$set('pageVisited', true),
    P.$set('pagination.limit', 10),
    P.$set('totalCount', totalCount),
    P.$set(
      'pagination.offset',
      G.ifElse(
        R.gt(totalCount, newOffset),
        newOffset,
        totalCount,
      ),
    ),
    state,
  );
};

const deleteItemsSuccess = (state: Object, data: Arary) => (
  P.$all(
    P.$set('itemList', R.omit(data, state.itemList)),
    P.$set('totalCount', G.ifElse(R.equals(state.totalCount, 0), 0, R.subtract(state.totalCount, data.length))),
    state,
  )
);

const toggleDetails = (state: Object, { guid }: Object) => (
  P.$toggle(`itemList.${guid}.expanded`, state)
);

const setFleetProfileSettings = (state: Object, settings: Object = fleetProfileInitSettings) => (
  P.$set('fleetProfileSettings', settings, state)
);

export default createReducer({
  // report common
  [A.setReports]: setReports,
  [A.selectItem]: selectItem,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setReportPending]: setReportPending,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.setInitialStateOmitReport]: setInitialStateOmitReport,
  [updateReportFromReportListSuccess]: handleUpdateReportFromReportListSuccess,
  // report common
  [A.toggleDetails]: toggleDetails,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.deleteItemsSuccess]: deleteItemsSuccess,
  [A.setFleetProfileSettings]: setFleetProfileSettings,
}, initialState);

import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose, withProps } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
import { preferredUsernameFormatOptions, driverOnboardingIntegrationTypeOptions } from '../../../../../helpers/options';
// hocs
import { withAsyncRolesByUserType } from '../../../../../hocs';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 270,
};

const fieldSettings = [
  {
    type: 'select',
    inputWrapperStyles,
    label: ['titles:integration-type'],
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    options: driverOnboardingIntegrationTypeOptions,
    customDisabledFunction: 'handleDisableIntegrationType',
  },
  {
    type: 'toggle',
    inputWrapperStyles,
    label: ['titles:active'],
    fieldName: GC.FIELD_ENABLED,
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:company-id'],
    fieldName: GC.FIELD_COMPANY_ID,
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:default-driver-password'],
    fieldName: GC.FIELD_DEFAULT_DRIVER_PASSWORD,
    isRequired: (props: Object) => G.isNilOrEmpty(R.path(['values', GC.FIELD_VERSION], props)),
  },
  {
    type: 'select',
    inputWrapperStyles,
    options: preferredUsernameFormatOptions(),
    label: ['titles:preferred-username-format'],
    fieldName: GC.FIELD_PREFERRED_USERNAME_FORMAT,
  },
  {
    isMulti: true,
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    options: 'driverRoles',
    fieldName: GC.FIELD_DRIVER_ROLES_GUIDS,
    label: ['titles:select-user-permissions'],
  },
];

const defaultFields = {
  [GC.FIELD_ENABLED]: true,
  [GC.FIELD_COMPANY_ID]: null,
  [GC.FIELD_DRIVER_ROLES_GUIDS]: null,
  [GC.FIELD_DEFAULT_DRIVER_PASSWORD]: null,
  [GC.FIELD_PREFERRED_USERNAME_FORMAT]: GC.PREFERRED_USER_NAME_TYPE_EMAIL,
  [GC.FIELD_INTEGRATION_TYPE]: GC.DRIVER_ONBOARDING_INTEGRATION_TYPE_TENSTREET,
};

const validationSchema = ({ isEditMode }: Object) => Yup.lazy(() => Yup.object().shape({
  [GC.FIELD_COMPANY_ID]: G.yupStringRequired,
  [GC.FIELD_DEFAULT_DRIVER_PASSWORD]: G.ifElse(R.not(isEditMode), G.yupStringRequired),
  [GC.FIELD_DRIVER_ROLES_GUIDS]: G.yupArrayRequired.max(3, G.getShouldBePickOnlyLocaleTxt(3)),
}));

const enhance = compose(
  withProps(() => ({
    currentBranchGuid: G.getAmousCurrentBranchGuidFromWindow(),
  })),
  withAsyncRolesByUserType({ [GC.FIELD_ROLE_ROLE_TYPE]: GC.USER_ROLE_TYPE_DRIVER }),
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
  }),
  pure,
);

const OnboardingIntegrationConfigForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={fieldSettings}
      fieldsWrapperStyles={{ pt: 11 }}
      driverRoles={G.getPropFromObject(GC.FIELD_ROLE_ROLES_GUIDS, props)}
      handlers={{ handleDisableIntegrationType: () => props.isEditMode }}
    />
    <FormFooter />
  </form>
);

export default enhance(OnboardingIntegrationConfigForm);

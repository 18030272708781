import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const deleteInvoiceStatusRequest = createAction('deleteInvoiceStatusRequest');
export const returnInheritedStatusesRequest = createAction('returnInheritedStatusesRequest');
export const getDriverPayrollStatusesRequest = createAction('getDriverPayrollStatusesRequest');
export const getDriverPayrollStatusesSuccess = createAction('getDriverPayrollStatusesSuccess');
export const createUpdateInvoiceStatusRequest = createAction('createUpdateInvoiceStatusRequest');
export const deleteDriverPayrollStatusSuccess = createAction('deleteDriverPayrollStatusSuccess');
export const getCarrierInvoiceStatusesRequest = createAction('getCarrierInvoiceStatusesRequest');
export const getCarrierInvoiceStatusesSuccess = createAction('getCarrierInvoiceStatusesSuccess');
export const createDriverPayrollStatusSuccess = createAction('createDriverPayrollStatusSuccess');
export const updateDriverPayrollStatusSuccess = createAction('updateDriverPayrollStatusSuccess');
export const getConfigsForInvoiceConfigSuccess = createAction('getConfigsForInvoiceConfigSuccess');
export const getCustomerInvoiceStatusesSuccess = createAction('getCustomerInvoiceStatusesSuccess');
export const getCustomerInvoiceStatusesRequest = createAction('getCustomerInvoiceStatusesRequest');
export const createCarrierInvoiceStatusSuccess = createAction('createCarrierInvoiceStatusSuccess');
export const updateCarrierInvoiceStatusSuccess = createAction('updateCarrierInvoiceStatusSuccess');
export const deleteCarrierInvoiceStatusSuccess = createAction('deleteCarrierInvoiceStatusSuccess');
export const updateCustomerInvoiceStatusSuccess = createAction('updateCustomerInvoiceStatusSuccess');
export const deleteCustomerInvoiceStatusSuccess = createAction('deleteCustomerInvoiceStatusSuccess');
export const createCustomerInvoiceStatusesSuccess = createAction('createCustomerInvoiceStatusesSuccess');
export const getFleetServiceInvoiceStatusesSuccess = createAction('getFleetServiceInvoiceStatusesSuccess');
export const deleteFleetServiceInvoiceStatusSuccess = createAction('deleteFleetServiceInvoiceStatusSuccess');
export const createOrUpdateFleetServiceInvoiceStatusSuccess =
  createAction('createOrUpdateFleetServiceInvoiceStatusSuccess');
// qb account mapping
export const setQBAccountMappingFilter = createAction('setQBAccountMappingFilter');
export const removeQBAccountMappingRequest = createAction('removeQBAccountMappingRequest');
export const removeQBAccountMappingSuccess = createAction('removeQBAccountMappingSuccess');
export const getQBAccountMappingListRequest = createAction('getQBAccountMappingListRequest');
export const getQBAccountMappingListSuccess = createAction('getQBAccountMappingListSuccess');
export const createOrUpdateQBAccountMappingRequest = createAction('createOrUpdateQBAccountMappingRequest');
export const createOrUpdateQBAccountMappingSuccess = createAction('createOrUpdateQBAccountMappingSuccess');
// qb iif accessorial mapping
export const setQbIffAssessorialMappingFilter = createAction('setQbIffAssessorialMappingFilter');
export const removeQBIIFAccessorialMappingRequest = createAction('removeQBIIFAccessorialMappingRequest');
export const removeQBIIFAccessorialMappingSuccess = createAction('removeQBIIFAccessorialMappingSuccess');
export const getQBIIFAccessorialMappingListRequest = createAction('getQBIIFAccessorialMappingListRequest');
export const getQBIIFAccessorialMappingListSuccess = createAction('getQBIIFAccessorialMappingListSuccess');
export const createOrUpdateQBIIFAccessorialMappingRequest = createAction('createOrUpdateQBIIFAccessorialMappingRequest');
export const createOrUpdateQBIIFAccessorialMappingSuccess = createAction('createOrUpdateQBIIFAccessorialMappingSuccess');
// sage account mapping
export const setSageAccountMappingFilter = createAction('setSageAccountMappingFilter');
export const removeSageAccountMappingRequest = createAction('removeSageAccountMappingRequest');
export const removeSageAccountMappingSuccess = createAction('removeSageAccountMappingSuccess');
export const getSageAccountMappingListRequest = createAction('getSageAccountMappingListRequest');
export const getSageAccountMappingListSuccess = createAction('getSageAccountMappingListSuccess');
export const createOrUpdateSageAccountMappingRequest = createAction('createOrUpdateSageAccountMappingRequest');
export const createOrUpdateSageAccountMappingSuccess = createAction('createOrUpdateSageAccountMappingSuccess');
// customer id mapping
export const removeCustomerIdMappingRequest = createAction('removeCustomerIdMappingRequest');
export const removeCustomerIdMappingSuccess = createAction('removeCustomerIdMappingSuccess');
export const getCustomerIdMappingListRequest = createAction('getCustomerIdMappingListRequest');
export const getCustomerIdMappingListSuccess = createAction('getCustomerIdMappingListSuccess');
export const createOrUpdateCustomerIdMappingRequest = createAction('createOrUpdateCustomerIdMappingRequest');
export const createOrUpdateCustomerIdMappingSuccess = createAction('createOrUpdateCustomerIdMappingSuccess');
// department id mapping
export const removeDepartmentIdMappingRequest = createAction('removeDepartmentIdMappingRequest');
export const removeDepartmentIdMappingSuccess = createAction('removeDepartmentIdMappingSuccess');
export const getDepartmentIdMappingListRequest = createAction('getDepartmentIdMappingListRequest');
export const getDepartmentIdMappingListSuccess = createAction('getDepartmentIdMappingListSuccess');
export const createOrUpdateDepartmentIdMappingRequest = createAction('createOrUpdateDepartmentIdMappingRequest');
export const createOrUpdateDepartmentIdMappingSuccess = createAction('createOrUpdateDepartmentIdMappingSuccess');
// gl code mapping
export const setGLCodeMappingFilter = createAction('setGLCodeMappingFilter');
export const removeGLCodeMappingRequest = createAction('removeGLCodeMappingRequest');
export const removeGLCodeMappingSuccess = createAction('removeGLCodeMappingSuccess');
export const getGLCodeMappingListRequest = createAction('getGLCodeMappingListRequest');
export const getGLCodeMappingListSuccess = createAction('getGLCodeMappingListSuccess');
export const createOrUpdateCLCodeMappingRequest = createAction('createOrUpdateCLCodeMappingRequest');
export const createOrUpdateCLCodeMappingSuccess = createAction('createOrUpdateCLCodeMappingSuccess');
// invoice status rule
export const setInvoiceStatusRuleFilter = createAction('setInvoiceStatusRuleFilter');
export const removeInvoiceStatusRuleRequest = createAction('removeInvoiceStatusRuleRequest');
export const getInvoiceStatusRuleListRequest = createAction('getInvoiceStatusRuleListRequest');
export const getInvoiceStatusRuleListSuccess = createAction('getInvoiceStatusRuleListSuccess');
export const createOrUpdateInvoiceStatusRuleRequest = createAction('createOrUpdateInvoiceStatusRuleRequest');
// expense types
export const removeExpenseTypeRequest = createAction('removeExpenseTypeRequest');
export const removeExpenseTypeSuccess = createAction('removeExpenseTypeSuccess');
export const getExpenseTypeListRequest = createAction('getExpenseTypeListRequest');
export const getExpenseTypeListSuccess = createAction('getExpenseTypeListSuccess');
export const createOrUpdateExpenseTypeRequest = createAction('createOrUpdateExpenseTypeRequest');
export const createOrUpdateExpenseTypeSuccess = createAction('createOrUpdateExpenseTypeSuccess');

import React, { useState } from 'react';
// components
import ToggleBtn from '../../../components/toggle-btn';
import { Switcher } from '../../../components/switcher';
import { DateRangeMui } from '../../../components/date-range';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Flex } from '../../../ui';
//////////////////////////////////////////////////

const switchIndexMap = {
  1: 0,
  3: 1,
  7: 2,
};

const switchOptions = [
  {
    value: 1,
    width: 50,
    name: G.getWindowLocale('titles:1-day', '1 day'),
  },
  {
    value: 3,
    width: 50,
    name: G.getWindowLocale('titles:3-days', '3 days'),
  },
  {
    value: 7,
    width: 50,
    name: G.getWindowLocale('titles:7-days', '7 days'),
  },
];

export const DateFilters = (props: Object) => {
  const { dateFilters = {}, handleSetDateFilters, handleSetFilterQuickDays } = props;

  const [showDateRange, setShowDateRange] = useState(false);

  const { dateTo, dateFrom, initialFilterDays } = dateFilters;

  return (
    <Flex height={35}>
      <Switcher
        mr={15}
        version={3}
        fontSize={10}
        options={switchOptions}
        onSwitch={handleSetFilterQuickDays}
        selectedOptionIndex={switchIndexMap[initialFilterDays]}
      />
      <ToggleBtn
        mr={15}
        checked={showDateRange}
        onToggleHandler={setShowDateRange}
        title={G.getWindowLocale('titles:date-range', 'Date Range')}
      />
      {
        showDateRange &&
        <DateRangeMui
          width={80}
          dateTo={dateTo}
          withIcon={true}
          dateFrom={dateFrom}
          useNewMuiInputField={true}
          popperPlacement='bottom-end'
          flexBoxStyles={{ zIndex: 0 }}
          onSelectDateRange={handleSetDateFilters}
          maxDate={G.momentAddYearsFromCurrent(100)}
          labelTo={G.getWindowLocale('titles:to', 'To')}
          minDate={G.momentSubtractYearsFromCurrent(100)}
          labelFrom={G.getWindowLocale('titles:from', 'From')}
        />
      }
    </Flex>
  );
};

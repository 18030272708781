import * as R from 'ramda';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import React, { useMemo, useState, useCallback } from 'react';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Grid } from '../../../ui';
// feature geo-fencing-location
import * as H from '../helpers';
import {
  getDefaultLocationValues,
  getLocationFieldSettings,
  getValidationSchemaObject,
} from '../settings/location-form';
import LocationMap from './location-map';
//////////////////////////////////////////////////

const LocationForm = (props: Object) => {
  const { configs, closeModal, currentLocation, handleCreateLocation} = props;

  const initialPosition = G.ifElse(
    G.isNotNilAndNotEmpty(currentLocation),
    R.pick(['latitude', 'longitude'], R.or(currentLocation, {})),
    null,
  );

  const [position, setPosition] = useState(initialPosition);

  const onSubmit = useCallback((values: Object) => {
    handleCreateLocation(values);
  }, [handleCreateLocation]);

  const validationSchema = Yup.lazy((values: Object) => Yup.object().shape(getValidationSchemaObject(values)));

  const initialValues = G.ifElse(
    G.isNotNilAndNotEmpty(currentLocation),
    H.remapLocationValues(currentLocation),
    getDefaultLocationValues(currentLocation),
  );

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  const { handleSubmit } = formik;

  const radius = R.prop('value', formik.getFieldProps(GC.FIELD_FLEET_LOCATION_RADIUS));

  const geoFencingLocationTypeOptions = useMemo(() => G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    configs,
    GC.FLEET_GENERAL_GEO_FENCING_LOCATION_TYPE,
  ), [configs]);

  const handleSelectLocation = useCallback((newValues: Object) => {
    setPosition(R.pick(['latitude', 'longitude'], newValues));
  }, [currentLocation]);

  const handleSetRadius = useCallback((radius: number) => {
    formik.setFieldValue(GC.FIELD_FLEET_LOCATION_RADIUS, radius);
  });

  return (
    <Box
      width={920}
      maxHeight='85vh'
      overflow='hidden'
      p='20px 15px 15px 15px'
    >
      <Grid gap={15} gridTemplateColumns='250px 1fr'>
        <form onSubmit={handleSubmit}>
          <Fieldset2
            {...G.getFormikPropsToFieldset(formik)}
            fields={getLocationFieldSettings()}
            customSelectLocationFunction={handleSelectLocation}
            geoFencingLocationTypeOptions={geoFencingLocationTypeOptions}
          />
          <FormFooter2 mt={25} cancelAction={closeModal} />
        </form>
        <LocationMap mode={H.EDIT_MODE} radius={radius} position={position} handleSetRadius={handleSetRadius} />
      </Grid>
    </Box>
  );
};

export default LocationForm;

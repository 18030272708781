import React, { memo } from 'react';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { ActionButton } from '../../../../ui';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature fleet-profile
import CreateFromTruckLogForm from '../forms/create-from-truck-log';
import DriverDailyLogList from '../components/driver-daily-log-list';
//////////////////////////////////////////////////

export const DailyHoursAdditionalFormGroupTitle = memo((props: Object) => {
  const { openModal, driverBranchGuid, primaryObjectGuid } = props;

  const handleGetDailyLogListAvailable = (dailyLogListAvailable: Array) => {
    const { closeModal, openLoader, closeLoader, getItemListRequest, handleResetListAndPagination } = props;

    const dailyLogCreateFromTruckLog = async (payload: Object) => {
      G.callFunction(openLoader);

      try {
        const { selectedList, setSubmitting } = payload;

        const options = {
          data: {
            driverGuid: primaryObjectGuid,
            enterpriseGuid: driverBranchGuid,
            truckDailyLogGuids: selectedList,
          },
        };

        const res = await sendRequest('post', endpointsMap.driverDailyLogCreateFromTruckLog, options);

        const { data, status } = res;

        const isResponseSuccess = G.isResponseSuccess(status, data);

        if (isResponseSuccess) {
          G.callFunction(handleResetListAndPagination);
          G.callFunction(getItemListRequest);
        } else {
          G.handleFailResponseSimple(res, true, 'handleGetDailyLogListAvailable');
        }

        G.callFunctionWithArgs(setSubmitting, false);

        G.callFunction(closeLoader);

        if (isResponseSuccess) G.callFunction(closeModal);
      } catch (error) {
        G.callFunction(closeLoader);

        G.handleException(error, 'handleGetDailyLogListAvailable');
      }
    };

    const component = (
      <DriverDailyLogList data={dailyLogListAvailable} dailyLogCreateFromTruckLog={dailyLogCreateFromTruckLog} />
    );

    const modal = {
      p: 15,
      component,
      options: {
        width: 'auto',
        height: 'auto',
        title: G.getWindowLocale('titles:create-from-truck-log', 'Create From Truck Log'),
      },
    };

    openModal(modal);
  };

  const handleAddFromTruckLog = () => {
    const modalContent = (
      <CreateFromTruckLogForm
        driverBranchGuid={driverBranchGuid}
        currentDriverGuid={primaryObjectGuid}
        handleGetDailyLogListAvailable={handleGetDailyLogListAvailable}
      />
    );

    const modal = G.getDefaultModalOptions(
      modalContent,
      G.getAddTitle(['titles:titles:create-from-truck-log', 'Create From Truck Log']),
    );

    openModal(modal);
  };

  return (
    <ActionButton
      ml={10}
      height={22}
      p='4px 8px'
      fontSize={11}
      borderRadius='5px'
      onClick={handleAddFromTruckLog}
      bgColor={G.getTheme('colors.white')}
      textColor={G.getTheme('colors.dark.blue')}
      border={`1px solid ${G.getTheme('colors.dark.blue')}`}
    >
      {G.getWindowLocale('titles:titles:create-from-truck-log', 'Create From Truck Log')}
    </ActionButton>
  );
});

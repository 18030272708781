import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { Box } from '../../../../ui';
import { scrollableContainerCss4px } from '../../../../ui/common';
// feature fleet
import LeftTabs from './left-tabs';
import VendorForm from './vendor-form';
import ServiceForm from './service-form';
import InvoiceForm from './invoice-form';
//////////////////////////////////////////////////

const activeTabComponentMap = {
  0: ServiceForm,
  1: VendorForm,
  2: InvoiceForm,
};

const LeftSection = (props: Object) => {
  const {
    leftActiveTab,
    setLeftActiveTab,
    handleToggleInvoiceSection,
  } = props;

  const Component = activeTabComponentMap[leftActiveTab];

  return (
    <Box
      width={620}
      height='100%'
      flexShrink={0}
      overflow='auto'
      borderRight='1px solid'
      css={scrollableContainerCss4px}
      borderColor={G.getTheme('colors.lightGrey')}
    >
      <LeftTabs
        leftActiveTab={leftActiveTab}
        setLeftActiveTab={(tab: number) => {
          if (R.equals(tab, 2)) handleToggleInvoiceSection();

          setLeftActiveTab(tab);
        }}
      />
      {
        G.isNilOrEmpty(Component) ? null : <Component {...props} />
      }
    </Box>
  );
};

export default LeftSection;

import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import ErrorsPopover from '../../../components/errors-popover';
import { getConfirmModal } from '../../../components/confirm';
import { openModal, closeModal } from '../../../components/modal/actions';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature edi-integration
import DeclineForm from './decline-form';
import {
  downloadEdiFileRequest,
  cancelLoadFromEDIRequest,
  acceptLoadFromEDIRequest,
  declineLoadFromEDIRequest,
} from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  connect(
    null,
    {
      openModal,
      closeModal,
      downloadEdiFileRequest,
      cancelLoadFromEDIRequest,
      acceptLoadFromEDIRequest,
      declineLoadFromEDIRequest,
    },
  ),
  withHandlers({
    handleCreateOrUpdateLoad: ({ entity }: Object) => () => {
      const options = {
        [GC.FIELD_SOURCE_TYPE]: GC.CREATE_DO_SOURCE_TYPE_EDI,
        [GC.FIELD_GUID]: R.path(['data', GC.FIELD_GUID], entity),
      };
      G.goToRoute(routesMap.newDOBySourceType(options));
    },
    handleAcceptLoad: (props: Object) => () => {
      const {
        entity,
        ediData,
        openModal,
        closeModal,
        acceptLoadFromEDIRequest } = props;

      const transactionGuid = G.ifElse(
        G.isNotNilAndNotEmpty(ediData),
        R.path([GC.FIELD_GUID], ediData),
        R.path(['data', GC.FIELD_GUID], entity),
      );
      const modalContent = getConfirmModal({
        withComment: true,
        cancelAction: props.closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:accept', 'Accept'),
        submitAction: ({ comment }: Object) => {
          acceptLoadFromEDIRequest({ comment, transactionGuid });
          closeModal();
        },
        text: `${G.getWindowLocale(
          'messages:before-accept',
          'Are you sure you want to accept',
        )}?`,
      });
      openModal(modalContent);
    },
    handleDeclineLoad: (props: Object) => () => {
      const {
        entity,
        ediData,
        openModal,
        closeModal,
        declineLoadFromEDIRequest,
      } = props;

      const transactionGuid = G.ifElse(
        G.isNotNilAndNotEmpty(ediData),
        R.path([GC.FIELD_GUID], ediData),
        R.path(['data', GC.FIELD_GUID], entity),
      );

      const submitAction = (values: Object) => {
        declineLoadFromEDIRequest({ ...values, transactionGuid });
        closeModal();
      };

      const component = <DeclineForm submitAction={submitAction} transactionGuid={transactionGuid} />;

      const modal = {
        p: 15,
        component,
        options: {},
      };

      openModal(modal);
    },
  }),
  pure,
);

const renderAction = (
  transactionAction: string,
  handleCreateOrUpdateLoad: Function,
  createdCloGuid: string,
) => {
  const titles = {
    [GC.EDI_TRANSACTION_ACTION_CREATE]: G.getWindowLocale('actions:create-clo', 'Create CLO'),
    [GC.EDI_TRANSACTION_ACTION_REPLACE]: G.getWindowLocale('actions:create-clo', 'Create CLO'),
    [GC.EDI_TRANSACTION_ACTION_UPDATE]: G.getWindowLocale('actions:update-clo', 'Update CLO'),
  };

  if (G.isNilOrEmpty(R.prop(transactionAction, titles))) return <Box />;

  if (G.isNotNilAndNotEmpty(createdCloGuid)) {
    return (
      <AuthWrapper has={[PC.CLO_READ, PC.CLO_WRITE]}>
        <Box
          cursor='pointer'
          onClick={() => G.goToRoute(routesMap.dispatchDetailsOrder(createdCloGuid))}
          title={G.getWindowLocale('actions:go-to-clo-details', 'Go To CLO Details')}
        >
          {I.routesLoads(G.getTheme('colors.dark.blue'))}
        </Box>
      </AuthWrapper>
    );
  }

  return (
    <AuthWrapper has={[PC.CLO_EDI_WRITE]}>
      <Box
        cursor='pointer'
        onClick={handleCreateOrUpdateLoad}
        title={R.prop(transactionAction, titles)}
      >
        {I.routesLoads(G.getTheme('colors.dark.blue'))}
      </Box>
    </AuthWrapper>
  );
};

const AcceptDeclineLoadFromEDIComponent = (props: Object) => {
  const {
    entity,
    ediData,
    showDownload,
    handleAcceptLoad,
    handleDeclineLoad,
    handleCreateOrUpdateLoad,
    cancelLoadFromEDIRequest } = props;

  const status = G.ifElse(
    G.isNotNilAndNotEmpty(ediData),
    R.path([GC.FIELD_EDI_STATUS], ediData),
    R.path(['data', GC.FIELD_EDI_STATUS], entity),
  );
  const errors = G.ifElse(
    G.isNotNilAndNotEmpty(ediData),
    R.path([GC.FIELD_ERRORS], ediData),
    R.path(['data', GC.FIELD_ERRORS], entity),
  );
  const transactionGuid = G.ifElse(
    G.isNotNilAndNotEmpty(ediData),
    R.path([GC.FIELD_GUID], ediData),
    R.path(['data', GC.FIELD_GUID], entity),
  );
  const createdCloGuid = G.ifElse(
    G.isNotNilAndNotEmpty(ediData),
    R.path([GC.GRC.EDI_CREATED_CLO_GUID], ediData),
    R.path(['data', GC.GRC.EDI_CREATED_CLO_GUID], entity),
  );
  const createdCloStatus = G.ifElse(
    G.isNotNilAndNotEmpty(ediData),
    R.path([GC.GRC.EDI_CREATED_CLO_STATUS], ediData),
    R.path(['data', GC.GRC.EDI_CREATED_CLO_STATUS], entity),
  );

  const fileUri = R.path(['data', GC.FIELD_EDI_FILE_URI], entity);
  const branchGuid = R.path(['data', GC.FIELD_BRANCH_GUID], entity);
  const toAcceptDecline = R.includes(status, GC.EDI_STATUSES_TO_ACCEPT_DECLINE);
  const originalFileName = R.path(['data', GC.FIELD_EDI_ORIGINAL_FILE_NAME], entity);
  const transactionAction = R.path(['data', GC.GRC.EDI_CLO_TRANSACTION_ACTION], entity);
  const createdCloStatusNotCanceled = G.notEquals(createdCloStatus, GC.LOAD_STATUS_CANCELED);
  const toCancel = G.isAllTrue(
    createdCloStatusNotCanceled,
    G.isNotNilAndNotEmpty(createdCloGuid),
    R.equals(transactionAction, GC.EDI_TRANSACTION_ACTION_CANCEL),
  );
  const showAcceptDecline = G.isAllTrue(
    toAcceptDecline,
    R.not(toCancel),
    G.notEquals(transactionAction, GC.EDI_TRANSACTION_ACTION_CANCEL),
  );

  return (
    <Flex
      alignItems='center'
      height='max-content'
      color={G.getTheme('colors.dark.blue')}
    >
      {
        toCancel &&
        <AuthWrapper has={[PC.CLO_WRITE]}>
          <Box
            mr={10}
            cursor='pointer'
            textDecoration='underline'
            onClick={() => cancelLoadFromEDIRequest({ transactionGuid, cloGuid: createdCloGuid })}
          >
            {G.getWindowLocale('actions:cancel', 'Cancel')}
          </Box>
        </AuthWrapper>
      }
      {
        showAcceptDecline &&
        <AuthWrapper has={[PC.CLO_EDI_WRITE]}>
          <Box
            cursor='pointer'
            textDecoration='underline'
            onClick={handleAcceptLoad}
          >
            {G.getWindowLocale('titles:accept', 'Accept')}
          </Box>
        </AuthWrapper>
      }
      {
        showAcceptDecline &&
        <AuthWrapper has={[PC.CLO_EDI_WRITE]}>
          <Box
            mx={10}
            cursor='pointer'
            textDecoration='underline'
            onClick={handleDeclineLoad}
          >
            {G.getWindowLocale('titles:decline', 'Decline')}
          </Box>
        </AuthWrapper>
      }
      {
        showDownload &&
        <Box
          mr={10}
          cursor='pointer'
          title={G.getWindowLocale('actions:download', 'Download')}
          onClick={() => props.downloadEdiFileRequest({
            fileUri,
            branchGuid,
            action: 'download',
            fileName: originalFileName,
          })}
        >
          {I.downloadDocument(G.getTheme('colors.dark.blue'))}
        </Box>
      }
      {renderAction(transactionAction, handleCreateOrUpdateLoad, createdCloGuid)}
      <ErrorsPopover errors={errors} wrapperPadding='3px' />
    </Flex>
  );
};

export default enhance(AcceptDeclineLoadFromEDIComponent);

import React from 'react';
import * as R from 'ramda';
// features
import Audit2 from '../../../audit2';
import InspectionsListComponent from '../../../inspections';
import WorkOrderListComponent from '../../../work-order/report';
// helpers/constants
import * as G from '../../../../helpers';
// feature fleet-profile
import { Tab, TabsGroupWrapper, tabsGroupWrapperEnhance } from '../../components/tabs-group-wrapper';
// feature fleet-profile/driver
import {
  withAuditTab,
  withWorkOrdersTab,
  withInspectionsTab,
  withGeneralSettings,
  withSpecificationsTab,
  withDocumentsSettings,
  withTrailerTrackingTab,
  withMaintenanceSettings,
  withServiceIssueSettings,
  withLastKnownLocationSettings,
} from '../hocs/with-tab-group-settings';
/////////////////////////////////////////////

const AuditTab = withAuditTab(Audit2);
const GeneralTab = withGeneralSettings(Tab);
const DocumentsTab = withDocumentsSettings(Tab);
const MaintenanceTab = withMaintenanceSettings(Tab);
const SpecificationsTab = withSpecificationsTab(Tab);
const ServiceIssueTab = withServiceIssueSettings(Tab);
const TrailerTrackingTab = withTrailerTrackingTab(Tab);
const WorkOrdersTab = withWorkOrdersTab(WorkOrderListComponent);
const LastKnownLocationTab = withLastKnownLocationSettings(Tab);
const InspectionsTab = withInspectionsTab(InspectionsListComponent);

const ActiveTab = (props: Object) => {
  const { shared, activeTab, fleetType, primaryObjectGuid, tabsCollapsedView, primaryObjectUnitId } = props;

  let tabsMap = [
    <GeneralTab key={1} {...props} />,
    <DocumentsTab key={2} {...props} />,
    <SpecificationsTab key={3} {...props} />,
    <MaintenanceTab key={4} {...props} />,
    <ServiceIssueTab key={5} {...props} />,
    <InspectionsTab key={6} {...props} />,
    <WorkOrdersTab
      key={7}
      fleetType={fleetType}
      primaryObjectGuid={primaryObjectGuid}
      tabsCollapsedView={tabsCollapsedView}
      primaryObjectUnitId={primaryObjectUnitId}
    />,
    <TrailerTrackingTab key={8} {...props} />,
    <AuditTab key={9} primaryObjectGuid={primaryObjectGuid} tabsCollapsedView={tabsCollapsedView} />,
  ];

  if (shared) {
    const tabProps = {
      ...props,
      withoutAddition: true,
    };

    tabsMap = [
      <DocumentsTab key={1} {...tabProps} />,
      <ServiceIssueTab key={2} {...props} />,
      <LastKnownLocationTab key={3} {...tabProps} />,
      <TrailerTrackingTab key={4} {...props} />,
    ];
  }

  return R.pathOr(null, [activeTab], tabsMap);
};

const getTabs = (shared: Object) => {
  if (shared) {
    return [
      { text: G.getWindowLocale('titles:documents', 'Documents') },
      { text: G.getWindowLocale('titles:issues', 'Issues') },
      { text: G.getWindowLocale('titles:last-known-location', 'Last Known Location') },
      { text: G.getWindowLocale('titles:trailer-tracking', 'Trailer Tracking') },
    ];
  }

  return [
    { text: G.getWindowLocale('titles:general', 'General') },
    { text: G.getWindowLocale('titles:documents', 'Documents') },
    { text: G.getWindowLocale('titles:specifications', 'Specifications') },
    { text: G.getWindowLocale('titles:maintenance', 'Maintenance') },
    { text: G.getWindowLocale('titles:issues', 'Issues') },
    { text: G.getWindowLocale('titles:inspections', 'Inspections') },
    { text: G.getWindowLocale('titles:work-orders', 'Work Orders') },
    { text: G.getWindowLocale('titles:trailer-tracking', 'Trailer Tracking') },
    { text: G.getWindowLocale('titles:audit', 'Audit') },
  ];
};

const TrailerTabsGroups = (props: Object) => (
  <TabsGroupWrapper
    activeTab={props.activeTab}
    tabs={getTabs(props.shared)}
    setTabsView={props.setTabsView}
    tabsCollapsedView={props.tabsCollapsedView}
    handleSetActiveTab={props.handleSetActiveTab}
  >
    <ActiveTab {...props} />
  </TabsGroupWrapper>
);

export default tabsGroupWrapperEnhance(TrailerTabsGroups);

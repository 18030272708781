import React from 'react';
import * as R from 'ramda';
// components
import { FormGroupTitlePanel } from '../../../../components/form-group-table';
// forms
import { FormGroupWrapper } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { MainActionButton } from '../../../../ui';
// feature fleet-list
import FleetList from '../../../fleet-list';
//
import EquipmentServiceDocuments from './documents';
import { withEquipmentServiceActions } from '../hocs/with-equipment-service-actions';
//////////////////////////////////////////////////

const tableSettings = {
  minHeight: 320,
  cellFontSize: 12,
  titleFontSize: 12,
  titleRowHeight: 50,
  allowEditBtn: true,
  tableRowHeight: 35,
  useMainColors: true,
  allowSelectAll: false,
  checkBoxCellWidth: 27,
  expandableItems: true,
  searchableTitles: true,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 90px)',
  expandedDetailsComponent: ({ parentProps, callbackData }: Object = {}) => (
    <EquipmentServiceDocuments
      primaryObjectGuid={G.getGuidFromObject(parentProps)}
      disabled={R.pathOr(false, ['disabled'], parentProps)}
      documentList={R.pathOr([], ['details'], parentProps)}
      {...R.pick(
        [
          'openModal',
          'entityType',
          'closeModal',
          'openLoader',
          'closeLoader',
          'documentTypeOptions',
        ],
        R.or(callbackData, {}),
      )}
    />
  ),
};

const CreateButton = ({ openMaintenanceForm }: Object) => (
  <MainActionButton
    mx={10}
    mb='3px'
    height={30}
    width={165}
    onClick={() => openMaintenanceForm()}
  >
    {G.getWindowLocale('actions:add-equipment-service', 'Add Equipment Service')}
  </MainActionButton>
);

const EquipmentServiceReport = (props: Object) => {
  const {
    isOpened,
    openMaintenanceForm,
    handleToggleFormGroup,
    handleCreateRelatedTask,
  } = props;

  return (
    <FormGroupWrapper isOpened={isOpened}>
      <FormGroupTitlePanel
        isOpened={isOpened}
        isHiddenAddIcon={true}
        handleToggleFormGroup={handleToggleFormGroup}
        panelTitle={G.getWindowLocale('titles:fleet-equipment-service', 'Fleet Equipment Service')}
      />
      <FleetList
        pt='0px'
        pb='6px'
        withoutPageActions={true}
        usePortalForFilters={true}
        useSavedReportState={false}
        openedFromFleetProfile={true}
        tableSettings={tableSettings}
        openMaintenanceForm={openMaintenanceForm}
        handleCreateRelatedTask={handleCreateRelatedTask}
        handleUpdateEquipmentService={openMaintenanceForm}
        callbackData={R.assoc('withoutExpandedContainer', true, props)}
        additionalComponent={<CreateButton openMaintenanceForm={openMaintenanceForm} />}
      />
    </FormGroupWrapper>
  );
};

export default withEquipmentServiceActions(EquipmentServiceReport);

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withState,
  lifecycle,
  withHandlers,
  renderNothing } from 'react-recompose';
// components
import { PageTabs } from '../../../../components/page-tabs';
import { PageTitle } from '../../../../components/page-title';
import { ConfirmComponent } from '../../../../components/confirm';
import { openModal, closeModal } from '../../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// features
import Audit2 from '../../../audit2';
import PC from '../../../permission/role-permission';
import IftaListComponent from '../../../ifta-report';
import FuelCardListComponent from '../../../fuel-cards';
import TollChargesListComponent from '../../../toll-charges';
import { visitFuelCardsOnFleetPage } from '../../../fuel-cards/actions';
import { visitIftaReportOnFleetPage } from '../../../ifta-report/actions';
import { visitTollChargesOnFleetPage } from '../../../toll-charges/actions';
import { makeSelectInitialDataLoadedStatus } from '../../../permission/selectors';
import { makeSelectExpandedContainerOpened } from '../../../expanded-container/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// ui
import { FixedFlex, CancelButton, PageHeaderWrapper } from '../../../../ui';
// feature fleet-list
import { visitFleetListOnFleetProfilePage } from '../../../fleet-list/actions';
// feature fleet
import * as C from '../../constants';
import { SimpleWrapper } from '../../ui';
import AssignInfoComponent from '../../components/assign-info';
import LatestKnownLocationHeaderTitle from '../../components/latest-known-location-header-title';
// feature fleet/driver
import { makeSelectAssignInfo } from '../../driver/selectors';
// feature fleet/truck
import { EditTruckWrapper } from '../ui';
import TruckDocsTab from './truck-docs-tab';
import TruckSpecTab from './truck-spec-tab';
import TruckEquipTab from './truck-equp-tab';
import { EDIT_TRUCK_FORM } from '../constants';
import TruckDetailsTab from './truck-details-tab';
import { withComplexInitialValues } from '../../hocs/complex-initial-values';
import {
  makeSelectTruckConfigs,
  makeSelectTruckDetails,
  makeSelectBranchConfigs,
  makeSelectTruckActiveTab,
  makeSelectCollapsedGroup,
  makeSelectTruckFormValues,
  makeSelectIntegrationSamsaraList,
  makeSelectLatestKnownLocationList,
} from '../selectors';
import {
  setActiveTab,
  setInitialState,
  getTruckDocsRequest,
  toggleTruckFormGroup,
  getTruckLocationRequest,
  getTruckOwnershipRequest,
  getTruckComponentRequest,
  validateBeforeChangeTruckTab,
  getTruckSpecificationRequest,
  changeAssignedToDivisionRequest,
} from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  reduxForm({
    form: EDIT_TRUCK_FORM,
    enableReinitialize: true,
  }),
  withState('location', 'setLocation', {}),
  withState('fieldsErrors', 'setFieldError', {}),
  withHandlers({
    handleToggleFormGroup: ({ toggleTruckFormGroup }: Object) => (fieldName: string) =>
      toggleTruckFormGroup(fieldName),
    handleSetFieldError: ({ fieldsErrors, setFieldError }: Object) => (errorState: Object) => {
      const errorsState = R.clone(fieldsErrors);
      if (R.has(errorState.groupName, errorsState)) {
        if (R.has(errorState.name, errorsState[errorState.groupName])) return;
        errorsState[errorState.groupName][errorState.name] = errorState.error;
      } else {
        errorsState[errorState.groupName] = { [errorState.name]: errorState.error };
      }
      setFieldError(errorsState);
    },
    handleClearFieldError: ({ fieldsErrors, setFieldError }: Object) => (groupName: string, name: string) => {
      const errorsState = R.clone(fieldsErrors);
      if (R.has(groupName, errorsState)) {
        if (R.not(R.has(name, errorsState[groupName]))) return;
        errorsState[groupName] = R.dissoc(name, errorsState[groupName]);
      }
      setFieldError(errorsState);
    },
    handleTabNav: (props: Object) => () => {
      const truckGuid = R.path(['initialValues', GC.FIELD_GUID], props);

      const detailTab = (tabName: string) => ({
        touch: props.touch,
        fleet: C.FLEET_TRUCK,
        formName: EDIT_TRUCK_FORM,
        tabName: props.stateActiveTab,
        tabAction: () => props.handleSetActiveTab(tabName),
      });
      const docsTab = (tabName: string) => ({
        touch: props.touch,
        fleet: C.FLEET_TRUCK,
        formName: EDIT_TRUCK_FORM,
        tabName: props.stateActiveTab,
        tabAction: () => {
          props.handleSetActiveTab(tabName);
          props.getTruckDocsRequest(truckGuid);
        },
      });
      const specificationTab = (tabName: string) => ({
        touch: props.touch,
        fleet: C.FLEET_TRUCK,
        formName: EDIT_TRUCK_FORM,
        tabName: props.stateActiveTab,
        tabAction: () => {
          props.handleSetActiveTab(tabName);
          props.getTruckLocationRequest(truckGuid);
          props.getTruckOwnershipRequest(truckGuid);
          props.getTruckSpecificationRequest(truckGuid);
        },
      });
      const serviceTab = (tabName: string) => ({
        touch: props.touch,
        fleet: C.FLEET_TRUCK,
        formName: EDIT_TRUCK_FORM,
        tabName: props.stateActiveTab,
        tabAction: () => {
          props.handleSetActiveTab(tabName);
          props.getTruckLocationRequest(truckGuid);
          props.getTruckComponentRequest(truckGuid);
          props.visitFleetListOnFleetProfilePage({
            fleetProfileGuid: truckGuid,
            fleetProfileType: GC.FIELD_TRUCK,
            fleetProfileGuidType: GC.FIELD_TRUCK_GUID,
            reportType: GC.FLEET_EQUIPMENT_SERVICE_REPORT,
          });
        },
      });
      const fuelCardsTab = (tabName: string) => ({
        touch: props.touch,
        fleet: C.FLEET_TRUCK,
        formName: EDIT_TRUCK_FORM,
        tabName: props.stateActiveTab,
        tabAction: () => {
          props.handleSetActiveTab(tabName);
          props.visitFuelCardsOnFleetPage({ truckGuid, [GC.FIELD_DRIVER_GUID]: null });
        },
      });
      const tollChargesTab = (tabName: string) => ({
        touch: props.touch,
        fleet: C.FLEET_TRUCK,
        formName: EDIT_TRUCK_FORM,
        tabName: props.stateActiveTab,
        tabAction: () => {
          props.handleSetActiveTab(tabName);
          props.visitTollChargesOnFleetPage({ truckGuid, [GC.FIELD_DRIVER_GUID]: null });
        },
      });
      const iftaReportTab = (tabName: string) => ({
        touch: props.touch,
        fleet: C.FLEET_TRUCK,
        formName: EDIT_TRUCK_FORM,
        tabName: props.stateActiveTab,
        tabAction: () => {
          props.handleSetActiveTab(tabName);
          props.visitIftaReportOnFleetPage({ truckGuid });
        },
      });
      const iconColor = G.getTheme('icons.iconColor');
      const iconColorGrey = G.getTheme('icons.iconColorGrey');

      return [
        {
          tabName: C.FLEET_TAB_DETAILS,
          label: G.getWindowLocale('titles:general-details', 'General Details'),
          action: (tabName: string) => props.validateBeforeChangeTruckTab(detailTab(tabName)),
          frontIcon: G.ifElse(
            R.equals(props.stateActiveTab, C.FLEET_TAB_DETAILS),
            I.searchDocument(iconColor),
            I.searchDocument(iconColorGrey),
          ),
        },
        {
          tabName: C.FLEET_TAB_DOCUMENTS,
          label: G.getWindowLocale('titles:documents', 'Documents'),
          action: (tabName: string) => props.validateBeforeChangeTruckTab(docsTab(tabName)),
          frontIcon: G.ifElse(
            R.equals(props.stateActiveTab, C.FLEET_TAB_DOCUMENTS),
            I.documents(iconColor),
            I.documents(iconColorGrey),
          ),
        },
        {
          tabName: C.FLEET_TAB_TRUCK_SPECIFICATION,
          label: G.getWindowLocale('titles:specifications', 'Specifications'),
          action: (tabName: string) => props.validateBeforeChangeTruckTab(specificationTab(tabName)),
          frontIcon: G.ifElse(
            R.equals(props.stateActiveTab, C.FLEET_TAB_TRUCK_SPECIFICATION),
            I.searchUser(iconColor),
            I.searchUser(iconColorGrey),
          ),
        },
        {
          tabName: C.FLEET_TAB_SERVICE,
          label: G.getWindowLocale('titles:maintenance', 'Maintenance'),
          action: (tabName: string) => props.validateBeforeChangeTruckTab(serviceTab(tabName)),
          frontIcon: G.ifElse(
            R.equals(props.stateActiveTab, C.FLEET_TAB_SERVICE),
            I.gearSolid(iconColor),
            I.gearSolid(iconColorGrey),
          ),
        },
        {
          tabName: C.FLEET_TAB_FUEL_CARDS,
          label: G.getWindowLocale('titles:fuel-transactions', 'Fuel Transactions'),
          action: (tabName: string) => props.validateBeforeChangeTruckTab(fuelCardsTab(tabName)),
          frontIcon: G.ifElse(
            R.equals(props.stateActiveTab, C.FLEET_TAB_FUEL_CARDS),
            I.fuel(iconColor),
            I.fuel(iconColorGrey),
          ),
        },
        {
          tabName: C.FLEET_TAB_TOLL_CHARGES,
          permissions: [PC.TOLL_CHARGE_READ, PC.TOLL_CHARGE_WRITE],
          label: G.getWindowLocale('titles:toll-charges', 'Toll Charges'),
          action: (tabName: string) => props.validateBeforeChangeTruckTab(tollChargesTab(tabName)),
          frontIcon: G.ifElse(
            R.equals(props.stateActiveTab, C.FLEET_TAB_TOLL_CHARGES),
            I.tollRoad(iconColor),
            I.tollRoad(iconColorGrey),
          ),
        },
        {
          permissions: [PC.IFTA_EXECUTE],
          tabName: C.FLEET_TAB_IFTA_REPORT,
          label: G.getWindowLocale('titles:ifta-report', 'IFTA Report'),
          action: (tabName: string) => props.validateBeforeChangeTruckTab(iftaReportTab(tabName)),
          frontIcon: G.ifElse(
            R.equals(props.stateActiveTab, C.FLEET_TAB_IFTA_REPORT),
            I.gearSolid(iconColor),
            I.gearSolid(iconColorGrey),
          ),
        },
      ];
    },
    handleClickCancel: (props: Object) => () => {
      const { openModal, closeModal, closeExpandedContainer, expandedContainerOpened } = props;

      if (expandedContainerOpened) return closeExpandedContainer();

      const component = (
        <ConfirmComponent
          textLocale={G.getWindowLocale(
            'messages:confirmation-leave-page',
            'All changes will be lost. Are you sure you want leave page?',
          )}
        />
      );

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:leave', 'Leave'),
              action: () => {
                closeModal();

                G.historyGoBack();
              },
            },
          ],
        },
      };

      openModal(modal);
    },
    handleCancel: ({ expandedContainerOpened, closeExpandedContainer }: Object) => () => {
      if (expandedContainerOpened) return closeExpandedContainer();

      G.historyGoBack();
    },
  }),
  lifecycle({
    componentWillUnmount() {
      const { setInitialState, handleSetActiveTab } = this.props;

      setInitialState();
      handleSetActiveTab(C.FLEET_TAB_DETAILS);
    },
  }),
  branch(
    (props: Object) => (
      R.and(
        R.not(props.initialDataLoaded),
        G.isNilOrEmpty(R.path(['initialValues', GC.FIELD_GUID], props)),
      )
    ),
    renderNothing,
  ),
  pure,
);

const renderTitle = ({ initialValues }: Object) => {
  const unitId = R.pathOr('', [GC.FIELD_UNIT_ID], initialValues);

  const prefix = G.getWindowLocale('titles:fleet-truck', 'Fleet: Truck');

  if (G.isNilOrEmpty(unitId)) return `${prefix}`;

  return `${prefix} ${unitId}`;
};

const renderTab = (props: Object) => {
  const truckGuid = R.path(['initialValues', GC.FIELD_GUID], props);

  const auditProps = {
    useFullScreen: true,
    requestOptions: {
      [GC.FIELD_OBJECT_GUID]: truckGuid,
      [GC.FIELD_TYPE]: GC.AUDIT_TYPE_TRUCK,
    },
  };

  const tabs = {
    audit: () => <Audit2 {...auditProps} />,
    [C.FLEET_TAB_DETAILS]: () => (
      <TruckDetailsTab
        {...props}
        truckGuid={truckGuid}
        branchGuid={R.path(['complexInitialValues', GC.FIELD_BRANCH_GUID], props)}
      />
    ),
    [C.FLEET_TAB_DOCUMENTS]: () => <TruckDocsTab {...props} primaryObjectGuid={truckGuid} />,
    [C.FLEET_TAB_TRUCK_SPECIFICATION]: () => <TruckSpecTab {...props} truckGuid={truckGuid} />,
    [C.FLEET_TAB_TOLL_CHARGES]: () => <TollChargesListComponent pl='0px' title={renderTitle(props)} />,
    [C.FLEET_TAB_SERVICE]: () =>
      <TruckEquipTab {...props} branchGuid={R.path(['initialValues', GC.FIELD_BRANCH_GUID], props)} />,
    [C.FLEET_TAB_IFTA_REPORT]: () => (
      <IftaListComponent
        pl='0px'
        title={renderTitle(props)}
        fromTruckDetailPage={true}
      />
    ),
    [C.FLEET_TAB_FUEL_CARDS]: () => (
      <FuelCardListComponent
        pl='0px'
        pageActionsBottom={70}
        title={renderTitle(props)}
        fleetType={GC.FIELD_TRUCK}
        openedFromFleetProfile={true}
        primaryObjectGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
        truckUnitId={R.path(['initialValues', GC.FIELD_TRUCK_UNIT_ID], props)}
        assignedDriverGuid={R.pathOr(null, ['initialValues', GC.FIELD_DRIVER_GUID], props)}
        defaultBranchCurrency={G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, props.branchConfigs)}
      />
    ),
  };

  return tabs[props.stateActiveTab]();
};

const PageHeader = (props: Object) => (
  <PageHeaderWrapper
    zI='13'
    aI='flex-end'
    position='relative'
    height='max-content'
    justify='space-between'
  >
    <SimpleWrapper>
      <PageTitle
        status={true}
        showStatus={true}
        title={renderTitle(props)}
      />
      <AssignInfoComponent
        truck={true}
        type={C.FLEET_TRUCK}
        openModal={props.openModal}
        closeModal={props.closeModal}
        assignInfo={R.prop(C.FLEET_ASSIGN_INFO, props)}
        expandedContainerOpened={props.expandedContainerOpened}
        objGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
        version={R.path(['initialValues', GC.FIELD_VERSION], props)}
        branchGuid={R.path(['initialValues', GC.BRANCH_GUID], props)}
        changeAssignedToDivisionRequest={props.changeAssignedToDivisionRequest}
        assignedToDivision={R.path(['formValues', 'assignedToDivision'], props)}
      />
    </SimpleWrapper>
    {
      G.isNotNilAndNotEmpty(props.latestKnownLocations)
      && <LatestKnownLocationHeaderTitle latestKnownLocations={props.latestKnownLocations} />
    }
  </PageHeaderWrapper>
);

const EditTruckComponent = enhance((props: Object) => {
  const {
    handleCancel,
    handleTabNav,
    stateActiveTab,
    handleSetActiveTab,
    expandedContainerOpened,
  } = props;

  const isNotExternalTab = G.notContain(
    stateActiveTab,
    [C.FLEET_TAB_FUEL_CARDS, C.FLEET_TAB_TOLL_CHARGES, C.FLEET_TAB_IFTA_REPORT]
  );

  return (
    <EditTruckWrapper
      mb={60}
      background={G.getTheme('colors.white')}
      pl={G.ifElse(expandedContainerOpened, 25)}
      height={G.ifElse(expandedContainerOpened, '100vh')}
      overflowY={G.ifElse(expandedContainerOpened, 'auto')}
      pb={G.ifElse(R.and(isNotExternalTab, expandedContainerOpened), 60)}
    >
      <PageTabs
        zI={20}
        height={220}
        withAudit={true}
        isLabelExist={true}
        tabs={handleTabNav()}
        activeTab={stateActiveTab}
        setActiveTab={handleSetActiveTab}
      />
      {isNotExternalTab && <PageHeader {...props} />}
      {renderTab(props)}
      {
        G.notContain(
          stateActiveTab,
          [
            C.FLEET_TAB_DETAILS,
            C.FLEET_TAB_TRUCK_SPECIFICATION,
          ],
        ) &&
        <FixedFlex
          pl={70}
          left='0px'
          height={60}
          bottom='0px'
          width='100%'
          zIndex={12}
          borderTop='1px solid'
          bg={G.getTheme('forms.actionsFooter.bgColor')}
          borderColor={G.getTheme('forms.actionsFooter.borderTopColor')}
        >
          <CancelButton width={100} onClick={() => handleCancel()}>
            {G.getWindowLocale('actions:cancel', 'Cancel')}
          </CancelButton>
        </FixedFlex>
      }
    </EditTruckWrapper>
  );
});

const EditFormWithComplexInitialValues = withComplexInitialValues((props: Object) => (
  <EditTruckComponent {...props} initialValues={props.complexInitialValues} />
));

const mapStateToProps = (state: Object) => (createStructuredSelector({
  assignInfo: makeSelectAssignInfo(state),
  activeTab: makeSelectTruckActiveTab(state),
  truckConfigs: makeSelectTruckConfigs(state),
  initialValues: makeSelectTruckDetails(state),
  branchConfigs: makeSelectBranchConfigs(state),
  collapsedGroup: makeSelectCollapsedGroup(state),
  integrationSamsara: makeSelectIntegrationSamsaraList(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  formValues: makeSelectTruckFormValues(state, EDIT_TRUCK_FORM),
  latestKnownLocations: makeSelectLatestKnownLocationList(state),
  expandedContainerOpened: makeSelectExpandedContainerOpened(state),
}));

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  openLoader,
  closeLoader,
  setActiveTab,
  setInitialState,
  getTruckDocsRequest,
  toggleTruckFormGroup,
  getTruckLocationRequest,
  getTruckOwnershipRequest,
  getTruckComponentRequest,
  visitFuelCardsOnFleetPage,
  visitIftaReportOnFleetPage,
  visitTollChargesOnFleetPage,
  getTruckSpecificationRequest,
  validateBeforeChangeTruckTab,
  changeAssignedToDivisionRequest,
  visitFleetListOnFleetProfilePage,
})(EditFormWithComplexInitialValues);
